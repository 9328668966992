/**
 * FORMAT, do not forget COMMAS
 * 
 * Anything between the `` can be put on separate lines, if needed
 * 
 * For supers, use slot31 and slot32 for the slot types (can keep the star number as slot3)
 * If Unit has no TW, can delete TW section
 * {
        id: ,
        name: ``,
        attribute: ``,
        type: ``,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_.png",
                detailsuper: "/db/Units/Detail/unit_detail_.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/defLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: ,
                hpplus: ,
                atk: ,
                atkplus: ,
                def: ,
                defplus: 
            },
        skillset: 
            {
                skill: ``,
                skillbreak: ,
                arts: ``,
                artsbreak: ,
                truearts: ``,
                trueartsbreak: ,
                superarts: ``,
                superartsbreak: 
            },
        passive: 
            {
                ability1: ``,
                ability2: ``
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131354.png",
                    mat2amt: 220,
                    hover2: "Eternaglint Soulgem"
                }
            },
        trueweapon: 
            {
                name: ``,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_.png",
                skill: ``,
                skillbreak: ,
                passive: 
                    {
                        ability1: ``,
                        ability2: ``
                    }
            },
        lore: 
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    }

    ,
        review:
        {
            skill: ``,
            arts: ``,
            truearts: ``,
            superarts: ``,
            ability1: ``,
            ability2: ``,
            ability3: ``,
            trueweapon: {
                skill: ``,
                ability1: ``,
                ability2: ``
            },
            overall: ``,
            lastupdated: `December 31, 2023`,
            author: `Zanster`
        }

        //format for Dream Evolution Passives. Remove / Add Colors as needed.
        Colors are:
        - fire
        - water
        - earth
        - light
        - dark

        dreamJP: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                        mat3amt: 2,
                        hover3: "Ifrit",
                    },
                    passive: {
                        ability1: ``,
                        ability2: ``
                    }
                },
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                        mat3amt: 2,
                        hover3: "Ifrit",
                    },
                    passive: {
                        ability1: ``,
                        ability2: ``
                    }
                },
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                        mat3amt: 2,
                        hover3: "Ifrit",
                    },
                    passive: {
                        ability1: ``,
                        ability2: ``
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                        mat3amt: 2,
                        hover3: "Ifrit",
                    },
                    passive: {
                        ability1: ``,
                        ability2: ``
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                        mat3amt: 2,
                        hover3: "Ifrit",
                    },
                    passive: {
                        ability1: ``,
                        ability2: ``
                    }
                }
            }
    

    /db/Mines/thumbnail/physLB.png
    /db/Mines/thumbnail/magLB.png
    /db/Mines/thumbnail/defLB.png
    /db/Mines/thumbnail/healLB.png
    /db/Mines/thumbnail/suppLB.png

 */

const UnitInformation = [
    { //Clyde - Complete
        id: 100016111,
        name: "Clyde",
        rank: "F",
        twrequire: "reliance",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_100016111.png",
                thumb2: "/db/Units/Thumbnail/unit_thumbnail_100012111.png",
                thumb3: "/db/Units/Thumbnail/unit_thumbnail_100013111.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_100014111.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_100015111.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_100016111.png",
                detail2: "/db/Units/Detail/unit_detail_100012111.png",
                detail3: "/db/Units/Detail/unit_detail_100013111.png",
                detail4: "/db/Units/Detail/unit_detail_100014111.png",
                detail5: "/db/Units/Detail/unit_detail_100015111.png"
            },
        skillset: 
            {
                skill: "6s CT - 2000% Fire DMG (PHY). Increase own Arts gauge by 10.",
                skillbreak: 1500,
                arts: "15000% Fire DMG (PHY). 50% chance to inflict Burn for 10s.",
                artsbreak: 2200,
                truearts: "45000% Fire DMG (PHY). 100% chance to inflict Burn for 15s. Increase Allies' Arts by 20.",
                trueartsbreak: 4800
            },
        passive: 
            {
                ability1: "Increase Fire Allies' BE generation by 20%.",
                ability2: "Increase Fire Allies' DMG by 30%."
            },
        stats: 
            {
                hp: 6960,
                hpplus: 999,
                atk: 2772,
                atkplus: 425,
                def: 2138,
                defplus: 330
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        evolution: 
            {
                evo23: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_fire2.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_fire1.png",
                    mat2amt: 2
                },
                evo34: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_fire2.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_fire2.png",
                    mat2amt: 5
                },
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_fire4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_fire3.png",
                    mat2amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                    mat3amt: 1,
                    hover3: "Degilmagna"
                }
            },
        dream: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragDegilmagna.png`,
                        mat3amt: 2,
                        hover3: `Degilmagna`
                    },
                    passive: {
                        ability1: `Increase own DMG against Earth enemies by 200%.`,
                        ability2: ``
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragWilkes.png`,
                        mat3amt: 2,
                        hover3: `Wilkes`
                    },
                    passive: {
                        ability1: `Increase own CRI rate by 100% when HP is 80% or above.`,
                        ability2: ``
                    }
                },
            },
        trueweapon: 
            {
                name: "Gradion",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_103371.png",
                skill: "60s CT - 7000% Fire DMG (PHY). For 8s, increase own Critical Rate by 100% against Burned Enemies.",
                skillbreak: 1600,
                passive: 
                    {
                        ability1: "When equipped by Clyde, increase DMG by 75% and increase Arts Gauge by 3/s.",
                        ability2: "Increase DMG to Earth Enemies by 25%."
                    }
            },
        lore: 
            {
                evo2: "One of the 3 Blade Masters who fought in the Ancient War. Trained in the sword from a young age, and was as strong as a knight by 16. Only nobles could become knights in his birthplace of Isliid, however, and so he set off on a journey to perfect himself as a swordsman. Though he emerged victorious from countless battles, his teacher would later say his greatest talent waas his penchant for meeting power foes." ,
                evo3: "An Isliidan swordsman, and one of the 3 Blade Masters who fought in the Ancient War. After setting out on a journey to become the greatest swordsman, he fought countless battles against those who would later become heroes in the war. His fight against Mashreed, emperor of Isliiid, is particularly notable. It is said the future emperor was so impressed by the swordsman's proud stature that he invited him to join the knights. ",
                evo4: "An Isliidan swordsman later counted among the 3 Blade Masters. During his journey of self-improvement, a Great War between the Gods and Demons began. Clyde sought to fight as a mercenary, but a request from Isliid's emperor sent him to the capital. There the new emperor Mashreed, who Clyde had once battled against, chose Clyde to serve as eader of the imperial knights. ",
                evo5: "One of the 3 Blade Masters who fought in the Ancient War. After becoming head of Isliid's kngihts, he deferred command, assembled a group of young knights and headed to the battlefield. With his trusted Flameblade Gladion in hand, he quickly laid waste to the Giant and Demon hordes opposing him. Those who were initially critical of him later came to revere him - a point of pride.",
                evoawk: "Although being one of the 3 Blade Masters, he suddenly disappeared from history. It is not clear of this life after the Ancient War. There are several rumors about him such as, helping others during the harsh times after war, death and life battle with his master, or being killed by the emperor of Isliid due to his riot against him. However, one thing is common, he had enormous power even after the war and bright fire in his eyes."
            }
    },
    { //Corsair - Complete
        id: 100026211,
        name: "Corsair",
        rank: "F",
        twrequire: "reliance",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_100026211.png",
                thumb2: "/db/Units/Thumbnail/unit_thumbnail_100022211.png",
                thumb3: "/db/Units/Thumbnail/unit_thumbnail_100023211.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_100024211.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_100025211.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_100026211.png",
                detail2: "/db/Units/Detail/unit_detail_100022211.png",
                detail3: "/db/Units/Detail/unit_detail_100023211.png",
                detail4: "/db/Units/Detail/unit_detail_100024211.png",
                detail5: "/db/Units/Detail/unit_detail_100025211.png"
            },
        stats: 
            {
                hp: 6708,
                hpplus: 999,
                atk: 2074,
                atkplus: 321,
                def: 2203,
                defplus: 340
            },
        slots: {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        skillset: 
            {
                skill: "6s CT - 1800% Water DMG (PHY). 10% chance to inflict Freeze for 2s.",
                skillbreak: 1300,
                arts: "14000% Water DMG (MAG).",
                artsbreak: 2400,
                truearts: "35000% Water DMG (MAG). 100% chance to inflict Freeze for 5s. Increase all Allies' Arts Gauge by 20.",
                trueartsbreak: 4800
            },
        passive: 
            {
                ability1: "Increase Water Allies' BE generation by 20%.",
                ability2: "Increase Water Allies' DMG by 30%."
            },
        evolution: 
            {
                evo23: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_water2.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_water1.png",
                    mat2amt: 2
                },
                evo34: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_water2.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_water2.png",
                    mat2amt: 5
                },
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_water3.png",
                    mat2amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat3amt: 1,
                    hover3: "Freydal"
                }
            },
        dream: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragFreydal.png`,
                        mat3amt: 2,
                        hover3: `Freydal`
                    },
                    passive: {
                        ability1: `Adds "Reduce enemies' Freeze RES by 30% for 30s" effect to Arts.`,
                        ability2: ``
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragRagsherumPhantom.png`,
                        mat3amt: 2,
                        hover3: `Ragsherum Phantom`
                    },
                    passive: {
                        ability1: `Increase own Arts gauge by 200 at the Start of the Quest.`,
                        ability2: ``
                    }
                },
            },
        trueweapon: 
            {
                name: "Froid Rosier",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_103381.png",
                skill: "40s CT - 5500% Water DMG (MAG). 60% Chance to inflict Freeze.",
                skillbreak: 1200,
                passive: 
                    {
                        ability1: "When equipped by Corsair, increase DMG by 75% and increase Arts Gauge by 3/s",
                        ability2: "Increase DMG to Fire Enemies by 25%"
                    }
                
            },
        lore: 
            {
                evo2: "One of the 3 Blade Masters who fought in the Ancient War. A highborn member of Bamint nobility, she received special eduction from birth. Though she excelled in every field, what she preferred most was sword training, and at the age of 16 was regarded as having skills equal to that of one of the country's knights." ,
                evo3: "A Bamint noble later counted among the 3 Blade Masters. On her 18th birthday, she discarded her favorite sword, a moment of joy for her faather, who hoped she would settle down. She rebelled instead, leaving a journey in pursuit of freedom. Using her sword skills to help those in need, this passionate swordswoman's ice magic-ensconced blade shines cold and beautiful.",
                evo4: "A Bamint swordswoman later counted amount the 3 Blade Masters. Countless foes marked her days of travel, all sent by her father in an attempt to bring her home. Ironically, her victorious battles against them only honed her skills with the blade further. One day, an unusually vicious foe appeared before her. After defeating it, she voewed to return home, praying for her father's safety all the while. ",
                evo5: "After returning to Bamint, Corsair found her father chatting happily with the assassin she thought she had defeated. Furious, her father smiled and informed her that, by defeating the strongest fighter in the land, she had earned herself a spot as a member of the knights. She would go on to fight in the Great War, becoming known as one of the 3 Blade Masters, but the look on her face was always a sour one.",
                evoawk: "Although being one of the 3 Blade Masters, it is said that she created the reason for the royal family to fall. After the Ancient War, she was ordered to guard the prince of Bamint who was going to attend the 5 Kings Conference and even proposed. However, she declined the offer, and left the country. Her father did not criticize her for this. She is said to have fought with the other Blade Masters or have lived to bring up her adopted child. Her sword skills were the best during her last years."
            }
    },
    { //Arth - Complete
        id: 100036311,
        name: "Arth",
        rank: "F",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_100036311.png",
                thumb2: "/db/Units/Thumbnail/unit_thumbnail_100032311.png",
                thumb3: "/db/Units/Thumbnail/unit_thumbnail_100033311.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_100034311.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_100035311.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_100036311.png",
                detail2: "/db/Units/Detail/unit_detail_100032311.png",
                detail3: "/db/Units/Detail/unit_detail_100033311.png",
                detail4: "/db/Units/Detail/unit_detail_100034311.png",
                detail5: "/db/Units/Detail/unit_detail_100035311.png"
            },
        skillset: 
            {
                skill: "6s CT - 1900% Earth DMG (PHY).",
                skillbreak: 2100,
                arts: "11000% Earth DMG (PHY).",
                artsbreak: 4200,
                truearts: "30000% Earth DMG (PHY). Increase all Allies' Arts Gauge by 20.",
                trueartsbreak: 9500
            },
        passive: 
            {
                ability1: "Increase Earth Allies' BE generation by 20%",
                ability2: "Increase Earth Allies' DMG by 30%"
            },
        stats: 
            {
                hp: 6546,
                hpplus: 991,
                atk: 2268,
                atkplus: 350,
                def: 2074,
                defplus: 321
            },
        slots: 
            {
            slot1: 6,
            slot1type: "/db/Mines/thumbnail/physLB.png",
            slot2: 5,
            slot2type: "/db/Mines/thumbnail/magLB.png",
            slot3: 4,
            slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        evolution: 
            {
                evo23: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_earth2.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_earth1.png",
                    mat2amt: 2
                },
                evo34: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_earth3.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_earth2.png",
                    mat2amt: 5
                },
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_earth3.png",
                    mat2amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat3amt: 1,
                    hover3: "Elpneumas"
                }
            },
        dream: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragIfrit.png`,
                        mat3amt: 2,
                        hover3: `Ifrit`
                    },
                    passive: {
                        ability1: `Increase all allies' Arts gauge by 1/s (No Duplication).`,
                        ability2: ``
                    }
                },
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragElpneumas.png`,
                        mat3amt: 2,
                        hover3: `Elpneumas`
                    },
                    passive: {
                        ability1: `Increase own DMG against Water enemies by 70%.`,
                        ability2: `Increase own Break Power against Water enemies by 70%.`
                    }
                },
            },
        trueweapon: 
            {
                name: "Arth Lumina",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_103391.png",
                skill: "50s CT - 6000% Earth DMG (PHY)",
                skillbreak: 3000,
                passive: 
                    {
                        ability1: "When equipped by Arth, increase DMG by 75% and increase Arts Gauge by 3/s" ,
                        ability2: "Increase own DMG to Water Enemies by 25%"
                    }
            },
        lore: 
            {
                evo2: "One of the 3 Blade Masters who fought in the Ancient War. Son of one of Gramenia's great swordmasters, Arth was renowned as a genius swordsman from youth. To improve his abilities even further, he began developing techniques that broke from tradition. It's said that his teacher - who was also his father - observed his training quietly at first, only to reprimand him severely when he took up two swords.",
                evo3: "A Gramenian swordsman later counted among the 3 Blade Masters. After taking up a twin- bladed style not seen in any school, Arth dueled his father and won. While this lead to his expulsion, his father advised that he open a new school of his own to impart his style to a new generation. Arth rejected this and continued improving his skills, speaking to others only about swords.",
                evo4: "A Gramenian swordsman later counted among the 3 Blade Masters. The story goes that when he heard of the massive war between Gods and Demons during his sword training, he immediately headed to the battlefield. Aligned with the Gods, Arth achieved many military victories, he once turned and bore witness to the unspeakable devastation that had been wrought. Shocked senseless, his blades dropped to the ground.",
                evo5: "In the midst of the Great War between Gods and Demons, Arth dropped his blades and returned to his wartorn hometown, but took them up again upon seeing the devastation. This time, his blades would be used not to destroy, but to rebuild, with a pair of blades from his father. Armed with these new earth magic-infused swords, Arth fought to help others, coming to be known as the holy sword.",
                evoawk: "After he became a teacher of sword skills for Gramenia Republic, other than leaving the leaving the country once, he remained, and people greatly thanked him for his selfless devotion. On the other hand, he sometimes had a look of sorrow on his face. He hated to meet high officials on an extreme degree. He stayed single for his whole life. On his 80th birthday, surrounded by his disciples, he passed away with last words of 'Was I forgiven?' His sword skills are still passed won to the next generation."
            }
    },
    { //Feena - Complete, Dream Evo Complete
        id: 100046411,
        name: "Feena",
        rank: "B+",
        twrequire: "reliance",
        attribute: "Light",
        type: "God",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_100046411.png",
                thumb2: "/db/Units/Thumbnail/unit_thumbnail_100042411.png",
                thumb3: "/db/Units/Thumbnail/unit_thumbnail_100043411.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_100044411.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_100045411.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_100046411.png",
                detail2: "/db/Units/Detail/unit_detail_100042411.png",
                detail3: "/db/Units/Detail/unit_detail_100043411.png",
                detail4: "/db/Units/Detail/unit_detail_100044411.png",
                detail5: "/db/Units/Detail/unit_detail_100045411.png"
            },
        skillset: 
            {
                skill: "8s CT - 2600% Light DMG (MAG). For 5s, heal own HP by 20/s.",
                skillbreak: 1000,
                arts: "13000% Light DMG (MAG). Cure all Ally Status Ailments (except Faint). For 10s, increase all Ally Status RES (except Faint) by 100%",
                artsbreak: 2600,
                truearts: "26000% Light DMG (MAG). Cure all Ally Status Ailments (except Faint). For 20s, increase all Ally Status RES (except Faint) by 100% and heal all Ally HP by 300/s",
                trueartsbreak: 4500
            },
        passive: 
            {
                ability1: "Increase own DMG to Demon Enemy by 80%",
                ability2: "When an other Ally dies, revive that Ally with 33% HP. Can be activated once per quest",
                ability3: "When own HP is 80%, or more, increase own Status RES (except Faint) by 100%"
            },
        stats: 
            {
                hp: 6059,
                hpplus: 918,
                atk: 2423,
                atkplus: 373,
                def: 2280,
                defplus: 352
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        evolution: 
            {
                evo23: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light2.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light1.png",
                    mat2amt: 5
                },
                evo34: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light2.png",
                    mat2amt: 5
                },
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat2amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 4,
                    hover3: "Fosradius",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 4,
                    hover4: "Code-XTF",
                    mat5: "/db/Icons/dungeon_drops/fragAerugraxes.png",
                    mat5amt: 5,
                    hover5: "Aerugraxes"
                }
            },
            dream: {
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        hover2: `Eternaglint Soulgem`,
                        mat2amt: 50,
                        mat3: `/db/Icons/dungeon_drops/fragFosradius.png`,
                        mat3amt: 2,
                        hover3: `Fosradius`
                    },
                    passive: {
                        ability1: `Increases all God Allies' Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: ``
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragValzandeth.png`,
                        mat3amt: 2,
                        hover3: `Valzandeth`
                    },
                    passive: {
                        ability1: `Increase own BREAK Power by 60% against Dark enemies.`,
                        ability2: ``
                    }
            }
        },
        trueweapon: 
            {
                name: "Centurion",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_103941.png",
                skill: "60s CT - 6200% Light DMG (MAG). For 10s, Allies heal for 30% of DMG taken.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Feena, increase DMG by 20% and increase Arts Gauge by 3/s" ,
                        ability2: "Increase own Dark RES by 20%"
                    }
            },
        lore: 
            {
                evo2: "A holy knight who rose to prominence in Famelle shortly before the start of the Ancient War. Her furious sword moves belied her appearance and she was regarded as the best in the kingdom. She was tasked with a certain duty because of her unerring faith towards the Gods, and departed on a journey to fulfill it. Feena and a knight of La Riiz would later be known as the Twin Knights of the Morning Star, but little is known about her.",
                evo3: "A member of the Twin Knights of the Morning Star, duo famous after the Ancient War. Entrusted with a secret duty by the Kingdom of Famelle, she visited every land under Human rule, and saved members of other races from oppression in more than one occasion. To this day, many know her from bard songs, championing her as the Luminous Knight. There are also stories of her meeting with key figures from every country during her journey.",
                evo4: "A member of the Twin Knights of the Morning Star, duo famous after the Ancient War. After visiting all the lands ruled by Humans, she headed to the lands ruled by Gods. When war between Gods and Demons broke out, she was allowed to join the Gods Army despite not being a God herself. It is said even Demons feared her on the battlefield. Toward the end of the war, she heard of a crisis befalling Famelle, and recieved permission to return home.",
                evo5: "After returning home, the war reached its end. With the Gods and Demons sealing themselves, and the other races on the verge of destruction, she chose to ignore boundaries of race to aid those in need. Her holy light inspired those in the throes of despair, and led them to call her Knight of the Morning star. Despite the legends, her absence from Famellian historical records remains a mystery to this day.",
                evoawk: "One of the Twin Knights of the Morning Star, who, like the Holy Mother, brought healing and charity to the people. Tales of her generosity have been handed down through every land, and though there is no trace in the history, they tell of how she brought soothing to those in need weeping as she did. The reason of her tears remains a mystery even now, but there are those who say she used to mutter words of apology, as well as the name of a vanished God."
            }
    },
    { //Regulus - Complete, Dream Evo Complete
        id: 100056511,
        name: "Regulus",
        rank: "C",
        twrequire: "reliance",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_100056511.png",
                thumb2: "/db/Units/Thumbnail/unit_thumbnail_100052511.png",
                thumb3: "/db/Units/Thumbnail/unit_thumbnail_100053511.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_100054511.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_100055511.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_100056511.png",
                detail2: "/db/Units/Detail/unit_detail_100052511.png",
                detail3: "/db/Units/Detail/unit_detail_100053511.png",
                detail4: "/db/Units/Detail/unit_detail_100054511.png",
                detail5: "/db/Units/Detail/unit_detail_100055511.png"
            },
        skillset: 
            {
                skill: "7s CT - 2200% Dark DMG (PHY). Heal own HP by 5% of DMG dealt by own Skill",
                skillbreak: 1300,
                arts: "16000% Dark DMG (PHY). For 10s, increase all Allies' Critical Rate by 50%.",
                artsbreak: 2000,
                truearts: "40000% Dark DMG (PHY). For 12s, increase all Ally Critical Rate by 100% and DMG to Light Enemy by 50%",
                trueartsbreak: 3800
            },
        passive: 
            {
                ability1: "Increase own Light RES by 30%",
                ability2: "Increase all Ally Dark DMG by 30% (Non-stack)"
            },
        stats: 
            {
                hp: 6970,
                hpplus: 999,
                atk: 2693,
                atkplus: 413,
                def: 2423,
                defplus: 373
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        evolution: 
            {
                evo23: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_dark2.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_dark1.png",
                    mat2amt: 5
                },
                evo34: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_dark3.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_dark2.png",
                    mat2amt: 5
                },
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_dark3.png",
                    mat2amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragValzandeth.png",
                    mat3amt: 4,
                    hover3: "Valzandeth",
                    mat4: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat4amt: 4,
                    hover4: "Ifrit",
                    mat5: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat5amt: 5,
                    hover5: "Oldeus"
                }
            },
            dream: {
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragTonetaurus.png`,
                        mat3amt: 2,
                        hover3: `Tonetaurus`
                    },
                    passive: {
                        ability1: `Adds "Increase all Allies' Arts by 20" effect to Arts.`,
                        ability2: ``
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        hover2: `Eternaglint Soulgem`,
                        mat2amt: 50,
                        mat3: `/db/Icons/dungeon_drops/fragWilkes.png`,
                        mat3amt: 2,
                        hover3: `Wilkes`
                    },
                    passive: {
                        ability1: `Adds "For 10s, heal all allies' Blind and increase allies' Blind RES by 100%" effect to Arts.`,
                        ability2: ``
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragMavroskia.png`,
                        mat3amt: 2,
                        hover3: `Mavroskia`
                    },
                    passive: {
                        ability1: `Adds "Increase own Arts by 40" effect to Skill.`,
                        ability2: ``
                    }
            }
        },
        trueweapon: 
            {
                name: "Nemesis Crow",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_103501.png",
                skill: "50s CT - 7000% Dark DMG (MAG). For 10s, reduce Enemy Dark RES by 50%",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Regulus, increase own Critical DMG by 50% and increase own Arts Gauge by 3/s" ,
                        ability2: "Increase own DMG to Dragon Enemy by 50%"
                    }
            },
        lore: 
            {
                evo2: "A knight of the Raven's Aegis, a troop of La Riiz dedicated to protecting sorcerers. While the troop emphasized defense, Regulus opted not to carry a shield, focusing on offense instead. Nonetheless, he remained in the troop, both because his sword skills saved more sorcerers than any shield, and because he lacked the capacity for magic himself. He would later achieve renown as one of the Twin Knights of the Morning Star.",
                evo3: "A member of the Twin Knights of the Morning Star, duo famous after the Ancient War. Just before the start of the Ancient War, when tensions between Gods and Demons were at their peak, Regulus spent time away from his fellow knights. Records of his battles with knights across the land suggest this was a training pilgrimage, but it's also rumored that he recieved a secret mission from La Riiz' High Council to visit Human-controlled lands.",
                evo4: "A member of the Twin Knights of the Morning Star, duo famous after the Ancient War. Regulus challenged not just Humans during his travels, but countless other races as well. One of his famous achievement was the battle against the Flamewyrm Dargeon, which lasted three days and three nights before the final draw, that made his name renowned even among Dragons. This only incremented his opponents, who, regardless of race, challenged him, increasing his skills to the apex.",
                evo5: "When the Ancient War broke out, Regulus returned to La Riiz to rejoin his troop. A veteran of battles against all the races, Regulus inspired bravery in his companions and countrymen. After Gods and Demons vanished from the world at the end of the war, he traveled with a lady knight to help those in need: a duo that came to be known as the Twin Knights of the Morning Star. Strangely, no records of his deeds after this point remains...",
                evoawk: "After the end of the Ancient War, he was known as one of the Twin Knights of the Morning Star, but before that, he was already famous for saving the Magus Empire of La Riiz from great peril. He stood against the God Eater Mavroskia, which brings calamity wherever it appears, and turned its rampage. This is the only time in history a God Eater's destruction has been limited so far. They say he had the protection of a certain Witch, but his merit in the story is undeniable."
            }
    },
    { //Dargeon - Complete + DreamEvo
        id: 100566112,
        name: "Dargeon",
        rank: "F",
        attribute: "Fire",
        type: "Dragon",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_100566112.png",
                detail3: "/db/Units/Detail/unit_detail_100563112.png",
                detail4: "/db/Units/Detail/unit_detail_100564112.png",
                detail5: "/db/Units/Detail/unit_detail_100565112.png",
                thumb3: "/db/Units/Thumbnail/unit_thumbnail_100563112.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_100564112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_100565112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_100566112.png"
            },
        skillset: 
            {
                skill: "3000% Fire DMG (PHY). 25% chance to inflict Burn for 6s.",
                skillbreak: 900,
                arts: "35000% Fire DMG (MAG). Additionally, when under Light Dream Path, for 20s, reduce enemies' Burn RES by 20%.",
                artsbreak: 1700,
                truearts: "46000% Fire DMG (MAG). For 35s, increase all Fire Ally DMG by 50%",
                trueartsbreak: 2400
            },
        passive: 
            {
                ability1: "Increase own DMG to Dragon Enemy by 50%",
                ability2: "Increase own Dark RES by 35%",
                ability3: "Increase own Freeze and Paralysis RES by 100%"
            },
        stats: 
            {
                hp: 7350,
                hpplus: 999,
                atk: 2500,
                atkplus: 390,
                def: 2456,
                defplus: 377
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        evolution: 
            {
                evo34: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_fire3.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_fire2.png",
                    mat2amt: 5
                },
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_fire4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_fire3.png",
                    mat2amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                    mat3amt: 2,
                    hover3: "Degilmagna",
                }
            },
        dream: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragDegilmagna.png`,
                        mat3amt: 2,
                        hover3: `Degilmagna`
                    },
                    passive: {
                        ability1: `Increase DMG against Earth enemies by 200%.`
                    }
                },
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragGoldola.png`,
                        mat3amt: 2,
                        hover3: `Goldola`
                    },
                    passive: {
                        ability1: `Increase Poison RES by 100%.`,
                        ability2: `Increase own Arts gauge by 2/s.`
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragWilkes.png`,
                        mat3amt: 2,
                        hover3: `Wilkes`
                    },
                    passive: {
                        ability1: `Add "For 20s, reduce enemies' Burn RES by 20%." to Arts.`
                    }
                },
            },
        lore: 
            {   
                evo3: "A flame Dragon that reigned over Famelle Rock. Boasting overwhelming strength, Dargeon lived impulsively, trampling others without a thought. One day, a lone swordsman appeared to challenge Dargeon, who dismissed the challenger out of hand as a feeble Human. But something unexpected happened. The swordsman - Clyde - gave the dragon his first taste of defeat, and kindling vengeful flames within his heart. ",
                evo4: "A Dragon burning with revenge against the swordsman Clyde. Following his defeat, Dargeon became fixated on strengthening himself. Sure of his victory, he challenged Clyde to a rematch, only to suffer defeat once more. Bystanders demanded he finish Dargeon off, but Clyde quieted them before congratulating his fallen foe on a fight well fought. Dargeon said nothing, but something within had changed.",
                evo5: "Encountering pure strength, as opposed to violence used only to subdue others, led Dargeon to greater heights. When he encountered Clyde up against the wall and surrounded by Demons, he realized the flames of vengeance within his heart were gone. With a flame Dragon behind him and hordes of Demons readying to strike, you'd think Clyde was finished, but with a roar, Dargeon unleashed a final desperate attack to save him.",
                evoawk: "There exists a legend in Famelle of an unlikely savior: a flame-wrapped Dragon with the majesty of a war god. He who once ravaged landand sky attained true enlightenment after his second duel with a master swordsman. Later, when the swordsman faced peril in the Ancient War, the Dragon appeared to fight alongside him out of respect for his strength as well as his own pride. Once the vast army was repelled, it was said he promised to duel the swordsman once again before soaring back into the endless blue."
            }
    },
    { //Herck - Complete
        id: 100576212,
        name: "Herck",
        rank: "F",
        attribute: "Water",
        type: "Giant",
        image: 
            {
                detail3: "/db/Units/Detail/unit_detail_100573212.png",
                detail4: "/db/Units/Detail/unit_detail_100574212.png",
                detail5: "/db/Units/Detail/unit_detail_100575212.png",
                detailawk: "/db/Units/Detail/unit_detail_100576212.png",
                thumb3: "/db/Units/Thumbnail/unit_thumbnail_100573212.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_100574212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_100575212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_100576212.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7128,
                hpplus: 999,
                atk: 2376,
                atkplus: 366,
                def: 2609,
                defplus: 401
            },
        skillset: 
            {
                skill: "8s CT - 1800% Water DMG (PHY). Chance (10%) to inflict Freeze.",
                skillbreak: 2100,
                arts: "15000% Water DMG (MAG). For 15s, increase all Water Ally DMG RES by 30%",
                artsbreak: 3100,
                truearts: "40000% Water DMG (MAG). For 15s, all Allies' attacks have chance to inflict Freeze",
                trueartsbreak: 7000
            },
        passive: 
            {
                ability1: "Increase own DMG to Spirit Enemy by 80%",
                ability2: "Increase own DMG to Human Enemy by 80%",
                ability3: "Increase own DMG by 80% when own HP is over 50%. (Does not stack)"
            },
        evolution: 
            {
                evo34: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_water2.png",
                    mat1amt: 5,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_earth2.png",
                    mat2amt: 1
                },
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_water3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat3amt: 4,
                    hover3: "Palamicia",
                    mat4: "/db/Icons/dungeon_drops/fragValzandeth.png",
                    mat4amt: 4,
                    hover4: "Valzandeth",
                    mat5: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat5amt: 3,
                    hover5: "Freydal"
                }
            },
        dream: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragFlogassa.png`,
                        mat3amt: 2,
                        hover3: `Flogassa`
                    },
                    passive: {
                        ability1: `Increase all allies' Fire RES by 20% (Does not stack with passives with the same name).`
                    }
                },
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragGeoZagnus.png`,
                        mat3amt: 2,
                        hover3: `Geo Zagnus`
                    },
                    passive: {
                        ability1: `Increase Break Power against Fire enemies by 70%.`
                    }
                },
            },
        lore: 
            {   
                evo3: "The king of water-dwelling Giants. Herck rarely makes contact with surface Giants, quietly ruling within the sea. Harm his subjects, however, and he's liable to bring down a few ships in a fit of rage. He observed from afar when the Great War began, but eventually accepted a request to lend the Giants' strength to the fight. Through his dominion of the seas, he participated in many fierce battles with the Sea Dragons.",
                evo4: "The king of rare water-dwelling Giants, Herck shared dominion with the Sea Dragons prior to the start of the Great War. The two had a relationship that surpassed race, and even Herck's grandfather was a Sea Dragon. His body had dragon features, which were seen as natural and respected at the time, and a source of great power for Herck, but the war pit the two races against each other.",
                evo5: "The king of the rarely seen Sea Giants, Herck followed the request to fight alongside the other Giants, and joined the Demon forces. The Sea Giants, always friends to his people, joined the Gods, but he turned his spear against them without hesitation. Though this drew the hatred of the Sea Dragons, the fear they felt towards him proved tactically useful. Damage to both sides was minimal, but scars remained...",
                evoawk: "Grand king of the rare Sea Giants, who ruled the seas during the Ancient War. He had good reason for his unrelenting attacks on his mother's relatives, the Sea Dragons. He wished only to minimize harm to both races. When he sensed the Ancient War would grow only longer and more cruel, his frenzied attack served to restrain the Sea Dragons, and protect his people from further battle. History hides his true intent, though he regrets nothing."
            }
    },
    { //Valhalla - Complete
        id: 100586312,
        name: "Valhalla",
        rank: "B-",
        attribute: "Earth",
        type: "Spirit",
        image: 
            {
                detail3: "/db/Units/Detail/unit_detail_100583312.png",
                detail4: "/db/Units/Detail/unit_detail_100584312.png",
                detail5: "/db/Units/Detail/unit_detail_100585312.png",
                detailawk: "/db/Units/Detail/unit_detail_100586312.png",
                thumb3: "/db/Units/Thumbnail/unit_thumbnail_100583312.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_100584312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_100585312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_100586312.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7068,
                hpplus: 999,
                atk: 2016,
                atkplus: 312,
                def: 2772,
                defplus: 425
            },
        skillset: 
            {
                skill: "8s CT - 1300% Earth DMG (MAG). For 5s, increase own Normal Attack DMG by 20.",
                skillbreak: 600,
                arts: "For 20s, Heal all Ally HP by 200/s and increase all Allies' Skill CT REC by 75%",
                artsbreak: 0,
                truearts: "25000% Earth DMG (MAG). For 15s, Heal all Allies' HP by 5%/s and increase all Allies' DMG RES by 10%. If Ally is Earth, increase DMG RES by 20% instead",
                trueartsbreak: 1900
            },
        passive: 
            {
                ability1: "Increase own Earth RES by 35%",
                ability2: "Increase all Ally Poison RES by 50%",
                ability3: "Increase all Ally DMG RES by 10% when near death"
            },
        evolution: 
            {
                evo34: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_earth3.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_earth2.png",
                    mat2amt: 5
                },
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_earth3.png",
                    mat2amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat3amt: 2,
                    hover3: "Elpneumas",
                }
            },
        dream: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragFreydal.png`,
                        mat3amt: 2,
                        hover3: `Freydal`
                    },
                    passive: {
                        ability1: `Increase all allies' Water RES by 20% (Does not stack with passives with the same name).`
                    }
                },
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragElpneumas.png`,
                        mat3amt: 2,
                        hover3: `Elpneumas`
                    },
                    passive: {
                        ability1: `Increase own HP Recovery Amount by 20% (excluding Absorption attacks).`,
                        ability2: `Increase own Arts gauge by 2/s.`
                    }
                },
            },
        lore: 
            {   
                evo3: "A divine tree that holds the powers of creation and rebirth. Prior to the Great War, a land feud between spirits in Isliid's forests led to the area being razed to the ground. Filled with anguish over the self-inflicted loss of their home, the Spirits offered a prayer to rejuvenate the forest. Hearing this prayer, it is said that Valhalla appeared and restored the forest, which served as a reminder not to repeat the mistakes of the past.",
                evo4: "A divine tree with the powers of creation and rebirth. The Great War's battles rended the continents of Raktehelm in two, leading to a natural increase in prayers toward Valhalla. However, few firsthand accounts of such behavior remain. Some despaired, thinking Valhalla had abandoned the eight races after their countless mistakes, but current theories suggest that is was merely preparing its true power for the devastation to come.",
                evo5: "A divine tree representing the world's will. Toward the end of the Great War, with the once-beautiful world of Raktehelm a mere shadow of its former self, Valhalla appeared at last in the skies over a raging battle and released the totality of its power. The world was suffused in a radiant light. the light eventually faded, the war eventually ended. And the survivors still had the earth, whose verdant glory returned, slowly but surely.",
                evoawk: "A divine tree from another world with the overwhelming power to create, dominate and destroy life. After quelling the horros that have plagued the continent, this divine tree suddenly vanished with a white flash to spend eternity somewhere far far away in an unknown land. This divine tree has many beings living upon its exuberant treetops, including the seeds thought to have been destroyed ages ago. When the divine tree annihilated all that life that dwelled in this realm, as if being wished upon the stars, it is thought that such destruction sparked new life to be brought forth."
            }
    },
    { //EDEN-typeΩ - Complete
        id: 100596412,
        name: "EDEN-typeΩ",
        rank: "B-",
        attribute: "Light",
        type: "Machine",
        image: 
            {
                detail3: "/db/Units/Detail/unit_detail_100593412.png",
                detail4: "/db/Units/Detail/unit_detail_100594412.png",
                detail5: "/db/Units/Detail/unit_detail_100595412.png",
                detailawk: "/db/Units/Detail/unit_detail_100596412.png",
                thumb3: "/db/Units/Thumbnail/unit_thumbnail_100593412.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_100594412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_100595412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_100596412.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/healLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6930,
                hpplus: 999,
                atk: 2304,
                atkplus: 335,
                def: 2138,
                defplus: 330
            },
        skillset: 
            {
                skill: "10s CT - 1400% Light DMG (MAG). Chance to inflict Seal",
                skillbreak: 900,
                arts: "12000% Light DMG (PHY). For 25s, Allies gain a DMG barrier that negates 4000 DMG.",
                artsbreak: 1400,
                truearts: "For 30s, Allies gain a DMG barrier that negates 4000 DMG. Additionally, increase strength of Barrier by [2000 × Level of Wave Armor]. If Wave Armor is at Max Level: 50000% Light DMG (PHY). For 30s, Allies gain a DMG barrier that negates 4000 DMG. Additionally, increase strength of Barrier by [2000 × Level of Wave Armor].",
                trueartsbreak: 1800
            },
        passive: 
            {
                ability1: "Increase own Paralysis RES by 60%",
                ability2: "Increase own Poison RES by 60%",
                ability3: "After using True Arts, increase Wave Armor level by 1 (Max 3)"
            },
        evolution: 
            {
                evo34: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_fire2.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_water2.png",
                    mat3amt: 2,
                    mat4: "/db/Icons/dungeon_drops/material_thumbnail_earth2.png",
                    mat4amt: 2,
                    mat5: "/db/Icons/dungeon_drops/material_thumbnail_dark2.png",
                    mat5amt: 2
                },
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_fire3.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_water3.png",
                    mat3amt: 2,
                    mat4: "/db/Icons/dungeon_drops/material_thumbnail_earth3.png",
                    mat4amt: 2,
                    mat5: "/db/Icons/dungeon_drops/material_thumbnail_dark3.png",
                    mat5amt: 2
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 2,
                    hover3: "Fosradius"
                }
            },
        dream: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                        mat3amt: 2,
                        hover3: "Ifrit",
                    },
                    passive: {
                        ability1: `Increase own Arts gauge by 2/s.`,
                        ability2: `Increase own BE Output by 10%.`
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragFosradius.png`,
                        mat3amt: 2,
                        hover3: `Fosradius`
                    },
                    passive: {
                        ability1: `Increase own BREAK Power by 60% against Dark enemies.`,
                        ability2: `Increase all allies' Accuracy rate by 20%.`
                    }
            }
        },
        lore: 
            {   
                evo3: "A mechanical lifeform created in another world. After a long journey. EDEN finally arrived in the depths of a lush. green forest. Beginning its investigation of this new world, he soon detected the presence of unknown lifeforms, who turned out to be the small fairies of the forest. Confirming their lack of hostility. Eden introduced itself and explained his goal: to save his world from utter annihilation.",
                evo4: "A mechanical lifeform from another world. EDEN worked with the fairies he met to find some way to save his world, but unfortunately stumbled into Demon territory. To protect the fairies, he disabled the limiters on his functions, unleashing his original power in the process. Though the Demons were defeated, he had exhausted his energy far faster than expected. His time remaining grew shorter by the moment.",
                evo5: "With his functions close to ceasing, the worried fairies gave EDEN some of their secret medicine, which also happened to be the substance he was searching for to save his world. Thanking him for being their friend, the fairies gave him all of their medicine. With the last of his power, he transformed for the long-distance jump back to his world. Even now, the legend of these fairies who saved the metal traveler from another world persists.",
                evoawk: "The metal traveler, EDEN, succeeded in his quest to save his world from utter destruction. With the power of a particular secret medicine, he managed to rid his world of the pollution that plagued it and return it to its verdant glory. And so it is thought that the ark of steel had finally fulfilled his duty. However, to send the benevolent fairies home safely, EDEN set sail once again off into the stars. Legend has it that the stars weren't the only thing shining that night."
            }
    },
    { //Abaddon - Complete
        id: 100606512,
        name: "Abaddon",
        rank: "F",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail3: "/db/Units/Detail/unit_detail_100603512.png",
                detail4: "/db/Units/Detail/unit_detail_100604512.png",
                detail5: "/db/Units/Detail/unit_detail_100605512.png",
                detailawk: "/db/Units/Detail/unit_detail_100606512.png",
                thumb3: "/db/Units/Thumbnail/unit_thumbnail_100603512.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_100604512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_100605512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_100606512.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7140,
                hpplus: 999,
                atk: 2852,
                atkplus: 437,
                def: 2008,
                defplus: 311
            },
        skillset: 
            {
                skill: "8s CT - 1800% Dark DMG (MAG). 20% chance to inflict Blind for 5s.",
                skillbreak: 700,
                arts: "11000% Dark DMG (MAG). For 20s, reduce enemies' ATK by 30%.",
                artsbreak: 1600,
                truearts: "55000% Dark DMG (MAG). For 15s, increase own DMG against God and Demon enemies by 200%.",
                trueartsbreak: 2300
            },
        passive: 
            {
                ability1: "Increase own DMG to Demon Enemy by 100%",
                ability2: "Increase own DMG to God Enemy by 100%"
            },
        evolution: 
            {
                evo34: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_dark3.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_fire3.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_dark2",
                    mat3amt: 5
                },
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_fire4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_dark3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat3amt: 2,
                    hover3: "Oldeus"
                }
            },
            dream: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                        mat3amt: 2,
                        hover3: "Geo Zagnus",
                    },
                    passive: {
                        ability1: `Increase own DMG against enemies under 30% HP by 50%.`,
                        ability2: `Increase own Arts by 3/s.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                        mat3amt: 2,
                        hover3: "Ragsherum Phantom",
                    },
                    passive: {
                        ability1: `When own HP is 80% or above, increase own CRI rate by 100%.`,
                        ability2: `Increase own Accuracy rate by 30%.`
                    }
                }
            },
        lore: 
            {   
                evo3: "A mad knight spoken of in Demon legend. Records from just before the start of the Great War speak of a masked knight attacking Demon settlements. The Demon military moved to dispatch the criminal, but the killings ended before they were able to ascertain his whereabouts. Rumors began to spread that the mad knight was a new military weapon or just a conspiracy made up by other races. One thing was certain: he was far stronger than your average Demon.",
                evo4: "A mad knight spoken of in Demon legend. Just as old rumors began to fade, another incident had happened. The masked knight attacked the Demon army. Though thrown into chaos, the military beat back the knight with the help of a knight of their own, who described the attacker as a threat, certainly not a Demon, and questionably a living thing. Soon afterward, the masked knight vanished once more.",
                evo5: "A mad knight spoken of in Demon legend. Rumors of the masked knight died down after the tumult of the Great War had begun, but he woudl appear once more at the war's end. After falling into a trap, the Demon platoon was about to meet its end. Then suddenly, the knight appeared and annihilated the encroaching Gods. The Demons rejoiced, but the knight soon turned his sword on them, and in a ghostly voice, his armor said it would devour all their souls.",
                evoawk: "A masked knight spoken of in Demon legend. After countless massacres during the Great War, he had become known as the mad knight. Even those who had survived the bloodshed were engulfed in the insanity of his presence. No one knows where he is now, but if the words of his armor ring true, he will continue to plague the battlefield in search of souls to devour."
            }
    },
    { //Ragna - Complete 
        id: 100616112,
        name: "Ragna",
        rank: "F",
        attribute: "Fire",
        type: "God",
        image: 
            {
                detail3: "/db/Units/Detail/unit_detail_100613112.png",
                detail4: "/db/Units/Detail/unit_detail_100614112.png",
                detail5: "/db/Units/Detail/unit_detail_100615112.png",
                detailawk: "/db/Units/Detail/unit_detail_100616112.png",
                thumb3: "/db/Units/Thumbnail/unit_thumbnail_100613112.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_100614112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_100615112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_100616112.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6300,
                hpplus: 955,
                atk: 3060,
                atkplus: 469,
                def: 2304,
                defplus: 355
            },
        skillset: 
            {
                skill: "8s CT - 1800% Fire DMG (PHY). 10% chance to inflict Burn for 5s.",
                skillbreak: 2500,
                arts: "17000% Fire DMG (PHY). For 15s, increase Allies' DMG by 30% and Fire Allies' DMG by an additional 30%.",
                artsbreak: 3000,
                truearts: "45000% Fire DMG (PHY). For 20s, increase Allies' DMG and Break PWR by 50%, and Fire Allies' DMG and Break PWR by an additional 30%.",
                trueartsbreak: 6000
            },
        passive: 
            {
                ability1: "Increase Paralysis RES by 80%.",
                ability2: "Increase Blind RES by 80%.",
                ability3: "Increase DMG to Dark Enemies by 20%. Increase Dark RES by 10%.",
                ability4: "After using both True Arts and Arts, activate Transcendental Enhancement for 30s. Faint self for 12s after Transcendental Enhancement is deactivated. Transcendental Enhancement: Increase own Movement Speed, Skill CT REC, Equipment CT REC, DMG and Break PWR by 100%."
            }, 
        evolution: 
            {
                evo34: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_fire3.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_fire2.png",
                    mat2amt: 5
                },
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_fire4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_fire3.png",
                    mat2amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                    mat3amt: 4,
                    hover3: "Degilmagna",
                    mat4: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat4amt: 4,
                    hover4: "Ifrit",
                    mat5: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat5amt: 5,
                    hover5: "Ragsherum Phantom"
                }
            },
            dream: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                        mat3amt: 2,
                        hover3: "Flogassa",
                    },
                    passive: {
                        ability1: `Increase own Break Power against Earth enemies by 50%.`,
                        ability2: `Increase own Equip CT Speed by 10%.`
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                        mat3amt: 2,
                        hover3: "Fosraidus",
                    },
                    passive: {
                        ability1: `Increase own Arts by 200 at start of quest.`,
                        ability2: `Increase God allies' Arts by 1/s. Does not stack with Abilities of the same name.`
                    }
                }
            },
        trueweapon: 
            {
                name: "Day Break",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_103601.png",
                skill: "55s CT - 6500% Fire DMG (PHY). Increase own Arts by 80 if user is near-death.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Ragna, increase DMG by 20% and increase Status RES (except Faint) by 100%.",
                        ability2: "Increase DMG to Demon Enemies by 50%."
                    }
            },
        lore: 
            {   
                evo3: "A God knight who threw his lot in with Humans. Originally a high-ranking knight among the Gods, he began a journey in search of strong foes to improve himself further. As he witnessed Humans dueling each other, he grew intrigued, the passion of people living to their truest potential coming through in the fights between heroes who would one day be known as Blade Masters. He realized what he lacked. And this only made him stronger.",
                evo4: "A knight, born among the Gods, who chose to fight for Humans. Though Ragna observed many duels between Humans, he never participated. People only grew aware of his existence and power sometime after the beginning of the great war. Supposedly, he took up the sword to protect innocent children and the elderly who came under attack, telling those responsible not to 'spoil his fun' - words remembered even now.",
                evo5: "A knight, born among the Gods, who threw his lot in with Humans. During the Great War, Ragna aligned himself with no country, and continued travelling the world to help those in need. He turned his blade not just against Demons, but anyone who dared to harm the weak, inspiring fear in the strong and courage in the oppressed. Always a wellspring of hope, he never offered his name, leaving it blank from the pages of history.",
                evoawk: "A knight of the Gods whose name lives on only as a word of hope. Hailed as a savior in the Ancient War, he was in truth a brave protector of the weak and warrior for justice. And yet, for non-Humans, he was a figure of fear whose name is spoken even today in hushed tones. Today in Raktehelm, none alive know that the mantra of Ragna, spoken in times of deepest distress, is his true name."
            }
    },
    { //Yomi - Complete
        id: 100626212,
        name: "Yomi",
        rank: "F",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail3: "/db/Units/Detail/unit_detail_100623212.png",
                detail4: "/db/Units/Detail/unit_detail_100624212.png",
                detail5: "/db/Units/Detail/unit_detail_100625212.png",
                detailawk: "/db/Units/Detail/unit_detail_100626212.png",
                thumb3: "/db/Units/Thumbnail/unit_thumbnail_100623212.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_100624212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_100625212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_100626212.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6708,
                hpplus: 999,
                atk: 2449,
                atkplus: 377,
                def: 2074,
                defplus: 321 
            },
        skillset: 
            {
                skill: "8s CT - 2000% Water DMG (PHY). Increase own Arts gauge by 30.",
                skillbreak: 1700,
                arts: "20000% Water DMG (PHY). For 20s, increase all Allies' ATK by 50% and Water allies' ATK by an additional 50%.",
                artsbreak: 2000,
                truearts: "55000% Water DMG (PHY). If this True Arts hits a Broken Enemy, increase own Arts by 100 (only once per True Arts activation) + For 10s, increase own Critical Rate by 100%.",
                trueartsbreak: 3600
            },
        passive: 
            {
                ability1: "Increase DMG to Dragon Enemies by 100%.",
                ability2: "Increase evasion by 35% when near-death.",
                ability3: "Increase DMG to Enemies in Break by 30%."
            }, 
        evolution: 
            {
                evo34: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_water3.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_water2.png",
                    mat2amt: 5
                },
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_water3.png",
                    mat2amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat3amt: 1,
                    hover3: "Radachne",
                    mat4: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat4amt: 1,
                    hover4: "Palamicia",
                    mat5: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat5amt: 2,
                    hover5: "Freydal"
                }
            },
        dream: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        hover2: `Frostsprite Soulgem`,
                        mat2amt: 50,
                        mat3: `/db/Icons/dungeon_drops/fragPalamicia.png`,
                        mat3amt: 2,
                        hover3: `Palamicia`
                    },
                    passive: {
                        ability1: `Increase own CRI DMG by 80%.`,
                        ability2: `Increase own Arts gauge by 1/s.`
                    }
                },
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragGoldola.png`,
                        mat3amt: 2,
                        hover3: `Goldola`
                    },
                    passive: {
                        ability1: `Increase own DMG against Giant Bosses by 60%.`,
                        ability2: `Increase own Poison RES by 100%.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragMavroskia.png`,
                        mat3amt: 2,
                        hover3: `Mavroskia`
                    },
                    passive: {
                        ability1: `Increase all allies' Break Power by 20%.`,
                        ability2: `Increase own BE Output by 10%.`
                    }
            }
        },
        lore: 
            {   
                evo3: "An otherworldly swordswoman summoned to Raktehelm by the Gods at the end of the great war. Though Human in appearance, her strength was greater than the average God and she slew countless Demons. Though nothing is written about what she fought for, rumor suggests that she made a deal with the Gods: in return for her power, the Gods used their strength to defeat a country invading Yomi's homeland.",
                evo4: "A swordswoman summoned to Raktehelm from another world. Prior to her summoning, it is said that while she was always an expert with the sword, she never had any special powers. In Raktehelm, however, she overflowed with magical energies, and water surrounded her always. protecting her from harm and forming into blades to attack her Demonic foes. And they're still telling stories of how she cut Afradohl's wings...",
                evo5: "A swordswoman summoned to Raktehelm from another world. Though she followed orders to fight against the Demons, a fight she had against a certain scythe-wielding Demoness changed her when she realized that her opponent had once been Human, and that the Demons altered people, turning them into weapons. This only made her magic power stronger, and steeled her resolved to defeat the enemies of Humans.",
                evoawk: "A mysterious swordswoman summoned from another world. She once valued loyalty above all, but eventually, she came to doubt her God masters as much as the Demons. Ironically, the more she doubted, the sharper her blade cut. But soon, she will find that this power she sought to defend her home will bring even greater tragedy and hatred. And so she sets out on a journey to discover how to destroy forever her true enemy; War."
            }
    },
    { //Vaid - Complete
        id: 100636312,
        name: "Vaid",
        rank: "F",
        attribute: "Earth",
        type: "Machine",
        image: 
            {
                detail3: "/db/Units/Detail/unit_detail_100633312.png",
                detail4: "/db/Units/Detail/unit_detail_100634312.png",
                detail5: "/db/Units/Detail/unit_detail_100635312.png",
                detailawk: "/db/Units/Detail/unit_detail_100636312.png",
                thumb3: "/db/Units/Thumbnail/unit_thumbnail_100633312.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_100634312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_100635312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_100636312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/healLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 8409,
                hpplus: 999,
                atk: 2521,
                atkplus: 388,
                def: 1945,
                defplus: 301
            },
        skillset: 
            {
                skill: "9s CT - 1600% Earth DMG (PHY). For 9s, reduce Enemies' PHY RES by 10%, chance of reducing Enemies' Break RES by 15%.",
                skillbreak: 1600,
                arts: "14000% Earth DMG (PHY). For 20s, increase Earth Allies' Break Power by 35%.",
                artsbreak: 2500,
                truearts: "20000% Earth DMG (PHY). Increase own Arts by 80. When used against Enemies in BREAK, increase Earth Allies' Arts by 20 + For 20s, increase Allies' DMG by 100%.",
                trueartsbreak: 6000
            },
        passive: 
            {
                ability1: "Increase Arts by 1/s.",
                ability2: "Increase DMG to Beast Enemies by 80%."
            }, 
        evolution: 
            {
                evo34: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_earth3.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_earth2.png",
                    mat3amt: 5
                },
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_earth3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat3amt: 2,
                    hover3: "Elpneumas",
                }
            },
        dream: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                        mat3amt: 2,
                        hover3: "Flogassa",
                    },
                    passive: {
                        ability1: `When enemies' HP is 30% or less, increase own DMG by 120%.`,
                        ability2: `Reduce own Water RES by 20%.`
                    }
                },
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragTonetaurus.png`,
                        mat3amt: 2,
                        hover3: `Tonetaurus`
                    },
                    passive: {
                        ability1: `Increase all Machine allies' Arts gauge by 2/s.`,
                        ability2: `Increase own Break Power by 16%.`
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragWilkes.png`,
                        mat3amt: 2,
                        hover3: `Wilkes`
                    },
                    passive: {
                        ability1: `Increase all allies' Max HP by 10%.`,
                        ability2: `Increase all allies' MAG RES by 10%.`
                    }
            }
        },
        lore: 
            {   
                evo3: "A Machine weapon that fought during the Ancient War. Vaid absorbs the countless spirits that float in the atmosphere to provide energy for flight and weapons. The main body is the small module in the centre which controls the other, larger body, but mysteries about its construction abound. The name 'Vaid' refers to the apparatus as a whole.",
                evo4: "A Machine weapon that fought during the Ancient War. Vaid was developed in secret to oppose the Gods. The large module is designed to excel in combat, while the smaller module is designed to act as the brain. Working together, they obliterated countless foes of every race. Both units have wills, but the large module is unable to move - it can only speak.",
                evo5: "A machine weapon that fought during the great war. The small module's will won many battles, during which the large module rarely objected. Only once did the modules differ in opinion. After discovering an enemy Beast commander, the small module released all weapons and unleashed a devastating barrage. The commander survived, but the large module refused to attack. 'That is your foe. Not mine,' it said.",
                evoawk: "A Machine weapon that underwent self-evolution after absorbing many spirits. It crushed the strongest of the Gods' soldiers, but the Beasts proved to be formidable threats. When one of them defended against an all-out barrage, its self-defense system sounded the alarm to retreat, but the soldier's will programmed into its core demanded it bring the battle to a conclusion."
            }
    },
    { //Elfalla - Complete (+ Dream Evo)
        id: 100646412,
        name: "Elfalla",
        rank: "C",
        attribute: "Light",
        type: "Beast",
        image: 
            {
                detail3: "/db/Units/Detail/unit_detail_100643412.png",
                detail4: "/db/Units/Detail/unit_detail_100644412.png",
                detail5: "/db/Units/Detail/unit_detail_100645412.png",
                detailawk: "/db/Units/Detail/unit_detail_100646412.png",
                thumb3: "/db/Units/Thumbnail/unit_thumbnail_100643412.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_100644412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_100645412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_100646412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6708,
                hpplus: 915,
                atk: 2376,
                atkplus: 439,
                def: 2614,
                defplus: 411
            },
        skillset: 
            {
                skill: "9s CT - 1600% Light DMG (PHY). 10% chance to inflict Paralysis for 3s.",
                skillbreak: 1100,
                arts: "12000% Light DMG (PHY). 80% chance to inflict Paralysis for 3s + For 15s, increase Light Allies' Break PWR by 30%.",
                artsbreak: 1600,
                truearts: "27000% Light DMG (PHY). Increase own Arts by 60 + For 20s, reduce Enemies' Paralysis RES by 50%.",
                trueartsbreak: 2900
            },
        skillsetJP: 
            {
                skill: "9s CT - 1600% Light DMG (PHY). 10% chance to inflict Paralysis for 3s.",
                skillbreak: 1100,
                arts: "18000% Light DMG (PHY). 100% chance to inflict Paralysis for 4s + For 15s, increase Light Allies' Break PWR by 30%.",
                artsbreak: 1600,
                truearts: "37000% Light DMG (PHY). Increase own Arts by 60 + For 30s, reduce Enemies' Paralysis RES by 50%.",
                trueartsbreak: 2900
            },
        passive: 
            {
                ability1: "Increase Paralysis RES by 60%.",
                ability2: "Increase Dark RES by 15%."
            }, 
        evolution: 
            {
                evo34: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light2.png",
                    mat2amt: 5
                },
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat2amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 2,
                    hover3: "Fosradius",
                }
            },
            dreamJP: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                        mat3amt: 2,
                        hover3: "Flogassa",
                    },
                    passive: {
                        ability1: `Increase own Accuracy by 30%.`,
                        ability2: `Increase own Break Power against Dark enemies by 80%.
                        `
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                        mat3amt: 2,
                        hover3: "Fosradius",
                    },
                    passive: {
                        ability1: `Increase all allies' Paralysis RES by 30%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase Beast allies' Arts by 3/s. Does not stack with Abilities of the same name.`
                    }
                }
            },
        lore: 
            {   
                evo3: "A hero of the Beasts later known as the Azure Saint. Much hope was placed in him from an early age... perhaps because he defeated a lion with his bare hands at age 10. Belying his fierce appearance was a surprising warmth, and his gentle smile provided inspiration for many. One day, word arrived of a Machine attack on a settlement in a nearby region. Unbeknownst to him, one of these machines would influence the rest of his life...",
                evo4: "A Beast hero later referred to as the Azure Saint. After the start of the Great War, the Beasts dedicated all of their forces to helping the Gods. Elfalla, too. fought on the front lines as a warrior. achieving many notable battlefield accomplishments, with a silent grace and a sense of propriety that earned him respect - and his second name - from enemy and ally alike. All the while, a Machine weapon continued watching his actions...",
                evo5: "A Beast hero renowned as the Azure Saint, Elfalla attempted to put a stop to a machine weapon of mass destruction, only for the weapon to inform him of a great sin he had committed during an earlier mission. Barely managing to escape, Elfalla found his actions clouded, but was stirred to act once more upon seeing his fellow soldiers doing their best to repay him. He would stop at nothing to stop the rampaging weapon.",
                evoawk: "A hero praised as the Empyreal Beast. After realising his mistake in fleeing from the Machine weapon, he rushed back to the battlefield. Though afraid he was too late to stop the inevitable slaughter, he arrived to see his brothers-in-arms fighting against the menace, empowered by the belief that he would return. That was the inspiration he needed to shed his past regrets and face his destined foe."
            }
    },
    { //Lily - Complete (+ JP Dream Evo)
        id: 100656512,
        name: "Lily",
        rank: "F",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail3: "/db/Units/Detail/unit_detail_100653512.png",
                detail4: "/db/Units/Detail/unit_detail_100654512.png",
                detail5: "/db/Units/Detail/unit_detail_100655512.png",
                detailawk: "/db/Units/Detail/unit_detail_100656512.png",
                thumb3: "/db/Units/Thumbnail/unit_thumbnail_100653512.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_100654512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_100655512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_100656512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6708,
                hpplus: 999,
                atk: 2976,
                atkplus: 456,
                def: 1944,
                defplus: 307
            },
        skillset: 
            {
                skill: "7s CT - 2500% Dark DMG (PHY). DMG and CRI rate of this Skill increases the lower the Enemies' HP is.",
                skillbreak: 1150,
                arts: "11800% Dark DMG (PHY). DMG and Break PWR of this Arts increases the higher the Enemies' HP is.",
                artsbreak: 2800,
                truearts: "23800% Dark DMG (PHY). For 12s, increase own CRI rate by 100% and reduce Enemies' Movement Speed by 30%.",
                trueartsbreak: 4500
            },
        skillsetJP: 
            {
                skill: "7s CT - 2500% Dark DMG (PHY). For 8s, increase own DMG and CRI rate the lower the Enemies' HP is (MAX: 200%).",
                skillbreak: 1200,
                arts: "27000% Dark DMG (PHY). DMG and Break PWR of this Arts increases the higher the Enemies' HP is (MAX: 300%).",
                artsbreak: 2800,
                truearts: "50000% Dark DMG (PHY). For 30s, increase own CRI rate by 100% and reduce Enemies' Movement Speed by 30%. ",
                trueartsbreak: 4500
            },
        passive: 
            {
                ability1: "When near-death, heal own HP by 4% of DMG dealt.",
                ability2: "Increase Curse RES by 100%.",
                ability3: "Increase Dark RES by 25%.",
                ability4: "Increase DMG by 10% and increase Arts by 2/s."
            }, 
        evolution: 
            {
                evo23: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_dark2.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_dark1.png",
                    mat2amt: 5
                },
                evo34: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_dark3.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_dark2.png",
                    mat2amt: 5
                },
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_dark3.png",
                    mat2amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 3,
                    hover3: "RagsherumPhantom",
                    mat4: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat4amt: 4,
                    hover4: "Oldeus",
                    mat5: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat5amt: 2,
                    hover5: "Flogassa"
                }
            },
            dreamJP: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                        mat3amt: 2,
                        hover3: "Palamicia",
                    },
                    passive: {
                        ability1: `Increase own Arts by 3/s.`,
                        ability2: `Increase own Freeze RES by 100%.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragOldeus.png",
                        mat3amt: 2,
                        hover3: "Oldeus",
                    },
                    passive: {
                        ability1: `Increase all allies' Dark DMG by 20%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own CRI DMG by 80%.`
                    }
                }
            },  
        lore: 
            {   
                evo3: "The Gods destroyed a Human village suspected of rebelling, and she was a survivor. At six years of age, she left the village to get medicine for her mother, and came back to find only devastation. She was brought in by Demons, and agreed to undergo modification experiments so that she might exact revenge on the Gods. Time passed. By 16, Lily had grown into a Demonic weapon feared by the Gods.",
                evo4: "A murderous weapon that fought for the Demons during the Great War. Lily used her enormous scythe to cut down countless Gods. One day, a swordswoman from another world appeared before her, fighting for the Gods. During the fight, the swordswoman tried to speak to Lily again and again, but to no avail. The swordswoman unleashed hidden magical energy shatter Lily's scythe, promising to save her, someday, somehow.",
                evo5: "A murderous weapon that fought for the Demons during the Great War. After losing to the otherworldly swordswoman, Lily sought even greater power, and used forbidden Demonic arts to form a pact with the reaper. With unimaginable strength and a new scythe, Lily returned to the battlefield... but her mind was slowly being consumed by the reaper. Those who survived described Lily as the reaper incarnate, desperately searching for her mark.",
                evoawk: "An opponent of the Gods in the Ancient War who was called the Deathbringer of Corpse Mountain. In truth, she was a Human girl who turned her hatred into a continual search for power. Once the Deathdealer had her target, she would pursue it without cease, even to death. Once, when she came again against a swordswoman who had ducked her demonic sickle once before, she took on the demeanor of a death-dealing force of nature to kill her foe. For some reason, the swordswoman merely complained under her breath and readied her sword. History does not record what happened next."
            }
    },
    { //Rayas - Complete, Review done
        id: 101016411,
        name: "Rayas",
        luckrank: "B+",
        attribute: "Light",
        rank: "A",
        type: "Human",
        image: 
            {
                detail2: "/db/Units/Detail/unit_detail_101012411.png",
                detail3: "/db/Units/Detail/unit_detail_101013411.png",
                detail4: "/db/Units/Detail/unit_detail_101014411.png",
                detail5: "/db/Units/Detail/unit_detail_101015411.png",
                detailawk: "/db/Units/Detail/unit_detail_101016411.png",
                thumb2: "/db/Units/Thumbnail/unit_thumbnail_101012411.png",
                thumb3: "/db/Units/Thumbnail/unit_thumbnail_101013411.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101014411.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101015411.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101016411.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 7213,
                hpplus: 999,
                atk: 2851,
                atkplus: 437,
                def: 2319,
                defplus: 357
            },
        skillset: 
            {
                skill: "7s CT - 1800% Light DMG (PHY). Increase own Arts by 10. During Ex-Force: 10s CT - 2000% Light DMG (PHY) Increase own Arts by 30.",
                skillbreak: "1450 (800)",
                arts: "9000% Light DMG (PHY). For 40s, increase Allies' ATK by 50%. During Ex-Force mode: 17000% Light DMG (PHY). For 15s, increase Allies' max HP by 20% and Allies gain a DMG barrier that negates 1000 DMG.",
                artsbreak: "1800 (1200)",
                truearts: "For 60s, enter Ex-Force mode. During Ex-Force mode: 52000% Light DMG (PHY). For 20s, increase Allies' PHY DMG by 120% and increase own Arts by 3/s.",
                trueartsbreak: 1800
            },
        passive: 
            {
                ability1: "Increase Dark RES by 25%.",
                ability2: "Increase DMG to God Enemies by 50%.",
                ability3: "Increase DMG to Demon Enemies by 50%."
            }, 
        evolution: 
            {
                evo23: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light2.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light1.png",
                    mat2amt: 2
                },
                evo34: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light2.png",
                    mat2amt: 5
                },
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_105750.png",
                    mat1amt: 5,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRayas.png",
                    mat3amt: 1,
                    hover3: "Spirit of Adventure",
                }
            },
        trueweapon: 
            {
                true1: 
                {
                    name: "Regracia",
                    slot: "/db/Mines/thumbnail/physLB.png",
                    thumb: "/db/Equipment/Thumbnail/item_thumbnail_103111.png",
                    detail: "/db/Equipment/Detail/item_detail_103111.png",
                    skill: "50s CT - 6600% Light DMG (PHY).",
                    skillbreak: 1600,
                    passive: 
                        {
                            ability1: "When equipped by Rayas, increase DMG by 20%. Does not stack with similar Abilities.",
                            ability2: "Increase Allies' ATK by 15%."
                        }
                },
                true2: 
                {
                    name: "Grand Scale",
                    slot: "/db/Mines/thumbnail/physLB.png",
                    thumb: "/db/Equipment/Thumbnail/item_thumbnail_109521.png",
                    detail: "/db/Equipment/Detail/item_detail_109521.png",
                    skill: "55s CT - 10000% Light DMG (PHY). For 12s, increase own DMG by 60%. If user is Light, increase own ATK by 60%.",
                    skillbreak: 800,
                    passive: 
                        {
                            ability1: "When equipped by Rayas, increase the duration of Ex-Force mode by 30s. Increase Arts by 100 when entering Ex-Force mode.",
                            ability2: "Increase ATK by 10%."
                        }
                }
                
            },
        lore: 
            {   
                evo2: "A new recruit at the Isliid Adventurer's Guild. An orphan from the surrounding area, Rayas has been friends with imperial knight Roy for as long as he can remember. His interest in history and ancient ruins inspired him to become an adventurer, and he dreams of making a never-before-seen historical discovery. His skills with a sword are above average, but Roy often chastises him for a lack of effort.",
                evo3: "An adventurer from the Isliid Adventurer's Guild, Rayas became involved in the battle against the Demons after encountering the amnesiac Iris during a training exercise with Ganan, his guildmaster. While protecting Iris, the power to summon the spirits of ancient heroes awoke within him, allowing him to successfully summon one of the legendary Blade Masters. His abilities with sword and summon will only grow stronger.",
                evo4: "During his travels to defeat the Demons, Rayas encountered the Demon Suppression Corps composed of members from the Great Nations. His old friend Roy, is there as well, and the group's strength proves inspiring enough to further draw out his latent abilities (not that he noticed). Now a part of the Corps, Rayas returns to his journey with expectations of further growth.",
                evo5: "An adventurer journeying with friends to end the Demon menace. Contrary to his great strength, few know of his name and exploits, but even the handpicked warriors he journeys with have their eyes on him. Real experience has sharpened his talent with a sword and he’s managed to summon just Blade Masters, but incredible heroes from all of the eight races. Rayas still wants more, however, and so his journey continues.",
                evoawk: "A member of the Reigrad branch of the Adventurer's Guild. Although his contributions in ending both the Second Battle of Gomarey and the Bamint Princess' uprising earned recognition from key international figures, he is far from a household name. His self-taught bladework is, according to a freind with the imperial army, at major officer level. While journeying through La Riiz to defeat the Ancients, he unlocked the memories of the knight within Grand Scale and thus the blade's true power."
            },
        review:
            {
                skill: `A rather strong skill providing plenty of artgen, especially when in Ex-Force.`,
                arts: `This is a rather interesting Art given how much it's effect changes between modes. Overall though it's still a very strong Art, providing some emergency heals and a small barrier when in EX-Force and a rather long decent ATK buff when not in it, however if you're not in EX-Force your highest priority should be getting him in it.`,
                truearts: `This is where Rayas will truly shine, with a rather sizeable physical damage boost to all allies and some self artgen. Sadly the rather short duration on Ex-Force does hurt his overall potential.`,
                ability1: `Some nice additional DMG res, it'll help quite a bit when needed.`,
                ability2: `Nice DMG boost against Gods`,
                ability3: `Nice DMG boost against Demons.`,
                trueweapon: {
                    true1: {
                        skill: `... That's it?`,
                        ability1: `Decent attack boost, but wasted on a unique passive`,
                        ability2: `Team-wide power boost is decent, but there are better options out there`
                    },
                    true2: {
                        skill: `A rather strong effect, although it's longer CT does limit it a bit for general use it'd work great in nukes with some light collab units in paticular.`,
                        ability1: `The increased duration and Art refund for entering Ex-Force makes this a great boost to Rayas's overall performance.`,
                        ability2: `The small ATK boost is a nice addition to have.`
                    }
                },
                overall: `Ah Rayas, it seems like just a bit ago you were just a joke of a unit, only used as baby's first luck unit if we didn't have a collab going on that gave us one. Now look at you, finally all grown up. 

                In all seriousness, with his Awoken form Rayas is now a real unit, being able to compete with other DPS options like Swick. Although his lack of crit does hurt, he's a solid option. Stages with "clear with Rayas" requires will no longer require you to carry a dead unit. As a luck unit Rayas is still lacking though, needing to enter Ex-Force before he can TA is a very big downside when you just want to luck nuke. Also you know...there's the minor issue of how anyone who is capable of getting him awoken already has a decently developed box so not like he'll ever be used outside of those "clear with Rayas" requirements anyway.`,
                lastupdated: `August 25, 2022`,
                author: `Zanster`
            }
    },
    { //Iris - Complete
        id: 101025411,
        name: "Iris",
        rank: "F",
        attribute: "Light",
        type: "God",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101024411.png",
                detail5: "/db/Units/Detail/unit_detail_101025411.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101024411.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101025411.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 5000,
                hpplus: 400,
                atk: 2000,
                atkplus: 400,
                def: 2000,
                defplus: 400
            },
        skillset: 
            {
                skill: "8s CT - 1650% Light DMG (MAG).",
                skillbreak: 1250,
                arts: "6500% Light DMG (MAG). For 10s, increase Allies' stats by 20%.",
                artsbreak: 2100,
            },
        passive: 
            {
                ability1: "When an Ally dies (except self), revive that Ally with 33% HP. Ability can only be activated once per Quest."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_Moonshine.png",
                    mat1amt: 9,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat3amt: 1,
                    mat4: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat4amt: 5
                }
            },
        trueweapon: 
            {
                name: "Raaz",
                slot: "/db/Mines/thumbnail/defLB.png",
                detail: "/db/Equipment/Detail/item_detail_103131.png",
                skill: "50s CT - For 15s, Allies gain a DMG barrier that negates 1000 DMG, increase Allies' DMG RES by 20% and Status RES (except Faint) by 100%.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Iris, increase Evasion by 50%. Does not stack with Abilities of the same name.",
                        ability2: "Increase Status RES (except Faint) by 30%."
                    }
            },
        lore: 
            {   
                evo4: "A mysterious amnesiac girl, Iris came under attack by monsters outside the Isliidian town of Reigrad and was saved by Rayas. Roy, a member of the imperial knights. tasked Rayas with her protection, perhaps because he knew the Demons were after her. She's aware of her Godly origins. but is still unable to fully control the powers that reside within her.",
                evo5: "A divine girl who has the power to break down the barriers surrounding Gods. Though Iris was unaware she possessed such a rare power, she was discovered by a Demon and held as a research subject for anti-God weaponry. Profound in their cruelty, Iris lost her memories as a result of the experiments, but eventually managed to escape. Her encounter with a young man awakened her true power further.",
            }
    },
    { //Roy (Water) - Complete
        id: 101036211,
        name: "Roy (Water)",
        rank: "C",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101034211.png",
                detail5: "/db/Units/Detail/unit_detail_101035211.png",
                detailawk: "/db/Units/Detail/unit_detail_101036211.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101034211.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101035211.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101036211.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7296,
                hpplus: 999,
                atk: 2722,
                atkplus: 418,
                def: 2266,
                defplus: 349
            },
        skillset: 
            {
                skill: "8s CT - 2150% Water DMG (PHY). During Enemy Arts, increase Critical Rate of this Skill by 50% and Break PWR of this Skill by 70%.",
                skillbreak: 2000,
                arts: "18000% Water DMG (PHY). During Enemy Arts, increase Critical Rate of this Arts by 100% and Break Power of this Arts by 200%.",
                artsbreak: 3500,
                truearts: "45000% Water DMG (PHY). 100% chance to inflict Ultimate Freeze for 6s.",
                trueartsbreak: 6000
            },
        passive: 
            {
                ability1: "Increase Rayas' DMG by 50%.",
                ability2: "Increase Allies' DMG RES by 20%.",
                ability3: "When near-death, heal 60 HP/s."
            },     
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat1amt: 3,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_water3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat3amt: 3,
                    hover3: "Ifrit",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 4,
                    hover4: "Radachne",
                    mat5: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat5amt: 5,
                    hover5: "Freydal"
                }
            },
        dream: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragIfrit.png`,
                        mat3amt: 2,
                        hover3: `Ifrit`
                    },
                    passive: {
                        ability1: `Increase own CRI DMG by 300%.`,
                        ability2: ``
                    }
                },
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        hover2: `Frostsprite Soulgem`,
                        mat2amt: 50,
                        mat3: `/db/Icons/dungeon_drops/fragAerugraxes.png`,
                        mat3amt: 2,
                        hover3: `Aerugraxes`
                    },
                    passive: {
                        ability1: `Increase own Break Power against Fire enemies by 80%.`,
                        ability2: `Increase own Arts gauge by 3/s.`
                    }
                },
            },
        trueweapon: 
            {
                name: "Gaimalse",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_103121.png",
                skill: "60s CT - 6000% Water DMG (PHY). For 10s, increase Allies' ATK by 30%.",
                skillbreak: 2200,
                passive: 
                    {
                        ability1: "When equipped by Roy (Water), increase DMG by 20%.",
                        ability2: "Increase DMG by 10% when near-death."
                    }
            },
        lore: 
            {   
                evo4: "An old friend of Rayas chosen by the Great Nations for its Demon Suppression Corps, Roy is the commander of Fenrir, the thrid unit of the imperial knights. many were opposed to Roy becoming commander, due to his youth and lowborn status, but he attained the position with the support of the Emperor and Empress. He was supposedly tasked with such a dangerous mission by the nobility, who despise him.",
                evo5: "A knight of Isliid traveling with his allies to eliminate the Demon menace, Roy knows the secret concealed in Raktehelm's history, and seeks a world in which all races can live as equals. Though he once attempted to force a way forward, his way of thinking gradually became more flexible as he fought against the Demons with his friends. When light reached his stubborn heart, he was freed in the truest sense of the word, unleashing his true potential.",
                evoawk: "After the mortal combat at Sealed Fortress Gormarey, he has changed his actions. He has been leaving the subhead in charge, taking action on his own, and training even harder than before. For those who know Roy, they see him as if he decided something and is working vigorously towards that. Few know the faces he shows to his best friend. A huge ambition in his heart he will continue become stronger and believe his path he chosen."
            }
    },
    { //Mira (Fire) - Complete
        id: 101046111,
        name: "Mira (Fire)",
        rank: "F",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101044111.png",
                detail5: "/db/Units/Detail/unit_detail_101045111.png",
                detailawk: "/db/Units/Detail/unit_detail_101046111.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101044111.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101045111.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101046111.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6660,
                hpplus: 999,
                atk: 2904,
                atkplus: 445,
                def: 2292,
                defplus: 353
            },
        skillset: 
            {
                skill: "7s CT - 2100% Fire DMG (PHY). For 8s, reduce Enemies' Burn RES by 30%.",
                skillbreak: 2000,
                arts: "16000% Fire DMG (PHY). 80% chance to inflict Burn.",
                artsbreak: 3000,
                truearts: "48000% Fire DMG (PHY). Increase this True Arts' DMG by 100% against Burned Enemies.",
                trueartsbreak: 5000
            },
        passive: 
            {
                ability1: "Increase DMG to Demon Enemies by 70%.",
                ability2: "When this unit dies, revive with 1 HP + For 5s, HP cannot drop below 1, heal HP by 16%/s (80% max HP). Can only be activated once per Quest. If revived by another unit or equipment, Ability is reset.",
                ability3: "When near-death, increase ATK and Break PWR by 30%.",
                ability4: "Increase own Critical Rate & Break Power by 100% against Burned Enemies."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_fire4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_fire3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat3amt: 1,
                    hover3: "Ifrit",
                    mat4: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                    mat4amt: 1,
                    hover4: "Degilmagna",
                    mat5: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat5amt: 1,
                    hover5: "Oldeus"
                }
            },
            dream: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragFlogassa.png`,
                        mat3amt: 2,
                        hover3: `Flogassa`
                    },
                    passive: {
                        ability1: `Increase all Human Allies' Arts by 2/s. Does not stack with Abilities with the same name.`,
                        ability2: ``
                    }
                },
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        hover2: `Frostsprite Soulgem`,
                        mat2amt: 50,
                        mat3: `/db/Icons/dungeon_drops/fragPalamicia.png`,
                        mat3amt: 2,
                        hover3: `Palamicia`
                    },
                    passive: {
                        ability1: `Increase own CRI DMG by 150%.`,
                        ability2: ``
                    }
                }
        },
        trueweapon: 
            {
                name: "Noble Bamint",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_103141.png",
                skill: "70s CT - 8400% Fire DMG (MAG). 70% chance to inflict Burn.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Mira, increase DMG by 20%.",
                        ability2: "Increase DMG to Earth Enemies by 5%."
                    }
            },
        lore: 
            { 
                evo4: "Vice-commander of the Bamint knight order 'Rose Garden' and member of the Demon extermination squad. Though a princess, she chose the life of a knight instead. Mira refuses to mince words and has a strong sense of justice, resulting in her often acting impulsively and ignoring the actions of others. This has made her some enemies, but her willingness to defend the weak has made her friends to spare.",
                evo5: "Third princess of Bamint, who travels with her allies to defeat the Demon menace. She ignored the opinions of Bamint's upper echelons, who didn't want to oppose the Demons, and joined the Demon Suppression Squad on her own. She seems aware of the rumor that they let her join just to get rid of her. She finds it difficult to live true to herself while traveling with others, and will continue forcing her way forward to find a place she can call home.",
                evoawk: "She felt she became stronger ever since the battle at Gormarey, the incident after, and the encounter with numerous people. Her weakness, her goals, what she could only do... these were the things she did not know before. At the same time, she noticed this, she understood that extraordinary possibilities were within her. That enormous power will become the hope of light in this dark world."
            }
    },
    { //Fen (Earth) - Complete
        id: 101056311,
        name: "Fen (Earth)",
        rank: "A",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101054311.png",
                detail5: "/db/Units/Detail/unit_detail_101055311.png",
                detailawk: "/db/Units/Detail/unit_detail_101056311.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101054311.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101055311.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101056311.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 5820,
                hpplus: 883,
                atk: 2642,
                atkplus: 406,
                def: 2257,
                defplus: 348
            },
        skillset: 
            {
                skill: "7s CT - 1850% Earth DMG (PHY). Increase Allies' Arts by 4, heal the Ally with the lowest current HP by 3%.",
                skillbreak: 1150,
                arts: "13000% Earth DMG (PHY). For 20s, increase Allies' Equipment CT REC by 25%.",
                artsbreak: 2200,
                truearts: "21500% Earth DMG (PHY). For 20s, increase Allies' Arts by 3/s, increase Allies' Equipment DMG by 50%.",
                trueartsbreak: 4600
            },
        passive: 
            {
                ability1: "Increase Allies' evasion by 15%. Does not stack with Abilities of the same name.",
                ability2: "Increase DMG to Enemies with 50% or less HP by 20%. Does not stack with Abilities of the same name.",
                ability3: "Increase DMG to Human Enemies by 80%.",
                ability4: "When near-death, increase ATK by 50%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat1amt: 3,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_earth3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat3amt: 3,
                    hover3: "Palamicia",
                    mat4: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat4amt: 4,
                    hover4: "Elpneumas",
                    mat5: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat5amt: 5,
                    hover5: "Goldola"
                }
            },
        trueweapon: 
            {
                name: "Efkeria",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_103781.png",
                skill: "35s CT - 5000% Earth DMG (PHY). Heal own HP by 260 and increase own Arts by 20 each time this attack hits an Enemy (only once per enemy, per usage).",
                skillbreak: 1500,
                passive: 
                    {
                        ability1: "When equipped by Fen (Earth), increase DMG by 20%. When near-death, increase evasion by 15%. Does not stack with Abilities of the same name.",
                        ability2: "Increase DMG to Enemies with 50% or less HP by 15%. Does not stack with Abilities of the same name."
                    }
            },
        lore: 
            {   
                evo4: "Head of General Staff in Gramenia, and member of the Demon Suppression Corps. Fen is a tactical genius, and trusted more than the president of his own country, but his tendency to vocalise harsh opinions has earned him many enemies. His talents made him head of General Staff by his 29th birthday, but the sweeping reforms he later made to the military drew the ire of the top brass. He proposed the Corps and joined it of his own volition.",
                evo5: "The Gramenian head of General Staff who worked to defeat the Demonic threat. After the destruction of the Demon Suppression Corps, Fen returned to Gramenia and acted to protect his position, drawing harsh criticism from his opponents. Knowing the threats that the Demons pose, however, forced Fen to do everything to retain his position. When someone who understands the situation appears, he'll finally have the freedom to command as he sees fit.",
                evoawk: "The current head of General Staff for Gramenia became a skilled commander despite his underprivileged childhood. Having already changed his whole life to achieve power and status, this stern man once again begins to change when he meets an adventurer who offers the same words he heard in his childhood. Now, his enemies defeated and the strength he sought in hand, he begins little by little to gain control over the power sleeping within him, for his own sake and for those who count on him."
            }
    },
    { //Zeorg (Light) - Complete
        id: 101066411,
        name: "Zeorg (Light)",
        rank: "F",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101064411.png",
                detail5: "/db/Units/Detail/unit_detail_101065411.png",
                detailawk: "/db/Units/Detail/unit_detail_101066411.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101064411.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101065411.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101066411.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7212,
                hpplus: 999,
                atk: 3072,
                atkplus: 470,
                def: 1992,
                defplus: 308
            },
        skillset: 
            {
                skill: "9s CT - 2500% Light DMG (PHY). Heal own HP by 100.",
                skillbreak: 1000,
                arts: "15000% Light DMG (PHY). 70% chance to inflict Faint.",
                artsbreak: 1990,
                truearts: "40000% Light DMG (PHY). 100% chance to inflict Faint. Increase own Arts by 70.",
                trueartsbreak: 2900
            },
        passive: 
            {
                ability1: "Increase Allies' Critical Rate by 30% against Enemies in Break.",
                ability2: "Increase DMG to Demon Enemies by 50%.",
                ability3: "Increase DMG to God Enemies by 50%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat3amt: 4,
                    hover3: "Ifrit",
                    mat4: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat4amt: 4,
                    hover4: "Fosradius",
                    mat5: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat5amt: 5,
                    hover5: "Code-XTF"
                }
            },
        dream: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        hover2: `Frostsprite Soulgem`,
                        mat2amt: 50,
                        mat3: `/db/Icons/dungeon_drops/fragFreydal.png`,
                        mat3amt: 2,
                        hover3: `Freydal`
                    },
                    passive: {
                        ability1: `Adds "For 10s, Increase all Allies' Stats by 30%." effect to Arts.`,
                        ability2: ``
                    }
                },
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragWilkes.png`,
                        mat3amt: 2,
                        hover3: `Wilkes`
                    },
                    passive: {
                        ability1: `Increase all Light Units' Arts by 2/s. Does not stack with Abilities with the same name.`,
                        ability2: ``
                    }
                }
        },
        trueweapon: 
            {
                name: "Ryude Magus",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_103511.png",
                skill: "50s CT - 8500% Light DMG (PHY). For 8s, increase Allies' Critical Rate by 60% against Enemies in Break.",
                skillbreak: 1400,
                passive: 
                    {
                        ability1: "When equipped by Zeorg (Light), increase DEF by 50% and DMG by 20%. Does not stack with Abilities of the same name.",
                        ability2: "Increase Allies' Critical DMG against Enemies in Break by 20%. Does not stack with Abilities of the same name."
                    }
            },
        lore: 
            {   
                evo4: "A longtime vassal of Famelle and member of the Demon Suppression Corps. A living military legend in his home country, his bravery is spoken of in whispers worldwide. Though his appearance and history often has him mistaken for a silent. serious type, he's actually quite chatty, which sometimes ends up terrifying younger soldiers, much to his chagrin. He acquired his jeweled blade Reudo Magus after slaying a dark dragon long ago.",
                evo5: "A Famellian hero who fought to defeat the Demonic threat. Besides his experience fighting countless victorious battles, his words and actions ooze strength and dependability: small wonder everyone in the Demon Suppression Corps trusts him so much. Despite his age, the indomitable magnificence of his old hand will only grow stronger as he journeys with the younger generation. Proof that heroes don't always look the part.",
                evoawk: "A Hero and a living legend of Famelle. The more he was looked up to, the more he suffered between the created fake-self and true-self. However, thanks to his friends who did not judge him for his past mistakes, he regains his pride and braveness. Now that he has no doubts, his sword skills are sharper as ever. No one can be as strong as this veteran thus his name will continue to shine as a hero."
            }
    },
    { //Melia (Dark) - Complete + DreamEvo
        id: 101076511,
        name: "Melia (Dark)",
        rank: "C",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101074511.png",
                detail5: "/db/Units/Detail/unit_detail_101075511.png",
                detailawk: "/db/Units/Detail/unit_detail_101076511.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101074511.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101075511.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101076511.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6420,
                hpplus: 973,
                atk: 2808,
                atkplus: 431,
                def: 2274,
                defplus: 351
            },
        skillset: 
            {
                skill: "9s CT - 2200% Dark DMG (MAG). Heal self by 2% of own max HP.",
                skillbreak: 1000,
                arts: "16000% Dark DMG (MAG). Chance (60%) to inflict Blind. During Arts, stop Enemy actions. Refresh Skill CT for Allies + For 10s, increase Allies' BE generation by 50%.",
                artsbreak: 2000,
                truearts: "45000% Dark DMG (MAG). During True Arts, stop Enemy actions. Steals 20 of Enemies' Arts + For 15s, increase Allies' MAG DMG by 80%.",
                trueartsbreak: 3500
            },
        passive: 
            {
                ability1: "Heal Allies' HP by 10/s.",
                ability2: "When damaged while near-death, for 10s, gain a DMG barrier that negates 2000 DMG. Ability can only be activated once every 60s.",
                ability3: "Increase DMG to God Enemies by 90%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat1amt: 3,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_fire4.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_dark3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat3amt: 4,
                    hover3: "Radachne",
                    mat4: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat4amt: 3,
                    hover4: "Mavroskia",
                    mat5: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat5amt: 2,
                    hover5: "Ifrit"
                }
            },
        dream: {
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragGoldola.png`,
                        mat3amt: 2,
                        hover3: `Goldola`
                    },
                    passive: {
                        ability1: `Passive no longer heals Allies' HP by 10/s. Instead, it heals Allies' HP by 1%/s.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragMavroskia.png`,
                        mat3amt: 2,
                        hover3: `Mavroskia`
                    },
                    passive: {
                        ability1: `Increase all Dark allies' Arts gauge by 2/s (Does not stack with Abilities of the same name).`
                    }
                },
            },
        trueweapon: 
            {
                name: "Maleficium",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_104051.png",
                skill: "90s CT - 13000% Dark DMG (MAG).",
                skillbreak: 1200,
                passive: 
                    {
                        ability1: "When equipped by Melia (Dark), increase DMG by 20% and heal HP by 10/s. Does not stack with Abilities of the same name.",
                        ability2: "Increase DMG against Blinded Enemies by 20%. Does not stack with Abilities of the same name."
                    }
            },
        lore: 
            {   
                evo4: "Vice-commander of the La Riiz 3rd Magus Division and member of the Demon Suppression Corps. Known as Blue-eyed Black Fox, she's an expert at data collection operations with many successful infiltrations under her belt. Though her voluptuous appearance has engendered many rumours, she acknowledges none of them. The only thing certain about her is that anyone who brings up the subject of marriage to her will never mention the word ever again.",
                evo5: "A mage from La Riiz who fought to end the Demonic menace. She has an upbringing she'd prefer not to bring up, and had a tendency to distance herlfrom from others. After journeying with the destined young heroes of the Demon Suppression Corps, her repressed kindness slowly began to reveal itself. It's not just her powerful magic, but her long-buried ability to feel for others that is her source of strength.",
                evoawk: "A sorcerer who chose to embrace the darkest parts of the Magus Empire of La Riiz. Her particular abilities and tendency toward rash, independent action invited dangerous misunderstandings. She fell into a trap, straining the faith of her friends, yet the unwavering trust of the adventurer broke through to her, and she resolved to face up to her past. Her sorcery thus broke away from her shadowy past to become something brilliant, something greater, something to protect those she held dear."
            }
    },
    { //Ragsherum - Complete
        id: 101086511,
        name: "Ragsherum",
        rank: "A-",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101084511.png",
                detail5: "/db/Units/Detail/unit_detail_101085511.png",
                detailawk: "/db/Units/Detail/unit_detail_101086511.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101084511.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101085511.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101086511.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 5,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6743,
                hpplus: 999,
                atk: 2739,
                atkplus: 420,
                def: 2420,
                defplus: 373
            },
        skillset:
            {
                skill: "9s CT - 3000% Dark DMG (PHY). Increase own Arts by 30.",
                skillbreak: 1200,
                arts: "35000% Dark DMG (PHY). Consumes 20% of own HP. 60% chance to inflict Faint for 5s.",
                artsbreak: 1900,
                truearts: "80000% Dark DMG (PHY). 100% chance to inflict Faint for 5s. Consumes 30% of own HP + For 30s, gain a DMG barrier that negates 6000 DMG to self.",
                trueartsbreak: 4000
            },
        passive: 
            {
                ability1: "Increase Dark RES by 10%. Increase own ATK and DEF based on how low HP is (up to 100%).",
                ability2: "When near-death, heal own HP by 4% of DMG dealt.",
                ability3: "Increase Status RES by 60%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat1amt: 5,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat2amt: 3,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat3amt: 2
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 5,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat4amt: 5,
                    hover4: "Mavroskia",
                    mat5: "/db/Icons/dungeon_drops/fragRagsherum.png",
                    mat5amt: 1,
                    hover5: "Ragsherum"
                }
            },
            dream: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                        mat3amt: 2,
                        hover3: "Ifrit",
                    },
                    passive: {
                        ability1: `Increase own CRI DMG by 80%.`,
                        ability2: `Increase own CRI rate by 80%.`
                    }
                },
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragGoldola.png",
                        mat3amt: 2,
                        hover3: "Goldola",
                    },
                    passive: {
                        ability1: `Increase own Equip CT Speed by 20%.`,
                        ability2: `Increase all allies' Break Power by 20%. Does not stack with Abilities of the same name.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragRagsherumPhantom.png`,
                        mat3amt: 2,
                        hover3: `Ragsherum Phantom`
                    },
                    passive: {
                        ability1: `Increase own DMG by 50% against enemies below 30% HP.`,
                        ability2: `Increase Demon allies' Arts by 2/s. Does not stack with Abilities of the same name.`
                    }
                }
            },
        trueweapon: 
            {
                name: "Geshpenst",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_103901.png",
                skill: "50s CT - 7200% Dark DMG (PHY).",
                skillbreak: 1600,
                passive: 
                    {
                        ability1: "When equipped by Ragsherum, increase DMG by 20% and Skill CT REC by 50%. Does not stack with Abilities of the same name.",
                        ability2: "Increase Allies' ATK by 15%. Does not stack with Abilities of the same name."
                    }
            },
        lore: 
            {   
                evo4: "A demon once sealed in Gormarey, until a break in the seal led to his resurrection. Ragsherum served as divisional commander under Auge, one of the six great Demon Generals that ruled the Demons during the Great War. The strongest and most prominent of the revived Demons, he assumed command over Grad and Nier as subordinates. He sought to fully resurrect the Demons, but was defeated by Rayas and his allies.",
                evo5: "After falling to Rayas and company, Ragsherum dedicates his life to the resurrection of the Demon Generals. Though a Demon, he possesses something resembling a chivalric dedication to his cause, and a high degree of devotion to the Generals. Though he doesn't destroy or kill for sport, he will pursue mass slaughter if it would achieve his goals. He's wary of Humans, who now rule Raktehelm despite being the weakest race during the Great War.",
                evoawk: "The divisional commander of the six great Demon Generals that revived at the Sealed Fortress Gormarey. At first, Demons were reviving sparsely. However, since they considered power the most, they never worked as groups and fought against themselves. One day Ragsherum had suppressed all the others and started his plan to revive Auge at Gormarey. Not only that, he desired to get the heart of Humans, the winner of the Ancient War, and to put them in fear and despair."
            }
    },
    { //Grad - Complete + JP ver.
        id: 101096511,
        name: "Grad",
        luckrank: "C",
        rank: "F",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101094511.png",
                detail5: "/db/Units/Detail/unit_detail_101095511.png",
                detailawk: "/db/Units/Detail/unit_detail_101096511.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101094511.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101095511.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101096511.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 6,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7999,
                hpplus: 999,
                atk: 2369,
                atkplus: 369,
                def: 2636,
                defplus: 405
            },
        skillset: 
            {
                skill: "9s CT - 2666% Dark DMG (PHY).",
                skillbreak: 666,
                arts: "16666% Dark DMG (PHY). For 5s, increase Allies' Critical Rate by 100%, and Fire, Water and Earth RES by 50%.",
                artsbreak: 1666,
                truearts: "44444% Dark DMG (PHY). Consume 50% of own HP + For 30s, reduce own DMG RES by 40%, increase own DMG by 50%, increase own Crit Rate by 100% and heal own HP for 1% of DMG dealt.",
                trueartsbreak: 2666,
                crossarts: "66666% Dark DMG (PHY). For 99s, increase Grad and Nier's DMG by 66%, Critical DMG by 99%.",
                crossartsbreak: 2666
            },
        skillsetJP: 
            {
                skill: "9s CT - 2666% Dark DMG (PHY).",
                skillbreak: 666,
                arts: "24444% Dark DMG (PHY). For 10s, increase Allies' Critical Rate by 100%, and Fire, Water and Earth RES by 50%.",
                artsbreak: 1666,
                truearts: "66666% Dark DMG (PHY). For 30s, increase own DMG by 50%, CRI rate by 100%, and heal own HP by 1% of DMG dealt.",
                trueartsbreak: 2666,
                crossarts: "66666% Dark DMG (PHY). For 99s, increase Grad and Nier's DMG by 66%, Critical DMG by 99%.",
                crossartsbreak: 2666
            },
        passive: 
            {
                ability1: "Increase Blind RES by 50%.",
                ability2: "Increase Demon Allies' DMG against God Enemies by 20%. Does not stack with Abilities of the same name.",
                ability3: "When at MAX HP, increase ATK by 100%."
            }, 
        passiveJP: 
            {
                ability1: "Increase Blind RES by 50%.",
                ability2: "Increase Demon Allies' DMG against God Enemies by 20%. Does not stack with Abilities of the same name.",
                ability3: "When above 80% HP, increase ATK by 100%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_110550.png",
                    mat1amt: 20,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat3amt: 1
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGrad.png",
                    mat3amt: 20,
                    hover3: "Grad",
                }
            },
            dreamJP: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 100,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_GradDream.png`,
                        mat2amt: 100,
                        hover2: `Soulgem of Desire`
                    },
                    passive: {
                        ability1: `Increase own CRI DMG by 100%.`,
                        ability2: `Increase own Arts by 3/s when above 80% HP.`
                    }
                },
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 100,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_GradDream.png`,
                        mat2amt: 100,
                        hover2: `Soulgem of Desire`
                    },
                    passive: {
                        ability1: `Increase own DMG against Gods by 150%.`,
                        ability2: `Increase own Arts by 4/s.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 100,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_GradDream.png`,
                        mat2amt: 100,
                        hover2: `Soulgem of Desire`
                    },
                    passive: {
                        ability1: `Increase all allies' MAX HP by 20%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase Demon allies' Arts by 2/s. Does not stack with Abilities of the same name.`
                    }
                }
            },
        trueweapon: 
            {
                name: "Berserk",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_107661.png",
                skill: "70s CT - 6666% Dark DMG (PHY). For 10s, decrease Enemies' Dark RES and PHY RES by 20%.",
                skillbreak: 1666,
                passive: 
                    {
                        ability1: "When equipped by Grad, after using True Arts, increase DMG by 50% for 30s. Does not stack with Abilities of the same name.",
                        ability2: "Increase Critical DMG by 20%."
                    }
            },
        lore: 
            {   
                evo4: "A Demon once sealed in Gormarey, until a break in the seal led to his resurrection. Grad was a member of the squad dedicated to protecting Giaveil, one of The Six Great Demons. After his resurrection, he follows Ragsherum, renowned as one of the great Demon heroes, serving as one of his subordinated alongside Nier. A brutal lover of destruction and combat, Grad obsessively chases Rayas and his friends.",
                evo5: "After continuously suffering defeat at the hands of Rayas and his friends, Grad will do anything to win. ",
                evoawk: "A Demon warrior who once served under sin Emperor Glaveil, slaughtering countless God soldiers. After being defeated by a Human swordsman, Grad couldn't contain the raging fury inside him. His hatred seethed, \"No one surpasses me and lives,\" he thought, a common notion among Demons, who take pride only in power. Now Grad knows just what to do, \"All that's left is to kill him and prove my strength,\" Grad laughs, grabbing his magic spear."
            }
    },
    { //Nier - Complete
        id: 101106511,
        name: "Nier",
        rank: "C",
        luckrank: "F",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101104511.png",
                detail5: "/db/Units/Detail/unit_detail_101105511.png",
                detailawk: "/db/Units/Detail/unit_detail_101106511.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101104511.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101105511.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101106511.png"
            },
        slots:  
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 7666,
                hpplus: 937,
                atk: 2666,
                atkplus: 388,
                def: 2499,
                defplus: 348
            },
        skillset: 
            {
                skill: "8s CT - 1600% Dark DMG (PHY). 100% chance to inflict Disease for 8s.",
                skillbreak: 900,
                arts: "9999% Dark DMG (MAG). If attack hits, recover Allies' HP by 2000 (only once per Arts activation) + For 7s, increase Dark Allies' DMG by 44%.",
                artsbreak: 1666,
                truearts: "36666% Dark DMG (MAG). During this True Arts, ignore 50% of Enemies' DEF. For 20s, reduce Enemies' Disease RES by 50%.",
                trueartsbreak: 1666,
                crossarts: "66666% Dark DMG (PHY). For 99s, increase Grad and Nier's DMG by 66%, Critical DMG by 99%.",
                crossartsbreak: 2666
            },
        passive: 
            {
                ability1: "Increase Demon Allies' Light RES by 15%. Does not stack with Abilities of the same name.",
                ability2: "Allies ignore 50% of Diseased and/or Poisoned Enemies' DEF. Does not stack with Abilities of the same name.",
                ability3: "Increase lifesteal RES by 30%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_112755.png",
                    mat1amt: 20,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat3amt: 1
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragNier.png",
                    mat3amt: 20,
                    hover3: "Nier",
                }
            },
        trueweapon: 
            {
                name: "Basilisk",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_108521.png",
                skill: "70s CT - 6666% Dark DMG (PHY). For 10s, increase own Dark DMG by 44%.",
                skillbreak: 1666,
                passive: 
                    {
                        ability1: "When equipped by Nier, increase Healing Taken and accuracy by 30%. Does not stack with Abilities of the same name.",
                        ability2: "Increase DMG to Poisoned/Diseased Enemies by 15%."
                    }
            },
        lore: 
            {   
                evo4: "The Demon that revived when the seal was partly destroyed at the sealed area Gormarey. She was a member under Ze-Lemma, one of The Great Six Demons, who created the Special Magic Lab. She gets obsessed with results, but even if she fails if that is what makes her closer to her goal, she will accept it. However, her decisions are unique, so people around her think she moves depending on her feelings.",
                evo5: "Being defeated several times by Rayas and the others, she calmly reviewed her cause of it, and planned to fight with grad who she was at odds. She felt humiliated, but she only pretended to feel like so in order to make him agree with her. For her, this was a matter of course for what her goal. Primarily, she was better at researching than fighting and she was doing research on anti-God magic during the Ancient War. After reviving, she was head of creating Iris's copies.",
                evoawk: "A leader of the Demon army seeking the complete restoration of her race. She is one to achieve her goals at any cost. This was best exemplified when she joined forces with a rival Demon after her first defeat at the hands of a Human adventurer, yet that humiliation proved to be in vain. The losses continued to mount and a new emotion took hold of her heart, changing her objective from selfless duty to personal revenge."
            }
    },
    { //Ganan - Complete (+ JP version)
        id: 101116111,
        name: "Ganan",
        rank: "B-",
        luckrank: "C",
        twrequire: "favorable",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101114111.png",
                detail5: "/db/Units/Detail/unit_detail_101115111.png",
                detailawk: "/db/Units/Detail/unit_detail_101116111.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101114111.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101115111.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101116111.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 6,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7100,
                hpplus: 999,
                atk: 2448,
                atkplus: 377,
                def: 2050,
                defplus: 317
            },
        skillset: 
            {
                skill: "9s CT - 2000% Fire DMG (PHY). Increase own Arts by 5.",
                skillbreak: 2200,
                arts: "15000% Fire DMG (PHY). For 15s, increase own ATK by 200%, increase Freeze and Paralysis RES by 100%. During Berserk Mode: 46500% Fire DMG (PHY). Consume 40% of own HP, increase this Arts' DMG by 200% + For 15s, increase own ATK by 200%, increase Freeze and Paralysis RES by 100%.",
                artsbreak: 3100,
                truearts: "40000% Fire DMG (PHY). Enter Berserk Mode for 55s + For 10s, reduce enemies' PHY RES and Evasion rate by 30%.",
                trueartsbreak: 5000
            },
        skillsetJP: 
            {
                skill: "9s CT - 2000% Fire DMG (PHY). Increase own Arts by 20.",
                skillbreak: 2200,
                arts: "18000% Fire DMG (PHY). For 15s, increase own ATK by 200%, increase Freeze and Paralysis RES by 100%. During Berserk Mode: 54000% Fire DMG (PHY). Consume 40% of own HP, increase this Arts' DMG by 200% + For 15s, increase own ATK by 200%, increase Freeze and Paralysis RES by 100%.",
                artsbreak: 3100,
                truearts: "55000% Fire DMG (PHY). Enter Berserk Mode for 99s + For 15s, reduce enemies' PHY RES by 50% and Evasion rate by 30%.",
                trueartsbreak: 6000
            },
        passive: 
            {
                ability1: "During Berserk Mode, increase Arts by 1 per hit taken, reduce DMG RES by 35% and increase Critical Rate by 100%. When reduced to 1 HP, Exit Berserk Mode.",
                ability2: "Increase Break PWR by 10%.",
                ability3: "Increase DMG to Enemies in BREAK by 20%."
            }, 
        evolution: 
            {
                evo45: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_106450.png", // Pride of Almadin
                    mat1amt: 80,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_fire4.png",
                    mat2amt: 3
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGanan.png",
                    mat3amt: 20,
                    hover3: "Ganan",
                }
            },
            dreamJP: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 100,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_133851.png`,
                        mat2amt: 100,
                        hover2: `Warrior Soulgem`
                    },
                    passive: {
                        ability1: `Increase own Break Power against Earth Enemies by 60%.`,
                        ability2: `When Near-Death, increase own DMG RES by 20%.`
                    }
                },
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 100,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_133851.png`,
                        mat2amt: 100,
                        hover2: `Warrior Soulgem`
                    },
                    passive: {
                        ability1: `Increase own CRI DMG by 60%.`,
                        ability2: `When Near-Death, increase own Arts by 4/s.`
                    }
                }
            },
        trueweapon: 
            {
                name: "Shurahalt",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_107561.png",
                skill: "50s CT - 8000% Fire DMG (PHY). If user is Fire, for 6s, increase own DMG by 50% and Break PWR by 100%.",
                skillbreak: 2100,
                passive: 
                    {
                        ability1: "When equipped by Ganan, increase DMG by 80% during Berserk Mode.",
                        ability2: "Increase Allies' Break PWR by 15%."
                    }
            },
        lore: 
            {   
                evo4: "The guild master at the Reigard branch of the Adventurer's Guild. Once a commander of Isliid's self-defense knight corps, a failed operation led to his demotion. Since then, he focused on raising the next generation, and became part of the Adventurer's Guild, saying that the laid back work suits his personality better. He suffered from a terrible injury during his failed operation with the knights, making it impossible for him to unleash his full power.",
                evo5: "Everyone thought he was unable to use his full power because of his unhealed injuries. However, he had actually fully recovered from it long ago. Not being able to let go of the sins of the past limited his true powers. When he finally overcame his past, his power surged beyond its peak, unleashing a strike capable of rending the earth. However strong his enemies, his allies will always be able to depend on his smiling courage.",
                evoawk: "The most powerful warrior of the Adventurer's Guild and guild master of Reigard branch. Theroughout the days at Reigard, Ganan interacted with many young adventurers. Being touched by their passion, he reconsidered his own path, together with the unforgettable mistake of his past and the meaning of being a guild master now... Embracing the failure of his past, he takes a step forward to protect the heroes of tomorrow."
            }
    },
    { //Gilliam - Complete (+ JP version + Dream Evo)
        id: 101126311,
        name: "Gilliam",
        luckrank: "F",
        rank: "F",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101124311.png",
                detail5: "/db/Units/Detail/unit_detail_101125311.png",
                detailawk: "/db/Units/Detail/unit_detail_101126311.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101124311.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101125311.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101126311.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 6,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 6,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7320,
                hpplus: 999,
                atk: 2543,
                atkplus: 391,
                def: 2418,
                defplus: 372
            },
        skillset: 
            {
                skill: "9s CT - 1600% Earth DMG (PHY). For 6s, increase Allies' DEF by 10%.",
                skillbreak: 900,
                arts: "15000% Earth DMG (PHY). For 10s, increase Allies' Equipment DMG by 30%.",
                artsbreak: 1600,
                truearts: "37000% Earth DMG (PHY). For 20s, increase own DMG by 70% and ignore 100% of Enemy DEF.",
                trueartsbreak: 2000
            },
        skillsetJP: 
            {
                skill: "9s CT - 1600% Earth DMG (PHY). For 6s, increase Allies' DEF by 10%.",
                skillbreak: 900,
                arts: "22000% Earth DMG (PHY). For 20s, increase Allies' Equipment DMG by 70%.",
                artsbreak: 1600,
                truearts: "52000% Earth DMG (PHY). For 20s, increase own DMG by 120% and ignore 100% of Enemy DEF.",
                trueartsbreak: 2000
            },
        passive: 
            {
                ability1: "Increase PHY RES by 10%.",
                ability2: "Increase Equipment CT REC by 20%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_110953.png", // Emerald of Refinement
                    mat3amt: 20
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGilliam.png",
                    mat3amt: 20,
                    hover3: "Gilliam",
                }
            },
            dreamJP: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 100,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_GilliamDream.png`,
                        mat2amt: 100,
                        hover2: `Scorched Iron Soulgem`
                    },
                    passive: {
                        ability1: `Increase own Accuracy by 20%.`,
                        ability2: `Increase own DMG against Water enemies by 100%.`
                    }
                },
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 100,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_GilliamDream.png`,
                        mat2amt: 100,
                        hover2: `Scorched Iron Soulgem`
                    },
                    passive: {
                        ability1: `Increase own Equipment DMG by 50%.`,
                        ability2: `Increase Earth allies' Arts by 2/s. Does not stack with Abilities of the same name.`
                    }
                }
                
            },
        trueweapon: 
            {
                name: "Hepaistos",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_108861.png",
                skill: "45s CT - 10000% Earth DMG (PHY). For 8s, increase own Earth DMG by 30%.",
                skillbreak: 300,
                passive: 
                    {
                        ability1: "When equipped by Gilliam, increase Allies' Equipment DMG by 20%. Does not stack with Abilities of the same name.",
                        ability2: "Increase DEF by 10%."
                    }
            },
        lore: 
            {   
                evo4: "A blacksmith who runs a weapons shop in the town of Reigrad. His expertise is known throughout nearby kingdoms, and though many would-be blacksmiths have begged to apprentice under him, only three have met his rigid standards. This means he can't take massive military orders like other blacksmiths, instead tailoring his work to individuals. He's also extremely picky about materials, and will fight monsters to get what he wants. He's drinking buddies with Ganan.",
                evo5: "Though originally a highborn child of Bamint, Gilliam decided he didn't want to live according to his parents' whims and left the house at age 16 to travel the world. After falling in love with a certain blacksmith and demanding to become their apprentice, he heard tales of the legendary weapons used by the 3 Blade Masters. He set off on a yourney to amass materials to forge an even greater weapon, and the trail lef him to set up shop in Reigrad.",
                evoawk: "A skilled craftsman of Reigrad. Mesmerized by the art of smithing from a young age, he honed his craft diligently. The color of the fire, the sound of forging steel, the weight of the sledgehammer in his hands are constantly changing phenomena that, even after many years of disciplined practice, never extinguish his passion. His legacy is an inspiration not only for other blacksmiths but for artists of all kinds."
            }
    },
    { //Liese (Light) - Complete
        id: 101136411,
        name: "Liese (Light)",
        rank: "B+",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101134411.png",
                detail5: "/db/Units/Detail/unit_detail_101135411.png",
                detailawk: "/db/Units/Detail/unit_detail_101136411.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101134411.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101135411.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101136411.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/healLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 7510,
                hpplus: 999,
                atk: 2550,
                atkplus: 392,
                def: 2550,
                defplus: 392
            },
        skillset: 
            {
                skill: "Recover Mode: 5s CT - Heal Allies' HP by 500. Attack Mode: 5s CT - 1800% Light DMG (MAG).",
                skillbreak: 1700, //how do i write this??? cause liese skill has two modes, one has 0 break the other has 1700
                arts: "Recover Mode: Heal Allies' HP by 3000, cure Allies' Status Ailments (except Faint). Attack Mode: 16000% Light DMG (MAG). 80% chance to inflict Paralysis for 2s.",
                artsbreak: 3500, //same comment for skill
                truearts: "32000% Light DMG (MAG). 80% chance to inflict Paralysis for 6s. Recover Allies' HP by 6000, revive fallen Allies with 30% HP (only able to revive fallen Allies once every 60s).",
                trueartsbreak: 4500
            },
        passive: 
            {
                ability1: "Switch between Attack Mode and Recover Mode when using Skill.",
                ability2: "Increase Equipment CT REC 15%.", 
                ability3: "Increase Status RES (except Faint) by 50%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat3amt: 3,
                    hover3: "Ifrit",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 2,
                    hover4: "Radachne",
                    mat5: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat5amt: 4,
                    hover5: "Code-XTF"
                }
            },
        dream: {
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragElpneumas.png`,
                        mat3amt: 2,
                        hover3: `Elpneumas`
                    },
                    passive: {
                        ability1: `Increase own HP Recovery Amount by 30% (excluding Absorption attacks).`,
                        ability2: `Increase own Arts gauge by 2/s.`
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragRadachne.png`,
                        mat3amt: 2,
                        hover3: `Radachne`
                    },
                    passive: {
                        ability1: `Increase all Light allies' Arts gauge by 2/s (No Duplication).`,
                        ability2: ``
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragRagsherumPhantom.png`,
                        mat3amt: 2,
                        hover3: `Ragsherum Phantom`
                    },
                    passive: {
                        ability1: `Increase all allies' Dark RES by 20% (No Duplication).`,
                        ability2: ``
                    }
                },
            },
        trueweapon: 
            {
                name: "Rig Arcela",
                slot: "/db/Mines/thumbnail/healLB.png",
                detail: "/db/Equipment/Detail/item_detail_103151.png",
                skill: "50s CT - Cure Allies' status ailments (except Faint), heal Allies' HP by 1200.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Liese (Light), increase DMG by 20%.",
                        ability2: "Increase Healing Output by 10%."
                    }
            },
        lore: 
            {   
                evo4: "High priestess of the shrine in Reigard, Liese's parents emigrated to Isliid from Famelle in order to spread the teachings of the Guiding Light. At age 4, Liese began training at Famelle's great shrine. Her talents saw her becoming a priestess at age 10, and was assigned to Reigard's shrine at 11. Though she's currently only 13, she's far more dignified and regal in presence than Rayas.",
                evo5: "Called a genius, her light magic are powerful enough to be termed 'miracles', but she still has moments that reveal that yes, she is in fact a teenager. Like when she goes out for ice cream with the other girls in town. her eyes then were not those of a high priestess, but of a regular girl. The moments where Liese acknowledges her own youth are moments that cause her heart to soar, unleashing ever greater power and potential.",
                evoawk: "Although she had pride in being a priestess, she acknowledged that living in a shrine meant living in a closed world. Since some time or another, an adventurer and his friends started visiting her and telling stories of their journeys. The stories sometimes exciting and at other times terrifying. What kind of judgment should she make as a follower of the Light God? This question and responsibilities led her to a higher dignity and more power as a priestess."
            }
    },
    { //Est - Complete
        id: 101146211,
        name: "Est",
        rank: "C",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101144211.png",
                detail5: "/db/Units/Detail/unit_detail_101145211.png",
                detailawk: "/db/Units/Detail/unit_detail_101146211.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101144211.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101145211.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101146211.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7188,
                hpplus: 999,
                atk: 2784,
                atkplus: 427,
                def: 2138,
                defplus: 330
            },
        skillset: 
            {
                skill: "10s CT - 2800% Water DMG (PHY). 15% chance to inflict Freeze for 2s.",
                skillbreak: 1800,
                arts: "14000% Water DMG (PHY). For 10s, increase Allies' Water DMG to Enemies in Break by 70%.",
                artsbreak: 2750,
                truearts: "23500% Water DMG (PHY). For 10s, increase Allies' DMG to Enemies in Break by 100%.",
                trueartsbreak: 4550
            },
        passive: 
            {
                ability1: "When Ganan is in party, increase Allies' DMG by 5%.",
                ability2: "Giant Boss' Break Bar is halved at the beginning of battle. Raid Bosses' Break Bars take 20000 Break DMG instead. Ability can only be activated once per Quest.",
                ability3: "Increase DMG to Giant Bosses by 50%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat1amt: 3,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_fire4.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_water3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat3amt: 5,
                    hover3: "Radachne",
                    mat4: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat4amt: 4,
                    hover4: "Tonetaurus",
                    mat5: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat5amt: 3,
                    hover5: "Palamicia"
                }
            },
        trueweapon: 
            {
                name: "Wahrheit",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_104021.png",
                skill: "90s CT - 10800% Water DMG (PHY). If user is Human, increase Arts DMG by 50%. For 10s, reduce own Arts by 2/s.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Est, increase DMG and Break PWR by 20%. Does not stack with Abilities of the same name.",
                        ability2: "Increase PHY DMG by 15%. Does not stack with Abilities of the same name."
                    }
            },
        lore: 
            {   
                evo4: "The receptionist at Reigrad's arena. Though she normally greets adventurers and knights, Est herself was originally a knight of Isliid. Though an incident resulted in her demotion, her commander led her to her current job. Usually calm and polite, Est is quick to anger if someone starts causing trouble, exacting discipline at swordpoint. Those who know her are reminded during moments like these that yes, she really is Ganan's daughter.",
                evo5: "Est always sought to become a knight like her father, Ganan. After joining an order, however, her father was expelled from the knights, filling her with frustration and anger - anget that found an outlet against those foolish enough to provoke her. Though she continues working on her self-control, by realizing her own true nature, Est became far more powerful than she was during her time in the knights.",
                evoawk: "The receptionist at the Arena. In order to settle things between her father and mother, she keeps training to become stronger than the two. Her father worries and tries to stop her. On the other hand, her mother helps her out secretly. She feels that unlike her expectations, the distance of the two are getting even farther. Yet, she still aims for a higher goal. This pure heart releases the strong power from her parents."
            }
    },
    { //Sophie - Complete (+ JP version)
        id: 101156111,
        name: "Sophie",
        luckrank: "C",
        rank: "F",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101154111.png",
                detail5: "/db/Units/Detail/unit_detail_101155111.png",
                detailawk: "/db/Units/Detail/unit_detail_101156111.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101154111.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101155111.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101156111.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 6,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6731,
                hpplus: 999,
                atk: 2310,
                atkplus: 356,
                def: 2238,
                defplus: 345
            },
        skillset: 
            {
                skill: "8s CT - 1200% Fire DMG (PHY). For 6s, reduce Enemies' Break RES by 15%.",
                skillbreak: 2500,
                arts: "10000% Fire DMG (PHY). For 10s, increase Allies' Skill CT REC by 50%. Fire Allies' Skill CT REC is increased by 100% instead.",
                artsbreak: 2900,
                truearts: "27000% Fire DMG (PHY). 80% chance to inflict Burn for 11s. If Enemy was Burned by own True Arts or was already Burned when own True Arts was activated, for 10s, reduce Enemies' Fire RES by 50%.",
                trueartsbreak: 3000
            },
        skillsetJP: 
            {
                skill: "8s CT - 1200% Fire DMG (PHY). For 6s, reduce Enemies' Break RES by 25%.",
                skillbreak: 2500,
                arts: "20000% Fire DMG (PHY). For 20s, increase Allies' Skill CT REC by 60%. Fire Allies' Skill CT REC is increased by 120% instead.",
                artsbreak: 3500,
                truearts: "45000% Fire DMG (PHY). 80% chance to inflict Burn for 11s. If Enemy was Burned by own True Arts or was already Burned when own True Arts was activated, for 20s, reduce Enemies' Fire RES by 80%.",
                trueartsbreak: 6000
            },
        passive: 
            {
                ability1: "Increase Fire Allies' ATK by 30%.",
                ability2: "Increase Burn RES by 80%."
            }, 
        evolution: 
            {
                evo45: 
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_106950.png", // Sophie's 4* > 5* awaken mats
                    mat1amt: 20,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_fire4.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat3amt: 1
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragSophie.png",
                    mat3amt: 20,
                    hover3: "Sophie",
                }
            },
            dreamJP: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 100,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_SophieDream.png`,
                        mat2amt: 100,
                        hover2: `Passionate Soulgem`
                    },
                    passive: {
                        ability1: `Increase own Equip CT Speed by 15%.`,
                        ability2: `Increase all allies' Break Power by 30%. Does not stack with Abilities of the same name.`
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_SophieDream.png`,
                        mat2amt: 100,
                        hover2: `Passionate Soulgem`
                    },
                    passive: {
                        ability1: `Increase Human allies' Arts by 3/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own Arts by 1/s.`
                    }
                }
            },
        trueweapon: 
            {
                name: "Wild Cat",
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_108771.png",
                skill: "60s CT - 1000% Fire DMG (PHY). For 20s, increase Allies' Break PWR by 25%, and Fire Allies' DMG by 15%.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Sophie, increase accuracy by 30% and increase own Arts by 2/s.",
                        ability2: "Increase DMG dealt against Enemies in BREAK by 10%."
                    }
            },
        lore: 
            {   
                evo4: "Proprietress and head chef of the Firecat's Den in Reigrad, which boasts flavours and prices that make it popular among the common people. Her father, who is also her teacher, is currently retured due to injury, but hailed from a family of high-class Bamantian restauranteurs. Displeased with the idea that his food would only be enjoyed by the rich, he left on a journey in search of freedom.",
                evo5: "Sophie was taught not only how to cook, but also how to acquire important ingredients on her own. How to lay traps for animals, how to traverse dangerous environs and live to talk about it, and how to fight. This training has made her an extremely capable adventurer recognised by even Ganan. Because of her upbringing, she's eye for men is extremely demanding, and she has yet to find someone who sets her heart aflutter.",
                evoawk: "As head chef of the Firecat's Den, she works hard to make sure that her customers stay happy and satisfied. However, she tends to furrow her brow when focusing on her craft. This used to bother her, but the words of a novice adventurer made her concerns fly away. 'A good meal always puts a smile on your face!' was all she needed to hear to gather herself and head back to the kitchen, motivated as ever."
            }
    },
    { //Rem (Grand Summoners) - Complete
        id: 101166511,
        name: "Rem (GS)",
        rank: "B+",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101164511.png",
                detail5: "/db/Units/Detail/unit_detail_101165511.png",
                detailawk: "/db/Units/Detail/unit_detail_101166511.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101164511.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101165511.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101166511.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6944,
                hpplus: 999,
                atk: 2455,
                atkplus: 378,
                def: 2560,
                defplus: 394
            },
        skillset: 
            {
                skill: "10s CT - 2000% Dark DMG (MAG). During Skill, increase own BE generation by 50%.",
                skillbreak: 800,
                arts: "13000% Dark DMG (MAG). For 15s, reduce Enemies' ATK and DEF by 35%, reduce Enemies' RES to the element they have an Elemental Weakness against by 40%.",
                artsbreak: 1300,
                truearts: "25000% Dark DMG (MAG). Increase own True Arts' DMG by 100% against Light Enemies.",
                trueartsbreak: 3000
            },
        passive: 
            {
                ability1: "When using Equipment, heal Allies' HP by 2%.",
                ability2: "Increase Allies' DMG to Enemies of a certain Element (depends on day of the week: Monday - Dark, Tuesday - Fire, Wednesday - Water, Thursday - Earth, Friday - Light, Saturday & Sunday - Random Element) and DMG RES of the same Element by a random percentage between 20% and 40% (changed daily).",
                ability3: "Increase Allies' MAG DMG by 15%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat1amt: 3,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_dark3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat3amt: 2,
                    hover3: "Mavroskia",
                    mat4: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat4amt: 1,
                    hover4: "Tonetaurus",
                }
            },
        trueweapon: 
            {
                name: "Alchemia",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_104411.png",
                skill: "40s CT - 7200% Dark DMG (MAG). For 5s, increase own BE generation by 50%, reduce Enemies' ATK and DEF by 20%.",
                skillbreak: 700,
                passive: 
                    {
                        ability1: "When equipped by Rem (Grand Summoners), increase DMG by 20% and healing when using Equipment to 3% HP.",
                        ability2: "Increase MAG DMG by 10%. When near-death, increase Arts by 2/s."
                    }
            },
        lore: 
            {   
                evo4: "An alchemist who runs an alchemy shop in Reigrad. Her words and actions are shrouded always in an air of mystery. Though she claims to be from Isliid, it's impossible to determine if this is the truth. What's clear is that she's a very capable alchemist, to the point that she can create anything in existence if given the right materials. She's quite skilled in magic, too. deepening the mystery surrounding her even further...",
                evo5: "An incident reveals that Rem is a magician who was once stationed in the Isliidian military. That same incident led to her leaving the army and setting out to become an alchemist. Her goal: to create the Philosopher's Stone and revive the dead. When she found out that the soul she was hoping to revive didn't wish to return to life, she gave up on her original goal, choosing to use both magic and alchemy to enrich the lives of others.",
                evoawk: "She is the greatest of all alchemists who is secretly approaching the world's rule of nature. Having the powers of once trying to revive the dead, she never stands at the stage. This is because she is getting the closest as she can to the truth of alchemy. Possessing the powers to overturn common sense and making the impossible possible, she cannot take the wrong path. With this firm belief. she will generously show her ultimate powers of an achemist to the adventurer standing against the world's danger"
            }
    },
    { //Arosdea - Complete
        id: 101176112,
        name: "Arosdea",
        rank: "F",
        attribute: "Fire",
        type: "Dragon",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101174112.png",
                detail5: "/db/Units/Detail/unit_detail_101175112.png",
                detailawk: "/db/Units/Detail/unit_detail_101176112.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101174112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101175112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101176112.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7536,
                hpplus: 999,
                atk: 3017,
                atkplus: 462,
                def: 2030,
                defplus: 375
            },
        skillset: 
            {
                skill: "8s CT - 2300% Fire DMG (PHY). Chance (15%) to inflict Burn.",
                skillbreak: 1800,
                arts: "20000% Fire DMG (PHY). Increase Arts by 30 + For 20s, increase Allies' DMG to Enemies in BREAK by 100%.",
                artsbreak: 2600,
                truearts: "44000% Fire DMG (PHY). For 15s, reduce Enemies' DMG RES by 50%. For 30s, gain a DMG barrier that negates 5000 DMG to self.",
                trueartsbreak: 3000
            },
        passive: 
            {
                ability1: "Increase DMG to Dragon Enemies by 100%.",
                ability2: "Increase Earth RES by 15%.",
                ability3: "When near-death, increase own Arts by 6/s."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_fire4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_fire3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                    mat3amt: 2,
                    hover3: "Degilmagna",
                }
            },
        dream: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragIfrit.png`,
                        mat3amt: 2,
                        hover3: `Ifrit`
                    },
                    passive: {
                        ability1: `Increase CRI DMG by 70%.`,
                        ability2: `Increase CRI rate by 100% when Near-death.`
                    }
                },
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragTonetaurus.png`,
                        mat3amt: 2,
                        hover3: `Tonetaurus`
                    },
                    passive: {
                        ability1: `Increase Break Power against Earth enemies by 70%.`,
                        ability2: `Increase own Arts gauge by 2/s.`
                    }
                },
            },
        lore: 
            {   
                evo4: "Inflicted with dracosis, which causes a person's body to transform into that of a dragon, Arosdea understood that he would one day lose all reason, becoming nothing more than a mad dragon. However, a turning point manifested in the form of a lone witch, who sealed his dracosis and returned him to his senses. When he came to, what joined him was his sense of powerlessness and a furious rage.",
                evo5: "A half-dragon warrior transformed by a disease known as dracosis. While an encounter with a witch saved him from becoming a mad dragon, the curse of the disease continue to haunt him. Forced to barely repress his destructive impulses. Arosdea searched battlefields for someone strong enough to stop him. However, his draconic power was too much to bear and no such savor appeared. He then set off to find the witch who sealed his powers.",
                evoawk: "A warrior who gained great power from dracosis. As he fought an archer, the seal placed over his half-dragon self broke and Arosdea realized his true power, but he lost his senses and rampaged to scorch the whole world. Unable to stand by, the witch solved the problem with an ice age. This is now passed down in legends as the Winter of Thaumazein."
            }
    },
    { //Selia (Regular) - Complete
        id: 101186212,
        name: "Selia",
        rank: "C",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101184212.png",
                detail5: "/db/Units/Detail/unit_detail_101185212.png",
                detailawk: "/db/Units/Detail/unit_detail_101186212.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101184212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101185212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101186212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/healLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6499,
                hpplus: 984,
                atk: 2549,
                atkplus: 392,
                def: 2574,
                defplus: 396
            },
        skillset: 
            {
                skill: "9s CT - 1700% Water DMG (MAG). Chance to reduce Enemies' DMG by 20% + For 8s, reduce Enemies' Freeze RES by 15%.",
                skillbreak: 1350,
                arts: "12800% Water DMG (MAG). 80% chance to inflict Freeze for 6s. Cleanse Allies' and Enemies' Burn.",
                artsbreak: 2100,
                truearts: "25000% Water DMG (MAG). 100% chance to inflict Freeze for 8s. Cleanse Allies' and Enemies' Burn + For 8s, reduce Enemies' Water RES by 20%.",
                trueartsbreak: 3800
            },
        passive: 
            {
                ability1: "Increase Fire RES by 35%.",
                ability2: "Increase DMG to Fire Enemies by 35%.",
                ability3: "Increase Allies' Burn and Freeze RES by 30%. Increase all Allies' Critical Rate by 50% against Frozen Enemies. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_water3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                    mat3amt: 1,
                    hover3: "Geo-Zagnus",
                    mat4: "/db/Icons/dungeon_drops/fragAerugraxes.png",
                    mat4amt: 3,
                    hover4: "Aerugraxes",
                    mat5: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat5amt: 1,
                    hover5: "Oldeus"
                }
            },
        lore: 
            {   
                evo4: "One of the six witches in 'Grimm Stella', a coven of witches written about in ancient histories, Selia is a master of powerful ice magic, and possesses a reckless personality that sees curiosity guiding her every action. Oblivious to her surroundings, once she sets her mind to something, it's almost certainly going to cause some serious damage. She caused countless incidents prior to the Great War, receiving the nickname 'Trouble Witch'.",
                evo5: "Selia travelled the world, eventually encountering a soldier whose life she had inadvertently saved. Bored of his pleas to kill him, she suddenly realised she had a plan. 'He can definitely kill you,' she said, pointing at the archer who conveniently walked by. In moments, the dragon soldier and archer began to fight. Happy that her plan had succeeded, she strolled off to observe the furious battle raging on Thaumazein Plain at the peak of summer.",
                evoawk: "The fierce battle ended before the eyes of distant watcher Selia. However, the massive flames of the warriors' final clash spread in a great wildfire, threatening to consume the entire wilderness. Selia saw this, and used her Absolute Zero sorcery. The plains were frozen solid, and the rampaging flames died out. This disaster became known as the Thaumazein Winter, when the grain belt was buried in snow. The truth of what happened is recorded nowhere, but she surely doesn't mind."
            }
    },
    { //Ashe Toto - Complete, Dream Evo Complete
        id: 101196312,
        name: "Ashe Toto",
        rank: "B-",
        attribute: "Earth",
        type: "Spirit",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101194312.png",
                detail5: "/db/Units/Detail/unit_detail_101195312.png",
                detailawk: "/db/Units/Detail/unit_detail_101196312.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101194312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101195312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101196312.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6185,
                hpplus: 939,
                atk: 2432,
                atkplus: 411,
                def: 1955,
                defplus: 350
            },
        skillset: 
            {
                skill: "9s CT - 1900% Earth DMG (PHY).",
                skillbreak: 1600,
                arts: "15000% Earth DMG (PHY). For 15s, increase Allies’ evasion by 30%.",
                artsbreak: 2000,
                truearts: "30000% Earth DMG (PHY). Cure Allies’ Status Ailments (Except Faint) + For 20s, increase Earth Allies’ Arts by 3/s.",
                trueartsbreak: 3400
            },
        passive: 
            {
                ability1: "Heal 10 HP/s.",
                ability2: "Increase own Equipment CT REC by 5%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_earth3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat3amt: 2,
                    hover3: "Tonetaurus",
                }
            },
            dream: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        hover2: `Frostsprite Soulgem`,
                        mat2amt: 50,
                        mat3: `/db/Icons/dungeon_drops/fragFreydal.png`,
                        mat3amt: 2,
                        hover3: `Freydal`
                    },
                    passive: {
                        ability1: `Increase all Allies' Water RES by 20%. Does not stack with Abilities with the same name.`,
                        ability2: ``
                    }
                },
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragElpneumas.png`,
                        mat3amt: 2,
                        hover3: `Elpneumas`
                    },
                    passive: {
                        ability1: `Adds "Heal Allies by 30% of their Max HP" effect to Arts.`,
                        ability2: ``
                    }
                }
        },
        lore: 
            {   
                evo4: "A spirit archer who fought prior to the Great War. A fox child, Toto was taken in by Eleanor, one of the six Great Witches, and spent his days training his archery skills in the depths of the forest. One day, he was ordered by Eleanor to chase down a troublesome witch who was disrupting nature's providence. After leaving the forest and travelling the world, he finally found Selia at Thaumazein Plain. Suddenly, a new, draconic challenger attacked...",
                evo5: "An archer who chased after the witch Selia, Ashe Toto was forced into a fierce fight against a newfound assailant. Dodging a furious flurry of slashes and moving in for a counterattack, Toto's skills with the bow were at their sharpest. As the swordsman attacked with all his might, Toto loosed an arrow to end it all... and realised he was going to lose. The explosion from their strikes engulfed him, and everything in his vision suddenly turned pure white...",
                evoawk: "The fiery Thaumazein Plain was once transformed into a frigid wasteland by the Witch of Disaster. The disgrace at being saved by his target and the anger at his own weakness that allowed it awakened the power sleeping within him. He fired an arrow with the whole of that strength, intending to bring the chase to an end. However, all that accomplished was ridding the Plain of her cold magic, making him its unwitting saviour."
            }
    },
    { //Duran - Complete
        id: 101206412,
        name: "Duran",
        rank: "B-",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101204412.png",
                detail5: "/db/Units/Detail/unit_detail_101205412.png",
                detailawk: "/db/Units/Detail/unit_detail_101206412.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101204412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101205412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101206412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6792,
                hpplus: 999,
                atk: 2382,
                atkplus: 337,
                def: 2792,
                defplus: 428
            },
        skillset: 
            {
                skill: "10s CT - 3300% Light DMG (PHY). During this Skill, increase own DMG RES by 30%.",
                skillbreak: 2400,
                arts: "For 10s, Enemies take [??? × DMG they inflict on this unit] Light DMG (PHY) + For 15s, heal Allies' HP by 300/s & Allies gain a DMG barrier that negates 1200 DMG.",
                artsbreak: 0,
                truearts: "For 10s, take all DMG dealt to Allies instead, Enemies take [??? × DMG they inflict on this unit] Light DMG (PHY), and gain a DMG barrier that negates 9000 DMG to self. During True Arts, increase Paralysis and Freeze RES by 100%.",
                trueartsbreak: 0
            },
        passive: 
            {
                ability1: "When an Ally dies (except self), revive that Ally with 50% HP. Ability can only be activated once per Quest.",
                ability2: "Increase PHY RES by 20%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat3amt: 4,
                    hover3: "Code-XTF",
                }
            },
        lore: 
            {   
                evo4: "A Human soldier who fought prior to the Great War, Duran was known as a true and dependable knight. His hometown suffered a great fire. Though countless innocents fled for their lives in front of him, he could do nothing against the roaring flames. At that moment, a girl suddenly appeared in front of him, summoning a great blizzard to extinguish the fire. From that day forward, Duran abandoned his post as a knight to embark on a journey.",
                evo5: "Once a knight, Duran left his post to embark on a journey. As he travelled the world, Duran fought for the powerless in every land, eventually leading to him being renowed as a wandering hero. Needless to say, Duran himself never claimed to be any such thing. One day, he heard rumours of a dread swordsman running rampant across the land. Without delay, he began heading to the area where this murderous monster was rumoured to be...",
                evoawk: "Duran encountered the crazed swordsman who was terrorizing the northern towns. From how the warrior wielded his sword with abandon, he knew the weapon had possessed him. After a heated confrontation, the knight suffered near-fatal injuries but not a single innocent was harmed. Ever since, the legend of Duran and his shield spread across the land."
            }
    },
    { //Zenon - Complete
        id: 101216512,
        name: "Zenon",
        rank: "A-",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101214512.png",
                detail5: "/db/Units/Detail/unit_detail_101215512.png",
                detailawk: "/db/Units/Detail/unit_detail_101216512.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101214512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101215512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101216512.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6169,
                hpplus: 935,
                atk: 2848,
                atkplus: 437,
                def: 2495,
                defplus: 384
            },
        skillset: 
            {
                skill: "10s CT - 1600% Dark DMG (MAG). During this Skill, increase own BE generation by 60%.",
                skillbreak: 1350,
                arts: "13000% Dark DMG (MAG). Heal own HP by 3% of DMG dealt by this Arts + For 15s, increase Allies' Arts by 3/s.",
                artsbreak: 2100,
                truearts: "30000% Dark DMG (MAG). Consume 10% of own HP, increase Allies' Arts by 40 + For 10s, increase Allies' MAG DMG by 30%.",
                trueartsbreak: 4400
            },
        passive: 
            {
                ability1: "Increase DMG to Human Enemies by 80%.",
                ability2: "Ignore 50% of Enemies' DEF."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_fire4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_dark3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 1,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat4amt: 2,
                    hover4: "Oldeus",
                    mat5: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat5amt: 2,
                    hover5: "Code-XTF"
                }
            },
        dream: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragGeoZagnus.png`,
                        mat3amt: 2,
                        hover3: `Geo Zagnus`
                    },
                    passive: {
                        ability1: `Increase own Freeze RES by 100%.`,
                        ability2: `Increase own DMG against Light enemies by 100%.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragOldeus.png`,
                        mat3amt: 2,
                        hover3: `Oldeus`
                    },
                    passive: {
                        ability1: `Increase all Demon allies' Arts gauge by 2/s (No Duplication).`,
                        ability2: ``
                    }
                },
            },
        trueweapon: 
            {
                name: "Fe'Ode",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_105671.png",
                skill: "50s CT - 6800% Dark DMG (MAG). For 10s, increase own Arts by 2/s.",
                skillbreak: 1200,
                passive: 
                    {
                        ability1: "When equipped by Zenon, increase DMG to Human Enemies by 100%. Does not stack with Abilities of the same name.",
                        ability2: "Increase BE generation by 20%."
                    }
            },
        lore: 
            {   
                evo4: "A spellblade who served as bodyguard to Auge, one of the six Demon Generals. Zenon was dedicated to Auge in heart and soul, and would perform any job requested of him, no matter how low, until the day he was imprisoned for a crim he didn't commit. This was all a plot by a Demon commander who was anxious about Zenon's growing power. Furious at this betrayal, he howled revenge, and his magic sword Fah Ode came to him as if in answer.",
                evo5: "A demonic spellblade betrayed by his own people, Zenon used his sword Fah Ode to dismiss the officer who conspired against him... permanently. As the life drained from his body, the officer revealed Auge was partially responsible for carrying out the betrayal. As hatred welled up within him, his sword unleashed even greater power, reducing his surroundings to a mountain of corpses and debris. And he had no idea this was all part of Auge's plan...",
                evoawk: "Zenon loathed the whole world, so took up his cursed sword and made blood rain throughout the land. And then a single knight rose to stop him. The battle that suddenly erupted in the city streets was so fierce it changed the very shape of the land. During the battle. Zenon finally understood the reason his cursed sword came to his hand. And that understanding released the true power of the blade. The attack then unleashed, fueled by the hatred and bloodlust of the blade's will. destroyed everything in the area--including his own life."
            }
    },
    { //Soleil - Complete
        id: 101226112,
        name: "Soleil",
        rank: "F",
        attribute: "Fire",
        type: "Machine",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101224112.png",
                detail5: "/db/Units/Detail/unit_detail_101225112.png",
                detailawk: "/db/Units/Detail/unit_detail_101226112.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101224112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101225112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101226112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6424,
                hpplus: 999,
                atk: 2952,
                atkplus: 454,
                def: 2304,
                defplus: 355
            },
        skillset: 
            {
                skill: "10s CT - 2000% Fire DMG (MAG). If a Burning enemy is present, deal 3000% Fire DMG (MAG) instead.",
                skillbreak: 1000,
                arts: "14000% Fire DMG (MAG). 70% chance to inflict Burn for 15s, Burn self + For 20s, increase own Critical Rate by 100% while Burned.",
                artsbreak: 1800,
                truearts: "33000% Fire DMG (MAG). For 15s, reduce Burned Enemies' DMG RES by 25% and increase own DMG by 100% while Burned.",
                trueartsbreak: 2000
            },
        passive: 
            {
                ability1: "Increase DMG to Beast Enemies by 30%.",
                ability2: "Overheat: Every 3rd Skill used, apply Burn to self. While Burned, increase Skill CT REC by 100% and Freeze RES by 100%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_fire4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_fire3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat3amt: 1,
                    hover3: "Code-XTF",
                    mat4: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat4amt: 1,
                    hover4: "Flogassa"
                }
            },
        lore: 
            {   
                evo4: "A Machine gunner who fought before the Great War. Soleil was originally a little girl's automaton. One day when the girl was out on an excursion, the house came under attack and the girl never returned. Having lost her master, Soleil concluded from the flower petals left by the assailant that the Funereal Bloom was responsible. After a defeat at her hands, Soleil sought out the Dragon blacksmith Rhodia.",
                evo5: "A Machine gunner who chased after a witch. After meeting with the Dragon blacksmith, Soleil gained the strength she needed and headed after the witch Pola to enact her revenge. Although the two are an even match for each other, Soleil was never built for combat and her body slowly buckled under the force of her weaponry. Realizing a long fight was out of the question, Soleil disabled the limiter on her weapons, unleashing a final salvo at Pola.",
                evoawk: "A machine gunner with the Demonic Warrior in her sights, locked and loaded, is out for revenge. The strong-willed shooter needs to team up with the Beast Martial Artist and hone her technique. Unaware of her ebbing endurance, she slowly ripped herself apart in battle. Right before her functions halted, the Beast combatant bested the Demon with his martial prowess. After exacting her revenge, fading in and out of consciousness, she cried for the first time in her life."
            }
    },
    { //Saar - Complete
        id: 101236212,
        name: "Saar",
        rank: "C",
        attribute: "Water",
        type: "Beast",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101234212.png",
                detail5: "/db/Units/Detail/unit_detail_101235212.png",
                detailawk: "/db/Units/Detail/unit_detail_101236212.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101234212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101235212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101236212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6504,
                hpplus: 965,
                atk: 2712,
                atkplus: 416,
                def: 2480,
                defplus: 382
            },
        skillset: 
            {
                skill: "6s CT - 1750% Water DMG (PHY). Increase own Arts by 10. If this Skill crits, reduce Enemies' accuracy by 30% for 5s.",
                skillbreak: 900,
                arts: "12000% Water DMG (PHY). Permanently increase DMG and Critical Rate by 20% (Up to 100%).",
                artsbreak: 1950,
                truearts: "28000% Water DMG (PHY). For 15s, increase own Skill CT REC and Critical DMG by 100%.",
                trueartsbreak: 3500
            },
        passive: 
            {
                ability1: "Increase MAG RES by 20%.",
                ability2: "Increase PHY evasion by 30%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_water3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat3amt: 2,
                    hover3: "Freydal",
                }
            },
        lore: 
            {   
                evo4: "A half-demon, half-beast fighter, Saar was timid during childhood, but was filled with an urge to grow stronger after a witch saved his life, and trained furiously with the witch's scorpion familiars. One day, Saar heard rumors of a gunner seeking revenge against the Funeral Bloom for murdering her master. To confirm this rumor, Saar headed to the Dragon blacksmith who provided modifications to the gunner.",
                evo5: "A half-Demon, half-Beast fighter who was once saved by a witch. The blacksmith, who had modified the gunner now hunting the witch, was moved by Saar's story, and taught him traditional Draconic martial arts. From there, he headed to the site of the battle between the witch and the gunner, who was just about to unleash a final salvo. Saar threw himself in front of the witch without hesitation, blocking and negating the incoming salvo.",
                evoawk: "Some time after putting a stop to the battle between the witch and the musketeers, Saar saw that blacksmith to whom he owed so much was feuding with the mysterious Demons. When he rushed to the battlefield and learned the truth behind their conflict, Saar's searing rage awakened the slumbering powers of his dual heritage. The witch and musketeers joined the fight against the demons, but the blacksmith fell at the height of the battle protecting Saar. Entrusted with the smith's legacy and with a mighty roar, Saar now unleashes secret Dragon techniques in his war against the Demons and their schemes."
            }
    },
    { //Pola - Complete
        id: 101246312,
        name: "Pola",
        rank: "C",
        attribute: "Earth",
        type: "Demon",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101244312.png",
                detail5: "/db/Units/Detail/unit_detail_101245312.png",
                detailawk: "/db/Units/Detail/unit_detail_101246312.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101244312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101245312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101246312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7212,
                hpplus: 999,
                atk: 2545,
                atkplus: 391,
                def: 2580,
                defplus: 397
            },
        skillset: 
            {
                skill: "9s CT - 2280% Earth DMG (PHY). 25% chance to inflict Paralysis for 3s. Increase own Arts by 3.",
                skillbreak: 1250,
                arts: "16000% Earth DMG (PHY). 100% chance to inflict Poison for 15s. Increase DMG of Poison based on Enemies' HP (up to 2000 DMG).",
                artsbreak: 2100,
                truearts: "20200% Earth DMG (PHY). Heal own HP by 3% of DMG dealt by own True Arts + For 20s, reduce Enemies' Paralysis and Poison RES by 35%.",
                trueartsbreak: 3750
            },
        passive: 
            {
                ability1: "Increase Water RES by 50%.",
                ability2: "Increase Freeze RES by 100%.",
                ability3: "Heal own HP by 10/s.",
                ability4: "Increase accuracy by 30% against Paralyzed or Poisoned Enemies. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_earth3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat3amt: 4,
                    hover3: "Goldola",
                    mat4: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat4amt: 2,
                    hover4: "Mavroskia",
                    mat5: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat5amt: 3,
                    hover5: "Tonetaurus"
                }
            },
        lore: 
            {   
                evo4: "One of the six witches in 'Grim Stella', Pola possesses incredible power and is feared as the 'Funereal Bloom'. With a unique set of values, Pola believed the singing voice of a young Spirit boy she knew was the definition of beauty. However, a bandit she was chasing unleashed a curse on this boy, stealing his voice and vanishing without warning. Having been deprived of that which she loved most, she began an indiscriminate rampage on the world.",
                evo5: "A witch feared as the 'Funereal Bloom'. As her rampage continued, a female Machine appeared before Pola and commenced a furious assault against her. In response, Pola unleashed her previously sealed true power. To her surprise, she realised that she preceived this automaton fighting heedlessly for its life as beautiful. Her lips curled into a smile. As the flames drew closer, Pola slowly closed her eyes.",
                evoawk: "Her protector is a half-Beast half-Demon youth who saved her on a whim. She was completely dumbfounded by this, but she used her special plant based defensive magic to protect an overwhelmed automaton that had changed its target to the Demons. Until then, their only choice had been to fight alongside their enemy. And for some reason, this helped her power grow far beyong her previous limits. No one knows how the battle ended or what happened to her later, but the name Witch of Funeral Flowers never appeared in the histories again."
            }
    },
    { //Leon - Complete
        id: 101256512,
        name: "Leon",
        rank: "C",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101254512.png",
                detail5: "/db/Units/Detail/unit_detail_101255512.png",
                detailawk: "/db/Units/Detail/unit_detail_101256512.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101254512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101255512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101256512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6797,
                hpplus: 999,
                atk: 2453,
                atkplus: 378,
                def: 1926,
                defplus: 298
            },
        skillset: 
            {
                skill: "9s CT - 1800% Dark DMG (MAG). 15% chance to inflict Blind for 8s.",
                skillbreak: 2000,
                arts: "13000% Dark DMG (MAG). 80% chance to inflict Blind for 8s + For 20s, reduce Enemies' ATK and DEF by 30%.",
                artsbreak: 2600,
                truearts: "30000% Dark DMG (MAG). For 13s, increase Dark Allies' Arts by 2/s, and Demon Allies' DMG by 30%.",
                trueartsbreak: 2800
            },
        passive: 
            {
                ability1: "Increase Curse RES by 30%.",
                ability2: "Increase Disease RES by 30%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_dark3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragValzandeth.png",
                    mat3amt: 2,
                    hover3: "Valzandeth",
                }
            },
        lore: 
            {   
                evo4: "A Demon warrior, shrouded in mystery. Though mighty enough to control battlefields. Leon preferred to do so from behind the scenes. Upon discovering the existence of a Demon god sealed in another world, he sought to make that strength his own. This involved extracting despair and mutual hatred from many powerful individuals. And it was a single witch's despair that opened the throttle on his master plan.",
                evo5: "A Demon warrior who works in secret to attain the power of a dark god. Leon's fearful scheme called for filling the world with hatred to undo the god's dimensional bonds. He believed he was close to completion by pitting a certain witch and gunner against each other, but a young Beast fighter intervened to stop their conflict. Furious, Leon approached the meddler to finish the plan personally, but a lone blacksmith stood in his way.",
                evoawk: "A Demon warrior who sought to acquire the power of a dark god. With his original plan foiled, he decided to kill the blacksmith, who had a martial artist apprentice. He hoped the apprentice's anger would be enough to free the evil deity, but it instead brought his ambition -- and his life -- to an ironic end. The pugilist's fist pierced Leon's chest with astonishing strength, killing him before the shock faded from his eyes."
            }
    },
    { //Zoroas - Complete + JP ver
        id: 101266512,
        name: "Zoroas",
        rank: "F",
        attribute: "Dark",
        type: "Dragon",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101264512.png",
                detail5: "/db/Units/Detail/unit_detail_101265512.png",
                detailawk: "/db/Units/Detail/unit_detail_101266512.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101264512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101265512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101266512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7110,
                hpplus: 999,
                atk: 2600,
                atkplus: 388,
                def: 2450,
                defplus: 373
            },
        skillset: 
            {
                skill: "10s CT - 1900% Dark DMG (PHY). Increase Arts by 20 if own Skill crits.",
                skillbreak: 1200,
                arts: "13000% Dark DMG (PHY). For 15s, increase Allies' DMG by 30%.",
                artsbreak: 1800,
                truearts: "34000% Dark DMG (PHY). For 15s, increase own Critical Rate by 100%, own DMG to Demon Enemies by 150%.",
                trueartsbreak: 2500
            },
        skillsetJP: 
            {
                skill: "10s CT - 1900% Dark DMG (PHY). Increase Arts by 20 if own Skill crits.",
                skillbreak: 1200,
                arts: "25000% Dark DMG (PHY). For 15s, increase Allies' DMG by 70%.",
                artsbreak: 1800,
                truearts: "60000% Dark DMG (PHY). For 25s, increase own Critical Rate by 100% and DMG against Demon Enemies by 200%.",
                trueartsbreak: 2500
            },
        passive: 
            {
                ability1: "Increase DMG to Demon Enemies by 100%."
            }, 
        dreamJP: {
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                        mat3amt: 2,
                        hover3: "Wilkes",
                    },
                    passive: {
                        ability1: `Increase own CRI DMG by 50%.`,
                        ability2: `When above 80% HP, increase own Arts by 3/s.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragOldeus.png",
                        mat3amt: 2,
                        hover3: "Oldeus",
                    },
                    passive: {
                        ability1: `Increase Dark allies' Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own Accuracy by 30%.`
                    }
                }
            },
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_fire4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_dark3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat3amt: 2,
                    hover3: "Mavroskia",
                }
            },
        lore: 
            {   
                evo4: "A Dragon mercenary active prior to the Great War. Zoroas participated in countless battles in search of powerful opponents. Though currently known as a master of armed combat, he was once feared only for the destructive power of his iron ball. That is. until he lost it in a battle. prompting him to draw a sword and begin slaying foes immediately. The rumor from that day forward was that the iron ball was merely to keep a limit on his overwhelming strength.",
                evo5: "A Dragon mercenary who sought powerful foes. One day, when Zoroas had grown frustrated over a lack of worthy opponents, a God appeared before him. After a brief exchange, this knight burst into a rage, unleashing her jeweled blade's power, but Zoroas simply smiled and met the attack at full strength. This fight was stopped, however, by two Giants and a girl. Readying his furious iron ball, the young girl's magical power soon exploded.",
                evoawk: "At the end of his journey with the Spirit girl, Zoroas confronted a terrible monster and struggled in battle against its magic-devouring maw. When all seemed lost, Zoroas noticed the girl casting a sealing magic spell, completely unguarded. Smiling with abandon, Zoroas turned back to face the Demon beast that was now charging towards the girl. \"Whatever it takes to win!\" Zoroas said to the shocked Spirit girl. It was the first time that Zoroas, someone who had selfishly lived for only to fight and defeat poweful foes in battle, fought instead to protect another."
            }
    },
    { //Zechsia - Complete
        id: 101276412,
        name: "Zechsia",
        rank: "F",
        attribute: "Light",
        type: "God",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101274412.png",
                detail5: "/db/Units/Detail/unit_detail_101275412.png",
                detailawk: "/db/Units/Detail/unit_detail_101276412.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101274412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101275412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101276412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6660,
                hpplus: 999,
                atk: 2664,
                atkplus: 411,
                def: 2256,
                defplus: 350
            },
        skillset: 
            {
                skill: "6s CT - 1900% Light DMG (PHY). Increase own Arts by 5.",
                skillbreak: 2300,
                arts: "10000% Light DMG (PHY). Increase own Arts by 50.",
                artsbreak: 3700,
                truearts: "26000% Light DMG (PHY). For 25s, increase own Arts by 5/s and increase Break PWR by 100%.",
                trueartsbreak: 4000
            },
        passive: 
            {
                ability1: "Increase evasion by 15%.",
                ability2: "Increase Allies' Break PWR by 15%. Does not stack with Abilities of the same name.",
                ability3: "Increase DMG to Human Enemies by 60%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat3amt: 2,
                    hover3: "Radachne",
                }
            },
        lore: 
            {   
                evo4: "A knight who was part of a company of God knights in ancient times. She despised being underestimated and was always seen wearing a full suit of armour. As a knight in service to the higher Gods of order, she saw the commands of the higher Gods and order itself as absolute. Her sternness was so great that she would cut down any knight who expressed a lack of faith where they stood. Zechsia, ever loyal, was given a divine mission: to slay a mercenary whose intervention in battles caused chaos and confusion.",
                evo5: "As a God knight dedicated to preserving order, Zechsia's rage was directed at a Dragon mercenary who fought and created chaos for his own enjoyment. Unleashing the power of the jewelled sword given to her by the high Gods, she began a furious assault in the name of her divine mission. The battle soon reached a fever pitch. Right as two Giants entered the fray, a tearful young girl appeared and unleashed tremendous magical power, swallowing up all in the vicinity.",
                evoawk: "Zechsia pursued the Dragon mercenary, but grew skeptical of the justness of her orders upon seeing a kind young girl travelling happily with him. Her doubts about slaying the supposedly wicked Dragon were comfirmed when she witnessed him fearlessly protect the girl from a beast. Zechsia drew her sword against the beast to save the wounded mercenary, marking the first time in her entire life that she disobeyed a divine order."
            }
    },
    { //Zecht - Complete
        id: 101286512,
        name: "Zecht",
        rank: "F",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101284512.png",
                detail5: "/db/Units/Detail/unit_detail_101285512.png",
                detailawk: "/db/Units/Detail/unit_detail_101286512.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101284512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101285512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101286512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6384,
                hpplus: 808,
                atk: 2640,
                atkplus: 340,
                def: 2124,
                defplus: 275
            },
        skillset: 
            {
                skill: "8s CT - 2200% Dark DMG (MAG). 50% chance to inflict Blind for 8s. Increase own Arts by 10.",
                skillbreak: 850,
                arts: "13500% Dark DMG (MAG). For 8s, increase Dark Allies' Evasion and DMG by 30%.",
                artsbreak: 1900,
                truearts: "30000% Dark DMG (MAG). 100% chance to inflict Blind for 8s. If Enemy was Blinded by this True Arts, for 15s, reduce DMG RES of that Enemy by 40%.",
                trueartsbreak: 3700
            },
        passive: 
            {
                ability1: "Increase DMG to Beast Enemies by 60%.",
                ability2: "Increase Paralysis RES by 100%.",
                ability3: "Increase BE generation by 20%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_dark3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragValzandeth.png",
                    mat3amt: 4,
                    hover3: "Valzandeth",
                }
            },
        lore: 
            {   
                evo4: "A Human gunner who became renowned prior to the Great War, who uses Nigreid, the Dark Gun. to hunt monsters across the land. Though his actions earned him renown, none knew his reasons for fighting monsters. One explanation is that Zecht's gun became more powerful for every foe he defeated with it. Though he defeated countless monsters, one fateful occasion found him chasing after a fire Dragon...",
                evo5: "A gunner who chased a fire Dragon. After witnessing its symbolic destructive power, Zecht began chasing the fire Dragon, eventually cornering it thanks to the help of friends he met along the way. In accordance with the distant promise it made with the self-destructive Dragon, Nigreid unleashed its full power, unleashing a bullet to end all bullets. As the Dragon fell, the Dark gun consumed its soul, shining brightly.",
                evoawk: "The slain fire Dragon resurrected as a flame elemental. Zecht fought back, but its massive power was impossible to endure in his wounded state. Defeat seemed certain until his friends risked their lives to lend him strength. Grateful, he used that additional might to awaken Nigreid's true form, allowing it to absorb the Dragon's flames. By combining that energy with his spirit, it released an awesome blast which destroyed his archenemy for good."
            }
    },
    { //Aristela - Complete + JP ver
        id: 101296212,
        name: "Aristela",
        rank: "A-",
        attribute: "Water",
        type: "Spirit",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101294212.png",
                detail5: "/db/Units/Detail/unit_detail_101295212.png",
                detailawk: "/db/Units/Detail/unit_detail_101296212.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101294212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101295212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101296212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/healLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/healLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6074,
                hpplus: 921,
                atk: 2407,
                atkplus: 371,
                def: 2294,
                defplus: 354
            },
        skillset: 
            {
                skill: "9s CT - 800% Water DMG (MAG). Heal Allies' HP by 120.",
                skillbreak: 400,
                arts: "Heal Allies' by 35% of their max HP, cure Allies' status ailments (except Faint and Disease).",
                artsbreak: 0,
                truearts: "17800% Water DMG (MAG). Heal Allies' by 50% of their max HP + For 20s, heal Allies' HP by 200/s.",
                trueartsbreak: 2500
            },
        skillsetJP: 
            {
                skill: "9s CT - 800% Water DMG (MAG). Heal Allies' HP by 120.",
                skillbreak: 400,
                arts: "Heal Allies' by 40% of their max HP, cure Allies' status ailments (except Faint and Disease).",
                artsbreak: 0,
                truearts: "40000% Water DMG (MAG). Heal Allies' by 50% of their max HP + For 20s, heal Allies' HP by 200/s.",
                trueartsbreak: 2500
            },
        passive: 
            {
                ability1: "Increase MAG RES by 25%.",
                ability2: "Increase Allies' max HP and MAG DMG by 15%. Does not stack with Abilities of the same name."
            }, 
        dreamJP: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                        mat3amt: 2,
                        hover3: "Freydal",
                    },
                    passive: {
                        ability1: `Increase own Freeze RES by 100%.`,
                        ability2: `Increase own Heal Amount by 25% (excluding Lifesteal).`
                    }
                },
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragElpneumas.png",
                        mat3amt: 2,
                        hover3: "Elpneumas",
                    },
                    passive: {
                        ability1: `Increase own Arts by 2/s.`,
                        ability2: `Increase allies' Skill CT Speed by 10%. Does not stack with Abilties of the same name.`
                    }
                }
            },
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_water3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                    mat3amt: 5,
                    hover3: "Geo-Zagnus",
                    mat4: "/db/Icons/dungeon_drops/fragAerugraxes.png",
                    mat4amt: 4,
                    hover4: "Aerugraxes",
                    mat5: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat5amt: 1,
                    hover5: "Elpneumas"
                }
            },
        lore: 
            {   
                evo4: "A Spirit mage and member of the 'Grim Stella' coven in ancient Raktehelm. Aristela had powerful magic from a young age, and would later be counted among the Six Witches. A bookworm, it's written that she read the entirety of the coven's archives, numbering thousands of books. On a visit to broaden her mind, she heard a rumor of two rampaging Giants. Unable to see the villagers suffer any more, she headed to the Giants' abandoned fortress.",
                evo5: "One of the supremely powerful Six Witches. To reform the two troublemaking Giants, she brought them on a journey to help people. As they traveled the world helping those in need, they found two warriors fighting against each other. The Giants attempted to intervene, but the situation worsened, dealing huge damage to the surroundings. Aristela tried her best to mitigate the damage, but came to be known as the 'Annihilation Witch' instead...",
                evoawk: "While on a journey with her four companions. she heard tell of a beast that would devour the world, and they ventured to find it without pausing for thought. She faced the immensely powerful creature and prepared all her power to unleash a sorcerers attack. Yet the only result was that the monster absorbed the attack and grew even more powerful. Seeing that mere force would not defeat the beast, she focused the power offered by her comrades. Together, they manipulated the ancient power of the seals to banish it to another world, yet the cost was the lives of her comrades."
            }
    },
    { //Zoldes - Complete + JP ver
        id: 101306112,
        name: "Zoldes",
        rank: "F",
        attribute: "Fire",
        type: "Giant",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101304112.png",
                detail5: "/db/Units/Detail/unit_detail_101305112.png",
                detailawk: "/db/Units/Detail/unit_detail_101306112.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101304112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101305112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101306112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6151,
                hpplus: 999,
                atk: 2120,
                atkplus: 391,
                def: 2136,
                defplus: 330
            },
        skillset: 
            {
                skill: "10s CT - 2400% Fire DMG (PHY). Increase DMG RES by 10% during Skill.",
                skillbreak: 2400,
                arts: "8000% Fire DMG (PHY). For 5s, increase Allies' (except self) DMG RES by 100%.",
                artsbreak: 3000,
                truearts: "[27000% + 3% per DEF of this unit] Fire DMG (PHY). For 20s, gain a 3000 Barrier to self.",
                trueartsbreak: 3000
            },
        skillsetJP: 
            {
                skill: "10s CT - 2400% Fire DMG (PHY). Increase DMG RES by 10% during Skill.",
                skillbreak: 2400,
                arts: "8000% Fire DMG (PHY). For 6s, increase Allies' (except self) DMG RES by 100%.",
                artsbreak: 3000,
                truearts: "[50000% + 5% per DEF of this unit] Fire DMG (PHY). For 20s, gain a 5000 Barrier to self.",
                trueartsbreak: 3000
            },
        passive: 
            {
                ability1: "Increase MAG RES by 20%.",
                ability2: "Increase Burn RES by 60%.",
                ability3: "When near-death, increase DEF by 30%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_fire4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_fire3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                    mat3amt: 2,
                    hover3: "Degilmagna",
                }
            },
            dreamJP: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                        mat3amt: 2,
                        hover3: "Flogassa",
                    },
                    passive: {
                        ability1: `Increase Fire allies' Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own Burn RES by 100%.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragMavroskia.png",
                        mat3amt: 2,
                        hover3: "Mavroskia",
                    },
                    passive: {
                        ability1: `When Near-Death, increase own DMG RES by 20%.`,
                        ability2: `Increase own Arts by 3/s.`
                    }
                }
            },
        lore: 
            {   
                evo4: "A Giant warrior who fought prior to the Great War. Zoldes was a famous fighter among the Giants renowned for his many battlefield feats. However, after many years of fighting against other races, he reacted violently to a perceived insult after the end of hostilities and struck down the emissary of another race. Upset about the perceived weakness of his fellow Giants, Zoldes left with his brother Olgeth on a rampage of destruction.",
                evo5: "A Giant warrior who went on a rampage prior to the Great War. One day, a young girl delivered a devastating defeat to him, deflecting all of his mighty blows with magic. Driven to rage, he unleashed blow after blow, destroying everything around him. Wen he awoke inside the collapsed castle, he realized the girl had protected him from a falling rock with magic. Admitting his defeat, he asked to travel with the girl from that day onward.",
                evoawk: "Realizing their disadvantage against the Magibeast horde, the elder of the giant brothers decides to fight solo to protect his brother and the young Spirit girl. Possessed by some divine strength, he slaughters the enemy host without letting a single enemy touch the others. After the carnage, he looks to see the girl running towards him, tears in her eyes. He gazes upwards, and with a hearty laugh, falls into an eternal slumber."
            }
    },
    { //Rhodia - Complete
        id: 101316412,
        name: "Rhodia",
        rank: "F",
        attribute: "Light",
        type: "Dragon",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101314412.png",
                detail5: "/db/Units/Detail/unit_detail_101315412.png",
                detailawk: "/db/Units/Detail/unit_detail_101316412.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101314412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101315412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101316412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6600,
                hpplus: 999,
                atk: 2760,
                atkplus: 424,
                def: 2700,
                defplus: 415
            },
        skillset: 
            {
                skill: "8s CT - 2300% Light DMG (PHY). 15% chance to inflict Paralysis for 4s.",
                skillbreak: 2100,
                arts: "12000% Light DMG (PHY). For 20s, increase Allies' DMG by 30%, increase Light Allies' DMG by 20%.",
                artsbreak: 3400,
                truearts: "29800% Light DMG (PHY). For 20s, reduce enemies' Light RES by 30%, increase Allies' DMG to Dragon Enemies by 30%.",
                trueartsbreak: 4000
            },
        passive: 
            {
                ability1: "Increase Paralysis RES by 100%.",
                ability2: "Increase DMG to Dragon Enemies by 80%.",
                ability3: "Increase Allies' Skill CT REC and DMG to Dark Enemies by 25%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 2,
                    hover3: "Fosradius",
                }
            },
        lore: 
            {   
                evo4: "A legendary Dragon blacksmith. Single-mindedly obsessed with creating powerful equipment, Rhodia cared little about who used the tools she created. One day, a young man appeared, asking about the whereabouts of a Machine she had done upgrades to. She sensed a powerful will behind his words and decided to teach him Dragon martial arts so that he might realise his mission. From that day forward, Rhodia supposedly acted more warmly to others.",
                evo5: "A Dragon blacksmith spoken of in legend. She taught a half-Beast and half-Demon fighter Dragon martial arts so that he might save a witch. Rhodia began acting on her own and discovered a dark plot involving the witch. While she went after the young man to make sure he knew the truth, she ended up finding the Demon who was responsible for the plot in the first place. To put an end to things for good, she unleashed the power of her transforming scythe and challenged Leon to a duel.",
                evoawk: "The Dragon blacksmith Rhodia and her former student Saar, a young half-Beast half-Demon man, fought a desperate battle against Demon warrior Leon. When Leon struck down with his great axe, Rhodia rushed in to take the blow for Saar, but she suffered a fatal injury. Rhodia knew that her end was nigh, so she confirmed that her student was safe and gave him the armour that she had spent half her life to create. Afterwards, she smiled contentedly and closed her eyes."
    }
    },
    { //Olgeth - Complete + JP ver
        id: 101326312,
        name: "Olgeth",
        rank: "F",
        attribute: "Earth",
        type: "Giant",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101324312.png",
                detail5: "/db/Units/Detail/unit_detail_101325312.png",
                detailawk: "/db/Units/Detail/unit_detail_101326312.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101324312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101325312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101326312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 7704,
                hpplus: 973,
                atk: 2808,
                atkplus: 361,
                def: 2592,
                defplus: 334
            },
        skillset: 
            {
                skill: "10s CT - 1900% Earth DMG (PHY). Increase own Arts by 10.",
                skillbreak: 1200,
                arts: "16000% Earth DMG (PHY). Increase Allies' (except self) Arts by 20.",
                artsbreak: 1800,
                truearts: "28000% Earth DMG (PHY). For 20s, increase Earth Allies' DMG by 100%.",
                trueartsbreak: 2000
            },
        skillsetJP: 
            {
                skill: "10s CT - 1900% Earth DMG (PHY). Increase own Arts by 10.",
                skillbreak: 1200,
                arts: "16000% Earth DMG (PHY). Increase Allies' (except self) Arts by 30.",
                artsbreak: 1800,
                truearts: "48000% Earth DMG (PHY). For 20s, increase Earth Allies' DMG by 150%.",
                trueartsbreak: 2000
            },
        passive: 
            {
                ability1: "Increase PHY RES by 30%.",
                ability2: "Increase DMG to Machine Enemies by 100%.",
                ability3: "Increase Allies' max HP by 500 and DMG to Water Enemies by 25%. Does not stack with Abilities of the same name."
            },
            dreamJP: {
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragGoldola.png",
                        mat3amt: 2,
                        hover3: "Goldola",
                    },
                    passive: {
                        ability1: `When above 80% HP, increase own Arts by 5/s.`
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                        mat3amt: 2,
                        hover3: "Fosradius",
                    },
                    passive: {
                        ability1: `Increase own DMG against Machines by 300%.`
                    }
                }
            },
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_earth3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat3amt: 4,
                    hover3: "Elpneumas",
                }
            },
        lore: 
            {   
                evo4: "A Giant warrior who fought prior to the Great War. Quiet in nature, he always worked in the shadow of his furious brother. Olgeth affected the course of many battles all by himself, but this simply earned him the title of 'The Mad Brother,' When his brother left the Giants to wander on his own, he followed along without saying much. But an encounter with a young girl affected a great change on his weak-willed heart.",
                evo5: "A Giant warrior who made a name for himself prior to the Great War. During his journey, he and his brother were defeated by a girl with incredibly powerful magic. Shocked by this turn of events, Olgeth was filled with an intense desire to join her on her travels. As he grew more powerful, he would end up saving the girl on countless occasions during their journey.",
                evoawk: "Surrounded by a throng of Magibeast, the Giant brothers and their Spirit companion find themselves vastly outnumbered. Against the older brother's demands to save themselves, the younger brother stands and fights alongside him. With a face of certainty and uncharacteristic defiance, the younger brother asserts, 'I'm not leaving my only brother behind.' And with that declaration, they face the Demon horde, side by side."
            }
    },
    { //Riffili - Missing Lore (JP)
        id: 101336512,
        name: "Riffili",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101334512.png",
                detail5: "/db/Units/Detail/unit_detail_101335512.png",
                detailawk: "/db/Units/Detail/unit_detail_101336512.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101334512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101335512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101336512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7620,
                hpplus: 999,
                atk: 2892,
                atkplus: 443,
                def: 2532,
                defplus: 389
            },
        skillset: 
            {
                skill: "7s CT - 1900% Dark DMG (PHY). Heal own HP by 3% of DMG dealt with this Skill.",
                skillbreak: 2300,
                arts: "12800% Dark DMG (PHY). For 15s, increase Allies' Skill CT REC by 30%, Dark Allies' Break PWR by 30%.",
                artsbreak: 4800,
                truearts: "42000% Dark DMG (PHY). For 15s, increase own DMG by [100% - Enemy Break Bar %].",
                trueartsbreak: 6000
            },
        passive: 
            {
                ability1: "Increase Allies' Break PWR by 15%.",
                ability2: "Increase DMG to God Enemies by 100%.",
                ability3: "When near-death, increase Skill CT REC by 70%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_dark3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat3amt: 2,
                    hover3: "Mavroskia",
                }
            },
        lore: 
            {   
                evo4: "Currently unreleased in Global.",
                evo5: "Currently unreleased in Global.",
                evoawk: "Currently unreleased in Global."
            }
    },
    { //Shadie - Complete
        id: 101346212,
        name: "Shadie",
        rank: "C",
        attribute: "Water",
        type: "God",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101344212.png",
                detail5: "/db/Units/Detail/unit_detail_101345212.png",
                detailawk: "/db/Units/Detail/unit_detail_101346212.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101344212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101345212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101346212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7079,
                hpplus: 999,
                atk: 2406,
                atkplus: 370,
                def: 2531,
                defplus: 389
            },
        skillset: 
            {
                skill: "7s CT - Increase own Arts by 10. For 8s, gain a DMG barrier that negates 300 DMG.",
                skillbreak: 0,
                arts: "12800% Water DMG (MAG). For 10s, increase Allies' DMG by 20%. Water Allies' DMG by 20%.",
                artsbreak: 3400,
                truearts: "27000% Water DMG (MAG). For 20s, reduce Enemies' evasion, Status Ailment RES and ATK by 30%.",
                trueartsbreak: 3700
            },
        passive: 
            {
                ability1: "Increase own Equipment CT REC by 15%.",
                ability2: "Increase Poison RES by 60%.",
                ability3: "Increase Burn RES by 80%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_water3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat3amt: 2,
                    hover3: "Freydal",
                }
            },
        lore: 
            {   
                evo4: "A girl of Gods who is after a musketeer. Having no relatives, Shadie was brought up in the woods by a wizard. She grew up cheerfully by her kind foster father but felt bored at times. However, getting involved in a battle between a musketeer and fire dragon, her life changed completely. She fell in love with the musketeer who risked his life to save hers. Believing that he was her soul mate, she decides to follow him.",
                evo5: "A girl of Gods who is after a musketeer. The moment she realised her lifesaver was a magical beast hunter, she became one too. Learning how to use whips by her own, she defeated magical beasts one after another. This was only to get closer to the musketeer, but her skills became more powerful by battling beasts. Finally, she found her soulmate... but there was a woman of Beasts next to him.",
                evoawk: "Shadie's questions about the woman the musketeer was with were interrupted by a fire dragon's attack. With her true power awakened by this disruption to her journey to true love, Shadie and the musketeer prevailed over the dragon. However, the musketter nearly faced his demise after the battle, enveloped by the cursed flames unleashed by his overloaded firearm. In the end, what saved the musketeer from his fate and dispelled the gun's curse was Shadie, who embraced him with open arms even as the dark fire threatened to swallow him whole."
            }
    },
    { //Rhiothis - Complete
        id: 101356412,
        name: "Rhiothis",
        rank: "F",
        attribute: "Light",
        type: "God",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101354412.png",
                detail5: "/db/Units/Detail/unit_detail_101355412.png",
                detailawk: "/db/Units/Detail/unit_detail_101356412.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101354412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101355412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101356412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6201,
                hpplus: 999,
                atk: 2827,
                atkplus: 434,
                def: 2250,
                defplus: 347
            },
        skillset: 
            {
                skill: "10s CT - Heal Allies' HP by 5% of own ATK. During Emperor of Thunder Mode: Heal Allies' HP by 10% of own ATK.",
                skillbreak: 0,
                arts: "15000% Light DMG (MAG). Cure Allies' Blind + For 6s, reduce Enemies' DEF by 100%. During Emperor of Thunder Mode: 20000% Light DMG (MAG). Cure Allies' Blind + For 6s, reduce Enemies' DEF by 100%.",
                artsbreak: 1900,
                truearts: "For 60s, enter Emperor of Thunder Mode. During Emperor of Thunder Mode: 40000% Light DMG (MAG). Exit Emperor of Thunder Mode + For 30s, increase Allies' Light and MAG DMG by 50%.",
                trueartsbreak: 2000
            },
        passive: 
            {
                ability1: "Increase Allies' DMG RES by 8%.",
                ability2: "Increase Equipment CT REC by 15%.",
                ability3: "Increase Arts by 50 when entering Emperor of Thunder Mode."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 2,
                    hover3: "Fosradius",
                }
            },
        lore: 
            {   
                evo4: "A mage of the Gods active before the Great War. Rhiothis defeated his foes with barrages of electricity earning him the nickname \"Thunder God\" and unerring faith in his abilities. One day, ignoring the pleas of his friends. Rhiothis decided to challenge a powerful Demon solo. Though finally managing to defeat his foe, his friend died protecting him. Lamenting his arrogance, he vowed to raise his friend's now fatherless daughter. sealed his powers, and moved deep into the forest.",
                evo5: "A mage who sealed his powers, moving to the forest to raise his dead son's daughter. One day, however, he found that his beloved adopted daughter had left the house. He gave chase, but after seeing how much she was enjoying herself, he hesitated to bring her home. When danger threatened her, however, he unleashed his sealed powers to protect her. From that day forward, he continued protecting her daughter during her journey with his gentle-yet-powerful light.",
                evoawk: "The old mage looked up to see his daughter and a soldier under the collapsing cliff. He rushed after them and suddenly remembered her smile from before. Seeing his little girl leave the nest left him feeling sad but grateful since she seemed so happy with the gunman. With both hands raised, he used his magic to save them both from the falling rocks."
            }
    },
    { //Teriodos - Complete
        id: 101366112,
        name: "Teriodos",
        rank: "F",
        attribute: "Fire",
        type: "Dragon",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101364112.png",
                detail5: "/db/Units/Detail/unit_detail_101365112.png",
                detailawk: "/db/Units/Detail/unit_detail_101366112.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101364112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101365112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101366112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7464,
                hpplus: 999,
                atk: 2639,
                atkplus: 405,
                def: 2460,
                defplus: 379
            },
        skillset: 
            {
                skill: "9s CT - 1800% Fire DMG (MAG). For 5s, reduce Enemies' Fire RES by 10%.",
                skillbreak: 1700,
                arts: "14000% Fire DMG (MAG). For 10s, increase Fire Allies' DMG by 30%, Dragon Allies' DMG by 30%.",
                artsbreak: 2600,
                truearts: "36000% Fire DMG (MAG). For 40s, increase own DMG by 50% (stackable), increase own ATK by [25% × # of Dragon Allies in the party].",
                trueartsbreak: 2600
            },
        passive: 
            {
                ability1: "Increase DMG to Human Enemies by 70%.",
                ability2: "Increase Earth RES by 15%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_fire4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_fire3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 2,
                    hover3: "Flogassa",
                }
            },
        lore: 
            {   
                evo4: "The fire dragon that destroyed as much as it could before the Ancient War at Raktehelm. It used to be high-rank intelligent dragon. However, one day it was haunted by a Demon of Destruction and its soul was tortured little by little. The hellish flames that dwelled in it not only burned livings beings but also castles and fortresses. With no signs it showed up and destroyed everything that it wished. Thus, Teriodos left its name in history as a symbol of terror.",
                evo5: "A fire dragon that united with a Demon and turned into an incarnation of destruction. Its powers merged as Teriodos destroyed places there and here. The flames that dwelled inside it flooded, thus where it landed turned the ground to a waste land. Sooner or later, it was defeated by a musketeer. In its last moments, it was able to control itself and wished to die. However, no one knows the truth...",
                evoawk: "At constant war with its destructive urges, the Fire Dragon decided to allow a Human warrior to determine its fate. Against its own will, the Demon possessing the Dragon forced it to continue his rampage. Through the lens of what little consciousness it had left, it saw the barrel of the warrior's gun and the determination in his eyes. At the ominous sight, the Dragon felt not fear but a sense of relief."
            }
    },
    { //Keiones - Complete
        id: 101376512,
        name: "Keiones",
        rank: "F",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101374512.png",
                detail5: "/db/Units/Detail/unit_detail_101375512.png",
                detailawk: "/db/Units/Detail/unit_detail_101376512.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101374512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101375512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101376512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6067,
                hpplus: 999,
                atk: 2402,
                atkplus: 469,
                def: 2296,
                defplus: 405
            },
        skillset: 
            {
                skill: "8s CT - 1850% Dark DMG (MAG). For 7s, increase own ATK by 10%.",
                skillbreak: 1300,
                arts: "10000% Dark DMG (MAG). Reduce Enemies' Arts by 20 + For 40s, increase own accuracy by 15% (stackable).",
                artsbreak: 800,
                truearts: "31000% Dark DMG (MAG). For 10s, increase own Critical Rate by 100% and Critical DMG by magnitude of accuracy buffs on self.",
                trueartsbreak: 1800
            },
        passive: 
            {
                ability1: "Increase DMG to Demon Enemies by 50%.",
                ability2: "Increase DMG to Giant Bosses by 40%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_dark3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragValzandeth.png",
                    mat3amt: 2,
                    hover3: "Valzandeth",
                }
            },
        lore: 
            {   
                evo4: "A Human knight who fought for Bamint after the Ancient War. A war orphan, Keiones was recognized for his hard work and made a knight along with his childhood friend Reyon. Though his rigorously trained skills with a bow were accurate enough to earn him the nickname 'Bullseye,' he felt like Reyon was achieving more than him. When a Bamint VIP was assassinated and Reyon volunteered to help search for the culprit, Keiones had to raise his hand too.",
                evo5: "A Human knight with impeccably trained archery skills. After realizing that the assassin he was chasing was an old friend of his, Keiones felt conflicted. Not only that, the order had been changed from 'apprehend' to 'kill.' He asked his old knight friend why this didn't give him pause, but his mind was made up. Keiones was still conflicted as he faced his old friend's girlfriend. With his heart pulled in multiple directions, he found himself unable to lose his arrow.",
                evoawk: "As the knight stood still, holding the deeply wounded girl in his arms, the girl muttered, \"It's all going to be OK. There will come a day where we can all sit and laugh together again.\" To put an end to the infighting, she sacrificed herself. And after letting out a grin marked with a hint of sadness, she gently closed her eyes and faded away. With complicated feelings of regret and determination, the knight shed tears over her serene face."
            }
    },
    { //Elmessio - Complete
        id: 101386212,
        name: "Elmessio",
        rank: "F",
        attribute: "Water",
        type: "Spirit",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101384212.png",
                detail5: "/db/Units/Detail/unit_detail_101385212.png",
                detailawk: "/db/Units/Detail/unit_detail_101386212.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101384212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101385212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101386212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6797,
                hpplus: 999,
                atk: 2354,
                atkplus: 363,
                def: 2506,
                defplus: 385
            },
        skillset: 
            {
                skill: "10s CT - 2300% Water DMG (MAG). Reduce Water Enemies' Earth RES by 20% for 6s.",
                skillbreak: 1700,
                arts: "13000% Water and Earth DMG (MAG). Increase DMG of this Arts to Fire and Water Enemies by 60%, and swap Blessing of Midori Modes.",
                artsbreak: 2300,
                truearts1: "When in Water Mode: 35000% Water DMG (MAG). For 12s, increase own Critical Rate by 100%, increase Water Allies' DMG by 50%. ",
                truearts2: "When in Earth Mode: 28000% Earth DMG (MAG). 50% chance to inflict Disease for 8s.",
                trueartsbreak1: 2300,
                trueartsbreak2: 3000
            },
        passive: 
            {
                ability1: "Increase DMG to Dragon Enemies by 60%.",
                ability2: "Increase Freeze RES by 100%.",
                ability3: "Blessing of Midori: This unit has two modes, Water Mode and Earth Mode. While in Water Mode, heal own HP by 100/s. While in Earth Mode, increase Arts by 1/s, and increase DMG to Diseased Enemies by 30%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_water3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat3amt: 2,
                    hover3: "Freydal",
                }
            },
        lore: 
            {   
                evo4: "A Spirit mercenary who has roamed all the post-War nations. He was raised in a village in what is now the Principality of Bamint along with his beloved adopted sister Lione, and now they are unrivaled partners in work as well. He usually plays a support role for his attacking sister, but his ever changing attacks are unstoppable, and if he takes the offensive, the enemy forces are doomed. When he learned of danger approaching his home, he and his sister assassinated the ringleader of the plot, a powerful offical.",
                evo5: "A Spirit mercenary who wields the power of nature. Though wanted for the murder of an official, he explained to the knight pursuing him that the deed was done to protect their home. The knight was not convinced and attacked relentlessly, despite being a childhood friend. Indeed, he once held deep feelings for Lione... And know, the only ones ready to fight are he and this knight. If these two destroy each other, the other 3 present can go on without further death. With that thought, the man readied himself to stop the knight's attack.",
                evoawk: "He had only meant to stop the fighting but inadvertently killed his childhood friend. When he threatened to be overcome by the weight of that loss, a slap from his sister brought him back to his senses. She was bravely holding back tears, which made him realize that death should not be in vain. Bidding a final farewell, he marched onward towards a future without war, a dream they all shared in their youth."
            }
    },
    { //Lione - Complete
        id: 101396112,
        name: "Lione",
        rank: "C",
        attribute: "Fire",
        type: "Beast",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101394112.png",
                detail5: "/db/Units/Detail/unit_detail_101395112.png",
                detailawk: "/db/Units/Detail/unit_detail_101396112.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101394112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101395112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101396112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6971,
                hpplus: 999,
                atk: 2327,
                atkplus: 359,
                def: 2587,
                defplus: 398
            },
        skillset: 
            {
                skill: "7s CT - 2000% Fire DMG (PHY). When this attack hits an enemy, heal own HP by 40.",
                skillbreak: 1900,
                arts: "16000% Fire DMG (PHY). For 12s, increase Allies' DMG and evasion by 20%.",
                artsbreak: 2400,
                truearts: "30000% Fire DMG (PHY). For 15s, increase Fire Allies' ATK by 80%, increase Human and Beast Allies' Arts by 2/s.",
                trueartsbreak: 4800
            },
        passive: 
            {
                ability1: "When near-death, increase evasion by 25%.",
                ability2: "When near-death, increase Skill CT REC by 60%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_fire4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_fire3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 2,
                    hover3: "Flogassa",
                }
            },
        lore: 
            {   
                evo4: "A Beast mercenary who fought many battles after the Ancient War. She was a war orphan, but she had a happy childhood in the Principality of Bamint. Aspiring to become famous, she fought the fiercest battles with her fire and became known as 'Lion Fire Lione'. She accomplished many missions together with her step-brother Elmessio, but once they accidentally became aware of a secret. In order to prevent the unforgivable plan, the two decided by their own decision to assassinate the key man involved in this plan.",
                evo5: "A mercenary who controls fire lions. To undermine a secret plan, she got her hands dirty with the murder of a man. Thus, she escaped to her hometown to hide and was welcomed by a God childhood friend. But soon the chaser found her and unexpectedly turned out to be another childhood friend, a Spirit. She would then explain the reason for the murder, to achieve his understanding, but instead, his suspicion grew even bigger. The battle came to an end only when the God friend, who had defended her, fell down.",
                evoawk: "The Spirit who slashed his childhood friend was now screaming with the lifeless remains in his arms. After all, he was also one of the orphans grown up in this village. After punching him only once, Lione proclaimed she would fulfill her friend’s dying wish and protect their hometown. And then she asked the remaining three childhood friends: “What will you do to accomplish the dream we had as children, and her dying wish?”"
            }
    },
    { //Reyon - Complete
        id: 101406412,
        name: "Reyon",
        rank: "F",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101404412.png",
                detail5: "/db/Units/Detail/unit_detail_101405412.png",
                detailawk: "/db/Units/Detail/unit_detail_101406412.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101404412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101405412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101406412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7332,
                hpplus: 999,
                atk: 2839,
                atkplus: 435,
                def: 2405,
                defplus: 370
            },
        skillset: 
            {
                skill: "7s CT - 1900% Light DMG (PHY). Increase own ATK and Break PWR each time own Skill is activated.",
                skillbreak: 1900,
                arts: "16500% Light DMG (PHY). For 10s, increase Allies' Skill CT REC by 30%, Light Allies' Skill CT REC by 30%.",
                artsbreak: 2500,
                truearts: "26500% Light DMG (PHY). Increase this True Arts' DMG by 100% against Dark Enemies.",
                trueartsbreak: 4600
            },
        passive: 
            {
                ability1: "Increase BE generation against Enemies in Break by 100%.",
                ability2: "Increase Freeze and Paralysis RES by 100%.",
                ability3: "Increase DMG to Dragon Enemies by 80%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat1amt: 3,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 3,
                    hover3: "Fosradius",
                    mat4: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat4amt: 2,
                    hover4: "Ifrit",
                    mat5: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                    mat5amt: 2,
                    hover5: "Degilmagna"
                }
            },
        lore: 
            {   
                evo4: "A Bamint knight whose name rose to prominence after the end of the Great War. He grew up in a village with other war orphans, and was scouted for his talent. becoming a knight along with an archer friend of his. He improved quickly after joining, always analyzing situations calmly. and rumors spoke of his bright future. When he found out the person he owed his life to had been assassinated, however, he was the first to offer to hunt down the culprit.",
                evo5: "A Bamint knight who worked tirelessly to make a name for himself. When he found out that the assassin who murdered the person he owed everything to was an old friend, he didn't so much as bat an eye. Not because he was cold and unfeeling, but because he wanted to ask the assassin why the did it, and possibly allow them to escape. When he heard their reasons, however, he grew enraged. To protect the honor of the deceased, he prepared to slay his old friend himself.",
                evoawk: "He fell into despair when he ended up slaying the person he treasured and wished to protect above all others, but in the end, she merely smiled up at him. She seemed happy if it meant her friends could all be together again. The childhood dream which she left him as she died burned itself into his heart. To defend their village, he decided to help create a country free of discrimination. He returned to his original ambition of finding success in the Principality of Bamint and left his village again."
            }
    },
    { //Nerim - Complete
        id: 101416312,
        name: "Nerim",
        rank: "F",
        attribute: "Earth",
        type: "God",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101414312.png",
                detail5: "/db/Units/Detail/unit_detail_101415312.png",
                detailawk: "/db/Units/Detail/unit_detail_101416312.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101414312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101415312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101416312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/healLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 5702,
                hpplus: 865,
                atk: 2652,
                atkplus: 407,
                def: 2347,
                defplus: 362
            },
        skillset: 
            {
                skill: "8s CT - 1550% Earth DMG (PHY). 30% chance to inflict Poison for 8s.",
                skillbreak: 1200,
                arts: "13800% Earth DMG (PHY). For 9s, reduce Enemies' Poison, Blind and Paralysis RES by 30%.",
                artsbreak: 2800,
                truearts: "22900% Earth DMG (PHY). For 15s, increase own DMG to Enemies with Status Ailments by 70%, increase Earth Allies' DMG by 30%.",
                trueartsbreak: 4200
            },
        passive: 
            {
                ability1: "Increase Curse RES by 90%.",
                ability2: "Increase DMG to Demon Enemies by 90%.",
                ability3: "When this unit takes lethal DMG, revive all Allies with 50% HP, increase own Arts by 200 and increase all Allies' DMG by 100% for 10s."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat1amt: 3,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_earth3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat3amt: 5,
                    hover3: "Palamicia",
                    mat4: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat4amt: 3,
                    hover4: "Goldola",
                    mat5: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat5amt: 3,
                    hover5: "Tonetaurus"
                }
            },
        lore: 
            {   
                evo4: "A God who protected a certain village after the end of the Great War. To realise their sword dream, she remained in the village even after her friends had left on their own journeys. Though she was willing to unleash her slumbering power to defend the village, she was fundamentally very calm. and despised conflict. So calm and kind, in fact, that she was willing to shelter her friends when they became implicated in the grave crime of assassinating one of Bamint's VIPs.",
                evo5: "A God who did everything in her power to protect her precious village and childhood friends. She was requested to keep the presence of her old friend, now criminals, a secret. After much soul-searching, she agreed... They were her childhood friends, after all, and they promised not to harm anyone. But in the end, she couldn't avoid a confrontation. Regretting the decision, she threw herself into the fray, ending the conflict at the expense of her life.",
                evoawk: "Regretting all she'd done, she shared tears with the knight who hunted her down. Gasping, she murmured kindly to him. 'I brought all this down on myself, you musn't regret ending me. Be proud of all you've done as a knight to defend your village. And I am truly glad to die in your arms... You, who have cared more for me than any other.' With her sacrifice, childhood friends long scattered were able to come together to revisit their younger days."
            }
    },
    { //Quon - Complete
        id: 101556212,
        name: "Quon",
        luckrank: "F",
        rank: "F",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101554212.png",
                detail5: "/db/Units/Detail/unit_detail_101555212.png",
                detailawk: "/db/Units/Detail/unit_detail_101556212.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101554212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101555212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101556212.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6072,
                hpplus: 920,
                atk: 2154,
                atkplus: 333,
                def: 2326,
                defplus: 358
            },
        skillset: 
            {
                skill: "7s CT - 2300% Water DMG (MAG). Increase own Arts by 5.",
                skillbreak: 1200,
                arts: "For 14s, reduce Enemies' DMG RES and Movement Speed by 30%.",
                artsbreak: 0,
                truearts: "16800% Water DMG (MAG). For 20s, reduce Enemies' DMG RES and Movement Speed by 35%.",
                trueartsbreak: 2400
            },
        passive: 
            {
                ability1: "Increase MAG RES by 20%.",
                ability2: "Increase Allies' Fire RES by 10%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_112052.png", // quon's 4* > 5* mats
                    mat3amt: 20
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragQuon.png",
                    mat3amt: 20,
                    hover3: "Quon",
                }
            },
        lore: 
            {   
                evo4: "He was once a wizard of La Riiz at the beginning of the Ancient War. He earned high office through quiet dedication and ability, mastering sorcery with ease. However, after an incident with the nation's ancient customs, he left the path of sorcery for a life of seclusion. The Lord of the Ancient Forest, revered as a sacred place, is usually controlled through a holy ritual performed every few years. Once every few years, it goes out of control. Hearing its rumors, he felt something head to the forest.",
                evo5: "He was famed among the wizards of La Riiz as a master of defense at the beginning of the Ancient War. Yet this he could not defend his only daughter from becoming a sacrifice to the Lord of the Ancient Forest. When he hears rumors of the Lord running amok, a girl riding his back, he realizes he might have a chance to meet the daughter he thought dead. Yet she refuses him. His despondency was only momentary, though as a sudden Demon appeared to attack the Lord and his rider. He stood before the Demon, his true power now unleashed.",
                evoawk: "After he drove off the danger that came to the forest and its Lord, and his daughter with them, he called out to her once more, but she only shook her head. Understanding that one's past cannot be changed, the man turned to leave. However, a forest Spirit close to the Lord appears to stop him. 'If you have the courage to become the forest's guardian, we will allow you to stay.' The man swore to stay on, protecting hte forest along with the mechanical doll that aided him in the earlier fight."
            }
    },
    { //Feld - Complete
        id: 101566112,
        name: "Feld",
        rank: "C",
        attribute: "Fire",
        type: "Spirit",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101564112.png",
                detail5: "/db/Units/Detail/unit_detail_101565112.png",
                detailawk: "/db/Units/Detail/unit_detail_101566112.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101564112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101565112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101566112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6180,
                hpplus: 937,
                atk: 2522,
                atkplus: 388,
                def: 2256,
                defplus: 348
            },
        skillset: 
            {
                skill: "9s CT - 1600% Fire DMG (MAG). 50% chance to inflict Burn for 5s.",
                skillbreak: 1000,
                arts: "9000% Fire DMG (MAG). For 10s, increase Allies' Status RES (except Faint) by 100%.",
                artsbreak: 2600,
                truearts: "40000% Fire DMG (MAG). Consume 30% of own max HP. For 10s, increase own Critical Rate by 100%, reduce Enemies' Fire RES by 30%.",
                trueartsbreak: 2800
            },
        passive: 
            {
                ability1: "Increase DMG to Dragon Enemies by 70%.",
                ability2: "Increase Allies' Critical DMG by 20%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat2amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                    mat3amt: 2,
                    hover3: "Degilmagna",
                }
            },
        lore: 
            {   
                evo4: "A Spirit who watched over the ancient forest that spreads across La Riiz, and representative of its master. ever by the master's side, she would employ her considerable powers to defend the forest against any threat. she was also in charge of watching over the girl chosen to become the next master and watched over her like a mother. When a Demoness suddenly appeared intent of making the master of the forest hers, Feld fought against her, day in, day out.",
                evo5: "A Spirit who fought to protect the master of the ancient forest. Feld fought back, but now had to face an automaton - and its country-obliterating firepower. She stood her ground, drawing out untapped and unthinkably great potential in the name of protecting the forest's master. Feld never so much as faltered... until the master, mad with rage, showed itself before her.",
                evoawk: "The ancient forest's master and its guardian spirit faced a terrible dilemma at the hands of a Demonic invasion. Their rescue, and the automaton's halt, came at the hands of a mage claiming to be the forest master's father. When he pleaded that he wanted to live with his daughter again, the spirit calmlhy asked if he was ready to give up everything and live as the forest's guardian. Questioned about concerns for the forest's future itself rather than for its master, the man shed tears for some time before finally nodding."
            }
    },
    { //Edram - Complete (+ JP version)
        id: 101575412,
        name: "Edram",
        luckrank: "F",
        rank: "C",
        attribute: "Light",
        type: "Machine",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101574412.png",
                detail5: "/db/Units/Detail/unit_detail_101575412.png",
                detailawk: "/db/Units/Detail/unit_detail_101576412.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101574412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101575412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101576412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 5366,
                hpplus: 814,
                atk: 2300,
                atkplus: 355,
                def: 1956,
                defplus: 303
            },
        statsJP: 
            {
                hp: 7650,
                hpplus: 999,
                atk: 3206,
                atkplus: 490,
                def: 2817,
                defplus: 432
            },
        skillset: 
            {
                skill: "12s CT - 2500% Light DMG (MAG). For 5s, reduce Enemies' DEF by 30%.",
                skillbreak: 1300,
                arts: "14000% Light DMG (MAG). 60% chance to inflict Paralysis for 4s.",
                artsbreak: 1500,
                truearts: "N/A",
                trueartsbreak: 0
            },
        skillsetJP: 
            {
                skill: "12s CT - 3000% Light DMG (MAG). For 5s, reduce Enemies' DEF by 30%.",
                skillbreak: 1400,
                arts: "20000% Light DMG (MAG). 70% chance to inflict Paralysis for 4s.",
                artsbreak: 1800,
                truearts: "50000% Light DMG (MAG). Increase allies' Arts by 30 and Light allies' Arts by an additional 10. For 20s, ignore 100% of enemies' DEF.",
                trueartsbreak: 2200
            },
        passive: 
            {
                ability1: "Each normal attack will increase own Arts by 10.",
                ability2: "Increase Light Allies' Arts by 1/s.",
                ability3: "Reduce Water RES by 50%."
            },
        passiveJP: 
            {
                ability1: "Each normal attack will increase own Arts by 10.",
                ability2: "Increase Light Allies' Arts by 2/s.",
                ability3: "Reduce Water RES by 50%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_113454.png", // Edram's 4* > 5* mats
                    mat3amt: 20
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_134354.png",
                    mat3amt: 20,
                    hover3: "Edram",
                }
            },
        lore: 
            {   
                evo4: "A mecha doll that was owned by a Demon during the first period of the Ancient War. Especially created for battle, he could wipe out a whole troop by itself. In order to perform efficient massacre, he had no ego and faithfully followed its master's orders. However, his master had vigorous powers and he was hardly used. At the end, he was given as a present to his master's daughter and now she was his new master.",
                evo5: "The humanoid weapon that a Demon's daughter had during the first period of the Ancient War. For fun, she started to invade an ancient forest and when she was resisted she used him. Although that he was finally being able to use his battle abilities, a Spirit stood in his way and did not give up to fight despite the difference in their powers. This created something like a 'feeling' inside him. Although, he did not know how to handle this 'feeling', once he was ordered to use his last ultimate weapon, he became 'excited.'",
            }
    },
    { //Favelle - Complete
        id: 101586312,
        name: "Favelle",
        rank: "B",
        attribute: "Earth",
        type: "Dragon",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101584312.png",
                detail5: "/db/Units/Detail/unit_detail_101585312.png",
                detailawk: "/db/Units/Detail/unit_detail_101586312.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101584312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101585312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101586312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/healLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6836,
                hpplus: 999,
                atk: 2424,
                atkplus: 373,
                def: 2743,
                defplus: 421
            },
        skillset: 
            {
                skill: "7s CT - 1800% Earth DMG (PHY).",
                skillbreak: 1200,
                arts: "8000% Earth DMG (PHY). For 25s, Allies gain a DMG barrier that negates 3200 DMG.",
                artsbreak: 2000,
                truearts: "15000% Earth DMG (PHY). For 30s, Allies gain a DMG barrier that negates 5000 DMG, increase Allies' DMG RES by 15%.",
                trueartsbreak: 2500
            },
        passive: 
            {
                ability1: "Increase Near-Death Allies' DMG RES by 10%.",
                ability2: "Increase Allies' max HP by 10%.",
                ability3: "When at 50% HP or more, increase Arts DMG by 50%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_earth3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat3amt: 2,
                    hover3: "Elpneumas",
                }
            },
        lore: 
            {   
                evo4: "A girl who served as the symbol of La Riiz ancient forest. As far back as she could remember, Favelle always felt like she was riding on something enormous. She was: the Ancient Dragon, master of the forest. After becoming able to communicate with the Dragon, she dedicated herself to the forest of her own volition. Unified with the Dragon, she fought against threats to the forest.",
                evo5: "A warrior who became the second master of the ancient forest. A Demoness seeking to make the Ancient Dragon her own used the terrifying power of an automation to push the guardian of the forest to the brink of defeat. Furious, the master of the forest and Favelle flew out to drive away the Demoness. After a fierce battle, the automaton prepared a devastating weapon to obliterate the entire forest, but its flames scorched the Demoness instead. Someone from her youth had saved her...",
                evoawk: "Having avoided disaster, the man introduced himself to Favelle as her father. He told her that he wanted to live with her together again, as a family. Having decided to live forever in the forest alongside the Ancient Dragon, she refused. However, sensing his strong resolve, she allowed the man to live in the forest under the condition that he would become its protector. She agreed to this because of the faint memories she had of her father, and the determination of the man before her who was prepared to throw away everything if he could live with his daughter."
            }
    },
    { //Catilou - Complete
        id: 101596312,
        name: "Catilou",
        rank: "F",
        attribute: "Earth",
        type: "Beast",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101594312.png",
                detail5: "/db/Units/Detail/unit_detail_101595312.png",
                detailawk: "/db/Units/Detail/unit_detail_101596312.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101594312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101595312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101596312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 7032,
                hpplus: 999,
                atk: 2491,
                atkplus: 357,
                def: 2580,
                defplus: 365
            },
        skillset: 
            {
                skill: "9s CT - 2000% Earth DMG (PHY). If Skill Crits, increase Break PWR of that hit by 100%.",
                skillbreak: 2200,
                arts: "11000% Earth DMG (PHY). For 20s, heal Allies' HP by 120/s. and increase own Skill CT REC by 100%.",
                artsbreak: 3600,
                truearts: "32000% Earth DMG (PHY). For 15s, increase Allies' DMG by 30%, increase Allies' DMG to Enemies in BREAK by 30%.",
                trueartsbreak: 5000
            },
        passive: 
            {
                ability1: "When Art Gauge is 50 or more, increase own Critical Rate by 70%.",
                ability2: "When near-death, heal HP by 60/s."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_earth3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat3amt: 4,
                    hover3: "Tonetaurus",
                }
            },
        lore: 
            {   
                evo4: "A Beast bandit active prior to the Great War. Catilou led bandit gang that robbed greedy businessmen and government officals exclusively. Most of the members of her gang were people who fell in love with Catilou's caring personality. One day, a lone intruder appeared on her turf. She didn't pay him much mind... until he took out some of her men. Taking hammer in hand, she prepared to take on the Human gunslinger who had dared to enter her turf.",
                evo5: "A Beast bandit active prior to the Great War. Catilou fought against the gunslinger who had shot down her men. Midway through, however, she realized something: her men were unharmed, and had only had their weapons destroyed. Catilou dropped her weapons and admitted defeat, and the gunslinger apologized for mistaking her for the mark he had been chasing for years. They would travel together, and Catilou would eventually save the gunslinger during a fight against the fire dragon.",
                evoawk: "The fire dragon, thought to have been defeated, let out a bellowing roar. The gunslinger slumped to his knees at the sight of the raging dragon. Realizing that his magical firearm drew power from its user's life force, Catilou took hte gun herself and gave her own energy to the weapon to protect the gunslinger. The gunslinger stood up again after this selfless act and the two defeated the dragon together. For this, Catilou is known as the dragon-slaying Maverick."
            }
    },
    { //Rasaou - Complete
        id: 101606112,
        name: "Rasaou",
        rank: "F",
        attribute: "Fire",
        type: "God",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101604112.png",
                detail5: "/db/Units/Detail/unit_detail_101605112.png",
                detailawk: "/db/Units/Detail/unit_detail_101606112.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101604112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101605112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101606112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7500,
                hpplus: 999,
                atk: 4620,
                atkplus: 841,
                def: 1260,
                defplus: 236
            },
        skillset: 
            {
                skill: "9s CT - 2100% Fire DMG (PHY). For 5s, increase own Equipment CT REC by 30%.",
                skillbreak: 1100,
                arts: "12800% Fire and Dark DMG (PHY). For 5s, increase Allies' RES to the Element they have an Elemental Advantage against by 100%.",
                artsbreak: 5000,
                truearts: "15500% Fire DMG (PHY). Increase own DMG RES by 100% during own True Arts.",
                trueartsbreak: 6000
            },
        passive: 
            {
                ability1: "Increase own God's Eye Counter whenever own Skill (1 Count), Arts (1 Count), or True Arts (3 Count) is activated + Increase own Critical Rate by 10% for each God's Eye Count (max. 10 stacks (100% Critical Rate increase)) + When God's Eye Counter is 10, reset God's Eye Count to 0 with next activation of own Arts or True Arts and increase that True/Arts' DMG and Break PWR by 100%.",
                ability2: "Increase DMG to Dragon Enemies by 50%.",
                ability3: "Increase DMG to Demon Enemies by 100%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_fire4.png",
                    mat1amt: 3,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_fire3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 3,
                    hover3: "Flogassa",
                    mat4: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat4amt: 2,
                    hover4: "Mavroskia",
                }
            },
        lore: 
            {   
                evo4: "A swordsman who escaped from the Gods in order to attain skills as a swordsman during the early period of the Ancient War. He traveled all over the world to find a strong opponent. Hearing the rumors of the \"Destruction Giant\" he heads to where the Giants lived. However, who he encountered was a girl running away from Demons. Sensing that the Demon was strong, he fought against it, but disappeared during their fight. He ended up saving the girl, but this will change the rest of his life.",
                evo5: "The Swordsman who traveled with a girl named Yummi during the early period of the Ancient War. His battles against strong opponents brushed up his magnificent sword skills. However, he still was not able to find the \"Destruction Giant.\" One day, Yummi fell under the trap of a woman of the Spirit race and attacked him. Seeing her horrendous powers, he recognized she was the one he was looking for. Still, he was not able to raise his sword against her.",
                evoawk: "'Spare the women and children...' This dignified swordsman never wavered in his principles. Perhaps it was this conviction, that let him remain true even when he was under attack, that helped the little girl's heart within the rampaging Giant of Ruin to win out. Although the swordsman could not win, he perhaps found some satisfaction in fighting a worthy adversary. The swordsman had a smile throughout the battle, and when the girl's frenzy abated, he held out his hand and took her on his journey."
            }
    },
    { //Yumi - Missing Lore (JP)
        id: 101616212,
        name: "Yumi",
        attribute: "Water",
        type: "Giant",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101614212.png",
                detail5: "/db/Units/Detail/unit_detail_101615212.png",
                detailawk: "/db/Units/Detail/unit_detail_101616212.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101614212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101615212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101616212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 8100,
                hpplus: 999,
                atk: 2453,
                atkplus: 380,
                def: 2409,
                defplus: 377
            },
        skillset: 
            {
                skill: "9s CT - 1800% Water DMG (PHY). Increase own Arts by 5 when this hits an Enemy.",
                skillbreak: 1900,
                arts: "14000% Water DMG (PHY). For 15s, increase Allies' Healing Taken (except Lifesteal) by 80%.",
                artsbreak: 3200,
                truearts: "27000% Water DMG (PHY). Increase DMG by this True Arts to non-Boss Enemies by 150% + For 15s, increase stats by 50%.",
                trueartsbreak: 3600
            },
        passive: 
            {
                ability1: "Increase Fire RES by 25%.",
                ability2: "Increase Allies' max HP by 15%.",
                ability3: "Increase Healing Taken (except Lifesteal) by 20%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_water3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                    mat3amt: 4,
                    hover3: "Geo-Zagnus",
                }
            },
        lore: 
            {   
                evo4: "Currently unreleased in Global.",
                evo5: "Currently unreleased in Global.",
                evoawk: "Currently unreleased in Global."
            }
    },
    { //Keit - Complete
        id: 101626312,
        name: "Keit",
        rank: "C",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101624312.png",
                detail5: "/db/Units/Detail/unit_detail_101625312.png",
                detailawk: "/db/Units/Detail/unit_detail_101626312.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101624312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101625312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101626312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6228,
                hpplus: 999,
                atk: 2832,
                atkplus: 434,
                def: 2258,
                defplus: 348
            },
        skillset: 
            {
                skill: "8s CT - 800% Earth DMG (MAG). 70% chance to inflict Poison for 8s.",
                skillbreak: 500,
                arts: "14000% Earth DMG (MAG). 100% chance to inflict Paralysis against Poisoned Enemies for 6s.",
                artsbreak: 1450,
                truearts: "30000% Earth DMG (MAG) For 15s, reduce Enemies' Poison and MAG RES by 35%.",
                trueartsbreak: 1600
            },
        passive: 
            {
                ability1: "Increase Allies' Poison RES by 30%. Increase Allies' Critical Rate by 50% against Poisoned Enemies. Does not stack with Abilities of the same name.",
                ability2: "Increase Paralysis RES by 70%.",
                ability3: "Increase Equip CT REC by 10%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_earth3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat3amt: 4,
                    hover3: "Elpneumas",
                }
            },
        lore: 
            {   
                evo4: "A Human who researched dangerous subjects prior to the Ancient War. Though originally a studious researcher, when he discovered a way to alter the makeup of living things, he strayed from his path. After abandoning experiments on Humans and moving on to other races, a mysterious Spirit appeared with a question: would it be possible to change a Giant girl's size? Keit happily accepted the challenge and headed off to make the girl's wish come true.",
                evo5: "A researcher who succeeded in changing the bodies of living things prior to the Ancient War. Keit met with a Giant girl who could change the size of her body, and she asked if he knew a way to make it so she would never become Giant-sized again. 'I don't want him to know the truth about me,' she said. 'I want to be small always.' Keit granted her desire... in return for unleashing the power slumbering within her. The power which was enough to change the world.",
                evoawk: "The girl finally being able to control her Giant transformation ability marked the mad scientist's experiment as a success. Despite merely using her desire for his own ambition, he felt a sense of accomplishment, or perhaps a sense of admiration for the girl's display of will. Whichever it was, as he saw her and her beloved walking away in the distance, a satisfied grin flickered across his face."
            }
    },
    { //Cathemilla - Complete
        id: 101646412,
        name: "Cathemilla",
        rank: "F",
        attribute: "Light",
        type: "Spirit",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101644412.png",
                detail5: "/db/Units/Detail/unit_detail_101645412.png",
                detailawk: "/db/Units/Detail/unit_detail_101646412.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101644412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101645412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101646412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6780,
                hpplus: 999,
                atk: 1958,
                atkplus: 303,
                def: 2689,
                defplus: 413
            },
        skillset: 
            {
                skill: "9s CT - Heals Allies' HP by 200 and increase Allies' Arts by 10.",
                skillbreak: 0,
                arts: "15600% Light DMG (MAG). For 10s, increase Allies' Paralysis and Freeze RES by 100%, and Arts DMG by 20% + If an enemy is Dark, increase Allies' Arts DMG by an additional 50%.",
                artsbreak: 3200,
                truearts: "28000% Light DMG (MAG). For 12s, increase Fire, Water and Earth Allies' DMG by [30% × number of unique Elements (excluding Light and Dark) in the Party].",
                trueartsbreak: 5000
            },
        passive: 
            {
                ability1: "Increase Fire Allies' Critical DMG by 40% and Skill CT REC by 60%.",
                ability2: "Increase Water Allies' Status RES by 60% and Arts DMG RES by 25%.",
                ability3: "Heal Earth Allies' HP by 40/s. Increase Earth Allies' Break PWR by 40%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat1amt: 3,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat3amt: 2,
                    hover3: "Radachne",
                    mat4: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat4amt: 2,
                    hover4: "Palamicia",
                }
            },
        lore: 
            {   
                evo4: "The mysterious girl that appeared here and there and called herself an 'observer' during the early period of the Ancient War. Always having a smile, people thought she was of the God race. The reason she arrived in the country of Giants was for world peace and to seal the powers in the way. However, she noticed that she wasn't the only one after the power of 'The Destruction Giant'.",
                evo5: "A girl who made every effort to become a mediator during the early period of the Ancient War. When she noticed that the girl who traveled with the God who she was observing was \"the Destruction Giant.\" she contacted a certain Human researcher to seal this abominable power. She asks the researcher to \"seal the special abilities of the Giant girl.\" Taking advantage of girl's feelings for the man of God, they led the girl to come to the researcher.",
                evoawk: "The girl of Spirit who was after the power of 'the Destruction Giant' to become the true mediator. Noticing that the girl who was saved by a God swordsman was 'the Destruction Giant' herself, she teamed up with a researcher to seal and steal the powers. Their plan failed at the very end where they were fought back. Although a failure, seeing the strong powers of the Giant girl, she smiled mysteriously."
            }
    },
    { //Mizyna - Complete
        id: 101656512,
        name: "Mizyna",
        rank: "C",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101654512.png",
                detail5: "/db/Units/Detail/unit_detail_101655512.png",
                detailawk: "/db/Units/Detail/unit_detail_101656512.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101654512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101655512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101656512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6348,
                hpplus: 999,
                atk: 2832,
                atkplus: 434,
                def: 2250,
                defplus: 347
            },
        skillset: 
            {
                skill: "7s CT - 1800% Dark DMG (PHY). Heal own HP by 2%.",
                skillbreak: 1400,
                arts: "15000% Dark DMG (PHY). For 12s, increase Allies' Skill CT REC by 30%, increase Dark Allies' Skill CT REC by an additional 30%.",
                artsbreak: 2800,
                truearts: "34000% Dark DMG (PHY). Increase own Arts by 20 + For 12s, increase Own Critical Rate by 100%.",
                trueartsbreak: 2800
            },
        passive: 
            {
                ability1: "At the start of a Battle, increase own Arts by 100.",
                ability2: "Increase DMG to God Enemies by 80%.",
                ability3: "Increase Allies' Critical DMG by 20%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_dark3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat3amt: 4,
                    hover3: "Oldeus",
                }
            },
        lore: 
            {   
                evo4: "Having a complete attitude of achieving missions in the army, Mizyna had left a legacy. Recognized as a skilled knight, she was specially sent to capture the 'Destruction Giant' which had power to even destroy the world. She was almost able to get hold of the target, but was interrupted by a mysterious man.",
                evo5: "An elite knight of the Demon army at the start of the Ancient War. During a mission to capture the 'Destruction Giant,' a swordsman interrupted. As a knight, the giant's capture was the priority. Yet, when the mysterious man appeared, the battle was euphoric.As Mizyna took the upper hand, the man told her, 'I won't kill women,' shaking Mizyna to her core. The most cutting insult to her long-hidden true identity finally made her 'feel' for the first time.",
                evoawk: "Enraged by the swordsman's pity, Mizyna focuses solely on becoming stronger than him. However, she realized during their battle that strength alone wouldn't be sufficient. Through rigorous discipline, she pushes her skill to the limits in mastering the art of the spear to one day prove herself worthy."
            }
    },
    { //Nies - Complete + JP ver
        id: 101666212,
        name: "Nies",
        rank: "B-",
        twrequire: "reliance",
        attribute: "Water",
        type: "God",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101664212.png",
                detail5: "/db/Units/Detail/unit_detail_101665212.png",
                detailawk: "/db/Units/Detail/unit_detail_101666212.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101664212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101665212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101666212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7596,
                hpplus: 999,
                atk: 2756,
                atkplus: 423,
                def: 2866,
                defplus: 439
            },
        skillset: 
            {
                skill: "7s CT - 2000% Water DMG (PHY). For 5s, reduce Enemies' ATK by 20%.",
                skillbreak: 1400,
                arts: "14500% Water DMG (PHY). For 15s, increase Allies' Fire RES by 15%, heal Allies' HP by 200/s, increase Water Allies' Arts by 2/s.",
                artsbreak: 2400,
                truearts: "35000% Water DMG (PHY). For 3s, increase Allies' DMG RES by 80%. For 15s, increase Water Allies' Skill CT REC and Skill DMG by 120%.",
                trueartsbreak: 4400
            },
        skillsetJP: 
            {
                skill: "7s CT - 2000% Water DMG (PHY). For 5s, reduce Enemies' ATK by 20%.",
                skillbreak: 1400,
                arts: "16000% Water DMG (PHY). For 15s, increase Allies' Fire RES by 20%, heal Allies' HP by 500/s, increase Water Allies' Arts by 3/s.",
                artsbreak: 2400,
                truearts: "55000% Water DMG (PHY). For 5s, increase Allies' DMG RES by 80%. For 15s, increase Water Allies' Skill CT REC and Skill DMG by 120%.",
                trueartsbreak: 4400
            },
        passive: 
            {
                ability1: "Increase Allies' DEF by 500 and DMG to Fire Enemies by 25%. Does not stack with Abilities of the same name.",
                ability2: "Increase Burn RES by 100%.",
                ability3: "Ignore 35% of Enemies' DEF."
            }, 
        passiveJP: 
            {
                ability1: "Increase Allies' DEF by 1000 and DMG to Fire Enemies by 50%. Does not stack with Abilities of the same name.",
                ability2: "Increase Burn RES by 100%.",
                ability3: "Ignore 35% of Enemies' DEF."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat1amt: 3,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_water3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 2,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat4amt: 2,
                    hover4: "Freydal",
                }
            },
            dreamJP: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                        mat3amt: 2,
                        hover3: "Palamicia",
                    },
                    passive: {
                        ability1: `Increase all allies' Freeze RES by 30%.`,
                        ability2: `Increase own HP Recovery amount by 30% (excluding Lifesteal).`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragValzandeth.png",
                        mat3amt: 2,
                        hover3: "Valzandeth",
                    },
                    passive: {
                        ability1: `Increase all allies' MAX HP by 10%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own Arts by 2/s.`
                    }
                }
            },
        trueweapon:
            {
                name: "Saint Blaze",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_106241.png",
                skill: "65s CT - 9500% Water DMG (PHY). For 10s, increase Water Allies' DMG by 40%.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Nies, increase DMG by 20%, heal HP by 100/s and increase Arts by 2/s.",
                        ability2: "Increase Skill DMG by 20%."
                    }
            },
        lore: 
            {   
                evo4: "During the early period of the Ancient War, he was the captain of the Divine Knights of the Divine Guardian Uther, who was one of the Ten Divine Forces of Gods. With exceptional talent and ability, he was regarded as the strongest leader. However, when a Demon girl attempted to kill their guardian, he met a big conflict with the vice-captain about the treatment of the culprit. He suggested that even if a Demon, she was still young and if they put her on the right path without killing her, eventually, their guardian would forgive her. But he couldn't convince the other knights and decided to flee with the young Demon.",
                evo5: "The former captain of the Divine Knights, who was acknowledged as the strongest. The girl he saved tried to kill him, but in response, he continued patiently to teach her about happiness, sadness and the rightful way to use power. Eventually, she began to show humanity and to open her heart. When their chaser suddenly appeared in front of them, he stood before the girl and declared to his former subordinate: 'Power is what is given to protect what is important'.",
                evoawk: "An invincible warrior and former leader of the Divine Knights. He fought to carve out the path he felt right, and they say his skill with the blade made him the match for any army. Still, they tell tales of his deeds. His life was often chaotic, and they say that when he saved a Demon because it was the right, he was forced to fight his brethren in arms. His great faith led to endless admiration for deeds surpassing mere race, and his name was honored for eternity throughout the land."
            }
    },
    { //Sanstone - Complete + JP ver
        id: 101676112,
        name: "Sanstone",
        rank: "A+",
        twrequire: "favorable",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101674112.png",
                detail5: "/db/Units/Detail/unit_detail_101675112.png",
                detailawk: "/db/Units/Detail/unit_detail_101676112.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101674112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101675112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101676112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7416,
                hpplus: 999,
                atk: 2275,
                atkplus: 351,
                def: 2834,
                defplus: 435
            },
        skillset: 
            {
                skill: "8s CT - 1700% Fire DMG (PHY). 60% chance to inflict Burn for 10s.",
                skillbreak: 1300,
                arts: "12400% Fire DMG (PHY). For 25s, Allies gain a DMG barrier that negates 3800 DMG.",
                artsbreak: 2400,
                truearts: "24000% Fire DMG (PHY). For 20s, increase Allies' DMG RES by 30% and Critical RES by 20%.",
                trueartsbreak: 3700
            },
        skillsetJP: 
            {
                skill: "8s CT - 1700% Fire DMG (PHY). 60% chance to inflict Burn for 10s.",
                skillbreak: 1300,
                arts: "14000% Fire DMG (PHY). For 30s, Allies gain a DMG barrier that negates 5000 DMG.",
                artsbreak: 2400,
                truearts: "30000% Fire DMG (PHY). For 30s, increase Allies' DMG RES by 30% and Critical RES by 20%.",
                trueartsbreak: 3700
            },
        passive: 
            {
                ability1: "Increase Freeze RES and Paralysis RES by 100%.",
                ability2: "Increase Allies' RES to their Weak Element by 10%.",
                ability3: "When near-death, increase Arts by 3/s."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_fire4.png",
                    mat1amt: 3,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_fire3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 2,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                    mat4amt: 2,
                    hover4: "Degilmagna",
                }
            },
            dreamJP: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                        mat3amt: 2,
                        hover3: "Ifrit",
                    },
                    passive: {
                        ability1: `Increase all allies' MAX HP by 10%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own Arts by 200 at Start of Quest.`
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                        mat3amt: 2,
                        hover3: "Radachne",
                    },
                    passive: {
                        ability1: `Increase own Arts by 2/s.`,
                        ability2: `Increase all allies' Break Power by 25%. Does not stack with Abilities of the same name.`
                    }
                }
            },
        trueweapon: 
            {
                name: "Flambardo",
                slot: "/db/Mines/thumbnail/defLB.png",
                detail: "/db/Equipment/Detail/item_detail_106361.png",
                skill: "25s CT - For 12s, increase Allies' DMG RES by 20%. Allies with 50% or less HP gain a 30% increase instead.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Sanstone, increase Equipment CT REC by 30% and max HP by 20%. Does not stack with Abilities of the same name.",
                        ability2: "Increase PHY RES by 10%."
                    }
            },
        lore: 
            {   
                evo4: "One of the Divine Knights serving the Divine Guardian Uther in the first part of the Ancient War. Her deep respect for the vice-captain Platina led her to become a close confidant even from her training days. The pairing of attacker Platina and defender Sanstone created an unmatched, perfectly balanced team and their deeds of arms earned unending praise. Even when they captured the assassin sent by the Demons, she didn't hesitate to agree with the vice-captain about the brutal treatment of the perpetrator. She was, on the contrary, the one who blamed the captain the most for protecting the young demon.",
                evo5: "The knight known as the Invincible Shield of the Divine Knights. After the captain fled, she strongly favored the vice-captain to take his place but was refused. Platina continued to believe that their captain would return, but this caused Sanstone such pain that she proposed to send a force to capture the rebel. She led the assault herself and finally tracked the captain down. She attacked, hoping to break the spell the captain held over her dear Platina. That was the only instant this defending knight had ever shown the desire to go on the offensive.",
                evoawk: "As a vice-commander of the Divine Guardian Divine Knights, she serves Platina without reserve, and fights ever on the front line to guard her commander and fellow knights without regard to her own safety. She bears the name \"Invincible Shield\" for her history of withstanding Demonic attacks, and holds herself alone responsible for the safety of the Divine Knights. However, her motivation comes from the deep need to keep one special person safe."
            }
    },
    { //Platina - Complete + JP ver
        id: 101686412,
        name: "Platina",
        rank: "B+",
        attribute: "Light",
        type: "God",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101684412.png",
                detail5: "/db/Units/Detail/unit_detail_101685412.png",
                detailawk: "/db/Units/Detail/unit_detail_101686412.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101684412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101685412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101686412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7464,
                hpplus: 943,
                atk: 2796,
                atkplus: 359,
                def: 2640,
                defplus: 340
            },
        skillset: 
            {
                skill: "10s CT - 2000% Light DMG (PHY). For 8s, reduce Enemies' Break RES by 30%.",
                skillbreak: 2100,
                arts: "15500% Light DMG (PHY). For 15s, when the Boss' Break Bar is 70% or less, increase own Break PWR by 70%.",
                artsbreak: 4000,
                truearts: "34000% Light DMG (PHY). 100% chance to inflict Faint for 5s. For 20s, reduce Enemies' Break Bar by 10% of total Break Bar each time they are hit by own Skill.",
                trueartsbreak: 8500
            },
        skillsetJP: 
            {
                skill: "10s CT - 2000% Light DMG (PHY). For 8s, reduce Enemies' Break RES by 30%.",
                skillbreak: 2100,
                arts: "18000% Light DMG (PHY). For 15s, when the Boss' Break Bar is 70% or less, increase own Break PWR by 100%.",
                artsbreak: 4000,
                truearts: "50000% Light DMG (PHY). 100% chance to inflict Faint for 5s. For 20s, reduce Enemies' Break Bar by 10% of remaining Break Bar each time they are hit by own Skill.",
                trueartsbreak: 9500
            },
        passive: 
            {
                ability1: "When an Ally is near-death, cure that Ally's Status Ailments (except Faint) and that Ally gains a DMG barrier that negates 2000 DMG for 10s. Can be activated only once every 70s.",
                ability2: "Increase own Skill CT REC based on own Break PWR buffs.",
                ability3: "Increase Allies' Break PWR by 10%. Does not stack with Abilities of the same name."
            }, 
        passiveJP: 
            {
                ability1: "When an Ally is near-death, cure that Ally's Status Ailments (except Faint) and that Ally gains a DMG barrier that negates 3000 DMG for 20s. Can be activated only once every 70s.",
                ability2: "Increase own Skill CT REC based on own Break PWR buffs.",
                ability3: "Increase Allies' Break PWR by 10%. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat1amt: 3,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 2,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 2,
                    hover4: "Radachne",
                    mat5: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat5amt: 1,
                    hover5: "Palamicia"
                }
            },
            dreamJP: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                        mat3amt: 2,
                        hover3: "Ifrit",
                    },
                    passive: {
                        ability1: `Increase own Equip CT Speed by 20%.`,
                        ability2: `Increase God allies' Arts by 2/s. Does not stack with Abilities of the same name.`
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                        mat3amt: 2,
                        hover3: "Radachne",
                    },
                    passive: {
                        ability1: `Increase own Break Power against Demons by 70%.`,
                        ability2: `When enemy is below 30% HP, increase own DMG by 100%.`
                    }
                }
            },
        trueweapon: 
            {
                name: "Knight's Glow",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_106081.png",
                skill: "70s CT - 2000% Light DMG (PHY).",
                skillbreak: 8000,
                passive: 
                    {
                        ability1: "When equipped by Platina, increase own Break PWR by 30%. Increase own DMG to Enemies in Break by 30%. Does not stack with Abilities of the same name.",
                        ability2: "Increase Dark RES by 20%."
                    }
            },
        lore: 
            {   
                evo4: "The subhead of the Divine Guardian Ulther's Divine Knights during the early period of the Ancient War. She was praised as the Two Great Knights along with the leader. Born to a high-class God family, she was beautiful and noble, which led her to be the symbol of the Divine Knights. Respecting religious precepts and being strict, her subordinates were afraid of her and often argued with the leader. Her belief 'Master is absolute, we are his sword and shield' led her to the breakdown with the leader considering the Demon who tried to murder Ulther.",
                evo5: "The high-pride former subhead of the Divine Knights. Although she was the commander of the army searching for the leader, she was planning to convince him. However, he did not answer her feelings. He was protecting the Demon, thus she drew her sword. Outnumbering them, she was about to defeat him but the Demon stood in her way risking her life. Her last words were 'Respect the small knight.' and left the two alive.",
                evoawk: "The proud knight who became the new commander of the Divine Knights. She holds the safety of the Divine Guardian Uther her top priority, and she will allow none to oppose him. She has trained ceaselessly at the sword to prove her worth as a knight, and is now able to cut light itself. She respects those with honor, and is strict but not heartless, so her way of living has earned her admiration and a name that rings throughtout the land."
            }
    },
    { //Parlot - Complete + JP ver
        id: 101696312,
        name: "Parlot",
        rank: "A-",
        attribute: "Earth",
        type: "Spirit",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101694312.png",
                detail5: "/db/Units/Detail/unit_detail_101695312.png",
                detailawk: "/db/Units/Detail/unit_detail_101696312.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101694312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101695312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101696312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6541,
                hpplus: 991,
                atk: 2138,
                atkplus: 330,
                def: 2388,
                defplus: 368
            },
        skillset: 
            {
                skill: "9s CT - Heal Allies' HP by 200 + Increase Allies' Arts by 3.",
                skillbreak: 0,
                arts: "10800% Earth DMG (MAG). Heal Allies by 40% of their max HP + For 15s, increase Allies' Water RES by 15%.",
                artsbreak: 2100,
                truearts: "Heal all Allies by 60% of their max HP + Increase Allies' Arts by 30.",
                trueartsbreak: 0
            },
        skillsetJP: 
            {
                skill: "9s CT - Heal Allies' HP by 500 + Increase Allies' Arts by 10.",
                skillbreak: 0,
                arts: "12000% Earth DMG (MAG). Heal Allies by 40% of their max HP + For 20s, increase Allies' Water RES by 20%.",
                artsbreak: 2100,
                truearts: "Heal all Allies by 70% of their MAX HP + Increase Allies' Arts by 40.",
                trueartsbreak: 0
            },
        passive: 
            {
                ability1: "Increase Allies' MAX HP by 500 and DMG to Water Enemies by 25%. Does not stack with Abilities of the same name.",
                ability2: "Increase Equipment CT REC by 10%.",
                ability3: "Increase Freeze RES by 100%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_earth3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat3amt: 4,
                    hover3: "Elpneumas",
                }
            },
            dreamJP: {
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragElpneumas.png",
                        mat3amt: 2,
                        hover3: "Elpneumas",
                    },
                    passive: {
                        ability1: `Increase all allies' Status RES by 50% (except Faint).`,
                        ability2: `Increase own Arts by 2/s.`
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                        mat3amt: 2,
                        hover3: "Fosradius",
                    },
                    passive: {
                        ability1: `Increase all allies' MAG DMG by 30%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase all allies' MAX HP by 15%. Does not stack with Abilities of the same name.`
                    }
                }
            },
        lore: 
            {   
                evo4: "One of the members of the Divine Knights who served the Divine Guardian Uther during the first part of the Ancient War. He was always calm and worked as the right-hand of the captain. He was the oldest of the group and thanks to his friendly personality, was considered as a big brother. He was always the one to sedate the frequent conflicts between the captain and the vice-captain. When the captain was criticized concerning the treatment of a Demon who tried to kill the Divine Guardian, Parlot defended him until the very end and helped him escape.",
                evo5: "A member of the Divine Knights of the Divine Guardian Uther who made a name for himself in the first half of the Ancient War. He joined the punitive forces formed to chase his captain, who had escaped with the Demon who attempted to the Divine Guardian's life, but his real intentions were different. He wanted the vice-captain not to worry too much and, on the other hand, he was planning to control the defender of the team, who didn't have a good opinion of the captain. In the worst case, he would have even left the Divine Knights, but his priority was to save the captain. But eventually he didn't have the chance to do so, and nobody understood his true intentions.",
                evoawk: "The severe vice-leader for the first time decided to contrast the boss' orders and intentionally overlooked the leader and the Demon. When the punitive squad came back, Parlor gave the Demon a magical communicating device and told her to contact him whenever she was in trouble. After this, the vice-leader sealed her feelings for the leader and became the new leader of the Holy Knights,protecting the boss for all her life. Nobody knew that she secretly kept contact with the former leader through a confidant."
            }
    },
    { //Dahlia - Complete + JP ver
        id: 101706512,
        name: "Dahlia",
        rank: "F",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101704512.png",
                detail5: "/db/Units/Detail/unit_detail_101705512.png",
                detailawk: "/db/Units/Detail/unit_detail_101706512.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101704512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101705512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101706512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6844,
                hpplus: 999,
                atk: 2997,
                atkplus: 459,
                def: 2360,
                defplus: 364
            },
        skillset: 
            {
                skill: "7s CT - 1850% Dark DMG (PHY). For 7s, reduce Enemies' Status RES by 30%.",
                skillbreak: 1300,
                arts: "14800% Dark DMG (PHY). Increase CRI rate by 100% against enemies with Status ailment(s) for the duration of Arts. Additionally when used against enemies with Status ailment(s), multiply the DMG Multiplier of current Arts by [1.5 + 0.25 x # of unique Status ailments on enemy].",
                artsbreak: 3100,
                truearts: "28800% Dark DMG (MAG). For 20s, increase own Arts by [2 × # of unique Status ailments on Enemy with the most Status ailments]/s, and all Allies' DMG by [40% × # of unique Status ailments on Enemy with the most Status ailments].",
                trueartsbreak: 3600
            },
        skillsetJP: 
            {
                skill: "7s CT - 2000% Dark DMG (PHY). For 7s, reduce Enemies' Status RES by 30%.",
                skillbreak: 1300,
                arts: "25000% Dark DMG (PHY). Increase CRI rate by 100% against enemies with Status ailment(s) for the duration of Arts. Additionally when used against enemies with Status ailment(s), multiply the DMG Multiplier of current Arts by [1.5 + 0.5 x # of unique Status ailments on enemy].",
                artsbreak: 3100,
                truearts: "67000% Dark DMG (MAG). For 20s, increase own Arts by [3 × # of unique Status ailments on Enemy with the most Status ailments]/s, and all Allies' DMG by [50% × # of unique Status ailments on Enemy with the most Status ailments].",
                trueartsbreak: 3600
            },
        passive: 
            {
                ability1: "Increase Allies' evasion by 10%.",
                ability2: "All attacks have a 4% chance to inflict Blind (except equipment) for 8s.",
                ability3: "Increase DMG to Human Enemies by 80%."
            }, 
        passiveJP: 
            {
                ability1: "Increase Allies' evasion by 10%.",
                ability2: "All attacks have a 8% chance to inflict Blind (except equipment) for 8s.",
                ability3: "Increase DMG to Human Enemies by 80%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_dark4.png",
                    mat1amt: 3,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_dark3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 1,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 1,
                    hover4: "Radachne",
                    mat5: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat5amt: 1,
                    hover5: "Freydal"
                }
            },
            dreamJP: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                        mat3amt: 2,
                        hover3: "Palamicia",
                    },
                    passive: {
                        ability1: `Increase own DMG against Gods by 150%.`,
                        ability2: `Increase all allies' Arts by 30 at Start of Quest. Does not stack with Abilities of the same name.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragValzandeth.png",
                        mat3amt: 2,
                        hover3: "Valzandeth",
                    },
                    passive: {
                        ability1: `Increase own Arts by 2/s.`,
                        ability2: `Increase all allies' Arts DMG by 40%. Does not stack with Abilities of the same name.`
                    }
                }
            },
        lore: 
            {   
                evo4: "She was an elite gunwoman, part of the Demonic Intelligence and Assassination Corp, early in the Ancient War. Since childhood, she was taught only about the art of killing and her humanity was suppressed. She once was ordered to kill Uther to topple the Divine Guardian forces. The assassin made her way smoothly into the castle, where her quarry awaited. And yet, the Knights managed to capture her. The captain, however, felt a sense of deep empathy for her and astonishingly turned on his comrades to lead her to safety.",
                evo5: "A former Demons assassin who rediscovered her heart. She was unable to understand why the knight captain had saved her. She tried over and over to kill him and return home, but each time he avoided her without violence. She felt only confusion. Yet eventually. she came to find peace in his presence. And it was this realization that lifted her heart when she saw him risking his life to protect her. She decided to use her power to defend the first valuable thing she'd ever found: friendship.",
                evoawk: "Her life changed the moment when she protected the Captain of the Divine Knights from his former subordinates, who was trying to kill him. While once she had been a mere weapon, a mindless follower of orders, in that instant her own will began to flourish. She soon grew proud that she was able to pull the trigger for her own reasons. And tonight, she would need to open fire once more, to save her life, and the life of the one she wanted to protect..."
            }
    },
    { //Gaia - Complete, Review done + JP ver
        id: 101716211,
        name: "Gaia",
        rank: "A-",
        luckrank: "F",
        attribute: "Water",
        type: "God",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101714211.png",
                detail5: "/db/Units/Detail/unit_detail_101715211.png",
                detailawk: "/db/Units/Detail/unit_detail_101716211.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101714211.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101715211.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101716211.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 6,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6783,
                hpplus: 999,
                atk: 2518,
                atkplus: 387,
                def: 2840,
                defplus: 436
            },
        skillset: 
            {
                skill: "8s CT - 2050% Water DMG (PHY). Heal own HP by 150.",
                skillbreak: 1700,
                arts: "12000% Water DMG (MAG). For 20s,  Allies gain a DMG barrier that negates [# of Water Allies x 1000] DMG.",
                artsbreak: 2400,
                truearts: "24000% Water DMG (MAG). For 20s, increase Allies' DMG RES by 20% and Water Allies' DMG by 30%.",
                trueartsbreak: 2700
            },
        skillsetJP: 
            {
                skill: "8s CT - 2050% Water DMG (PHY). Heal own HP by 150.",
                skillbreak: 1700,
                arts: "18000% Water DMG (MAG). For 20s, Allies gain a DMG barrier that negates [# of Water Allies x 1300] DMG.",
                artsbreak: 2400,
                truearts: "33000% Water DMG (MAG). For 20s, increase Allies' DMG RES by 30% and Water Allies' DMG by 30%.",
                trueartsbreak: 2700
            },
        passive: 
            {
                ability1: "Increase Near-Death Allies' DMG RES by 10%.",
                ability2: "Increase Water Allies' Arts by 1/s.",
                ability3: "Increase own DEF by 20% when under a DMG barrier."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_114152.png",
                    mat3amt: 20
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGaia.png",
                    mat3amt: 20,
                    hover3: "Gaia",
                }
            },
            dreamJP: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 100,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_GaiaDream.png`,
                        mat2amt: 100
                    },
                    passive: {
                        ability1: `Increase allies' Fire RES by 20%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own Arts by 3/s.`
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 100,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_GaiaDream.png`,
                        mat2amt: 100
                    },
                    passive: {
                        ability1: `Increase allies' MAX HP by 10%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own Equip CT Speed by 20%.`
                    }
                }
            },
        trueweapon: 
            {
                name: "Castillo",
                slot: "/db/Mines/thumbnail/defLB.png",
                detail: "/db/Equipment/Detail/item_detail_109231.png",
                skill: "40s CT - For 10s, increase Allies' DMG RES by 15%, and Water Allies' DEF by 50%.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Gaia, increase own ATK by 20% and increase own Arts by 2/s. Does not stack with Abilities of the same name.",
                        ability2: "Increase own DEF by 20% when under a DMG barrier."
                    }
            },
        lore: 
            {   
                evo4: "A divine swordsman during the early period in the Ancient War. He had absolute loyalty to the Higher Gods and paid no heed to personal peril, so he was often sent to the front lines. He was gravely wounded in a surprise attack by a Demon girl during a top-secret investigation of a Dragon research lab. However, his goal just happened to be home to a skilled doctor who saved his life. She saved him against the strong objections of her Dragon superiors, and just as he came to realize his obligation to her. Demons attacked the facility.",
                evo5: "A swordsman who destroyed a Dragon facility early in Ancient War. His life was saved by a doctor there, yet his wounds still kept him from defending her in a Demon attack. Then, a Dragon researcher appeared and claimed to be able to awaken great power within him. so he agreed immediately with no doubts. His wounds healed before his very eyes. As he felt incredible power filling his body, he stood before the Demons. Though, he did not know that he will go berserk at this moment...",
                evoawk: "A God knight who agreed to undergo an augmenting operation. It indeed let him fulfill his mission to destroy the invading Demon soldiers, but that moment of triumph was short-lived as he realized he could not control his new strength. That was why he ignored the outstretched hand of the girl who saved him and left without a word -- his very presence had become a threat to those he meant to protect."
            },
        review:
            {
                skill: ``,
                arts: ``,
                truearts: ``,
                ability1: ``,
                ability2: ``,
                ability3: ``,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Before awakening, Gaia was a mitigator/shield unit that only worked to a desirable level in mono-water teams, with his NA granting the team a shield that scales up by 1000 dmg x the number of water units in the team, and one of his passives granting 10% dmg res to near-death units. His awakening gives him a TA that increases the party’s dmg res by 20% and buffs water units’ damage by 30 %. This, coupled with his two defense slots, enables Gaia to be used to substantially mitigate boss dmg. While not as airtight as what Sanstone can achieve, this is nonetheless extremely useful for new players, especially on a free farmable unit that can also serve double-duty as luck unit, or for more experienced players in water ruins (where Gaia can also provide 1 arts/s teamwide with his passive). Awoken Gaia is a unit with a narrower use, but one that can be vital in certain quests.`,
                lastupdated: `June 23, 2022`,
                author: `MOB of Metal`
            }
    },
    { //Millenia - Complete
        id: 101726112,
        name: "Millenia",
        rank: "F",
        attribute: "Fire",
        type: "Demon",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101724112.png",
                detail5: "/db/Units/Detail/unit_detail_101725112.png",
                detailawk: "/db/Units/Detail/unit_detail_101726112.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101724112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101725112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101726112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6796,
                hpplus: 859,
                atk: 2472,
                atkplus: 319,
                def: 2388,
                defplus: 308
            },
        skillset: 
            {
                skill: "8s CT - 2000% Fire DMG (PHY). The last Ally to use a Skill/Arts before this Skill gains 20% evasion for 8s.",
                skillbreak: 1300,
                arts: "15000% Fire DMG (PHY). Permanently increase Allies' Skill and Arts Critical Rate by 20% (up to 60%).",
                artsbreak: 2400,
                truearts: "30400% Fire DMG (PHY). For 7s, increase Allies' Critical DMG by 70%.",
                trueartsbreak: 3700
            },
        passive: 
            {
                ability1: "Skill can be used twice after Skill CT is refreshed.",
                ability2: "Increase Earth RES by 25%.",
                ability3: "Heal HP by 12/s."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_fire4.png",
                    mat1amt: 3,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_fire3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                    mat3amt: 2,
                    hover3: "Degilmagna",
                }
            },
        lore: 
            {   
                evo4: "A Demon Warrior who let loose during the first part of the Ancient War. She is a ruthless spy agent who has no regret in killing her opponent. To achieve a goal, the method is no concern to her. Due to her young girl appearance, many fools carelessly threw themselves at her and found death. She once heard from her superiors that the Dragons were developing a way to expand the true magic inside oneself and transform it, so she was dispatched to take over the power or at worst, destroy it.",
                evo5: "A spy agent that took part in the massacre of Dragons during the Great War. Millenia is an efficient agent who uses charm to her advantage, particularly rival agents after the same goal. Seducing, then finishing them off would be her method. But that didn't stop her from destroying her enemies so long as they showed the slightest sign of resistance. Committed to her mission, nothing will stop her--except the Dragons that she had supposedly destroyed.",
                evoawk: "Millenia accomplished her mission taking advantage of her unhinged target, but was swallowed up in the blast and her traces are lost since then. Though highly valued as a spy, she wanted to leave the Organization and be free. But she knew far too much and her wish was refused. According to one theory, her disappearance is said to have been planned to fulfill that wish, but nobody knows what really happened."
            }
    },
    { //Alche - Complete
        id: 101736412,
        name: "Alche",
        rank: "C",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101734412.png",
                detail5: "/db/Units/Detail/unit_detail_101735412.png",
                detailawk: "/db/Units/Detail/unit_detail_101736412.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101734412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101735412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101736412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/healLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 5988,
                hpplus: 999,
                atk: 2275,
                atkplus: 387,
                def: 2479,
                defplus: 436
            },
        skillset: 
            {
                skill: "9s CT - Heal Allies' HP by 400. For 5s, increase Allies' Skill CT REC by 20%.",
                skillbreak: 0,
                arts: "Heal Allies' HP by 30%, revive an Ally with 100 Arts and reduce that Ally's maximum HP by 15%.",
                artsbreak: 0,
                truearts: "19000% Light DMG (MAG). For 15s, heal Allies' HP by 200/s and increase Allies' DMG by 30%. Increase Allies' DMG to Enemies that they have Elemental Advantage against by an additional 30%.",
                trueartsbreak: 1200
            },
        passive: 
            {
                ability1: "Increase Dark RES by 20%.",
                ability2: "Heal HP by 18/s.",
                ability3: "Increase Allies' Disease RES by 50%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat1amt: 3,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_water4.png",
                    mat2amt: 2,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat3amt: 2,
                    hover3: "Radachne"
                }
            },
        lore: 
            {   
                evo4: "A Human doctor during the early period of the Ancient War. She worked at the research Lab of the Dragons as one of the medical team members and was loved by everyone for her devoted spirit. Although Humans were under control of the Dragons, she respected them as a high-rank species. One day she came across a dying God and with no hesitation, she took care of him. She was criticized for bringing in an enemy, but no one could say back anything to her belief in 'all who are injured are equal.' Thus, the Dragons accepted her request.",
                evo5: "A Human doctor who was respected by the Dragons during the early period of the Ancient War. Suddenly. Demons attacked their Lab, and one by one defeated the researchers. Although she was told to run away, with all her might. she kept helping them. Until the very end. she continued to help everyone. The God who she saved appeared in front of them with his wound completely gone. Within seconds, he drove back the Demons. But the next second, he lost his mind and went out of control!",
                evoawk: "Managing to snap out of his raging frenzy temporarily, the God warrior came to his senses for a moment and fled. The girl, realizing that he may be too far gone to rescue and understanding the damage he could cause, is in a dilemma. There are some things that alchemy can't save. Despite this, she raised her head and spoke, \"Even if I can't save everyone, I will do whatever it takes to save as many people as possible.\" And with those words, chased after him."
            }
    },
    { //Louvet - Complete
        id: 101746312,
        name: "Louvet",
        rank: "F",
        attribute: "Earth",
        type: "Dragon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101745312.png",
                detailawk: "/db/Units/Detail/unit_detail_101746312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101745312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101746312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6391,
                hpplus: 968,
                atk: 2200,
                atkplus: 340,
                def: 2142,
                defplus: 331
            },
        skillset: 
            {
                skill: "8s CT - 1900% Earth DMG (PHY). For 6s, increase own evasion by 20%.",
                skillbreak: 1500,
                arts: "14200% Earth DMG (PHY). For 10s, increase Fire, Water and Earth Allies' DMG by 30%.",
                artsbreak: 2400,
                truearts: "23600% Earth DMG (PHY). For 20s, increase Fire, Water and Earth Allies' DMG by 60%, reduce Enemies' Break RES by 25%.",
                trueartsbreak: 4000
            },
        passive: 
            {
                ability1: "Increase Dark RES by 20%.",
                ability2: "Increase Paralysis RES by 100%.",
                ability3: "Increase all Allies' DMG to Weak Element Enemies by 20%. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat3amt: 5,
                    hover3: "Elpneumas",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 5,
                    hover4: "Radachne",
                    mat5: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat5amt: 3,
                    hover5: "Goldola"
                }
            },
        lore: 
            {   
                evo5: "A scholar of the Grramenia Republic in the era of confusion just after the Ancient War. He was also a member of the Five Freedom Fighters, and a fellow researcher into the true history along with the noble son Freed. The former La Riiz intelligence operative, friend and collaborator joined their journey at Louvet's invitation. The oldest of the Five Freedom Fighters, his vast stores of knowledge and wisdom have earned him deep faith from the others. And as a result, he was the first to understand the true secret history.",
                evoawk: "After escaping from La Riiz' assassin, he and the noblewoman of the Principality of Bamint finally married. The two lived happily in secret, but he was unable to abandon his research, and he finally did the reach the deepest secrets of the hidden history. But sadly Sonije fell into the relentless assassin's trap, and when he tried to save her he lost his life. A letter he wrote revealing the assassin's identity and the power hidden within the Five Freedom Fighter's leader was never delivered."
            }
    },
    { //Gran Brave - Complete
        id: 101756111,
        name: "Gran Brave",
        rank: "C",
        twrequire: "favorable",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101754111.png",
                detail5: "/db/Units/Detail/unit_detail_101755111.png",
                detailawk: "/db/Units/Detail/unit_detail_101756111.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101754111.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101755111.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101756111.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6660,
                hpplus: 962,
                atk: 2856,
                atkplus: 444,
                def: 2712,
                defplus: 375
            },
        skillset: 
            {
                skill: "5s CT - 1600% Fire DMG (PHY). 50% chance to inflict Burn for 8s.",
                skillbreak: 1000,
                arts: "13500% Fire DMG (PHY). 80% chance to inflict Burn for 8s. For 15s, increase own Skill CT REC by 150%.",
                artsbreak: 3600,
                truearts: "40000% Fire DMG (PHY). For 30s, increase own Skill DMG by 300% and Critical DMG by 100%.",
                trueartsbreak: 4000
            },
        passive: 
            {
                ability1: "Increase Allies' Arts DMG by 15%.",
                ability2: "Increase own Critical Rate by 100% against Burned Enemies.",
                ability3: "Increase Clyde, Arth and Corsair's stats by 20%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat2amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat3amt: 1,
                    hover3: "Ifrit",
                    mat4: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                    mat4amt: 2,
                    hover4: "Degilmagna"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Magmation",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_106481.png",
                skill: "40s CT - 5800% Fire DMG (PHY). If user is Fire, increase own BE generation by 50% for 8s.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Gran Brave, increase stats and DMG by 20%.",
                        ability2: "Increase Skill DMG by 30%."
                    }
            },
        lore: 
            {   
                evo4: "The greatest swordsman in all of Isliid, who fought slightly before the Great War. Raised in an orphanage on a nameless island, Gran Brave grew up strong with intense resentment towards the world and his parents for abandoning him. A natural swordsman, he was quickly recognized for his abilities by the emperor of Isliid. Even after being invited to become a high-ranking nobleman, he continued to amass incredible achievements, becoming a hero known to all of Isliid.",
                evo5: "One of Raktehelm's legendary 3 Blade Masters, known to history as Clyde's master and a celebrated imperial hero. He one day encountered a young swordsman's ability. When he witnessed Clyde's potential, he immediately abandoned his title and position in Isliid and dedicated all of his energies to training Clyde. He surely bore witness to the fruits of that training as the swordsman guided the Empire and all of Humanity's future during the course of the Great War.",
                evoawk: "Before Gran Brave knew it, his student had left him, climbing the ranks to become an unparalleled imperial knight through battles with many powerful foes. Gran Brave quietly prepared to rest on his laurels, occasionally looking down on his student's growth while still taking pride in the pupil's achievements as if they were those of the master. Not many tales are told of Gran Brave the mentor, but those of Gran Brave the warrior will live on forever in legend. Clyde of the 3 Blade Maseters once said that the only warrior who ever surpassed him was his master."
            }
    },
    { //Kazlaser - Missing Lore (JP)
        id: 101765411,
        name: "Kazlaser",
        translate: "Kaz Laser",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101765411.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101765411.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 5550,
                hpplus: 752,
                atk: 2350,
                atkplus: 289,
                def: 1750,
                defplus: 307
            },
        skillset: 
            {
                skill: "9s CT - 1200% Light DMG (PHY).",
                skillbreak: 1450,
                arts: "5800% Light DMG (PHY). For 20s, increase Allies' ATK by 30%.",
                artsbreak: 1800
            },
        passive: 
            {
                ability1: "Increase DMG to God Enemies by 50%.",
                ability2: "Increase DMG to Demon Enemies by 50%."
            },
        lore: 
            {   
                evo5: "Currently unreleased in Global.",
            }
    },
    { //Marzex (Light) - Complete
        id: 101776412,
        name: "Marzex (Light)",
        rank: "B-",
        twrequire: "favorable",
        attribute: "Light",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101775412.png",
                detailawk: "/db/Units/Detail/unit_detail_101776412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101775412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101776412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 5,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6024,
                hpplus: 915,
                atk: 2868,
                atkplus: 439,
                def: 2676,
                defplus: 411
            },
        skillset: 
            {
                skill: "13s CT - 2200% Water, Fire, and Earth DMG (PHY). Cure own Poison, Disease, Burn and Curse.",
                skillbreak: 1850,
                arts: "18000% Water, Fire and Earth DMG (PHY). Permanently increase ATK by 15% (No Limit) + For 70s, activations of True Arts will also increase Arts by 100.",
                artsbreak: 2200,
                truearts: "32000% Elementless DMG (Typeless). Increase this True Arts' Critical Rate by 100%. Permanently increase own maximum HP by 15% (No Limit).",
                trueartsbreak: 3500
            },
        passive: 
            {
                ability1: "Increase own Skill CT REC by 1%/s (No Limit)",
                ability2: "Increase DMG to all Enemies (except Gods) by 50%.",
                ability3: "Increase MAG evasion by 25%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 2,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat4amt: 2,
                    hover4: "Wilkes"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Valiant",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_107281.png",
                skill: "30s CT - 4500% Light DMG (PHY). For 5s, reduce non-God Enemies' DMG RES by 20%.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Marzex, increase Critical DMG by 70% and increase own Arts by 1/s.",
                        ability2: "When near-death, increase DMG RES by 10%."
                    }
            },
        lore: 
            {   
                evo5: "The merciful side of God of the Heavens Marzex, one of the Ten Divine Forces, which once led the Gods. During the late period of the Ancient War, he fought alongside with the Humans, who nonetheless tricked him and sealed him. Filled with anger, he eventually came back to life. However, since he could not fully detest the ones he once loved, he manifested in this kind form. But his soul, left in the realm of Gods, regretted the last days of Gods. Embarrassed by his foolishness, he decided to disappear, when he unexpectedly met a warrior of Light.",
                evoawk: "The merciful side of God of the Heavens Marzex, which he refused. The warrior of Light he met in the Realm of Gods was an adventurer, whom he eventually decided to help. No one knows whether his choice was to atone for the sins his hateful half made or to thank the adventurer for defeating him. The merciful Marzex never spoke a word, but continued to show the powers of the Gods to the adventurer."
            }
    },
    { //Number 2 - Complete
        id: 101786512,
        name: "Number 2",
        rank: "C",
        attribute: "Dark",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101785512.png",
                detailawk: "/db/Units/Detail/unit_detail_101786512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101785512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101786512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6040,
                hpplus: 999,
                atk: 2432,
                atkplus: 374,
                def: 2432,
                defplus: 374
            },
        skillset: 
            {
                skill: "10s CT - 3200% Dark DMG (MAG). If Enemy's Arts is 70% or less, increase this Skill's Critical Rate by 100%.",
                skillbreak: 1800,
                arts: "17000% Light DMG (MAG). Steal 50% of Enemies' Arts (Effect can only take place on the same Enemy once every 13s).",
                artsbreak: 3000,
                truearts: "30000% Elementless DMG (Typeless). Increase this True Arts' Critical Rate by 100%. When 'God Rage' Passive is at max stacks, increase this True Arts' Critical DMG by 100%.",
                trueartsbreak: 4000
            },
        passive: 
            {
                ability1: "When an Ally dies (except self), revive that Ally with 33% HP. Ability can only be activated once per Quest.",
                ability2: "When near-death, increase Arts by 2/s.",
                ability3: "God Rage: When this unit gains Arts, increase stats (up to 50%. At 50%, this Ability increases current HP instead)."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragValzandeth.png",
                    mat3amt: 3,
                    hover3: "Valzandeth",
                    mat4: "/db/Icons/dungeon_drops/fragAerugraxes.png",
                    mat4amt: 2,
                    hover4: "Aerugraxes",
                    mat5: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat5amt: 1,
                    hover5: "Ragsherum Phantom"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Peluscia",
                slot: "/db/Mines/thumbnail/healLB.png",
                detail: "/db/Equipment/Detail/item_detail_104471.png",
                skill: "50s CT - 7000% Typeless DMG (MAG). Heal own HP by 150 each time this attack hits an Enemy.",
                skillbreak: 1200,
                passive: 
                    {
                        ability1: "When equipped by Number 2, increase DMG by 20% and BE generation by 50%.",
                        ability2: "Increase Status RES (except Faint) by 20%."
                    }
            },
        lore: 
            {   
                evo5: "A mysterious girl who strongly resembles Iris. She works for the Demons and goes by the name “Number 2” for unknown reasons. There are other girls who resembles Iris, each named using a similar numbering scheme. Number 2 being the oldest number, has the authority to command the others. Whenever she and her friends appear, the Demon Nier can’t be far behind. The existence of Number 1 has yet to be confirmed.",
                evoawk: "The girl who was created by the Demons. She was built to use Iris' true powers. Thus, initially, she does not have any intentions or feelings and will obey any orders. However, when her other copies were defeated her feelings of hatred were unleashed. At that moment, she harmonized with the others, and many feelings filled her which led to gaining her ego."
            }
    },
    { //Lian - Complete
        id: 101796112,
        name: "Lian",
        rank: "F",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101795112.png",
                detailawk: "/db/Units/Detail/unit_detail_101796112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101795112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101796112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 5770,
                hpplus: 875,
                atk: 2567,
                atkplus: 395,
                def: 2169,
                defplus: 335
            },
        skillset: 
            {
                skill: "8s CT - 2600% Fire DMG (PHY). Increase Paralysis and Freeze RES by 100% during Skill.",
                skillbreak: 1700,
                arts: "12000% Fire DMG (PHY). 50% chance to inflict Burn for 10s. For 10s, increase own Skill CT REC by 60%.",
                artsbreak: 2300,
                truearts: "20900% Fire DMG (PHY). 100% chance to inflict Burn for 15s. Reset own Hit Count to 0 and increase Allies' Arts by the amount of Hit Count lost.",
                trueartsbreak: 3300
            },
        passive: 
            {
                ability1: "Add a Hit Counter that gains Count based on hits to enemies by own Skill, Arts and True Arts. Increase own Movement Speed and Allies' DMG based on Hit Count. Hit Counter caps at 99 hits (DMG increase is approximately 0.5% per hit).",
                ability2: "Heal own HP by 14/s.",
                ability3: "Increase all Allies' Burn and Freeze RES by 30%. Increase all Allies' Critical Rate by 50% against Burned Enemies. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 2,
                    hover3: "Fosradius",
                    mat4: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat4amt: 2,
                    hover4: "Ifrit",
                    mat5: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                    mat5amt: 2,
                    hover5: "Degilmagna"
                }
            },
        lore: 
            {   
                evo5: "The swordswoman who was brought up in a hidden village of Spirits during the middle period of Ancient War. While looking after her, her guardian taught her high magic and sword skill, but never had the chance to use it. She never knew the meaning to fight, and thought that peace was natural. Since the encounter with a warrior she learned about the outside world and became interested in it. She dreamt of going on a journey, but suddenly demons invaded her village.",
                evoawk: "She drew her sword for the first time when she encountered the existence trying to crush her and her precious family. She could not understand the Demon's actions, but she learned that there were people who wished to hurt the people she loved. Noticing the powers she was given, she chose to fight and saved the village with the warrior which she reached out to. Now that she learned ways to protect herself, her mother allowed her to go out on a journey."
            }
    },
    { //Daisy - Complete
        id: 101806212,
        name: "Daisy",
        rank: "F",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101806212.png",
                detailawk: "/db/Units/Detail/unit_detail_101806212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101806212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101806212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6007,
                hpplus: 911,
                atk: 2499,
                atkplus: 384,
                def: 2571,
                defplus: 395
            },
        skillset: 
            {
                skill: "7s CT - 1700% Water DMG (PHY). Increase own Arts by 7.",
                skillbreak: 1900,
                arts: "12000% Water DMG (PHY). If enemy is Broken during this Arts, increase own Arts by 200.",
                artsbreak: 3000,
                truearts: "22500% Water DMG (PHY). For 15s, reduce Enemies' DMG RES and Movement Speed by 30%.",
                trueartsbreak: 4900
            },
        passive: 
            {
                ability1: "Increase DMG to Demon Enemies by 50%.",
                ability2: "Increase Allies' Break PWR by 20%.",
                ability3: "Increase Allies' Arts DMG by 25% and heal Allies' HP by 250 hp/s against Enemies in Break."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat3amt: 2,
                    hover3: "Radachne",
                    mat4: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat4amt: 2,
                    hover4: "Freydal",
                    mat5: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat5amt: 2,
                    hover5: "Palamicia"
                }
            },
        lore: 
            {   
                evo5: "A Human warrior who fought at the grounds of Spirits during the Ancient War. She was brought up as a warrior and became a very talented one. However, because a Human, she never had the chance to be promoted. One day, she became lost and arrived at a village of Spirits, and met a Human girl. They got along with each other and secretly started to meet each other, but living as a Spirit, she envied her.",
                evoawk: "Before she knew it, she admired the Spirit girl she met at the hidden village and tried to take her with her to the outside world. While she hesitated to do so, she became more and important to her. However, one day the village was suddenly invaded by Demons. To protect the one she adored, she rushed out of the army to once she had pledged. Once she arrived, she saw the girl bravely facing the Demons."
            }
    },
    { //Musse - Complete
        id: 101816312,
        name: "Musse",
        rank: "B",
        attribute: "Earth",
        type: "Spirit",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101814312.png",
                detail5: "/db/Units/Detail/unit_detail_101815312.png",
                detailawk: "/db/Units/Detail/unit_detail_101816312.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101814312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101815312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101816312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/healLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6685,
                hpplus: 999,
                atk: 2592,
                atkplus: 398,
                def: 2675,
                defplus: 411
            },
        skillset: 
            {
                skill: "9s CT - 2700% Earth DMG (MAG). During Skill, increase own BE generation by 60%.",
                skillbreak: 900,
                arts: "16000% Earth DMG (MAG). Increase this Arts' Critical Rate against Water Enemies by 100%.",
                artsbreak: 1100,
                truearts: "18000% Earth DMG (MAG). For 17s, heal Allies' HP by 300/s + increase Allies' Healing Taken by 20% (Stackable).",
                trueartsbreak: 1400
            },
        passive: 
            {
                ability1: "Increase Allies' Critical DMG by 30%. Does not stack with Abilities of the same name.",
                ability2: "Increase Allies' MAX HP by 15%. Does not stack with Abilities of the same name.",
                ability3: "Increase Allies' Healing Taken by 30%. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_earth4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_fire4.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_earth3.png",
                    mat3amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat3amt: 2,
                    hover3: "Elpneumas",
                }
            },
        lore: 
            {   
                evo4: "She was a magician from a reclusive Spirit village who served as an advisor during the Ancient War. When she went to offer aid in the aftermath of the battle for domination in the region, she adopted a Human war orphan and taught her magic so she could adapt to her new life. As the months passed, she came to think of her as her own daughter, and that attachment made it all the more painful when the girl started showing an interest in the outside world.",
                evo5: "She earned much fame for her actions during the outside of the Ancient War, but she soon decided to retire to her home village. Unfortunately, she was given no time to rest as Demons attacked in force. Not even one of her incredible talent could oppose such overwhelming numers, but she was determined to protect her adopted daughter at the cost of her own life. Witnessing that deep love, the young girl too made a stand.",
                evoawk: "She stood against the Demons to protect what she held dear, but death seemed inevitable. However, before the fatal blow fell, there was a bright flash from her adopted daughter's blade. It was that moment she realized the girl she had proected for so long was now strong enough to defend others. Entrusting their back to the other, the pair fought back the huge army. Several days later, their wounds had healed and she gave the young girl her own sword. With the warm, sad gaze of a mother, she then watched her depart into the wide world."
            }
    },
    { //Freed - Complete
        id: 101826412,
        name: "Freed",
        rank: "F",
        twrequire: "favorable",
        attribute: "Light",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101825412.png",
                detailawk: "/db/Units/Detail/unit_detail_101826412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101825412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101826412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6874,
                hpplus: 999,
                atk: 3350,
                atkplus: 512,
                def: 2367,
                defplus: 365
            },
        skillset: 
            {
                skill: "7s CT - 2300% Light and Dark DMG (PHY). Increase own Arts by 10.",
                skillbreak: 1700,
                arts: "17550% Light and Dark DMG (PHY). Permanently increase own Critical Rate by 20% (up to 100%) + For 16s, increase own Skill CT REC by 50%.",
                artsbreak: 2500,
                truearts: "35000% Light and Dark DMG (PHY). Increase own Arts by 2 each time own True Arts crits.",
                trueartsbreak: 3600
            },
        passive: 
            {
                ability1: "Increase Allies' max HP and DEF by 20%. Increase own Critical DMG by 30%.",
                ability2: "Increase DMG to Light Enemies by 20%.",
                ability3: "Increase DMG to Dark Enemies by 20%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 3,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                    mat4amt: 5,
                    hover4: "Geo-Zagnus",
                    mat5: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat5amt: 5,
                    hover5: "Ifrit"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Siegfried",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_104301.png",
                skill: "3s CT - 900% Elementless DMG (PHY). Deal 1200% Elementless DMG (PHY) to Demon and God Enemies instead. If this attack hits an Enemy, heal own HP by 50 + For 3s, reduce own DEF by 20%.",
                skillbreak: 240,
                passive: 
                    {
                        ability1: "When equipped by Freed, increase stats by 30% and increase Paralysis and Freeze RES by 100%. Does not stack with Abilities of the same name.",
                        ability2: "Increase Arts DMG by 20%. If user is God, increase Equipment CT REC by 20%. Does not stack with Abilities of the same name."
                    }
            },
        lore: 
            {   
                evo5: "A noble child of the Isliid Empire and one of the Five Freedom Fighters. He was trusted by all due to his kindness and sense of justice, and he mastered both literacy and martial arts. When he was young, his brother disappeared from the Royal Library of Famelle, a place they often visited. He decided to dedicated himself to looking further into the true history... Believing that the disappearance was connected to the true history, he went on a journey with his comrades. As they approached to the core of the matter, they were hindered by an assassin and hunted by every nation. He tried to hide, but the assassin eventually",
                evoawk: "Thrown into a frenzy after the murder of the one he loved, he was shocked by her last words... The assassin was his lost brother. He fought on, unsure what to believe, but recognized his brother's face behind the mask. In the confusion of love and hate, his enemy was perplexed when called by name by a loved one. Brought to senses by his brother's stilled sword and heartfelt words. Grohl explained of how he was kidnapped, and how is glad that Freed is well. However, the time is brief, and when he went back to madness, the assassin slayed his younger brother without hesitation."
            }
    },
    { //Grohl - Complete
        id: 101836512,
        name: "Grohl",
        rank: "F",
        attribute: "Dark",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101835512.png",
                detailawk: "/db/Units/Detail/unit_detail_101836512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101835512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101836512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6237,
                hpplus: 945,
                atk: 2691,
                atkplus: 413,
                def: 2201,
                defplus: 340
            },
        skillset: 
            {
                skill: "8s CT - 2000% Dark DMG (MAG). Heal own HP by 4% of DMG dealt by this Skill.",
                skillbreak: 1200,
                arts: "13000% Dark DMG (MAG). 40% chance to inflict Nightmare for 12s. For 6s, increase Allies' BE generation by 70%.",
                artsbreak: 2100,
                truearts: "24600% Dark DMG (MAG). 100% chance to inflict Nightmare for 12s + For 15s, reduce Enemies' MAG RES by 20%. If Enemy is afflicted by Nightmare by own True Arts, for 15s, reduce that Enemy's Dark RES by 20%.",
                trueartsbreak: 2700
            },
        passive: 
            {
                ability1: "Increase own Water, Earth and Dark RES by 10%.",
                ability2: "Increase DMG to non-Boss enemies with less than 30% HP by 150%.",
                ability3: "Increase DMG against Enemies inflicted with Blind or Nightmare by 100%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat3amt: 5,
                    hover3: "Palamicia",
                    mat4: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat4amt: 5,
                    hover4: "Goldola",
                    mat5: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat5amt: 3,
                    hover5: "Ragsherum Phantom"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Gestalt",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_104311.png",
                skill: "60s CT - 4444% Dark DMG (MAG). If this attack hits a Boss with Status Ailments, steal 20 Arts from its Arts + For 6s, reduce Enemies' Blind RES by 50%.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Grohl, increase DMG by 20%, and heal HP by 200/s when inflicted by a status ailment. Does not stack with Abilities of the same name.",
                        ability2: "Increase DMG by 20% to Bosses with less than 30% HP. Does not stack with Abilities of the same name."
                    }
            },
        lore: 
            {   
                evo5: "An intelligence operative for the Magus Empire of La Riiz, whose role was to get rid of those intereted in the true history. He was about to kill his targets. who infiltrated a ruin designated forbidden by the Cult, but failed, allowing them to escape with the proof they'd gathered. Afterward, he tracked down his prey and slaughtered them one by one. until he reached the final pair. He brought down the first, whose counterattack was irresolute, and remained speechless after hearing her final words, a familiar name. Shocked, he approached his last target, equally speechless.",
                evoawk: "His hands were now dyed in the blood of someone who had once been so dear to him, someone who called him by the name he thought he'd lost... Lying in his arms was his younger brother, whom he risked his life to protect. His brother's words cut through his despair: \"Live... and reveal the true history to the world.\" He opened the letter stolen from his brother's comrades and after reading about the truth in it, he decided to devote his life to atonement. According to one theory, he eventually built an orphanage and lived lonely before heading for revenge."
            }
    },
    { //Rishley - Complete
        id: 101846112,
        name: "Rishley",
        rank: "F",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101845112.png",
                detailawk: "/db/Units/Detail/unit_detail_101846112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101845112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101846112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6750,
                hpplus: 999,
                atk: 3015,
                atkplus: 462,
                def: 2243,
                defplus: 346
            },
        skillset: 
            {
                skill: "5s CT - 2300% Fire DMG (PHY). Heal self by 2% of own max HP.",
                skillbreak: 900,
                arts: "15500% Fire DMG (PHY). 80% chance to inflict Burn for 15s.",
                artsbreak: 2500,
                truearts: "28800% Fire DMG (PHY). 100% chance to inflict Burn for 20s. Increase Fire Allies' stats by 20%, and own stats by 20%.",
                trueartsbreak: 3700
            },
        passive: 
            {
                ability1: "Increase Critical Rate by 100% against Burned Enemies.",
                ability2: "Increase Earth RES by 20%.",
                ability3: "Increase Allies' Arts DMG by 20%. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat3amt: 5,
                    hover3: "Oldeus",
                    mat4: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                    mat4amt: 3,
                    hover4: "Geo-Zagnus",
                    mat5: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat5amt: 3,
                    hover5: "Ifrit"
                }
            },
        lore: 
            {   
                evo5: "A nobleman of the 5th rank in the Kingdom of Famelle just after the war. She was one of the Five Freedom Fighters Dark,a group on the Most Wanted list of every nation for subversion of the state. They said she entered into this plan along with a noble son of the Isliid Empire she'd known since childhood. The two went on a mysterious journey together with other members of the five nations which laid the foundations for the treachery. They waited for their chance, but an operative of the Magus Empire of La Riiz stopped their plan in its tracks. To this day, no one truly knows what their crimes were.",
                evoawk: "A princess of the Kingdom of Famelle, who discovered the truth and became hunted for it. She and her companions discovered a truth that would shake the world to its foundations in dangerous ruins, and then were attacked by a mysterious assassin. They escaped with their lives from his terrifying power but were scattered. Later, she was able to live in secret with her childhood friend, but the assassin attacked once more. Just as she understood the odd feeling she'd had in the battle, the assassin ran her through."
            }
    },
    { //Lapleh - Complete
        id: 101856212,
        name: "Lapleh",
        rank: "F",
        attribute: "Water",
        type: "Machine",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101855212.png",
                detailawk: "/db/Units/Detail/unit_detail_101856212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101855212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101856212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6325,
                hpplus: 958,
                atk: 2396,
                atkplus: 369,
                def: 2119,
                defplus: 327
            },
        skillset: 
            {
                skill: "8s CT - 1000% Water DMG (MAG). Increase Golden Machina Count by 2 (up to 8), increase own Skill DMG by 100% for each Golden Machina Count (up to 800%).",
                skillbreak: 400,
                arts: "11450% Water DMG (MAG). For 12s, increase Allies' Skill CT REC by 30%, Water Allies' Skill CT REC by 30%.",
                artsbreak: 1900,
                truearts: "27450% Water DMG (MAG). If 8 Golden Machina are present, reduce Enemies' DEF by 100% for 12s and reset Golden Machina Count to 0.",
                trueartsbreak: 3800
            },
        passive: 
            {
                ability1: "Increase Equipment CT REC by 15%.",
                ability2: "Increase Burn RES by 90%.",
                ability3: "Increase Arts by 1/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragAerugraxes.png",
                    mat3amt: 5,
                    hover3: "Aerugraxes",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 4,
                    hover4: "Code-XTF",
                    mat5: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                    mat5amt: 3,
                    hover5: "Geo-Zagnus"
                }
            },
        lore: 
            {   
                evo5: "A Machine girl employed by a grand sorcerer called the Observer. There is little information about her, but every text discussing her the sorcerer includes her name. The oldest records of Raktehelm includes reference to a mighty weapon bearing the same name. As an aside, the record includes a detailed explanation of the destructive power of that weapon: it could erase an entire nation in a single night.",
                evoawk: "She was once called Lapleh, and was employed as a caretaker and manager at the orphanage where Rayas and Roy were raised. The orphanage records included a person named Lapleh in hte rold of caretaker when it was first founded, and so as time went on the person who raised and ministered to the children was simply called 'Lapleh' which is a mark of tradition. For what it's worth, the person called Lapleh when Rayas was a child has passed on, and there is a new person in that role."
            }
    },
    { //Alma - Complete
        id: 101866312,
        name: "Alma",
        rank: "F",
        attribute: "Earth",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101865312.png",
                detailawk: "/db/Units/Detail/unit_detail_101866312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101865312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101866312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7082,
                hpplus: 999,
                atk: 2582,
                atkplus: 397,
                def: 2298,
                defplus: 354
            },
        skillset: 
            {
                skill: "9s CT - 2000% Earth DMG (MAG). For 9s, reduce Enemies' ATK by 10%.",
                skillbreak: 1000,
                arts: "15500% Earth DMG (MAG). For 15s, reduce Enemies' MAG RES by 20%.",
                artsbreak: 2000,
                truearts: "24000% Earth DMG (MAG). For 15s, reduce Enemies' MAG RES by 50% + If an Enemy is Human, increase own Arts by 100.",
                trueartsbreak: 3300
            },
        passive: 
            {
                ability1: "Increase MAG RES by 30%.",
                ability2: "Increase Arts by 1/s.",
                ability3: "If HP is over 50%, increase DMG by 30%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat3amt: 4,
                    hover3: "Tonetaurus",
                    mat4: "/db/Icons/dungeon_drops/fragValzandeth.png",
                    mat4amt: 2,
                    hover4: "Valzandeth",
                    mat5: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat5amt: 2,
                    hover5: "Goldola"
                }
            },
        lore: 
            {   
                evo5: "A mysterious witch whose name appears all throughout history. The accounts of her presence are spread so wide through the ages that historians of Raktehelm believe these are accounts of different people with the same name. However, if they all are about the same person, she must have possessed unprecedented anti-aging abilities, or have mastered Hyper-time Jumping, a magic thought to be impossible. There are rumors that she instructed one of the Five Freedom Fighters in barrier magic, and aided their escape from the shadows, but they remain just rumors.",
                evoawk: "This transcendental being who often shows up at critical historical points is called \"The Watcher\" by magical researchers. There is a theory that she is also Elenore, one of the Six Great Witches, but none know if this is true. The final mention of her in the histories implies that she was in contact with the assassin who killed the Five Freedom Fighters. By some coincidence, the records of the orphanage Rayas and Roy grew up in mention someone named Alma present at the founding, possibly the same one."
            }
    },
    { //Sonije - Complete
        id: 101876412,
        name: "Sonije",
        rank: "B+",
        attribute: "Light",
        type: "Spirit",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101875412.png",
                detailawk: "/db/Units/Detail/unit_detail_101876412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101875412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101876412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6245,
                hpplus: 946,
                atk: 2097,
                atkplus: 324,
                def: 2167,
                defplus: 335
            },
        skillset: 
            {
                skill: "8s CT - Increase Allies' Arts by 5 + For 8s, increase Allies' Skill CT REC by 20%.",
                skillbreak: 0,
                arts: "7900% Light DMG (MAG). For 15s, increase Allies' Arts by 2/s.",
                artsbreak: 2100,
                truearts: "20800% Light DMG (MAG). For 12s, increase Allies' Paralysis and Freeze RES by 100%, Arts DMG by 30%, for Earth and Light Allies, increase Arts DMG by 60% instead.",
                trueartsbreak: 3600
            },
        passive: 
            {
                ability1: "Increase Allies' max HP by 10%. Does not stack with Abilities of the same name.",
                ability2: "When near-death, increase Arts by 2/s.",
                ability3: "Increase Allies' Accuracy and Critical DMG by 30%. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                    mat3amt: 2,
                    hover3: "Geo-Zagnus",
                    mat4: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat4amt: 2,
                    hover4: "Fosradius",
                    mat5: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat5amt: 4,
                    hover5: "Elpneumas"
                }
            },
        lore: 
            {   
                evo5: "A noble daughter of the Principality of Bamint in the era of confusion just after the Ancient War, and one of the Five Freedom Fighters. Granddaughter of a Spirit who befriended the Humans, she hated her strict noble upbringing and so attempted a clandestine journey to escape...but was attacked by monsters on the way. She then fell in love with the scholar who saved her, and forced her way into his traveling companionship. These later became known as the Five Freedom Fighters, the group led by Freed to find her magical power allows her mind's eye to see farther and more clearly than any sighted person.",
                evoawk: "A sorceress who passed on the true history to future generations. She fled an assassin's attack with her scholar lover, and later they bore a child in secret. Just as they were find true happiness, she was thrown into confusion when she was deceived into believing her old friends were killed by a traitor in their midst. The mention of a name already mistrusted sent her into a rage, leading her right into a trap. She agreed to help catch the \"traitor\" but ended up losing her husband and her right arm. She somehow survived and passed down the secret history to her child."
            }
    },
    { //Onfuan - Complete
        id: 101886512,
        name: "Onfuan",
        rank: "B-",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101885512.png",
                detailawk: "/db/Units/Detail/unit_detail_101886512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101885512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101886512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6721,
                hpplus: 999,
                atk: 2521,
                atkplus: 388,
                def: 2099,
                defplus: 324
            },
        skillset: 
            {
                skill: "7s CT - 1500% Dark DMG (PHY).",
                skillbreak: 800,
                arts: "7200% Dark DMG (PHY). For 25s, Allies gain a DMG barrier that negates 3000 DMG.",
                artsbreak: 1900,
                truearts: "14000% Dark DMG (PHY). For 25s, increase Allies' DEF by 30%, Allies gain a DMG barrier that negates 4000 DMG.",
                trueartsbreak: 2500
            },
        passive: 
            {
                ability1: "When near-death, increase DMG RES by 10%. Does not stack with Abilities of the same name.",
                ability2: "Increase Curse RES by 100%.",
                ability3: "Increase all Allies' DEF and Blind RES by 30%. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat3amt: 5,
                    hover3: "Mavroskia",
                    mat4: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat4amt: 5,
                    hover4: "Oldeus",
                    mat5: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat5amt: 3,
                    hover5: "Ragsherum Phantom"
                }
            },
        lore: 
            {   
                evo5: "A former intelligence operative of the Magus Empire of La Riiz, and then a member of the Five Freedom Fighters in the confused age just after the Ancient War. Born a Demon, he was a Human sympathizer, but he used the war as a chance to escape the clutches of the Church. His appearance meant he had to live in hiding, but when he found a kindred spirit in Louvet, a researcher into the true history, he offered his help. When he and his comrades were attacked by an assassin on coming too close to the truth., he used his master of barrier sorcery to protect his friends for long years.",
                evoawk: "His former comrade, the noblewoman, was deceived by the assassin and denounced him, and despite all his efforts, he could not dissuade her from her folly. And awaiting the dear friend who came to stop them was only the assassin's dagger. He sacrificed himself to save the woman, left alone to gaze on the massacre. He and the woman combined all their might into a single attack, which was countered by the assassin's blow. The mortally wounded assassin escaped, but came back to confirm the targets' deaths. He found Onfuan's corpse and the woman's severed arm. He left them there."
            }
    },
    { //Kayas - Complete
        id: 101896512,
        name: "Kayas",
        rank: "F",
        attribute: "Dark",
        type: "God",
        image: 
            {
                detail4: "/db/Units/Detail/unit_detail_101894512.png",
                detail5: "/db/Units/Detail/unit_detail_101895512.png",
                detailawk: "/db/Units/Detail/unit_detail_101896512.png",
                thumb4: "/db/Units/Thumbnail/unit_thumbnail_101894512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101895512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101896512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6827,
                hpplus: 999,
                atk: 3264,
                atkplus: 499,
                def: 2028,
                defplus: 314
            },
        skillset: 
            {
                skill: "1800% Dark DMG (MAG). Increase own BE generation by 80%.",
                skillbreak: 1150,
                arts: "19000% Dark DMG (MAG). Increase own Arts by 100 against Enemies in BREAK.",
                artsbreak: 2200,
                truearts: "32000% Dark DMG (MAG). For 15s, decrease God Enemies' MAG RES by 30%. If this unit has taken 5000 DMG since its last Arts/True Arts, increase own Arts DMG by 250% for 30s.",
                trueartsbreak: 2700
            },
        passive: 
            {
                ability1: "Increase DMG by 1% every 3s (maximum of 60% DMG increase).",
                ability2: "Increase Arts DMG every time damage is taken - 1000 DMG taken will increase Arts DMG dealt by 10%. Buff resets after using Arts or True Arts (maximum of 50% Arts DMG increase).",
                ability3: "Increase DMG to God Enemies by 50%."
            }, 
        evolution: 
            {
                evo45:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_light4.png",
                    mat1amt: 2,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_light3.png",
                    mat2amt: 5
                },
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat3amt: 2,
                    hover3: "Mavroskia",
                }
            },
        lore: 
            {   
                evo4: "A God mage during the early period of the Ancient War. However, unsuitably he used dark magic. Once a young mage, he was concerned of this talent. One day, he heard a rumor that there was a secret research of the Dragons which could extremely boost one's power, thus he disguised himself and joined the research. He engaged in it as an enthusiastic research and finally used his own body for the experiment. It succeeded, but ever since he disappeared...",
                evo5: "A mage who was expelled from the Gods during the early period of the Ancient War. He gained tremendous power at the lab of the Dragons, but that was not what he wished for. Holy light powers had inverted into deep dark powers. Being expelled from the Gods, in order to get revenge on the Dragons, he hid himself in the undergrounds of the lab, and waited for the chance. Finally, he got the chance to take revenge!",
                evoawk: "After using his Godly brethren for his own ends in a successful assault on the Dragons' lab. Kayas was welcomed back into the army of the Gods, fulfilling his long-held ambitions. However, one man stood against him: a knight of the Gods who ought to have been dead as a result of Kayas' schemes. The Mad Mage used his full power to eliminate the knight, but his hands were already stained with darkness."
            }
    },
    { //End - Complete
        id: 101906412,
        name: "End",
        rank: "F",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101905412.png",
                detailawk: "/db/Units/Detail/unit_detail_101906412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101905412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101906412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 5440,
                hpplus: 826,
                atk: 1840,
                atkplus: 286,
                def: 2200,
                defplus: 340
            },
        skillset: 
            {
                skill: "8s CT - 1800% Light DMG (MAG). Increase own Arts by 5.",
                skillbreak: 1000,
                arts: "12000% Light DMG (MAG). For 60s, increase Allies' stats by 20%.",
                artsbreak: 1900,
                truearts: "21500% Light DMG (MAG). For 10s, increase Allies' DMG by 20%, increase God Allies' DMG by 30%.",
                trueartsbreak: 3300
            },
        passive: 
            {
                ability1: "Increase own Critical DMG by 10%. At the start of a Battle, increase own Arts by 100. Does not stack with similar Abilities.",
                ability2: "Increase DMG to Giant Bosses by 20%.",
                ability3: "Increase DMG RES against God Enemies by 15%.",
                ability4: "Increase own Critical Rate by 20%. 60s after a Quest starts, increase Arts by 100. Can only be activated once per Quest."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 1,
                    hover3: "Fosradius",
                    mat4: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat4amt: 1,
                    hover4: "Flogassa",
                }
            },
        lore: 
            {   
                evo5: "A girl who is the leader of the Wasetto, a tribe of humans who existed in the early days of the Ancient War who possess the power to see the future. While most tribe members can only see visions of a near future, her powers outclass the rest of the tribe and is sometimes able to see far beyond in the future. Although the lives of the Wasetto tribe were protected by their guardian God, other races discover their existence and try to capture her for their own benefits.",
                evoawk: "She who swears to remain in isolation in order to fight, suddenly sees a vision of the future. A future in which humans are victorious in the Ancient War and rewrite history. However, mankind will commit a great sin and betray the Gods in this process. After prophesizing this future, she stabs herself with a blade in the hopes that by erasing her own existence, this tragic future can be avoided..."
            }
    },
    { //Coco - Complete
        id: 101916512,
        name: "Coco",
        rank: "F",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101915512.png",
                detailawk: "/db/Units/Detail/unit_detail_101916512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101915512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101916512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 5890,
                hpplus: 893,
                atk: 2340,
                atkplus: 361,
                def: 2590,
                defplus: 398
            },
        skillset: 
            {
                skill: "8s CT - 3000% Dark DMG (PHY).",
                skillbreak: 2500,
                arts: "16800% Dark DMG (PHY). For 10s, increases own BE generation by 80%.",
                artsbreak: 4800,
                truearts: "32000% Dark DMG (PHY). Break PWR of True Arts becomes 20000 when attacking Raid Bosses. Otherwise, reduce Enemies' Break Bar by 50% (effect can only take place on the same Enemy once every 40s).",
                trueartsbreak: 3300
            },
        passive: 
            {
                ability1: "Activates after using True Arts. For 50s, increase Arts by 2/s, increase Arts DMG to Raid Bosses by 100% and increase Skill CT REC by 30%. Can be activated multiple times.",
                ability2: "When near-death, heal own HP by 6% of DMG dealt."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 1,
                    hover3: "Flogassa",
                    mat4: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat4amt: 1,
                    hover4: "Mavroskia",
                }
            },
        lore: 
            {   
                evo5: "A young warrior who led the massive army of Demons before the Ancient War. Having great pride in being a Demon, she was accepted for her loyalty and high battle skills and made an unusual success. One day, she was ordered to capture a maiden with future vision within the Wasetto Tribe. She fights with a Dragon army whose goal was the same as her. Being a High Demon, the battle between Anna the divine god of Wasetto was a severe one.",
                evoawk: "Now her target has changed from the divine god to the Dragon warrior who was aiming to kill the maiden. In order to get him out of her way, she fought with all her strength. However, during the battle, she learns that he was a warrior who fought following his beliefs. When both accepted each other's sword skills, their battle suddenly ended with the maiden's suicide. The maiden gone, Coco was rushing home to report the prediction she left. It was about a large war between the Gods..."
            }
    },
    { //Anna - Complete
        id: 101926112,
        name: "Anna",
        rank: "F",
        attribute: "Fire",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101925112.png",
                detailawk: "/db/Units/Detail/unit_detail_101926112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101925112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101926112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6220,
                hpplus: 943,
                atk: 2650,
                atkplus: 403,
                def: 2020,
                defplus: 313
            },
        skillset: 
            {
                skill: "9s CT - 1600% Fire DMG (PHY). For 6s, reduce Enemies' Burn RES by 20%.",
                skillbreak: 800,
                arts: "7600% Fire DMG (PHY). 100% chance to inflict Burn for 12s. For 7s, increase Allies' Poison RES by 80%.",
                artsbreak: 1600,
                truearts: "20000% Fire DMG (PHY). 100% chance to inflict Burn for 18s. Reduce Enemies' Arts by 30.",
                trueartsbreak: 2600
            },
        passive: 
            {
                ability1: "Increase Poison RES by 100%.",
                ability2: "Increase Allies' Critical Rate by 30%. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 2,
                    hover3: "Flogassa",
                }
            },
        lore: 
            {   
                evo5: "A divine swordsman worshiped by a particular tribe of Humans early in the Ancient War. She served as a guardian for the tribe, and also watched over the tribe's Second Sight holder. Her particular duty is to protect the Maiden, priestess and leader of the tribe, with whom she had secretly developed a special bond. The Maiden had unheard of precognitive abilities so she became a target for all the other races, and Anna risked her life to save her from harm.",
                evoawk: "Eventually, after years of laying her own life on the line, the Maiden died in her arms. Yet the end had come not through work of the Demons who'd hunted her, or the Dragon general come to kill her. but by her own hand. The Maiden had foreseen a future war that would fit all the great races against each other, and had lain there. gasping and begging for forgiveness. Anna could only punish those who had hunted her so relentlessly. for she did not understand what had happened."
            }
    },
    { //Sly - Complete
        id: 101936212,
        name: "Sly",
        rank: "F",
        attribute: "Water",
        type: "Giant",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101935212.png",
                detailawk: "/db/Units/Detail/unit_detail_101936212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101935212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101936212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6550,
                hpplus: 992,
                atk: 2380,
                atkplus: 327,
                def: 2260,
                defplus: 349
            },
        skillset: 
            {
                skill: "9s CT - 2200% Water DMG (PHY). 15% chance to inflict Freeze for 2s.",
                skillbreak: 1400,
                arts: "15800% Water DMG (PHY). If own Arts hits a Enemy in BREAK, increase own Arts by 100.",
                artsbreak: 2600,
                truearts: "30000% Water DMG (PHY). Increase Critical Rate of True Arts by 100% + For 25s, if activated while own HP is less than 50%, increase own ATK by 150% and DMG to Demon and Human Enemies by 100%.",
                trueartsbreak: 4400
            },
        passive: 
            {
                ability1: "Increase DMG to Human Enemies by 50%.",
                ability2: "Increase DMG to Demon Enemies by 50%.",
                ability3: "Increase Paralysis and Freeze RES by 100%. When near-death, increase Status RES (except Faint) by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                    mat3amt: 5,
                    hover3: "Geo-Zagnus",
                    mat4: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat4amt: 3,
                    hover4: "Mavroskia",
                    mat5: "/db/Icons/dungeon_drops/fragAerugraxes.png",
                    mat5amt: 3,
                    hover5: "Aerugraxes"
                }
            },
        lore: 
            {   
                evo5: "He always accomplished his jobs impeccably, the the clients were not always satisfied. Sometimes he even received complaints about that. Once, he was hired by a female ogre, and because she didn't specify to kill also the swordsman at the service of the target, he let him live, so he eventually defeated the ogre. Another time he didn't attack the archer who had come to rescue his targets and let her go after the battle. Those times he used to say 'I wasn't paid for that.' However, if he wished to, she would have been killed by now.",
                evoawk: "He was a mercenary and assassin in the first part of the Ancient War. Besides this, he almost did anything for a reward. His abilities were first class and his rationality absolute. He certainly would never accept a job without a proper reward, but he didn't choose his clients, and wouldn't hesistate to cause the collapse of a nation to accomplish his misison. His job let him around the world, and he is also mentioned in hero sagas both as an ally and as an enemy."
            }
    },
    { //Vogue - Complete
        id: 101946312,
        name: "Vogue",
        rank: "F",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101945312.png",
                detailawk: "/db/Units/Detail/unit_detail_101946312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101945312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101946312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 5500,
                hpplus: 835,
                atk: 2320,
                atkplus: 358,
                def: 2080,
                defplus: 322
            },
        skillset: 
            {
                skill: "5s CT - 1400% Earth DMG (PHY). Increase own Arts by 10 + For 5s, reduce Enemies' accuracy by 30%.",
                skillbreak: 600,
                arts: "12000% Earth DMG (PHY). Increase own Critical Rate and DMG by 20% (up to 100%).",
                artsbreak: 2000,
                truearts: "18000% Earth DMG (PHY). For 10s, increase own Paralysis and Freeze RES, increase Allies' accuracy by 50%.",
                trueartsbreak: 2800
            },
        passive: 
            {
                ability1: "Increase MAG evasion by 20%.",
                ability2: "Increase Arth's Break PWR and DMG RES by 10%. Does not stack with Abilities of the same name.",
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat3amt: 1,
                    hover3: "Tonetaurus",
                    mat4: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat4amt: 2,
                    hover4: "Goldola",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Glamerald",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_104461.png",
                skill: "45s CT - 7200% Earth DMG (PHY). For 8s, increase Earth Allies' Break PWR by 30%.",
                skillbreak: 2400,
                passive: 
                    {
                        ability1: "When equipped by Vogue, increase DMG by 20% and PHY RES by 15%.",
                        ability2: "When near-death, increase evasion by 10%."
                    }
            },
        lore: 
            {   
                evo5: "A swordsman who was the senior of Arth, of the Twin Blades early in the Ancient War, when the 3 Blade Masters were still training in the Zairyudo Sword school at his parents' house. Everyone there possessed incredible ability, and was respected for their kindness. Yet Arth showed interest only in his own sword training and just opened up to his friend Vogue. As for Vogue himself, he worried deeply for his friend and his endless pursuit of strength.",
                evoawk: "He continued to watch out for Arth, who had worked so hard he had surpassed his masters and begun his walk on the path of twin blades. Vogue may not have approved, but he still admired the resolution and even pointed out some flaws in techniques. Arth always respected his friend, so listened carefully to the very end. This, along with Arth's natural character, gave some peek into his future. They say to this day, without Vogue's help he never would have mastered his twin blade style."
            }
    },
    { //Voghan - Complete
        id: 101956112,
        name: "Voghan",
        rank: "F",
        attribute: "Fire",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101955112.png",
                detailawk: "/db/Units/Detail/unit_detail_101956112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101955112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101956112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6650,
                hpplus: 999,
                atk: 2236,
                atkplus: 345,
                def: 2380,
                defplus: 367
            },
        skillset: 
            {
                skill: "8s CT - 1600% Fire DMG (PHY). Increase own Arts by 7.",
                skillbreak: 1700,
                arts: "14500% Fire DMG (PHY). For 16s, increase own Skill CT REC by 120%.",
                artsbreak: 3000,
                truearts: "23000% Fire DMG (PHY). When activated against an Enemy in BREAK, reduce the Break Bar by 100% + For 20s, increase own DMG to Enemies in Break by 100%.",
                trueartsbreak: 1000
            },
        passive: 
            {
                ability1: "Increase DMG to Human Enemies by 70%.",
                ability2: "Ignore 35% of Enemies' DEF.",
                ability3: "Increase own Critical Rate by 30% and Critical DMG by 20% against Enemies in Break."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 3,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 2,
                    hover4: "Code-XTF",
                    mat5: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat5amt: 5,
                    hover5: "Flogassa"
                }
            },
        lore: 
            {   
                evo5: "An assassin whose name was renowned during the first period of the Ancient War. His ambition for power was so fierce he didn’t hesitate to alter his own body. Thus, his fighting potential grew significantly, and he became a professional killer to satisfy his willingness to kill. He was totally indifferent towards money, and his only interest was to torture and kill his prey.",
                evoawk: "He kept slaughtering the monsters that the two tamers summoned, one after another, laughing vulgarly. Screaming in the ecstasy of the massacre, he built a mountain of corpses killing more monsters than the King’s Army. For his unquenchable thirst for blood, the two who kept summoning new preys were the perfect enemy. In front of the Beast Tamers who had finally run out of power, he held his sword licking his chops."
            }
    },
    { //Diaz - Complete
        id: 101966212,
        name: "Diaz",
        rank: "C",
        attribute: "Water",
        type: "Spirit",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101965212.png",
                detailawk: "/db/Units/Detail/unit_detail_101966212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101965212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101966212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6880,
                hpplus: 999,
                atk: 2130,
                atkplus: 329,
                def: 2550,
                defplus: 392
            },
        skillset: 
            {
                skill: "8s CT - For 8s, heal all Allies' HP by 20/s.",
                skillbreak: 0,
                arts: "13500% Water DMG (MAG). Increase Water Allies' Arts by 30.",
                artsbreak: 2500,
                truearts: "19500% Water DMG (MAG). When this attack hits an Enemy, heal Allies' HP by 2000 + For 8s, increase Water Allies' Arts DMG by 30%.",
                trueartsbreak: 3800
            },
        passive: 
            {
                ability1: "Increase Water Allies' stats by 10% and Mikyuon Allies' stats by 300%.",
                ability2: "Increase DMG to Fire Enemies by 20%.",
                ability3: "Increase Allies' Critical DMG by 10%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                    mat3amt: 2,
                    hover3: "Geo-Zagnus",
                    mat4: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat4amt: 5,
                    hover4: "Freydal",
                    mat5: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat5amt: 3,
                    hover5: "Tonetaurus"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Proscris",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_104801.png",
                skill: "35s CT - 5000% Water DMG (MAG). Heal own HP by 260 and increase own Arts by 20 each time this attack hits an Enemy (only once per enemy, per usage).",
                skillbreak: 1500,
                passive: 
                    {
                        ability1: "When equipped by Diaz, increase DMG by 20% and heal HP by 100/s. Does not stack with Abilities of the same name.",
                        ability2: "Increase Allies' max HP by 5% and Beast Allies' DMG by 15%. Does not stack with Abilities of the same name."
                    }
            },
        lore: 
            {   
                evo5: "In the early days of the Ancient War, he was the star magician and beast tamer of the travelling circus 'Crimson Stars'. They say that the group had great success everywhere it performed, thanks to the ringmaster's comic magical arts and Diaz' magical ability to summon cute Mikyuons. His gentle manners and the intellectual atmosphere were part of his charm. He was also the mentor of the orphan who joined the group as an archer. The secrets of his tricks are still undisclosed.",
                evoawk: "After his village was vandalized when he was a child, he swore vengeance together with his friend, the son of the village Spirit's leader. The fire of revenge which possessed him was relieved by the light of the Archer. Even if she recognized the reason for their hatred, she told him not to live in the past but think about the future instead. Her words set him free. When the Royal Army and the assassins came for them the Great Circus began: he played his ace in the hold and suddenly countless monsters appeared on his side..."
            }
    },
    { //Mamori - Complete
        id: 101976312,
        name: "Mamori",
        rank: "F",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101975312.png",
                detailawk: "/db/Units/Detail/unit_detail_101976312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101975312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101976312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6050,
                hpplus: 917,
                atk: 2021,
                atkplus: 313,
                def: 1980,
                defplus: 307
            },
        skillset: 
            {
                skill: "8s CT - 2000% Earth DMG (PHY). This Skill ignores 30% of Enemy DEF.",
                skillbreak: 1600,
                arts: "12800% Earth DMG (PHY). For 11s, reduce Enemies' Earth RES by 30%.",
                artsbreak: 2200,
                truearts: "25600% Earth DMG (PHY). Increase own evasion by 20% & ignore Enemies' DEF during own True Arts + For 15s, increase Allies' DMG and evasion by 30%.",
                trueartsbreak: 4200
            },
        passive: 
            {
                ability1: "Heal own HP by 10/s.",
                ability2: "Increase accuracy by 30%.",
                ability3: "Increase DMG to Water Enemies by 30%. Increase Fire RES by 35%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat3amt: 4,
                    hover3: "Goldola",
                    mat4: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat4amt: 2,
                    hover4: "Tonetaurus",
                    mat5: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat5amt: 2,
                    hover5: "Radachne"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Forester",
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_104741.png",
                skill: "40s CT - 2222% Earth DMG (PHY). If user is Earth, increase own Arts by 20 + For 10s, increase Allies' evasion and accuracy by 30%.",
                skillbreak: 500,
                passive: 
                    {
                        ability1: "When equipped by Mamori, increase DMG and Equipment CT REC by 20%, and increase Arts by 2/s. Does not stack with Abilities of the same name.",
                        ability2: "Increase Equipment DMG by 15% and heal HP by 10/s. Does not stack with Abilities of the same name."
                    }
            },
        lore: 
            {   
                evo5: "In the early days of the Ancient War, she was the Archer of the famous \"Crimson Stars\" circus. She was a was orphan who decided to join the group and traveled with them all her life. Inspired from her childhood from the story of the hero Ashe Toto, she decided to learn archery. Her skill is more than enough to please the audience as a performer,and also in the actual fight, she is one in a million. But her best talent is to let fall one arrow like a shooting star, which is also the origin of her name.",
                evoawk: "The two friends she traveled with founded \"Crimson Stars\" to disguise themselves and revenge on those who destroyed their village during their childhood. Even after knowing their true intentions, she still considered them her family. Not only because they raised her, but also for the time spent together making people laugh all over the world. All that could not be a lie. The two, who had been haunted by hate from their past, turned forward and continued their trip to make the world smile."
            }
    },
    { //Melody - Complete
        id: 101986412,
        name: "Melody",
        luckrank: "F",
        attribute: "Light",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101985412.png",
                detailawk: "/db/Units/Detail/unit_detail_101986412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101985412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101986412.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6100,
                hpplus: 925,
                atk: 2050,
                atkplus: 317,
                def: 2360,
                defplus: 364
            },
        skillset: 
            {
                skill: "9s CT - 1600% Light DMG (PHY). For 5s, increase Allies' Healing Taken by 20%.",
                skillbreak: 500,
                arts: "13700% Light DMG (PHY). Increase own Critical Rate by 100% and evasion by 25% during this Arts.",
                artsbreak: 800,
                truearts: "24300% Light DMG (PHY). For 15s, increase Allies' Skill CT REC and DEF by 50%, Light Allies' DMG by 30%.",
                trueartsbreak: 1200
            },
        passive: 
            {
                ability1: "Increase Dark RES by 15%.",
                ability2: "Increase DMG to Dark Enemies by 10%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragMelody.png",
                    mat3amt: 20,
                    hover3: "Melody",
                }
            },
        lore: 
            {   
                evo5: "A saloon dancer who works to restore the Gods' rule in a nation conquered by Demons. As daughter of a high officer, she was once a noblewoman until her family lost their status after the war. Her father was executed on overblown charges of treason, motivating her to become an actual rebel. It is said that she chose her new occupation to better organize an underground resistance group, which also provides an outlet for her exceptional swordsmanship.",
                evoawk: "The only family who shared her late father's memory had became mad with power. Looking at the deranged beast, she recalled the past and realized the least she could do for her former guardian was grant him a swift death. The dancer drew her sword in resignation, but the mage who accompanied her managed to restore his mind first. Although she never spoke of what happened to him afterwards, she took his spirit to heart and continued in her efforts to liberate the country."
            }
    },
    { //Rohst - Complete
        id: 101996511,
        name: "Rohst",
        attribute: "Dark",
        luckrank: "F",
        type: "Dragon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_101995511.png",
                detailawk: "/db/Units/Detail/unit_detail_101996511.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_101995511.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_101996511.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6250,
                hpplus: 947,
                atk: 2032,
                atkplus: 314,
                def: 2250,
                defplus: 347
            },
        skillset: 
            {
                skill: "8s CT - 1900% Dark DMG (PHY).",
                skillbreak: 700,
                arts: "8500% Dark DMG (PHY). For 10s, increase Allies' DMG by 20%, Dragon Allies' DMG by 30%.",
                artsbreak: 1800,
                truearts: "15500% Dark DMG (PHY). For 9s, Allies heal their HP by 4% of their DMG dealt.",
                trueartsbreak: 3000
            },
        passive: 
            {
                ability1: "Increase DMG to Demon Enemies by 40%.",
                ability2: "Increase Dragon Allies' stats by 15%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRohst.png",
                    mat3amt: 20,
                    hover3: "Rohst",
                }
            },
        lore: 
            {   
                evo5: "A Dragon general during the early period of the Ancient War. Even as the Dragon grew ascendant, he was skeptical of the idea of singular rule over the world and wanted balance among all the people of Raktehelm. So, judging the Maiden's ability to see the future with her Second Sight would destroy the balance, he raised an army of Human subjects to assassinate her. Hus incredible military might and the single-mindedness with which he pursued his vision of the future earned him great respect and faith, and thus the army he raised was overwhelming.",
                evoawk: "He was opposed by a young Demoness general. Her interests were in direct opposition, as her sole goal was to protect the Maiden, and so they fell to a fierce battle. The two generals, sensing each others' resolve, joined a duel to test the others' will to fight. Yet the battle ended when the Maiden suddenly took her own life. He rushed back to his home, intent to avoid the terrible future foretold in her final words."
            }
    },
    { //Gerald - Complete
        id: 102006112,
        name: "Gerald",
        rank: "A",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102005112.png",
                detailawk: "/db/Units/Detail/unit_detail_102006112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102005112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102006112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7500,
                hpplus: 999,
                atk: 2120,
                atkplus: 328,
                def: 3020,
                defplus: 463
            },
        skillset: 
            {
                skill: "8s CT - 2000% Fire DMG (PHY). For 5s, reduce Enemies' ATK by 20%.",
                skillbreak: 1200,
                arts: "15400% Fire DMG (MAG). Increase Allies' Arts by 10 + For 20s, redirect Allies' DMG taken to self and gain a DMG barrier that negates 8000 DMG to self.",
                artsbreak: 2400,
                truearts: "21600% Fire DMG (MAG). Cure own Status Ailments, increase Allies' Arts by 15 + For 20s, redirect Allies' DMG taken to self and gain a DMG barrier that negates 15000 DMG to self.",
                trueartsbreak: 4800
            },
        passive: 
            {
                ability1: "Increase Allies' DMG RES by 10% when near-death.",
                ability2: "When this unit takes lethal DMG, prevent HP from dropping below 1 HP for 5s, gradually heal for 50% HP and gradually increase Arts for a total of 200. Ability can only be activated once per Quest.",
                ability3: "Increase Burn RES by 50%.",
                ability4: "Increase Raid Boss DMG RES by 20%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 2,
                    hover3: "Flogassa",
                }
            },
        lore: 
            {   
                evo5: "A young officer who led a legion under the command of the Dragons, early in the Ancient War. He held his commanding officer in such respect, that he felt deep pride in serving the Dragons hand and foot. He joined his commander in a mission to kill a Maiden Oracle, but he began to doubt the need to slay an innocent girl. He questioned his orders for the first time. His officer explained that sometimes it is necessary to sacrifice one to save one hundred, and so affirmed a Demon, who was there hunting the girl to exploit her power.",
                evoawk: "The Maiden he was ordered to kill decided to die herself, together with her dangerous power of foreseeing gurure. Gerald, who always followed orders to the letter, now found himself doubting his superior for the first time. His inability to deal with these doubts caused him to question his own worth. However, as his commanding officer said, the Maiden was a sacrifice necessary to save countless lives. So he swore that he would help avoid the war foreseen by the Maiden. And that was the instant he took his first step towards independence."
            }
    },
    { //Zeela - Complete
        id: 102016212,
        name: "Zeela",
        rank: "C",
        attribute: "Water",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102015212.png",
                detailawk: "/db/Units/Detail/unit_detail_102016212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102015212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102016212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 5840,
                hpplus: 886,
                atk: 2492,
                atkplus: 383,
                def: 2135,
                defplus: 330
            },
        skillset: 
            {
                skill: "8s CT - Heal all Allies' HP by 300. For 3s, increase Allies' Arts by 2/s.",
                skillbreak: 0,
                arts: "Increase own Arts by 50 + For 15s, increase Allies' (except self) accuracy and Critical Rate by 70%, and MAG DMG by 50%.",
                artsbreak: 0,
                truearts: "28500% Water DMG (MAG). Cure own status ailments (except Faint), increase this True Arts' accuracy by 100%, this True Arts ignores 100% of Enemy DEF.",
                trueartsbreak: 3800
            },
        passive: 
            {
                ability1: "When near-death, increase DEF by 50%.",
                ability2: "Increase Freeze RES by 100%.",
                ability3: "Increase own Arts by 2/s. When damaged while near-death, for 10s, gain a DMG barrier that negates 2200 DMG to self. Ability can only be activated once every 30s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                    mat3amt: 2,
                    hover3: "Geo-Zagnus",
                    mat4: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat4amt: 2,
                    hover4: "Freydal",
                    mat5: "/db/Icons/dungeon_drops/fragAerugraxes.png",
                    mat5amt: 1,
                    hover5: "Aerugraxes"
                }
            },
        lore: 
            {   
                evo5: "The descendant of a sorcerer who sealed an Ogre who had destroyed the capital of a Gods' kingdom early in the Ancient War. Although she was born with divine power inherited from her family, her constitution was so weak she almost never left her house. However, when the Ogre returned, she was recruited in a legion to seal it. Her childhood friend opposed and though her family was prohibited to set foot where a Demon is sealed, she inevitably joined the force.",
                evoawk: "When she saw the sorcerer pierced by a bullet, the Ogre started laughing. The being called \"Ogre\" was only the flesh, while the soul had been sealed by the sorcerer using his own blood. The Fox God appeared from Zeela's boty was finally trying to reunite with the flesh-part, namely the Ogre. However, because of the Agent Machina's plan, the Fox was sealed once more inside her body."
            }
    },
    { //Fosly - Complete
        id: 102026312,
        name: "Fosly",
        rank: "B",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102025312.png",
                detailawk: "/db/Units/Detail/unit_detail_102026312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102025312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102026312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6527,
                hpplus: 989,
                atk: 2534,
                atkplus: 390,
                def: 2289,
                defplus: 353
            },
        skillset: 
            {
                skill: "8s CT - 1700% Earth DMG (PHY). Each time own Skill is activated, permanently increase own ATK by 20% (up to 100%).",
                skillbreak: 2000,
                arts: "15000% Earth DMG (PHY). Permanently increase own Break PWR by 40% (up to 120%).",
                artsbreak: 3200,
                truearts: "25000% Earth DMG (PHY). 100% chance to inflict Faint for 5s. For 20s, reduce Enemies' Break RES by 40%.",
                trueartsbreak: 6000
            },
        passive: 
            {
                ability1: "At the start of a Quest, reduce own max HP by 15%. For 30s, gradually recover up to 65% max HP.",
                ability2: "When near-death, heal own HP for 60/s.",
                ability3: "When near-death, increase own Arts by 2/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 2,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat4amt: 2,
                    hover4: "Tonetaurus",
                }
            },
        lore: 
            {   
                evo5: "The head of a group of dark river and mountain Spirits that menaced a certain Divine capital early in the Ancient War. She was eventually sealed away by a sorcerer but has reappeared after her long slumber. Wielding a massive hammer at odds with her childish looks, she is mighty enough to crush even a large army on her own. No one knows her true motive, but she has once again started attacking the capital. The kingdom, sensing the danger, sent out its mightiest army to subdue her, but she laid it to waste.",
                evoawk: "The ogre long sought out the descendants of the sorcerer who sealed her away. When they were finally discovered, they fell before the assassins hired for the job... and from their bodies a sinister ghostly fox appeared. That was her lost half, the ogre's soul torn from her body by the sorcerer and hidden within his own flesh and blood. The sould was passed down with the sorcerer's blood, and is only a matter of time until it meets its lost flesh, sealed away in the forbidden land. And thus the blue-faced golden haired Fox Goddess seeks ever for resurrection."
            }
    },
    { //Jack - Complete
        id: 102036412,
        name: "Jack",
        rank: "F",
        attribute: "Light",
        type: "Machine",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102035412.png",
                detailawk: "/db/Units/Detail/unit_detail_102036412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102035412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102036412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7080,
                hpplus: 999,
                atk: 2325,
                atkplus: 358,
                def: 2408,
                defplus: 371
            },
        skillset: 
            {
                skill: "7s CT - 1900% Light DMG (PHY). Increase own Arts by 8.",
                skillbreak: 1800,
                arts: "14200% Light DMG (PHY). 60% chance to transform Enemies into a Chest for 5s. Increase Allies' BE generation by 50% against Enemies transformed by this Arts.",
                artsbreak: 2500,
                truearts: "28000% Light DMG (PHY). 100% chance to transform Enemies into a Chest for 7s. Increase Allies' Arts by 20. Increase Allies' BE generation by 80% against Enemies transformed by this True Arts.",
                trueartsbreak: 4000
            },
        passive: 
            {
                ability1: "When near-death, increase Skill CT REC by 50%.",
                ability2: "Increase evasion by 10%.",
                ability3: "Increase Allies' Arts DMG by 10%. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 3,
                    hover3: "Fosradius",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 4,
                    hover4: "Code-XTF",
                    mat5: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                    mat5amt: 1,
                    hover5: "Geo-Zagnus"
                }
            },
        lore: 
            {   
                evo5: "A Machine who followed the legendary master of Radiant Ittoryu early in the Ancient War. He was originally an intelligence operative in the Royal Army, but his disillusionment with corrupt officials led him to becoming a gentleman thief. After he mistakenly broke into the Royal Fencing Instructor's estates, he met the master's daughter and found her skills and character so charming he decided to serve her. He was feared by the officials because he was considered as a secret agent in her service and he is said to have contributed deeply to the security of the kingdom.",
                evoawk: "His mistress ordered him to investigate the reason why an Ogre which had once laid waste to the capital had been released from her seal. However, he ended up being wounded by a Giant assassin, who had been released by the Ogre. He still made it back to his mistress, only to find her facing her childhood friend, who was possessed by the Ogre's spirit. She could not slay her friend. He dragged his shattered body over to the Ogre to save his desperate mistress. Only he knew the Ogre's weak point, so he gave his life to strike it."
            }
    },
    { //Celia (Light) - Complete
        id: 102046412,
        name: "Celia (Light)",
        rank: "B+",
        twrequire: "favorable",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102045412.png",
                detailawk: "/db/Units/Detail/unit_detail_102046412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102045412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102046412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 7104,
                hpplus: 999,
                atk: 2424,
                atkplus: 373,
                def: 2808,
                defplus: 431
            },
        skillset: 
            {
                skill: "8s CT - 1800% Light DMG (PHY). For 5s, reduce Enemies' ATK by 20%.",
                skillbreak: 1400,
                arts: "13800% Light DMG (PHY). Increase Allies' Arts by 10 + For 20s, redirect Allies' DMG taken to self and gain a DMG barrier that negates 8000 DMG to self.",
                artsbreak: 2900,
                truearts: "22800% Light DMG (PHY). Increase Allies' Arts by 15 + Cure own Status Ailments + For 20s, redirect Allies' DMG taken to self and gain a DMG barrier that negates 15000 DMG to self.",
                trueartsbreak: 4500
            },
        passive: 
            {
                ability1: "Less likely to be knocked back. Heal 30% of DMG taken from Enemy Arts.",
                ability2: "Increase Allies' DMG RES by 10% when near-death.",
                ability3: "Increase own Healing Taken by 30% (except Lifesteal) and ignore 50% of Enemies' DEF."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 4,
                    hover3: "Fosradius",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 3,
                    hover4: "Code-XTF",
                    mat5: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat5amt: 5,
                    hover5: "Palamicia"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Joyeuse",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_104401.png",
                skill: "70s CT - 8000% Light DMG (PHY). For 8s, ignore 50% of Enemy DEF, and increase DMG RES by 30%.",
                skillbreak: 1200,
                passive: 
                    {
                        ability1: "When equipped by Celia (Light), increase DMG by 20% and increase Freeze and Paralysis RES by 100%. Does not stack with Abilities of the same name.",
                        ability2: "Increase max HP by 1000. When near-death, increase DMG RES by 10%."
                    }
            },
        lore: 
            {   
                evo5: "The first princess of Bamint. Well versed in letters and arms and possessing both wit and beauty, Celia is a paragon of virtue held in high regard by all. She's also the sister of Demon Suppression Corps member Mira, and worries about her always. Though being close growing up, her overprotectiveness caused Mira to pull away of her own volition. It remains something that Celia feels a twinge of sadness about.",
                evoawk: "The only noblewoman to stand up to the corruption plaguing the Principality of Bamint. Her unique sense of justice led her to defy others within sense of justice led her to defy others within the government, and she faced head-on their attacks without relying on her noble blood to save her, thus averting true danger. For she had always been one to battle alone for what she felt was right. Yet the thought that the adventurers shared her fight against the Demons, despite their being physically separated, bolstered her spirit and offered her renewed strength."
            }
    },
    { //Ashe - Complete
        id: 102056512,
        name: "Ashe",
        rank: "B-",
        twrequire: "favorable",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102055512.png",
                detailawk: "/db/Units/Detail/unit_detail_102056512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102055512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102056512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6950,
                hpplus: 999,
                atk: 2880,
                atkplus: 442,
                def: 2220,
                defplus: 340
            },
        skillset: 
            {
                skill: "5s CT - 2000% Dark DMG (PHY). 60% chance to inflict Blind for 9s. Increase own Arts by 3.",
                skillbreak: 800,
                arts: "19000% Dark DMG (PHY). For 13s, reduce Enemies' Blind RES by 35%.",
                artsbreak: 2300,
                truearts: "30000% Dark DMG (PHY). Reduce Enemies' Arts by 40 + For 15s, reduce Enemies' DMG RES by 50% and Movement Speed by 30%.",
                trueartsbreak: 4300
            },
        passive: 
            {
                ability1: "Increase Critical Rate by 100% against Blinded Enemies.",
                ability2: "Increase Critical DMG by 30%.",
                ability3: "Increase DMG to non-Boss Enemies under 30% HP by 300%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 1,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragValzandeth.png",
                    mat4amt: 2,
                    hover4: "Valzandeth",
                    mat5: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat5amt: 2,
                    hover5: "Oldeus"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Dos Muertes",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_105651.png",
                skill: "30s CT - 6800% Dark DMG (MAG). Heal user's HP by 7% of DMG dealt by this attack.",
                skillbreak: 1200,
                passive: 
                    {
                        ability1: "When equipped by Ashe, increase Critical DMG by 50% and increase Arts by 1/s. Does not stack with Abilities of the same name.",
                        ability2: "Increase DMG to Human Enemies by 30%."
                    }
            },
        lore: 
            {   
                evo5: "An assassin who works for the Cult, the secret organization that controls the Magus Empire of La Riiz. When only a child, he was taken into the Magic Lab of La Riiz where he was the object of magic experiments which made his body strong over human limits. Most of the experiments executed in the Lab were a failure, except for him and one of his comrades, who were considered as a success, and exploited by the Cult. With his unbreakable body and the murder technique he mastered thanks to his magic weapons, he perfectly accomplished all the missions the Cult assigned.",
                evoawk: "An assassin who dedicated his life to the Cult that controls La Riiz. When he was expelled, he didn't only lose his mission, but his whole way of life. Wandering, he found himself in the research facility where he had grown up, and where his humanity was deviated to make him an unbreakable assassin. There he remembered the decision he had made many years before, that showed him a new way."
            }
    },
    { //Norn - Complete
        id: 102066312,
        name: "Norn",
        rank: "A-",
        attribute: "Earth",
        type: "Machine",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102065312.png",
                detailawk: "/db/Units/Detail/unit_detail_102066312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102065312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102066312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6990,
                hpplus: 848,
                atk: 2383,
                atkplus: 295,
                def: 2568,
                defplus: 320
            },
        skillset: 
            {
                skill: "10s CT - 1800% Earth DMG (MAG). For 10s, reduce Enemies' Status RES by 20%.",
                skillbreak: 2000,
                arts: "14000% Earth DMG (MAG). 80% chance to inflict Paralysis for 6s. For 6s, increase own DMG to Paralyzed Enemies by 100%.",
                artsbreak: 2800,
                truearts: "32600% Earth DMG (MAG). Increase Allies' Arts by 20 + For 20s, increase Allies' Arts by 3/s. For 30s, increase own stats by 50%.",
                trueartsbreak: 5000
            },
        passive: 
            {
                ability1: "Increase Machine Allies' stats by 15%.",
                ability2: "Increase Critical RES by 20%.",
                ability3: "Increase DMG to enemies with 30% HP or less by 20%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat3amt: 3,
                    hover3: "Code-XTF",
                    mat4: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat4amt: 2,
                    hover4: "Goldola"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Griffin",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_105541.png",
                skill: "45s CT - 7800% Earth DMG (MAG). For 12s, reduce Enemies' Paralysis RES by 60%.",
                skillbreak: 1500,
                passive: 
                    {
                        ability1: "When equipped by Norn, increase DMG by 20%. At the start of a Quest, increase Arts by 100. Does not stack with Abilities of the same name.",
                        ability2: "Increase Break PWR by 10%."
                    }
            },
        lore: 
            {   
                evo5: "This Machine warrior came to Raktehelm from the Sealed Land. Her outstanding mobility and intel gathering capabilities make her an excellent support unit. However, in battle form, her firepower focus makes her a useful attacker as well. Her official name is AHD-03RE, and Norn is her codename. When she first came to Raktehelm, she felt nothing but deep-seated hatred for humans, but apparently among Machines she is one of the more amicable ones.",
                evoawk: "A young Machine warrior who came to Raktehelm to help Humans. When she first came, she hated Humans, and never imagined the Knight of Isliid could ever become her partner. However, his sincere words and gaze moved her, and she finally realized the true purpose of her mission. She appears to be rather cold with Humans, but she herself seems unaware of this. Her frank way of speaking is a personal issue and has nothing to do with being a Machine."
            }
    },
    { //Riana - Complete
        id: 102166212,
        name: "Riana",
        rank: "F",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102165212.png",
                detailawk: "/db/Units/Detail/unit_detail_102166212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102165212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102166212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6068,
                hpplus: 920,
                atk: 2418,
                atkplus: 372,
                def: 2093,
                defplus: 323
            },
        skillset: 
            {
                skill: "12s CT - For 3s, enter Counterattack Mode. Counterattack: 2900% Water DMG (PHY). For 5s, increase own ATK by 30%.",
                skillbreak: 800,
                arts: "16000% Water DMG (PHY). If Arts is activated during the ATK buff from Skill, increase this Arts' Critical Rate by 100%.",
                artsbreak: 1800,
                truearts: "26000% Water DMG (PHY). Increase own True Arts' Critical Rate by 100% + For 20s, increase own Skill CT REC by 100%.",
                trueartsbreak: 3200
            },
        passive: 
            {
                ability1: "Increase own PHY RES by 10%.",
                ability2: "Increase Equipment DMG and Break PWR by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat3amt: 1,
                    hover3: "Freydal",
                    mat4: "/db/Icons/dungeon_drops/fragAerugraxes.png",
                    mat4amt: 2,
                    hover4: "Aerugraxes"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Fatalite",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_104391.png",
                skill: "25s CT - 4200% Water DMG (PHY). If user is Human, increase DMG and Break PWR of next Skill by 25%.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Riana, increase DMG and Skill CT REC by 20%. Does not stack with Abilities of the same name.",
                        ability2: "Increase Equipment Critical Rate by 20%. Does not stack with Abilities of the same name."
                    }
            },
        lore: 
            {   
                evo5: "Even today, her name is extolled as the last successor of the Sword Master dynasty. She is married to Ganan, master of Reigrad Adventurer's Guild and mother of the Arena receptionist, Est. Yet she still has the appearance of a young lady, and lives apart from her family. She spends her time travelling the world, and all those stories about a mysterious noble woman testing the heroes of every country she visits are all about her.",
                evoawk: "She is a peerless swordswoman, worthy of her ancestors, the Sword Master dynasty. In order to continue her struggle to become the greatest fighter of her family, she had an anti-aging spell cast on her when she was still a child. Ganan found within her a strength different to the one of her sword and they married, but soon she returned to her world travels. She made an oath that they could never live together until she achieved her goal, and that resolution only grew stronger when she met a certain adventurer."
            }
    },
    { //Kane (Earth) - Complete
        id: 102176312,
        name: "Kane (Earth)",
        rank: "F",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102175312.png",
                detailawk: "/db/Units/Detail/unit_detail_102176312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102175312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102176312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6250,
                hpplus: 947,
                atk: 2420,
                atkplus: 373,
                def: 2150,
                defplus: 332
            },
        skillset: 
            {
                skill: "8s CT - 1850% Earth DMG (PHY). For 5s, increase own Arts by 1/s.",
                skillbreak: 900,
                arts: "16200% Earth DMG (MAG). 80% chance to inflict Paralysis for 3s.",
                artsbreak: 2400,
                truearts: "22600% Earth DMG (PHY). 50% chance to increase own Arts by 200 (can only occur up to 3 consecutive times).",
                trueartsbreak: 3200
            },
        passive: 
            {
                ability1: "Increase Critical DMG by 20%. Does not stack with Abilities of the same name.",
                ability2: "Increase DMG to Water Enemies by 35%.",
                ability3: "Increase Human Allies' stats by 15%. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat3amt: 3,
                    hover3: "Goldola",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 5,
                    hover4: "Code-XTF",
                    mat5: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat5amt: 2,
                    hover5: "Elpneumas"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Glorious",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_104631.png",
                skill: "40s CT - 7200% Earth DMG (MAG). 80% chance to inflict Paralysis.",
                skillbreak: 1200,
                passive: 
                    {
                        ability1: "When equipped by Kane (Earth), increase DMG by 20% and increase Freeze and Paralysis RES by 100%. Does not stack with Abilities of the same name.",
                        ability2: "If the user is Human, increase Arts DMG by 10% and increase Arts DMG RES by 15%. Does not stack with Abilities of the same name."
                    }
            },
        lore: 
            {   
                evo5: "A high official in the Gramenia Republic. Seeing how he orders Fen around makes it look like he is higher ranking, but they also talk to each other like very old friends. He's a rough lady's man with a big personality, but when he's at his work, they say he's so efficient it's like a whole other person. There are very few who actually know what his job is, and despite his outgoing, friendly personality he is still full of hidden facets.",
                evoawk: "A lonely person from the Republic of Gramenia, dressed in a tentative figure that has endured the world.He climbed to that position at a young age, so many political opponents aren't happy with him, but those opponents are cleverly held down by the most trusted Chief of Staff, Fen. He is the one who once found and promoted Fen."
            }
    },
    { //Courtney - Complete
        id: 102186512,
        name: "Courtney",
        rank: "C",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102185512.png",
                detailawk: "/db/Units/Detail/unit_detail_102186512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102185512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102186512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7200,
                hpplus: 999,
                atk: 2500,
                atkplus: 385,
                def: 2380,
                defplus: 367
            },
        skillset: 
            {
                skill: "8s CT - 1700% Dark DMG (MAG). For 6s, reduce Enemies' DMG RES by 10%.",
                skillbreak: 700,
                arts: "15500% Dark DMG (MAG). For 10s, reduce Enemies' Status RES by 50%.",
                artsbreak: 2200,
                truearts: "22000% Dark DMG (MAG). For 15s, reduce Enemies' ATK, DEF and Break RES by 50%.",
                trueartsbreak: 3500
            },
        passive: 
            {
                ability1: "Increase Gren gained on Quests by 30%. Does not stack with similar Abilities.",
                ability2: "Increase Light RES by 25%.",
                ability3: "When near-death, increase Skill CT REC by 20%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragValzandeth.png",
                    mat3amt: 1,
                    hover3: "Valzandeth",
                    mat4: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                    mat4amt: 1,
                    hover4: "Degilmagna",
                }
            },
        lore: 
            {   
                evo5: "A lady thief during the early Ancient War. Using wicked tricks, she stole only from the rich who stuffed their own pockets and gave her awards to the poor. Her wish was only for everyone to be equal and never stole for herself. One day, she got information about a family who has powers to see the future. To grant her wish, she sneaks into the village. That's right... to steal the shrine maiden.",
                evoawk: "While tens and hundreds were there wanting the shrine maiden. she was one of the closest to her. Now a Human man stands in her way. He was a warrior ordered to kill the shrine maiden but had doubts. Courtney said, \"I will use her in order to save the unfortunate.\" However, the shrine maiden killed herself. Bursting into tears, what she only needed was the maiden's powers, and her. She was one of the unfortunate ones."
            }
    },
    { //Marzex (Dark) - Complete
        id: 102196512,
        name: "Marzex (Dark)",
        rank: "B",
        attribute: "Dark",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102195512.png",
                detailawk: "/db/Units/Detail/unit_detail_102196512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102195512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102196512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 5,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7357,
                hpplus: 999,
                atk: 2957,
                atkplus: 454,
                def: 2212,
                defplus: 341
            },
        skillset: 
            {
                skill: "9s CT - 2000% Water, Fire, and Earth DMG (MAG). Cure own Poison, Disease, Burn and Curse. Increase own Arts by 15.",
                skillbreak: 1800,
                arts: "18000% Dark DMG (PHY). For 10s, reduce Enemies’ Critical RES by 100%.",
                artsbreak: 2000,
                truearts: "38000% Elementless DMG (Typeless). Increase this True Arts' DMG depending on how high Enemies' HP are (up to 150%). Increase this True Arts' Critical Rate by 100%. Consume 15% of your current HP + For 30s, enemies take continuous, unstoppable DMG.",
                trueartsbreak: 2800
            },
        passive: 
            {
                ability1: "Increase own max HP by 100%. At the start of a Quest, for 120s, reduce own max HP by 1%/s (down to a total of 20% max HP reduction).",
                ability2: "Increase DMG to non-God Enemies by 50%.",
                ability3: "Increase PHY evasion by 25%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 2,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat4amt: 2,
                    hover4: "Mavroskia",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Vengeance",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_107291.png",
                skill: "60s CT - 8000% Dark DMG (PHY). For 8s, increase God Allies' DMG by 40%.",
                skillbreak: "?",
                passive: 
                    {
                        ability1: "When equipped by Dark Marzex, increase Arts by 100 at the start of the Quest.",
                        ability2: "Increase Dark RES by 10%."
                    }
            },
        lore: 
            {   
                evo5: "The hateful side of Marzex, born from the grudge consuming him after being sealed at the hands of Humans. The obscurity eventually consumed the mercy of the God and made him a being of pure darkness. The merciful Marzex, as a proud God, entrusted his power to the adventurer who once defeated him, in order to save the world from the abyss. Even the power of the Dark to be used if necessary.",
                evoawk: "The side of Marzex which embodies hate. The endlessly increasing powers of the Dark could eventually be used by the adventurer, who had been blessed by the merciful Marzex. How did the god feel,to help the adventurer who once defeated him? Hate still grows silently inside him, who stands beside the adventurer..."
            }
    },
    { //Mako - Complete
        id: 102206412,
        name: "Mako",
        luckrank: "B",
        rank: "S",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102205412.png",
                detailawk: "/db/Units/Detail/unit_detail_102206412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102205412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102206412.png"
            },
        slots:  
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6680,
                hpplus: 999,
                atk: 2520,
                atkplus: 394,
                def: 2180,
                defplus: 343
            },
        skillset: 
            {
                skill: "8s CT - 1800% Light DMG (PHY). Increase own Arts by 10.",
                skillbreak: 1000,
                arts: "18000% Light DMG (PHY). For 15s, increase own Skill CT REC by 150%, reduce Enemies' DMG RES and Movement Speed by 30%.",
                artsbreak: 2200,
                truearts: "For 15s, heal Allies' HP by 400/s, increase Allies' Arts by 4/s and Arts DMG by 130%.",
                trueartsbreak: 0
            },
        passive: 
            {
                ability1: "Increase Equipment CT REC by 10%.",
                ability2: "Increase Healing Taken by 20%.",
                ability3: "Increase PHY RES by 30% and increase Paralysis and Freeze RES by 100%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 1,
                    hover3: "Fosradius",
                }
            },
            dream: {
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                        mat3amt: 2,
                        hover3: "Wilkes",
                    },
                    passive: {
                        ability1: `Increase Human allies' Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own Equipment CT Speed by 10%.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                        mat3amt: 2,
                        hover3: "Ragsherum Phantom",
                    },
                    passive: {
                        ability1: `Increase all allies' Max HP by 20%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase all allies' PHY RES by 10%. Does not stack with Abilities of the same name.`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Fight Club Goku Uniform",
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_151341.png",
                skill: "30s CT - Increase Allies' Arts by 15. For 15s, increase Allies' DMG by 20%.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Mako, the Arts generation and DMG buff of this Equip's Skill is doubled. Does not stack with Abilities of the same name.",
                        ability2: "Increase DEF by 5%."
                    }
            },
        lore: 
            {   
                evo5: "A student in Second Year, Class K of Honnouji Academy, the school where Ryuko transfers. Known as being a bit off, she often leaves people speechless with her unpredictability--once even calling Ryuko her \"best friend\" despite having just met. By a strange coincidence, she becomes the president of the Fight Club and is given the Fight Club-Spec Two-Star Goku Uniform, bestowing her with extremely high battle powers.",
                evoawk: "Mako Mankanshoku, Ryuko Matoi's best friend, has obtained the true powers of the Fight Club Spec Two-Star Goku Uniform. To maintain her social status, she fights evenly with Ryuko. However, once remembering why she fights, she is able to shake the earth with her fists. The ability shown when fighting Ryuko is but a shadow to her newly unleashed powers."
            }
    },
    { //Satsuki - Complete
        id: 102216212,
        name: "Satsuki",
        rank: "B+",
        luckrank: "F",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102215212.png",
                detailawk: "/db/Units/Detail/unit_detail_102216212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102215212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102216212.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 7580,
                hpplus: 999,
                atk: 2680,
                atkplus: 418,
                def: 2320,
                defplus: 364
            },
        skillset: 
            {
                skill: "6s CT - 2200% Water DMG (PHY). Increase own Arts by 30.",
                skillbreak: 2500,
                arts: "22000% Water DMG (PHY). Increase Break PWR of this Arts by 250% during Enemy Arts. For 25s, reduce Enemies' Break RES by 35%.",
                artsbreak: 4000,
                truearts: "70000% Water DMG (PHY). 100% chance to inflict Ultimate Freeze for 10s. For 30s, increase Status RES (except Faint) by 100% for self.",
                trueartsbreak: 8500
            },
        passive: 
            {
                ability1: "Heal HP by 25/s.",
                ability2: "Increase Dark RES by 30%.",
                ability3: "Increase Break PWR by 20% and Critical Rate by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat3amt: 1,
                    hover3: "Freydal",
                }
            },
        dream: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                        mat3amt: 2,
                        hover3: "Freydal",
                    },
                    passive: {
                        ability1: `Increase own Break Power against Human enemies by 150%.`,
                        ability2: `Increase own Arts by 2/s.`
                    }
                },
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragGoldola.png",
                        mat3amt: 2,
                        hover3: "Goldola",
                    },
                    passive: {
                        ability1: `Increase own Break Power against Earth enemies by 150%.`,
                        ability2: `Increase own Skill CT Speed by 20%.`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Bakuzan",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_151331.png",
                skill: "40s CT - 7500% Water DMG (PHY).",
                skillbreak: 5000,
                passive: 
                    {
                        ability1: "When equipped by Satsuki, increase user's Break PWR by 60%. Does not stack with Abilities of the same name.",
                        ability2: "Increase ATK by 5%."
                    }
            },
        lore: 
            {   
                evo5: "The student principal who rules Honnouji Academy, the school where Ryuko transfers, with power and terror. Thanks to her sword 'Bakuzan', she overwhelms people with her strong aura. Just like Ryuko Matoi's 'Senketsu', Satsuki possesses a Kamui called 'Junketsu' which is also made only with Life Fiber. Once its powers are revealed, there will be no enemies for her.",
                evoawk: "The student principal of Honnouji Academy. Through the fight against her true enemies and thanks to her encounter with Ryuko Matoi, she realizes the mistakes made in the past and recognizes the support of those close to her. With this newfound clarity, surely no one can defeat her."
            }
    },
    { //Ryuko - Complete
        id: 102226112,
        name: "Ryuko",
        rank: "A-",
        luckrank: "B",
        twrequire: "favorable",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102225112.png",
                detailawk: "/db/Units/Detail/unit_detail_102226112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102225112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102226112.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7200,
                hpplus: 999,
                atk: 2880,
                atkplus: 448,
                def: 2260,
                defplus: 355
            },
        skillset:
            {
                skill: "8s CT - 2200% Fire DMG (PHY). Increase BE generated by this Skill by 100%.",
                skillbreak: 1200,
                arts: "35000% Fire DMG (PHY). For 30s, increase own ATK by 200% & Skill CT REC by 100%.",
                artsbreak: 2200,
                truearts: "100000% Fire DMG (PHY). For 20s, increase own DMG by 200%, increase own DMG to Enemies in Break by 200%, increase Paralysis and Freeze RES by 100%.",
                trueartsbreak: 4800
            },
        passive: 
            {
                ability1: "When near-death, increase Arts by 3/s.",
                ability2: "When near-death, heal HP by 40/s.",
                ability3: "Increase Accuracy by 100% and Arts DMG by 30%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 1,
                    hover3: "Flogassa",
                }
            },
            dream: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                        mat3amt: 2,
                        hover3: "Flogassa",
                    },
                    passive: {
                        ability1: `Increase own Arts by 6/s.`,
                        ability2: `Increase own DMG by 60% when enemies' HP is below 30%.`
                    }
                },
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                        mat3amt: 2,
                        hover3: "Palamicia",
                    },
                    passive: {
                        ability1: `Increase all allies' Accuracy by 30%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase all allies' Break Power by 30%. Does not stack with Abilities of the same name.`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Scissor Blade",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_151321.png",
                skill: "55s CT - 13000% Fire DMG (PHY). For 20s, increase user's Fire DMG by 30%.",
                skillbreak: 1200,
                passive: 
                    {
                        ability1: "When equipped by Ryuko, increase own DMG by 20% and Arts by 2/s. Does not stack with Abilities of the same name.",
                        ability2: "When near-death, increase own DMG by 10%."
                    }
            },
        lore: 
            {   
                evo5: "A girl who transferred to Honnouji Academy. She has a huge scissor blade and is looking for the other half. She meets her friend Mako Mankanshoku, the sentient sailor uniform Senketsu and the mysterious Satsuki Kiryuin. Eventually, Ryuko throws herself into an intense battle. Nobody can stop her once Senketsu reveals his powers using \"Life Fiber Synchronize\".",
                evoawk: "This is the ultimate form of Kamui reached when Senketsu has combined with all other Goku Uniforms during the last battle. Known as 'Senketsu Kisaragi', its enormous power could be said to be that of all humanity combined. With humanity's hope, she flies into the eternal sky..."
            }
    },
    { //Pientz - Complete
        id: 102236111,
        name: "Pientz",
        attribute: "Fire",
        luckrank: "F",
        type: "Spirit",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102235111.png",
                detailawk: "/db/Units/Detail/unit_detail_102236111.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102235111.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102236111.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6222,
                hpplus: 943,
                atk: 2088,
                atkplus: 323,
                def: 2222,
                defplus: 343
            },
        skillset: 
            {
                skill: "8s CT - 1480% Fire DMG (MAG). For 8s, increase Allies' (except self) Skill CT REC by 20%.",
                skillbreak: 900,
                arts: "8580% Fire DMG (MAG). For 10s, reduce Enemies' ATK and DEF by 30%.",
                artsbreak: 2000,
                truearts: "14550% Fire DMG (MAG). Reduce Boss' Arts by 30 + For 10s, increase Fire Allies' DMG by 30%.",
                trueartsbreak: 3400
            },
        passive: 
            {
                ability1: "Increase Burn RES by 60%.",
                ability2: "Heal Fire Allies' HP by 30/s and increase Fire Allies' Water RES by 10%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragPientz.png",
                    mat3amt: 20,
                    hover3: "Pientz",
                }
            },
        lore: 
            {   
                evo5: "Ringmaster of the Crimson Stars Circus, which was so active early in the Ancient War. He won fame as a masterful beast tamer in jovial clown garb. Normally a gentle, warm-hearted person, his circus performers all trusted him deeply. The archer, the groups only woman, in particular looked on him as a father-figure. As they performed all over the world bringing smiles to countless faces, they say any number of people tried to unlock the secret of his beast taming. And yet, none ever succeeded.",
                evoawk: "The true identity of his partner, another beast tamer, remained hidden. Namely, that he was a member of a family of Spirits, supposedly wiped out by the Kingdom. As the oldest son, he remained cautious, and created the Crimson Stars as a way to disguise himself. However, he revealed all when a once-in-a-lifetime chance presented itself. They pull their lives on the line and yet assassins and royal soldiers lay waiting in ambush... Now that they were trapped, the only lay in these countless, oddly familiar arrows."
            }
    },
    { //Mixie - Complete
        id: 102246412,
        name: "Mixie",
        rank: "C",
        attribute: "Light",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102245412.png",
                detailawk: "/db/Units/Detail/unit_detail_102246412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102245412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102246412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6530,
                hpplus: 989,
                atk: 2380,
                atkplus: 367,
                def: 2155,
                defplus: 333
            },
        skillset: 
            {
                skill: "6s CT - Enter Counterattack Mode for 2s. Counterattack: 1800% Light DMG (MAG). 100% chance to inflict Poison for 10s.",
                skillbreak: 1000,
                arts: "10000% Light DMG (MAG). Enter Counterattack Mode for 4s + For 8s, increase own Arts DMG by 50%. Counterattack: For 12s, reduce Enemies' DEF by 100%.",
                artsbreak: 2500,
                truearts: "26000% Light DMG (MAG). For 12s, reduce Enemies' Poison RES by 100% and Light RES by 30%.",
                trueartsbreak: 3800
            },
        passive: 
            {
                ability1: "When near-death, heal own HP by 6% of DMG dealt.",
                ability2: "Increase DMG to Light Enemies by 40%.",
                ability3: "Increase own Light and Dark RES by 20%. Increase Critical Rate by 100% against Poisoned Enemies."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat3amt: 2,
                    hover3: "Radachne",
                    mat4: "/db/Icons/dungeon_drops/fragValzandeth.png",
                    mat4amt: 1,
                    hover4: "Valzandeth",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Sans Pluie",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_104961.png",
                skill: "40s CT - 7200% Light DMG (MAG). 80% chance to inflict Poison. If user is Demon, increase own Arts by 30.",
                skillbreak: 1200,
                passive: 
                    {
                        ability1: "When equipped by Mixie, increase DMG and Equipment CT REC by 20%. Does not stack with Abilities of the same name.",
                        ability2: "Increase accuracy and Skill CT REC by 20%. Does not stack with Abilities of the same name."
                    }
            },
        lore: 
            {   
                evo5: "This young woman was a highly placed officer supporting the Six Great Demon Generals early in the Ancient War. The courage she showed fighting on the front lines and leading her soldiers into battle meant no one could denounce her for the overweening arrogance she showed in her noble birth. Indeed, many loved her for it, believing such arrogance only seemed fitting for one so high in the Demon ranks. She had indeed once served a truly famous Demon General, but her renown was earned through deed, not on the coat-tails of her betters. She was truly a model Demon who never questioned her superiors and sincerely believed that the Demons were the natural rulers of the world.",
                evoawk: "Her scheme to destroy a gathering to bring peace between the 8 Races was proceeding perfectly. The Gods were trapped, forced to take arms against their partners. The gathering was destroyed, and yet her meticulously laid plan, built to fulfill her duty of spreading the seeds of war, suddenly fell apart. She reappeared before the crowd, revealing the plot, and when she moved to attack the Gods her body was pierced by unstoppable weapons. Standing there was the Machine General,a quiet being who normally only acted for itself..."
            }
    },
    { //Pheles - Complete
        id: 102256212,
        name: "Pheles",
        rank: "C",
        attribute: "Water",
        type: "Spirit",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102255212.png",
                detailawk: "/db/Units/Detail/unit_detail_102256212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102255212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102256212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6830,
                hpplus: 999,
                atk: 2850,
                atkplus: 437,
                def: 2824,
                defplus: 433
            },
        skillset: 
            {
                skill: "10s CT - 1600% Water DMG (MAG). For 5s, reduce Enemies' DMG by 10%.",
                skillbreak: 500,
                arts: "14000% Water DMG (MAG). Gain 1 stack of Devotion (Up to 5). For 10s, reduce Enemies' MAG RES by 30%.",
                artsbreak: 1100,
                truearts: "30000% Water DMG (MAG). For 10s, Allies ignore 100% of Enemies' DEF, and increase Water Allies' DMG by 75%.",
                trueartsbreak: 1800
            },
        passive: 
            {
                ability1: "Increase Poison RES by 100%.",
                ability2: "Increase Curse RES by 50%.",
                ability3: "Devotion: For each stack of Devotion, increase Allies' ATK by 15% and reduce own maximum HP by 15%. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat3amt: 1,
                    hover3: "Palamicia",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 2,
                    hover4: "Radachne",
                }
            },
        lore: 
            {   
                evo5: "A young Spirit who lived in the early years of the Ancient War. She was the daughter of an eastern tribe leader, raised to value harmony. The way she treated everyone with the same kindness earned her many admirers, but those closest to her worried she was naïve. Her ability to commune with the souls of her ancestors let her represent her elderly father at the peace conference hosted by the Gods.",
                evoawk: "Despite her best efforts to meet her kindred's expectations at the peace talks, she fell to a Demon assassin's blade. It was only then she realize that mere idealism would not win over her enemies, and she regretted not doing more to prevent that. However, a swordsman of the Gods who formed a connection to the eastern lands said that she was a major inspiration, for no one else had believed in him amidst the tension and intrigue of the conference."
            }
    },
    { //Vicious - Complete
        id: 102266311,
        name: "Vicious",
        luckrank: "F",
        attribute: "Earth",
        type: "Machine",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102265311.png",
                detailawk: "/db/Units/Detail/unit_detail_102266311.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102265311.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102266311.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 5990,
                hpplus: 908,
                atk: 1890,
                atkplus: 293,
                def: 2135,
                defplus: 330
            },
        skillset: 
            {
                skill: "8s CT - 1700% Earth DMG (PHY).",
                skillbreak: 2000,
                arts: "9000% Earth DMG (PHY). For 12s, increase Earth Allies' DMG by 20%.",
                artsbreak: 2500,
                truearts: "14500% Earth DMG (PHY). For 12s, prevent Arts from increasing beyond 0, and increase Skill CT REC by 400% and Break PWR by 30% + For 12s, change own Skill, cycling between MISSILE MODE, BLADE MODE and LASER MODE with each activation of own Skill. Skill reverts back to normal after 12s.MISSILE MODE - 2040% Earth DMG (PHY). 2000 BREAK. BLADE MODE - 1700% Earth DMG (PHY). 40% chance to inflict Paralysis. 2000 BREAK. LASER MODE - 1700% Earth DMG (PHY). 3000 BREAK.",
                trueartsbreak: 4000
            },
        passive: 
            {
                ability1: "Increase Freeze RES by 40%.",
                ability2: "Increase DMG to God Enemies by 20%, increase PHY RES by 15%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragVicious.png",
                    mat3amt: 20,
                    hover3: "Vicious",
                }
            },
        lore: 
            {   
                evo5: "She led a Machine nation's army early in the Ancient War. With her overwhelming firepower and ability to command, she was considered a key part of national defense by the government. Her soldiers were specialized for battle, and indeed most had little of what you'd call human emotion. In particular, she considered herself a weapon, and never wavered in that conviction. As a result, she never fretted about kiss of life in battle. She simply pursued her goal single-mindedly.",
                evoawk: "There was a Machine soldier who worried at her insistence she was a mere weapon. He fled of his own free will, and joined the service of a Divine swordsman. When the news of his death reached her, for the first time she felt an emotion; hatred. She sought out the swordsman to discover the killer, but learned that he had laid down his life in battle in the search for peace. Her life changed, then, to one of sadness over the death of her friend, and one of hope in the ideals he had held."
            }
    },
    { //Tallis - Complete
        id: 102276112,
        name: "Tallis",
        rank: "F",
        attribute: "Fire",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102275112.png",
                detailawk: "/db/Units/Detail/unit_detail_102276112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102275112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102276112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 5730,
                hpplus: 869,
                atk: 2278,
                atkplus: 351,
                def: 1950,
                defplus: 302
            },
        skillset: 
            {
                skill: "8s CT - 1650% Fire DMG (PHY). For 6s, reduce Enemies' Break RES by 25%.",
                skillbreak: 1800,
                arts: "14000% Fire DMG (PHY). For 10s, increase own Break PWR by 50% when the Boss' Break Bar is at 70% or less.",
                artsbreak: 3500,
                truearts: "26000% Fire DMG (PHY). For 8s, increase own DMG RES by 50%. For 15s, increase Fire Allies' ATK by 100%.",
                trueartsbreak: 4000
            },
        passive: 
            {
                ability1: "Increase Burn RES by 100%.",
                ability2: "All attacks have a chance to inflict Burn (except Equipment).",
                ability3: "When this unit takes lethal DMG, revive self with 50% HP. Can only be activated once every 60s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat3amt: 2,
                    hover3: "Ifrit",
                    mat4: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat4amt: 3,
                    hover4: "Flogassa",
                    mat5: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat5amt: 1,
                    hover5: "Radachne"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Dragon Twilight",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_104881.png",
                skill: "50s CT - 7800% Fire DMG (PHY). For 8s, increase Allies' Break PWR by 30%.",
                skillbreak: 1500,
                passive: 
                    {
                        ability1: "When equipped by Tallis, increase DMG by 20% and increase Paralysis, Freeze and Curse RES by 100%. Does not stack with Abilities of the same name.",
                        ability2: "When near-death, increase DMG and DMG RES by 10%."
                    }
            },
        lore: 
            {   
                evo5: "The master of Radiant Ittoryu, a Divine sword school, early in the Ancient War. When her father never returned from going to defeat a resurrected great beast, she took his place and became a national hero. Later, she became the royal fencing instructor, and earned international renown as the Ogre Slayer. However, she spent much of her time in diplomacy to help create enduring peace between all the 8 great races, as her King wished. She had dedicated her life to the sword, yet when she was with her childhood friend, a wizard now, she giggled like a young girl.",
                evoawk: "The first step toward the peace both she and her king desired so fervently was a great summit between all the different neighboring races, but the clash of interests involved doomed it to failure. Animosity and ideology created a vast divide, and hatred built on hatred to sow chaos. And yet, she still strove to stop the senseless fighting and stood between the fighters to take the blows upon her own body. It was if she believed that she could stop all pain and suffering if she could keep the embers of war from bursting into flame."
            }
    },
    { //Lozze - Complete
        id: 102286212,
        name: "Lozze",
        rank: "C",
        attribute: "Water",
        type: "Beast",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102285212.png",
                detailawk: "/db/Units/Detail/unit_detail_102286212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102285212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102286212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6035,
                hpplus: 915,
                atk: 2420,
                atkplus: 373,
                def: 2055,
                defplus: 318
            },
        skillset: 
            {
                skill: "7s CT - 2000% Water DMG (PHY). Increase Allies' Arts by 4.",
                skillbreak: 1200,
                arts: "17200% Water DMG (PHY). For 20s, increase Allies' Equipment CT REC by 30%.",
                artsbreak: 2300,
                truearts: "30000% Water DMG (PHY). For 20s, increase Allies' Arts by 3/s, increase Allies' Equipment DMG by 60%.",
                trueartsbreak: 4000
            },
        passive: 
            {
                ability1: "When near-death, increase own Arts by 2/s.",
                ability2: "When near-death, increase own evasion by 10%.",
                ability3: "Increase all Allies' evasion by 15%. Increase own stats by [10% × # of female Allies in the party]."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat3amt: 2,
                    hover3: "Palamicia",
                    mat4: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat4amt: 3,
                    hover4: "Freydal",
                    mat5: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat5amt: 1,
                    hover5: "Oldeus"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Diamond Chakram",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_104891.png",
                skill: "40s CT - 6650% Water DMG (PHY). For 8s, increase Allies' Equipment DMG by 25%. If user is Water, increase Equipment DMG by 40% instead.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Lozze, increase DMG and Skill DMG by 20%. Does not stack with Abilities of the same name.",
                        ability2: "When near-death, heal own HP by 30/s."
                    }
            },
        lore: 
            {   
                evo5: "This warrior war a younger prince of a certain Beast Kingdom early in the Ancient War, and served as the head of its army. He was often driven by instinct and was quick to anger, and he was always one to find some reason to attack those he didn't get along with, so of course he was by nature perfectly suited to war. On the other hand, when he took a liking to someone he treated them like family, and he was particularly fond of women. His many loves included women of other races, and his affection for them also spilled over onto their entire nations.",
                evoawk: "He had absolutely no interest in the ideals of peace extolled at the summit he was invited to. Indeed, he considered himself a proud member of a warlike people, and considered it only right to kill those he couldn't get along with. Indeed, the second he decided the Divine swordswoman was his enemy he challenged her to battle, but her unwavering dedication to peace struck a chord within him. Thus, this simple reason led him to stand in defiance before the advancing Demon army."
            }
    },
    { //Jill - Complete
        id: 102296312,
        name: "Jill",
        rank: "F",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102295312.png",
                detailawk: "/db/Units/Detail/unit_detail_102296312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102295312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102296312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7180,
                hpplus: 999,
                atk: 2250,
                atkplus: 347,
                def: 2420,
                defplus: 373
            },
        skillset: 
            {
                skill: "8s CT - 2100% Earth DMG (PHY). Increase own Arts by 3. During this Skill, increase BE generation by 50%.",
                skillbreak: 2000,
                arts: "17000% Earth DMG (PHY). For 15s, increase Allies' DMG and evasion by 20%.",
                artsbreak: 4000,
                truearts: "Increase own Arts by 100 + For 20s, summon a Double that mimics all of own attacks (including Arts and Skill, not including Equipment).",
                trueartsbreak: 0
            },
        passive: 
            {
                ability1: "Heal own HP by 16/s.",
                ability2: "When near-death, increase own Skill CT REC by 70%.",
                ability3: "While a Double is present, increase evasion by 10% and DMG against Enemies in Break by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat3amt: 2,
                    hover3: "Tonetaurus",
                    mat4: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat4amt: 1,
                    hover4: "Oldeus",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Viperra",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_104971.png",
                skill: "50s CT - 8500% Earth DMG (PHY). For 8s, reduce Enemies' Earth RES by 50%.",
                skillbreak: 1700,
                passive: 
                    {
                        ability1: "When equipped by Jill, increase DMG by 20%. At the start of a Quest, increase Arts by 2/s for 90s. Does not stack with Abilities of the same name.",
                        ability2: "At the start of a Quest, increase Allies' DMG by 10% for 30s. Does not stack with Abilities of the same name."
                    }
            },
        lore: 
            {   
                evo5: "An official of a nation surrounded by other races who worked hard to maintain peace for her home. She used her gentle nature and feminine charms as weapons to lead other nations to lower their guards. Yet if circumstances called for it she was fully ready to lead armies form the front, and quite effectively too. However, her role in international diplomacy meant she had to keep that skill hidden. They say that no one who uncovered her secret survived long.",
                evoawk: "They say that the moment the Gods asked her and her nation for help holding a meeting with the neighboring countries, it was doomed to failure. Even as she played innocent, she was putting her strategies into place. She planted the poisonous seeds of dissent, helped in the revenge of the Machines who were playing at being friends with the Demons, and succeeded in setting off the war. She and her co-conspirators had only one purpose. That was to get the other 7 great races apart to destroy each other."
            }
    },
    { //Alvina - Complete
        id: 102306212,
        name: "Alvina",
        rank: "B-",
        attribute: "Water",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102305212.png",
                detailawk: "/db/Units/Detail/unit_detail_102306212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102305212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102306212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6550,
                hpplus: 992,
                atk: 2260,
                atkplus: 349,
                def: 2330,
                defplus: 359
            },
        skillset: 
            {
                skill: "9s CT - 2000% Water DMG (PHY). 30% chance to inflict Freeze for 3s.",
                skillbreak: 1000,
                arts: "17000% Water DMG (MAG). 80% chance to inflict Ultimate Freeze for 10s.",
                artsbreak: 2200,
                truearts: "28000% Dark DMG (MAG). 100% chance to inflict Disease for 12s. For 20s, increase own Arts by 5/s.",
                trueartsbreak: 5000
            },
        passive: 
            {
                ability1: "Increase DMG against Frozen Enemies by 100%.",
                ability2: "Increase evasion by 10%.",
                ability3: "Increase DMG against Diseased Enemies by 100%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat3amt: 1,
                    hover3: "Mavroskia",
                    mat4: "/db/Icons/dungeon_drops/fragAerugraxes.png",
                    mat4amt: 1,
                    hover4: "Aerugraxes"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Draco Hastia",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_105001.png",
                skill: "50s CT - 8200% Dark DMG (MAG). For 12s, reduce Enemies' Freeze RES by 60%. If user is Water, increase DMG by 20% for 12s.",
                skillbreak: 1200,
                passive: 
                    {
                        ability1: "When equipped by Alvina, increase DMG by 20% and increase Paralysis, Seal and Curse RES by 100%. Does not stack with Abilities of the same name.",
                        ability2: "Increase all Water and Dark Allies' Equipment DMG by 10%. Does not stack with Abilities of the same name."
                    }
            },
        lore: 
            {   
                evo5: "A spear user known as the half-sister of Corsair, one of the Three Blade Masters. They say her power was equal to her sister's, but no detailed record has been found. She obeyed no master, had no home, and spent her life in travel. No one knows what her purpose was, but Corsair researchers say that in all the places where she fell into danger, there is evidence of Alvina's visit as well.",
                evoawk: "There is only one record of the two sisters fighting side by side. It is believed that they defeated the invading Demons together, but there are also documents establishing they fought fiercely and that Alvina was a fellow of the Demons. They say that she hated both her father, who had abandoned her, and her half-sister born after that, but there is also literature stating that she used the power of darkness to protect her sibling, as well. The truth is not clear, but there are stories stating that the younger sister spent her later years waiting for someone."
            }
    },
    { //Palamicia - Complete
        id: 102316212,
        name: "Palamicia",
        rank: "F",
        attribute: "Water",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102315212.png",
                detailawk: "/db/Units/Detail/unit_detail_102316212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102315212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102316212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7150,
                hpplus: 999,
                atk: 2080,
                atkplus: 322,
                def: 2380,
                defplus: 367
            },
        skillset: 
            {
                skill: "7s CT - 1600% Water DMG (MAG). Heal own HP by 100.",
                skillbreak: 1200,
                arts: "Enter Counterattack Mode for 5s. Counterattack: 15000% Water DMG (PHY). Increase own Arts' Critical Rate by 100%, reduce Enemies' Break Bar by 15%.",
                artsbreak: 2000,
                truearts: "27000% Water DMG (PHY). Increase next Counterattack's DMG by 100% + For 15s, increase Allies' Arts by 3/s and Healing Taken by 50%.",
                trueartsbreak: 5500
            },
        passive: 
            {
                ability1: "Heal own HP by 16/s.",
                ability2: "Increase DMG to Giant Bosses by 30%.",
                ability3: "Increase all Allies' evasion by 10%. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat3amt: 2,
                    hover3: "Palamicia",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Frozen Twin Swords",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_105061.png",
                skill: "5s CT - 2200% Water DMG (MAG). Heal own HP by 100.",
                skillbreak: 500,
                passive: 
                    {
                        ability1: "When equipped by Palamicia, increase DMG by 20% and Break PWR by 25%. Does not stack with Abilities of the same name.",
                        ability2: "When near-death, increase Arts by 2/s."
                    }
            },
        lore: 
            {   
                evo5: "She was known as one of the greatest sword masters of the Gods. She was known as the Quiet Goddess because she would slice an enemy in two without a hint of sound. They say she took no particular pride in her sword skill and was wont to praise anyone she found worthy, regardless of race or technique.. Her character was the epitome of her quiet style, and the only time she showed any emotion was when the one opponent she truly admired fell to evil. She dedicated her life to the sword and represented the ideal of the warrior's eternal quest for supremacy.",
                evoawk: "Once known as one of the Gods' greatest sword masters, she had a fierce match with a warrior of the Gods, and the two were so impressed with each other they swore a rematch. Time passed, and she was ordered to subdue a rampaging High Demon. When she faced it, though, she found that it was her former opponent, now changed. No one knows the truth of the rumors that she was overcome with rage at him for abandoning his pride, and cleaved him in two with her mighty blows. They do say that afterward, she continued her endless pursuit of mastery."
            }
    },
    { //Ifrit - Complete
        id: 102326112,
        name: "Ifrit",
        rank: "C",
        attribute: "Fire",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102325112.png",
                detailawk: "/db/Units/Detail/unit_detail_102326112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102325112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102326112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6880,
                hpplus: 999,
                atk: 2650,
                atkplus: 407,
                def: 2000,
                defplus: 310
            },
        skillset: 
            {
                skill: "8s CT - 2000% Fire DMG (PHY). Likely Burns Enemy.",
                skillbreak: 1000,
                arts: "15000% Fire DMG (PHY). Inflicts Burn on Enemies and self. For 10s, reduce Enemies' Fire RES by 30%.",
                artsbreak: 2100,
                truearts: "30000% Fire DMG (PHY). If True Arts is activated while Burned, increase DMG of that True Arts by 160% and cure own Burn.",
                trueartsbreak: 3800
            },
        passive: 
            {
                ability1: "Increase Critical Rate by 100% against Burned Enemies.",
                ability2: "Increase PHY RES by 20%.",
                ability3: "Increase own Arts by 3/s when Burned."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat3amt: 2,
                    hover3: "Ifrit",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Volkans",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_105071.png",
                skill: "70s CT - 10000% Fire DMG (PHY). Burns Enemies and Allies.",
                skillbreak: 3300,
                passive: 
                    {
                        ability1: "When equipped by Ifrit, increase DMG by 20% and max HP by 50%. Does not stack with Abilities of the same name.",
                        ability2: "Increase Skill CT REC by 35%."
                    }
            },
        lore: 
            {   
                evo5: "This being is a manifestation of flame, the rage of the world. It possesses the power to cause any heat source to rage out of control and burn the earth clean, but for some reason it has manifested in the body of a certain God, limiting his powers. Even so, it must be careful to hold back the raging fire that well forth from it, and the seals holding it back are also now ready to burst. As he is a fragment of the world’s consciousness, his actions are likely a warning to Humanity.",
                evoawk: "A renowned Divine warrior challenged a Divine swordswoman to a battle, and after losing in a fierce fight, the two so admired each others’ techniques they swore to a rematch. Later, the fighter was overcome by his wounds and fell to the ground, but in that instant, he made a compact [?] with the planet to save his life. The swordswoman reappeared before him, this fighter who became a High Demon with the power to burn the world to ash. No one knows the truth of the rumors that he may have survived, despite the swordwoman’s victory."
            }
    },
    { //Nogia - Complete
        id: 102336411,
        name: "Nogia",
        rank: "C",
        luckrank: "F",
        attribute: "Light",
        type: "Spirit",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102335411.png",
                detailawk: "/db/Units/Detail/unit_detail_102336411.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102335411.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102336411.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6085,
                hpplus: 922,
                atk: 2140,
                atkplus: 331,
                def: 1990,
                defplus: 308
            },
        skillset: 
            {
                skill: "7s CT - 1600% Light DMG (PHY). Increase own Arts by 3.",
                skillbreak: 1700,
                arts: "Increase all Allies' Arts by 15 + For 10s, heal Allies' HP by 100/s and gain a DMG barrier that negates 2500 DMG to self.",
                artsbreak: 0,
                truearts: "18500% Light DMG (MAG). For 12s, increase Allies' Critical Rate by 50% and Paralysis and Burn RES by 80%.",
                trueartsbreak: 4000
            },
        passive: 
            {
                ability1: "When near-death, increase Arts by 2/s.",
                ability2: "Increase Dark RES by 40%.",
                ability3: "Increase Allies' MAG RES by 10% and Critical RES by 25%. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragNogia.png",
                    mat3amt: 20,
                    hover3: "Nogia",
                }
            },
        lore: 
            {   
                evo5: "A mysterious warrior early in the Ancient War known as \"Edgeless Nogia.\" For some reason, he never drew his sword and preferred to fight empty-handed. Nevertheless, there is no record of him losing a fight, meaning he must have possessed limitless power. He spent his life traveling, searching for \"that which must be slashed.\" He swore to never draw his sword until that moment and kept that promise until the very end...Though none can say if he ever met his target.",
                evoawk: "A blade master who believed his sword should only be drawn when truly needed. He searched his whole life and found the need when he encountered a Flame Demon possessing the power of the stars. The beast was a personification of the very idea of flame, manifested by the Gods. And yet the first glimpse of his blade seemed to slice the creature in two. However, what he truly cut was not his body, but the link between the body and the flame. Sayingg that there still must be something needed to be slashed, he set out again."
            }
    },
    { //Daki - Complete (+ JP version)
        id: 102346412,
        name: "Daki",
        rank: "C",
        attribute: "Light",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102345412.png",
                detailawk: "/db/Units/Detail/unit_detail_102346412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102345412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102346412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6350,
                hpplus: 962,
                atk: 2465,
                atkplus: 379,
                def: 2133,
                defplus: 329
            },
        skillset: 
            {
                skill: "6s CT - 2000% Light DMG (MAG). Increase own Arts by 5.",
                skillbreak: 1200,
                arts: "16000% Light DMG (MAG). For 14s, increase Allies' Skill CT REC by 120%, increase Light Allies' Skill DMG by 35%.",
                artsbreak: 2100,
                truearts: "30000% Light DMG (MAG). For 10s, Allies ignore 100% of Enemies' DEF, increase Light and Dark Allies' DMG by 60%.",
                trueartsbreak: 3800
            },
        skillsetJP: 
            {
                skill: "6s CT - 2000% Light DMG (MAG). Increase own Arts by 5.",
                skillbreak: 1200,
                arts: "14000% Light DMG (MAG). For 14s, increase Allies' Skill CT REC by 120%, increase Light Allies' Skill DMG by 35%.",
                artsbreak: 2100,
                truearts: "27000% Light DMG (MAG). For 10s, Allies ignore 100% of Enemies' DEF, increase Light and Dark Allies' DMG by 60%.",
                trueartsbreak: 3800
            },
        passive: 
            {
                ability1: "Increase Dark RES by 25%.",
                ability2: "Increase Allies' Status RES (except Faint) by 20%.",
                ability3: "Increase God Allies' stats by 15%. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat3amt: 2,
                    hover3: "Radachne",
                    mat4: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat4amt: 1,
                    hover4: "Flogassa",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Atropos",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_105111.png",
                skill: "50s CT - 7200% Light DMG (MAG). For 16s, increase own Skill CT REC by 30%. If user is Light, increase Skill CT REC by 80% instead.",
                skillbreak: 1200,
                passive: 
                    {
                        ability1: "When equipped by Daki, increase DMG, evasion and accuracy by 20%. Does not stack with Abilities of the same name.",
                        ability2: "Increase MAG DMG by 30%."
                    }
            },
        lore: 
            {   
                evo5: "A sorcerer God who was a member of the 3rd Dove Brigade. He was revered by his fellow soldiers for his ability to always make sound judgements during combat. His travel companion Spirit Meiol knew Daki since he was just a child. Because of their closeness, Daki showed a side of himself that he would never let anyone else see. He desired to learn the meaning behind his late mother's last words, but didn't know what great events would occur due to his search for truth...",
                evoawk: "A sorcerer God who was a member of the 3rd Dove Brigade. He was revered by his fellow soldiers for his ability to always make sound judgements during combat. His travel companion Spirit Meiol knew Daki since he was just a child. Because of their closeness, Daki showed a side of himself that he would never let anyone else see. He desired to learn the meaning behind his late mother's last words, but didn't know what great events would occur due to his search for the truth..."
            }
    },
    { //Raki - Complete (+ JP version)
        id: 102356512,
        name: "Raki",
        rank: "F",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102355512.png",
                detailawk: "/db/Units/Detail/unit_detail_102356512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102355512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102356512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7268,
                hpplus: 999,
                atk: 2255,
                atkplus: 348,
                def: 2387,
                defplus: 368
            },
        skillset: 
            {
                skill: "7s CT - 2200% Dark DMG (MAG). Heal own HP by 5% of DMG dealt by this Skill.",
                skillbreak: 1400,
                arts: "17000% Dark DMG (PHY). For 15s, increase own Arts DMG by 25%.",
                artsbreak: 2100,
                truearts: "34000% Dark DMG (PHY). Increase own Arts by 2 for each hit of own True Arts that hits an enemy + For 10s, reduce Enemies' Light and Dark RES by 20%.",
                trueartsbreak: 4200
            },
        skillsetJP: 
            {
                skill: "7s CT - 2200% Dark DMG (MAG). Heal own HP by 5% of DMG dealt by this Skill.",
                skillbreak: 1400,
                arts: "14500% Dark DMG (PHY). For 15s, increase own Arts DMG by 25%.",
                artsbreak: 2100,
                truearts: "31000% Dark DMG (PHY). Increase own Arts by 2 for each hit of own True Arts that hits an enemy + For 10s, reduce Enemies' Light and Dark RES by 20%.",
                trueartsbreak: 4200
            },
        passive: 
            {
                ability1: "Increase Light RES by 25%.",
                ability2: "Increase Allies' accuracy by 20%. Does not stack with Abilities of the same name.",
                ability3: "Increase Demon Allies' stats by 15%. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 1,
                    hover3: "Fosradius",
                    mat4: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat4amt: 1,
                    hover4: "Ragsherum Phantom",
                    mat5: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat5amt: 1,
                    hover5: "Mavroskia"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Deino",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_105261.png",
                skill: "45s CT - 7800% Dark DMG (PHY). Heal own HP by 8% of DMG dealt by this attack + For 10s, increase Allies' accuracy by 30%.",
                skillbreak: 1200,
                passive: 
                    {
                        ability1: "When equipped by Raki, increase BE generation by 15%, DMG and Skill CT REC by 20%. Does not stack with Abilities of the same name.",
                        ability2: "When near-death, increase ATK by 40%."
                    }
            },
        lore: 
            {   
                evo5: "A twin-blade-wielding Demon who was a member of the 3rd Raven Brigade. He was always the life of the party within the brigade with his inherent positivity and unabashed demeanor. However, he was also recognized as a powerful soldier due to his skill with the sword. His companion Gobl was technically his servant, though sometimes the roles seemed to be reversed. He aimed to grant his father's dying wish: to create a world in which the races could co-exist.",
                evoawk: "Just as Raki had grown enough to unleash the magical power hidden within his twin-blades, he met a man on the battlefield. Unable to accept his words, the two were forced to fight. However, the parting that came at the end of the battle made Raki realize the path he had to go down. Though his fellow soldiers hated to see him leave, Raki resigned from the military, and it's said that he was spotted traveling various countries around the world. However, he no longer had his famous fighting spirit, but instead a peaceful expression on his face."
            }
    },
    { //Zerkalo - Complete
        id: 102366511,
        name: "Zerkalo",
        luckrank: "F",
        attribute: "Dark",
        type: "Beast",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102365511.png",
                detailawk: "/db/Units/Detail/unit_detail_102366511.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102365511.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102366511.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6188,
                hpplus: 938,
                atk: 2200,
                atkplus: 340,
                def: 2010,
                defplus: 311
            },
        skillset: 
            {
                skill: "8s CT - 1800% Dark DMG (PHY). For 6s, reduce enemies' ATK by 15%.",
                skillbreak: 2000,
                arts: "8800% Dark DMG (PHY). For 8s, increase own Critical Rate by 50%, reduce Enemies' Break RES by 15%.",
                artsbreak: 2400,
                truearts: "14800% Dark DMG (PHY). For 12s, increase Allies' Break PWR by 20%, increase own Critical Rate by 80%.",
                trueartsbreak: 3800
            },
        passive: 
            {
                ability1: "Increase DMG to Demon Enemies by 60%.",
                ability2: "Increase Allies' Break PWR by 10%. Does not stack with Abilities of the same name.",
                ability3: "Increase own Arts by 1/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragZerkalo.png",
                    mat3amt: 20,
                    hover3: "Zerkalo",
                }
            },
        lore: 
            {   
                evo5: "This beast served the ruler of a country controlled by the Gods, that was taken by the Demons during the Ancient war. His master was executed for resisting the Demons, and his family scattered. As he felt his own strength insufficient to protect the master's daughters, he went to a hermit sorcerer to beg for more power, but it ended up consuming him. He was transformed into a creature of darkness, overcome with the need to kill his foes. He was soon feared as the Mad Beast Slayer.",
                evoawk: "After his master's death, this small Beast man went deep into the woods to ask a hidden sorcerer for the power to protect the master's daughter. The sorcerer gave him the power to protect her, with the admonition that he must not kill out of hate. However, he soon broke this ban and was consumed by the darkness within, becoming a foul slayer of Demons. The Gods and Humans sent forces to put an end to the mad beast's rage, but those forces have disappeared without a trace."
            }
    },
    { //Valentia - Complete
        id: 102386411,
        name: "Valentia",
        rank: "C",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102385411.png",
                detailawk: "/db/Units/Detail/unit_detail_102386411.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102385411.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102386411.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/healLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6530,
                hpplus: 989,
                atk: 2010,
                atkplus: 311,
                def: 2322,
                defplus: 358
            },
        skillset: 
            {
                skill: "8s CT - Heal Allies' HP by 100 and increase own Arts by 5.",
                skillbreak: 0,
                arts: "For 15s, heal Allies' HP by 100/s and Allies gain a DMG barrier that negates 3000 DMG.",
                artsbreak: 0,
                truearts: "Recover Allies' HP by 2000. Heal the Ally with the lowest current HP by 15% of their max HP + For 12s, increase Allies' Skill CT REC by 80%.",
                trueartsbreak: 0
            },
        passive: 
            {
                ability1: "Increase Disease RES by 30%.",
                ability2: "Increase Allies' max HP by 10%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 2,
                    hover3: "Flogassa",
                    mat4: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat4amt: 3,
                    hover4: "Palamicia",
                    mat5: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat5amt: 4,
                    hover5: "Radachne"
                }
            },
        lore: 
            {   
                evo5: "A girl who went from being an orphan during the Ancient War to becoming a great sorcerer's apprentice. She lived in peace under the protection of the Gods, but when her country was seized by the Demons she lost her family and position. She was then taken in by her future master, the sorcerer. From then on, as she served the sorcerer hand and foot, she learned everything she could, and developed a true talent for healing. That power, still immature, was so great they say it could even save souls fallen to darkness.",
                evoawk: `A small Beast servant came to visit her teacher,and claimed to desire power to better perform the tasks left by his deceased master. However this servant was actually consumed by hate over the murder and became a slayer of Demons. Her teacher said... "Power is there to protect,we must never forget that." Hearing the regret in those words,and seeing the heart-breaking sight of that old servant now a mighty swordman. led to her mustering all her power. She struck, to cleanse his soul so that he could see and repent of his crimes.`
            }
    },
    { //Mira (Earth) - Complete
        id: 102396312,
        name: "Mira (Earth)",
        rank: "B-",
        twrequire: "favorable",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102395312.png",
                detailawk: "/db/Units/Detail/unit_detail_102396312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102395312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102396312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 7280,
                hpplus: 999,
                atk: 2560,
                atkplus: 394,
                def: 2488,
                defplus: 383
            },
        skillset: 
            {
                skill: "7s CT - 2000% Earth DMG (PHY). For 7s, increase Earth Allies' Arts by 1/s.",
                skillbreak: 1800,
                arts: "15500% Earth DMG (PHY). For 10s, increase Earth Allies' DMG by 30% and Critical Rate by 80%.",
                artsbreak: 2400,
                truearts: "32000% Earth DMG (PHY). For 25s, heal Allies' HP by 120/s, Allies gain a DMG barrier that negates 4200 DMG.",
                trueartsbreak: 4800
            },
        passive: 
            {
                ability1: "Increase Earth Allies' DMG by 30%.",
                ability2: "Increase Allies' DMG against Enemies in Break by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat3amt: 3,
                    hover3: "Tonetaurus",
                    mat4: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat4amt: 2,
                    hover4: "Goldola",
                    mat5: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat5amt: 2,
                    hover5: "Flogassa"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "IZANAGI",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_105431.png",
                skill: "10s CT - 2300% Earth DMG (PHY). For 3s, increase own Arts by 2/s.",
                skillbreak: 300,
                passive: 
                    {
                        ability1: "When equipped by Mira (Earth), increase DMG by 20% and increase Arts by 2/s. Does not stack with Abilities of the same name.",
                        ability2: "Increase Equipment CT REC by 15%."
                    }
            },
        lore: 
            {   
                evo5: "The third princess of the Principality of Bamint, who carries a sword inherited from an island country in the Far East. The crest inscribed in the blade resembles that of the \"Radiant Itto-Ryu\", but the shape of the sword differs. One day she starts to have a strange dream. A beautiful kimono, a charming cityscape, a young woman with long black hair. Searching for the source of the dream, she reaches the Far East where she meets two swordswomen with important destinies",
                evoawk: "The third princess of the principality of Bamint,who was taught the deepest secrets of the \"Sakura Itto-Ryu\" style by two swordswomen who'd once once journeyed to the \"Island of the God's\" to save their country. This unique sword technique engages the enemy in a fight dance with similar form to that of falling cherry blossoms petals in spring. Mira wielded two swords until then, but aftermastering the \"Sakura Itto-Ryu\" she improved her skill and now uses only one blade. After inheriting \"Sakura Itto-Ryu\" and the spirit of the swordswoman from another world. Mira's eyes sparkle with renewed excitement."
            }
    },
    { //Zeorg (Dark) - Complete
        id: 102406512,
        name: "Zeorg (Dark)",
        rank: "F",
        twrequire: "favorable",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102405512.png",
                detailawk: "/db/Units/Detail/unit_detail_102406512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102405512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102406512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6800,
                hpplus: 999,
                atk: 3055,
                atkplus: 468,
                def: 2366,
                defplus: 364
            },
        skillset: 
            {
                skill: "9s CT - 3000% Dark DMG (PHY). For 10s, increase own Paralysis and Freeze RES by 50%.",
                skillbreak: 1200,
                arts: "16000% Dark DMG (PHY). Increase Arts DMG by 100% when attacking Light Enemies.",
                artsbreak: 2500,
                truearts: "31200% Dark DMG (PHY). For 12s, heal own HP by 4% of DMG dealt, increase own Arts by 2 each time this unit is hit, redirect Allies' DMG taken to self and gain a DMG barrier that negates 20000 DMG to self.",
                trueartsbreak: 4000
            },
        passive: 
            {
                ability1: "Increase Allies' Dark DMG by 30%. Does not stack with Abilities of the same name.",
                ability2: "When near-death, increase own DMG by 20%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat3amt: 1,
                    hover3: "Palamicia",
                    mat4: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat4amt: 4,
                    hover4: "Mavroskia",
                    mat5: "/db/Icons/dungeon_drops/fragValzandeth.png",
                    mat5amt: 1,
                    hover5: "Valzandeth"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Exceed Zechs",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_105621.png",
                skill: "45s CT - 8800% Dark DMG (MAG). If own HP is 75% or more, increase own Arts by 30. For 8s, increase own Arts DMG by 50%.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Zeorg (Dark), increase DMG and max HP by 20%, Paralysis and Freeze RES by 50%. Does not stack with Abilities of the same name.",
                        ability2: "When near-death, heal own HP by 3% of DMG dealt."
                    }
            },
        lore: 
            {   
                evo5: "A living legend, the Hero of Famelle is the subject of countless epic tales. Despite how many adventurers admire and praise him, he holds hidden pain within him. He invited his adventurer friend on a journey to a forbidden territory to deal with a cursed sword in a bid to put paid to his worries. There, he reached out to break the sword's seal, and amid his frenzy, the power and vigor of his youth returned in full force. Having surpassed the bonds of old age, he became an unstoppable force.",
                evoawk: "Already the bearer of the magic blade Ryude Magus, the Hero of Famelle has dominated the cursed sword \"Exceed Zechs\". His unbreakable spirit and deep faith helped him shake off the cursed sword's attempts at domination. Now that he bears both these swords, the very spirits of light and dark, they say he is a man like none that has ever lived. Having broken the bonds of old age, and all other limits, this pursuer of perfection can be called a true Hero."
            }
    },
    { //Badoul - Complete
        id: 102416111,
        name: "Badoul",
        luckrank: "F",
        rank: "B-",
        attribute: "Fire",
        type: "Giant",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102415111.png",
                detailawk: "/db/Units/Detail/unit_detail_102416111.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102415111.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102416111.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6550,
                hpplus: 992,
                atk: 2015,
                atkplus: 312,
                def: 2280,
                defplus: 352
            },
        skillset: 
            {
                skill: "8s CT - 1600% Fire DMG (PHY). 10% chance to inflict Faint for 5s.",
                skillbreak: 1600,
                arts: "8600% Fire DMG (PHY). For 15s, increase Allies' MAG RES by 50%.",
                artsbreak: 2100,
                truearts: "18500% Fire DMG (MAG). 100% chance to inflict Burn for 15s. For 12s, increase Allies' PHY DMG by 50%. For 8s, reduce own DEF by 30%.",
                trueartsbreak: 3500
            },
        passive: 
            {
                ability1: "Increase DEF by 20% when under a DMG barrier.",
                ability2: "Increase MAG RES by 25%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragBadoul.png",
                    mat3amt: 20,
                    hover3: "Badoul",
                }
            },
        lore: 
            {   
                evo5: "A Giant clad in flames that fought alongside the leader of a feudal domain in a time before the Ancient War. He used his incredible physical strength to support the leader in creating his own nation, and as such came to be revered by the land's people as a Hades Giant. After the country was established and he fulfilled his purpose, he sacrificed his soul and fell into a long slumber in order to become the guardian Giant of the land. Before he fell asleep, he told the people to awaken him again in their time of need with the power of one born underneath a star of fire. It's said that when that time came, he was without a soul, but fought by connecting himself to the soul of the one who awakened him.",
                evoawk: "He awoke to a gruesome sight: the corpses of the people being enslaved... They were the corpses of the villagers who enshrined and adored him. And, the \"key\" to his awakening was also born in the same village. Her small body shook in anger, but her soul burned so fervently that it could never be extinguished. Her intense emotions made the flames in his fist burn bright. Knowing he had something left to protect, he prepared to enter the fray once again for the sake of his little master."
            }
    },
    { //Strife - Complete
        id: 102436511,
        name: "Strife",
        luckrank: "F",
        rank: "F",
        attribute: "Dark",
        type: "Spirit",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102435511.png",
                detailawk: "/db/Units/Detail/unit_detail_102436511.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102435511.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102436511.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6012,
                hpplus: 911,
                atk: 2392,
                atkplus: 368,
                def: 2055,
                defplus: 318
            },
        skillset: 
            {
                skill: "8s CT - 1700% Dark DMG (MAG). For 6s, reduce Enemies' DEF by 30%.",
                skillbreak: 1400,
                arts: "9000% Dark DMG (MAG). Heal own HP by 3% of DMG dealt by this Arts, increase Dark Allies' Arts by 15.",
                artsbreak: 2200,
                truearts: "22000% Dark DMG (MAG). For 12s, reduce Enemies' MAG RES by 20%, reduce Enemies' Weak-Element RES by 20%.",
                trueartsbreak: 3600
            },
        passive: 
            {
                ability1: "Increases Allies' Critical RES by 20%.",
                ability2: "Increase MAG RES by 15%.",
                ability3: "Increases DMG to God Enemies by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragStrife.png",
                    mat3amt: 20,
                    hover3: "Strife",
                }
            },
        lore: 
            {   
                evo5: "A magical Spirit warrior from the early Ancient War. He wore a strange mask and had a unique way of fighting that used magic. He used to teach swordsmanship and magic in his hometown, but he was thrown out due to his abnormality. He was obsessed with making his students fight each other, tossing the losers aside and making the winners fight more. He called the most skilled of his students his \"works of art.\" His masterpiece was a war orphan who grew up to become a commander of a battalion of Dragon knights.",
                evoawk: "In order to create his \"works of art\"--that is, his students--he would arrange for assassins that he himself trained to try to kill his students. He thought that the hatred his students felt for him was a necessary element in making them grow, and his final goal was to sacrifice himself so that he could perfect his works of art. He played with many lives for his own entertainment and ended up being targeted by the Black Dragon Knights because of it, but it is unclear what happened to him in the end."
            }
    },
    { //Weaver - Complete
        id: 102446212,
        name: "Weaver",
        rank: "B+",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102445212.png",
                detailawk: "/db/Units/Detail/unit_detail_102446212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102445212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102446212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7156,
                hpplus: 999,
                atk: 2335,
                atkplus: 360,
                def: 2496,
                defplus: 384
            },
        skillset: 
            {
                skill: "7s CT - 1800% Water DMG (PHY). Recover own HP by 120 and increase own Arts by 8.",
                skillbreak: 1300,
                arts: "13500% Water DMG (PHY). Increase Allies' Arts by 20 + For 20s, Allies gain a DMG barrier that negates 2000 DMG.",
                artsbreak: 2000,
                truearts: "31000% Water DMG (PHY). For 13s, reduce Enemies' Water RES by 30%, increase Allies' Critical DMG by 50%.",
                trueartsbreak: 5500
            },
        passive: 
            {
                ability1: "Increase Water Allies' BE generation by 15%.",
                ability2: "Increase Critical Rate by 20%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                    mat3amt: 2,
                    hover3: "Geo-Zagnus",
                    mat4: "/db/Icons/dungeon_drops/fragAerugraxes.png",
                    mat4amt: 2,
                    hover4: "Aerugraxes"
                }
            },
        lore: 
            {   
                evo5: "This dual-blade wielder was accepted into the Black Dragon Knights in recognition of his great skills. Even as a Human, he stood out among the Dragons for his ceaseless training and discipline. His positive, honest character and blinding sword skills earned him great respect from the prideful Dragons, and he soon rose to be second in command. He once faced off an old friend who became a Dragon-killer, and despite the fierce battle he found victory, but he rarely speaks of this.",
                evoawk: "When sent out to subdue a Dragon-killer, what awaited him was a dear old friend, cursed to become a dragon himself and now filled with hatred for all Dragon-kind. Having defeated the Dragon murderer after a long battle, his opponent begged for death, wishing to end his life still as a man and not as a dragon, they say he wept bitterly as he beheaded his former friend. With strength surpassing both Humans and Dragons, after a lifetime of training he finally became known as the God of Dragon Knights."
            }
    },
    { //Wargul - Complete
        id: 102456512,
        name: "Wargul",
        rank: "B",
        attribute: "Dark",
        type: "Dragon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102455512.png",
                detailawk: "/db/Units/Detail/unit_detail_102456512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102455512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102456512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7269,
                hpplus: 999,
                atk: 2768,
                atkplus: 427,
                def: 2447,
                defplus: 377
            },
        skillset: 
            {
                skill: "8s CT - 2000% Dark DMG (MAG). Permanently increase own ATK and Break PWR by 6% (max 60% increase).",
                skillbreak: 1500,
                arts: "15500% Dark DMG (MAG). 80% chance to inflict Blind for 12s. For 12s, reduce Enemies' Break RES by 30%.",
                artsbreak: 3500,
                truearts: "31200% Dark DMG (MAG). For 12s, increase Allies' DMG to Enemies in BREAK by 150%.",
                trueartsbreak: 5200
            },
        passive: 
            {
                ability1: "Increase DMG to Enemies in BREAK by 60%.",
                ability2: "Increase Allies' Break PWR by 15%. Does not stack with Abilities of the same name.",
                ability3: "When near-death, increase own Arts by 3/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat3amt: 2,
                    hover3: "Palamicia",
                    mat4: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat4amt: 3,
                    hover4: "Mavroskia",
                    mat5: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat5amt: 1,
                    hover5: "Oldeus"
                }
            },
        lore: 
            {   
                evo5: "Before the Ancient War, he was the sorcerer swordsman leader of the Black Dragon Knights. He was very earnest, and as a war orphan, he was raised by a Spirit, as his apprentice. However, because of his preceptor's distorted ethic, he eventually ran away. After that, he joined the Black Dragon Knights and became its captain. Besides being a master of daggers, he was also an excellent sorcerer, able to control the shadows of Dragons. Nobody could ever survive his illusory balde.",
                evoawk: "When he went on a mission to execute a Dragon-killer with his second-in-command, he found on the other side the immoral Spirit he knew well, with a grin on his face. It was clear that the next \"masterpiece\" whose life he had chosen to play with was the Dragon-killer's. After a fierce battle, he learnt that the Dragon-killer, who was a childhood friend of his second-in-command, was actually the Spirit's apprentice, and he quit the Knights to fight him. No one knows what happened next..."
            }
    },
    { //Granith - Complete
        id: 102466211,
        name: "Granith",
        attribute: "Water",
        luckrank: "F",
        type: "Dragon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102465211.png",
                detailawk: "/db/Units/Detail/unit_detail_102466211.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102465211.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102466211.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6189,
                hpplus: 938,
                atk: 2266,
                atkplus: 349,
                def: 2013,
                defplus: 311
            },
        skillset: 
            {
                skill: "8s CT - 1900% Water DMG (MAG). For 5s, reduce Enemies' Freeze RES by 15%.",
                skillbreak: 1000,
                arts: "9800% Water DMG (MAG). For 8s, increase Allies' DMG by 20% and Water Allies' DMG by 30%.",
                artsbreak: 2200,
                truearts: "25000% Water DMG (MAG). 100% chance of inflicting Freeze for 8s. For 8s, increase DMG against Frozen Enemies by 80%.",
                trueartsbreak: 4500
            },
        passive: 
            {
                ability1: "Increase Critical Rate by 100% against Frozen Enemies."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGranith.png",
                    mat3amt: 20,
                    hover3: "Granith",
                }
            },
        lore: 
            {   
                evo5: "An ice Dragon's curse transformed this Human knight into one of its own kind. Unlike his friend and rival who joined the Black Dragon Knights, he wanted to forge his own path to prominence. He journeyed and trained alone until a skilled magic warrior took him as an apprentice. Granith's strength grew dramatically, but a tragic fate awaited him...",
                evoawk: "His master told him to slay the Absolute Zero Sea Dragon as part of his training, but he was cursed in the course of battle. As Granith's humanity and reason faded, he begged for help, but the master claimed the curse could only be overcome by growing stronger through the killing of more Dragons. This purposeful lie was never questioned and he began hunting the scaled beasts."
            }
    },
    { //Altie - Complete
        id: 102476511,
        name: "Altie",
        luckrank: "F",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102475511.png",
                detailawk: "/db/Units/Detail/unit_detail_102476511.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102475511.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102476511.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6300,
                hpplus: 950,
                atk: 2268,
                atkplus: 352,
                def: 1950,
                defplus: 302
            },
        skillset: 
            {
                skill: "10s CT - 1800% Dark DMG (MAG). Increase own Arts by 5.",
                skillbreak: 900,
                arts: "13000% Dark DMG (MAG). Increase Break PWR of this Arts by 8% per Death Count (up to 104%).",
                artsbreak: 2000,
                truearts: "20000% Dark DMG (MAG). If own Death Count is 13, increase this True Arts' DMG by 200%. Consume 15% of Allies' (except self) HP, increase Allies' Arts by 20.",
                trueartsbreak: 3000
            },
        passive: 
            {
                ability1: "Gain a Death Counter that increases its Count by the total number of Enemies and Allies that have died during the current Quest. Increase own Skill CT REC by 8% per Death Count (up to 104%).",
                ability2: "Increase own Arts by 2/s.",
                ability3: "Increase Curse RES by 100%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 4,
                    hover3: "RagsherumPhantom",
                    mat4: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat4amt: 3,
                    hover4: "Goldola",
                    mat5: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat5amt: 3,
                    hover5: "Radachne"
                }
            },
        lore: 
            {   
                evo5: "A necromancer who destroyed cities and nations early in the Ancient War. They say that she was capable of undending brutality on the orders of a certain beloved Demoness. Her unusual values system meant she was apt to call the spirits of the dead she used her 'friends,' and looked down on the living as prisoners in the flesh. Her nature was shaped by the legendary Flame Giant, and this is evidenced by the terrible brutality she showed when dispatched to a certain territory.",
                evoawk: "Her eyes sparkled at the resurrected Flame Giant of legend. She would not balk at turning an entire nation into a realm of the dead to earn the praise and favor of her beloved Demoness. All that remained was the elimination of a certain irritating sorceress, companion to the Giant. However, the needed Giant only obeyed the girl's orders. The only choice is to turn her into a 'friend.' With that understanding, she gladly went among the teeming spirtis."
            }
    },
    { //Berwick - Complete (+ JP ver.)
        id: 102486112,
        name: "Berwick",
        rank: "B+",
        twrequire: "favorable",
        attribute: "Fire",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102485112.png",
                detailawk: "/db/Units/Detail/unit_detail_102486112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102485112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102486112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6856,
                hpplus: 999,
                atk: 2992,
                atkplus: 458,
                def: 2241,
                defplus: 346
            },
        skillset: 
            {
                skill: "8s CT - 2200% Fire DMG (MAG). 60% chance to inflict Burn for 12s.",
                skillbreak: 1000,
                arts: "14400% Fire DMG (MAG). Increase Allies' Arts by 25 + For 10s, increase Fire Allies' Arts by 2/s.",
                artsbreak: 2100,
                truearts: "For 180s, enter Magia Drive. During Magia Drive: 37000% Fire DMG (MAG). For 15s, increase Allies' DMG by 50%, for Fire Allies, increase DMG by 100% instead.",
                trueartsbreak: 4000
            },
        skillsetJP: 
            {
                skill: "8s CT - 2200% Fire DMG (MAG). 60% chance to inflict Burn for 12s.",
                skillbreak: 1000,
                arts: "22000% Fire DMG (MAG). Increase Allies' Arts by 25 + For 10s, increase Fire Allies' Arts by 3/s.",
                artsbreak: 2100,
                truearts: "For 180s, enter Magia Drive. During Magia Drive: 65000% Fire DMG (MAG). For 25s, increase Allies' DMG by 60%, for Fire Allies, increase DMG by 120% instead.",
                trueartsbreak: 4000
            },
        passive: 
            {
                ability1: "Increase DMG to Earth Enemies by 25%.",
                ability2: "Increase Water RES by 25%.",
                ability3: "Increase Arts by 200 when entering Magia Drive. During Magia Drive, increase Critical Rate by 100% and increase Arts by 3/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat3amt: 1,
                    hover3: "Ifrit",
                    mat4: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat4amt: 2,
                    hover4: "Flogassa",
                    mat5: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                    mat5amt: 1,
                    hover5: "Degilmagna"
                }
            },
        dreamJP: {
            fire: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                    mat2amt: 50,
                    hover2: `Flametail Soulgem`,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 2,
                    hover3: "Flogassa",
                },
                passive: {
                    ability1: `Increase own CRI DMG by 50%.`,
                    ability2: `When own HP is 80% or higher, increase own Arts by 2/s.`
                }
            },
            water: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                    mat2amt: 50,
                    hover2: `Frostsprite Soulgem`,
                    mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat3amt: 2,
                    hover3: "Palamicia",
                },
                passive: {
                    ability1: `At the start of a Quest, Increase own Arts by 100. Does not stack with Abilities of the same name.`,
                    ability2: `Increase all allies' Skill CT Speed by 15%. Does not stack with Abilities of the same name.`
                }
            }
        },
        trueweapon: //If no TW, may delete this section
            {
                name: "Fomalhaut",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_105711.png",
                skill: "50s CT - 7200% Fire DMG (MAG). For 10s, reduce Enemies' Fire RES by 50%.",
                skillbreak: 1200,
                passive: 
                    {
                        ability1: "When equipped by Berwick, increase stats and DMG by 30%. Does not stack with Abilities of the same name.",
                        ability2: "Increase DMG to Earth Enemies by 20%."
                    }
            },
        lore: 
            {   
                evo5: "An Elite sorcerer, renowned for skill not only among the Gods but other magical races as well, and named among the Juno Templars. Recognized as gifted from his early childhood, he then proved his abilities and gained confidence, which resulted in a cold, distant character. And yet, on the battlefield his keen eye offers valuable support, and the powerful magic he uses helps lead to victory after victory for the Templars. Ever since the Knight Commander saved him from a monster attack, he offers complete loyalty to the leader.",
                evoawk: "His admiration for the Commander and his hunger for further growth drove him to facing the devastating attacks against his foes, and when a mighty Ice dragon appeared, he activated the devastating Magia Drive to defeat the fire-proof beast. His countless battles with his fellow Templars has given him a calm perspective on battle, and he is always waiting for the perfect opponent to lay low with his raging flames."
            }
    },
    { //Riviera - Complete (+ JP ver.)
        id: 102496312,
        name: "Riviera (Earth)",
        rank: "B",
        attribute: "Earth",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102495312.png",
                detailawk: "/db/Units/Detail/unit_detail_102496312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102495312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102496312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6576,
                hpplus: 996,
                atk: 3228,
                atkplus: 494,
                def: 2088,
                defplus: 323
            },
        skillset: 
            {
                skill: "10s CT - 2800% Earth DMG (PHY). Increase own Arts by 15.",
                skillbreak: 1000,
                arts: "19000% Earth DMG (PHY). Permanently increase own Critical Rate by 10% (up to 50%).",
                artsbreak: 2000,
                truearts: "For 60s, enter Magia Drive. During Magia Drive: 42000% Earth DMG (PHY). Increase own Arts by 100.",
                trueartsbreak: 3500
            },
        skillsetJP: 
            {
                skill: "10s CT - 2800% Earth DMG (PHY). Increase own Arts by 20.",
                skillbreak: 1000,
                arts: "28000% Earth DMG (PHY). Permanently increase own Critical Rate by 20% (up to 80%).",
                artsbreak: 2000,
                truearts: "For 120s, enter Magia Drive. During Magia Drive: 70000% Earth DMG (PHY). Increase own Super CRI rate by 30% for the duration of True Arts and Increase own Arts by 100.",
                trueartsbreak: 3500
            },
        passive: 
            {
                ability1: "At the start of a Battle, increase own Arts by 100.",
                ability2: "Increase Critical Rate by 50%.",
                ability3: "When Magia Drive is activated, increase own Arts by 100. During Magia Drive, increase Critical DMG by 150% and reduce DMG RES by 30%."
            },
        passiveJP: 
            {
                ability1: "At the start of a Battle, increase own Arts by 100.",
                ability2: "Increase Critical Rate by 50%.",
                ability3: "When Magia Drive is activated, increase own Arts by 100. During Magia Drive, increase Critical DMG by 150%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat3amt: 2,
                    hover3: "Goldola",
                    mat4: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat4amt: 2,
                    hover4: "Tonetaurus",
                    mat5: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat5amt: 1,
                    hover5: "Oldeus"
                }
            },
        dreamJP: {
            water: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                    mat2amt: 50,
                    hover2: `Frostsprite Soulgem`,
                    mat3: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                    mat3amt: 2,
                    hover3: "Geo Zagnus",
                },
                passive: {
                    ability1: `Increase own DMG against Water enemies by 80%.`,
                    ability2: `Increase own Skill CT Speed by 30%.`
                }
            },
            earth: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                    mat2amt: 50,
                    hover2: `Smogdragon Soulgem`,
                    mat3: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat3amt: 2,
                    hover3: "Tonetaurus",
                },
                passive: {
                    ability1: `Increase own Equipment CT Speed by 10%.`,
                    ability2: `When Enemy is below 30% HP, increase own DMG by 80%.`
                }
            }
        },
        trueweapon: //If no TW, may delete this section
            {
                name: "Tempest",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_105741.png",
                skill: "100s CT - 14000% Earth DMG (PHY). If user is Earth, increase own Arts by 50.",
                skillbreak: 800,
                passive: 
                    {
                        ability1: "When equipped by Riviera, increase DMG by 30% and increase Paralysis and Freeze RES by 100%.",
                        ability2: "Increase accuracy by 20%."
                    }
            },
        lore: 
            {   
                evo5: "A female Demon warrior known as \"Brutal War Axe\". She was a bounty hunter who stopped by the small Kingdom of Granadia to heal her battle wounds. There she met Princess Juno and her entourage and became friends with them. She joined the Juno Templars and cast herself into harsh battles to protect the Princess, the Little Lady and the Kingdom of Granadia.",
                evoawk: "Self-proclaimed commander of the \"Vanguard Battalion\" of the Juno Templars. She was a wandering warrior who traveled to many countries with her double axes and decided to stay in Granadia after a long journey. On the battlefield, she attacks in all directions and knocks down her opponents, but she's well knwon by many people for her bright and lively personality. After the coronation of the Little Lady subsequently to the Princess' death, her intent is to protect the kingdom with no mercy to those who try to take advantage of the situation and attempt to hurt the Royal family."
            }
    },
    { //Thetis - Complete + JP ver
        id: 102506212,
        name: "Thetis",
        rank: "S",
        twrequire: "favorable",
        attribute: "Water",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102505212.png",
                detailawk: "/db/Units/Detail/unit_detail_102506212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102505212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102506212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7682,
                hpplus: 999,
                atk: 2332,
                atkplus: 359,
                def: 3188,
                defplus: 488
            },
        skillset: 
            {
                skill: "9s CT - 1500% Water DMG (PHY). For 7s, reduces Enemies' Break RES by 15%.",
                skillbreak: 1800,
                arts: "8000% Water DMG (PHY). ATK of this Arts is increased by [DEF x 1.5] + For 15s, increase Allies' Break PWR by 30%, redirect Allies' DMG taken to self and gain a DMG barrier that negates 10000 DMG to self.",
                artsbreak: 3000,
                truearts: "For 120s, enter Magia Drive. During Magia Drive: 30000% Water DMG (PHY). Cure own Poison and Burn + For 30s, redirect Allies' DMG taken to self and gain a DMG barrier that negates 25000 DMG to self.",
                trueartsbreak: 5800
            },
        skillsetJP: 
            {
                skill: "9s CT - 1500% Water DMG (PHY). For 7s, reduces Enemies' Break RES by 15%.",
                skillbreak: 1800,
                arts: "8000% Water DMG (PHY). ATK of this Arts is increased by [DEF x 1.5] + For 15s, increase Allies' Break PWR by 30%, redirect Allies' DMG taken to self and gain a DMG barrier that negates 10000 DMG to self.",
                artsbreak: 3000,
                truearts: "For 120s, enter Magia Drive. During Magia Drive: 40000% Water DMG (PHY). Cure own Poison and Burn + For 30s, redirect Allies' DMG taken to self and gain a DMG barrier that negates 30000 DMG to self.",
                trueartsbreak: 5800
            },
        passive: 
            {
                ability1: "When an Ally dies (except self), revive that Ally with 50% HP. Ability can only be activated once per Quest.",
                ability2: "Increase Critical RES by 60%.",
                ability3: "Increase Arts by 100 when entering Magia Drive. During Magia Drive, increase DMG RES by 25% and increase Arts by 1 every time this unit is hit."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 1,
                    hover3: "Fosradius",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 2,
                    hover4: "Code-XTF",
                    mat5: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat5amt: 1,
                    hover5: "Palamicia"
                }
            },
        dreamJP: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                        mat3amt: 2,
                        hover3: "Geo Zagnus",
                    },
                    passive: {
                        ability1: `Increase Magia Drive duration by an additional 80s.`,
                        ability2: `When Near-death, increase own DMG RES by 15%.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragMavroskia.png",
                        mat3amt: 2,
                        hover3: "Mavroskia",
                    },
                    passive: {
                        ability1: `Increase Demon allies' Break Power by 30%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own Arts by 100 at Start of Quest. Does not stack with Abilities of the same name.`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Adamas Gear",
                slot: "/db/Mines/thumbnail/defLB.png",
                detail: "/db/Equipment/Detail/item_detail_106001.png",
                skill: "30s CT - For 15s, increase Target's DMG RES by 35%.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Thetis, increase DMG by 30%, max HP by 2000 and increase Paralysis RES, Freeze RES and Seal RES by 100%. Does not stack with Abilities of the same name.",
                        ability2: "Increase DEF by 20% when under a DMG barrier."
                    }
            },
        lore: 
            {   
                evo5: "A young warrior who defends Granadia as a member of the Juno Templars. As the son of an armor shop, he was invited by the leader to join the Templars to serve their beloved princess. On the battlefield, he saved his companions several times thanks to his great shield which generates a wall of steel. His character is so honest and gentle, his friends all trust him like a brother. Because of his temperament, he is often subject to other people's will, but he seems to enjoy it anyway.",
                evoawk: "The Juno Templar known as \"Immovable Shield.\" When one of his companions lost consciousness behind enemy lines, he penetrated the enemy encirclement alone. On the way, he was simultaneously attacked by God army and found himself in a critical situation, but thanks to his willpower he could handle the enemies. He was moved by his duty as a member of the Templars but most importantly by the strong will to save his friends."
            }
    },
    { //Vox - Complete (+ JP ver.)
        id: 102516212,
        name: "Vox",
        rank: "A",
        attribute: "Water",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102515212.png",
                detailawk: "/db/Units/Detail/unit_detail_102516212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102515212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102516212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6891,
                hpplus: 999,
                atk: 2895,
                atkplus: 444,
                def: 2137,
                defplus: 330
            },
        skillset: 
            {
                skill: "10s CT - 2400% Water DMG (PHY). Increase own Arts by 20. During Magia Drive: 2400% Water DMG (PHY). Increase own Arts by 40.",
                skillbreak: 1000,
                arts: "22000% Water DMG (PHY). For 15s, increase Allies' stats by 30%.",
                artsbreak: 2800,
                truearts: "For 180s, enter Magia Drive. During Magia Drive: 45000% Water DMG (PHY). For 15s, increase Allies' Critical Rate by 100%.",
                trueartsbreak: 4000
            },
        skillsetJP: 
            {
                skill: "10s CT - 2400% Water DMG (PHY). Increase own Arts by 20. During Magia Drive: 2400% Water DMG (PHY). Increase own Arts by 40.",
                skillbreak: 1000,
                arts: "18000% Water DMG (PHY). For 12s, increase Allies' stats by 30%.",
                artsbreak: 2800,
                truearts: "For 180s, enter Magia Drive. During Magia Drive: 82000% Water DMG (PHY). For 25s, increase Allies' Critical Rate by 100%.",
                trueartsbreak: 4000
            },
        passive: 
            {
                ability1: "Increase Critical DMG by 80%.",
                ability2: "Increase all Allies' accuracy by 30%. Does not stack with Abilities of the same name.",
                ability3: "During Magia Drive, increase Skill CT REC, ATK, Paralysis RES and Freeze RES by 100%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                    mat3amt: 2,
                    hover3: "Geo-Zagnus",
                    mat4: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat4amt: 3,
                    hover4: "Freydal",
                    mat5: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat5amt: 2,
                    hover5: "Wilkes"
                }
            },
        dreamJP: {
            water: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                    mat2amt: 50,
                    hover2: `Frostsprite Soulgem`,
                    mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat3amt: 2,
                    hover3: "Palamicia",
                },
                passive: {
                    ability1: `Increase all allies' CRI DMG by 30%. Does not stack with Abilities of the same name.`,
                    ability2: `Increase own Arts by 1/s.`
                }
            },
            light: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                    mat2amt: 50,
                    hover2: `Eternaglint Soulgem`,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 2,
                    hover3: "Wilkes",
                },
                passive: {
                    ability1: `At the start of a Quest, increase own Arts by 200. Does not stack with Abilities of the same name.`,
                    ability2: `Increase own Accuracy rate by 30%.`
                }
            }
        },
        trueweapon: //If no TW, may delete this section
            {
                name: "ZANKETSU",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_106071.png",
                skill: "35s CT - 12000% Water DMG (PHY). For 10s, increase own Skill CT REC by 50%.",
                skillbreak: 2000,
                passive: 
                    {
                        ability1: "When equipped by Vox, increase stats and Critical Rate by 20%, and evasion by 10%. Does not stack with Abilities of the same name.", 
                        ability2: "When equipped by Sword Berwick, increase DMG by 30%. At the start of a Battle, increase own Arts by 30. Does not stack with Abilities of the same name.",
                        ability3: "Increase BE generation by 15%."
                    }
            },
        lore: 
            {   
                evo5: "A lightning-fast swordsman who is the leader of the Juno Templars. He pierced deep into enemy lines to overwhelm the Gods Army, but took a sword blow to the back to protect his mistress, Princess Leone. As his consciousness faded, he recalled the promise he made to his former mistress, Princess Juno, and gripped the memento she had given him. That feather held the power to accomplish a single miracle, a power destined to change the fate of Granadia.",
                evoawk: "A lightning-fast swordsman who is the leader of the Juno Templars. He pierced deep into enemy lines to overwhelm the Gods army, but took a sword blow to the back to protect his mistress, Princess Leone. As his consciousness faded, he recalled the promise he made to his former mistress, Princess Juno, and griped the memento she had given him. That feather held the power to accomplish a single miracle, a power destined to change the fate of Granadia."
            }
    },
    { //Duke - Complete + JP ver
        id: 102526512,
        name: "Duke",
        rank: "B+",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102525512.png",
                detailawk: "/db/Units/Detail/unit_detail_102526512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102525512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102526512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7077,
                hpplus: 999,
                atk: 2538,
                atkplus: 390,
                def: 2613,
                defplus: 401
            },
        skillset: 
            {
                skill: "12s CT - 2200% Dark DMG (PHY). During Magia Drive: For 8s, reduce Enemies' Break RES by 25% + Increase own Arts by 20 & Skill's Break Power by 1000.",
                skillbreak: 2400,
                arts: "18000% Dark DMG (PHY). For 10s, reduce Enemies' DMG RES by 30%, reduce DMG RES of Enemies in Break by 30%.",
                artsbreak: 3500,
                truearts: "For 120s, enter Magia Drive. During Magia Drive: 38000% Dark DMG (PHY). Increase own True Arts' Break PWR by 5% of own True Arts' DMG dealt.",
                trueartsbreak: 9900
            },
        skillsetJP: 
            {
                skill: "12s CT - 2200% Dark DMG (PHY). During Magia Drive: For 8s, reduce Enemies' Break RES by 30% + Increase own Arts by 20 & Skill's Break Power by 1000.",
                skillbreak: 2400,
                arts: "18000% Dark DMG (PHY). For 20s, reduce Enemies' DMG RES by 30% and reduce DMG RES of Enemies in Break by an additional 30%.",
                artsbreak: 3500,
                truearts: "For 120s, enter Magia Drive. During Magia Drive: 65000% Dark DMG (PHY). Increase own True Arts' Break PWR by 5% of own True Arts' DMG dealt.",
                trueartsbreak: 9900
            },
        passive: 
            {
                ability1: "Giant Boss' Break Bar is halved at the beginning of battle. Raid Bosses' Break Bars take 20000 Break DMG instead. Ability can only be activated once per Quest.",
                ability2: "Increase Arts by 100 when entering Magia Drive. When a Boss is Broken during Magia Drive, increase own Arts by 100."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 2,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 1,
                    hover4: "Radachne",
                    mat5: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat5amt: 1,
                    hover5: "Freydal"
                }
            },
            dreamJP: {
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragElpneumas.png",
                        mat3amt: 2,
                        hover3: "Elpneumas",
                    },
                    passive: {
                        ability1: `Increase own DMG by [2% × own ATK/100].`,
                        ability2: `When HP is above 80%, increase own ATK by 25%.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                        mat3amt: 2,
                        hover3: "Ragsherum Phantom",
                    },
                    passive: {
                        ability1: `Increase Dark allies' Break Power by 40%. Does not stack with Abilities of the same name.`,
                        ability2: `When HP is above 80%, increase own Arts by 2/s.`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Vanish",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_106661.png",
                skill: "50s CT - 9200% Dark DMG (PHY). For 10s, increase Allies' Skill CT REC by 30%.",
                skillbreak: 5500,
                passive: 
                    {
                        ability1: "When equipped by Duke, increase DMG by 30% and Skill CT REC by 50%.",
                        ability2: "Increase DMG to Enemies in Break by 20%."
                    }
            },
        lore: 
            {   
                evo5: "The leader of the Leone Templars. Under Princess Juno, he and his sworn friend Vox from their mercenary period founded the Kingdom of Granadia. After the death of Princess Juno, he protected Granadia and the young Princess Leone, fighting with good results in the Famelle Defensive War. Although he is usually quiet, his inner fighting spirit is passionate, and thanks to his bravery, he is of strong support for his team.",
                evoawk: "He is the leader of the Leone Templars that protect Granadia and a sturdy soldier who fights the enemy in front of him without hesitation. During the great invasion of Granadia by the Gods Army, he confronted the Invincible Shield of the Divine Knights and defeated her. The invasion of the royal capital closes in a spectacular way. The truth he once heard from Juno about the armor entrusted to him on the battlefield will now change his destiny and those of all the Templars."
            }
    },
    { //Melia (Water) - Complete
        id: 102536212,
        name: "Melia (Water)",
        rank: "B-",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102535212.png",
                detailawk: "/db/Units/Detail/unit_detail_102536212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102535212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102536212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6541,
                hpplus: 991,
                atk: 2658,
                atkplus: 408,
                def: 2446,
                defplus: 376
            },
        skillset: 
            {
                skill: "7s CT - 1900% Water DMG (PHY). 20% chance to inflict Freeze for 3s.",
                skillbreak: 800,
                arts: "15000% Water DMG (PHY). During Arts, stop Enemy actions + For 10s, reduce Enemies' Water RES and Freeze RES by 30%.",
                artsbreak: 2100,
                truearts: "31200% Water DMG (PHY). For 11s, increase Allies' Critical Rate by 100% and increase Water Allies' DMG by 60%.",
                trueartsbreak: 4000
            },
        passive: 
            {
                ability1: "Increase DMG to Fire Enemies by 35%.",
                ability2: "Increase Burn RES by 70%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                    mat3amt: 2,
                    hover3: "Geo-Zagnus",
                    mat4: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat4amt: 1,
                    hover4: "Oldeus",
                    mat5: "/db/Icons/dungeon_drops/fragAerugraxes.png",
                    mat5amt: 2,
                    hover5: "Aerugraxes"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Maelstrom",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_105911.png",
                skill: "45s CT - 7800% Water DMG (PHY). If user is Water, increase all Allies' Status RES (Except Faint) by 100% for 12s.",
                skillbreak: 1500,
                passive: 
                    {
                        ability1: "When equipped by Aqua Melia, increase DMG and Skill CT REC by 30%. Does not stack with Abilities of the same name.",
                        ability2: "Increase evasion by 10%."
                    }
            },
        lore: 
            {   
                evo5: "A different version of Melia, vice-commander of the 3rd Magus Division of the Empire of La Riiz. Her training in the Cult taught her to blend into any situation, vital skill for her intelligence gathering work. She can change not only her looks but her voice as well, making it nearly impossible to see through her disguises. Her fighting technique is top-level, and her reputation as a sorcerer allowed her to surprise numberless enemies with unexpected speed and physical skill.",
                evoawk: "An assassin of the Cult of La Riiz, feared as the 'Illusory Twin-blades Assassin'. Few know that her true identity is Melia, the Blue-eyed Black Fox. Her vast arsenal of assassin skills allows her to overwhelm the demons without using magic, and the incredible speed she shows with her twin blades has earned her a masterful reputation within the Cult. She was once a mere tool for the Cult, yet now she has found her own path and has given herself to fighting to save others."
            }
    },
    { //Celia (Dark) - Complete
        id: 102546512,
        name: "Celia (Dark)",
        rank: "B+",
        twrequire: "favorable",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102545512.png",
                detailawk: "/db/Units/Detail/unit_detail_102546512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102545512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102546512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 5,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7090,
                hpplus: 999,
                atk: 2871,
                atkplus: 441,
                def: 2205,
                defplus: 340
            },
        skillset: 
            {
                skill: "8s CT - 2000% Dark DMG (PHY). Reduce Enemies' Arts by 5 + Increase own Arts by 5 for each Enemy hit.",
                skillbreak: 300,
                arts: "18000% Dark DMG (MAG). For 15s, increase Allies' Arts DMG by 50%.",
                artsbreak: 1400,
                truearts: "43000% Dark DMG (PHY). For 10s, increase Allies' Arts by 3/s. When Ancient Blood Count is 5: 43000% Dark DMG (PHY). For 20s, increase Allies' Arts by 6/s.",
                trueartsbreak: 2500
            },
        passive: 
            {
                ability1: "Gain an Ancient Blood Counter that gains Count whenever own Arts is activated + Increase ATK by 20% for each Blood of the Ancients Count (max. 100% ATK increase)) + When Blood of the Ancients Counter is 5, increase DMG and Critical Rate by 100%.",
                ability2: "Increase Critical DMG by 30%.",
                ability3: "Increase DMG to Enemies in Break by 30%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 2,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat4amt: 2,
                    hover4: "Flogassa",
                    mat5: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat5amt: 1,
                    hover5: "Radachne"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Creation",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_106961.png",
                skill: "40s CT - 8000% Dark DMG (PHY). For 8s, increase own MAG DMG by 30%, Arts by 3/s.",
                skillbreak: 2000,
                passive: 
                    {
                        ability1: "When equipped by Dark Celia, increase stats by 30%, Status RES (except Faint) by 50%."
                    }
            },
        lore: 
            {   
                evo5: "The ruler of the Republic of Bamint, doused in the power of darkness. Celia had lost all hope for her nation, which lead her to deciding to become a dictator with absolute authority. Knowing she had to use any needs necessary in order to bring permanent peace, she stepped into the darkness and began down her path toward military rule.",
                evoawk: "The ruler of the Republic of Bamint who awakened to the ancient blood flowing within her. Seeing the world filled with those that ignore everything just, her pride turned into despair. She knew she must become a dictator with absolute authority in order to correct the state of the world. Taking her sword into her hand, she disposed of anyone deemed unjust. She sought even greater strength, and eventually came into the possession of power that could even surpass the Gods, both good and evil."
            }
    },
    { //Vitz - Complete
        id: 102556211,
        name: "Vitz",
        luckrank: "F",
        rank: "F",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102555211.png",
                detailawk: "/db/Units/Detail/unit_detail_102556211.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102555211.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102556211.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6466,
                hpplus: 979,
                atk: 2443,
                atkplus: 376,
                def: 2257,
                defplus: 348
            },
        skillset: 
            {
                skill: "8s CT - 1300% Water DMG (MAG). For 4s, increase Allies' Fire RES by 10%.",
                skillbreak: 600,
                arts: "14000% Water DMG (MAG). 70% chance to inflict Freeze for 4s. If Enemy was Frozen by this Arts, for 10s, reduce their MAG RES by 35%.",
                artsbreak: 1500,
                truearts: "33000% Water DMG (MAG). For 10s, increase Allies' Arts by 2/s, increase DMG to Fire Enemies by 50%.",
                trueartsbreak: 2700
            },
        passive: 
            {
                ability1: "When near-death, increase Arts by 1/s.",
                ability2: "Increase DMG to Enemies with 30% HP or less by 20%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                    mat3amt: 3,
                    hover3: "Geo-Zagnus",
                    mat4: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat4amt: 2,
                    hover4: "Tonetaurus",
                    mat5: "/db/Icons/dungeon_drops/fragAerugraxes.png",
                    mat5amt: 3,
                    hover5: "Aerugraxes"
                }
            },
        lore: 
            {   
                evo5: "A Human warrior who became famous during the first part of the Ancient War. under the guidance of his mother, an expert in sorcery, he grew a natural talent for fighting technique and ice magic. His skill is so advanced they say there is no sorcerer comparable to him in all of Raktehelm. He grew up on a journey and begun to refine his powers from an early age to get revenge on his mother's bitter enemy, a Dragon woman. Eventually, he defeated her alongside his family and fulfilled his desire.",
                evoawk: "During the deadly battle with his arch-enemy, his kind mother fell on her kneew. In such a desperate situation, he made his decision to save his family. Calling his brother's name, he unleashed his best shot and abandoned himself to his fate. There was no hesitation in his eyes. He entrusted everything to his brother and was clad by the coldness of his Icicle Spear. This was the moment when his power awoken and his name as a sorcerer became legendary."
            }
    },
    { //Maquiness - Complete
        id: 102566312,
        name: "Maquiness",
        rank: "F",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102565312.png",
                detailawk: "/db/Units/Detail/unit_detail_102566312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102565312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102566312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6873,
                hpplus: 999,
                atk: 1957,
                atkplus: 303,
                def: 2675,
                defplus: 411
            },
        skillset: 
            {
                skill: "8s CT - Increase own Arts by 10.",
                skillbreak: 0,
                arts: "For 15s, increase Allies' Equipment CT REC by 25% and Arts by 2/s.",
                artsbreak: 0,
                truearts: "Heal the Ally with the lowest current HP by 100% of their max HP + For 15s, increase Allies' Water RES by 25%, heal Allies' HP by 350/s.",
                trueartsbreak: 0
            },
        passive: 
            {
                ability1: "Increase Equipment CT REC by 10%.",
                ability2: "Increase Paralysis RES by 100%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat3amt: 1,
                    hover3: "Tonetaurus",
                    mat4: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat4amt: 1,
                    hover4: "Goldola",
                }
            },
        lore: 
            {   
                evo5: "She was a Human sorceress said to have been a match for the Dragon champion called the War God. Though she went missing in the aftermath of a huge battle, her daughter, also a talented magician, revealed the Dragons' had turned her into a young girl with a secret potion. However, she did not let that faze her. Continuing to raise her adopted war orphans, she went on a journey to punish the one responsible. She succeeded, but her youthful form remained.",
                evoawk: "Her daughter told of when the two Dragon brothers, who went on to become reliable allies in battle, first joined the family. They approached them at dinner time after being ejected from their army for their ineptitude. Regarding them with narrowed eyes at first, she welcomed them with a smile to show her children the power of forgiveness. Her travels ended when she founded a nation, and history remembered her as a symbol of affection."
            }
    },
    { //Eternia - Complete
        id: 102576412,
        name: "Eternia",
        rank: "F",
        attribute: "Light",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102575412.png",
                detailawk: "/db/Units/Detail/unit_detail_102576412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102575412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102576412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7566,
                hpplus: 999,
                atk: 2676,
                atkplus: 411,
                def: 2342,
                defplus: 361
            },
        skillset: 
            {
                skill: "6s CT - 1700% Light DMG (PHY). For 4s, reduce Enemies' Paralysis RES by 15%.",
                skillbreak: 700,
                arts: "18000% Light DMG (PHY). For 12s, increase accuracy by 50%.",
                artsbreak: 2000,
                truearts: "32000% Light DMG (PHY). 100% chance to inflict Paralysis for 5s. For 15s, increase Allies' PHY DMG by 50%, increase Light Allies' ATK by 20%.",
                trueartsbreak: 3600
            },
        passive: 
            {
                ability1: "Whenever this unit takes damage more than 10% of its HP, increase Arts by 10.",
                ability2: "Increase DMG to Giant Bosses by 20%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat3amt: 2,
                    hover3: "Radachne",
                    mat4: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat4amt: 1,
                    hover4: "Palamicia",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Ebony Radiance & Ivory Tempest",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_105941.png",
                skill: "40s CT - 9000% Light DMG (PHY). For 12s, reduce Enemies' Light RES by 35%.",
                skillbreak: 1500,
                passive: 
                    {
                        ability1: "When equipped by Eternia, increase max HP by 30% and DMG to Giant Bosses by 35%. Does not stack with Abilities of the same name.",
                        ability2: "Increase Skill CT REC by 25%."
                    }
            },
        lore: 
            {   
                evo5: "A warrior born to a consul of the Gods. Despite her high position, she hated the exclusionary attitude of her race and endeavored to change it. Her strength and talent in combat matched even the Dragons, and legends spoke of how her flashing swords bathed the battlefield in the glorious light of dawn.",
                evoawk: "A neighboring country of Humans faced crisis, but her father refused to offer aid. Enraged, she came to the realization that only personal action would guarantee change, so she left on a journey to help those in need. Her first accomplishment was joining the cause of a warrior family in battling Dragons, and she felt a true sense of purpose both during and after the feat. It also gave assurance the brighter future she created would vindicate her."
            }
    },
    { //Kurt - Missing Lore (JP)
        id: 102586112,
        name: "Kurt",
        attribute: "Fire",
        type: "Beast",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102585112.png",
                detailawk: "/db/Units/Detail/unit_detail_102586112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102585112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102586112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6544,
                hpplus: 991,
                atk: 2587,
                atkplus: 398,
                def: 2195,
                defplus: 339
            },
        skillset: 
            {
                skill: "8s CT - 1900% Fire DMG (PHY).",
                skillbreak: 1500,
                arts: "15000% Fire DMG (PHY). For 15s, increase Allies' accuracy by 25%, Allies ignore 50% of Enemy DEF.",
                artsbreak: 2800,
                truearts: "32000% Fire DMG (PHY). During own True Arts, increase own accuracy by 100%. If own HP is over 90%, increase DMG of that True Arts by 75% + For 20s, increase own Arts by 5/s.",
                trueartsbreak: 4500
            },
        passive: 
            {
                ability1: "Increase accuracy by 30%.",
                ability2: "Ignore 30% of Enemies' DEF."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat3amt: 2,
                    hover3: "Ifrit",
                    mat4: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat4amt: 2,
                    hover4: "Flogassa",
                }
            },
        lore: 
            {   
                evo5: "Currently unreleased in Global.",
                evoawk: "Currently unreleased in Global."
            }
    },
    { //Aesis - Complete
        id: 102596312,
        name: "Aesis",
        luckrank: "C",
        rank: "B-",
        attribute: "Earth",
        type: "Dragon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102595312.png",
                detailawk: "/db/Units/Detail/unit_detail_102596312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102595312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102596312.png"
            },
        slots:
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6877,
                hpplus: 999,
                atk: 2881,
                atkplus: 442,
                def: 2500,
                defplus: 330
            },
        skillset: 
            {
                skill: "16s CT - 4000% Earth DMG (PHY). Increase own Arts by 10.",
                skillbreak: 600,
                arts: "13300% Earth DMG (PHY). 60% chance to inflict Disease for 10s. For 10s, reduce Enemies' PHY RES by 30%.",
                artsbreak: 1100,
                truearts: "30000% Earth DMG (PHY). 90% chance to inflict Poison for 20s. For 15s, increase Allies' Earth DMG by 50%.",
                trueartsbreak: 2000
            },
        passive: 
            {
                ability1: "Increase Critical DMG by 40%.",
                ability2: "Increase Critical Rate by 100% against Poisoned Enemies."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat3amt: 4,
                    hover3: "Goldola",
                    mat4: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat4amt: 3,
                    hover4: "Elpneumas",
                    mat5: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat5amt: 2,
                    hover5: "Mavroskia"
                }
            },
        lore: 
            {   
                evo5: "A Dragon warrior who led an army of mixed races during the early Ancient War. She was feared for her lack of mercy on the battlefield, but she always stuck to her beliefs and never betrayed her allies, leading many to admire to admire her. Although her unit was famously known as the Indomitable Legion of Dragons, she once left tens of thousands of soldiers stranded during a critical battle. According to one theory, she may have done this to avoid getting her comrade in arms--a mage with a young child--mixed up in the chaos. However, no one knows the truth.",
                evoawk: "A Dragon warrior spoken of as a goddess on the battlefield. Aesis was filled with regret for going against her own dignity and betraying her allies. Even if it had been to protect a friend from a reckless battle, she had still tricked a comrade in arms into leaving her unit. She had been prepared for her friend's anger when they reunited, but her friend, who understood the situation, readily forgave her and said, \"Thanks to you, I could protect my family.\" When Aesis heard those words, she felt years of unease disappear."
            }
    },
    { //Luda - Complete
        id: 102606312,
        name: "Luda",
        rank: "F",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102605312.png",
                detailawk: "/db/Units/Detail/unit_detail_102606312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102605312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102606312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6221,
                hpplus: 943,
                atk: 2415,
                atkplus: 372,
                def: 2091,
                defplus: 323
            },
        skillset: 
            {
                skill: "10s CT - 1600% Earth DMG (PHY). Permanently increase own ATK by 10% (up to 100%).",
                skillbreak: 1400,
                arts: "12000% Earth DMG (PHY). Increase own Arts by 100 against Enemies in Break.",
                artsbreak: 2200,
                truearts: "32000% Earth DMG (PHY). Increase Water and Earth Allies' Arts by 15 + For 12s, increase Water and Earth Allies' ATK and DEF by 50%.",
                trueartsbreak: 3900
            },
        passive: 
            {
                ability1: "Increase DMG to Giant Bosses by 30%.",
                ability2: "Increase DMG to Enemies in Break by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat3amt: 2,
                    hover3: "Elpneumas",
                }
            },
        lore: 
            {   
                evo5: "A human swordsman who easily handles a sword as large as his own body. Together with a Spirit fortune teller, he destroyed the seal of a grimoire created by a Demon and became a hero that saved numerous lives. However, he often fell in love with women he met during his journey and was object of gossip. His freewheeling personality which leads him to continue travelling is also noticeable in his sword skills, and even swordmasters had a hard time to deal with his swordsmanship.",
                evoawk: "Not only a swordmaster but also a hero who saved many lives with his free sword skills. After he destroyed the seal of a grimoire with a Spirit fortune teller,he set out on a journey with his allies. Trough his long journey, his sword skills were improved, and he became a famous great swordmaster who could even slash a dragon in one blow. The legend of his great sword and dragon are passed down until today."
            }
    },
    { //Lygor - Complete
        id: 102616512,
        name: "Lygor",
        rank: "C",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102615512.png",
                detailawk: "/db/Units/Detail/unit_detail_102616512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102615512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102616512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6232,
                hpplus: 944,
                atk: 2911,
                atkplus: 446,
                def: 2208,
                defplus: 341
            },
        skillset: 
            {
                skill: "10s CT - 2200% Dark DMG (MAG). For 12s, reduce Enemies' DEF by 10%.",
                skillbreak: 1300,
                arts: "15000% Dark DMG (MAG). For 15s, reduce Enemies' ATK by 50%, increase Allies' ATK by 50%.",
                artsbreak: 2300,
                truearts: "30000% Dark DMG (MAG). For 15s, reduce Enemies' Dark RES by 30% + For 10s, increase Dark Allies' Arts by 4/s.",
                trueartsbreak: 4100
            },
        passive: 
            {
                ability1: "Increase Paralysis RES by 70%.",
                ability2: "Increase Arts by 2/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 1,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat4amt: 2,
                    hover4: "Mavroskia",
                }
            },
        lore: 
            {   
                evo5: "A mage who is loyal to his own desires. He used to trap people within another dimension he created in sorcerous tomes,and used their negative emotions as the source of his magic. He was able to transfer that power to others, which led to people joining him just to have a chance of getting their hands on his magic. He was used to getting all the power he wanted without ever trying, but that sweet life was cut short when he met a certain Spirit fortune-teller.",
                evoawk: "There are a plethora of documents describing how Lygor continued to do nasty things as a mage all across the land, even after he lost the power to drain negative emotions as magic from his seperate dimension by one of his former prisoners. He is spoken even in modern day as a legendary mage who brought misfortune wherever he went. It's unknown how he was unable to restore his powers, but his name remains in the records of a laboratory that is said to have been visited by those who had lost their magic. It can be assumed that there was an accomplice that assisted in his reawakening."
            }
    },
    { //Tia - Missing Lore (JP)
        id: 102626212,
        name: "Tia",
        attribute: "Water",
        type: "Spirit",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102625212.png",
                detailawk: "/db/Units/Detail/unit_detail_102626212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102625212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102626212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7255,
                hpplus: 999,
                atk: 2561,
                atkplus: 394,
                def: 2297,
                defplus: 354
            },
        skillset: 
            {
                skill: "10s CT - Increase own Arts by 20 + For 6s, increase Allies' MAG RES by 20%.",
                skillbreak: 0,
                arts: "15000% Water DMG (MAG). For 10s, reduce Enemies' MAG RES by 40%.",
                artsbreak: 1700,
                truearts: "31000% Water DMG (MAG). 40% chance to inflict Freeze for 3s. If Enemy was Frozen by own True Arts or was already Frozen when own True Arts was activated, increase own Arts by 200 (can occur up to 3 times consecutively).",
                trueartsbreak: 3200
            },
        passive: 
            {
                ability1: "Increase Critical Rate by 20%.",
                ability2: "Increase Fire RES by 20%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat3amt: 1,
                    hover3: "Palamicia",
                    mat4: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat4amt: 2,
                    hover4: "Freydal",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Feronica",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_106301.png",
                skill: "60s CT - 7500% Water DMG (MAG). For 10s, increase own MAG DMG by 30% and reduce Enemies' Freeze RES by 30%.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Tia, increase DMG by 20% and DMG to Fire Enemies by 20%. Does not stack with Abilities of the same name.",
                        ability2: "Increase Fire RES by 15%."
                    }
            },
        lore: 
            {   
                evo5: "Currently unreleased in Global.",
                evoawk: "Currently unreleased in Global."
            }
    },
    { //Shasha - Complete
        id: 102636412,
        name: "Shasha",
        rank: "F",
        attribute: "Light",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102635412.png",
                detailawk: "/db/Units/Detail/unit_detail_102636412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102635412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102636412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6012,
                hpplus: 911,
                atk: 2155,
                atkplus: 333,
                def: 2440,
                defplus: 376
            },
        skillset: 
            {
                skill: "8s CT - For 6s, heal Allies' HP by 50/s and increase Allies' Arts by 1/s.",
                skillbreak: 0,
                arts: "Cure Allies' Poison and Burn + For 12s, increase Allies' DMG and BE generation by 80%.",
                artsbreak: 0,
                truearts: "30000% Light DMG (MAG). For 15s, reduce Allies and Enemies' Status RES by 70%.",
                trueartsbreak: 4000
            },
        passive: 
            {
                ability1: "Increase MAG RES by 15%.",
                ability2: "Increase Dark RES by 20%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 2,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 3,
                    hover4: "Radachne",
                }
            },
        lore: 
            {   
                evo5: "A Demon infamous for sealing away people with her interdimensional tome. In contrast to her casual tone and appearance, she constantly worked to improve her talents. This diligence let her master all kinds of magic and formulate spells used for years to come. However, she experimented purely to satisfy her own curiosity, even if it carried her across moral boundaries. She started trapping souls inside her tome for no reason other than to prove the possibility.",
                evoawk: "She was shocked when Luda and Tear surpassed her expertly calculated predictions. Their incredible strength of will in the face of crisis piqued her interest, and she decided to join their journey. Her talent and inquisitiveness hindered as much as it helped, even casting them across the dimensional rift at times, yet those very ordeals would make for a series of thrilling adventure tales."
            }
    },
    { //Kush - Missing Lore (JP)
        id: 102646412,
        name: "Kush",
        attribute: "Light",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102645412.png",
                detailawk: "/db/Units/Detail/unit_detail_102646412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102645412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102646412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7155,
                hpplus: 999,
                atk: 2055,
                atkplus: 318,
                def: 2473,
                defplus: 380
            },
        skillset: 
            {
                skill: "9s CT - 1800% Light DMG (MAG). Increase own Arts by 7.",
                skillbreak: 1100,
                arts: "17000% Light DMG (MAG). For 15s, increase Allies' DMG by 50%.",
                artsbreak: 2300,
                truearts: "35000% Light DMG (MAG). The higher the Boss' Arts, the more DMG own True Arts deals (up to 140%). Increase Light Allies' Arts by 20.",
                trueartsbreak: 5000
            },
        passive: 
            {
                ability1: "Ignore 30% of Enemies' DEF.",
                ability2: "Increase Allies' DMG to Enemies in Break by 30%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 1,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 2,
                    hover4: "Radachne",
                    mat5: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat5amt: 1,
                    hover5: "Mavroskia"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Innocent Noah",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_106221.png",
                skill: "55s CT - 8800% Light DMG (MAG). For 10s, increase Allies' ATK and DEF by 50%.",
                skillbreak: 1500,
                passive: 
                    {
                        ability1: "When equipped by Kush, increase ATK and DMG by 25%. Does not stack with Abilities of the same name.",
                        ability2: "Increase ATK by 15%."
                    }
            },
        lore: 
            {   
                evo5: "Currently unreleased in Global.",
                evoawk: "Currently unreleased in Global."
            }
    },
    { //Rebol - Missing Lore (JP)
        id: 102656512,
        name: "Rebol",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102655512.png",
                detailawk: "/db/Units/Detail/unit_detail_102656512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102655512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102656512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6975,
                hpplus: 999,
                atk: 2641,
                atkplus: 406,
                def: 2382,
                defplus: 367
            },
        skillset: 
            {
                skill: "10s CT - 3200% Dark DMG (PHY).",
                skillbreak: 1000,
                arts: "15000% Dark DMG (PHY). Increase own Arts by 10 each time own Arts crits.",
                artsbreak: 1800,
                truearts: "36000% Dark DMG (PHY). For 12s, reduce Enemies' PHY RES by 50%.",
                trueartsbreak: 4000
            },
        passive: 
            {
                ability1: "Increase Critical Rate by 10%.",
                ability2: "Increase own Arts by 1/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 1,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragValzandeth.png",
                    mat4amt: 3,
                    hover4: "Valzandeth",
                    mat5: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                    mat5amt: 2,
                    hover5: "Degilmagna"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Gran Killer",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_106231.png",
                skill: "50s CT - 7500% Dark DMG (PHY). Increase own Critical rate by 50% for 10s. If user is Demon, increase own CRI rate by 80% instead.",
                skillbreak: 900,
                passive: 
                    {
                        ability1: "When equipped by Rebol, increase Critical DMG by 30% and increase own Arts by 1/s. Does not stack with Abilities of the same name.",
                        ability2: "Increase Skill CT REC by 20%."
                    }
            },
        lore: 
            {   
                evo5: "Currently unreleased in Global.",
                evoawk: "Currently unreleased in Global."
            }
    },
    { //Swordsman Berwick - Complete (+ JP ver.)
        id: 102666112,
        name: "Swordsman Berwick",
        rank: "B+",
        attribute: "Fire",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102665112.png",
                detailawk: "/db/Units/Detail/unit_detail_102666112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102665112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102666112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7016,
                hpplus: 999,
                atk: 2915,
                atkplus: 447,
                def: 2188,
                defplus: 338
            },
        skillset: 
            {
                skill: "8s CT - 1600% Fire DMG (PHY). Increase own Arts by 20. During Magia Drive: 1600% Fire DMG (PHY). Increase own Arts by 40.",
                skillbreak: 700,
                arts: "12000% Fire DMG (PHY). For 10s, increase Allies' Arts by 3/s.",
                artsbreak: 1800,
                truearts: "For 180s, enter Magia Drive. During Magia Drive: 40000% Fire DMG (PHY). For 10s, increase Allies' Max HP by 20% amd PHY DMG by 100%.",
                trueartsbreak: 3500
            },
        skillsetJP: 
            {
                skill: "8s CT - 3300% Fire DMG (PHY). Increase own Arts by 20. During Magia Drive: 3300% Fire DMG (PHY). Increase own Arts by 40.",
                skillbreak: 700,
                arts: "18000% Fire DMG (PHY). For 15s, increase Allies' Arts by 3/s.",
                artsbreak: 1800,
                truearts: "For 180s, enter Magia Drive. During Magia Drive: 75000% Fire DMG (PHY). For 20s, increase Allies' Max HP by 30% and PHY DMG by 170%.",
                trueartsbreak: 3500
            },
        passive: 
            {
                ability1: "When near-death, increase Skill CT REC by 50%.",
                ability2: "Increase Water RES by 25%.",
                ability3: "During Magia Drive, increase Skill CT REC and Skill DMG by 100%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat3amt: 1,
                    hover3: "Ifrit",
                    mat4: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat4amt: 2,
                    hover4: "Flogassa",
                    mat5: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat5amt: 2,
                    hover5: "Wilkes"
                }
            },
        dreamJP: {
            fire: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                    mat2amt: 50,
                    hover2: `Flametail Soulgem`,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 2,
                    hover3: "Flogassa",
                },
                passive: {
                    ability1: `Increase own Status Ailment RES by 50% (except Faint).`,
                    ability2: `Increase all Fire Allies' Arts by 2/s. Does not stack with Abilities of the same name.`
                }
            },
            earth: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                    mat2amt: 50,
                    hover2: `Smogdragon Soulgem`,
                    mat3: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat3amt: 2,
                    hover3: "Goldola",
                },
                passive: {
                    ability1: `Increase own DMG against Gods by 100%.`,
                    ability2: `Increase own Equipment CT Speed by 15%.`
                }
            }
        },
        trueweapon: //If no TW, may delete this section
            {
                name: "ZANKETSU",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_106071.png",
                skill: "35s CT - 12000% Water DMG (PHY). For 10s, increase own Skill CT REC by 50%.",
                skillbreak: 2000,
                passive: 
                    {
                        ability1: "When equipped by Vox, increase stats and Critical Rate by 20%, and evasion by 10%. Does not stack with Abilities of the same name.",
                        ability2: "When equipped by Swordsman Berwick, increase DMG by 30%. At the start of a Battle, increase own Arts by 30. Does not stack with Abilities of the same name.",
                        ability3: "Increase BE generation by 15%."
                    }
            },
        lore: 
            {   
                evo5: "The young swordsman of the Leone Templars, once feared as a powerful sorcerer called \"The Crimson Marksman\". However, when under attack from the Gods Army, he used sorcery that broke through his spiritual limits to save his comrades, and he lost his powers. Having lost power and confidence, he wandered wasted until and encounter with his old commander's beloved sword Zanketsu reawakened his lost passion. Beneath the castle of Granadia, he held a sword for the first time in his life.",
                evoawk: "The blazing-fast swordsman of the Leone Templars. A former sorcerer, he began following the path of the sword when he inherited Zanketsu, the beloved blade of his former captain, who had perished on the battlefield. He had long studied the sword techniques used by his old captain, and this imitation helped him become dizzying-fast. His skills with the blade are lightning-quick, but he takes no pride in them; he simply continues to follow his former leader's footsteps."
            }
    },
    { //Rosetta (Water) - Complete + JP ver
        id: 102676212,
        name: "Rosetta (Water)",
        rank: "A+",
        twrequire: "favorable",
        attribute: "Water",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102675212.png",
                detailawk: "/db/Units/Detail/unit_detail_102676212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102675212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102676212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7453,
                hpplus: 999,
                atk: 2388,
                atkplus: 368,
                def: 2311,
                defplus: 356
            },
        skillset: 
            {
                skill: "10s CT - 1400% Water DMG (MAG). For 5s, reduce Enemies' evasion by 10%.",
                skillbreak: 700,
                arts: "8000% Water DMG (MAG). 50% chance to inflict Paralysis for 3s. Additionally when used during Magia Drive, increase the duration of own Magia Drive by 30s.",
                artsbreak: 1700,
                truearts: "For 50s, enter Magia Drive. During Magia Drive: 30000% Water DMG (MAG). For 15s, reduce Enemies' Water RES and MAG RES by 35%. For 25s, increase Allies' (except self) Arts by 4/s.",
                trueartsbreak: 2800
            },
        skillsetJP: 
            {
                skill: "10s CT - 1400% Water DMG (MAG). For 5s, reduce Enemies' evasion by 10%.",
                skillbreak: 700,
                arts: "8000% Water DMG (MAG). 70% chance to inflict Paralysis for 3s. Additionally when used during Magia Drive, increase the duration of own Magia Drive by 30s.",
                artsbreak: 1700,
                truearts: "For 50s, enter Magia Drive. During Magia Drive: 55000% Water DMG (MAG). For 25s, reduce Enemies' Water RES and MAG RES by 45% (stackable) and increase Allies' (except self) Arts by 4/s.",
                trueartsbreak: 2800
            },
        passive: 
            {
                ability1: "During Magia Drive, increase MAG DMG by 20% and increase Arts by 10/s.",
                ability2: "Increase accuracy by 10%.",
                ability3: "Increase Seal RES by 30%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 2,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat4amt: 1,
                    hover4: "Elpneumas",
                    mat5: "/db/Icons/dungeon_drops/fragAerugraxes.png",
                    mat5amt: 2,
                    hover5: "Aerugraxes"
                }
            },
            dreamJP: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                        mat3amt: 2,
                        hover3: "Ifrit",
                    },
                    passive: {
                        ability1: `Increase all allies' Accuracy by 30%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase Demon allies' Arts by 2/s. Does not stack with Abilities of the same name.`
                    }
                },
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                        mat3amt: 2,
                        hover3: "Palamicia",
                    },
                    passive: {
                        ability1: `Each time Arts is used during Magia Drive, increase Magia Drive's duration by 60s instead.`,
                        ability2: `Increase Water allies' Arts by 2/s. Does not stack with Abilities of the same name.  `
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Asterisk",
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_106351.png",
                skill: "60s CT - Increase own Arts by 20, Increase Allies' Arts by 20 + For 10s, increase Allies' Arts by 2/s.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Rosetta (Water), increase Arts by 1/s. Increase Magia Drive duration by 20s. Increase Arts by 50 when entering Magia Drive. Does not stack with Abilities of the same name.",
                        ability2: "Increase MAG DMG by 20%."
                    }
            },
        lore: 
            {   
                evo5: "The self-proclaimed \"genius\" sorcerer of the Leone Templars. A master of Crest magic, who can achieve incredible results by drawing ancient symbols in the air. As a gifted child, she received special education, which enabled her to become the youngest student to ever graduate the Sorcerer Academy. Together with her various research results, this earned her attention from those around her. Many saw great things in her future and imagined her pursuing her research, but she decided to join the Templars to be reunited with her school friend Princess Leone.",
                evoawk: "A genius sorcerer, and pride of the Leone Templars. Her success at deciphering and using the previously incomprehensible tomes of Advanced Crest Magic earned her the nickname “Heavenly Witch”. After reuniting with Princess Leone by joining the Templars, she was both overjoyed that her friend became queen and sad for the distance. Yet, when she sensed the princess’s loneliness during the god’s attack on the sky ark, she resolved to support Leone as a Templar and as a friend."
            }
    },
    { //Leone - Complete (+ JP ver.)
        id: 102686412,
        name: "Leone",
        rank: "F",
        twrequire: "favorable",
        attribute: "Light",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102685412.png",
                detailawk: "/db/Units/Detail/unit_detail_102686412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102685412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102686412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6912,
                hpplus: 999,
                atk: 2723,
                atkplus: 418,
                def: 2669,
                defplus: 410
            },
        skillset: 
            {
                skill: "9s CT - 1300% Light DMG (MAG). Heal own HP by 3% of DMG dealt by this Skill.",
                skillbreak: 700,
                arts: "19700% Light DMG (MAG). Consume 15% of own HP + For 20s, increase own Critical DMG by 40%.",
                artsbreak: 2500,
                truearts: "For 120s, enter Demonic True Eclipse. During Demonic True Eclipse: 37700% Dark DMG (MAG). Consume 25% of own HP.",
                trueartsbreak: 4000
            },
        skillsetJP: 
            {
                skill: "9s CT - 2200% Light DMG (MAG). Heal own HP by 3% of DMG dealt by this Skill.",
                skillbreak: 700,
                arts: "27000% Light DMG (MAG). Consume 15% of own HP + For 30s, increase own Critical DMG by 200%.",
                artsbreak: 2500,
                truearts: "For 120s, enter Demonic True Eclipse. During Demonic True Eclipse: 90000% Dark DMG (MAG). Consume 25% of own HP.",
                trueartsbreak: 4000
            },
        passive: 
            {
                ability1: "Increase MAG RES by 20%.",
                ability2: "During Demonic True Eclipse, prevent falling below 200 Arts. When this unit takes lethal DMG, end Demonic True Eclipse, set HP to 1 HP and Arts to 0, and gain invulnerability for 1s."
            },
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat3amt: 2,
                    hover3: "Mavroskia",
                    mat4: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat4amt: 2,
                    hover4: "Wilkes",
                }
            },
        dreamJP: {
            light: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                    mat2amt: 50,
                    hover2: `Eternaglint Soulgem`,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 2,
                    hover3: "Fosradius",
                },
                passive: {
                    ability1: `Increase own Equipment CT Speed by 10%.`,
                    ability2: `Increase Demon Allies' Arts by 2/s. Does not stack with Abilities of the same name.`
                }
            },
            dark: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                    mat2amt: 50,
                    hover2: `Sinflow Soulgem`,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 2,
                    hover3: "Ragsherum Phantom",
                },
                passive: {
                    ability1: `Increase own CRI rate by 50%.`,
                    ability2: `Increase all allies' Dark RES by 20%. Does not stack with Abilities of the same name.`
                }
            }
        },
        trueweapon: //If no TW, may delete this section
            {
                name: "EX Gate",
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_106581.png",
                skill: "70s CT - For 15s, increase Target's Arts by 10/s, increase Allies' Critical Rate by 100%.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Leone, increase own DMG by 30% and Healing Taken by 10%. When Demonic True Eclipse is activated, heal HP by 100%. Does not stack with Abilities of the same name.",
                        ability2: "Increase MAG DMG by 20%."
                    }
            },
        lore: 
            {   
                evo5: "The Princess of the tiny nation of Demons called Granadia. She took control of the Templars who defended the kingdom after her sister Juno died. She is a kind-hearted person who loves peace and is admired by people all over the country and served with loyalty by her Templars. After the death of the leader Vox during the Famelle Defensive War, she realized her resolution to protect her country wasn't strong enough, so she decided to join her Templars in battle.",
                evoawk: "The Guardian of Leone Templars and protector of the Kingdom of Granadia. Using the secret of Granadia. \"Magia Drive\" , she can enhance the latent faculties of her Templars, maximizing their powers. This technique utilizes the power of the \"Crimson Blood\" flowing in her family, putting her very body into danger. However, during the bloody battle in the capital with the Gods, she pushed her blood power beyond her limits to save her Templars, without hesitation."
            }
    },
    { //Belle - Complete
        id: 102696312,
        name: "Belle",
        rank: "F",
        attribute: "Earth",
        type: "Spirit",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102695312.png",
                detailawk: "/db/Units/Detail/unit_detail_102696312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102695312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102696312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6858,
                hpplus: 999,
                atk: 2035,
                atkplus: 315,
                def: 2477,
                defplus: 381
            },
        skillset: 
            {
                skill: "10s CT - Increase own Arts by 20. For 5s, heal Allies' HP by 30/s.",
                skillbreak: 0,
                arts: "For 15s, increase Allies' Paralysis RES by 100% and max HP by 20%, increase Earth Allies' max HP by 20%.",
                artsbreak: 0,
                truearts: "30000% Earth DMG (MAG). For 15s, increase Allies' DMG to Water and Light Enemies by 50%.",
                trueartsbreak: 3700
            },
        passive: 
            {
                ability1: "Increase all Allies' max HP by 10%. Does not stack with Abilities of the same name.",
                ability2: "Increase Light RES by 20%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat3amt: 1,
                    hover3: "Elpneumas",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 1,
                    hover4: "Radachne",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Valtrenis",
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_106271.png",
                skill: "30s CT - 100% Earth DMG (MAG). For 10s, reduce Enemies' Earth RES by 20% and increase Allies' DMG to Light Enemies by 50%.",
                skillbreak: 800,
                passive: 
                    {
                        ability1: "When equipped by Belle, increase stats by 20% and increase own Arts by 2/s. Does not stack with Abilities of the same name.",
                        ability2: "Increase DMG to Light Enemies by 15%."
                    }
            },
        lore: 
            {   
                evo5: "A girl who lived in a small village of Spirits, loved for her earnest positivity. Not even a Demon attack could dim her bright attitude, keeping morale high during the rebuilding effort. A traveling magician both helped awaken her magical powers and taught her the importance of coexistence among the races. She found his vision so moving that she decided to set out on a journey to spread it.",
                evoawk: "The various experiences on her journey matured into a splendid wizardess. Her words profoundly affected audiences everywhere she went. Some were so motivated as to depict her while giving an impassioned lecture. When she at last returned home, her divine bearing shocked old acquaintances. Recognizing anew her gratitude for the Spirit God's blessing, she left on another trip, this time secretly hoping to find the one who inspired her."
            }
    },
    { //Honoka - Complete
        id: 102706112,
        name: "Honoka",
        rank: "F",
        attribute: "Fire",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102705112.png",
                detailawk: "/db/Units/Detail/unit_detail_102706112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102705112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102706112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7113,
                hpplus: 999,
                atk: 2605,
                atkplus: 400,
                def: 2369,
                defplus: 365
            },
        skillset: 
            {
                skill: "8s CT - 2000% Fire DMG (PHY). For 5s, decrease Enemies' ATK by 10%.",
                skillbreak: 500,
                arts: "18000% Fire Damage (PHY). For 20s, increase Allies' PHY RES by 20%, Fire Allies' Skill CT REC by 100%.",
                artsbreak: 1200,
                truearts: "40000% Fire Damage (PHY). For 20s, increase Fire Allies' stats by 30% and Status RES (except Faint) by 50%.",
                trueartsbreak: 2300
            },
        passive: 
            {
                ability1: "Increase DMG to Earth Enemies by 10%.",
                ability2: "Increase DMG to Enemies with less than 30% HP by 30%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 2,
                    hover3: "Flogassa",
                    mat4: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat4amt: 1,
                    hover4: "Wilkes",
                }
            },
        lore: 
            {   
                evo5: "A martial artist from the Setsuga clan, which fights evil from the shadows. The young prodigy gained notoriety for her skill with the short sword despite her age. She would appear out of nowhere to cut down evil with her lightning-quick blade and was viewed as a hero by the good people she protected. However, when she discovered the evil born of the Setsuga clan's deeds and came face-to-face with her own naivety, she decided to flee the valley with her taciturn bodyguard. That was the start of her journey to punish evil and learn self-control.",
                evoawk: "While travelling, Honoka was confronted by one of the few Demons left alive after the slaughter by the Setsuga clan. With a malevolent cry, the Demon moved to strike Honoka, who was too stunned by the truth of her clan's actions to raise her sword. Honoka's bodyguard rushed in to protect her and took the Demon's blade in his back. When Honoka saw her bodyguard fulfill his duty right before her, she wiped away her tears and raised her blade again. She had her own duties to fulfill."
            }
    },
    { //Io - Missing Lore (JP)
        id: 102716412,
        name: "Io",
        attribute: "Light",
        type: "Giant",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102715412.png",
                detailawk: "/db/Units/Detail/unit_detail_102716412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102715412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102716412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7159,
                hpplus: 999,
                atk: 2466,
                atkplus: 379,
                def: 2182,
                defplus: 337
            },
        skillset: 
            {
                skill: "6s CT - 2100% Light DMG (PHY).",
                skillbreak: 1000,
                arts: "15800% Light DMG (PHY). Consume 30% of own HP. For 20s, gain a DMG barrier that negates [Amount of HP consumed] DMG, increase Fire and Light Allies' Break PWR by 30%.",
                artsbreak: 2500,
                truearts: "27000% Light DMG (PHY). Increase own Arts by 80, increase this True Arts' DMG based on how low HP is (up to 250%).",
                trueartsbreak: 4600
            },
        passive: 
            {
                ability1: "When near-death, increase own ATK by 50%.",
                ability2: "Increase DMG to Demon Enemies by 50%.",
                ability3: "When near-death, increase own Arts by 2/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 1,
                    hover3: "Fosradius",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 2,
                    hover4: "Code-XTF",
                }
            },
        lore: 
            {   
                evo5: "Currently unreleased in Global.",
                evoawk: "Currently unreleased in Global."
            }
    },
    { //Miri-nyan - Complete
        id: 102736112,
        name: "Miri-nyan",
        luckrank: "F",
        attribute: "Fire",
        type: "Beast",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102735112.png",
                detailawk: "/db/Units/Detail/unit_detail_102736112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102735112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102736112.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6701,
                hpplus: 999,
                atk: 2400,
                atkplus: 670,
                def: 2300,
                defplus: 355
            },
        skillset: 
            {
                skill: "9s CT - 1200% Fire DMG (PHY). For 3s, reduce Enemies' DEF by 20%.",
                skillbreak: 600,
                arts: "9000% Fire DMG (PHY). For 12s, increase Allies' Earth RES by 15%, Freeze and Paralysis RES by 100%.",
                artsbreak: 1100,
                truearts: "30000% Fire DMG (PHY). For 8s, reduce Earth Enemies' PHY RES by 50%.",
                trueartsbreak: 2800
            },
        passive: 
            {
                ability1: "Increase DMG to Earth Enemies by 15%.",
                ability2: "All attacks ignore 20% of Enemy DEF."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 2,
                    hover3: "Flogassa",
                    mat4: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat4amt: 2,
                    hover4: "Wilkes",
                }
            },
        lore: 
            {   
                evo5: "A Beast warrior who lived in a city where monsters were rampant, in the Magus Empire. Although she was young, she had a talent for military arts and fought with her sister to protect the weak from monsters and bandits. The two became popular. Being a shy person, she wished to leave the town and peacefully live with her sister. However, her sister had a strong sense of justice, so she wanted to stay in the city to protect those in need. Their discrepancies will eventually lead to a certain incident...",
                evoawk: "The Magus Empire was looking for a subject who could bear the strong magic of their experiment. Having strong magic resistance, the Beast girl was their perfect victim. Promising to make her wish come true, the man from the Cult deceived her. She was separated from her sister and both were kidnapped. The two became victims of a synthesis experiment. In the depth of despair. a man in black reached out to help her. He had lost his brother because of the Cult and swore revenge…"
            }
    },
    { //Tami-nyan - Complete
        id: 102746212,
        name: "Tami-nyan",
        rank: "B",
        attribute: "Water",
        type: "Beast",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102745212.png",
                detailawk: "/db/Units/Detail/unit_detail_102746212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102745212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102746212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6750,
                hpplus: 999,
                atk: 2950,
                atkplus: 452,
                def: 2800,
                defplus: 430
            },
        skillset: 
            {
                skill: "7s CT - 1800% Water DMG (PHY). For 8s, reduce Enemies' Break RES by 30%.",
                skillbreak: 2000,
                arts: "17000% Water DMG (PHY). For 20s, reduce Enemies' Water and Freeze RES by 20%.",
                artsbreak: 4200,
                truearts: "35000% Water DMG (PHY). 80% chance to inflict Freeze for 10s. For 10s, increase Allies' Break PWR by 50%.",
                trueartsbreak: 8000
            },
        passive: 
            {
                ability1: "When near-death, increase ATK by 10%.",
                ability2: "Increase DMG to Enemies in BREAK by 100%.",
                ability3: "Increase DMG and Break PWR to Frozen Enemies by 100%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                    mat3amt: 1,
                    hover3: "Geo-Zagnus",
                    mat4: "/db/Icons/dungeon_drops/fragAerugraxes.png",
                    mat4amt: 2,
                    hover4: "Aerugraxes"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Azure Offering",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_108451.png",
                skill: "60s CT - 3000% Water DMG (PHY). For 10s, increase own Break PWR by 70%.",
                skillbreak: 3500,
                passive: 
                    {
                        ability1: "When equipped by Tami-nyan, increase Break PWR by 30% and increase DMG to Enemies in BREAK by 30%.",
                        ability2: "Increase BE generation by 10%."
                    }
            },
        lore: 
            {   
                evo5: "A Beast girl who lived in a poor town in the Magus Empire of La Riiz. Gifted in martial arts from childhood, she and her younger sister Miri-nyan were known as the town's protectors, and they even helped curtail the Cult's evil plans. Though it is unclear how their exploits came to an end, tales of them battling in tandem against those who would harm their neighbors continue to spread even now.",
                evoawk: "A Beast girl from La Riiz with a strong sense of justice. When the Cult abducted her sister, she broke into the facility alone to save her. There, she learned it was where they were conducting mad experiments to create weapons for the Ancient War. That they would claim a noble cause while spreading death and pain infuriated her. Gripping her tonfas tight, she fought to bring a brighter future to those imprisoned in the darkness."
            }
    },
    { //Fen (Dark) - Complete
        id: 102756512,
        name: "Fen (Dark)",
        rank: "A",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102755512.png",
                detailawk: "/db/Units/Detail/unit_detail_102756512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102755512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102756512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6260,
                hpplus: 949,
                atk: 2588,
                atkplus: 398,
                def: 2350,
                defplus: 362
            },
        skillset: 
            {
                skill: "10s CT - 2200% Dark DMG (MAG). For 5s, reduce Enemies' Dark RES by 10%.",
                skillbreak: 800,
                arts: "13000% Dark DMG (MAG). Increases DMG of this Arts by magnitude of accuracy buffs on self.",
                artsbreak: 1700,
                truearts: "31000% Dark DMG (MAG). 80% chance to inflict Blind for 10s. Increase Dark Allies' Arts by 20 + For 20s, increase Allies' Arts by 3/s, increase Allies' accuracy by 30%.",
                trueartsbreak: 3300
            },
        passive: 
            {
                ability1: "At the start of a Quest, increase Allies' Arts by 50. Does not stack with Abilities of the same name.",
                ability2: "Increases Critical Rate by 100% against Blinded Enemies."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 2,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 2,
                    hover4: "Code-XTF",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Stratega",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_106411.png",
                skill: "45s CT - 8800% Dark DMG (MAG). For 10s, reduce Enemies' DMG RES by 30%.",
                skillbreak: 1100,
                passive: 
                    {
                        ability1: "When equipped by Fen (Dark), increase Critical DMG by 50%.",
                        ability2: "Increase DMG to Giant Bosses by 20%."
                    }
            },
        lore: 
            {   
                evo5: "Master Strategist of the Gramenian Army. He was summoned alongside his friend to escort Forte to the nation of Garrah. Being planned solely by Kane, the escort mission was classified as top secret, forcing Fen to disguise himself in the local garb. He even chose a gun as his weapon because it was out of line with his image as possible. However, little did Fen know that his choice would open up new doors of possibility for him.",
                evoawk: "Fen grew as a warrior after overcoming battles and peril in peculiar conditions. His gun that was able to shoot both physical and magical bullets was the \"trigger\" that allowed him to do so. He succeeded in safely escorting Forte to her destination, but the mysterious gunman left a strong impact on the Garrah soldiers. Wanting to hire him as a mercenary, they discreetly attempted to research the man, but not a single soul was able to discover the agent of darkness, Fen."
            }
    },
    { //Kane (Dark) - Complete
        id: 102766512,
        name: "Kane (Dark)",
        rank: "C",
        twrequire: "favorable",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102765512.png",
                detailawk: "/db/Units/Detail/unit_detail_102766512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102765512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102766512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6800,
                hpplus: 999,
                atk: 2699,
                atkplus: 414,
                def: 2257,
                defplus: 348
            },
        skillset: 
            {
                skill: "10s CT - 2400% Dark DMG (PHY). For 3s, increase own Evasion by 20%.",
                skillbreak: 700,
                arts: "17700% Dark DMG (PHY). 60% chance to inflict Faint for 3s + For 15s, reduce Enemies' DMG RES by 20%.",
                artsbreak: 1900,
                truearts: "43000% Dark DMG (PHY). Increase Allies' DMG by 15% (max. 4 stacks (60% DMG increase)) + For 20s, increase Dark Allies' ATK by 100%.",
                trueartsbreak: 3800
            },
        passive: 
            {
                ability1: "Increase PHY Evasion by 20%.",
                ability2: "Increase DMG against Enemies in BREAK by 20%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat3amt: 2,
                    hover3: "Palamicia",
                    mat4: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat4amt: 2,
                    hover4: "Oldeus",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "NANASHI",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_106441.png",
                skill: "70s CT - 12500% Dark DMG (PHY). If user is Dark, increase own DMG by 50% for 10s.",
                skillbreak: 1500,
                passive: 
                    {
                        ability1: "When equipped by Kane (Dark), increase DMG by 30% and Arts Gauge by 1/s. Does not stack with Abilities of the same name.",
                        ability2: "Increase Critical Rate by 20%."
                    }
            },
        lore: 
            {   
                evo5: "A high-ranking official of the Republic of Gramenia. When he found out that another nation would be having a peace conference with the country of Garrah, he devised a plan in which he would attend the meeting as a bodyguard. His weapon of choice for the mission: a katana. Fen apparently found this choice odd, as Kane normally used guns, tanks, and other high-tech weaponry. Kane never explained why he chose a katana. In fact, only Ru--who noticed the reading material poking out from his pocket on the way home--learned the reason behind Kane's selection of the blade.",
                evoawk: "Like Fen, Kane was able to open the door to new possibilities in regards to combat at the end of the treacherous journey. \"A genius isn't choosy about his weapon!\" The only reason Fen didn't retort when Kane sang his own praises was most likely because he truly recognized his prowess as a warrior. Kane attempted to carry the sword and use it in his duties as an official as well, but gave up after learning that more people than he expected found out about his role as a bodyguard in the escort mission."
            }
    },
    { //Sybil - Missing Lore (JP)
        id: 102776512,
        name: "Sybil",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102775512.png",
                detailawk: "/db/Units/Detail/unit_detail_102776512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102775512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102776512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7011,
                hpplus: 999,
                atk: 2711,
                atkplus: 416,
                def: 2225,
                defplus: 343
            },
        skillset: 
            {
                skill: "8s CT - 2500% Dark DMG (PHY). 30% chance to inflict Blind for 15s.",
                skillbreak: 1000,
                arts: "20800% Dark DMG (PHY). Increase own Arts by [20 × # of Enemies killed by this Arts].",
                artsbreak: 1900,
                truearts: "36800% Dark DMG (PHY). DMG of own True Arts increases as the amount of HP the Enemy is missing increases (up to 200%). Increase accuracy of this True Arts against Blinded Enemies by 50%.",
                trueartsbreak: 3000
            },
        passive: 
            {
                ability1: "Increase Curse RES by 50%.",
                ability2: "When near-death, increase Arts by 3/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat3amt: 4,
                    hover3: "Oldeus",
                }
            },
        lore: 
            {   
                evo5: "Currently unreleased in Global.",
                evoawk: "Currently unreleased in Global."
            }
    },
    { //Sai - Missing Lore (JP)
        id: 102786212,
        name: "Sai",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102785212.png",
                detailawk: "/db/Units/Detail/unit_detail_102786212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102785212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102786212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6777,
                hpplus: 999,
                atk: 2555,
                atkplus: 393,
                def: 2615,
                defplus: 402
            },
        skillset: 
            {
                skill: "7s CT - 1200% Water DMG (PHY). Permanently increase PHY evasion by 3%. (Maximum 30%).",
                skillbreak: 700,
                arts: "14800% Water DMG (PHY). For 15s, heal Allies' HP by 250/s, increase Allies' DEF by 50%.",
                artsbreak: 1200,
                truearts: "29800% Water DMG (PHY). If own HP is 60% or lower, for 20s, increase Allies' Healing Taken, increase own Equipment CT REC by 100%.",
                trueartsbreak: 2800
            },
        passive: 
            {
                ability1: "Heal own HP by 10/s.",
                ability2: "Increase Fire RES by 25%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat3amt: 1,
                    hover3: "Palamicia",
                    mat4: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat4amt: 2,
                    hover4: "Freydal",
                }
            },
        lore: 
            {   
                evo5: "Currently unreleased in Global.",
                evoawk: "Currently unreleased in Global."
            }
    },
    { //Cynthia - Complete, Review done
        id: 102796212,
        name: "Cynthia",
        attribute: "Water",
        rank: "F",
        type: "Beast",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102795212.png",
                detailawk: "/db/Units/Detail/unit_detail_102796212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102795212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102796212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6271,
                hpplus: 950,
                atk: 2555,
                atkplus: 393,
                def: 2499,
                defplus: 384
            },
        skillset: 
            {
                skill: "8s CT - 2400% Water DMG (PHY). Invulnerable to DMG during Skill.",
                skillbreak: 1200,
                arts: "12800% Water DMG (PHY). Hit Count for this Arts is doubled + For 10s, increase Allies' ATK and DEF by 50%.",
                artsbreak: 2000,
                truearts: "34800% Water DMG (PHY). For 10s, increase own stats by 30%, increase own Arts by 3/s. When own Hit Counter is at 99: Reduce Hit Counter by 77 + For 20s, increase all Allies' stats by 30% and all Allies' Arts gauge by 3/s.",
                trueartsbreak: 3000
            },
        passive: 
            {
                ability1: "Add a Hit Counter that gains Count based on hits to enemies by own Skill, Arts and True Arts. Increase own Accuracy and Critical Rate based on Hit Count. Hit Counter caps at 99 hits.",
                ability2: "Increase DMG to Giant Bosses by 30%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat3amt: 1,
                    hover3: "Palamicia",
                    mat4: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat4amt: 1,
                    hover4: "Freydal",
                    mat5: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat5amt: 2,
                    hover5: "Tonetaurus"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Sabertooth Gauntlets",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_106491.png",
                skill: "40s CT - 9000% Water DMG (PHY). For 12s, reduce Enemies' Water and PHY RES by 20%.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Cynthia, increase DMG by 30% and Movement Speed by 10%.",
                        ability2: "Increase DMG to Giant Bosses by 20%."
                    }
            },
        lore: 
            {   
                evo5: "A Beast warrior determined to become a knight of the Isliid Empire. The champion Gran Brave, impressed by her fighting aptitude, enlisted her into his brigade. Childish innocence and charming looks lent to her popularity, and she also inspired her allies by ferociously punching through the enemy lines.",
                evoawk: "Sinister ambition and evil schemes cost many lives in the civil war. However, this brave fighter resolutely struggled to save both friend and foe, regardless of affiliation. Her devotion to the people earned her their adoration, leaving her mark on history as the \"Beastly Keeper of Peace\"."
            },
        review:
            {
                skill: `Similar to Fire Roy's skill, but without the arts gen. Pretty underwhelming.`,
                arts: `The bonus ATK and Defense boost is a decent buff to the team, though it's short uptime means you'll need some good arts generation to keep it up. The extra hit counts for her passives means you'll use this less times before using her TA to provide team buffs.`,
                truearts: `Some decent buffs! Unfortunately, there's some work you have to go through to actually access her team wide pseudo-heal and her arts generation is of the most common arts gen type. She also will end up losing some of her accuracy/crit buffs to provide that stats up buff to the team.`,
                ability1: `Cynthia's unique mechanic that is actually more of a detriment than a benefit. Other units can provide similar buffs without having to sacrifice their own passive buffs. It also means to optimize her kit, you have to cycle her NA and TA.`,
                ability2: `A small DMG boost against Giant Bosses.`,
                trueweapon: {
                    skill: `An actually decent active on a relatively low CT for Water/PHY sustain strategies. Replaceable by stronger effects for nuking strategies.`,
                    ability1: `A decent overall DMG increase and with a bonus small Action Speed buff though Cynthia isn't a particularly good damage dealer.`,
                    ability2: `A small DMG boost to Giant Bosses is always welcome.`
                },
                overall: `Oof, a seriously outdated unit that is immediately irrelevant.

                Unfortunately for Cynthia, she just shows her age - even her stats and tasmon caps are old. She's got worse stats than Takemichi! As a team wide pseudo healer and buffer, she is a lot of work for mediocre buffs and inconsistent at best. While she has arts gen, it wouldn't work with other common Type B arts gen units. Overall, she's useful for some compendium rewards and that's about it.`,
                lastupdated: `August 25, 2022`,
                author: `JC`
            }
    },
    { //Melissa - Complete, Review done
        id: 102806412,
        name: "Melissa",
        rank: "F",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102805412.png",
                detailawk: "/db/Units/Detail/unit_detail_102806412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102805412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102806412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6791,
                hpplus: 999,
                atk: 2888,
                atkplus: 443,
                def: 2632,
                defplus: 404
            },
        skillset: 
            {
                skill: "7s CT - 2000% Light DMG (PHY). Increase own Arts by 15.",
                skillbreak: 1200,
                arts: "18000% Light DMG (PHY). 50% chance to inflict Paralysis for 4s. If Paralysis is inflicted by this Art, increase own Arts by 50.",
                artsbreak: 2500,
                truearts: "41800% Light DMG (PHY). Heal self by 3% of this True Arts' Critical DMG dealt.",
                trueartsbreak: 4000
            },
        passive: 
            {
                ability1: "Increase own Critical Rate by 50%.",
                ability2: "When a Dragon is in the party, increase own ATK by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 2,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat4amt: 2,
                    hover4: "Mavroskia",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Arbasol",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_106531.png",
                skill: "40s CT - 10000% Light DMG (PHY). 70% chance to inflict Paralysis.",
                skillbreak: "?",
                passive: 
                    {
                        ability1: "When equipped by Melissa, increase Critical Rate by 20% and heal by an additional 2% of own True Arts' Critical DMG dealt.",
                        ability2: "Increase ATK by 15%."
                    }
            },
        lore: 
            {   
                evo5: "A Human knight who forged a new era out of the ensuing chaos in the Isliid Empire. Spending her childhood in an orphanage, she became apprenticed to the founder, who was a seasoned Dragon general. Her potential with the lance earned her the nickname \"Dragoon Princess\". Despite being Human, she fought with ferociousness reminiscent of her master to prove she was worthy of her title.",
                evoawk: "Quite some time has passed since the veteran general fell to his pupil's sword. This young girl gained much during her time at his orphanage: a dragon spear, a sharp wit, a cheerful soul, and unbreakable bonds. Her master's death brought great sadness, but the treasure he gave her lives on. Hearing the laughter of the other orphans, the girl, later known as the Dragoon Princess, perseveres towards a brighter future."
            },
        review:
            {
                skill: `Surprisingly a decent skill with it providing roughly 2aps to Melissa.`,
                arts: `While being a more niche ability, her chance to paralyze adds a bit of extra utility to her kit with a nice art refund if it get applied.`,
                truearts: `While the multiplier would have been good if she came out 3 years ago, it has a hard time holding up nowadays. The healing off critical damage does offer some extra survivability to her, but sadly she doesn't offer anything to help the team survive.`,
                ability1: `While the Critical rate buff is appreciated, 50% is on the more inconsistent side.`,
                ability2: `This would honestly be a decent passive, if we had more than 3 decent dragon units in the game.`,
                trueweapon: {
                    skill: `Nothing too special about it's active effect, the paralyze chance is decent but rarely needed.`,
                    ability1: `While the 20% crit rate is decent, it only boosts her up to 70% crit rate overall. The extra healing is nice but it runs into the same issue as her TA where keeping a DPS alive doesn't help keep the team alive.`,
                    ability2: `Small ATK boost which is nice`
                },
                overall: `Melissa was a decent unit who released far too late and even being this late lacks a dragon she can really pair up with. Her self healing on TA is nice, but the fact she doesn't have 100% crit rate for her healing prevent it from helping on hellfire stages. Her damage is far too outclassed to be useful in todays meta with some perma pool units being able to out damage her and out perform her with paralyzing.`,
                lastupdated: `June 23, 2022`,
                author: `Zanster`
            }
    },
    { //Barossa - Missing Lore
        id: 102816512,
        name: "Barossa",
        attribute: "Dark",
        type: "Dragon",
        rank: `B-`,
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102815512.png",
                detailawk: "/db/Units/Detail/unit_detail_102816512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102815512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102816512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7211,
                hpplus: 999,
                atk: 2434,
                atkplus: 375,
                def: 2919,
                defplus: 447
            },
        skillset: 
            {
                skill: "15s CT - 5000% Dark DMG (PHY).",
                skillbreak: 1200,
                arts: "12800% Dark DMG (PHY). For 20s, increase Allies' Critical RES by 30%, Allies gain a DMG barrier that negates 3000 DMG.",
                artsbreak: 2000,
                truearts: "24200% Dark DMG (PHY). When activated against Enemies in Break, increase own DMG by 100% for 10 seconds and gain 100 Art from one hit.",
                trueartsbreak: 4500
            },
        passive: 
            {
                ability1: "When near-death, recover 50 HP/s.",
                ability2: "When a Dragon is in the party, increase DEF by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 2,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat4amt: 2,
                    hover4: "Elpneumas",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Shadow Stella",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_106521.png",
                skill: "45s CT - 10000% Dark DMG (PHY). For 10s, increase Allies' DEF by 50%.",
                skillbreak: 1800,
                passive: 
                    {
                        ability1: "When equipped by Barossa, increase own max HP by 20% and increase own Arts by 2/s. Does not stack with Abilities of the same name.",
                        ability2: "Increase Break PWR by 6%."
                    }
            },
        lore: 
            {   
                evo5: "Missing Lore.",
                evoawk: "Missing Lore."
            }
    },
    { //Mizuki - Complete
        id: 102826212,
        name: "Mizuki",
        rank: "C",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102825212.png",
                detailawk: "/db/Units/Detail/unit_detail_102826212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102825212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102826212.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6892,
                hpplus: 999,
                atk: 2711,
                atkplus: 416,
                def: 2400,
                defplus: 370
            },
        skillset: 
            {
                skill: "8s CT - 2400% Water DMG (PHY). Increase DMG of this Skill by 100% against non-Boss Enemies.",
                skillbreak: 800,
                arts: "23000% Water DMG (PHY). 80% Chance to inflict Freeze for 4s. Increase own Arts by 20.",
                artsbreak: 1500,
                truearts: "For 180s, enter Third Eye. During Third Eye: 75000% Water DMG (PHY). Increase own Arts by 50.",
                trueartsbreak: 3100
            },
        passive: 
            {
                ability1: "Increase Hellfire RES by 70%.",
                ability2: "When in Third Eye, increase DMG to Fire Enemies by 180%. Heal own HP by 2% of DMG dealt."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat3amt: 1,
                    hover3: "Palamicia",
                    mat4: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat4amt: 2,
                    hover4: "Freydal",
                    mat5: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat5amt: 1,
                    hover5: "Oldeus"
                }
            },
        dream: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragIfrit.png`,
                        mat3amt: 2,
                        hover3: `Ifrit`
                    },
                    passive: {
                        ability1: `Increase all allies' Fire RES by 20%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own Arts by 2/s.`
                    }
                },
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragPalamicia.png`,
                        mat3amt: 2,
                        hover3: `Palamicia`
                    },
                    passive: {
                        ability1: `Increase own Equipment CT Speed by 20%. `,
                        ability2: ``
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Demonic Seal",
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_106721.png",
                skill: "25s CT - For 20s, increase own Arts by 3/s and increase own DMG by 40%.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Mizuki, increase own accuracy by 30% and increase Third Eye duration by 60s. Does not stack with Abilities of the same name.",
                        ability2: "Heal own HP by 10/s."
                    }
            },
        lore: 
            {   
                evo5: "The young female leader of the Imari Village, which is located far in the east. All the village's residents were called \"shinobi,\" and once made their living through assassination and other dark means. However, after taking over for her father after his death, Mizuki ordered the villagers to wash their hands of any immoral work. Although the village was able to experience a bit of calm after throwing away the ways of the shinobi, she could not obtain complete peace. Aware of the fact that her village was extremely impoverished, she continued to be a shinobi in the shadows for the sake of all those she ruled.",
                evoawk: "A proud shinobi who served as the leader of Imari Village. She has natural talent as a shinobi, and she was very respected because of that. With both pride and the future of the village on her shoulders, she continued to do assassination jobs in secret in order to sustain it. However, she was unable to hide her deeds forever. When she faced off against a Yoma in the depths of a dark cave, her sworn sisters Shiki and Kisaragi were badly hurt, forcing her to unleash her forbidden shinobi technique right in front of them."
            }
    },
    { //Ginzo - Complete
        id: 102836512,
        name: "Ginzo",
        rank: "F",
        twrequire: "favorable",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102835512.png",
                detailawk: "/db/Units/Detail/unit_detail_102836512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102835512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102836512.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7121,
                hpplus: 999,
                atk: 2870,
                atkplus: 440,
                def: 2190,
                defplus: 338
            },
        skillset: 
            {
                skill: "10s CT - 3500% Dark DMG (PHY). Increase own Arts by 30.",
                skillbreak: 900,
                arts: "20000% Dark DMG (PHY). For 15s, reduce Enemies' PHY RES by 40%.",
                artsbreak: 1600,
                truearts: "For 150s, enter Third Eye. During Third Eye: 57000% Dark DMG (PHY). For 20s, increase own DMG by 150% against Light Enemies.",
                trueartsbreak: 3000
            },
        passive: 
            {
                ability1: "Increase DMG to Giant Bosses by 20%.",
                ability2: "Increase Arts by 100 when entering Third Eye. While in Third Eye, ignore 100% of Enemies' DEF and increase Arts by 1/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 2,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat4amt: 2,
                    hover4: "Oldeus",
                }
            },
        dream: {
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragGoldola.png`,
                        mat3amt: 2,
                        hover3: `Goldola`
                    },
                    passive: {
                        ability1: `Increase own Equipment CT Speed by 15%.`,
                        ability2: `Increase own Poison RES by 100%.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragRagsherumPhantom.png`,
                        mat3amt: 2,
                        hover3: `Ragsherum Phantom`
                    },
                    passive: {
                        ability1: `Increase own Arts by 100 at start of quest.`,
                        ability2: `Increase own DMG against Humans by 70%.`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Netherworld Silver",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_106731.png",
                skill: "60s CT - 13000% Dark DMG (PHY). For 8s, increase own PHY DMG by 50%. Reduce Light Enemies' Dark RES by 20%.",
                skillbreak: 2000,
                passive: 
                    {
                        ability1: "When equipped by Ginzo, increase stats by 30% and increase own Arts by 2/s.",
                        ability2: "When near-death, increase own Skill CT REC by 20%."
                    }
            },
        lore: 
            {   
                evo5: "Far in the east in a village called Imari, there once lived a silent shinobi who dedicated his life to his duty. He swore absolute loyalty to Mizuki, the leader of Imari Village. He was known as her confidant, and would masterfully use a long spear to wipe out hordes of enemies with monster-like strength. Many people call him \"The Ultimate Shinobi\" because of his extremely refined shinobi techniques, non-existent free will, and dedication to his duty.",
                evoawk: "A spear-wielding warrior who was feared the among other shinobi as the \"Legend of Imari\" Completing his missions was everything to Ginzo, who had given up all of his sense of self. A single full-force strike from him has the power to smash through an enormous boulder. Serving the leader of Imari Village, his spearhead would glisten in the light of the moon as he sneaked through the night. In order to fulfill the mission assigned to him, and to protect his master, Ginzo continued swing his spear with secret feelings hidden in his heart."
            }
    },
    { //Shiki - Complete
        id: 102846412,
        name: "Shiki",
        rank: "A",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102845412.png",
                detailawk: "/db/Units/Detail/unit_detail_102846412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102845412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102846412.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6888,
                hpplus: 999,
                atk: 2650,
                atkplus: 406,
                def: 2499,
                defplus: 386
            },
        skillset: 
            {
                skill: "10s CT - 1600% Light DMG (MAG). 50% chance to inflict Burn on first usage, Poison on second usage, Blind on third usage, and repeat for 8s.",
                skillbreak: 300,
                arts: "17000% Light DMG (PHY). For 10s, increase DMG of this Arts against Enemies with Status Ailments by 50%. During Third Eye: 17000% Light DMG (PHY). For 10s, increase DMG of this Arts against Enemies with Status Ailments by 50%. Increase own Arts by 50.",
                artsbreak: 1100,
                truearts: "For 120s, enter Third Eye. During Third Eye: 35000% Light DMG (PHY). For 20s, increase Allies' Arts by 4/s, reduce Enemies' Status RES by 30%.",
                trueartsbreak: 1800
            },
        passive: 
            {
                ability1: "Increase Seal RES by 80%.",
                ability2: "Increase Arts by 200 when entering Third Eye. During Third Eye, increase Skill CT REC and accuracy by 100%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 2,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 1,
                    hover4: "Radachne",
                }
            },
        dream: {
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragWilkes.png`,
                        mat3amt: 2,
                        hover3: `Wilkes`
                    },
                    passive: {
                        ability1: `Increase Light allies' Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: ``
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragOldeus.png`,
                        mat3amt: 2,
                        hover3: `Oldeus`
                    },
                    passive: {
                        ability1: `Increase Human allies' Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: ``
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Mirage Kimono",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_107031.png",
                skill: "50s CT - 7500% Light DMG (MAG). For 10s, increase Allies' BE generated by 50%. If user is Light, increase own DMG by 30%.",
                skillbreak: 1300,
                passive: 
                    {
                        ability1: "When equipped by Shiki, increase Duration of Status Ailments inflicted by own Skill by 2s.",
                        ability2: "When near-death, increase evasion by 20%."
                    }
            },
        lore: 
            {   
                evo5: "A shinobi who has given her life to training, and the princess of the fallen land of Ena. She trained in Imari Village if order to find the one responsible for the destruction of her land and take revenge. However, she lost sight of her path after learning that Imari was involved in Ena's demise. Despite this, she stood up to fight against the Demon Fox after finding out about the tragic event she was planning, as well as the truth behind her birth. This time, she fought not only for revenge, but also to protect the shinobi of the village.",
                evoawk: "A noble girl who fought alongside the other shinobi to fight a great Demonic threat in a land in the Far East. Having defeated the Demon Fox once again, completing her mission, two paths were available to her: one where she could live alongside the shinobi of Imari Village, and the other... She did not hesitate in her decision. She put her foot on the ground and began to walk. At the end of a long journey, she arrived at her destination. \"I'm home.\" Her voice that echoed throughout her homeland marked the beginning of a new story."
            }
    },
    { //Shuri - Complete
        id: 102856512,
        name: "Shuri",
        rank: "B",
        twrequire: "favorable",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102855512.png",
                detailawk: "/db/Units/Detail/unit_detail_102856512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102855512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102856512.png"
            },
        slots:  
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7401,
                hpplus: 999,
                atk: 3055,
                atkplus: 469,
                def: 2000,
                defplus: 312
            },
        skillset: 
            {
                skill: "12s CT - 3300% Dark DMG (PHY). For 6s, reduce Enemies' Faint RES by 30%.",
                skillbreak: 1000,
                arts: "20000% Dark DMG (PHY). 80% chance to inflict Faint for 3s + For 10s, reduce Enemies' DMG RES by 30%. If enemy was Fainted by this Arts, reduce that Enemy's DMG RES by 60% instead.",
                artsbreak: 2200,
                truearts: "For 120s, enter Third Eye. During Third Eye: 68000% Dark DMG (PHY). For 10s, increase Allies' PHY DMG by 50% + increase own DMG by 50% until Third Eye ends (stackable).",
                trueartsbreak: 4000
            },
        passive: 
            {
                ability1: "Increase Freeze and Paralysis RES by 100%.",
                ability2: "During Third Eye, increase own Arts by 3 for every 1% of max HP lost.",
                ability3: "Increase DMG to Enemies with 30% or less HP by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 2,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 2,
                    hover4: "Code-XTF",
                }
            },
            dream: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragIfrit.png`,
                        mat3amt: 2,
                        hover3: `Ifrit`
                    },
                    passive: {
                        ability1: `Increase own Equipment CT Speed by 10%.`,
                        ability2: `Increase all allies' Break Power by 20%. Does not stack with Abilities of the same name.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragMavroskia.png`,
                        mat3amt: 2,
                        hover3: `Mavroskia`
                    },
                    passive: {
                        ability1: `Increase own DMG against Light enemies by 30%.`,
                        ability2: `Increase own Arts by 1/s.`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Crimson Talon",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_106991.png",
                skill: "60s CT - 12500% Dark DMG (PHY). For 10s, increase own Arts by 4/s, reduce own DEF by 30%.",
                skillbreak: 2300,
                passive: 
                    {
                        ability1: "When equipped by Shuri, increase Arts by 80 when entering Third Eye.",
                        ability2: "When near-death, increase ATK by 30%."
                    }
            },
        lore: 
            {   
                evo5: "The leader of a place called Yakuno Village, located in the Far East. able to complete any missions flawlessly and given the name \"Crimson Talon,\" she finally became a direct subordinate of the Shogun himself. This was a large success for her, as her dream is to show what a true shinobi should be by using the Divine Blade to bring prosperity to her village. Though she thought it would be smooth sailing, she ended up swallowed by the waves of history, with the red flash of the katana.",
                evoawk: "When she hurried to flee from the rising flames in front of her, what was waiting for her was the sight of her village destroyed, the culprit unknown. Within this miserable situation, she found the shinobi with prosthetic legs collapsed on the ground, who spoke to her the truth. Shuri cursed herself for not being able to see the true meaning behind the Divine Blade. Having lost so much, she left behind the village burnt to the ground, her eyes filled with rage toward the Demon Fox who took everything from her."
            }
    },
    { //Amane - Complete
        id: 102866412,
        name: "Amane",
        rank: "B-",
        twrequire: "favorable",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102865412.png",
                detailawk: "/db/Units/Detail/unit_detail_102866412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102865412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102866412.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6812,
                hpplus: 999,
                atk: 2661,
                atkplus: 408,
                def: 2558,
                defplus: 386
            },
        skillset: 
            {
                skill: "9s CT - 1700% Light DMG (PHY). Increase own Arts by 10. Increase own Arts by 5 each time own Skill crits.",
                skillbreak: 800,
                arts: "16000% Light DMG (PHY). For 20s, increase Allies' Accuracy by 80% and own CRI DMG by 150%.",
                artsbreak: 1300,
                truearts: "For 120s, enter Third Eye. During Third Eye: 40000% Light DMG (PHY). For 20s, increase Light and Dark Allies' DMG by 150%.",
                trueartsbreak: 2400
            },
        passive: 
            {
                ability1: "While in Third Eye, increase own Skill CT REC, Accuracy and Critical Rate by 50%.",
                ability2: "Increase Evasion by 15%.",
                ability3: "Increase Critical Rate by 30%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat3amt: 2,
                    hover3: "Radachne",
                    mat4: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat4amt: 1,
                    hover4: "Wilkes",
                }
            },
            dream: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragGeoZagnus.png`,
                        mat3amt: 2,
                        hover3: `Geo Zagnus`
                    },
                    passive: {
                        ability1: `Increase own Equipment CT Speed by 10%.`,
                        ability2: `Increase DMG to Enemies with 30% or less HP by 50%.`
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragWilkes.png`,
                        mat3amt: 2,
                        hover3: `Wilkes`
                    },
                    passive: {
                        ability1: `Increase own DMG against Humans by 50%.`,
                        ability2: `ncrease own DMG against Dark enemies by 50%.`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Dual Thunberbolts",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_107011.png",
                skill: "20s CT - 3800% Light DMG (PHY). For 5s, increase own Critical Rate by [# of times this equip has been used in quest x 25%] (up to 100%).",
                skillbreak: 500,
                passive: 
                    {
                        ability1: "When equipped by Amane, increase Critical DMG by 70%. Does not stack with Abilities of the same name.",
                        ability2: "Increase DMG to Human Enemies by 20%."
                    }
            },
        lore: 
            {   
                evo5: "A shinobi with prosthetic limbs who lived in Yakuno Village, located in the Far East. Although she was born in Imari Village, she was considered useless and abused. She was abandoned in the mountains with both her legs cut off as a form of so called training, but was saved by the leader of Yakuno, a female shinobi with an Oni arm. She invited her to live in the village, and was given prosthetic legs with which she awakened to her full potential. She was eventually able to become a high-ranking shinobi in the village, as well as the right-hand of the leader.",
                evoawk: "A high-ranking shinobi from Yakuno Village. The demon Fox that destroyed the village easily dodged all her attacks and disappeared, despite all the painful trials she had overcome. She lamented her weakness as she lay awake, half-conscious. However, she swore to become stronger in order to support her master after seeing her eyes burning with the flames of vengeance. She gained power almost on par with her master after going through a multitude of near-death experiences alongside her."
            }
    },
    { //Kisaragi - Complete
        id: 102876112,
        name: "Kisaragi",
        rank: "B-",
        twrequire: "favorable",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102875112.png",
                detailawk: "/db/Units/Detail/unit_detail_102876112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102875112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102876112.png"
            },
        slots:
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6799,
                hpplus: 999,
                atk: 2888,
                atkplus: 443,
                def: 2115,
                defplus: 327
            },
        skillset: 
            {
                skill: "14s CT - 4100% Fire DMG (PHY). For 10s, increase own ATK by 20%.",
                skillbreak: 700,
                arts: "20000% Fire DMG (PHY). For 15s, increase own ATK by 80% and BE Output by 80%.",
                artsbreak: 1200,
                truearts: "For 120s, enter Third Eye. During Third Eye: 60000% Fire DMG (PHY). For 20s, increase Allies' ATK by 150%.",
                trueartsbreak: 3000
            },
        passive: 
            {
                ability1: "Increase Poison RES by 20%.",
                ability2: "Increase Arts by 100 when entering Third Eye. During Third Eye, increase own ATK by 50%, and DMG by the same percentage of ATK buffs."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 1,
                    hover3: "Fosradius",
                    mat4: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat4amt: 2,
                    hover4: "Flogassa",
                }
            },
        dream: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragFlogassa.png`,
                        mat3amt: 2,
                        hover3: `Flogassa`
                    },
                    passive: {
                        ability1: `Increase own Equipment CT Speed by 15%.`,
                        ability2: `Increase own Poison RES by 100%.`
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragRadachne.png`,
                        mat3amt: 2,
                        hover3: `Radachne`
                    },
                    passive: {
                        ability1: `Increase own Accuracy rate by 60%.`,
                        ability2: `Increase own Arts by 2/s.`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Will-o'-the-Wisp",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_106761.png",
                skill: "80s CT - 11000% Fire DMG (PHY). For 12s, increase own ATK by 80%. If user is Fire, increase ATK by 150% instead.",
                skillbreak: 2500,
                passive: 
                    {
                        ability1: "When equipped by Kisaragi, increase ATK by 50%. At the start of a Quest, increase own Arts by 100.",
                        ability2: "Increase PHY RES by 10%."
                    }
            },
        lore: 
            {   
                evo5: "A teacher of ninja techniques in Imari, a village far to the east. She was one of the few top-ranking shinobi in the village, and was able to use high-level techniques. She was known as a very fair and just person by the villagers, who all trusted her. Although she accepted the fact that was an old-fashioned shinobi who could only find meaning in life through battle, she prayed that all the villagers would be able to live in peace and good health. She believed that non-essential conflict needed to be avoided, but also thought that one required the strength to protect oneself. This led her to teaching the next generation her methods of combat.",
                evoawk: "A top-ranked shinobi with extremely powerful ninja technique from Imari Village, located in the east. She fell into despair after losing her apprentice due to her own bad judgement. She grieved, wondering why her apprentice with a bright future died instead of herself, a shinobi stuck in the ways of old. Of course, no matter how much she suffered with this question in her mind, the answer never came. The only thing she knew was that she had to live his share of life in addition to her own. Despite sometimes wavering on the path she had chosen, she continued to tell herself that she must never stop walking."
            }
    },
    { //Saku - Complete
        id: 102886312,
        name: "Saku",
        rank: "C",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102885312.png",
                detailawk: "/db/Units/Detail/unit_detail_102886312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102885312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102886312.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6844,
                hpplus: 999,
                atk: 2489,
                atkplus: 383,
                def: 2507,
                defplus: 386
            },
        skillset: 
            {
                skill: "10s CT - 1700% Earth DMG (PHY). Increase own Arts by 20.",
                skillbreak: 600,
                arts: "20000% Earth DMG (PHY). High 80% chance to inflict Poison for 15s.",
                artsbreak: 1000,
                truearts: "For 200s, enter Third Eye. During Third Eye: 34000% Earth DMG (PHY). For 20s, increase Allies' Water RES by 40% and Freeze RES by 100%.",
                trueartsbreak: 2200
            },
        passive: 
            {
                ability1: "Increase Water RES by 20%.",
                ability2: "While in Third Eye, increase Skill CT REC by 200% and increase DMG to Water Enemies by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat3amt: 1,
                    hover3: "Goldola",
                    mat4: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat4amt: 1,
                    hover4: "Flogassa",
                }
            },
        dream: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragFreydal.png`,
                        mat3amt: 2,
                        hover3: `Freydal`
                    },
                    passive: {
                        ability1: `Increase own DMG against Water enemies by 70%.`,
                        ability2: `Increase own Arts by 2/s.`
                    }
                },
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragElpneumas.png`,
                        mat3amt: 2,
                        hover3: `Elpneumas`
                    },
                    passive: {
                        ability1: `Increase own Equipment CT Speed by 15%.`,
                        ability2: `Increase own BE Output by 15%.`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "TSUJI-KAZE",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_106771.png",
                skill: "60s CT - 9000% Earth DMG (PHY). For 10s, increase Allies' Earth DMG by 40%.",
                skillbreak: 2200,
                passive: 
                    {
                        ability1: "When equipped by Saku, increase DMG by 30% and increase max HP by 20%.",
                        ability2: "Increase Skill CT REC by 10%."
                    }
            },
        lore: 
            {   
                evo5: "A shinobi who trained in Imari Village, located far to the east. Though he was born into a fairly ordinary clan, his wholehearted effort and determination allowed him to become a powerful shinobi. According to his master, his greatest talents are his diligence and hard-working personality. Ten years after he began walking the path of a shinobi, his swordmanship with his jade green katana began to resemble that of his master's.",
                evoawk: "A proud shinobi from Imari Village who lived for the sake of the person he loved. At the end of the battle with Yakuno Village, he was stabbed in the back by the blade of the enemy. Though his consciousness was fading, he found relief in knowing that his master was safe. Held in the arms of his beloved, he smiled softly before slowly closing his eyes as his life ended. The events that occurred during the conflict between the two villages were spoken of through generations as a tragedy embodying the pride and fate of the shinobi."
            }
    },
    { //Tamae - Complete
        id: 102896112,
        name: "Tamae",
        rank: "B",
        twrequire: "favorable",
        attribute: "Fire",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102895112.png",
                detailawk: "/db/Units/Detail/unit_detail_102896112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102895112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102896112.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6800,
                hpplus: 999,
                atk: 3099,
                atkplus: 474,
                def: 2560,
                defplus: 392
            },
        skillset: 
            {
                skill: "12s CT - 4400% Dark DMG (MAG). 60% Chance to inflict Blind for 12s.",
                skillbreak: 600,
                arts: "21000% Dark DMG (MAG). For 50s, reduce Enemies' ATK, PHY RES, and Blind RES by 10% (stackable). For 15s, increase Dark Allies' Arts by 2/s.",
                artsbreak: 1300,
                truearts: "60000% Fire DMG (PHY). For 60s, reduce Enemies' Fire RES by 30% (stackable).",
                trueartsbreak: 2000
            },
        passive: 
            {
                ability1: "Increase Critical Rate by 100% against Blinded Enemies.",
                ability2: "Increase Critical DMG by 70%.",
                ability3: "Increase own Arts by 10 for each enemy killed."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 2,
                    hover3: "Flogassa",
                    mat4: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                    mat4amt: 1,
                    hover4: "Degilmagna",
                    mat5: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat5amt: 1,
                    hover5: "Ragsherum Phantom"
                }
            },
        dream: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragDegilmagna.png`,
                        mat3amt: 2,
                        hover3: `Degilmagna`,
                    },
                    passive: {
                        ability1: `Increase own Equipment CT Speed by 10%.`,
                        ability2: `Increase own Arts by 2/s.`
                    }
                },
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragTonetaurus.png`,
                        mat3amt: 2,
                        hover3: `Tonetaurus`,
                    },
                    passive: {
                        ability1: `Increase own Skill CT Speed by 30%.`,
                        ability2: `Increase own DMG against Earth enemies by 80%.`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Blood Moon",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_107051.png",
                skill: "65s CT - 10,000% Fire DMG (MAG). For 10s, reduce Enemies' Fire and Dark RES by 40%.",
                skillbreak: 1800,
                passive: 
                    {
                        ability1: "When equipped by Tamae, increase own Critical DMG by 60%.",
                        ability2: "Increase ATK by 10%."
                    }
            },
        lore: 
            {   
                evo5: "An enigmatic girl from the Far East who served as a spy to the Shogun behind the scenes. It was said that bad things would occur wherever she went, and was even rumored that she had something to do with the fall of the Land of Ena. As an ambassador from the capital, she tricked the people of Imari Village in order to obtain the Divine Blade that served as the key to the seal that imprisoned the Demon Fox, and succeeded in resurrecting it. No one knows of her past, but it is speculated that she might have been a former shinobi from her agility and skill with the sword.",
                evoawk: "Having been chosen to become the vessel for the soul of the Demon Fox, she stood in the way of her former brethren, the shinobi. The burning Magic of the Demon overwhelmed the skilled shinobi, and this power eventually devoured her, too. \"I can see the moon…\" Perhaps these final words she whispered were filled with yearning to her old days as a shinobi in her village, when she used to gaze up at the sky every night. lost in thought."
            }
    },
    { //Orgah - Complete (+ JP version)
        id: 102906112,
        name: "Orgah",
        rank: "F",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102905112.png",
                detailawk: "/db/Units/Detail/unit_detail_102906112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102905112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102906112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7012,
                hpplus: 999,
                atk: 2777,
                atkplus: 426,
                def: 2486,
                defplus: 382
            },
        skillset: 
            {
                skill: "8s CT - 1400% Fire DMG (PHY). Increase own Arts by 5.",
                skillbreak: 500,
                arts: "12000% Fire DMG (PHY). For 10s, increase Allies' Paralysis & Freeze RES by 100%, increase Fire Allies' DMG by 60%.",
                artsbreak: 900,
                truearts: "34000% Fire DMG (PHY). For 15s, reduce Enemies’ Fire RES by 40%. If a Demon Ally is present, for 15s, reduce Enemies' PHY RES by 40%. If a Human Ally is present, for 15s, all Allies gain a DMG barrier that negates 1000 DMG.",
                trueartsbreak: 1700
            },
        passive: 
            {
                ability1: "Increase DMG to Water Enemies by 30%.",
                ability2: "Increase Water RES by 30%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 2,
                    hover3: "Flogassa",
                    mat4: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat4amt: 1,
                    hover4: "palamicia",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Exferia",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_106941.png",
                skill: "45s CT - 7000% Fire DMG (PHY). For 8s, increase own PHY DMG by 20%, if user is Fire, increase own PHY DMG by 50% instead.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Orgah, increase DMG to Water Enemies by 70%.",
                        ability2: "Increase PHY DMG by 20%."
                    }
            },
        trueweaponJP: //If no TW, may delete this section
            {
                name: "Exferia",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_106941.png",
                skill: "45s CT - 9000% Fire DMG (PHY). For 8s, increase own PHY DMG by 20%, if user is Fire, increase own PHY DMG by 50% instead.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Orgah, increase DMG to Water Enemies by 70%.",
                        ability2: "Increase PHY DMG by 30%."
                    }
            },
        lore: 
            {   
                evo5: "A honest, competitive young boy who lived in a castle town with his twin sister, Adele. Under Raki, he studied the dual-blade style. The strong desire to protect his home along with an obedient nature granted him rapid improvement, but it was his close bond with Adele that Raki envied the most.",
                evoawk: "Orgah never forgot what happened in the abandoned mines that day. He took his master's words to heart and trained until he became a full-fledged warrior. The owner of a weapon shop urged him to join the knights, but he had sworn to defend his hometown. Amidst the tumult of the Ancient War, the sight of him bravely standing alongside the other soldiers was most heartening."
            }
    },
    { //Adele - Complete (+ JP version)
        id: 102916212,
        name: "Adele",
        rank: "C",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102915212.png",
                detailawk: "/db/Units/Detail/unit_detail_102916212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102915212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102916212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/healLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6355,
                hpplus: 963,
                atk: 2271,
                atkplus: 350,
                def: 2573,
                defplus: 395
            },
        skillset: 
            {
                skill: "10s CT - Heal all Allies' HP by 200. Heal the Ally with the lowest current HP by 5% of their max HP (except self).",
                skillbreak: 0,
                arts: "Heal all Allies' HP by 25%. Increase Healing Taken from this Arts by 100% for Fire Allies + For 20s, heal Allies' HP by 150/s.",
                artsbreak: 0,
                truearts: "18000% Water DMG (MAG). Increase own Arts by 50 + For 30s, increase Allies' Healing Taken by 50%.",
                trueartsbreak: 1200
            },
        passive: 
            {
                ability1: "Increase Seal RES by 100%.",
                ability2: "When this unit takes lethal DMG, heal Allies' HP by 100% and increase DMG RES by 50% for 10s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat3amt: 1,
                    hover3: "Ifrit",
                    mat4: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat4amt: 2,
                    hover4: "Freydal",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Empath",
                slot: "/db/Mines/thumbnail/healLB.png",
                detail: "/db/Equipment/Detail/item_detail_106851.png",
                skill: "50s CT - Heal all Allies' HP by 2000 + For 10s, increase own Skill CT REC by 50%.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Adele, increase own Skill's Healing Output by 10%. Does not stack with Abilities of the same name.",
                        ability2: "Increase Curse RES by 20%."
                    }
            },
        trueweaponJP: //If no TW, may delete this section
            {
                name: "Empath",
                slot: "/db/Mines/thumbnail/healLB.png",
                detail: "/db/Equipment/Detail/item_detail_106851.png",
                skill: "50s CT - Heal all Allies' HP by 2000 + For 15s, increase own Skill CT REC by 50%.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Adele, increase own Skill's Healing Output by 10%. Does not stack with Abilities of the same name.",
                        ability2: "Increase Curse RES by 20%."
                    }
            },
        lore: 
            {   
                evo5: "A cheerful, earnest young girl who lived in a castle town with her twin brother, Orgah. Both wished to protect their home full of kind people, so they asked the wandering swordsman Raki to teach them how to fight. However, he sensed great magic potential within her and suggested she foster that instead. Yet his true preference was that of the townsfolks's watching the orphaned siblings play without a care in the world.",
                evoawk: "Little time passed before Raki's tutelage brought her latent mastery bursting forth. As she felt herself growing stronger by the day, she also felt herself wishing to protect not only her home but her brother as well. However, the path he chose made fulfilling that wish much more difficult."
            }
    },
    { //Vermilia - Complete (+ JP version)
        id: 102926112,
        name: "Vermilia",
        rank: "B+",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102925112.png",
                detailawk: "/db/Units/Detail/unit_detail_102926112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102925112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102926112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/healLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6772,
                hpplus: 999,
                atk: 2431,
                atkplus: 411,
                def: 2557,
                defplus: 386
            },
        skillset: 
            {
                skill: "10s CT - 1400% Fire DMG (MAG). Remove all status ailments on self (except Faint, Paralysis, Seal and Freeze).",
                skillbreak: 350,
                arts: "Increase Fire Allies' Arts by 25, heal Allies' HP by 25% + For 20s, Allies gain a DMG barrier that negates 800 DMG.",
                artsbreak: 0,
                truearts: "20000% Fire DMG (MAG). Reduce Enemies' Arts by 20 + For 25s, reduce Enemies' DMG by 30%.",
                trueartsbreak: 1500
            },
        passive: 
            {
                ability1: "Increase Seal RES by 50%.",
                ability2: "Increase own Healing taken by 20% (excluding Lifesteal)."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat3amt: 1,
                    hover3: "Ifrit",
                    mat4: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat4amt: 2,
                    hover4: "Flogassa",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Fleurnika",
                slot: "/db/Mines/thumbnail/healLB.png",
                detail: "/db/Equipment/Detail/item_detail_107081.png",
                skill: "50s CT - Heal Allies' HP by 35%, remove Seal.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Vermilia, increase Max HP by 30%. Increase healing output by 10% (except lifesteal). Does not stack with Abilities of the same name.",
                        ability2: "Increase Seal RES by 20%."
                    }
            },
        trueweaponJP: //If no TW, may delete this section
            {
                name: "Fleurnika",
                slot: "/db/Mines/thumbnail/healLB.png",
                detail: "/db/Equipment/Detail/item_detail_107081.png",
                skill: "50s CT - Heal Allies' HP by 40%, remove Seal.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Vermilia, increase Max HP by 30%. Increase healing output by 10% (except lifesteal). Does not stack with Abilities of the same name.",
                        ability2: "Increase Seal RES by 20%."
                    }
            },
        lore: 
            {   
                evo5: "A young woman of 17 from the commercial city of Esylla in the Everite Republic, neighboring the Martial Kingdom of Gitun. Her father wanted her to inherit the family business, but she decided to become a sorceress when she obtained the flame scepter Flerunica. She developed her own skills apart from her mother and entered the martial arts tournament in Gitun to find her lost brother. This was a major turning point, for the difficult trials only made her stronger.",
                evoawk: "The crucible of the tournament drew out her full potential. This not only applied to her magic, but the ability to lead her comrades. As if in response, Flerunica also revealed a secret of its own: sealed inside of it was a furious beast from an ancient era. It resonated with her earnest emotion and entrusted that power to her gentle yet firm spirit."
            }
    },
    { //Filia - Complete
        id: 102936212,
        name: "Filia",
        rank: "B",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102935212.png",
                detailawk: "/db/Units/Detail/unit_detail_102936212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102935212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102936212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7200,
                hpplus: 999,
                atk: 2480,
                atkplus: 382,
                def: 2558,
                defplus: 393
            },
        skillset: 
            {
                skill: "7s CT - 2000% Water DMG (PHY). For 4 seconds, increase Allies' DEF by 15%.",
                skillbreak: 500,
                arts: "Increase own Arts by 30 + For 25s, Allies gain a DMG barrier that negates 3500 DMG, heal Allies' HP by 250/s.",
                artsbreak: 0,
                truearts: "40000% Water DMG (PHY). For 20s, increase Allies' DEF and Status RES (except Faint) by 100%.",
                trueartsbreak: 2000
            },
        passive: 
            {
                ability1: "Increase Earth RES by 25%.",
                ability2: "Increase DEF by 30% when under a DMG barrier."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                    mat3amt: 2,
                    hover3: "Geo-Zagnus",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 1,
                    hover4: "Radachne",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Brodithung",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_107241.png",
                skill: "50s CT - 5000% Water DMG (PHY). For 10s, increase Allies' PHY DMG by 30% and PHY RES by 10%.",
                skillbreak: 1500,
                passive: 
                    {
                        ability1: "When equipped by Filia, increase DMG by 20%. Filia's Arts will increase Arts by 50 instead. Does not stack with Abilities of the same name.",
                        ability2: "Increase Healing Taken by 20%."
                    }
            },
        lore: 
            {   
                evo5: "A 19-year-old girl who lived in a small mountain village in the western kingdom of Yoshal with her parents and little sister. She normally helped out with then farming chores until a beautiful blue sword appeared behind the shed one day. That convinced her to become a warrior, if only to earn the money for her blind sister's treatment that her poor family could not afford. Learning from a retired fencer who lived in the nearby mountains, she asked to become his apprentice.",
                evoawk: "Filia studied how to use her blade under the old veteran, gaining remarkable talent after a few years. However, they were suddenly attacked during one training session by an infamous band of thieves who wanted to steal her unique weapon. The fight was won despite overwhelming odds, but her master could never wield a sword again. Returning home to promise her family she would win a major martial arts tournament and its purse, she set out on a new journey."
            }
    },
    { //Garlan - Complete (+ JP version)
        id: 102946312,
        name: "Garlan",
        rank: "F",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102945312.png",
                detailawk: "/db/Units/Detail/unit_detail_102946312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102945312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102946312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7059,
                hpplus: 999,
                atk: 2555,
                atkplus: 393,
                def: 2731,
                defplus: 419
            },
        skillset: 
            {
                skill: "10s CT - 2500% Earth DMG (PHY). For 20s, reduce Movement Speed of Enemies in Break by 10%.",                
                skillbreak: 2000,                
                arts: "20000% Earth DMG (PHY). Permanently increase own Break PWR by 30% (up to 180%), reduce own DMG RES by 6% (up to 36%)",
                artsbreak: 4500,                
                truearts: "50000% Earth DMG (PHY). Increase DMG of this True Arts to Enemies in Break by 200%.",                
                trueartsbreak: 9000, 
            },
        skillsetJP: 
            {
                
                skill: "10s CT - 2200% Earth DMG (PHY). For 20s, reduce Movement Speed of Enemies in Break by 10%.",                
                skillbreak: 1800,                
                arts: "16000% Earth DMG (PHY). Permanently increase own Break PWR by 12% (up to 120%), reduce own DMG RES by 6% (up to 60%).",
                artsbreak: 4000,                
                truearts: "30000% Earth DMG (PHY). Increase DMG of this True Arts to Enemies in Break by 200%.",                
                trueartsbreak: 8000   
            },
        passive: 
            {
                ability1: "Increase Freeze RES by 50%.",
                ability2: "Increase Arts by 2/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat3amt: 2,
                    hover3: "Goldola",
                    mat4: "/db/Icons/dungeon_drops/fragValzandeth.png",
                    mat4amt: 1,
                    hover4: "Valzandeth",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Yulibert",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_107141.png",
                skill: "80s CT - 10000% Earth DMG (PHY). For 15s, increase own Break PWR by 50%. When used while a Giant Boss is broken, increase Arts by 50.",
                skillbreak: 3400,
                passive: 
                    {
                        ability1: "When equipped by Garlan, increase DMG to Enemies in Break by 50%.",
                        ability2: "Increase own Break PWR by 8%."
                    }
            },
        trueweaponJP: //If no TW, may delete this section
            {
                name: "Yulibert",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_107141.png",
                skill: "80s CT - 10000% Earth DMG (PHY). For 15s, increase own Break PWR by 70%. When used while a Giant Boss is broken, increase Arts by 50.",
                skillbreak: 3400,
                passive: 
                    {
                        ability1: "When equipped by Garlan, increase DMG to Enemies in Break by 50%.",
                        ability2: "Increase own Break PWR by 8%."
                    }
            },
        lore: 
            {   
                evo5: "The only son of a woodcutter family from the mountaineous Ghenen area. The sight of his father's scars after returning from war gave him a stark lesson -- combat skills were essential to a life of security. Ever since, he trained in stoic silence, eventually departing on a journey to become a true warrior. His self-taught technique mirrored his personality: straightforward but stubborn.",
                evoawk: "With his trusty polearm Yulibert, Garlan continued to refine his considerable technique. His name was known throughout the land as an undefeated duelist, but this meant few were willing to accept his challenge. He decided to cross the ocean to reach the Martial Kingdom of Gitun, where the strong gathered. It was then he had his destined meeting with Filia, and together they defeated the marauding monsters in their way."
            }
    },
    { //Aluze - Complete (+ JP version)
        id: 102956412,
        name: "Aluze",
        rank: "F",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102955412.png",
                detailawk: "/db/Units/Detail/unit_detail_102956412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102955412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102956412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6844,
                hpplus: 999,
                atk: 2800,
                atkplus: 430,
                def: 2322,
                defplus: 358
            },
        skillset: 
            {
                skill: "8s CT - 1600% Light DMG (PHY). For 3s, increase Allies' evasion by 5%.",
                skillbreak: 800,
                arts: "18000% Light DMG (PHY). For 5s, increase own DMG by [30% x # of Enemies (up to 180%)].",
                artsbreak: 1200,
                truearts: "32000% Light DMG (PHY). For 15s, increase Allies' DMG by 40%, increase Light Allies' Critical Rate by 100%.",
                trueartsbreak: 2500
            },
        passive: 
            {
                ability1: "Increase PHY evasion by 20%.",
                ability2: "When near-death, increase Skill CT REC by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 1,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat4amt: 2,
                    hover4: "Tonetaurus",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Leivachka",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_107121.png",
                skill: "90s CT - 12500% Light DMG (PHY). For 12s, increase own PHY DMG by 50%. If user is Light, increase own Critical Rate by 50%.",
                skillbreak: 2600,
                passive: 
                    {
                        ability1: "When equipped by Aluze, increase DMG and ATK by 30%.",
                        ability2: "Increase PHY evasion by 10%."
                    }
            },
        trueweaponJP: //If no TW, may delete this section
            {
                name: "Leivachka",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_107121.png",
                skill: "90s CT - 13500% Light DMG (PHY). For 12s, increase own PHY DMG by 50%. If user is Light, increase own Critical Rate by 50%.",
                skillbreak: 2600,
                passive: 
                    {
                        ability1: "When equipped by Aluze, increase DMG and ATK by 30%.",
                        ability2: "Increase PHY evasion by 10%."
                    }
            },
        lore: 
            {   
                evo5: "The son of a noble family from the small eastern city-state of Slevin. A strict upbringing and natural talent in both intellect and combat made him a standout from a young age. However, he never knew tenacity since expertise came to him so quickly. When he heard of a martial arts tournament in the distant country of Gitun, he faintly hoped it would provide some form of stimulation. He then ran away from home with only instinct and his light bow Leivachka to guide him on his journey.",
                evoawk: "The son of nobility, blessed with both luxury and ability. When he received his bow Leivachka from his father, he was told it was his duty as a noble to fight in defense of the weak. Upholding that principle even away from home, he helped a great number of people on the way to the tournament. His fair looks also earned him many admirers, but he was so focused on his destination that he paid them no mind."
            }
    },
    { //Veronica - Complete (+ JP version)
        id: 102966212,
        name: "Veronica",
        rank: "B-",
        twrequire: "favorable",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102965212.png",
                detailawk: "/db/Units/Detail/unit_detail_102966212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102965212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102966212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6680,
                hpplus: 999,
                atk: 2882,
                atkplus: 442,
                def: 2315,
                defplus: 356
            },
        skillset: 
            {
                skill: "9s CT - 2000% Water DMG (MAG). Increase own Arts by 15.",
                skillbreak: 700,
                arts: "18000% Water DMG (MAG). For 15s, increase own DMG and Skill CT REC by 50%, reduce own DEF by 75%.",
                artsbreak: 1200,
                truearts: "40000% Water DMG (MAG). For 15s, increase own Critical Rate and DMG by 100%, reduce own Status RES by 60%, reduce Enemies' DMG RES by 30%.",
                trueartsbreak: 2000
            },
        passive: 
            {
                ability1: "When near-death, increase DMG by 10%.",
                ability2: "Increase Critical DMG by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat3amt: 1,
                    hover3: "Radachne",
                    mat4: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat4amt: 2,
                    hover4: "Freydal",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Lizaverde",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_107101.png",
                skill: "30s CT - 6000% Water DMG (MAG). For 8s, increase own Critical DMG by 30%. If user is Water, increase Critical DMG by 60% instead.",
                skillbreak: 1200,
                passive: 
                    {
                        ability1: "When equipped by Veronica, increase own Critical DMG by 30% and Skill CT REC by 40%.",
                        ability2: "Increase DMG to Enemies in Break by 20%."
                    }
            },
        trueweaponJP: //If no TW, may delete this section
            {
                name: "Lizaverde",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_107101.png",
                skill: "30s CT - 6000% Water DMG (MAG). For 10s, increase own Critical DMG by 30%. If user is Water, increase Critical DMG by 60% instead.",
                skillbreak: 1200,
                passive: 
                    {
                        ability1: "When equipped by Veronica, increase own Critical DMG by 30% and Skill CT REC by 40%.",
                        ability2: "Increase DMG to Enemies in Break by 20%."
                    }
            },
        lore: 
            {   
                evo5: "A mysterious girl on a never-ending journey of solitude. She sent many monsters to their graves with her gigantic scythe. She became somewhat of a legend among monster hunters an an enigmatic scythe-wielder who relied on no one. Although her manner of speech was rather mature despite her lack of words, she loved sweets just like other children her age. It always seemed like she was looking for something, but she never talked to anyone about it.",
                evoawk: "No one know what Veronicia was after, she wandered the word endlessy, without opening her heart to anyonethose who know her suspected that she was actually a demondue to her appearance never changing over the decade."
            }
    },
    { //Roy (Fire) - Complete (+ JP version)
        id: 102976112,
        name: "Roy (Fire)",
        rank: "B",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102975112.png",
                detailawk: "/db/Units/Detail/unit_detail_102976112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102975112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102976112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6850,
                hpplus: 999,
                atk: 2777,
                atkplus: 426,
                def: 2321,
                defplus: 358
            },
        skillset: 
            {
                skill: "8s CT - 3600% Fire DMG (PHY). Increase own Arts by 20. Invulnerable to DMG during Skill.",                
                skillbreak: 500,
                arts: "12500% Fire DMG (PHY). Increase own Arts by 50 + For 15s, reduce Enemies' Fire RES by 30%.",                
                artsbreak: 1000,
                truearts: "50000% Fire DMG (PHY). For 20s, ignore enemies' DEF, increase Fire Allies' DMG by 120%.",                
                trueartsbreak: 2200
            },
        skillsetJP: 
            {
                skill: "8s CT - 3400% Fire DMG (PHY). Increase own Arts by 10. Invulnerable to DMG during Skill.",
                skillbreak: 500,                
                arts: "10000% Fire DMG (PHY). Increase own Arts by 50 + For 10s, reduce Enemies' Fire RES by 30%.",
                artsbreak: 1000,                
                truearts: "38000% Fire DMG (PHY). For 20s, ignore enemies' DEF, increase Fire Allies' DMG by 80%.",
                trueartsbreak: 2200
            },
        passive: 
            {
                ability1: "Increase DMG to Non-Boss Enemies under 30% HP by 300%.",
                ability2: "Increase DMG to Enemies in Break by 50%.",
                ability3: "If Skill invalidates damage, for 9s, increase own Arts DMG by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 1,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat4amt: 2,
                    hover4: "Flogassa",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "SV61",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_107261.png",
                skill: "30s CT - 6400% Fire DMG (PHY). For 8s, increase own Skill DMG by 50%, increase own Skill CT REC by 50%.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Fire Roy, increase stats by 20% and increase Skill DMG by 50%.",
                        ability2: "Increase PHY DMG by 20%."
                    }
            },
        lore: 
            {   
                evo5: "Captain of the knights of the Empire of Isliid. This skilled warrior has achieved much despite his young age, including the subjugation of the Demons. Roy accepts a sudden joint mission with the information bureau, sacrificing his own vacation to allow his subordinates to take their time off in peace. Despite having a gentle expression in front of his friends, he tends to be very stern at work due to his position and responsibilities. This leads him to isolate himself at times, but he is never truly alone thanks to the trust he has earned from the Emperor and his subordinates.",
                evoawk: "Roy, clad in the uniform of the Information Bureau. The empire has begun training its knights to be prepared for a myriad of combat situations by instructing them how to use a variety of weapons. The \"Imperial Gun Arts\" method was developed by Gichin Nanbu with the goal of teaching soldiers how to utilize firearms. It was created to accommodate the knight's affinity for close combat, but many-a-knight threw in the towel due to the hellish training needed to grasp the technique (even taking Roy a few years to master)."
            }
    },
    { //Cestina - Complete, Review done
        id: 102986312,
        name: "Cestina (Earth)",
        rank: "A+",
        twrequire: "favorable",
        attribute: "Earth",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102985312.png",
                detailawk: "/db/Units/Detail/unit_detail_102986312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102985312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102986312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7200,
                hpplus: 999,
                atk: 2815,
                atkplus: 432,
                def: 2690,
                defplus: 413
            },
        skillset: 
            {
                skill: "8s CT - 2400% Earth DMG (PHY). For 3s, reduce Enemies’ Accuracy by 10%.",
                skillbreak: 800,
                arts: "16000% Earth DMG (PHY). Increase Allies’ Arts by 20 + For 20s, Allies gain a DMG barrier that negates 3000 DMG.",
                artsbreak: 1200,
                truearts: "42000% Earth DMG (PHY). For 15s, increase all Allies’ PHY DMG by 100%, reduce Enemies’ DMG RES by 30%.",
                trueartsbreak: 2000,
                crossarts: "100000% Fire & Earth DMG (PHY). For 25s, increase Cestina and Radak's DMG by 200%, increase Fire Allies’ Arts by 7/s, increase Earth Allies’ DMG by 200%.",
                crossartsbreak: 5000
            },
        passive: 
            {
                ability1: "Increase Allies’ Max HP by 10%. Does not stack with Abilities of the same name.",
                ability2: "Increase DMG to Giant Bosses by 50%.",
                ability3: "When becoming near-death, increase Arts by 200 + For 15s, enter Majestic Grace. Majestic Grace increases Arts DMG by 100%. Can only be activated once per quest."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat3amt: 2,
                    hover3: "Radachne",
                    mat4: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat4amt: 2,
                    hover4: "Goldola",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Supernova",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_107341.png",
                skill: "30s CT - 7000% Earth DMG (PHY). If user is Earth, for 12s, increase own Arts Gauge by 1/s and increase own PHY DMG by 30%.",
                skillbreak: 900,
                passive: 
                    {
                        ability1: "When equipped by Cestina, increase Arts by 2/s + During Majestic Grace, increase Arts DMG by an additional 150%. Does not stack with Abilities of the same name.",
                        ability2: "When near-death, increase ATK by 20%."
                    }
            },
        lore: 
            {   
                evo5: "The second princess of the Gran Verge Kingdom, who was renowned for both her skill with the sword and her love for her citizens. With her lofty ideals and aspirations, she drew in the people around her, and her presence pushed the kingdom forward. She had two role models: her older sister, highly intelligent and skilled with magic, and her older brother, who carried himself with the majesty of a king. From a young age, Cestina took lessons from the leader of the Gran Verge knights so that she would be able to support her siblings. The Gran Verge sword fighting style was engraved in Cestina's soul, and she became the youngest ever assistant instructor.",
                evoawk: "The just and righteous second princess of Gran Verge. When she wielded her sword, it was always for somebody else's sake, and she would never hurt somebody for her own gain. However, her strong sense of justice could be taken advantage of, and it was sometimes a weakness. Even so, she held to her beliefs to the very end. Her compassion and benevolence instilled great pride in the people of Gran Verge."
            },
        review:
            {
                skill: `Offers a near negligible amount of survivability to the team.`,
                arts: `Bringing a plentiful amount of Support Cestina can offer to the team with this art as well as its relatively low cost makes this a great addition to any team that needs ArtGen and Defensive Measures.`,
                truearts: `Offering substantial buffs/debuffs allows for both Cestina and her allies to inflict major amounts of damage during its duration. Enabling Cestina to perform as a competent DPS or Nuker when her Arts buffs are unnecessary.`,
                crossarts: `While the high multiplier combined with the plethora of buffs this dual arts can bring seems quite potent, it’s a mere layer cake with a bitter inside. In spite of their limitations, the high cost of performing it as well as Cestina and Radak’s low synergy makes it so that this Dual Art seldom seize use.`,
                ability1: `A nice Buff to the team’s survivability.`,
                ability2: `A substantial DMG Buff for Cestina to further improve her DPS capabilities against Giant Bosses.`,
                ability3: `Even though you’d never want a Unit to be near death, the flexibility this ability offers is very welcome, whether it to be used for it’s incredible Arts DMG boost for some strong Burst or a load instant arts allowing Cestina to pull out her barrier as fast as possible to prevent Ally/Unit deaths.`,
                trueweapon: {
                    skill: `While it’s Arts per second isn’t on par with other alternatives in terms of general use especially when it’s locked to Earth units, It’s overall still a very neat piece of Equipment in trade of Arts Generation for more offensive measures.`,
                    ability1: `A whopping 2 Arts per second with even more to offer from the Equip’s skill itself makes this Equip Cestina’s Go-to for general use, even further buffing her DMG during Majestic Grace by a large amount.`,
                    ability2: `Supposedly to further boost Cestina’s DMG dealt if she ever Activates Majestic Grace. While it wont be seen often, a nice addition regardless.`
                },
                overall: `A really versatile Unit who can thrive in the current meta due to her excelling in the various roles she is able to fill. Cestina wields a lot of value for both veterans and newer players for bringing substantial amounts of team support in both Offensive and Defensive elements. Overall, a Befitting spotlight for her impressive kit, invaluable Equipment slots and in dire situations where her Majestic Grace activates revealing the Monster she truly is.`,
                lastupdated: `July 21, 2020`,
                author: `Unex`
            }
    },
    { //Radak - Complete, Review done
        id: 102996112,
        name: "Radak",
        rank: "C",
        luckrank: "F",
        attribute: "Fire",
        type: "Beast",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_102995112.png",
                detailawk: "/db/Units/Detail/unit_detail_102996112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_102995112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_102996112.png"
            },
        slots:  
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6891,
                hpplus: 999,
                atk: 2555,
                atkplus: 372,
                def: 2577,
                defplus: 396
            },
        skillset: 
            {
                skill: "9s CT - 2000% Fire DMG (PHY). Increase own Arts by 15.",
                skillbreak: 700,
                arts: "14000% Fire DMG (PHY). Increase own Arts by 30 + For 10s, increase Allies' ATK by 50%, reduce Enemies' ATK by 50%.",
                artsbreak: 1500,
                truearts: "32000% Fire DMG (PHY). For 15s, Increase Allies' DMG by 30%, reduce Enemies' Fire RES by 35%.",
                trueartsbreak: 2300,
                crossarts: "100000% Fire & Earth DMG (PHY). For 25s, increase Cestina and Radak's DMG by 200%, increase Fire Allies’ Arts by 7/s, increase Earth Allies’ DMG by 200%.",
                crossartsbreak: 5000
            },
        passive: 
            {
                ability1: "When Cestina is in party, increase Skill CT REC by 50%, increase DMG by 30%.",
                ability2: "Increase DMG to Giant Bosses by 20%.",
                ability3: "Increase DMG to Earth Enemies by 10%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat3amt: 2,
                    hover3: "Ifrit",
                    mat4: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat4amt: 2,
                    hover4: "Flogassa",
                    mat5: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat5amt: 1,
                    hover5: "Mavroskia"
                }
            },
        lore: 
            {   
                evo5: "A Beast servant who swore allegiance to the second princess of the Gran Verge Kingdom, Cestina. The youngest son of a venerable Beast priest, he was discovered by the King of Gran Verge and given the honorable position of being servant to the princess. In truth, Radak had been reluctant at first, but in a coincidental meeting with Cestina, he was struck by the sparkle in her eye and her strong will. This incident made Radak decide to become Cestina's servant.",
                evoawk: "A loyal warrior in the service of the second princess of the Gran Verge Kingdom, Cestina. Their bond was much stronger than that of a mere master and servant, and the trust between them was unparalleled. The connection between them showed its true strength during the turmoil at the tower of Oluon in Gran Verge and has gone down in history."
            },
        review:
            {
                skill: `Adds a good amount of self-sufficiency to his kit allowing him to build arts faster.`,
                arts: `A very useful part of his kit that further enables his team through boosts in survivability and damage output. The Arts refund allows for greater uptime on the buff and debuff, especially with external Arts Gen support.`,
                truearts: `A decent DMG buff/debuff that has a greater effect in mono Fire teams; however due to the effect being geared towards mono Fire and his slots not being optimal this will never see much use.`,
                crossarts: `Despite its enormous multiplier, DMG boost, and arts buff, you will rarely see this used due to its large arts consumption as well as Cestina's and Radak's low compatibility.`,
                ability1: `Increases his self sufficiency and damage output if Cestina is on the team. Do note that this ability will rarely be active due to their lack of synergy.`,
                ability2: `A welcome boost considering a majority of content consists of Giant Bosses.`,
                ability3: `A conditional boost that will never really impact anything due to its small multiplier.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Despite a lot of perks to his kit, Radak is held back by both his conditional buffs and team limitations. He will mainly act as a debuffer since he struggles as a DPS with his relatively low damage output and is unable to find a place in nuke teams due to his lackluster slots. His Cross Arts with Cestina offers some use, but due to the many conditions for its activation it will rarely be seen. Despite these limitations, he can definitely hold his own and has use in certain areas of content.`,
                lastupdated: `July 21, 2020`,
                author: `The True Pepe`
            }
    },
    { //Liza - Complete, Review done
        id: 103006412,
        name: "Liza",
        rank: "S",
        attribute: "Light",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103005412.png",
                detailawk: "/db/Units/Detail/unit_detail_103006412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103005412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103006412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6869,
                hpplus: 999,
                atk: 2611,
                atkplus: 442,
                def: 2605,
                defplus: 357
            },
        skillset: 
            {
                skill: "11s CT - 2000% Light DMG (MAG). For 5s, reduce Enemies' MAG RES by 10%.",
                skillbreak: 400,
                arts: "10000% Light DMG (MAG). For 10s, increase Allies' Arts by 4/s.",
                artsbreak: 900,
                truearts: "30000% Light DMG (MAG). For 20s, increase Allies' Equipment CT REC by 50%, reduce Enemies' Light and MAG RES by 35%.",
                trueartsbreak: 1800,
                crossarts: "77000% Light DMG (MAG). Liza and Cygnet heal 50% of DMG taken during this Cross Arts + For 60s, increase Allies' DMG to Enemies they have an Elemental Advantage against by 250%.",
                crossartsbreak: 3500
            },
        passive: 
            {
                ability1: "Increase MAG RES by 10%.",
                ability2: "Increase DMG to Dark Enemies by 10%.",
                ability3: "Each time own Equipment is used, gain a Majestic Grace stack for that Equipment (max. 6 stacks). Majesty: Instantly recover [5% × # of Majestic Grace stacks] of the relevant Equipments' CT after they are used (up to 30%)."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 1,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat4amt: 2,
                    hover4: "Tonetaurus",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Etoile",
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_107401.png",
                skill: "35s CT - For 20s, increase target's Arts by 4/s, if user is Light, increase target's MAG DMG by 30%.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Liza, increase DMG by 30% and Curse RES by 100%. When a Quest begins, increase own Arts by 50. Does not stack with Abilities of the same name.",
                        ability2: "Increase MAG RES by 10%."
                    }
            },
        lore: 
            {   
                evo5: "The first princess of the Gran Verge Kingdom. This goddess was filled with both magic and wisdom. Her knowledge of magic and her incredible intelligence was unrivaled, and her studies spurred on magic research for generations. In the kingdom, she preferred to stay in the shadows, supporting the people from behind rather than standing in the spotlight, but she still had quite a few enthusiastic fans.",
                evoawk: "The first princess of the Gran Verge Kingdom. She took the path of magic her whole life as she tried to master it. She was fascinated by the revolutionary power of magic and spent her life studying magic to help brighten people's futures. Even now, her achievements still support people's lives from the shadows. She is said to have been deeply involved in the project to restore the enigmatic Oluon, but very little of this has been written down in history, as the first princess disliked being in the spotlight."
            },
        review:
            {
                skill: `While nothing spectacular, it's a nice compliment to teams with significant Magic Damage output, though a bit out of place on Liza considering her role as both an equipment and arts support unit.`,
                arts: `Relatively potent arts generation which is at the very least on par with other top-tier arts generators. While the most unique part of her kit is on her True Arts, it's always nice to have additional utility, especially when looped with her True Arts.`,
                truearts: `Providing a myriad of buffs, the most important of which being the extremely potent Equipment CT Recovery Speed, which as potent as it is, its usefulness is dependent on your team layout and how much you rely on specific equipment. Still, the sheer amount of possibilities enabled by this relatively unique buff on its own bolster Liza enough to be touted as an excellent support unit, especially alongside the addition of powerful offensive debuffs.`,
                crossarts: `The DMG up buff and large modifier, while extremely powerful, is not only limited but also out of place considering it requires two subpar attackers in order to even activate. You're more often than not better off just using Liza's Arts and True Arts.`,
                ability1: `A relatively strong survivability enhancement - even if it's limited to a specific type of damage, the buff's potency is enough to make the difference noticeable.`,
                ability2: `While Liza isn't primarily an attacker, her True Arts' offensive debuffs make her output decent enough damage for her role, with this passive just adding a small niche boost on top of that.`,
                ability3: `A great passive to have, especially given Liza's relatively self-sufficient slotting. While this passive is more so geared towards longer stages, it can almost halve her own equipment cooldown times when combined with Liza's True Arts.`,
                trueweapon: {
                    skill: `With the arts generation itself is relatively underwhelming, coming out to around 2.28 arts per second and the DMG buff being relatively out of place on Liza, the active effect comes out to be relatively mediocre within the context of being equipped on Liza herself. You're better off using a high arts generation equipment in most cases.`,
                    ability1: `A strange assortment of buffs that don't make that much of an impact on the utility of Liza in most cases, though the combination of them, especially the arts start-up, can justify using Liza's True Weapon over slightly better arts generation equipment such as Relic "Lesser Demon Heart".`,
                    ability2: `Another small survivability bonus, which while won't make or break things, is always nice to have and goes along nicely with Liza's Magic DMG Resistance passive.`
                },
                overall: `Boasting arts generation comparable to some of the best-in-class generators combined with her distinct and signature equipment support, Liza is able easily fit into an enormous range of compositions. Still, her strength, by nature, heavily relies on end-game equipment to take full advantage of, though she's proves useful as an arts generator for newer players. This is especially apparent in the longest and most difficult content where her kit only becomes more pronounced and useful. Overall, an immensely valuable addition to any box due to her dominance within her extremely fundamental roles.`,
                lastupdated: `August 22, 2020`,
                author: `Elric`
            }
    },
    { //Cygnet - Complete, Review done
        id: 103016512,
        name: "Cygnet",
        rank: "F",
        luckrank: "F",
        attribute: "Dark",
        type: "Machine",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103015512.png",
                detailawk: "/db/Units/Detail/unit_detail_103016512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103015512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103016512.png"
            },
        slots:
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7188,
                hpplus: 999,
                atk: 2880,
                atkplus: 442,
                def: 2430,
                defplus: 357
            },
        skillset: 
            {
                skill: "10s CT - 3000% Dark DMG (PHY). Negate Hitstun during own Skill.",
                skillbreak: 800,
                arts: "16000% Dark DMG (PHY). For 15s, increase Allies' Critical DMG by 50%.",
                artsbreak: 1100,
                truearts: "34000% Dark DMG (PHY). If Critical Counter is above 70, reduce own Count by 50, increase own Arts by 100 + For 25s, increase Allies' Critical Rate by 50%.",
                trueartsbreak: 1500,
                crossarts: "77000% Light DMG (MAG). Liza and Cygnet heal 50% of DMG taken during this Cross Arts + For 60s, increase Allies' DMG to Enemies they have an Elemental Advantage against by 250%.",
                crossartsbreak: 3500
            },
        passive: 
            {
                ability1: "Increase Critical Rate by 30%.",
                ability2: "Gain a Critical Counter that gains 1 Count each time a Critical is landed + Increase own ATK by 1% for each Count (up to 99%).",
                ability3: "At the beginning of a Quest, increase Arts by 50 + If Liza is in the party, increase Arts by an additional 50 and increase own Critical Rate by 20%. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat3amt: 1,
                    hover3: "Code-XTF",
                }
            },
        lore: 
            {   
                evo5: "A Machine servant who swore loyalty to Liza, the First Princess of the Gran Verge Kingdom. Cygnet had been a mere gardener, but when the magic sleeping within her suddenly awoke, Liza took notice and she took Cygnet as her servant. Cygnet made a good match for Liza, who disliked formalities and preferred to speak plainly. When Liza couldn't find the words for what she wanted to say, Cygnet would speak for her, becoming an interpreter of sorts for her master's aloof curtness.",
                evoawk: "An eternal partner and servant to Liza, the First Princess of the Gran Verge Kingdom. The relationship between a master and their servant can take many forms. They may cover for each other's shortcomings or strengthen each other's merits. Cygnet and Liza's relationship leaned overwhelmingly towards the former. Liza's unparalleled focus sometimes came at the expense of her regular life, but Cygnet supported her master through this. Meanwhile, Cygnet lacked self-confidence despite her incredible qualities, but Liza would always push her servant past her insecurities."
            },
        review:
            {
                skill: `Nothing much to say about this. Negating Hitstun is decently useful for clearing enemies in pre-stages, which is what skills are mostly used for.`,
                arts: `The Critical DMG buff may look good for Arts, but it is inconsistent at best without a Crit Rate buffer on the team. It can make for a decent nuke support buff if you have a Crit Rate buffer such as Regulus.`,
                truearts: `Playing around with her Critical Counter passive, this allows Cygnet to be more self sustainable in long stages. The boost to her Crit Rate is too small to make any large impact on her DPS capability. The best way to use Cygnet is to chain her True Arts straight into her Arts for the Critical DMG buff.`,
                crossarts: `Despite being the second Cross Arts in the game, it is niche at best. It is certainly not worth building an entire team around the Elemental Advantage effect.`,
                ability1: `A nice passive that synergizes well with her Critical Counter gimmick.`,
                ability2: `A unique passive that functions similarly to Lian's Hit Counter, except only Critical Hits are counted. Most of Cygnet's animations have a large amount of hits to build up the counter. The ATK boost is appreciated despite her True Arts innately working against that bonus effect.`,
                ability3: `An Arts head start is appreciated for quests with less pre-stages. While 20% more Crit Rate is beneficial towards her Critical Counter, building a team around slightly increasing her Crit Rate is much more limiting than simply using a different DPS unit.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `At best, Cygnet is an inconsistent DPS. Despite having to rely on Critical Hits, her kit does a mediocre job in supporting this gimmick. Without Liza on the team and Cursed Light Sword "Anestra", the amount of time taken to fill the Critical Counter is slower than most other traditional DPS units. The defense slot on a DPS based unit only serves to slow Cygnet down from performing her role. However, she does have application as a budget nuke support, despite the slower slotting.`,
                lastupdated: `August 22, 2022`,
                author: `Senshi`
            }
    },
    { //Orvell - Complete, Review done + JP Dream
        id: 103026412,
        name: "Orvell (Light)",
        rank: "B",
        attribute: "Light",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103025412.png",
                detailawk: "/db/Units/Detail/unit_detail_103026412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103025412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103026412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7521,
                hpplus: 999,
                atk: 3077,
                atkplus: 471,
                def: 2156,
                defplus: 333
            },
        skillset: 
            {
                skill: "18s CT - 6000% Light DMG (PHY). Increase this Skill's DMG to Non-Boss Enemies by 100%.",
                skillbreak: 700,
                arts: "20000% Light DMG (PHY). For 15s, increase own Accuracy by 35% and reduce Enemies' PHY RES by 25%.",
                artsbreak: 1200,
                truearts: "65000% Light DMG (PHY). For 15s, increase stats by 50%. Additionally when there are 5 stacks of Testament to the King, for 15s, ignore 100% of Enemies' DEF.",
                trueartsbreak: 1900,
                crossarts: "120000% Water DMG (MAG) and Light DMG (PHY). Increase Orvell's and Virago's Arts by 50. If used when there are 5 stacks of both Testament to the King and Overlord's Squire, increase the DMG Multiplier of this Cross Arts by an additional 80000%.",
                crossartsbreak: 4700
            },
        skillsetJP: 
            {
                skill: "18s CT - 6000% Light DMG (PHY). Increase this Skill's DMG to Non-Boss Enemies by 100%.",
                skillbreak: 700,
                arts: "20000% Light DMG (PHY). For 15s, increase own Accuracy by 40% and reduce Enemies' PHY RES by 35%.",
                artsbreak: 1200,
                truearts: "80000% Light DMG (PHY). For 20s, increase stats by 50%. Additionally when there are 5 stacks of Testament to the King, for 15s, ignore 100% of Enemies' DEF.",
                trueartsbreak: 1900,
                crossarts: "150000% Water DMG (MAG) and Light DMG (PHY). Increase Orvell's and Virago's Arts by 50. If used when there are 5 stacks of both Testament to the King and Overlord's Squire, increase the DMG Multiplier of this Cross Arts by an additional 150000%.",
                crossartsbreak: 4700
            },
        passive: 
            {
                ability1: "On use of Arts, gain a stack of Testament to the King (Max 5 stacks). For each stack, increase DMG by 40% and Skill CT REC by 20%.",
                ability2: "Increase Freeze and Paralyze RES by 100%. Less likely to be knocked back.",
                ability3: "When near-death, increase DMG by 10%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 1,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat4amt: 2,
                    hover4: "Flogassa",
                }
            },
            dreamJP: {
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                        mat3amt: 2,
                        hover3: "Fosradius",
                    },
                    passive: {
                        ability1: `Increase own Arts by 3/s.`,
                        ability2: `Increase own Skill CT Speed by 10%.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragMavroskia.png",
                        mat3amt: 2,
                        hover3: "Mavroskia",
                    },
                    passive: {
                        ability1: `Increase own DMG against Demon enemies by 150%.`,
                        ability2: `Increase own Equip CT Speed by 10%.`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Grand Destin",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_107601.png",
                skill: "75s CT - 13000% Light DMG (PHY). For 10s, increase Light Allies' DMG by 40%.",
                skillbreak: 2000,
                passive: 
                    {
                        ability1: "When equipped by Orvell, increase ATK by 50%, Equipment DMG by 20%.",
                        ability2: "Increase BE generation by 10%."
                    }
            },
        lore: 
            {   
                evo5: "The brave and benevolent first prince of the Gran Verge Kingdom. Orvell was the first in line to the throne and had a lot of support from the people. As a boy, he ate little and grew slowly, so he had a small frame, but when he saw his equally small-framed father brandish his huge sword, he made up his mind to get stronger and trained until he had a magnificent body that surpassed his father's.",
                evoawk: "MThe strong and brave Orvell finally took possession of the king's sword, the symbol of the next king. The feel of it in his hand made his royal blood run hot, and he brandished the heavy sword with ease. When Orvell first picked up the sword, he was full of confidence and energy, but soon he realized the true weight of the sword. Within him, a king's resolve had begun to sprout."
            },
        review:
            {
                skill: `An ability that is not very useful when it comes to bosses, but it can help speed up the mob rounds of a quest if it has one.`,
                arts: `A decent PHY RES debuff that can help with physical attackers in dealing more damage. This not only helps Orvell, but also the team's DPS for time when the extra damage is needed such as speeding up a quest or for nuking. Aside from that, Orvell's Arts should be used to start stacking the Testament to the King passive. Paired with an accuracy buff to himself, this helps with dealing with tricky enemies with evasion. The increased accuracy buff by itself is not enough as a standalone to compensate the evasion enemies has however, so it can't be relied on heavily. But because there aren't too many enemies that has evasion, the buff itself is pretty niche.`,
                truearts: `A high multiplier with a self stat boost to compliment it for more damage. With the stat boost, it can serve as a pseudo heal for Orvell, making him a bit more self-sustainable. At 5 stacks of Testament to the King, the damage is greatly increased  due to the ability to ignore enemies' DEF. Because of this, he is suitable for nuke compositions and can serve as a general DPS.`,
                crossarts: `Its high multiplier may seem appealing to deal more damage and a 50 art refund to both Orvell and Virago, but the prerequisite is also high from both units. Only at 5 stacks of both Testament to the King and Overlord’s Squire does the multiplier increase and by that time, the enemy would already be dead. The lack of synergy between Orvell and Virago also makes it difficult to fit them both into a team, making it very restricting on what units you can actually use.`,
                ability1: `This is the bread and butter of Orvell; with each Arts and True Arts, a damage buff is applied and Orvell's Skill CT REC is boosted as well. Because Orvell lacks innate crit, he's missing out on the extra damage that comes with crit. This ability helps him overcome that with a 200% DMG increase and the ignore DEF on his True Arts, which is very useful for all content and especially in stages that negates crit. With Orvell having high multipliers, the DMG increase helps him to a greater degree.`,
                ability2: `Being able to resist Freeze and Paralyze means that Orvell will not get interrupted during his Arts and True Arts, making him a more competent DPS`,
                ability3: `A minor buff that seldom sees any use because most often units' health does not drop to the threshold, even more so with Orvell having a pseudo heal on his True Arts.`,
                trueweapon: {
                    skill: `A niche buff that only applies to Light units. In terms of buffing Orvell himself, this increases his damage a considerable amount. But with its long CT, it makes it less useful for DPS and general content.`,
                    ability1: `A high buffing ability that helps improve Orvell's damage further. The attack up buff is magnified with Orvell's high damage multipliers and DMG Up buffs, making his damage much higher. There are other equips that you can use in turn however, so his True Weapon is not a must.`,
                    ability2: `A slight increase in BE generation may help, but overall it's nothing significant.`
                },
                overall: `Orvell is a great unit to use as an attacker, and with enough support, his damage can become very potent. His kit fits in both drawn-out battles and in nuke-oriented compositions. Orvell's offensive slot only further his outstanding capabilities, though as a result, he struggles to generate his own Arts. With the right team, Orvell can truly become a competent self-sustaining attacker.`,
                lastupdated: `September 8, 2020`,
                author: `Unknown`
            }
    },
    { //Virago - Complete, Review done
        id: 103036212,
        name: "Virago",
        rank: "F",
        luckrank: "F",
        attribute: "Water",
        type: "Dragon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103035212.png",
                detailawk: "/db/Units/Detail/unit_detail_103036212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103035212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103036212.png"
            },
        slots:
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 7308,
                hpplus: 999,
                atk: 2741,
                atkplus: 421,
                def: 2475,
                defplus: 381
            },
        skillset: 
            {
                skill: "12s CT - 2800% Water DMG (MAG). For 5s, decrease Enemies' ATK by 10%.",
                skillbreak: 700,
                arts: "15000% Water DMG (MAG). Reduce Enemies' Arts by 10 + For 10s, increase All Allies' DMG RES by 15%.",
                artsbreak: 1000,
                truearts: "36000% Water DMG (MAG). For 15s, increase God and Dragon Allies' DMG by 80%.",
                trueartsbreak: 1500,
                crossarts: "120000% Water DMG (MAG) and Light DMG (PHY). Increase Orvell's and Virago's Arts by 50. If used when there are 5 stacks of both Testament to the King and Overlord's Squire, increase the DMG Multiplier of this Cross Arts by an additional 80000%.",
                crossartsbreak: 4700
            },
        skillsetJP: 
            {
                skill: "12s CT - 2800% Water DMG (MAG). For 5s, decrease Enemies' ATK by 10%.",
                skillbreak: 700,
                arts: "15000% Water DMG (MAG). Reduce Enemies' Arts by 10 + For 10s, increase All Allies' DMG RES by 15%.",
                artsbreak: 1000,
                truearts: "36000% Water DMG (MAG). For 15s, increase God and Dragon Allies' DMG by 80%.",
                trueartsbreak: 1500,
                crossarts: "150000% Water DMG (MAG) and Light DMG (PHY). Increase Orvell's and Virago's Arts by 50. If used when there are 5 stacks of both Testament to the King and Overlord's Squire, increase the DMG Multiplier of this Cross Arts by an additional 150000%.",
                crossartsbreak: 4700
            },
        passive: 
            {
                ability1: "On use of Arts or True Arts, gain one stack of Overlord's Squire (Max 5 stacks). For each stack, increase ATK and DEF by 10%.",
                ability2: "Heal HP by 16/s.",
                ability3: "Increase Water RES by 10%.",
                ability4: "When Orvell is in the party: Increase Arts by 2/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragVirago.png",
                    mat3amt: 20,
                    hover3: "Virago",
                }
            },
        lore: 
            {   
                evo5: "A Dragon servant loyal to Orvell, the first prince of the Gran Verge Kingdom. The bond between the servant and his master is said to have been one of the strongest of all time. While Virago always acted rational on the surface, underneath that was a strong desire for improvement and the mind of a warrior. Orvell was like this himself, and it took no time for the two of them to share their dreams and apsirations.",
                evoawk: "The brave and fierce Dragon general who served Orvell, the first prince of the Gran Verge Kingdom. Orvell riding on the Dragon's back as they flew through the skies was a sight loved by children and adults. This grand appearance made soldiers revere him and greatly boosted the morale of the knights who guarded the kingdom. When the Dragon cavalry was organized in later years, they based the coat of arms on this imagery."
            },
        review:
            {
                skill: `A very negligible debuff with very little uptime and use`,
                arts: `A decent buff to reduce DMG taken for allies and might be useful for stages where one is lacking in DEF equips, but the Arts Drain being quite useless as it only drains 1/2 an orb making it hard to use.`,
                truearts: `A sizable buff that allows Virago to be a decent DPS, but overall not that useful due to the restrictions as most Gods/Dragons units are not the best in terms of DMG dealing.`,
                crossarts: `Despite the large amount of DMG and the Arts Refund, the prerequisite is also high from both units. Only at 5 stacks of both Testament to the King and Overlord's Squire does the multiplier increase and by that time, the enemy would already be dead. The lack of synergy between Orvell and Virago also makes it difficult to fit them both into a team, making it very restricting on what units you can actually use.`,
                ability1: `An okay passive that slightly increases Virago's offensive and defensive capabilities`,
                ability2: `A negligible heal passive`,
                ability3: `It is not the best passive to have but not the worst`,
                ability4: `A good passive to have if you plan to use them together but they restrict the team a lot. However it certainly does help Virago offset his slow slots`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Overall Virago is a decent Ultra Luck unit who can output a decent amount of DMG despite his kit. His Arts would be his primary use, though it is not the best for luck farming. He is a decent free unit despite having better options to use over him.`,
                lastupdated: `September 9, 2020`,
                author: `The True Pepe`
            }
    },
    { //Luahn - Complete, Review done + JP Dream
        id: 103046512,
        name: "Luahn",
        rank: "B",
        twrequire: "favorable",
        attribute: "Dark",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103045512.png",
                detailawk: "/db/Units/Detail/unit_detail_103046512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103045512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103046512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7300,
                hpplus: 999,
                atk: 3000,
                atkplus: 460,
                def: 2560,
                defplus: 394
            },
        skillset: 
            {
                skill: "12s CT - 2000% Dark DMG (PHY). Increase own Arts by 30 + Increase own Arts by 20 if this Skill crits.",
                skillbreak: 600,
                arts: "20000% Dark DMG (PHY). Inflicts a stack of Bleed (up to 3 stacks).",
                artsbreak: 1200,
                truearts: "48000% Dark DMG (PHY). Increase own Arts by 20. For 15s, increase Allies' Critical Rate by 100%.",
                trueartsbreak: 2700,
                crossarts: "60000% Dark DMG (PHY). Increase Chloe and Luahn's Arts by 100. Sacrifice 50% of Chloe's maximum HP. For 120s, increase Luahn's Arts by 3/s, Skill CT Speed and ATK by 100%.",
                crossartsbreak: 3000
            },
        skillsetJP: 
            {
                skill: "12s CT - 2000% Dark DMG (PHY). Increase own Arts by 30 + Increase own Arts by 20 if this Skill crits.",
                skillbreak: 600,
                arts: "20000% Dark DMG (PHY). Inflicts a stack of Bleed (up to 3 stacks).",
                artsbreak: 1200,
                truearts: "65000% Dark DMG (PHY). Increase own Arts by 20. For 20s, increase Allies' Critical Rate by 100%.",
                trueartsbreak: 2700,
                crossarts: "100000% Dark DMG (PHY). Increase Chloe and Luahn's Arts by 100. Sacrifice 50% of Chloe's maximum HP. For 120s, increase Luahn's Arts by 6/s, Skill CT Speed and ATK by 100%.",
                crossartsbreak: 3000
            },
        passive: 
            {
                ability1: "Increase Allies' Critical DMG by 30%. Does not stack with Abilities of the same name.",
                ability2: "Increase DMG to Enemies with 2 or more stacks of Bleed by 100%.",
                ability3: "Heal own HP by 1% of DMG dealt to Enemies with 3 stacks of Bleed."
            }, 
        passiveJP: 
            {
                ability1: "Increase Allies' Critical DMG by 30%. Does not stack with Abilities of the same name.",
                ability2: "Increase DMG to Enemies with 2 or more stacks of Bleed by 150%.",
                ability3: "Heal own HP by 1% of DMG dealt to Enemies with 3 stacks of Bleed."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 2,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 2,
                    hover4: "Radachne",
                }
            },
            dreamJP: {
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                        mat3amt: 2,
                        hover3: "Wilkes",
                    },
                    passive: {
                        ability1: `Increase own DMG against Light enemies by 100%.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragOldeus.png",
                        mat3amt: 2,
                        hover3: "Oldeus",
                    },
                    passive: {
                        ability1: `Increase own DMG against Demon enemies by 100%.`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Insight",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_107971.png",
                skill: "45s CT - 10000% Dark DMG (PHY). For 10s, increase Skill CT REC by 30% and Critical Rate by 70%.",
                skillbreak: 1300,
                passive: 
                    {
                        ability1: "When equipped by Luahn, increase own Critical DMG by 50%. Does not stack with Abilities of the same name.",
                        ability2: "Increase PHY DMG by 20%."
                    }
            },
        lore: 
            {   
                evo5: "The second prince of Gran Verge. As he came into the world on the night of a red moon, many shunned him in private if not publicly. After a shipwreck in foreign waters, he went missing for almost ten years until news of his whereabouts surfaced. His siblings had prepared a warm welcome, but they were instead shocked by how much he had changed.",
                evoawk: "Iscator's strange mist slipped into the cracks of his fragile heart, gradually turning his disposition dark and dreary.. All that could get through to his blinded, deafened soul was that blatantly malicious shadow. Surrendering himself to its influence, he finally felt at ease. However, this put him at odds with the righteous nature of his royal blood. The internal struggle continues to tear him apart, sometimes forcing to spit up a thick, viscous miasma."
            },
        review:
            {
                skill: `This allows for good self-artsgen, regardless of CRIT or not making this a very consistent skill due to it always minimally giving you 30 arts.`,
                arts: `The DMG RES Down potency is one thing, but the fact that it is permanent and on a DPS such as Luahn makes his team hit hard, and him hit even harder. Ideally, you would use this 2 to 3 times before going for TA spam, due to the synergy with his other Bleed related passives.`,
                truearts: `High multiplier and the CRIT Chance on TA makes for some very high damage. This provides decent teamwide DPS support. Additionally, while the refund of 20 Arts is small, it is still appreciated in allowing him to use his TA more often.`,
                crossarts: `Truly, the best Cross Art in the whole game. Although its effects are very selfish to Luahn himself, this immensely boosts the best aspects of Luahn's kit. His self-sustainability and his notable damage.`,
                ability1: `Well look at that synergy. This again, boosts his and his Allies DMG, since it is assumed you will be using Luahn's True Arts so they would all crit`,
                ability2: `This is the reason to stack of Luahn's Bleed at least twice. Considering that Bleed is permanent, Luahn will always have 100% DMG Up to his enemies`,
                ability3: `Just to capatlize on all the immense damage Luahn will be doing if Bleed is stacked, this neat ability allows for him to directly reap the benefits and heal himself.`,
                trueweapon: {
                    skill: `Outside of units like Luahn who depend on Skill for self-sufficiency, this TW is mediocre at best. On him though, it allows him to somewhat work around Enemies' with CRIT RES or even score a cheeky critical on Skill, giving him more arts.`,
                    ability1: `To boost his damage even more. Taking into account his Bleed mechanics and synergy and CRIT buffs, this is guaranteed to make Luahn hit even harder, if you weren't already convinced.`,
                    ability2: `A significant damage boost to any Physical-based DPS, which is always appreciated.`
                },
                overall: `From the get-go (and get-up), Luahn proves to be an insanely strong DPS, even managing to cover his DPS weaknesses should the enemy have CRIT Negate/RES due to his Bleed and TW active ability. However, he is still at the end of the day just another DPS who has little to no versatility outside of his damage and crit-buffing. Furthermore, he depends on Chloe in order to truly achieve the high numbers you may see on TA, reducing your unit comps immensely to be built around him. All in all, a very good one-trick pony that makes his presence known amongst other contenders.`,
                lastupdated: `April 6, 2021`,
                author: `Berwick`
            }
    },
    { //Chloe (GS) - Complete, Review done
        id: 103056511,
        name: "Chloe (GS)",
        luckrank: "C",
        rank: "B+",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103055511.png",
                detailawk: "/db/Units/Detail/unit_detail_103056511.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103055511.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103056511.png"
            },
        slots:
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6857,
                hpplus: 999,
                atk: 2750,
                atkplus: 422,
                def: 2222,
                defplus: 343
            },
        skillset: 
            {
                skill: "8s CT - 1600% Dark DMG (PHY). For 4s, increase own PHY evasion by 15%.",
                skillbreak: 300,
                arts: "15000% Dark DMG (PHY). For 8s, increase Allies' stats and PHY DMG by 20%.",
                artsbreak: 1200,
                truearts: "30000% Dark DMG (PHY). For 20s, reduce Enemies' ATK, Dark RES, and PHY RES by 30%.",
                trueartsbreak: 2000,
                crossarts: "60000% Dark DMG (PHY). Increase Chloe and Luahn's Arts by 100. Sacrifice 50% of Chloe's maximum HP. For 120s, increase Luahn's Arts by 3/s, Skill CT Speed and ATK by 100%.",
                crossartsbreak: 3000
            },
        skillsetJP: 
            {
                skill: "8s CT - 1600% Dark DMG (PHY). For 4s, increase own PHY evasion by 15%.",
                skillbreak: 300,
                arts: "15000% Dark DMG (PHY). For 8s, increase Allies' stats and PHY DMG by 20%.",
                artsbreak: 1200,
                truearts: "30000% Dark DMG (PHY). For 20s, reduce Enemies' ATK, Dark RES, and PHY RES by 30%.",
                trueartsbreak: 2000,
                crossarts: "100000% Dark DMG (PHY). Increase Chloe and Luahn's Arts by 100. Sacrifice 50% of Chloe's maximum HP. For 120s, increase Luahn's Arts by 6/s, Skill CT Speed and ATK by 100%.",
                crossartsbreak: 3000
            },
        passive: 
            {
                ability1: "Heal HP by 20/s.",
                ability2: "When Luahn is in party: At the start of a Quest, increase own Arts by 100 + Increase Arts Gauge by 3/s. Does not stack with Abilities of the same name.",
                ability3: "When this unit takes lethal DMG, revive self with 100% HP. Can only be activated once per Quest."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragChloe.png",
                    mat3amt: 20,
                    hover3: "Chloe",
                }
            },
        lore: 
            {   
                evo5: "A Human servant who has sworn loyalty to the second prince of Gran verge, Luahn. She was once a thoroughly average housekeeper, but a curse of immortality utterly changed her. It freed her from the bonds of death, but when those she had known as children died of old age, she realized everlasting life was no different from torture.",
                evoawk: "An attendant of the second prince of Gran verge, Luahn. She had given up on her personality and humanity, only seeking salvation in being used like a tool. This made her the perfect sacrificial pawn for the king of Gran Verge, and the turning point came when she was appointed to serve the detested prince. Finally finding someone with whom she could commiserate, her heart slowly began to open up again."
            },
        review:
            {
                skill: `Nothing too special about this ability; the evasion is nice to have, but on most non tank units, you aren't planning on taking too much damage anyways`,
                arts: `The ability to increase Allies' stats makes Chloe one of the only pseudo-healing luck units in game. In addition, the PHY DMG bonus can help boost your team's damage output, though the duration leaves much to be desired. You will most likely be using her True Arts instead.`,
                truearts: `Chloe's True Arts inflicts an incredibly potent res down debuff on enemies that has an equally incredible duration. The ATK debuff and the base damage scaling are also nothing to scoff at, making this ability one of the strongest True Arts amongst the other luck units in terms of damage and nuking potential.`,
                crossarts: `Unlike other Cross the Blaze units, this Cross Arts has no direct impact on other team members, while focusing instead on solely Chloe and Luahn. The Arts refund is massive, and the combination of Chloe's 3 APS passive (when Luahn is in the party) with Luahn's 3 APS generation on Cross Arts allows you to essentially spam their Cross Arts as long as Chloe remains alive. The other buffs to Luahn allow him to bring a multitude of buffs, debuffs, and damage to the team. Ultimately, whether True Arts or Cross Arts is used still depends on the situation and the team comp.`,
                ability1: `A nice ability to have, though a mere band aid on her Cross Arts HP drain.`,
                ability2: `An extremely potent self-sufficiency passive on the unfortunate condition that Luahn is in the party.`,
                ability3: `Another nice ability to have that allows possible use in the Arena, as well as general gameplay in case your hp drops too low. This also compliments her Cross Arts HP drain.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Chloe is a powerful unit with potent debuffs, hindered mainly by a lack of self sufficiency unless Luahn is on her team. She can be used as a DPS but shines mostly as a nuke support unit in mono dark teams, or as a complement to Luahn. In this role, Chloe can provide great physical/dark debuffs to the enemy, as well as a defense equip that allows the team to survive any initial nukes or damage the boss might inflict, such as the Sanctuary of the Bright Spirit giant boss. When Chloe wears a Raaz Crown, and steps into the arena, her revive passive also allows her to become a cheesey arena unit. Overall, a very powerful luck unit and normal unit.`,
                lastupdated: `April 6, 2021`,
                author: `Orixy`
            }
    },
    { //Angelas - Complete, Review done
        id: 103066112,
        name: "Angelas",
        rank: "B+",
        twrequire: "favorable",
        attribute: "Fire",
        type: "Dragon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103065112.png",
                detailawk: "/db/Units/Detail/unit_detail_103066112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103065112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103066112.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6378,
                hpplus: 999,
                atk: 2990,
                atkplus: 458,
                def: 2434,
                defplus: 375
            },
        skillset: 
            {
                skill: "10s CT - 2300% Fire DMG (PHY). Increase Dragon Flame Count by 1. Chance (50%) to inflict Burn.",
                skillbreak: 600,
                arts: "20000% Fire DMG (PHY). Increase Dragon Flame Count by 3. For 15s, reduce Enemies' Burn RES by 50%.",
                artsbreak: 1000,
                truearts: "35000% Fire DMG (PHY). Reduce Dragon Flame Count to 0. Increase own Arts Gauge by [Original Dragon Flame Count × 25], increase this True Arts' Multiplier by [Original Dragon Flame Count × 12000%].",
                trueartsbreak: 1500
            },
        passive: 
            {
                ability1: "Increase Critical Rate by 100% against Burned Enemies.",
                ability2: "Increase own DMG by 30% against Enemies in BREAK.",
                ability3: "Dragon Flame: Counts disappear after 60s (Max 8 stacks). Decrease Enemies' Fire RES by [Dragon Flame Count × 5%], deal [Dragon Flame Count × 350%]/s Fire DMG (PHY) to Enemies."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat3amt: 2,
                    hover3: "Ifrit",
                    mat4: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                    mat4amt: 2,
                    hover4: "Degilmagna",
                    mat5: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat5amt: 1,
                    hover5: "Radachne"
                }
            },
            dream: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragFlogassa.png`,
                        mat3amt: 2,
                        hover3: `Flogassa`
                    },
                    passive: {
                        ability1: `Increase CRI DMG by 50%.`,
                        ability2: `Increase Skill CT Speed by 20%.`
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.pn`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragRadachne.png`,
                        mat3amt: 2,
                        hover3: `Radachne`
                    },
                    passive: {
                        ability1: `Increase Accuracy rate by 50%.`,
                        ability2: `Increase own Arts gauge by 2/s.`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Drago La'cryma",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_107631.png",
                skill: "55s CT - 9000% Fire DMG (PHY). 100% chance to inflict Burn. If Enemy was burned by this Skill, for 8s, decrease that Enemy's PHY RES by 35%.",
                skillbreak: 1600,
                passive: 
                    {
                        ability1: "When equipped by Angelas, increase Critical DMG by 60%, and increase Duration of Dragon Flame Counts by 10s. Does not stack with Abilities of the same name.",
                        ability2: "Increase ATK by 10%."
                    }
            },
        lore: 
            {   
                evo5: "A scion of the Dragons, who spent her childhood in an orphanage, without even knowing the names or faces of her parents. Years later, it is said that she was given to the family of a rich merchant of the Gods. However, the merchant and her mysteriously went missing at the same time after a few years. During the upheaval of Gran Verge Kingdom, she was seen wielding a great axe on the side of the king. The ghoulish expression on her face brought forth memories of painful pasts for many a people.",
                evoawk: "A warrior of the Dragons wielding a fearsome axe into battle. Having awoken abilities beyond those of the average Dragon due to her time spent in a hellish environment. Though in part her power came from her youth, her very power was the personification of menace causing those that crossed her path to tremble for their lives. She had always been a solitary figure, but one encounter brought about change to that way of life. The small hand of a heartwarming young girl reached out to her, bringing about change in her way of life."
            },
        review:
            {
                skill: `Should net you one Dragon Flame stack from the get-go for most stages, though a bit slow on its own if you actually plan to build her up. Burn can also play into Angelas' passive, but it's a far from reliable source of critical hit chance.`,
                arts: `Quick dragon flame stacks, though it's incredibly awkward given the fact that you'll require additional arts support specifically to sustain Angelas given the necessity of these stacks for her nuke. The burn resistance reduction is hardly ever useful despite its chained synergy with Angelas' Skill, Crit Passive, and True Weapon passive - it's just much too demanding and inconsistent of a source of crit chance.`,
                truearts: `Angelas' True Arts is completely at the mercy of how many Dragon Flame stacks you've acquired: it's a more than solid multiplier to go off of for nuking when her passive is sufficiently stacked, especially given her Dragon Flame buffs; but it's an utter waste of time without them. Her arts refund should allow you to recycle stacks more effectively in the case you're using her as a DPS unit rather than a nuker, though this isn't the best approach for reasons that'll be discussed later.`,
                ability1: `While this does go hand and hand with Angelas' own Arts and Skill, it can be a bit too inconsistent for nuking given you likely won't have time to apply burn resistance down with her arts before you burst the boss down. Still always a nice thing to have despite the fact that it doesn't work well with her intended purpose.`,
                ability2: `A significant enough damage buff, though breaking as a mechanic is seen in increasingly few pieces of content recently, causing this passive to come into play once in a blue moon at best.`,
                ability3: `This passive provides the main gimmick that Angelas' entire kit revolves around, providing quite a meaningful offensive debuff for her and her team as well as dealing significant damage, though this generally gets overshadowed by her True Arts damage.`,
                trueweapon: {
                    skill: `The key to this True Weapon is that due to its conditional nature, it stacks with other sources of Physical DMG Resistance down such as 'Snippity-Snips' or 'Type 30 Bayonet' to name a few, making this an amazing resource for bolstering the damage of not only teams Angelas takes part in, but physical nukes as a whole.`,
                    ability1: `Quite a large damage boost to encourage the use of her burn mechanic, though you're oftentimes better off using an external source of critical hit rate. As for the passive boost, it really shouldn't be too significant if you're aptly supporting Angelas in the first place.`,
                    ability2: `A pretty straight-forward passive that increases the User's offensive capabilities. While small, it's certainly good to have on any attacker unit.`
                },
                overall: `Coming off as a solid attacker and more specifically a nuker, though not a groundbreaking one for the most part, Angelas is the owner of one of the highest multipliers in the game. And to take full advantage of it, she requires a great amount damage-optimizing buffs such as DMG Up and DMG Resistance down from other sources, making her optimal role in nuking given how short-lived these buffs generally are. That is, however, not to say that her DPS capabilities are underwhelming, just that her lack of self-sufficiency and higher maintenance than most DPS units hinder her in that aspect. Overall, a competent unit who suffers due to difficult Dragon Flame stacking and generally lags behind her team as a result.`,
                lastupdated: `November 2, 2020`,
                author: `Elric`
            }
    },
    { //Eagle - Complete, Review done
        id: 103076312,
        name: "Eagle",
        rank: "A-",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103075312.png",
                detailawk: "/db/Units/Detail/unit_detail_103076312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103075312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103076312.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7275,
                hpplus: 999,
                atk: 2259,
                atkplus: 348,
                def: 3311,
                defplus: 506
            },
        skillset: 
            {
                skill: "10s CT - 2000% Earth DMG (PHY). For 6s, increase own DEF by 20%.",
                skillbreak: 600,
                arts: "16000% Earth DMG (PHY). For 25s, redirect Allies' DMG taken to self & gain a DMG barrier that negates 10000 DMG to self. During Avenger Mode: For 25s, redirect Allies' DMG taken to self & gain a DMG barrier that negates 14000 DMG to self.",
                artsbreak: 1200,
                truearts: "30000% Earth DMG (PHY). For 15s, increase own DMG RES by 25%. During Avenger Mode: 110000% Earth DMG (PHY). For 15s, increase own DMG RES by 25% + Steal 100% of Enemies' Arts and exit Avenger Mode.",
                trueartsbreak: 2000
            },
        passive: 
            {
                ability1: "Increase Gauge when being attacked. When Animosity Gauge is full, enter Avenger Mode for 60s. Avenger Mode increases ATK by Own DEF, Freeze and Paralysis RES by 100%, and prevents Animosity Gauge from increasing. Animosity Gauge fully drains over 60s, or when True Arts is used during Avenger Mode.",
                ability2: "Increase DEF by 20% when under a DMG barrier."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragValzandeth.png",
                    mat3amt: 1,
                    hover3: "Valzandeth",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 2,
                    hover4: "Code-XTF",
                    mat5: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat5amt: 2,
                    hover5: "Goldola"
                }
            },
        dream: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragGeoZagnus.png`,
                        mat3amt: 2,
                        hover3: `Geo Zagnus`
                    },
                    passive: {
                        ability1: `Increase Freeze RES by 100%.`,
                        ability2: `Increase own Arts gauge by 3/s.`
                    }
                },
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragTonetaurus.png`,
                        mat3amt: 2,
                        hover3: `Tonetaurus`
                    },
                    passive: {
                        ability1: `Increase DMG RES by 15% when Near-death.`,
                        ability2: `Increase own Arts gauge by 6/s when Near-death.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragRagsherumPhantom.png`,
                        mat3amt: 2,
                        hover3: `Ragsherum Phantom`
                    },
                    passive: {
                        ability1: `Increase own Arts gauge by 100 at the Start of Quest.`,
                        ability2: `Increase DMG against God enemies by 100%.`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Arsenal",
                slot: "/db/Mines/thumbnail/defLB.png",
                detail: "/db/Equipment/Detail/item_detail_107761.png",
                skill: "25s CT - For 12s, Target gains a DMG barrier that negates 5000 DMG, and increase Target's DEF by 50%.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Eagle, increase Animosity Gauge Fill Rate by 50% and Arts DMG by 50%. Does not stack with Abilities of the same name.",
                        ability2: "When near-death, increase DMG RES by 15%."
                    }
            },
        lore: 
            {   
                evo5: "A human warrior relentlessly hunting the scions of Gran Verge. When his hometown on the kingdom's fringes was attacked by Demons, he could do nothing but watch its destruction. This combined with his discontent at the Gods' inadequate response caused his hatred to swell over the years. As a result, when someone invited him to join their plot to seize the throne, he was all too willing to accept.",
                evoawk: "A warrior with a large, mechanized shield. Burdened with feelings of inferiority, his stubborn mental isolation means neither encouragement nor criticism can reach him. In a sense, the shield he carries also represents that wall between himself and others with its hidden blade piercing anyone who tries to breach it. Even so, it's possible he has not yet forgotten the warmth of comradery."
            },
        review:
            {
                skill: `Defence buffs are always appreciated, but this is nothing special. If this was Damage Resistance buff instead however, this would be much better, especially since he is a taunt.`,
                arts: `His magnum opus! Surrisingly, this is easily the longest taunt duration on a Normal Art, even when compared to something such as Thetis' 15 second 10k Barrier on NA. Primarily with Eagle, this Art should be what you ought to be using all the time.`,
                truearts: `I've never seen a more lacklustre ability. 10% Damage Resistance is just so weak. This is a disappointment of a True Art, and you're better off always using Arts. And no, the Arts Drain on TA is not enough justifying using TA in even in Avenger Mode.`,
                ability1: `In short, Avenger Mode is Eagle's "Magia", though it is quite weak, focusing on boosting his offensive capabilities instead of his primary defensive kit, making him a weird DPS taunt tank archetype. The Paralysis and Freeze Resistance however, is very helpful and drastically improves his performance as a Taunt. Due to the unique condition of Avenger Mode, however, causes these buffs to be inconsistent on him.`,
                ability2: `Considering how a majority of the bosses that would warrant using Eagle all deal critical damage, this buff is essentially negated. If only this was Damage Resistance....`,
                trueweapon: {
                    skill: `Incredibly mediocre as a Defence equip. The only redeeming factor of this equip is its decent defence buff (which is essentially negated by Critical Damage) and its great recovery time. Still, not remotely worth getting or using.`,
                    ability1: `Because Eagle is a DPS, right. The Arts DMG boost is appreciated, but again very out of place for a unit that should be trying to defend and stay alive more than do damage. The Fill Rate however, does allow Eagle to reach his max potential as a Taunt faster, so that is a very good ability that works with his kit.`,
                    ability2: `While you shouldn't be reaching the near-death state in general, this is probably the most potent aspect of Eagle's TW, and this buff is not to underappreciated. Definitely helpful, if you do hit near-death.`
                },
                overall: `The devs came up with an attempt at a DPS Taunt tank that was interesting in theory, but mediocre in practice, due to not having any potent in-built Damage Resistance tied to his kit. Add to the fact that his TA is literally 10% Damage Resistance with a 60k% multiplier in Avenger Mode, and you get a mismatched taunt tank that wanted to hit hard, but threw away almost all the aspects of what makes a defensive unit defensive, apart from the slots.`,
                lastupdated: `March 5, 2021`,
                author: `Berwick`
            }
    },
    { //Bakoo - Complete, Review done + JP Dream
        id: 103086512,
        name: "Bakoo",
        rank: "B-",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103085512.png",
                detailawk: "/db/Units/Detail/unit_detail_103086512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103085512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103086512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6666,
                hpplus: 999,
                atk: 2677,
                atkplus: 411,
                def: 2531,
                defplus: 389
            },
        skillset: 
            {
                skill: "11s CT - 2200% Dark DMG (MAG). For 6s, reduce Enemies' DMG RES by 10%.",
                skillbreak: 500,
                arts: "16000% Dark DMG (MAG). For 10s, reduce Enemies' DEF and Dark RES by 30%.",
                artsbreak: 1100,
                truearts: "40000% Dark DMG (MAG). Target the Ally that most recently used an Arts/True Arts with Ingested. Increase Allies' (except target) Arts by 30 + For 15s, increase Allies' (except target) Arts by 4/s and MAG DMG by 200%.",
                trueartsbreak: 1600
            },
        skillsetJP: 
            {
                skill: "11s CT - 2200% Dark DMG (MAG). For 6s, reduce Enemies' DMG RES by 20%.",
                skillbreak: 500,
                arts: "20000% Dark DMG (MAG). For 15s, reduce Enemies' DEF and Dark RES by 40%.",
                artsbreak: 1100,
                truearts: "55000% Dark DMG (MAG). Target the Ally that most recently used an Arts/True Arts with Ingested. Increase Allies' (except target) Arts by 50 + For 15s, increase Allies' (except target) Arts by 5/s and MAG DMG by 300%.",
                trueartsbreak: 1600
            },
        passive: 
            {
                ability1: "Increase evasion by 10%.",
                ability2: "Ingested: Faints the target for 5s, and sacrifice 30% of that target's current HP when the Faint expires.",
                ability3: "Increase own Arts by 10 for each enemy killed."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 1,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat4amt: 2,
                    hover4: "Oldeus",
                    mat5: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat5amt: 2,
                    hover5: "Mavroskia"
                }
            },
            dreamJP: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                        mat3amt: 2,
                        hover3: "Freydal",
                    },
                    passive: {
                        ability1: `Increase own Equip CT Speed by 30%.`
                    }
                },
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragGoldola.png",
                        mat3amt: 2,
                        hover3: "Goldola",
                    },
                    passive: {
                        ability1: `Increase own Status Ailment RES (except Faint) by 100%.`,
                        ability2: `Increase own DMG by 50% when enemies' HP is under 30%.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragValzandeth.png",
                        mat3amt: 2,
                        hover3: "Valzandeth",
                    },
                    passive: {
                        ability1: `Increase all Dark allies' Arts by 2/s. Does not stack with Abilities of the same name.`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Discontent",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_107821.png",
                skill: "50s CT - 9000% Dark DMG (MAG). Increase own Arts by 30 + For 8s, reduce Enemies' Dark RES by 30%.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Bakoo, increase ATK by 30%,  and at the start of a Quest, increase Arts by 50.",
                        ability2: "Increase MAG DMG by 10%."
                    }
            },
        lore: 
            {   
                evo5: "A doll that was once well-known throughout Gran verge. No one knows how it can move and talk on its own. Regardless, it's believed to be highly advanced since it has the capability for sarcasm and arrogance, along with its store of knowledge about the Tower of Providence. Its original owner was a girl named Milay, who was overjoyed to finally have a friend.",
                evoawk: "A stuffed toy that only used to cause trouble with its sharp tongue and unpredictable appearances. Now, its mysterious warmth has been replaced with hatred and malice. It despises anything that lives, regardless of race. Words have no effect as it destroys all in its path."
            },
        review:
            {
                skill: `A small boost to the team’s DMG if there are no other active DMG RES downs.`,
                arts: `Able to inflict offensive Debuffs, DEF% down is really insignificant being overshadowed by Crit DMG, the appeal being a decent Dark RES down. This Arts should only warrant use in bursting an Enemy in their last moments as Bakoo’s True Arts are far better.`,
                truearts: `A Burst of Artsgen topped with a massive MAG DMG% buff, the largest up to date. While the effects are extremely powerful, Ingested, disables an Ally with a Heavy Faint, losing the benefits of Bakoo’s True Art and the aftermath of draining the Ally’ HP can make a team much more difficult to play well or even inconsistent at worst especially in prolonged fights and sometimes shutting down the entire team if an Ally with a crucial role on the team were be hit with Ingested.`,
                ability1: `A small boost to Bakoo' survivability.`,
                ability2: `A very tricky gimmick to Bakoo: if Bakoo’s True Arts isn't properly timed for usage, the hefty consequence of Ingested can shut down an entire team`,
                ability3: `A good boost to Bakoo's Arts sufficiency, but should not be relied on`,
                trueweapon: {
                    skill: `Marginally better than the readily-accessible 'Akashic Eye', Bakoo's TW provides an additional deadly debuff that’s highly favourable towards him and other dark attackers.`,
                    ability1: `Small boost to Bakoo's Dmg, and a nice Start Passive. Accounting the TW's active Skill makes this Equip Bakoo's Best-in-Slot`,
                    ability2: `A nice offensive Buff`
                },
                overall: `Overall, Bakoo is an extremely potent buffer, but one that will actively make life much harder as you play around his mechanics. Due to this, his usage within Teams are very limited even more so for belonging in the oversaturated Type B, most players rather run a traditional support then consider using Bakoo. Still, his kit can still be of very interesting use within the nuking department for his absurd buffs and decent slotting.`,
                lastupdated: `March 5, 2021`,
                author: `Unex`
            }
    },
    { //Victoire - Complete, Review done
        id: 103096412,
        name: "Victoire",
        rank: "B",
        twrequire: "favorable",
        attribute: "Light",
        type: "Spirit",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103095412.png",
                detailawk: "/db/Units/Detail/unit_detail_103096412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103095412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103096412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 7393,
                hpplus: 999,
                atk: 2696,
                atkplus: 414,
                def: 2643,
                defplus: 406
            },
        skillset: 
            {
                skill: "9s CT - 1300% Light DMG (MAG). For 10s, increase Allies' DMG by 10% (stackable).",
                skillbreak: 600,
                arts: "15000% Light DMG (MAG). For 15s, increase Allies' Skill CT REC by 80%, for Light Allies, increase Skill CT REC by 160% instead.",
                artsbreak: 1000,
                truearts: "36000% Light DMG (MAG). For 15s, increase Allies' stats and accuracy by 30%, for Light Allies, increase stats and accuracy by 60% instead.",
                trueartsbreak: 1300
            },
        passive: 
            {
                ability1: "After Skill is used three times, for 10s, reduce Enemies' action speed 20%. Does not stack with Abilities of the same name.",
                ability2: "Increase DMG to non-Boss Enemies with less than 30% HP by 200%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 1,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 3,
                    hover4: "Radachne",
                    mat5: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat5amt: 1,
                    hover5: "Oldeus"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Cantabile",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_107941.png",
                skill: "50s CT - 6000% Light DMG (MAG). For 10s, reduce Enemies' Light RES by 50%.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Victoire, increase Arts by 2/s + Increase duration of True Art's Buffs by 10s. Does not stack with Abilities of the same name.",
                        ability2: "Increase MAG RES by 10%."
                    }
            },
        lore: 
            {   
                evo5: "A girl of the Spirits who was raised on hatred. She was persecuted from birth for her differently colored eyes as the rest of her kind viewed it as a sign of impurity, but they didn't kill her as they believed it'd release those impurities into the world. This truth was only revealed to her several years later when she obtained a special power. It was then she decided to turn her loneliness into a weapon of vengeance against not only her brethren, but the Gods as well.",
                evoawk: " master musician who transforms her deep sadness and anger into sound. Born without a family, she was maltreated even at the orphanage, unaware of the reason why. An attempt to take her life ended up changing it, as her brush with death unlocked the ability to read the memories of objects. Finally aware of the stark truth, her suffering became fuel for vengeance."
            },
        review:
            {
                skill: `While not too potent of a boost on its own, it does become reasonably powerful as an offensive battery when it's used in conjunction with her normal Arts or when used in battles with fast and snappy substages.`,
                arts: `An extremely potent form of a niche buff. Skill CT recovery speed buffs honestly don't do many wonders when it comes to increasing the overall effectiveness of many units let alone teams. While it does have its limited utility and notable synergy with Victoire's own skill, her True Art will likely be a more effective use of resources.`,
                truearts: `Those are some pretty nice buffs there for light units. Unfortunately, if you aren't a light unit, what you get is a decent, though still genuinely useful pseudo-heal and an extremely niche buff to accuracy. However, if you're running this on a mono-light team, Victoire becomes one of the most consistent and potent healers in the game on top of noticeable buffs. Still, quite a niche scenario you'd have to be in to find yourself resorting to such a composition`,
                ability1: `Quite a noticeable debuff when it's applied to a large enough enemy, and when combined with her arts buff, she can almost loop. While this is an all-around decent tool in her box for survivability, its usefulness can be inconsistent at best due to the diversity in the game's stages.`,
                ability2: `Victoire isn't primarily an attacker, and even then, this buff is so niche that it just won't matter in the majority of situations despite its high figure.`,
                trueweapon: {
                    skill: `A surprisingly potent debuff for... light nuke teams? Quite unfitting for a light support unit to say the least. While this role and effect mismatch doesn't entirely justify it on Victoire, it's certainly looking like a staple for a different kind of composition.`,
                    ability1: `That's more like it! This is the potent arts generation option if you plan on using Victoire, and should definitely be seen as a must-have equipment to get the full potential out of her. The duration increase is nice, but her buffs weren't the most critical to be able to cycle to begin with.`,
                    ability2: `Not a particularly bad passive, but it'll be inconcequential most of the time due to its lower potency.`
                },
                overall: `Victoire ticks all the boxes for being a niche support who still has a place on certain teams. Conditionally potent buffs? Check. Highly-situational utility? Check. Gimmicky mechanics that really shine on select stages? Another check. That being said, what she brings to the table, especially for mono light teams can't be understated, becoming one of the most potent healers and stats buffers in the game. Outside of the few scenarios she can be fully-utilized, her slot is best reserved for a more general-purpose unit. And thus, although helming some extremely flashy support, you'll be unlikely to see her as the leader of most bands.`,
                lastupdated: `March 16, 2021`,
                author: `Chase`
            }
    },
    { //Forte - Complete
        id: 103106512,
        name: "Forte",
        rank: "A-",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103105512.png",
                detailawk: "/db/Units/Detail/unit_detail_103106512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103105512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103106512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6884,
                hpplus: 999,
                atk: 2941,
                atkplus: 451,
                def: 2210,
                defplus: 341
            },
        skillset: 
            {
                skill: "9s CT - 2000% Dark DMG (PHY). Increase own Arts by 12.",
                skillbreak: 800,
                arts: "10000% Dark DMG (PHY). On hit, increase own Arts by 100 against Enemies in Break (up to once) + For 30s, decrease Enemies' Dark RES by 10% (stackable).",
                artsbreak: 1100,
                truearts: "30000% Dark DMG (PHY). For 10s, increase Dark Allies' DMG by 65%, Human Allies' DMG by 65%.",
                trueartsbreak: 3100
            },
        passive: 
            {
                ability1: "Increase Allies' Break PWR by 10%.",
                ability2: "Increase Allies' Critical DMG by 30%.",
                ability3: "At the start of a Quest, increase Allies' Arts by 50. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 1,
                    hover3: "RagsherumPhantom",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 2,
                    hover4: "Code-XTF",
                    mat5: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat5amt: 1,
                    hover5: "Elpneumas"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Assault Rifle Sable",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_107541.png",
                skill: "50s CT - 9000% Dark DMG (PHY). For 15s, increase own PHY DMG by 30%; if user is Human, increase own PHY DMG by 60% instead.",
                skillbreak: 900,
                passive: 
                    {
                        ability1: "When equipped by Forte, increase DMG by 30%, Skill CT REC by 50%. Does not stack with Abilities of the same name.",
                        ability2: "Increase accuracy by 20%."
                    }
            },
        lore: 
            {   
                evo5: "The commander of a squad who successfully completed the mission to form a peace treaty with the Kingdom of Garrah. Despite her young age she has led many troops. Because she does not show her emotions, she is often perceived as cold-hearted, but in fact, she is kind-hearted and cares deeply for her troops. She lost her parents in a war when she was a child, and as a result of this experience, she supports and believes in a solution through communication rather than force. The president of a country with whom she once undertook a mission with was said to have admired her unwavering eyes.",
                evoawk: "A commander within a small nation's military forces. While being deeply trusted by her subordinates and the King, above all else she is loved by the people of the country. A cool, yet sincere hardworking military officer with incredible physical capabilities, whose abilities in marksmanship leave even the elite soldiers of other nations in awe. When sent on a mission to the kingdom of Garrah, Forte learned of the importance of trust and comradery thanks to the people from the Gramenia Republic who accompanied her on her mission. Upon returning home, Forte with a proud look on her face was greeted with a big smile by the King who assigned these people to escort her."
            }
    },
    { //Sumire - Complete
        id: 103116112,
        name: "Sumire",
        rank: "C",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103115112.png",
                detailawk: "/db/Units/Detail/unit_detail_103116112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103115112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103116112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6908,
                hpplus: 999,
                atk: 2521,
                atkplus: 388,
                def: 2470,
                defplus: 380
            },
        skillset: 
            {
                skill: "12s CT - 1500% Fire DMG (MAG). Increase own Arts by 8 + Add one Spirit Fox (max. 10).",
                skillbreak: 400,
                arts: "12000% Fire DMG (MAG). For 10s, increase Allies' MAG DMG by [10% + (# of Spirit Foxes × 5%)].",
                artsbreak: 1000,
                truearts: "21000% Fire DMG (MAG). For 15s, increase Allies' Arts by 3/s, heal Allies' HP by 120/s. When there are 10 Spirit Foxes: 21000% Fire DMG (MAG). Reduce # of Spirit Foxes by 5 + Increase Allies' Arts by 100 + For 15s, increase Allies' Arts by 3/s, heal Allies' HP by 120/s.",
                trueartsbreak: 1600
            },
        passive: 
            {
                ability1: "Increase Accuracy by [# of Spirit Foxes × 5]%. Does not stack with Abilities of the same name.",
                ability2: "When near-death, increase Skill CT REC by 15%.",
                ability3: "Increase DMG to Giant Bosses by 30%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat3amt: 1,
                    hover3: "Ifrit",
                    mat4: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat4amt: 2,
                    hover4: "Flogassa",
                    mat5: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat5amt: 1,
                    hover5: "Radachne"
                }
            },
        lore: 
            {   
                evo5: "Born in the Far-East, this travelling priestess made her living through showcasing her traditional performing arts throughout the land. Her family that has worshipped and passed down the legend of the Fox Deity throughout the generations were apparently originally called \"Shinobi\". When she would swing around her giant calligraphy brush, everything she drew would pop up off the canvas, and come to life with an actual physical form. She was able to do so because of the golden hairs from a fox spirit what were mixed into the head of the brush. She was also known as a travelling artist, with her illustrations ranging from depictions of regional myths to representations of mundane everyday life. These picture scrolls that she created throughout her whole life were passed down through the ages.",
                evoawk: "It was within the silent grounds of the shrine that the priestess made the finishing touch on her masterpiece. That was the moment when a sparkle of life began to glint within the eyes of the illustration of the Fox Deity she worshiped. The sudden flash of light that emanated from the canvas forced her to shut her eyes. When she opened them, however, the illustration... No, the Fox Deity that had jumped out of the illustration stood before her. This led her to deciding to embark on a journey the next morning, saying farewell to the shrine that had been so good to her. It was through this journey that countless picture scrolls were produced, and would eventually be passed down throughout the generations. One of these scrolls was the 'Legend of the Sealed Fox.'"
            }
    },
    { //Arcana - Complete
        id: 103126512,
        name: "Arcana",
        rank: "C",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_1031265512.png",
                detailawk: "/db/Units/Detail/unit_detail_103126512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103125512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103126512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6711,
                hpplus: 999,
                atk: 2243,
                atkplus: 346,
                def: 2430,
                defplus: 374
            },
        skillset: 
            {
                skill: "12s CT - 2000% Dark DMG (MAG). For 5s, reduce Enemies' Healing Taken by 20%.",                
                skillbreak: 400,
                arts: "9999% Dark DMG (MAG). For 12s, reduce Enemies' ATK, DEF and MAG RES by 33%.",                
                artsbreak: 800,
                truearts: "22000% Dark DMG (MAG) For 25s, reduce Enemies' DMG and Accuracy by 30%, Status RES by 60%.",                
                trueartsbreak: 1300
            },
        skillsetJP:
            {
                
                skill: "12s CT - 2000% Dark DMG (MAG). For 5s, reduce Enemies' Healing Taken by 10%.",
                skillbreak: 400,                
                arts: "8800% Dark DMG (MAG). For 12s, reduce Enemies' ATK, DEF and MAG RES by 33%.",
                artsbreak: 800,                
                truearts: "18000% Dark DMG (MAG) For 25s, reduce Enemies' DMG and Accuracy by 30%, Status RES by 60%.",
                trueartsbreak: 1300
            },
        passive: 
            {
                ability1: "Increase Dark RES by 25%.",
                ability2: "Increase Light RES by 25%.",
                ability3: "When near-death, increases evasion by 15%."
            },
        passiveJP: 
            {
                ability1: "Increase Dark RES by 25%.",
                ability2: "When near-death, increases evasion by 15%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 2,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 1,
                    hover4: "Code-XTF",
                    mat5: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat5amt: 1,
                    hover5: "Elpneumas"
                }
            },
        lore: 
            {   
                evo5: "The professor of the Isliid Imperial Magistone Laboratory. He is disliked by other researchers due to his strange attire and arrogant attitude, but the academic articles he sometimes decides to write on a whim are highly regarded with the scientific community. Both make him a little different from other people... in a variety of ways he is versed in the art of combat despite being an academic, and even fights monsters that appear in the mines when on a expedition.",
                evoawk: "The mysterious Magistone researcher who lives in the abandoned mines. Since injuring his face during a botched experiment, he has worn a mask to hide the scar.... is what he says, but no one seems to have ever seen his face, so this might be a lie... or not. He understands the words of the strange organism, a Krod - it was given the name \"Serkro\" by his assistant - that assists him in his exploration of the mines' depths. He doesn't have much of a sense of direction, though. His favorite food is fish."
            }
    },
    { //Ashleigh - Complete
        id: 103136112,
        name: "Ashleigh",
        rank: "F",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103135112.png",
                detailawk: "/db/Units/Detail/unit_detail_103136112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103135112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103136112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7301,
                hpplus: 999,
                atk: 2624,
                atkplus: 403,
                def: 2778,
                defplus: 426
            },
        skillset: 
            {
                skill: "12s CT - 1200% Fire DMG (PHY). Increase own Art Gauge by 50 if this Skill crits.",
                skillbreak: 400,
                arts: "14000% Fire DMG (PHY). For 10s, increase Allies' Critical RES by 20% + If this Arts crits, for 5s, reduce Enemies' DMG by 30%.",
                artsbreak: 800,
                truearts: "33000% Fire DMG (PHY). For 15s, increase Allies' DMG to Enemies they have an Elemental Advantage against by 60% and increase Allies' RES by 25% against Enemies they have an Elemental Advantage against.",
                trueartsbreak: 1400
            },
        passive: 
            {
                ability1: "Increase Critical Rate by 10%.",
                ability2: "Increase Allies' HP REC by 30%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 1,
                    hover3: "Fosradius",
                    mat4: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat4amt: 2,
                    hover4: "Flogassa",
                    mat5: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat5amt: 1,
                    hover5: "Ifrit"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Scintilla",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_107681.png",
                skill: "45s CT - 8000% Fire DMG (PHY). For 10s, Allies gain a DMG barrier that negates 1200 DMG, increase Allies' Critical DMG by 35%.",
                skillbreak: "?",
                passive: 
                    {
                        ability1: "When equipped by Ashleigh, increase Critical Rate by 20% and Skill CT REC by 50%.",
                        ability2: "Increase DEF by 20%."
                    }
            },
        lore: 
            {   
                evo5: "The flexible and flamboyant captain of the Stella Squad. She was once an elite soldier and central figure of the Rose Garden, but she decided to lead the smaller Stella Squad when it formed. Since then, she has committed herself to spreading word of Bamint's policies to its citizens. In her eyes, displays of passion are the most beautiful thing in the world, regardless of form.",
                evoawk: "The Stella Squad acts as \"Defenders of Passion\", punishing criminals who prey on artists. Even while participating in plays and concerts, they are always on the lookout for signs of illicit activity. Each member undergoes training specialized to their skills, and she is no exception. However, she finds it difficult to set aside time for that between her acting and singing lessons."
            }
    },
    { //Salyssa - Complete
        id: 103146312,
        name: "Salyssa",
        rank: "F",
        twrequire: "favorable",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103145312.png",
                detailawk: "/db/Units/Detail/unit_detail_103146312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103145312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103146312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6921,
                hpplus: 999,
                atk: 2689,
                atkplus: 413,
                def: 2555,
                defplus: 393
            },
        skillset: 
            {
                skill: "10s CT - 1700% Earth DMG (PHY). Chance to reduce Enemies' PHY evasion by 10% for 5s.",
                skillbreak: 400,
                arts: "15500% Earth DMG (PHY). For 6s, reduce Enemies' Earth RES and PHY RES by 15%. When there are 10 stacks of Effort: For 12s, reduce Enemies' Earth and PHY RES by 15%.",
                artsbreak: 1400,
                truearts: "40000% Earth DMG (PHY). For 10s, increase Allies' Skill CT REC by 100%, PHY DMG by 60%. When there are 10 stacks of Effort: For 20s, increase Allies' Skill CT REC by 100%, PHY DMG by 60%.",
                trueartsbreak: 2200
            },
        passive: 
            {
                ability1: "Each time own Skill is used, gain 1 stack of Effort (up to 10). Effort: Increase Skill CT REC by [10% × # of Effort stacks] (up to 100%).",
                ability2: "When 'Miasma' Area Effect is active, increase Arts Over Time Effects by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 2,
                    hover3: "Flogassa",
                    mat4: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat4amt: 1,
                    hover4: "Goldola",
                    mat5: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat5amt: 1,
                    hover5: "Freydal"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Bandiera",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_107711.png",
                skill: "45s CT - 8000% Earth DMG (PHY). For 10s, reduce Enemies' PHY RES by 20%, Earth RES by 30%.",
                skillbreak: 700,
                passive: 
                    {
                        ability1: "When equipped by Salyssa, increase ATK by 30% and increase Arts by 10 on own Skill use. Does not stack with Abilities of the same name.",
                        ability2: "Increase Skill DMG by 20%."
                    }
            },
        lore: 
            {   
                evo5: "A new member of the Stella Squad. She wanted to become a knight from childhood after one rescued her from a violent beast. She is hardworking and upfront, which also means she is not afraid to speak out against irrationality. Being raised in the countryside, she is unfamiliar with Minchenne and was unaware of the Stella Squad's existence until she enlisted. Moreover, she has a complex about her lack of aesthetic sense and talent.",
                evoawk: "A knight aspiring to become the perfect soldier. Small yet resilient, she uses a style of spear work developed since living with her family. She does feel shame about her underdeveloped artistic skill, but that, along with everything else, is forgotten when she starts training. Not even she realizes how deeply she becomes absorbed, since most of her sessions are solo. As expected of a cultural metropolis, those who share her interests in combat are few and far between."
            }
    },
    { //Sharon - Complete
        id: 103156412,
        name: "Sharon",
        rank: "C",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103155412.png",
                detailawk: "/db/Units/Detail/unit_detail_103156412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103155412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103156412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7100,
                hpplus: 999,
                atk: 2650,
                atkplus: 407,
                def: 2463,
                defplus: 379
            },
        skillset: 
            {
                skill: "10s CT - 1200% Light DMG (MAG). Increase own Arts by 10.",
                skillbreak: 500,
                arts: "16000% Light DMG (MAG). For 30s, gain 1 stack of Cheer. Cheer increases own MAG DMG by [50% × # of stacks of Cheer].",
                artsbreak: 800,
                truearts: "40000% Light DMG (MAG). For 20s, increase Allies' (except self) MAG DMG by [50% × # of stacks of Cheer].",
                trueartsbreak: 1000
            },
        passive: 
            {
                ability1: "Increase DMG to Dark Enemies by 20%.",
                ability2: "Increase Allies' Healing Taken by 20%.",
                ability3: "When HP is above 80%, increase ATK by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 1,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 2,
                    hover4: "Radachne",
                    mat5: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat5amt: 1,
                    hover5: "Elpneumas"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Gold Rush",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_109021.png",
                skill: "40s CT - 5000% Light DMG (MAG). For 12s, increase own DMG by 50%. If user is Light, increase own accuracy by 30%.",
                skillbreak: 700,
                passive: 
                    {
                        ability1: "When equipped by Sharon, increase own stats by 15% and increase duration of Cheer stacks by 10s.",
                        ability2: "Increase MAG RES by 5%."
                    }
            },
        lore: 
            {   
                evo5: "A member of the Stella Squad. Famous for her performances in comedies, operas, and musicals, she has fans of all kinds. Beneath her charm and poise is a shrewd mind that will not hesitate to speak out. As the oldest member of the squad, she will help the others but always with a cutting remark or two. Her favorite phrase is 'cash-in-hand payment', born from her love of money.",
                evoawk: "A popular actress who works as even hostess for the Stella Squad. The wealth of connections provided by her acting career makes her the most informed of the group. As such, she is usually first to know when some scheme is afoot, and she is no stranger to the early-morning bar scene as a result. Much of her income goes towards her troupe, leaving little if anything to line her purse."
            }
    },
    { //Rits - Complete
        id: 103166412,
        name: "Rits",
        rank: "B",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103165412.png",
                detailawk: "/db/Units/Detail/unit_detail_103166412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103165412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103166412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7113,
                hpplus: 999,
                atk: 2659,
                atkplus: 408,
                def: 2719,
                defplus: 417
            },
        skillset: 
            {
                skill: "10s CT - 1500% Light DMG (MAG). Increase own Arts by 10.",
                skillbreak: 600,
                arts: "8000% Light DMG (MAG). For 50s, increase Light Allies' DMG by 20% (Stackable).",
                artsbreak: 900,
                truearts: "44000% Light DMG (MAG). For 10s, increase Allies' Critical Rate and own Critical DMG by 100%.",
                trueartsbreak: 1800
            },
        passive: 
            {
                ability1: "Increase own Healing Taken by 20% (except Lifesteal).",
                ability2: "When 'Miasma' Area Effect is active, increase Arts Over Time Effects by 60%.",
                ability3: "Increase ATK by 100% when own HP is above 90%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 1,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat4amt: 2,
                    hover4: "Tonetaurus",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Wonderful",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_108791.png",
                skill: "60s CT - 8000% Light DMG (MAG). For 10s, increase own MAG DMG by 30%. If user is Light, increase MAG DMG by 60% instead.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Rits, increase own Skill CT REC by 50%. At the start of a Quest, increase own Arts by 50. Does not stack with Abilities of the same name.",
                        ability2: "Increase Dark RES by 10%."
                    }
            },
        lore: 
            {   
                evo5: "Musician and knight of the Stella Squad. From her unique way of talking, she is assumed to be in her late teens. She was born into a musical family, giving her superb talent with stringed instruments. Her eccentrics can be shocking at times and might leave the impression she is imprudent, but she always looks after her squadmates.",
                evoawk: "The heiress of Muraku Itto-Ryu, a style of musical swordplay with over 400 years of history. She firmly believes in her duty to protect the passion of artists. As a performer herself, her behavior also tends to stand out. This is especially true on days off when she wanders to and fro, guided only by whimsy. Though she seems unpredictable, her new partner Salyssa says there is indeed a definite logic behind her curiosity."
            }
    },
    { //Raine - Complete
        id: 103176212,
        name: "Raine",
        rank: "F",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103175212.png",
                detailawk: "/db/Units/Detail/unit_detail_103176212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103175212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103176212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6807,
                hpplus: 999,
                atk: 2942,
                atkplus: 451,
                def: 2776,
                defplus: 426
            },
        skillset: 
            {
                skill: "9s CT - 1500% Water DMG (MAG). Increase own Arts by 25.",
                skillbreak: 400,
                arts: "14000% Water DMG (MAG). For 10s, reduce Enemies' Status RES and MAG RES by 30%.",
                artsbreak: 1000,
                truearts: "23000% Water DMG (MAG). For 25s, reduce Enemies' Water RES by 20%, if own HP is above 70%, reduce Enemies' Water RES by an additional 20% (stackable).",
                trueartsbreak: 1500
            },
        passive: 
            {
                ability1: "Increase Water Allies' BE generation by 10%.",
                ability2: "Each time own Skill, Arts or True Arts is used, consume 15% of own maximum HP. When own HP is above 50%, increase own Arts by 3/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat3amt: 1,
                    hover3: "Freydal",
                    mat4: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat4amt: 1,
                    hover4: "Tonetaurus",
                    mat5: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat5amt: 1,
                    hover5: "Flogassa"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Colorly",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_108271.png",
                skill: "40s CT - 4500% Water DMG (MAG). For 10s, increase own MAG DMG by 30%, if user is Water, increase own MAG DMG by an additional 30%.",
                skillbreak: 500,
                passive: 
                    {
                        ability1: "When equipped by Raine, increase Skill CT REC by 60%, DMG by 35%.",
                        ability2: "Increase Skill DMG by 20%."
                    }
            },
        lore: 
            {   
                evo5: "A knight of the Stella Squad, though she claims her main occupation is court painter. She is a prodigy who has worked on many masterpieces, earning her scores of fans both at home and abroad. Beneath her quiet, cool demeanor is a shy loner, so she treats her squad mates as close family, including how she tends to over-rely on them. Her role in the group is drawing for the royal newsletter and other official circulations.",
                evoawk: "A court painter affiliated with the Stella Squad. Her main purview is fine art, so she illustrates for government publications and plans museum exhibits. She can also perform on stage if the need arises, but she prefers a supportive role away from the spotlight. Even so, she will take action whenever she sees anyone in trouble like a true Defender of Passion."
            }
    },
    { //Alulu - Complete
        id: 103186512,
        name: "Alulu",
        rank: "F",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103185512.png",
                detailawk: "/db/Units/Detail/unit_detail_103186512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103185512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103186512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 7000,
                hpplus: 999,
                atk: 2918,
                atkplus: 451,
                def: 2649,
                defplus: 427
            },
        skillset: 
            {
                skill: "9s CT - 2000% Dark DMG (MAG). For 5s, increase own MAG DMG by 10%.",
                skillbreak: 1200,
                arts: "15000% Dark DMG (MAG). For 15s, reduce Enemies' MAG RES by 15%.",
                artsbreak: 1400,
                truearts: "44000% Dark DMG (MAG). For 30s, reduce Allies' DMG RES by 30%, and activations of own Arts will not deplete Arts Gauge.",
                trueartsbreak: 2300
            },
        passive: 
            {
                ability1: "Increase MAG RES by 10%.",
                ability2: "Increase DMG to Giant Bosses by 20%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 1,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragValzandeth.png",
                    mat4amt: 2,
                    hover4: "Valzandeth",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Bastone",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_108301.png",
                skill: "45s CT - For 15s, increase own Dark DMG by 20% and DMG to Giant Bosses by 50%.",
                skillbreak: "?",
                passive: 
                    {
                        ability1: "When equipped by Alulu, increase ATK and MAG DMG by 30%. Does not stack with Abilities of the same name.",
                        ability2: "Increase Curse RES by 20%."
                    }
            },
        lore: 
            {   
                evo5: "An orphan who was raised by a professor at a magic academy in Minchenne, the capital of Bamint. From a young age, she demonstrated great talent in magic, especially when it came to inorganic matter. She loved her foster parent and the magic he taught her and was the quiet type who'd continuously work on her magic skill. But ironically, the country's pronounced focus on the fine arts made her feel like an outcast.",
                evoawk: "A genius magician living in Bamint. She is usually reticent but will talk at length about her favorite subjects, like sorcery. Her helpful nature has made her popular at the magic school, particularly among the younger students, and praise will bring a small, shy smile to her face. Although she finds her country's obsession with fine arts irritating, she does not look down on the interests of others."
            }
    },
    { //Marika - Complete
        id: 103196112,
        name: "Marika",
        rank: "C",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103195112.png",
                detailawk: "/db/Units/Detail/unit_detail_103196112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103195112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103196112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6890,
                hpplus: 999,
                atk: 2855,
                atkplus: 438,
                def: 2755,
                defplus: 423
            },
        skillset: 
            {
                skill: "11s CT - 1600% Fire DMG (MAG). For 8s, reduce Enemies' Break RES by 30%.",
                skillbreak: 1700,
                arts: "20000% Fire DMG (MAG). For 15s, increase Allies' Break PWR by 25%. When attacking an enemy they have an Elemental Advantage against, increase BREAK PWR by 50% instead.",
                artsbreak: 4000,
                truearts: "45000% Fire DMG (MAG). For 20s, increase Allies' DMG by 50% + When activated against an Enemy in BREAK, for 5s, increase Allies' Arts by 10/s.",
                trueartsbreak: 6600
            },
        passive: 
            {
                ability1: "Increase Blind RES by 100%.",
                ability2: "Increase BE generation by 25%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat3amt: 1,
                    hover3: "Ifrit",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 2,
                    hover4: "Code-XTF",
                    mat5: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat5amt: 3,
                    hover5: "Flogassa"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                true1: 
                {
                    name: "Scatter 18",
                    slot: "/db/Mines/thumbnail/magLB.png",
                    thumb: "/db/Equipment/Thumbnail/item_thumbnail_107891.png",
                    detail: "/db/Equipment/Detail/item_detail_107891.png",
                    skill: "45s CT - 2000% Fire DMG (MAG).",
                    skillbreak: 6000,
                    passive: 
                        {
                            ability1: "When equipped by Marika, Increase Break PWR & Arts DMG by 30%. Does not stack with Abilities of the same name.",
                            ability2: "Increase Skill CT REC by 5%."
                        }
                },
                true2: 
                {
                    name: "Geek Shielder",
                    slot: "/db/Mines/thumbnail/healLB.png",
                    thumb: "/db/Equipment/Thumbnail/item_thumbnail_107911.png",
                    detail: "/db/Equipment/Detail/item_detail_107911.png",
                    skill: "60s CT - For 20s, heals Allies' HP by 300/s and Increase PHY RES by 10%.",
                    skillbreak: 0,
                    passive: 
                        {
                            ability1: "When equipped by Marika, at the start of a Battle, increase Arts by 100. Does not stack with Abilities of the same name.",
                            ability2: "Increase Burn RES by 20%."
                        }
                }
                
            },
        lore: 
            {   
                evo5: "A young but brilliant mechanical engineer. Her old workshop in the capital grew too cramped and cluttered, convincing her to move to Reigrad where rent was more affordable. However, she tends to keep her distance from strangers, so she has made few friends besides Rayas and Iris. Combat is not her strongest suit, but she can overcome any situation with her calm judgment and self-made inventions.",
                evoawk: "A girl who recently befriended Rayas and Iris. Mechanically inclined from a yong age, she quickly became one of the Isliid's brightest engineers. However, the unilateral praise for her talent and originality has yet to reach her ears since she is indifferent to the industry at large. Rather, whe values her parents' lessons and works tirelessly (with the help of banana shakes) to create ever more innovations."
            }
    },
    { //Noel - Complete
        id: 103206412,
        name: "Noel",
        rank: "F",
        twrequire: "favorable",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103205412.png",
                detailawk: "/db/Units/Detail/unit_detail_103206412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103205412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103206412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7298,
                hpplus: 999,
                atk: 3001,
                atkplus: 460,
                def: 2361,
                defplus: 364
            },
        skillset: 
            {
                skill: "10s CT - 1600% Light DMG (PHY). Inflicts a stack of Vector (up to 6 stacks).",
                skillbreak: 800,
                arts: "16000% Light DMG (PHY). For 12s, increase own Skill CT REC by 120% and Arts by 3/s.",
                artsbreak: 1800,
                truearts: "40000% Light DMG (PHY). Increase DMG of this True Arts by [50% × # of Marked Stacks on Enemy]. Cleanse Enemies' Marked Stacks after this True Arts is concluded.",
                trueartsbreak: 2500
            },
        passive: 
            {
                ability1: "Increase accuracy by 30%.",
                ability2: "Increases DMG to Enemies under 30% HP by 50%.",
                ability3: "Reduce Enemies' Evasion by [5% × # of Vector stacks on Enemy]."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 2,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat4amt: 1,
                    hover4: "Flogassa",
                    mat5: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat5amt: 1,
                    hover5: "Mavroskia"
                }
            },
            trueweapon: //If no TW, may delete this section
            {
                true1: 
                {
                    name: "Velocity",
                    slot: "/db/Mines/thumbnail/physLB.png",
                    thumb: "/db/Equipment/Thumbnail/item_thumbnail_108111.png",
                    detail: "/db/Equipment/Detail/item_detail_108111.png",
                    skill: "45s CT - 8000% Light DMG (PHY). For 10s, reduce Enemies' PHY RES by 20%, Light RES by 30%.",
                    skillbreak: 800,
                    passive: 
                        {
                            ability1: "When equipped by Noel, increase ATK by 40%.",
                            ability2: "Increase DMG to Giant Bosses by 10%."
                        }
                },
                true2: 
                {
                    name: "Scintillation",
                    slot: "/db/Mines/thumbnail/physLB.png",
                    thumb: "/db/Equipment/Thumbnail/item_thumbnail_108121.png",
                    detail: "/db/Equipment/Detail/item_detail_108121.png",
                    skill: "60s CT - 9000% Light DMG (PHY). For 10s, increase PHY DMG by 30%, if HP is over 80%, increase PHY DMG by 60% instead.",
                    skillbreak: 700,
                    passive: 
                        {
                            ability1: "When equipped by Noel, increase Arts DMG by 40%.",
                            ability2: "Increase DEF by 10%."
                        }
                }
                
            },
        lore: 
            {   
                evo5: "A young knight serving in the emperor's personal force, the Zero Guard. He carries out his orders with refined swordsmanship and a number of other techniques, and his earnest predisposition guarantees he puts in the maximum effort towards into all of his missions. He is chivalrous to all but his enemies, who may be unfortunate enough to see his ruthless side. His love of sweets, especially doughnuts, is also well known.",
                evoawk: "A knight serving the emperor of Isliid, born to a high-ranking officer and a librarian. This granted him an advantage in both military and literary arts, not only allowing him to skip grades at the military academy but also graduate as valedictorian. He took full advantage of his status as a prodigy, but jealousy and lack of understanding kept him from making friends. His life of loneliness continued until he met a special someone who turned his life around. He now seriously attends to his duties, putting forth his full effort."
            }
    },
    { //Batiste - Complete
        id: 103216512,
        name: "Batiste",
        rank: "B+",
        twrequire: "favorable",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103215512.png",
                detailawk: "/db/Units/Detail/unit_detail_103216512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103215512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103216512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7113,
                hpplus: 999,
                atk: 2659,
                atkplus: 408,
                def: 2719,
                defplus: 417
            },
        skillset: 
            {
                skill: "12s CT - 4000% Dark DMG (PHY). For 5s, reduce Enemies' evasion by 5%.",
                skillbreak: 800,
                arts: "20000% Dark DMG (PHY). For 20s, reduce Enemies' Dark RES by 15% (stackable).",
                artsbreak: 1300,
                truearts: "40000% Dark DMG (PHY). For 12s, increase own ATK by 100% and own attacks ignore 100% of Enemies' DEF. When Zero no Yaiba is at 6 stacks: 70000% Dark DMG (PHY). For 12s, increase own ATK by 100% and own attacks ignore 100% of Enemies' DEF.",
                trueartsbreak: 2200
            },
        passive: 
            {
                ability1: "Increase DMG to Enemies in BREAK by 20%.",
                ability2: "Increase DMG to Enemies under 30% HP by 40%.",
                ability3: "Gain a stack of Zero no Yaiba whenever own Arts is activated (up to 6). For each stack of Zero no Yaiba, increase own DMG by 40%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 1,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 2,
                    hover4: "Code-XTF",
                    mat5: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat5amt: 2,
                    hover5: "Mavroskia"
                }
            },
            trueweapon: //If no TW, may delete this section
            {
                true1: 
                {
                    name: "Night Edge",
                    slot: "/db/Mines/thumbnail/physLB.png",
                    thumb: "/db/Equipment/Thumbnail/item_thumbnail_108831.png",
                    detail: "/db/Equipment/Detail/item_detail_108831.png",
                    skill: "60s CT - 6000% Dark DMG (PHY). If user is Dark, for 8s, reduce Enemies' PHY RES by 20%.",
                    skillbreak: 600,
                    passive: 
                        {
                            ability1: "When equipped by Batiste, increase DMG by 40% and increase own Arts by 1/s. Does not stack with Abilities of the same name.",
                            ability2: "Increase DMG to Enemies under 30% HP by 10%."
                        }
                },
                true2: 
                {
                    name: "Night Jacket",
                    slot: "/db/Mines/thumbnail/physLB.png",
                    thumb: "/db/Equipment/Thumbnail/item_thumbnail_108821.png",
                    detail: "/db/Equipment/Detail/item_detail_108821.png",
                    skill: "60s CT - 5000% Dark DMG (PHY). For 8s, increase own Dark DMG by 50%. The first time this Equip is used in a Quest, increase own Arts by 30.",
                    skillbreak: 300,
                    passive: 
                        {
                            ability1: "When equipped by Batiste, increase ATK by 40% and increase own Arts by 1/s. Does not stack with Abilities of the same name.",
                            ability2: "When near-death, increase own DEF by 20%."
                        }
                }
                
            },
        lore: 
            {   
                evo5: "A member of the Emperor's Zero Guard, a cool realist with a dry wit. His objective, logical, and constructive decisions have made him a good superior and partner to Noel. In combat, he uses countless knives and agility to perplex foes before striking from their blind spot. His one shortcoming is that his focus on efficiency can come off as inconsideration by those unaware of his goal to help as many as possible. He claims to have no hobbies but diligently keeps a journal.",
                evoawk: "Adopted into a noble family who later arranged for him to become a knight. He was selected for the Zero Guard during the previous emperor's reign. His disgust with the unsavory ruling class grew over time, in part because he knew he himself was not much different. That is why he respects straightforward people like Noel and Rayas who he silently uses to lift his spirit. He keeps his collection of knives in top shape by visiting local blacksmiths between missions."
            }
    },
    { //Juno - Complete + JP ver
        id: 103226412,
        name: "Juno",
        rank: "A+",
        twrequire: "favorable",
        attribute: "Light",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103225412.png",
                detailawk: "/db/Units/Detail/unit_detail_103226412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103225412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103226412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6989,
                hpplus: 999,
                atk: 2902,
                atkplus: 445,
                def: 2430,
                defplus: 374
            },
        skillset: 
            {
                skill: "10s CT - 1500% Light DMG (MAG). Heal own HP by 5% of DMG dealt by own Skill.",
                skillbreak: 500,
                arts: "16000% Light DMG (MAG). For 10s, reduce Enemies' DMG RES by 30%, Status RES by 20%.",
                artsbreak: 1300,
                truearts: "Activates Demonic True Eclipse until own HP reaches 20%. When used during Demonic True Eclipse: 52000% Dark DMG (MAG). For 15s, consume 2%/s of own max HP and increase Allies' Arts by 7/s.",
                trueartsbreak: 1800
            },
        skillsetJP: 
            {
                skill: "10s CT - 1500% Light DMG (MAG). Heal own HP by 5% of DMG dealt by own Skill.",
                skillbreak: 500,
                arts: "16000% Light DMG (MAG). For 10s, reduce Enemies' DMG RES by 30%, Status RES by 20%.",
                artsbreak: 1300,
                truearts: "Activates Demonic True Eclipse until own HP reaches 20%. When used during Demonic True Eclipse: 70000% Dark DMG (MAG). For 20s, consume 2%/s of own max HP and increase Allies' Arts by 7/s.",
                trueartsbreak: 1800
            },
        passive: 
            {
                ability1: "During Demonic True Eclipse, increase own Arts by 5/s, own DMG and accuracy by 100%.",
                ability2: "Increase Demon Allies' stats by 10%. Does not stack with Abilities of the same name.",
                ability3: "Increase own ATK and DMG by [10% × # of Demon Allies in party, excluding self]."
            }, 
        passiveJP: 
            {
                ability1: "During Demonic True Eclipse, increase own Arts by 5/s, own DMG and accuracy by 100%.",
                ability2: "Increase Demon Allies' stats by 10%. Does not stack with Abilities of the same name.",
                ability3: "Increase own ATK and DMG by [30% × # of Demon Allies in party, excluding self]."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 2,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 3,
                    hover4: "Radachne",
                }
            },
            dreamJP: {
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                        mat3amt: 2,
                        hover3: "Radachne",
                    },
                    passive: {
                        ability1: `Increase True Arts' DMG Multiplier to 120000% Dark DMG (MAG) instead.`,
                        ability2: `Increases True Arts' HP consumption to 3%/s instead.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragValzandeth.png",
                        mat3amt: 2,
                        hover3: "Valzandeth",
                    },
                    passive: {
                        ability1: `Increase Demon allies' stats by 30%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase Demon allies' Arts by 1/s. Does not stack with Abilities of the same name.`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Testament",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_108001.png",
                skill: "60s CT - 8000% Light DMG (MAG). For 10s, reduce Enemies' MAG RES by 35%.",
                skillbreak: 500,
                passive: 
                    {
                        ability1: "When equipped by Juno, increase own Arts by 100 and heal own HP by 50% when entering Demonic True Eclipse.",
                        ability2: "Increase MAG DMG by 20%."
                    }
            },
        lore: 
            {   
                evo5: "Juno was born and raised in a remote village with her younger sister, but tragedy struck when the powers of her demonic blood awoke. Feared from that day as a descendant of the Crimson Blood Maiden, she was relunctantly exiled from the village. However, her awakening gave her a mysterious healing power, so she traveled the battlefields, treating the wounded. Her acts of compassion paved the road to the birth and rule of a new nation.",
                evoawk: "Juno was the first queen of Granadia, often referred to as the mother of the burgeoning nation. With miraculous powers, she cleansed the land defiled by monsters and founded a new nation. Though mistakenly described as fierce and ambitious in the chronicles, personal accounts from the time paint her as a warm and thoughtful figure. The very prescence of the Templars, devotees who supporter her in every aspect of life, was proof of that."
            }
    },
    { //Liese (Dark) - Complete
        id: 103236512,
        name: "Liese (Dark)",
        rank: "B",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103235512.png",
                detailawk: "/db/Units/Detail/unit_detail_103236512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103235512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103236512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6755,
                hpplus: 999,
                atk: 2888,
                atkplus: 443,
                def: 2861,
                defplus: 439
            },
        skillset: 
            {
                skill: "12s CT - 1200% Dark DMG (PHY). On every 3rd use of this Skill, for 20s, summon a Magic Circle. Resets when a battle ends.",
                skillbreak: 500,
                arts: "13000% Dark DMG (PHY). For 15s, increase Allies' max HP by 30%.",
                artsbreak: 1100,
                truearts: "40000% Dark DMG (PHY). For 15s, increase own Critical Rate and DMG by 100% + If a Magic Circle is present, increase Allies' Arts by 30.",
                trueartsbreak: 2000
            },
        passive: 
            {
                ability1: "When a Magic Circle is present, reduce Enemies' DMG RES by 30%. Does not stack with Abilities of the same name.",
                ability2: "Increase Curse RES by 100%.",
                ability3: "Increase BE generation by 5%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 1,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 2,
                    hover4: "Radachne",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Mornyan Star",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_108351.png",
                skill: "50s CT - 9000% Dark DMG (PHY). For 10s, increase own Critical DMG by 70%, reduce own Accuracy by 30%.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Dark Liese, increase Skill CT REC by 60%. Does not stack with Abilities of the same name.",
                        ability2: "When near-death, increase ATK by 20%."
                    }
            },
        lore: 
            {   
                evo5: "Another face of Liese, who ardently trained as a priestess from the age of 4, living in the great shrine of Famelle. Despite her farsighted maturity, a gift from a visitor--a detective's outfit and a mystery novel--changed the course of her life forever. With a curious partner disguised as a cat, she would go on to solve a case that threatened Reigrad and had a great impact on her own destiny.",
                evoawk: "Liese was committed to her duties as a priestess, but her heart longed to follow her childish dreams, in the same way her peers were taken by the awakenings of love. Cutting through the dark underbelly of Reigrad, she found the release she desired while clad in a trenchcoat and cap. As she saw how her alter ego put the people at ease, she gradually felt a new sense of purpose."
            }
    },
    { //Emi - Complete, Review done
        id: 103246512,
        name: "Emi",
        rank: "C",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103245512.png",
                detailawk: "/db/Units/Detail/unit_detail_103246512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103245512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103246512.png",
                revelation: "/db/Units/Thumbnail/unit_thumbnail_501701513.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7199,
                hpplus: 999,
                atk: 3041,
                atkplus: 466,
                def: 2416,
                defplus: 372
            },
        skillset: 
            {
                skill: "12s CT - 1600% Dark DMG (MAG). For 6s, reduce Enemies' MAG RES by 10%.",
                skillbreak: 400,
                arts: "11000% Dark DMG (MAG). For 30s, increase Dark Allies' DMG by 60%.",
                artsbreak: 600,
                truearts: "For 70s, enter Revelation Mode. While in Revelation Mode, replace Equips with Morph Skills, replace Skill with Mega Skill and replace Arts with Mega Arts. Other actions cannot be performed while an animation is ongoing.",
                trueartsbreak: 0,
                revelation: 
                    {
                        skill1: "10s CT - 10000% Dark DMG (MAG). 80% chance to inflict Blind",
                        skill1break: 1000,
                        skill2: "15s CT - 25000% Dark DMG (MAG). For 15s, reduce Enemies' DMG RES by 50%",
                        skill2break: 1200,
                        skill3: "25s CT - 48000% Dark DMG (MAG). For 20s, reduce Enemies' CRIT RES by 100%",
                        skill3break: 1500,
                        megaskill: "15s CT - 35000% Dark DMG (MAG)",
                        megaskillbreak: 800,
                        megaarts: "120000% Dark DMG (MAG). For 15s, increase Allies' Arts DMG By 100%",
                        megaartsbreak: 2000
                    }
            },
        passive: 
            {
                ability1: "Increase DMG to Humans by 50%.",
                ability2: "Increase PHY RES by 10%.",
                ability3: "Increase DMG against Blinded Enemies by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 2,
                    hover3: "RagsherumPhantom",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 4,
                    hover4: "Radachne",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                true1: 
                {
                    name: "Nine Oaths",
                    slot: "/db/Mines/thumbnail/magLB.png",
                    thumb: "/db/Equipment/Thumbnail/item_thumbnail_109511.png",
                    detail: "/db/Equipment/Detail/item_detail_109511.png",
                    skill: "80s CT - 10000% Dark DMG (MAG). For 30s, reduce Enemies' Dark RES by 25%.",
                    skillbreak: 800,
                    passive: 
                        {
                            ability1: "When equipped by Emi, increase the duration of Revelation Mode by 30s. Does not stack with Abilities of the same name.",
                            ability2: "Increase MAG RES by 5%."
                        }
                },
                true2: 
                {
                    name: "Ancient Law",
                    slot: "/db/Mines/thumbnail/magLB.png",
                    thumb: "/db/Equipment/Thumbnail/item_thumbnail_109501.png",
                    detail: "/db/Equipment/Detail/item_detail_109501.png",
                    skill: "80s CT - 5500% Dark DMG (MAG). Increase own Arts by 35. For 30s, increase own Dark DMG by 30%.",
                    skillbreak: 900,
                    passive: 
                        {
                            ability1: "When equipped by Emi, increase DMG by 20%. At the start of a Quest, increase Emi's Arts by 100. Does not stack with Abilities of the same name."
                        }
                }
                
            },
        lore: 
            {   
                evo5: "A young woman who claims to be an Ancient. She fights Rayas and his allies with her ability to transform into a huge monster. Her distrustful and antisocial nature has kept her from making friends even within Shadow. The only reason she obeys orders is because their objective to create a new world ruled by the Ancients falls in line with her domineering tendencies.",
                evoawk: "A young woman who claims the world belongs to her. She is able to use the powers of those she absorbs, and they also influence her transformed state. She holds Rayas in great contempt but does not understand why."
            },
        review:
            {
                skill: `The effect and duration aren't large enough to really do much; it basically has no significance in Emi's kit.`,
                arts: `Just focus on entering/re-entering Revelation Mode. It won't even last long enough for when Emi will deal her most amount of damage.`,
                truearts: `70 seconds is enough for one Mega Arts usage. Do note that her special skills aren't affected by CT buffs.`,
                revelation: {
                    skill1: `Pretty high chance to blind enemies, which will help with your survivability a good amount as long as the boss doesn't have high accuracy or guaranteed attacks.`,
                    skill2: `Potent damage resistance debuff. More damage. Yay!`,
                    skill3: `If there's no critical resistance, this will pretty much act like a 100% critical rate buff would for your team.`,
                    megaskill: `Don't use this.`,
                    megaarts: `Huge multiplier with a great teamwide DMG buff. You will have to charge this ability with using Emi's special skills. Optimal order for damage is 3-2-1-3-2 -> Mega Art`
                },
                ability1: `Pretty hefty DMG buff when you face against human-type enemies, which it will be decently often.`,
                ability2: `Negligible passive.`,
                ability3: `With a chance of 80%, Emi will be inflicting blindness pretty often, so this passive will usually boost her damage by a good amount.`,
                trueweapon: {
                    true1: {
                        skill: `As you can only use equipment outside of Revelation Mode, it isn't even long enough for Emi to make good use of the debuff, so it would just be better to have another unit have something like True "Nemesis Crow" equipped if you want to reduce damage resistance. The main point of the item is the duration extension.`,
                        ability1: `You could fit two Mega Arts in one Revelation Mode period with the extra 30 seconds. That's pretty cool. You'll need the 30 second extension if you want to use Mega Arts for the second time without pre-stages or other forms of animation cancellations.`,
                        ability2: `This effect is negligible.`
                    },
                    true2: {
                        skill: `Like with the other true weapon, 30 seconds is not long enough to have a significant impact. You would only use this if you wanted the one-time faster start-up.`,
                        ability1: `Use if you want the one-time faster start-up. Otherwise, Akashic Eye is a great alternative.`
                    }
                },
                overall: `Emi is a very strong unit, but she is very slow so you would only want to bring her in quests that last fairly long. When the quest does last long enough for her to be of good use, she deals some huge damage with great teamwide utility (inflicting blindness, critical resistance debuff, potent damage resistance debuff). For shorter quests, you'd opt for faster options, but she is an absolute powerhouse given the ample time needed to set her up and use her abilities.`,
                lastupdated: `June 23, 2022`,
                author: `Praying Patrick`
            }
    },
    { //Groitz - Complete, Review done
        id: 103256312,
        name: "Groitz",
        attribute: "Earth",
        rank: "B-",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103255312.png",
                detailawk: "/db/Units/Detail/unit_detail_103256312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103255312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103256312.png",
                revelation: "/db/Units/Thumbnail/unit_thumbnail_501771313.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6696,
                hpplus: 999,
                atk: 2950,
                atkplus: 452,
                def: 2649,
                defplus: 407
            },
        skillset: 
            {
                skill: "11s CT - 1800% Earth DMG (PHY). 50% chance of inflicting Poison for 10s.",
                skillbreak: 600,
                arts: "15000% Earth DMG (PHY). For 60s, reduce Enemies' Poison RES by 50%.",
                artsbreak: 1000,
                truearts: "For 60s, enter Revelation Mode. While in Revelation Mode, replace Equips with Morph Skills, replace Skill with Mega Skill and replace Arts with Mega Arts. Other actions cannot be performed while an animation is ongoing.",
                trueartsbreak: 0,
                revelation: 
                    {
                        skill1: "12s CT - 10000% Earth DMG (PHY). For 15s, reduce Enemies' Earth RES by 50%.",
                        skill1break: 1200,
                        skill2: "14s CT - 20000% Earth DMG (PHY). For 15s, reduce Enemies' PHY RES by 50%.",
                        skill2break: 1500,
                        skill3: "36000% Earth DMG (PHY). 100% Chance of Inflicting Poison for 10s.",
                        skill3break: 1000,
                        megaskill: "10s CT - 8000% Earth DMG (PHY). Increase Allies' (Except Self) Arts Gauge by 30.",
                        megaskillbreak: 1000,
                        megaarts: "100000% Earth DMG (PHY). For 15s, reduce Enemies' DMG RES by 50%.",
                        megaartsbreak: 2000
                    }
            },
        passive: 
            {
                ability1: "Increase accuracy against Poisoned Enemies by 50%.",
                ability2: "Increase DMG against Poisoned Enemies by 50%.",
                ability3: "Increase Groitz's DMG by [30% × # of times Skill or Revelation Skill 3 inflicted Poison] (up to 300%)."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat3amt: 2,
                    hover3: "Goldola",
                    mat4: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat4amt: 4,
                    hover4: "Mavroskia",
                }
            },
            trueweapon: //If no TW, may delete this section
            {
                true1: 
                {
                    name: "Cypher",
                    slot: "/db/Mines/thumbnail/physLB.png",
                    thumb: "/db/Equipment/Thumbnail/item_thumbnail_109661.png",
                    detail: "/db/Equipment/Detail/item_detail_109661.png",
                    skill: "45s CT - 8000% Earth DMG (PHY). For 20s, reduce Enemies' Poison RES by 30%. On first use of this Equip, increase own Arts by 50.",
                    skillbreak: 800,
                    passive: 
                        {
                            ability1: "When equipped by Groitz, increase own DMG and Skill CT REC by 30%. Does not stack with Abilities of the same name.",
                            ability2: "When HP is above 90%, increase ATK by 15%."
                        }
                },
                true2: 
                {
                    name: "Crew V",
                    slot: "/db/Mines/thumbnail/magLB.png",
                    thumb: "/db/Equipment/Thumbnail/item_thumbnail_109671.png",
                    detail: "/db/Equipment/Detail/item_detail_109671.png",
                    skill: "60s CT - 7000% Earth DMG (MAG). For 25s, increase own DMG by 25% and Earth DMG by 25%.",
                    skillbreak: 900,
                    passive: 
                        {
                            ability1: "When equipped by Groitz, increase own Arts by 50 after exiting Revelation Mode. Does not stack with Abilities of the same name.",
                            ability2: "Increase DEF by 10%."
                        }
                }
                
            },
        lore: 
            {   
                evo5: "A self-proclaimed Ancient who antagonizes Rayas and his allies. After his latent abilities activated, he absorbed numerous creatures which allowed him to transform into an enormous monster. In contrast to the detached Emi, he enjoys the company of others to an unnatural degree. However, this interest only extends to how they can further his goals.",
                evoawk: "An assassin who belongs to a secret society bent on restoring the Ancients to power. A member of that organization took him in as an orphan, and his abilities as an Ancient awoke after he was initiated. Consuming others to steal their qualities pairs with his experience as a famished youth to make him the ultimate predator."
            },
        review:
            {
                skill: `Nothing interesting to note, just used for stacking his passive.`,
                arts: `Great to increase the chances of stacking his passive, use it just before going into Revelation.`,
                truearts: `Quite a short timer on Revelation, he also has nothing to extend the duration for more time on his debuffs.`,
                revelation: {
                    skill1: `Great to use when you have other earth DPS units on the team, also just for himself to deal more damage.`,
                    skill2: `The Special Skill you want to be spamming the most, since most of the top DPS are physical units.`,
                    skill3: `Once again, mainly used for stacking his passive. It has the highest multiplier of the 3 Special Skills, but the debuffs compensate for the lower multiplier.`,
                    megaskill: `Since this won't count towards unlocking his Mega Arts, you likely won't be using this unless you desperately need the burst ArtGen.`,
                    megaarts: `The obvious bulk of Groitz damage. Assuming that his passive is decently stacked and with the 50% DMG RES debuff inflicted, Groitz can output some good damage with this Mega Arts. After, y'know, stacking for like 50s.`
                },
                ability1: `Niche but welcome, assuming that the enemy isn't poison immune.`,
                ability2: `Once again assuming that the enemy isn't immune to poison, this passive is a nice boost to his damage output.`,
                ability3: `Think Joker Selia's passive but worse. Groitz only has one Special Skill that can poison and it happens to be on the longest cooldown. You're likely not getting this very high unless there are a ton of ads in the quest.`,
                trueweapon: {
                    true1: {
                        skill: `Great for trying to stack his passive, although the ArtGen is one time use.`,
                        ability1: `You'll mainly be using this True Weapon for the DMG buff. The Skill CT is appreciated but not very impactful.`,
                        ability2: `Your typical healthy passive, nice when applicable.`
                    },
                    true2: {
                        skill: `It's good on paper, until you realize that it won't last long enough to be active when he uses Mega Arts.`,
                        ability1: `Not really that great unless your team lacks basic ArtGen, it's not worth it over a regular ArtGen equip.`,
                        ability2: `Very minor passive, you probably won't even notice that it's there.`
                    }
                },
                overall: `Joker Selia, Emi and Keit had a kid! Groitz feels like a strange combination of the three units, having a stacking DMG passive, poison based gimmicks and Revelation. However, due to being a Revelation unit, he suffers from the drawbacks of not being able to use equips and needing to charge up his Mega Arts. His optimal use would range from being a poison based DPS to a straight debuff bot, but if you ask me, you should save yourself the effort and use someone else.`,
                lastupdated: `August 25, 2022`,
                author: `Senshi`
            }
    },
    { //Hart (Earth) - Complete, Review done
        id: 103276312,
        rank: "SS",
        twrequire: "favorable",
        name: "Hart (Earth)",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103275312.png",
                detailawk: "/db/Units/Detail/unit_detail_103276312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103275312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103276312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6743,
                hpplus: 999,
                atk: 2852,
                atkplus: 437,
                def: 2898,
                defplus: 444
            },
        skillset: 
            {
                skill: "9s CT - 1500% Earth DMG (MAG). For 5s, reduce Enemies' accuracy by 10%.",
                skillbreak: 600,
                arts: "11000% Earth DMG (MAG). For 20s, increase Allies' Arts DMG by 50%, reduce Enemies' Earth RES by 30%.",
                artsbreak: 1000,
                truearts: "36000% Earth DMG (MAG). For 13s, heal Allies' HP by 6%/s and increase Allies' Arts by 5/s. For Human Allies, increase Arts by 7/s instead.",
                trueartsbreak: 1300
            },
        passive: 
            {
                ability1: "On every use of own True Arts, permanently increase Allies' stats by 10% (max 30%). Does not stack with Abilities of the same name.",
                ability2: "When Hart joins the battle from the 2nd party in Cross Battle, he gains Arts equal to what the unit he replaces had at the time.",
                ability3: "Increase Earth Allies' DMG by 30%. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat3amt: 1,
                    hover3: "Goldola",
                    mat4: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat4amt: 2,
                    hover4: "Elpneumas",
                    mat5: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat5amt: 2,
                    hover5: "Tonetaurus"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Vertrag",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_108501.png",
                skill: "50s CT - 7000% Earth DMG (MAG). For 10s, increase own DMG by 40%, reduce Enemies' Earth RES by 35%.",
                skillbreak: 500,
                passive: 
                    {
                        ability1: "When equipped by Hart (Earth), increase stats by 10% and Arts by 2/s. Does not stack with Abilities of the same name.",
                        ability2: "Increase MAG DMG by 10%."
                    }
            },
        lore: 
            {   
                evo5: "A Human youth from a quiet town. Having lost his mother at a young age, his teacher Miranda helped look after him. After turning seventeen, he decided to join the Rangers with his friends Vultee and Priscilla. His mother's sacrifice inspired him to live to help and protect others.",
                evoawk: "He and his friends went to the forest to hunt a monster and prove their worth. However, the trial was more difficult than they expected, and they found themselves faced with death. It was then he remembered what Miranda said at his mother's funeral. 'You need to survive until your final breath, because your life was given to you by someone special.' This gave him the determination to stand strong and save his friend's lives."
            },
        review:
            {
                skill: `This really won't be the most consequential skill, especially with how unreliable depending on the enemy to miss can be.`,
                arts: `Quite a nice offensive buff with a long enough duration that if Hart is supported enough, Hart should be able to loop this without too much difficulty with his extremely loaded True Arts. If you ever find yourself an opening to do so, this can serve as a nice foil to his more support-oriented True Arts.`,
                truearts: `Well... those are some... very high buff values for two of the most important buffs to have in the game. Despite the short duration, Hart's True Arts more than makes up for it in sheer potency, offering perhaps the highest heal over time buff in the game on top of arts generation near the best-in-class, especially when combined with the additional generation for humans. Even with no other humans on your team, this still better allows Hart to loop his own True Arts buffs, which will be your number one priority if you're playing him.`,
                ability1: `As if his True Arts wasn't loaded enough already, this all-around boost just makes everyone tankier as well as giving a slight offensive edge, not to mention making his healing even more effective than it already is.`,
                ability2: `As Cross Battles aren't the most heavily-used feature at the time of writing, this buff won't impact the vast majority of content. Still, the 6 arts per second it provides for 30 seconds serves as a very large boost if you find yourself in a situation where this actually applies.`,
                ability3: `A fairly-sized buff, which will, at the very least, increase Hart's own damage and can surely help out alongside any other Earth Party members.`,
                trueweapon: {
                    skill: `A surprisingly very offensive buff for a support unit's True Weapon. While Hart isn't the most offensive unit, at the rate he'll be spamming his True Arts, this can still be taken advantage of, especially if you have other Earth units on your team.`,
                    ability1: `While a stat boost is evidently nice, the main attraction here is that arts generation, making this by far the best option for Hart's magic slot if you own it.`,
                    ability2: `Again, Hart isn't a traditional damage dealer, but at this point from his True Weapon active to his passives and Arts buff, he'll begin to start dealing a decent amount.`
                },
                overall: `Hart, upon release, will be a large step up from most units within his role, being able to perform two of the most influential roles in the game to a degree with little precedent with his True Arts as well as having many, many cherries on top. Due to this, he's easily slotted into almost all teams that will allow for him for both newbies and veterans alike. There's absolutely no reason that you shouldn't do your guaranteed hyper rare summon on his banner just for the prospect of obtaining him.`,
                lastupdated: `August 18, 2021`,
                author: `Elric`
            }
    },
    { //Hart (Dark) - Complete, Review done + JP ver
        id: 103286512,
        name: "Hart (Dark)",
        translate: `Dark Hart`,
        rank: "B",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103285512.png",
                detailawk: "/db/Units/Detail/unit_detail_103286512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103285512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103286512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6392,
                hpplus: 999,
                atk: 3033,
                atkplus: 464,
                def: 2852,
                defplus: 437
            },
        skillset: 
            {
                skill: "10s CT - 1500% Dark DMG (MAG). 50% chance to inflict a stack of Bleed (up to 3).",
                skillbreak: 500,
                arts: "17000% Dark DMG (MAG). Increase own Arts by 20 + For 15s, increase Allies' Skill CT REC by 150%.",
                artsbreak: 900,
                truearts: "50000% Dark DMG (MAG). For 20s, increase Allies' Critical DMG by 100%.",
                trueartsbreak: 1500,
                phantombullet: "70000% Dark DMG (MAG). For 60s, reduce Enemies' Dark RES by 50%. Phantom Bullet can only be activated three times in a Quest.",
                phantombulletbreak: 2100
            },
        skillsetJP: 
            {
                skill: "10s CT - 1500% Dark DMG (MAG). 70% chance to inflict a stack of Bleed (up to 3).",
                skillbreak: 500,
                arts: "17000% Dark DMG (MAG). Increase own Arts by 20 + For 15s, increase Allies' Skill CT REC by 150%.",
                artsbreak: 900,
                truearts: "60000% Dark DMG (MAG). For 20s, increase Allies' Critical DMG by 150%.",
                trueartsbreak: 1500,
                phantombullet: "90000% Dark DMG (MAG). For 60s, reduce Enemies' DMG RES by 100%. Phantom Bullet can only be activated three times in a Quest.",
                phantombulletbreak: 2100
            },
        passive: 
            {
                ability1: "Increase Critical Rate against Enemies with 3 stacks of Bleed by 100%.",
                ability2: "Increase DMG against Enemies under 30% HP by 80%.",
                ability3: "When near-death, increase Arts by 1/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 2,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat4amt: 3,
                    hover4: "Elpneumas",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Bel Canto",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_109051.png",
                skill: "60s CT - 7500% Dark DMG (MAG). If Enemies have 1 or more stacks of Bleed, inflict 1 stack of Bleed (up to 3). For 8s, reduce Enemies' MAG RES by 35%.",
                skillbreak: 300,
                passive: 
                    {
                        ability1: "When equipped by Hart (Dark), increase ATK by 30% and Arts by 3/s.",
                        ability2: "Increase accuracy by 10%."
                    }
            },
        dreamJP: {
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                        mat3amt: 2,
                        hover3: "Tonetaurus",
                    },
                    passive: {
                        ability1: `When own HP is above 80%, increase own Accuracy by 100%.`,
                        ability2: `When own HP is above 80%, increase own Arts by 4/s.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragValzandeth.png",
                        mat3amt: 2,
                        hover3: "Valzandeth",
                    },
                    passive: {
                        ability1: `Increases Phantom Bullet's DMG Multiplier to 150000% Dark DMG (MAG) instead.`,
                        ability2: `Increase own ATK by 15%.`
                    }
                }
            },
        lore: 
            {   
                evo5: "Hart left home while clutching the gun of his beloved Miranda. The imperial hero Simon, long presumed dead, had murdered her. Hart's chance at revenge finally arrived two years later in a dim cavern outside the capital. Controlled by rage, he reached for Miranda's keepsake with his right hand as his left held the very thing that had taken her life: the Phantom Bullet.",
                evoawk: "A magic gunner who works under Azvan. Though he succeeded in eliminating the legendary sniper Simon with his own Phantom Bullet, the anger and frustration did not fade. He decided to direct it at the empire and their ruthless efforts to kill E-Factor holders like Miranda. No matter how great a threat they posed, the fact they died in utter secrecy was unacceptable to him."
            },
        review:
            {
                skill: `Nice skill to have. The permanent DMG Res down leads to a slightly increased damage output from the team.`,
                arts: `Great for Miasma Stages, or for spamming useful skills like stacking Hart-Xi's Bleed, or Vox's Artsgen Skill.`,
                truearts: `While the additional Crit Dmg is nice, he lacks a way to provide teamwide crit rate. His reliance on a Second unit or Equipment to provide teamwide Crit Rate makes this rather situational to use.`,
                phantombullet: `Once again, we have a unit with most of his power placed on his Phantom Bullet. Lowering the enemies Dark res by 50% for upto 3 Minutes in sustain, and simply lowering Res while dealing large DMG for Dark Nukes.`,
                ability1: `Allows Hart-Xi to benefit from his True Arts' Crit DMG buff, obviously since it requires all 3 bleed stacks you will have to deal with the RNG of the Skill.`,
                ability2: `Big DMG Buff, makes it easier for Hart-Xi to end stages with either his Phantom Bullet or True Arts.`,
                ability3: `Neardeath passive, since it's rare for most teams to lack a healer and function, this passive wont contribute much.`,
                trueweapon: {
                    skill: `Another Juno TW alternative, this time with a shorter duration and the ability to increase your Bleed stacks by 1.`,
                    ability1: `Nice ATK Buff, and APS Passive. If you plan to use Dark Hart in Sustain this is definitely the best in slot.`,
                    ability2: `This accuracy amount is far too low to be noticable.`
                },
                overall: `Dark Hart is in a rough place in global with Tokyo Revengers being expected in the coming weeks, having 2 units that will replace him in Simon Compositions, Mikey and Draken, as such you wont be using Dark Hart much outside of Dark Magic Nukes, or to buff the team's Crit DMG.`,
                lastupdated: `July, 21, 2022`,
                author: `Gian`
            }
    },
    { //Miranda - Complete, Review done
        id: 103296412,
        name: "Miranda",
        rank: "A+",
        twrequire: "favorable",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103295412.png",
                detailawk: "/db/Units/Detail/unit_detail_103296412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103295412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103296412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7171,
                hpplus: 999,
                atk: 2525,
                atkplus: 388,
                def: 3232,
                defplus: 494
            },
        skillset: 
            {
                skill: "12s CT - 1600% Light DMG (MAG). For 6s, reduce Enemies' evasion by 10%.",
                skillbreak: 400,
                arts: "14000% Light DMG (MAG). Cure own Poison and Curse + For 20s, redirect Allies' DMG taken to self and gain a DMG barrier that negates 11000 DMG to self.",
                artsbreak: 1000,
                truearts: "35000% Light DMG (MAG). For 30s, increase Allies' MAG DMG by 30% (stackable), redirect Allies' DMG taken to self and gain a DMG barrier that negates 27000 DMG to self.",
                trueartsbreak: 1600
            },
        passive: 
            {
                ability1: "Increase MAG RES by 50%.",
                ability2: "When an Ally dies (except self), revive that Ally with 50% HP. Ability can only be activated once per Quest.",
                ability3: "Upon taking direct damage from an Enemy while redirecting DMG to self, stop redirecting DMG to self and gain 50 Arts."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat3amt: 3,
                    hover3: "Radachne",
                    mat4: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat4amt: 1,
                    hover4: "Wilkes",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Engaged Shield",
                slot: "/db/Mines/thumbnail/defLB.png",
                detail: "/db/Equipment/Detail/item_detail_108671.png",
                skill: "30s CT - For 15s, increase own DMG RES by 20%. If used when own HP is above 80%, increase DMG RES by 40% instead.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Miranda, increase own Arts by 2/s, Paralysis RES and Freeze RES by 100%. Does not stack with Abilities of the same name.",
                        ability2: "Increase DEF by 30% when under a DMG barrier."
                    }
            },
        lore: 
            {   
                evo5: "Known as the shield of Simon's Hill. She is highly regarded figure of the town as both captain of the Rangers and schoolteacher. Many appreciate her service, particularly after becoming a widow, but the elders fear she will spend the rest of her days alone. However, the broad smile that she gives her students shows nothing but fulfillment.",
                evoawk: "Miranda was instantly smitten by Simon, fated to be her first and last love. Although he seemed to bear some dark burden, she never hesitated to keep him company. No matter how he sulked, she always talked to him, walked to school with him, and made food for him. Eventually, her sunny disposition reached through, melting his heart of ice."
            },
        review:
            {
                skill: `Nothing crazy, evasion debuff is not very useful in a lot of content especially considering that it's only 10%.`,
                arts: `For a taunt tank, being able to cure status is very important especially if they are really annoying ailments such as curse. In the situation where you find yourself needing to cure the aforementioned ailments, using this NA will certainly get you out of sticky situations.`,
                truearts: `Certainly a very strong TA for a tank. On top of boasting the highest barrier in-game, she also have stackable MAG DMG buff, making her not only a tank but also a dps support.`,
                ability1: `The highest innate dmg res that allows you to tank through a lot of content so long as the enemies deal magic damage.`,
                ability2: `Same passive as majority of other taunt tanks, while it is helpful for being able to revive a teammate, this should be avoided at all cost due to the fact that most quest requires no-death requirement to clear anyways on top of the fact that once a unit is revived, they no longer have their damage redirected towards Miranda until she uses NA/TA again.`,
                ability3: `A good art refund when your barrier breaks, allowing you to go back into arts faster. But in that time frame, the rest of your team will have to take damage until Miranda can taunt again. Not a game-breaking passive, in fact this passive could be very detrimental at times since some high damaging bosses can kill you very quickly if you aren't under a taunt. You'll see this effect truly shine in places such as Awoken Nier stage or Virago stage where guard killer is active.`,
                trueweapon: {
                    skill: `Strong defense equip with a good uptime. Downside is that you need to by above 80% hp for optimal dmg res, which isn't hard with Miranda's passive.`,
                    ability1: `A very useful equip for Miranda and most definitely her BIS in most cases. On top of having 2 arts per second, she also gains immunity to more status. As previously mentioned being able to evade status and cure them is very important. Being paralyzed or frozen while boss starts doing massive amount of damage towards you can be detrimental, but with this true weapon she can ignore all that easily.`,
                    ability2: `A decent perk, giving her an extra def boost to help her.`
                },
                overall: `A good tank unit, a strong magic resistance passive as well as a high barrier on both NA and TA alongside with stackable magic dmg boost every TA. For any quest that deals high magic damage, Miranda is definitely a strong choice. Even for quest that deals physical damage and your innate mag dmg is useless, she can still be used due to her high barrier. While she makes a great taunt tank against magic enemies, she would fall short to Thetis for quest that is more physical damage oriented`,
                lastupdated: `October 19, 2021`,
                author: `Icee`
            }
    },
    { //Simon - Complete, Review done + JP ver
        id: 103306512,
        name: "Simon",
        rank: "A-",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103305512.png",
                detailawk: "/db/Units/Detail/unit_detail_103306512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103305512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103306512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6530,
                hpplus: 999,
                atk: 2929,
                atkplus: 449,
                def: 2809,
                defplus: 431
            },
        skillset: 
            {
                skill: "12s CT - 1800% Dark DMG (PHY). For 8s, reduce Enemies' DMG RES by 10%.",
                skillbreak: 600,
                arts: "18000% Dark DMG (PHY). For 20s, increase DMG by 30%. If this Arts is reactivated before this effect expires, refresh the duration of this effect and increase DMG by a further 30% (up to 240%).",
                artsbreak: 600,
                truearts: "36000% Dark DMG (PHY). Increase own Arts by 50. For 15s, reduce Enemies' Dark and PHY RES by 30%.",
                trueartsbreak: 900,
                phantombullet: "100000% Dark DMG (PHY). Increase own accuracy by 100% and ignore 100% of Enemies' DEF during Phantom Bullet. Phantom Bullet can only be activated three times in a Quest.",
                phantombulletbreak: 1300
            },
        skillsetJP: 
            {
                skill: "12s CT - 1800% Dark DMG (PHY). For 8s, reduce Enemies' DMG RES by 10%.",
                skillbreak: 600,
                arts: "18000% Dark DMG (PHY). For 20s, increase DMG by 30%. If this Arts is reactivated before this effect expires, refresh the duration of this effect and increase DMG by a further 30% (up to 240%).",
                artsbreak: 600,
                truearts: "50000% Dark DMG (PHY). Increase own Arts by 50. For 15s, reduce Enemies' Dark and PHY RES by 45%.",
                trueartsbreak: 900,
                phantombullet: "120000% Dark DMG (PHY). Increase own accuracy by 100% and ignore 100% of Enemies' DEF during Phantom Bullet. Phantom Bullet can only be activated three times in a Quest.",
                phantombulletbreak: 1300
            },
        passive: 
            {
                ability1: "Increase DMG to Human Enemies by 40%.",
                ability2: "Increase DMG against Enemies in BREAK by 40%.",
                ability3: "Increase ATK by 80% and Art by 3/s while unaffected by status ailments."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 1,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 2,
                    hover4: "Code-XTF",
                    mat5: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat5amt: 2,
                    hover5: "Oldeus"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Jaeger",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_108911.png",
                skill: "45s CT - 10000% Dark DMG (PHY). For 8s, increase own PHY and Dark DMG by 40%.",
                skillbreak: 600,
                passive: 
                    {
                        ability1: "When equipped by Simon, increase ATK by 50% and increase Status RES by 20%.",
                        ability2: "Increase DMG to Giant Bosses by 10%."
                    }
            },
            dreamJP: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                        mat3amt: 2,
                        hover3: "Palamicia",
                    },
                    passive: {
                        ability1: `Increase own Arts by 2/s.`,
                        ability2: `Increase own True Arts' duration by an additional 15s.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                        mat3amt: 2,
                        hover3: "Ragsherum Phantom",
                    },
                    passive: {
                        ability1: `Increase own DMG against Humans by 120%.`,
                        ability2: `Increase own Accuracy by 50%.`
                    }
                }
            },
        lore: 
            {   
                evo5: "An expert sniper who once served as captain of a small Human town's self-defense force. During that time, he fearlessly defeated violent monsters, protected his allies, and gained the population's trust. He pierced the hearts of his targets with unfailing accuracy, but he met his end after taking an equally fatal blow to the chest from a black-winged dragon.",
                evoawk: "After moving to a small town in the empire at a young age, he met the previous Ranger captain's daughter, Miranda. They eventually became married, and anyone who saw them shopping in the quare on weekends would think of them as a loving couple. Yet as he pointed his gun at her from the edge of town, his gaze was filled with dark intent."
            },
        review:
            {
                skill: `Nice little DMG RES debuff, nothing noteworthy but not entirely redundant as well.`,
                arts: `With a rather generous uptime of 20s, given adequate support from ArtGen units, Simon should be able to reach his max of 240% DMG buff easily. This should see some use for quests where there are multiple waves, allowing Simon to ramp his DMG before using his coveted Phantom Bullet. Also as an added bonus, Simon’s Arts is able to freeze time for a brief moment.`,
                truearts: `Great debuffs for Dark and PHY units. The 50 Art refund is also nice, although you would rather use his Phantom Bullet in most situations.`,
                phantombullet: `Huge 100k% DMG Multiplier, topped with a huge Accuracy and DEF Ignore buff. Paired with the right support, he is able to match, and even outdamage, Saitama nukes in the current meta. Despite being capped at 3 uses per quest, most bosses would find it hard surviving the brute force of just 1 of Simon’s Phantom Bullet with adequate support.`,
                ability1: `Noticeable DMG buff against Human enemies, nothing much to add here.`,
                ability2: `Again, noticeable DMG buff against Broken enemies.`,
                ability3: `Amazing passive, boasting an impressive passive ArtGen and ATK buff while unaffected by ailments. As such, it might be worth the effort to ensure Simon doesn’t stay affected by status ailments.`,
                trueweapon: {
                    skill: `The best self buff equipment for Dark/PHY nukers such as Simon himself. Not only does it have a huge DMG multiplier, but it essentially boosts Simon’s, or any Dark/PHY unit’s, DMG output by a whopping 80%. Unlike other nuke equipment, it also has a decent cooldown which is a nice bonus.`,
                    ability1: `A lackluster unique passive. Although the ATK buff is nice to have, due to the passive ATK buff Simon already receives while unaffected by ailments, it doesn’t affect his DMG as much. The small Status RES buff also isn’t anything noteworthy.`,
                    ability2: `Nice added bonus DMG against those pesky Giant Bosses.`
                },
                overall: `Hey, it’s the first Phantom Sniper to actually use Phantom Bullet and actually has a gun, albeit not a sniper! No doubt, Simon is definitely an amazing unit. Not only does he perform well in sustain situations, able to consistently ramp and deal damage, but also excels in Dark nukes. Being Dark and Human, he is able to take full advantage of Forte’s, a key support unit in Dark nukes, buffs. Coupled with the huge Damage multiplier on his Phantom Bullet and nuke-friendly slots, he can easily fill the shoes of a DPS nuker. With the right support from equips and other units, he is able to rival the current Saitama nukes dominating most leaderboards in terms of Damage. Best of all, he has a pretty sick gun :)`,
                lastupdated: `March 18, 2022`,
                author: `Jeffers`
            }
    },
    { //Vultee (Earth) - Review done
        id: 103316312,
        name: "Vultee (Earth)",
        rank: "A+",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103315312.png",
                detailawk: "/db/Units/Detail/unit_detail_103316312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103315312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103316312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6858,
                hpplus: 999,
                atk: 2702,
                atkplus: 415,
                def: 3240,
                defplus: 496
            },
        skillset: 
            {
                skill: "11s CT - 1500% Earth DMG (PHY). Increase own Arts by 20. If self is not affected by status ailments, increase Arts by an additional 20.",
                skillbreak: 900,
                arts: "15000% Earth DMG (PHY). Increase own Arts by 50. Increase Bullet Stacks by 1 (up to # of Phantom Bullet activations left). For 10s, increase ATK by 50%.",
                artsbreak: 1000,
                truearts: "30000% Earth DMG (PHY). For 40s, increase Allies' Earth DMG by 30% (stackable), redirect Allies' DMG taken to self and gain a DMG barrier that negates 25000 DMG to self.",
                trueartsbreak: 1800,
                phantombullet: "[# of Bullet Stacks × 38000%] Earth DMG (PHY). Reset Bullet Stacks to 1. For 40s, increase own Physical RES by 20% and gain a DMG barrier that negates 25000 to self. Phantom Bullet can only be used 6 times in a Quest. Base # of Bullet Stacks is 1. Each Bullet Stack consumed due to an activation of Phantom Bullet consumes an additional activation of Phantom Bullet.",
                phantombulletbreak: 2000
            },
        passive: 
            {
                ability1: "Increase PHY RES by 20%.",
                ability2: "Increase DEF by 20% when under a DMG barrier.",
                ability3: "When own HP is above 50%, increase ATK by 50%. When own HP is below 50%, increase DMG RES by 20%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat3amt: 2,
                    hover3: "Goldola",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 2,
                    hover4: "Code-XTF",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Barbatos",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_109731.png",
                skill: "60s CT - 12000% Earth DMG (PHY). For 15s, reduce Enemies' PHY RES by 20%. If user is Earth, reduce Enemies' PHY RES by 30% instead.",
                skillbreak: "700",
                passive: 
                    {
                        ability1: "Increase own Skill CT REC by 10%.",
                        ability2: "When equipped by Vultee (Earth), increase DMG RES by 10%, and increase Paralysis and Freeze RES by 100%."
                    }
            },
        lore: 
            {   
                evo5: "He lost everything he held dear: a warm home, a close friend, a sunny sweetheart. He blamed his own weakness for being unable to cling to the hope, the forgiveness, the confidence and the tenacity that could have made a difference. He let lies and self-deceit cloud his vision, depriving him of freedom only found in the truth.",
                evoawk: "Carrying a turret in one hand, he has become an agent of pure destruction. His aggression runs counter to claims that he only harms his targets. Anyone who knows of his past might interpret that as him purposefully keeping others out of harm's way, but it is impossible to know for sure."
            },
        review:
            {
                skill: `A sizable amount of self arts when not in status.`,
                arts: `While the attack buff is decent, it won't get much use because it has a low uptime and Vultee isn't a DPS. The 50% arts refund would allow you to spam Arts more, however there's still no reason to use it over his True Arts and Phantom Bullet, as it will lower your available Phantom Bullet count.`,
                truearts: `While a general damage buff would be preferred over an element locked one, this still helps Vultee synergize with other great earth units like Fitoria or Cestina. The sturdy barrier also ties him with some of the top tanks for the highest total negation per TA/PB, allowing him to soak up more damage than other tanks like Gerald or Naofumi.`,
                phantombullet: `A chonky shield paired with the other sources of DMG resistance on his kit means Vultee can soak up a lot of damage from bosses, and even more from physical bosses. While he can only be used at max potential for 6 Phantom Bullets, ideally most missions won't last through all of them, and he is still very tanky regardless.`,
                ability1: `While it is a decent buff in terms of percentage, the ATK buff doesn't do much for him when he isn't ramped, which as mentioned before, there's no reason to do. The DMG resistance however provides an added layer of security in the event his health starts getting low, and if he has proper gear and PB activated it can help him hit 100% passive Physical DMG resistance.`,
                ability2: `Nothing too special, and completely useless if the boss can crit.`,
                ability3: `More passive DMG resistance. when paired with the rest of his kit, he can hit 60% Physical DMG resistance without the help of other units or equips.`,
                trueweapon: {
                    skill: `While it isn't amazing, 25% uptime is still pretty decent, especially paired with a sizable Physical resistance debuff when on an earth unit.`,
                    ability1: `Passive flinch resistance which will for the most part protect Vultee from being interrupted before he can taunt, as well as a nice extra bit of passive DMG resistance.`,
                    ability2: `A nice little bonus, but won't be too noticeable overall.`
                },
                overall: `Having the ability to reach high and even 100% Physical DMG resistance with the right gear makes Vultee-Lr one of the best if not the best taunt tanks for physical damage, and even with other types he preforms exceptionally well alongside the other top tanks like Miranda or Thetis. While it would ideally be a general buff, his stacking teamwide Earth Damage buff works well when he is in a team with damage dealing Earth units like Fitoria, Deadmaster, or Rudeus. And although it would be nice if he had two defense slots, the physical slot allows him to hold artsgen equipment to make him more self sufficient, and the heal slot allows him to hold a blessed necklace for a self revive on missions that have a big nuke. Even as a general tank without PB, he still does his job well, and will be a great addition to your box regardless of whether you're a vet or a newbie.`,
                lastupdated: `December 15, 2022`,
                author: `Pula`
            }
    },
    { //Priscilla - Complete, Review done + JP ver
        id: 103326112,
        name: "Priscilla",
        rank: "B+",
        twrequire: "favorable",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103325112.png",
                detailawk: "/db/Units/Detail/unit_detail_103326112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103325112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103326112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7158,
                hpplus: 999,
                atk: 3029,
                atkplus: 464,
                def: 2456,
                defplus: 378
            },
        skillset: 
            {
                skill: "9s CT - 1600% Fire DMG (PHY). Increase own Arts by 20 + If own HP is above 90%, increase own Arts by an additional 20.",
                skillbreak: 300,
                arts: "16000% Fire DMG (PHY). For 15s, reduce Enemies' Fire RES by 30%.",
                artsbreak: 1000,
                truearts: "62000% Fire DMG (PHY). For 20s, increase Allies' Critical Rate by 100%, and increase Allies' Critical DMG by [30% × # of Fire Allies in Party].",
                trueartsbreak: 1400
            },
        skillsetJP: 
            {
                skill: "9s CT - 1600% Fire DMG (PHY). Increase own Arts by 20 + If own HP is above 90%, increase own Arts by an additional 20.",
                skillbreak: 300,
                arts: "23000% Fire DMG (PHY). For 15s, reduce Enemies' Fire RES by 30%.",
                artsbreak: 1000,
                truearts: "72000% Fire DMG (PHY). For 20s, increase Allies' Critical Rate by 100%, and increase Allies' Critical DMG by [50% × # of Fire Allies in Party].",
                trueartsbreak: 1400
            },
        passive: 
            {
                ability1: "Increase DMG to Earth Enemies by 10%.",
                ability2: "When Priscilla joins the battle from the 2nd party in Cross Battle, she gains Arts equal to what the unit she replaces had at the time.",
                ability3: "Each time Arts is used, increase Allies' ATK by 10% (up to 50%)."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat3amt: 1,
                    hover3: "Ifrit",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 2,
                    hover4: "Radachne",
                    mat5: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat5amt: 2,
                    hover5: "Elpneumas"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Ember Bolt",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_108881.png",
                skill: "45s CT - 4000% Fire DMG (PHY). For 8s, reduce Enemies' PHY RES by 20% + If Enemies' HP is under 50%, reduce Enemies' PHY RES by an additional 20%.",
                skillbreak: "?",
                passive: 
                    {
                        ability1: "When equipped by Priscilla, increase DMG and accuracy by 50%. Does not stack with Abilities of the same name.",
                        ability2: "Increase ATK by 5%."
                    }
            },
            dreamJP: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                        mat3amt: 2,
                        hover3: "Flogassa",
                    },
                    passive: {
                        ability1: `Increase own Arts by 3/s.`,
                        ability2: `When own HP is above 80%, increase own ATK by 50%.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragOldeus.png",
                        mat3amt: 2,
                        hover3: "Oldeus",
                    },
                    passive: {
                        ability1: `Increase all allies' Accuracy by 30%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own DMG by 50% against enemies below 30% HP.`
                    }
                }
            },
        lore: 
            {   
                evo5: "An archer from a small human village. She attended school with childhood friends Hart and Vultee. A strict, rigorous upbringing left her with a tendency to act stiff, but it also gave her proper manners. That along with a natural charm endeared her to others. Even so, she was teasingly called 'Class Rep' since her earnestness could be more than a little overbearing at times.",
                evoawk: "An archer from the village of Simon's Hill. She wept bitterly the night her friend Hart fled their home. She believed from the bottom of her heart that he had not killed Miranda, but the fear their relationship would collapse the moment she asked him for confirmation prevented her from doing so. Regret soon taught her what she should have feared instead was losing him forever. Changing that weakness into determination, she set out to find Hart in hopes of undoing her mistake."
            },
        review:
            {
                skill: `Nice degree of self-sufficiency in terms of art gauge, allowing for Priscilla to use her True Arts faster and therefore deal more damage per second.`,
                arts: `As a normal DPS unit, it's best to just stick with the True Arts. Ramping the ATK buffs requires an unnecessary amount of time and art gauge for most quests, and ramping or alternating will just end up taking more time than you need to clear. For nuking, this wouldn't have any relevance due to another/better source of fire resistance debuffing already being present in a proper fire nuke, and just in general, the critical rate and damage buffs from True Arts would provide a lot more value.`,
                truearts: `Increasing the team's critical rate and providing a damage buff that scales with more fire units, Priscilla will be an amazing asset for any sort of team with damage in mind. Having more fire units on the team is by no means necessary for her to unleash a great amount of damage and provide awesome nuke support, but it does allow for more benefits if you do happen to have them`,
                ability1: `An additional increase that is quite small, conditional, and rather unnoticeable to her damage output.`,
                ability2: `This only applies to a small portion of content (raids, select few of quests), but depending how you utilize it, it can allow for some more flexibility in the second team's equipment slotting. The passive won't come into play for the huge majority of things.`,
                ability3: `True Arts usage does not count toward this bonus, and as mentioned before, the ATK bonus is generally not worth the time and effort ramping in most content.`,
                trueweapon: {
                    skill: `With a somewhat short cooldown compared to similar equipment and a conditional but potent effect, this is a pretty good item on Priscilla when sustaining. Unfortunately, the additional 20% does not come into play when nuking, thus there are still a handful of items that are on par or even better (albeit rarer) for resistance debuffing in this case.`,
                    ability1: `Her respective passive provides a generous damage boost along with accuracy to help against any bosses that might have high evasion.`,
                    ability2: `A tiny increase in ATK, the effect on damage output honestly isn't noticeable.`
                },
                overall: `Priscilla is a powerful DPS and consistent critical rate/damage buffing unit, providing great damage support while simultaneously hitting hard for many teams, whether they're mono-fire or mixed elements. In terms of nuking, she'll be a sought-after unit for veterans looking to use competitive fire nuke teams, and even outside fire nuking, she opens up a new variety of possible nuke teams as a critical buff support`,
                lastupdated: `January 12, 2022`,
                author: `Praying Patrick`
            }
    },
    { //Serah - Complete
        id: 103346412,
        name: "Serah",
        rank: "B",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103345412.png",
                detailawk: "/db/Units/Detail/unit_detail_103346412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103345412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103346412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6822,
                hpplus: 999,
                atk: 2211,
                atkplus: 341,
                def: 2333,
                defplus: 359
            },
        skillset: 
            {
                skill: "15s CT - Arts Mode: Increase Allies' Arts by 10. Heal Mode: Heal Allies' HP by 10%. Alternate between Arts Mode and Heal Mode on each use of Skill.",
                skillbreak: 0,
                arts: "12000% Light DMG (MAG). For 20s, reduce Enemies' Status RES by 50%.",
                artsbreak: 1100,
                truearts: "33000% Light DMG (MAG). For 15s, reduce Enemies' evasion by 20% and Enemies' MAG RES by 40%.",
                trueartsbreak: 1800
            },
        passive: 
            {
                ability1: "Increase MAG RES by 10%.",
                ability2: "Double the effects of own True Arts against Enemies with status ailments.",
                ability3: "Increase Allies' HP by 5%. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 1,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 2,
                    hover4: "Code-XTF",
                    mat5: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat5amt: 1,
                    hover5: "Flogassa"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Ready Maid",
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_108721.png",
                skill: "40s CT - For 10s, increase Allies' Arts by 3/s and Light Allies' DMG by 30%.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Serah, increase Skill CT REC by 70% and increase stats by 20%.",
                        ability2: "Increase MAG evasion by 10%."
                    }
            },
        lore: 
            {   
                evo5: "Assistant to Professor Arcana of the Magistone Laboratory. Despite her easygoing appearance, she is a hard worker who does everything from housework to research, and even finds time to look after a mysterious creature called Serko. Helping with the professor's studies has given her a wealth of knowledge about magistone.",
                evoawk: "A young girl who works with Professor Arcana in the Magistone Laboratory. Her rude words can give a cold and indifferent impression, but she has genuine enthusiasm for magistone research. This comes from a number of sources, with the greatest of all being the wordless joy from performing experiments. The first time she felt that, she knew she had found her ideal job."
            }
    },
    { //Airi - Complete
        id: 103356312,
        name: "Airi",
        rank: "B+",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103355312.png",
                detailawk: "/db/Units/Detail/unit_detail_103356312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103355312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103356312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7000,
                hpplus: 999,
                atk: 3150,
                atkplus: 482,
                def: 2510,
                defplus: 386
            },
        skillset: 
            {
                skill: "12s CT - 1000% Earth DMG (PHY). For 10s, increase own DMG by 10% (stackable).",
                skillbreak: 500,
                arts: "16000% Earth DMG (PHY). For 20s, enter Tenga Mode. During Tenga Mode: 16000% Earth DMG (PHY). Increase duration of Tenga Mode by 20s.",
                artsbreak: 800,
                truearts: "45000% Earth DMG (PHY). For 12s, increase own Critical Rate by 100%. During Tenga Mode: 90000% Earth DMG (PHY). For 12s, increase own Critical Rate by 100%.",
                trueartsbreak: 2000
            },
        passive: 
            {
                ability1: "Increase Critical DMG by 30%.",
                ability2: "Increase DMG to Enemies under 30% HP by 20%.",
                ability3: "While in Tenga Mode, increase own ATK and Skill CT REC by 100%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat3amt: 1,
                    hover3: "Goldola",
                    mat4: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat4amt: 2,
                    hover4: "Flogassa",
                    mat5: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat5amt: 2,
                    hover5: "Tonetaurus"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Ouen",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_108971.png",
                skill: "70s CT - 5000% Earth DMG (PHY). For 15s, increase own DMG by 50% and Earth DMG by 70%.",
                skillbreak: 600,
                passive: 
                    {
                        ability1: "When equipped by Airi, increase DMG by 30% and Arts by 1/s.",
                        ability2: "Increase PHY DMG by 20%."
                    }
            },
        lore: 
            {   
                evo5: "A soldier of the Valkia army whose talent with the sword is seen only once a generation. She dotes on Forte, but the younger officer finds her unchecked optimism disagreeable. Though this causes her some amount of concern, she has not yet felt the need to explain the reason for her behavior.",
                evoawk: "The greatest swordswoman in Valkia. She consistently achieves top-class results during military exercises, so everyone expects her to become the next sergeant. The fact that Forte responds coldly to her doting is one of her few worries. She read 'Bonds Beyond Races', the biography of two warrior brothers, at a young age, which greatly influenced her personal moral code."
            }
    },
    { //Lyla - Complete, Review done
        id: 103366412,
        name: "Lyla",
        rank: "B+",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103365412.png",
                detailawk: "/db/Units/Detail/unit_detail_103366412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103365412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103366412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7123,
                hpplus: 999,
                atk: 3019,
                atkplus: 462,
                def: 2490,
                defplus: 383
            },
        skillset: 
            {
                skill: "12s CT - 1800% Light DMG (PHY). If this Skill crits, increase own Arts by 30.",
                skillbreak: 500,
                arts: "17000% Light DMG (PHY). Increase Allies' Critical DMG by [20% x # of times this Art has been activated in the quest] (up to 100%).",
                artsbreak: 1200,
                truearts: "55000% Light DMG (PHY). Increase Own True Art's multiplier by [15000% x # of Unique Elements in the Party except Self].",
                trueartsbreak: 2100
            },
        passive: 
            {
                ability1: "Increase DMG against Beast Enemies by 30%.",
                ability2: "Increase Critical Rate by 20%.",
                ability3: "Increase Critical Rate by 80% if unafflicted by any status ailments. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 1,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 1,
                    hover4: "Code-XTF",
                    mat5: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat5amt: 2,
                    hover5: "Radachne"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Maximus",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_109241.png",
                skill: "60s CT - 8800% Light DMG (PHY). For 12s, reduce Enemies' PHY RES by 20%. If user is Light, reduce Enemies' PHY RES by 35% instead.",
                skillbreak: 1100,
                passive: 
                    {
                        ability1: "When equipped by Lyla, increase Critical DMG by 60%. Does not stack with Abilities of the same name.",
                        ability2: "Increase Skill DMG by 20%."
                    }
            },
        lore: 
            {   
                evo5: "A heavy gunner of Valkia's army. Her hobby is collecting stuffed toys. She impresses everyone with her outstanding performance during military exercises, but she tends to lose herself in the thrill of combat. Younger than Forte and Airi, she values both as respected role models. A terrible experience soon after she enlisted taught her how weak she was then, but only a handful of others know exactly what happened.",
                evoawk: "The best gunner in Valkia's army. She overcame her past trauma during a particularly tough training session. That mental fortitude later allowed her to support the promoted Forte. The influence of her closest comrades can be seen in her serious yet considerate personality. After Airi's disappearance, she took the initiative to form a search team that is active to this day, demonstrating her belief they will meet again."
            },
        review:
            {
                skill: `Pretty good arts generation, one of the best things anyone could ask for on a skill. Considering her critical rate passives, there usually won't be trouble utilizing this skill.`,
                arts: `While it is one of those stacking mechanics, at least the damage buffs are teamwide and optional as opposed to a major requirement for certain units to deal damage themselves (i.e Orvell/Batiste). Most usage for the Normal Arts will be in long-term quests with multi-elemental sustain teams, situations where you will more likely have the opportunity to ramp these buffs up. However, do note that you have to bring a critical rate buffer if you're actually planning to make use of this.`,
                truearts: `Having to compose the team of at least three unique elements, this will restrict options on who to bring as support/buffers. Some nuking examples are Shadie with Cestina + Kimono Milim or Erza + Forte, while Mako + Hart + [Non-Earth/Non-Light Damage Support] or Erza + Hart + [Water/Fire/Dark Arts Generator] will work nicely as sustaining compositions. As long as you can set up a decent multi-elemental team, Lyla should be able to delete most bosses if the true arts can actually land. Yes, do note that due to her animations, her true arts may not hit the boss.`,
                ability1: `The amount is fairly decent, but you probably won't come across beast enemies too often.`,
                ability2: `It's the small puzzle piece to complete the 100% critical rate when in conjunction with her main passive.`,
                ability3: `It's effectively 100% critical rate when taking her other critical rate passive into account. Status ailments aren't an issue for the majority of quests, so utilizing this passive usually won't be difficult.`,
                trueweapon: {
                    skill: `True Maximus applies a hefty physical damage resistance debuff on enemies when equipped on Lyla, and considering its generous cooldown for a resistance debuffing item, it's pretty good on her for sustaining. For nuking, it's the most potent option for inflicting physical resistance when it's equipped on her, but it's still substitutable with alternatives that offer 5% less (or with True Draco La'cryma) if you do not have it.`,
                    ability1: `This will boost her damage output by a fairly large amount.`,
                    ability2: `Because it only affects the damage dealt by Lyla's skill, this effect is negligible.`
                },
                overall: `Unlike most units, Lyla will not perform as well on mono-elemental teams, and her large multiplier's reliance on unique elements largely limits the possible compositions you can make with her. However, Lyla can deal a huge amount of DPS if you can place her in a compatible team. Unfortunately, her attacks' hitbox is pretty bad as it shoots toward the ground, so her position on the screen will be something important to keep note of as she may miss sometimes. Newer players (or those with less options and variety in their unit lists) will struggle to make the most out of her kit, and most players will generally opt for a less-restrictive DPS option to place on their teams. As for the nuking connoisseurs, they'll most likely have compositions that are stronger and easier to make (i.e Saitama/Simon nukes).`,
                lastupdated: `June 9, 2022`,
                author: `Praying Patrick`
            }
    },
    { //Summer Juno - Complete + JP ver.
        id: 103376212,
        name: "Summer Juno",
        rank: "A",
        attribute: "Water",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103375212.png",
                detailawk: "/db/Units/Detail/unit_detail_103376212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103375212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103376212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6969,
                hpplus: 999,
                atk: 3001,
                atkplus: 460,
                def: 2624,
                defplus: 403
            },
        skillset: 
            {
                skill: "15s CT - 3000% Water DMG (MAG). For 10s, increase own DMG by 20%.",
                skillbreak: 800,
                arts: "24000% Water DMG (MAG). For 15s, Increase Allies' Skill CT REC by 100% and reduce Enemies' Water RES by 30%.",
                artsbreak: 1300,
                truearts: "82000% Water DMG (MAG). For 60s, increase Allies' MAG DMG and Water Allies' DMG by 50% (stackable).",
                trueartsbreak: 2200
            }, 
        skillsetJP: 
            {
                skill: "15s CT - 3000% Water DMG (MAG). For 10s, increase own DMG by 20%.",
                skillbreak: 800,
                arts: "27000% Water DMG (MAG). For 20s, Increase Allies' Skill CT Speed by 100% and reduce enemies' Water RES by 30%.",
                artsbreak: 1300,
                truearts: "82000% Water DMG (MAG). For 60s, increase allies' MAG DMG and Water allies' DMG by 60% (stackable).",
                trueartsbreak: 2200
            }, 
        passive: 
            {
                ability1: "When HP is Above 80%, increase own Arts by 3/s.",
                ability2: "Increase own Arts by 5/s if unafflicted by Status Ailments.",
                ability3: "Increase DMG to Giant Bosses by 30%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat3amt: 1,
                    hover3: "Palamicia",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 2,
                    hover4: "Radachne",
                    mat5: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat5amt: 2,
                    hover5: "Flogassa"
                }
            },
            dreamJP: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                        mat3amt: 2,
                        hover3: "Flogassa",
                    },
                    passive: {
                        ability1: `Increase own DMG against Fire enemies by 150%.`,
                        ability2: `Increase own Status ailment RES by 30% (except Faint).`
                    }
                },
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                        mat3amt: 2,
                        hover3: "Freydal",
                    },
                    passive: {
                        ability1: `Increase own CRI rate by 50% when above 80% HP.`,
                        ability2: `At the Start of a Quest, increase own Arts by 150. Does not stack with Abilities of the same name.`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Juno's Pareo",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_110431.png",
                skill: "40s CT - 9000% Water DMG (MAG). Increase own Arts by 30. If user is Water, for 10s, increase own stats by 30%.",
                skillbreak: 800,
                passive: 
                    {
                        ability1: "When equipped by Summer Juno, increase duration of True Art's effects by 20s. Does not stack with Abilities of the same name.",
                        ability2: "Increase Skill CT REC by 5%."
                    }
            },
        lore: 
            {   
                evo5: "The queen of Granadia, a small country to the east, brought her sister to an ocean paradise. Her boasts of being the best swimmer in the kingdom was met with uncharacteristic protest form the captain of her Templars. To settle the dispute, they decided to race to the closest island and back, and loser would have to do anything the winner commanded. They stood at the starting line waiting for the signal, competetive spirit burning in their eyes.",
                evoawk: "Halfway through the race, her composure vanished. The Templar captain was always right behind her, no matter how fast she went. The finish line approached with them side by side. She then asked what he would command of her. That sudden question shook his focus, and she used that chance to pull ahead. but while she exulted in her imminent victory and pictured his scowl, she came to a certain realization. 'Wait, what did I even want him to do?'"
            },
        review:
            {
                skill: `A long skill CT with a decent buff duration to boost her damage. You will look to keep this buff active before either of her Arts in order to increase her damage output.`,
                arts: `Decent multiplier with pretty niche effects. The skill CT recovery buff can see use in Miasma stages and the Water RES debuff would be nice to further amplify her damage or support water nuking; However, for general use and nuking, you would just default to her True Arts instead.`,
                truearts: `A hefty multiplier paired with stackable damage buffs. Her damage is further amplified when she is allowed to stack as a result of the lengthy 50 second buff duration, especially against longer stages. The nature of its stacking ability also makes it stackable with magic and water damage buffs from other units such as Santa Rimuru, etc. Hence, this True Arts solidifies Summer Juno as a competent dps as well as damage support for the team for general use or nuking purposes.`,
                ability1: `Self sufficiency is always appreciated though it is tied to a HP condition. Healing might be required for certain situations if you want to keep this passive running.`,
                ability2: `Another source of self sufficiency in her kit but tied to her being free of status ailments this time. Bring cleanse if stage is known to inflict status ailments to keep this passive running.`,
                ability3: `A nice boost when dealing with giant bosses to further increase her damage output.`,
                trueweapon: {
                    skill: `Another artsgen equipment for general usage. On top of it, it increases own stats by 30% if Water unit. Which works well with Summer Juno's passive.`,
                    ability1: `As if the duration wasn't long enough already. This will enable Summer Juno to stack at least one more True Art.`,
                    ability2: `It's a nice bonus, but nothing too noticeable.`
                },
                overall: `As if Summer Juno wasn't already strong enough. With her buffs she is now capable of dealing even more damage than before. Furthermore, she has amazing self sufficiency with her passives and damage buffs for the team especially for magic/water teams. That being said, she’s one of the best magic dps units, and a common unit in magic and/or water nuking comps. The damage buffs she provides aren't a joke either as the duration of the buffs with her True Weapon will allow her to keep up 5-6 stacks on average with enough support. She is ultimately a luxury gift for any box, so don't go overboard and save your money.`,
                lastupdated: `December 1, 2022`,
                author: `Alice`
            }
    },
    { //Summer Leone - Complete, Review done + JP ver
        id: 103386312,
        name: "Summer Leone",
        rank: "B+",
        attribute: "Earth",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103385312.png",
                detailawk: "/db/Units/Detail/unit_detail_103386312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103385312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103386312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6818,
                hpplus: 999,
                atk: 2676,
                atkplus: 411,
                def: 2715,
                defplus: 417
            },
        skillset: 
            {
                skill: "15s CT - 1500% Earth DMG (MAG). Increase own Arts by 30.",
                skillbreak: 500,
                arts: "12000% Earth DMG (MAG). Increase Allies' next True Arts' or Super Arts' DMG by 150%. Increase Earth Allies' next True Arts' or Super Arts' DMG by 300% instead.",
                artsbreak: 1000,
                truearts: "60000% Earth DMG (MAG). Increase Allies' Arts by 20. For 20s, Increase Allies' Critical Rate by 100% and Earth Allies' Arts by 4/s.",
                trueartsbreak: 2200
            },
        skillsetJP: 
            {
                skill: "15s CT - 1500% Earth DMG (MAG). Increase own Arts by 30.",
                skillbreak: 500,
                arts: "15000% Earth DMG (MAG). Increase Allies' next True Arts' or Super Arts' DMG by 160%. Increase Earth Allies' next True Arts' or Super Arts' DMG by 320% instead.",
                artsbreak: 1000,
                truearts: "60000% Earth DMG (MAG). Increase Allies' Arts by 30. For 25s, Increase Allies' Critical Rate by 100% and Earth Allies' Arts by 4/s.",
                trueartsbreak: 2200
            },
        passive: 
            {
                ability1: "When HP is above 70%, increase Equip CT REC by 20%.",
                ability2: "Increase Blind RES by 10%.",
                ability3: "Increase Demon Allies' stats by 10%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat3amt: 1,
                    hover3: "Goldola",
                    mat4: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat4amt: 2,
                    hover4: "Freydal",
                    mat5: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat5amt: 2,
                    hover5: "Fosradius"
                }
            },
        dreamJP: {
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragElpneumas.png",
                        mat3amt: 2,
                        hover3: "Elpneumas",
                    },
                    passive: {
                        ability1: `Increase Earth allies' Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own Disease RES by 50%.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                        mat3amt: 2,
                        hover3: "Ragsherum Phantom",
                    },
                    passive: {
                        ability1: `Increase Demon allies' Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own Paralysis RES by 50%.`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Leone's One-Piece",
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_110441.png",
                skill: "40s CT - Increase target's Arts by 80. For 12s, increase target's Earth DMG by 50%.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Summer Leone, increase own Arts by 3/s. Does not stack with Abilities of the same name.",
                        ability2: "When near-death, increase DEF by 40%."
                    }
            },
        lore: 
            {   
                evo5: "The princess of Granadia, a small country in the east, was brought to an ocean paradise by her sister. She claimed her lack of swimming talent was due to a curse that caused her to sink whenever she took a breath. Choosing instead to read beneath a parasol, the comfortable sea breeze started to convince her the trip was nonetheless enjoyable. all the while, a small group of green monsters was sneaking up on her.",
                evoawk: "Before she realized what was happening, the Mokyuon had jumped out and shoved her from the pier. She struggled desperately to keep from drowning. Returning to shore after several minutes, the monsters nodded with approval. It appeared their antics had forced her to learn proper breathing. Perhaps they saw the timid girl and knew the sink-or-swim approach was the only way she would succeed."
            },
        review:
            {
                skill: `A skill that increases own Arts is always appreciated.`,
                arts: `A strong damage buff for the team when your DMG Buffs/Debuffs are available for a quick burst of damage.
                Note that this is different from the usual Arts DMG buff, instead only affecting a unit’s True Arts and will be removed after a single use.`,
                truearts: `Great buffs, before this True Arts had pretty mediocre DMG and was basically useless for artsgen since Mono Earth has several Type B artsgens that are better. Now, she stacks with them and even has some respectable damage.`,
                ability1: `Quite an interesting passive, helping Leone get more from her equipment, be it Arts, Dmg Buffs/Debuffs, or Heals.`,
                ability2: `Negligible passive, blind is not often inflicted by enemies and 10% RES will not be enough to protect you from it.`,
                ability3: `Pair Summer Leone with other Demon Allies to make the most out of this stat buff, although it isn’t very significant either.`,
                trueweapon: {
                    skill: `Arts wise this equip provides less arts than LDH, the Earth DMG Up is nice but is limited to one unit.`,
                    ability1: `While this is an amazing passive, the active does leave a lot to be desired for supporting the team.`,
                    ability2: `As with any Near-death passive, this should be considered useless.`
                },
                overall: `Summer Leone used to be a pretty mediocre unit, having her Artsgen completely overlapped by Hart and Norn. Now she can stack with them! Her True Arts duration and multiplier were also buffed, increasing her damage output and buff durations aswell. Mono Earth still remains as a relatively slow and safe team, but welcomes this addition to the list.`,
                lastupdated: `December 1, 2022`,
                author: `Gian`
            }
    },
    { //Elizabeth - Complete
        id: 103396112,
        name: "Elizabeth",
        rank: "C",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103395112.png",
                detailawk: "/db/Units/Detail/unit_detail_103396112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103395112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103396112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6550,
                hpplus: 999,
                atk: 2521,
                atkplus: 388,
                def: 2677,
                defplus: 411
            },
        skillset: 
            {
                skill: "13s CT - 1400% Fire DMG (MAG). For 8s, reduce Enemies' DMG by 10%.",
                skillbreak: 400,
                arts: "12000% Fire DMG (MAG). For 15s, Increase Allies' maximum HP by 20% and reduce Enemies' Burn, Poison and Disease RES by 50%.",
                artsbreak: 800,
                truearts: "32000% Fire DMG (MAG). 60% chance to Inflict Disease for 15s. For 15s, reduce Enemies' DMG RES by 50%, and reduce MAG RES of Enemies inflicted with Disease by this True Arts by 50%.",
                trueartsbreak: 1000
            },
        passive: 
            {
                ability1: "When unafflicted by Status Ailments, increase Arts by 2/s.",
                ability2: "Increase Disease RES by 60%.",
                ability3: "Increase Lifesteal RES by 40%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat3amt: 1,
                    hover3: "Ifrit",
                    mat4: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat4amt: 2,
                    hover4: "Oldeus",
                    mat5: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat5amt: 1,
                    hover5: "Radachne"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Selfish Aria",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_109421.png",
                skill: "50s CT - 6000% Fire DMG (MAG). For 12s, increase own DMG by 50% and reduce DMG of Enemies afflicted by status ailments by 30%.",
                skillbreak: 600,
                passive: 
                    {
                        ability1: "When equipped by Elizabeth, increase DMG by 30% and Arts by 2/s.",
                        ability2: "Increase MAG RES by 10%."
                    }
            },
        lore: 
            {   
                evo5: "A girl born on an island country in the northeast of Raktehelm. Forbidden experiments gave her the power over enchanted light at the cost of her memories. Over half of her body was turned into magical machinery in the process, so she is heaver than she looks. For a century after the dawn of La Riiz, she was hailed as the founder of the Riizism.",
                evoawk: "A cunning priest set her up as the founder of a religion until she escaped. She then travelled around the world to rescue her lost relatives, eventually joining the lithomancer Yulong's crew. Long isolation gave her a stunted vocabulary, and she often punctuates her sentences with \"rawr\"."
            }
    },
    { //Vultee - Complete, Review done + JP ver
        id: 103406212,
        name: "Vultee",
        rank: "B",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103405212.png",
                detailawk: "/db/Units/Detail/unit_detail_103406212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103405212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103406212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7197,
                hpplus: 999,
                atk: 3019,
                atkplus: 462,
                def: 2431,
                defplus: 374
            },
        skillset: 
            {
                skill: "15s CT - 1500% Water DMG (MAG).  Increase own Arts by 10. For 15s, reduce Enemies' MAG RES by 10% (stackable).",
                skillbreak: 600,
                arts: "18000% Water DMG (MAG). For 20s, increase own Skill CT REC by 120% and Equip CT REC by 30%.",
                artsbreak: 1200,
                truearts: "60000% Water DMG (MAG). For 20s, Allies ignore Enemies' DEF by 100%, and increase Allies' Arts DMG by [30% × # of Water Allies in party].",
                trueartsbreak: 2000
            },
        skillsetJP: 
            {
                skill: "15s CT - 1500% Water DMG (MAG).  Increase own Arts by 10. For 15s, reduce Enemies' MAG RES by 10% (stackable).",
                skillbreak: 600,
                arts: "25000% Water DMG (MAG). For 20s, increase own Skill CT REC by 120% and Equip CT REC by 30%.",
                artsbreak: 1200,
                truearts: "77000% Water DMG (MAG). For 20s, Allies ignore Enemies' DEF by 100%, and increase Allies' Arts DMG by [50% × # of Water Allies in party].",
                trueartsbreak: 2000
            },
        passive: 
            {
                ability1: "When own HP is above 80%, increase ATK by 80%.",
                ability2: "Increase Hellfire RES by 40%.",
                ability3: "When Vultee joins the battle from the 2nd party in Cross Battle, he gains the Change Buff for Same Race regardless of the unit he replaces."
            }, 
        passiveJP: 
            {
                ability1: "When own HP is above 80%, increase ATK by 120%.",
                ability2: "Increase Hellfire RES by 40%.",
                ability3: "When Vultee joins the battle from the 2nd party in Cross Battle, he gains the Change Buff for Same Race regardless of the unit he replaces."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat3amt: 1,
                    hover3: "Palamicia",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 2,
                    hover4: "Code-XTF",
                    mat5: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat5amt: 2,
                    hover5: "Freydal"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Inspector",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_109451.png",
                skill: "45s CT - 8000% Water DMG (MAG). For 10s, reduce Enemies' Water RES by 30%. If user is Water, for 10s, increase own DMG by 50%.",
                skillbreak: 800,
                passive: 
                    {
                        ability1: "When equipped by Vultee, increase own Arts by 3/s.",
                        ability2: "Increase MAG DMG by 10%."
                    }
            },
            dreamJP: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                        mat3amt: 2,
                        hover3: "Ifrit",
                    },
                    passive: {
                        ability1: `Increase all allies' Fire RES by 20%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own DMG against Fire enemies by 50%.`
                    }
                },
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                        mat3amt: 2,
                        hover3: "Freydal",
                    },
                    passive: {
                        ability1: `Increase Human allies' Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own Equip CT Speed by 10%.`
                    }
                }   
            },
        lore: 
            {   
                evo5: "A gunner born in Simon's Hill and childhood friend of Hart and Priscilla. The hand-eye coordination and reflexes which let him catch beetles barehanded transferred well to marksmanship. Although he mostly lazed in school, joining the Rangers motivated him to self learn self-discipline, and he quickly rose to vice captain through hard work and dependability.",
                evoawk: "A shooting specialist who bears his hometown's hopes and dreams. The other imperial knights also believe he could be the second coming of Simon, one of Isliid's greatest modern heroes. He has matured greatly since arriving in the capital, replacing most of his flippancy for diligence. At his core is now unshakable resolve... with a tinge of unease."
            },
        review:
            {
                skill: `A pretty decent skill. The 10 Arts is nice as well as the stackable Magic Res debuff that he applies to enemies. The downside is that the skill is extremely slow so chances are that only one debuff would be active at a time. Maybe two or three with enough Skill CT buffs.`,
                arts: `The buffs on this arts work pretty well with his skill since it would allow him to apply at least two Magic Res debuffs at a time. The 30% Equip CT is a very nice added bonus. Overall, it's a good arts but a bit selfish since none of the buffs are teamwide`,
                truearts: `Now this is where most of Vultee's damage will come from. A nice teamwide Ignore Def buff + Arts DMG buff based on the number of water allies, making him a good option for water nuking in general, or for that one stage in Water Ruins that needs Ignore Def.`,
                ability1: `A pretty strong buff! Keeping his health above 80% is a pretty easy thing to do since there's plenty of ways to go about it such as using a competent healer, a taunt tank, or just having enough damage mitigation.`,
                ability2: `An extremely niche passive that will probably be forgotten about since 90% of the content doesn't involve Hellfire anyway`,
                ability3: `Another pretty strange passive that probably won't affect anything most of the time.  It does allow him to start with additional Arts depending on the unit he replaces, but as mentioned previously, this will not see use outside of a select few quests (Cross Battles) and Raids.`,
                trueweapon: {
                    skill: `Pretty decent damage for a pretty decent CT.  The 30% Water Res reduction and 50% DMG increase are nice little additions that make this a decent equip for water nuking, although Melty Shoes would probably do the job better`,
                    ability1: `A nice passive arts gen that makes this one of the best options for one of Vultee’s magic equips`,
                    ability2: `Very negligible.  A 10% increase shouldn’t make too much of a difference`
                },
                overall: `At first glance, Vultee seems like a pretty good water dps.  While there are other units like Summer Juno, Joker Selia, Santa Rimuru, and Emilia (and some others that I’m forgetting), he could work in place of them if you lack any of them.  Overall, he’s a decent dps that allows the team to do some extra damage, especially since his buffs affect “all” teams in general and are not limited to monowater or magic based teams.`,
                lastupdated: `September 6, 2022`,
                author: `Tinydomtako`
            }
    },
    { //Priscilla (Water) - Complete, Review done
        id: 103416212,
        name: "Priscilla (Water)",
        rank: "A",
        twrequire: "favorable",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103415212.png",
                detailawk: "/db/Units/Detail/unit_detail_103416212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103415212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103416212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6415,
                hpplus: 999,
                atk: 2909,
                atkplus: 446,
                def: 2848,
                defplus: 437
            },
        skillset: 
            {
                skill: "10s CT - 1000% Water DMG (PHY). For 8s, increase own ATK by 20%.",
                skillbreak: 600,
                arts: "15000% Water DMG (PHY). For 15s, reduce Enemies' evasion and Accuracy by 30%.",
                artsbreak: 900,
                truearts: "42000% Water DMG (PHY). For 15s, increase Allies' Arts by 5/s and PHY DMG by 50%.",
                trueartsbreak: 1200,
                phantombullet: "75000% Water DMG (PHY). During Phantom Bullet, Increase Own Critical Rate by 100%. For 90s, reduce Enemies' Water RES by 60%. Phantom Bullet can only be activated one time in a Quest.",
                phantombulletbreak: 1700
            },
        passive: 
            {
                ability1: "Increase PHY Evasion by 5%.",
                ability2: "Increase DMG against Enemies in BREAK by 20%.",
                ability3: "When own HP is above 80%, increase own ATK and accuracy by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 1,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat4amt: 1,
                    hover4: "Palamicia",
                    mat5: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat5amt: 1,
                    hover5: "Freydal"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Astral Bolt",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_109631.png",
                skill: "60s CT - 10000% Water DMG (PHY). For 12s, reduce Enemies' PHY RES by 20%. If User is Water, reduce Enemies' PHY RES by 35% instead.",
                skillbreak: 700,
                passive: 
                    {
                        ability1: "When equipped by Water Priscilla, increase own Arts by 3/s. Does not stack with Abilities of the same name.",
                        ability2: "Increase ATK by 5%."
                    }
            },
        lore: 
            {   
                evo5: "In the course of her struggles after leaving Simon's Hill to find and help Hart, she met with the misfortune of losing her eyesight. However, another strange turn of events led to her coming in contact with the Seed. Its magic began to mend her damaged optic nerves, but not before the true wound festering deep in her heart swallowed her whole.",
                evoawk: "Her ideal form as a master archer. Having honed the power of vision granted by the Seed, she strikes her target without mistake or hesitance. However, no trace remains of her original innocence, warmth, and vivacity. She has become a killing machine, deaf to all... except him."
            },
        review:
            {
                skill: `A simple but effective skill, too bad it isn't ArtGen.`,
                arts: `Very niche, you won't use this in 9/10 situations.`,
                truearts: `Solid 5 arts/s, but being in Type B means that she will be overriden by Juno and Hart. Other than that, the PHY DMG buff is appreciated as a nice bonus regardless of the ArtGen.`,
                phantombullet: `Dropping a massive 60% Water RES Down for a very long duration, this PB can be used for sustain situations alongside other water DPS units or in a nuking scenario. The only downside is unlike the other two PB units, she only has one bullet... and it's an arrow.`,
                ability1: `It could help with survivability, but I wouldn't bet on it.`,
                ability2: `She doesn't really do anything break related, so you won't see this in effect often, if at all.`,
                ability3: `Ignoring the fact that she has accuracy up as a blind person, it's a well appreciated boost to her offensive power when she's healthy.`,
                trueweapon: {
                    skill: `All things considered, it's not a bad effect. Unfortunately, it's outclassed by Angelas' True Weapon with an easier condition and later Emperor's physical True Weapon.`,
                    ability1: `The main reason you would want her True Weapon, helping with her lack of innate self sustainability.`,
                    ability2: `Small, but something is better than nothing.`
                },
                overall: `Water Priscilla is an interesting unit. No, I don't mean the accuracy related buffs in her kit despite being blind. Technically speaking, she's a very solid unit with good slotting, high arts/s and offensive support for physical and/or water. Unfortunately, she belongs in Type B, which means that she is overriden by the more dominant Hart and Juno. She's not exactly a "meta' option, but she's far from a bad unit.`,
                lastupdated: `October 14, 2022`,
                author: `Senshi`
            }
    },
    { //Emperor Isliid - Complete, Review done
        id: 103426412,
        name: "Emperor Isliid",
        attribute: "Light",
        rank: "SS",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103425412.png",
                detailawk: "/db/Units/Detail/unit_detail_103426412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103425412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103426412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 5,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6745,
                hpplus: 999,
                atk: 3143,
                atkplus: 481,
                def: 2431,
                defplus: 374
            },
        skillset: 
            {
                skill: "8s CT - 2000% Light DMG (PHY). Increase own Arts by 30. If self is unafflicted by Status Ailments, increase Arts by 50 instead.",
                skillbreak: 600,
                arts: "18000% Light DMG (PHY). For 10s, increase Allies' stats by 30% and reduce Enemies' PHY RES by 20%.",
                artsbreak: 1100,
                truearts: "67000% Light DMG (PHY). Sacrifice 10% of own MAX HP. For 15s, increase Allies' Arts by 6/s. An additional effect occurs depending on the Sword Mode Emperor Isliid is in when True Arts is activated.",
                trueartsbreak: 1700
            },
        passive: 
            {
                ability1: "When in Sky Light Sword Mode,  activations of own True Arts increase Allies' accuracy by 30% for 15s, and DMG of own True Arts against Gods is increased by 150%. If Emperor Isliid is in the 1st position, he will only use this mode.",
                ability2: "When in Terra Master Sword Mode, activations of own True Arts increase Allies' DMG RES by 20% for 15s, and DMG of own True Arts against Humans is increased by 150%. If the Emperor Isliid is in the 2nd position, he will only use this mode.",
                ability3: "When in Dark Bringer Sword Mode, activations of own True Arts increase Allies' ATK by 50% for 15s, and DMG of own True Arts against Demons is increased by 150%. If the Emperor Isliid is in the 3rd position, he will only use this mode.",
                ability4: `NOTE: If Emperor Isliid is in the 4th Party Slot, he will change modes every True Arts (Skylight -> Terra Master -> Darkbringer -> Repeat)`
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 2,
                    hover3: "Flogassa",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 2,
                    hover4: "Radachne",
                    mat5: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat5amt: 1,
                    hover5: "Ragsherum Phantom"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                true1: 
                {
                    name: "Sky Light",
                    slot: "/db/Mines/thumbnail/magLB.png",
                    thumb: "/db/Equipment/Thumbnail/item_thumbnail_109771.png",
                    detail: "/db/Equipment/Detail/item_detail_109771.png",
                    skill: "45s CT - 10000% Light DMG (MAG). For 10s, reduce Enemies' Light RES by 30%. If user is Light, increase own DMG by 50%.",
                    skillbreak: 700,
                    passive: 
                        {
                            ability1: "When equipped by Emporer of Isliid, Increase accuracy by 30%. Does not stack with Abilities of the same name.",
                            ability2: "Increase MAG evasion by 10%."
                        }
                },
                true2: 
                {
                    name: "Terra Master",
                    slot: "/db/Mines/thumbnail/suppLB.png",
                    thumb: "/db/Equipment/Thumbnail/item_thumbnail_109781.png",
                    detail: "/db/Equipment/Detail/item_detail_109781.png",
                    skill: "35s CT - 5000% Light DMG (PHY). For 10s, increase Allies' Arts by 3/s, and increase Human Allies' DMG by 35%.",
                    skillbreak: 500,
                    passive: 
                        {
                            ability1: "When equipped by Emperor Isliid, Increase stats by 30%. Does not stack with Abilities of the same name.",
                            ability2: "Increase DMG by 20% to Enemies in BREAK."
                        }
                },
                true3: 
                {
                    name: "Dark Bringer",
                    slot: "/db/Mines/thumbnail/physLB.png",
                    thumb: "/db/Equipment/Thumbnail/item_thumbnail_109791.png",
                    detail: "/db/Equipment/Detail/item_detail_109791.png",
                    skill: "55s CT - 13000% Light DMG (PHY). For 10s, reduce Enemies' PHY RES by 40%. If user is Light, increase own ATK by 40%.",
                    skillbreak: 1000,
                    passive: 
                        {
                            ability1: "When equipped by Emperor Isliid, increase DMG by 30%. Does not stack with Abilities of the same name.",
                            ability2: "Increase PHY evasion by 10%."
                        }
                }
            },
        lore: 
            {   
                evo5: "The young ruler of the Isliid Empire, enthroned in secret with the passing of his predecessor. Though he was a figure of exceptional valor, imperial men have historically short lifespans. As a result, no one doubts the legitimacy of his ascension, but very few are privy to the finer details, and much about him is still shrouded in mystery.",
                evoawk: "Like all who ruled Isliid, he is a direct descendant of the original Human born from Yggdrasil. They are considered the ultimate prototypes, blessed with the ability to realize mankind's limitless possibilities. Across each generation, the emperors worked to free the sealed Races, and some even exercised authority over all Raktehelm. However, he is different, for he looks past such purposes."
            },
        review:
            {
                skill: `Amazing Self-Sufficiency, combined with the Arts Gen from True Arts you'll have 190 Arts every 15 seconds.`,
                arts: `A small glimpse into the versatility of Emperor, providing pseudo-heals and Resistance Down debuff with just Arts.`,
                truearts: `All hail the Emperor Isliid! In one True Arts he provides; DPS and Artsgen with a bonus Accuracy, DMG Res, or ATK Buff. It's hard to deny the fact that this unit does it all, what's better is that this is very spammable thanks to his Skill as well.`,
                ability1: `This is the most niche Sword that Emperor possesses, with Accuracy being rarely needed and God enemies being few and far between.`,
                ability2: `This should be your standard Sword for most Endgame content due to the near-permanent DMG Resistance buff. Humans are also a very common enemy race in harder content, notable stages would be: Vitz, Chloe, Ganan, Gilliam, Emi, Groytz.`,
                ability3: `Putting aside the Demon Killer, this Sword will be used for buffing the teams damage for more relaxed stages that don't demand copious amounts of DMG Res or stages you can speed through. Demons are the most common enemy type alongside the multiple Valzandeth/Dragon SPEX events.`,
                ability4: `You won't be using this one that often unless you’re using him as Mines Support or someone else is joining Multi and can't swap to an ideal position.`,
                trueweapon: {
                    true1: {
                        skill: `Average Light Res Down Debuff with a decent DMG Up buff, You probably wouldnt use this over True "Cantabille" in a Mono Light since Emperor already has plenty DMG Up from Sword Positions.`,
                        ability1: `Generally the weakest passive out of his TW's, the accuracy boost will only be needed against a few bosses.`,
                        ability2: `Negligible Passive`
                    },
                    true2: {
                        skill: `Strong Artsgen equip, with slightly under 1 Arts/s factoring CT but a strong 35% DMG Up buff to all Humans.`,
                        ability1: `While not the best passive, it's not the worst. Increased stats is a nice bonus for Emperor.`,
                        ability2: `BREAK is a rare occurance nowadays, but the DMG Buff is appreciated.`
                    },
                    true3: {
                        skill: `Ah yes, the best PHY Res down equipment for Sustain. Boasting the 2nd highest PHY Res down debuff available in global, Dark Bringer will be a staple in all PHYS teams especially nuking.`,
                        ability1: `DMG Up is always appreciated, especially when paired with the amazing active.`,
                        ability2: `Negligible Passive`
                    }
                },
                overall: `Well...he's here. While being compared to his biggest counterpart, Juno, he's much faster, with decent equipment you can get his TA off in 9 seconds without needing to worry about BE. His TA's healthdrain is instant and a bit weaker, However, due to how spammable his TA is you'll still be draining health at a rather concerning rate. Good thing his Normal art can be cycled in as needed to provide some healing or just put a decent healer on the team.

                So, why is Emperor not actually the must Pity unit? Well, it comes down to the fact that we can still clear content without him. When the main appeal of these Type B Artsgens is their high APS amount with a strong secondary effect (Hart = Healing + Stats, Juno = DPS + Accuracy, Emperor = All supportive buffs except crit), you’ll find that Emperor only serves as an optimization of your Type B Artsgen Slot, providing more damage when paired with the Physical Teams and versatility through Sword Buffs, Pseudo-Healing, and all 5 Star Slotting.
                
                In other words: He’s the best but you still need units to build around him to clear endgame difficulty quests.`,
                lastupdated: `November 17, 2022`,
                author: `Gian`
            }
    },
    { //Beguiler Selia - Complete
        id: 103436212,
        name: "Beguiler Selia",
        rank: "A-",
        twrequire: "favorable",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103435212.png",
                detailawk: "/db/Units/Detail/unit_detail_103436212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103435212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103436212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6801,
                hpplus: 999,
                atk: 3015,
                atkplus: 462,
                def: 2750,
                defplus: 422
            },
        skillset: 
            {
                skill: "10s CT - 1500% Water DMG (MAG). 30% chance to inflict Freeze for 2s.",
                skillbreak: 700,
                arts: "13000% Water DMG (MAG). 80% chance to inflict Freeze for 6s. Increase Allies' Arts by 20 + Cleanse Allies' and Enemies' Burn.",
                artsbreak: 1300,
                truearts: "55000% Water DMG (MAG). For 20s, reduce Enemies' Critical RES by 100%, Freeze RES and Water RES by 50%.",
                trueartsbreak: 1700
            },
        passive: 
            {
                ability1: "When an activation of Skill or Arts inflicts Freeze, increase own DMG by 30% (up to 300%).",
                ability2: "Increase Critical DMG by 60%.",
                ability3: "Increase Freeze RES by 100%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                    mat3amt: 1,
                    hover3: "Geo-Zagnus",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 2,
                    hover4: "Radachne",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Assault Architect",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_109911.png",
                skill: "45s CT - 7000% Water DMG (MAG). For 10s, Increase own MAG DMG and Water DMG by 40%.",
                skillbreak: 800,
                passive: 
                    {
                        ability1: "When equipped by Beguiler Selia, increase own Arts by 2/s.",
                        ability2: "Increase Fire RES by 10%."
                    }
            },
        lore: 
            {   
                evo5: "A conjurer from Isliid who was active all over Raktehelm just before the Great War, according to records from the time. She garnered much fame for her ability to seemingly bring ice sculptures to life. When winter came, she would use her magic along with the rapid change in atmospheric temperatures to make night stars glimmer in a fantastic display, earning her the nickname 'Cosmic Winter Witch'.",
                evoawk: "A mysterious conjurer who appeared all over Raktehelm with winter's approach. Regardless of her mass popularity, no one ever learned her true origins since she answered every question with a lie. However, her revision of the Grimm Stella works which position her as the central focus contains some measure of truth. She also wrote many poetry collections about winter and practiced lithomancy."
            }
    },
    { //Rosetta (Earth) - Complete, Review Done + JP ver
        id: 103446312,
        name: "Rosetta (Earth)",
        rank: "B+",
        attribute: "Earth",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103445312.png",
                detailawk: "/db/Units/Detail/unit_detail_103446312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103445312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103446312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6918,
                hpplus: 999,
                atk: 3052,
                atkplus: 467,
                def: 2598,
                defplus: 399
            },
        skillset: 
            {
                skill: "10s CT - 1800% Earth DMG (MAG). Increase own Arts by 20.",
                skillbreak: 500,
                arts: "13000% Earth DMG (MAG). For 15s, reduce Enemies' Earth RES by 30%. During Magia Drive: 13000% Earth DMG (MAG). If this Arts has been activated twice during the duration of the current Magia Drive, activate Magia Drive: Unlimited for the duration of the current Magia Drive. For 15s, reduce Enemies' Earth RES by 30%. During Magia Drive: Unlimited: 26000% Earth DMG (MAG). For 15s, reduce Enemies' Earth RES by 50%.",
                artsbreak: 1000,
                truearts: "For 180s, enter Magia Drive. During Magia Drive: 40000% Earth DMG (MAG). For 20s, increase Allies' Equip CT REC and MAG DMG by 50%. During Magia Drive: Unlimited: 80000% Earth DMG (MAG). For 20s, increase Allies' Equip CT REC and MAG DMG by 50%.",
                trueartsbreak: 1400
            },
        skillsetJP: 
            {
                skill: "10s CT - 1800% Earth DMG (MAG). Increase own Arts by 20.",
                skillbreak: 500,
                arts: "13000% Earth DMG (MAG). For 15s, reduce Enemies' Earth RES by 30%. During Magia Drive: 13000% Earth DMG (MAG). Enter Magia Drive: Unlimited for remaining duration. For 15s, reduce Enemies' Earth RES by 30%. During Magia Drive: Unlimited: 26000% Earth DMG (MAG). For 15s, reduce Enemies' Earth RES by 70%.",
                artsbreak: 1000,
                truearts: "For 180s, enter Magia Drive. During Magia Drive: 60000% Earth DMG (MAG). For 20s, increase Allies' Equip CT Speed by 50% and MAG DMG by 100%. During Magia Drive: Unlimited: 120000% Earth DMG (MAG). For 20s, increase Allies' Equip CT Speed by 50% and MAG DMG by 100%.",
                trueartsbreak: 1400
            },
        passive: 
            {
                ability1: "During Magia Drive, increase ATK by 100%.",
                ability2: "During Magia Drive: Unlimited, double DMG Multipliers of Normal Arts and True Arts (reflected above), and increase DMG to Water Enemies by 50%.",
                ability3: "Increase Allies' accuracy by 30%. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat3amt: 1,
                    hover3: "Goldola",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 2,
                    hover4: "Code-XTF",
                    mat5: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat5amt: 1,
                    hover5: "Flogassa"
                }
            },
            dreamJP: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                        mat3amt: 2,
                        hover3: "Flogassa",
                    },
                    passive: {
                        ability1: `Increase all allies' Fire RES by 20%. Does not stack with Abilities of the same name.`,
                        ability2: `When HP is above 80%, increase own Arts by 2/s.`
                    }
                },
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragGoldola.png",
                        mat3amt: 2,
                        hover3: "Goldola",
                    },
                    passive: {
                        ability1: `Increase own Arts by 3/s.`,
                        ability2: `Increase own DMG by [1% × own ATK/100].`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Asteroid",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_109971.png",
                skill: "45s CT - 7500% Earth DMG (MAG). For 10s, increase own Earth DMG by 50%, reduce Enemies' MAG RES by 30%.",
                skillbreak: 500,
                passive: 
                    {
                        ability1: "When equipped by Rosetta (Earth), increase DMG by 40%. When entering Magia Drive, Increase own Arts by 50. Does not stack with Abilities of the same name.",
                        ability2: "When near-death, increase Skill CT REC by 20%."
                    }
            },
        lore: 
            {   
                evo5: "A magician and recorded member of Granadia's Templars in the era of the Ancient War. She returned to present Raktehelm after Emperor Isliid broke the Great Seal. Although bewildered by how much the world had changed in her absence, she was no less determined to reunite with Princess Leone. Intuition also told her to seek out the World Drops for she would meet her scattered comrades along the journey.",
                evoawk: "As a result of the irregular flow of time behind the Great Seal, only a few decades have passed since Princess Leone's departure from her perspective. She has lived for the sake of reviving her dear friend and sees the return to Raktehelm as another step towards the goal. However, what she truly desires could be just a return to those complex yet enjoyable days under her leadership."
            },
        review:
            {
                skill: `A decent chunk of self-sufficienty is always welcome.`,
                arts: `The TL;DR is use her Normal Aarts twice during Magia Drive to go into Magia Unlimited. Good for longer fights and during Magia Unlimited you'll want to throw in a Normal Arts to really pump up her damage.`,
                truearts: `During Magia Drive, Rosetta gives a rare and coveted Equip CT REC buff at a whopping 50% and a sizeable MAG DMG buff. If she's in Magia Unlimited, she'll do more damage on top of that.`,
                ability1: `Another hefty buff to Rosetta's damage just by simply being in Magia Drive.`,
                ability2: `A bit of work needed to get into Magia Unlimited to access this passive but doubling her damage means she'll be doing quite a bit offensively. The increase damage to water enemies isn't reliable but when it is relevant she'll be even more effective at her damage dealing role.`,
                ability3: `Another unit to help get around pesky evasion fights but won't stack with the likes of Vox.`,
                trueweapon: {
                    skill: `A pretty hefty active on a moderate CT for Earth/Magic sustain strategies and even great for Earth/Magic nuking strategies, though the latter isn't widely used. Rosetta herself will enjoy this, though not necessarily just for the active.`,
                    ability1: `An unconditional passive 40% damage buff to Rosetta is pretty good and increasing her arts when she enters Magia Drive is decent. A pretty good True Weapon on Rosetta with all things considered.`,
                    ability2: `And of course to top it all off: the obligatory useless generic passive.`
                },
                overall: `Foliage Carver Rosetta looks amazing on paper - great buffs and debuffs while doing a ton of damage but she suffers from a few issues. She needs a lot of outside support to ramp her up to her full damage potential. Not to mention, getting to Magia Unlimited will take a bit of time due to animations and needing lots of arts.

                The other problem is that magic support units aren't as coveted as other types of supports as Grand Summoner's meta still favors physical damage. Even when magic comps are good, you'd likely prefer other units in her stead.
                
                That isn't to say she's bad! She's one of the few units who can give a sizeable Equip CT buff, so if you're missing Liza, Rosetta might allow you to clear content that you hadn't before. Even without doing her complicated Magia Unlimited, she is still a very competent support unit with just True Arts spam.`,
                lastupdated: `February 16, 2023`,
                author: `JC`
            }
    },
    { // Orvell (Fire) - Complete, Review done
        id: 103456112,
        name: "Orvell (Fire)",
        attribute: "Fire",
        rank: "S",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103455112.png",
                detailawk: "/db/Units/Detail/unit_detail_103456112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103455112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103456112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7249,
                hpplus: 999,
                atk: 3051,
                atkplus: 467,
                def: 2370,
                defplus: 365
            },
        skillset: 
            {
                skill: "10s CT - 2000% Fire DMG (MAG). For 7s, reduce Enemies' DMG RES by 10%.",
                skillbreak: 800,
                arts: "18000% Fire DMG (MAG). For 20s, increase Allies' Equipment DMG by 50%, and Fire Allies' Equipment DMG by an additional 50%.",
                artsbreak: 1200,
                truearts: `62000% Fire DMG (MAG). For 20s, replace the equipment in his first slot with Super Equip "Gramlude". Additionally, increase Allies' MAG DMG by 100%, and own ATK by 100%.`,
                trueartsbreak: 1800,
                superequip: "2s CT - 5000% Fire DMG (MAG). For 15s, increase own Critical DMG by 30% (stackable).",
                superequipbreak: 500,
                superequiptype: "/db/Mines/thumbnail/magLB.png",
                superequipname: "Gramlude"
            },
        passive: 
            {
                ability1: "Increase Critical RES by 30%.",
                ability2: "Increase DMG against Enemies under 30% HP by 20%.",
                ability3: "When own HP is above 80%, increase own Critical Rate by 100% and Arts by 3/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat3amt: 1,
                    hover3: "Ifrit",
                    mat4: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat4amt: 3,
                    hover4: "Flogassa"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Gramlude",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_110091.png",
                skill: "65s CT - 12000% Fire DMG (MAG). For 15s, increase own MAG DMG by 50% and reduce Enemies' Fire RES by 30%.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Orvell (Fire), increase own DMG and All Status by 20%. Does not stack with Abilities of the same name.",
                        ability2: "Increase Physical RES by 10%."
                    }
            },
        lore: 
            {   
                evo5: "Orvell, once the hopeful young prince of Gran Verge, returned to Raktehelm as a hardened warrior bent on revenge. At the end of the Ancient War, his homeland fell at the hands of the enigmatic Usurper. Having lost both his glorious nation and precious sister, he grasped a Demon-forged weapon to carry out his dark intent.",
                evoawk: "Whenever Orvell closed his eyes, memories of that day remerged in vivid red: Gramlude, his castle, his sister -- all wrapped in flame. He journeyed without rest to exact revenge on the Usurper, his heart still aflame, feeding his fury, burning ever hotter."
            },
        review:
            {
                skill: `A basic skill that can sometimes slightly increase the team’s damage output. Note though that the DMG RES debuff does not stack with any other such general debuffs.`,
                arts: `Fits in the context of Fire Orvell’s kit that revolves around spamming an equip. However, this has marginal utility because how more beneficial spamming his True Arts is in comparison.`,
                truearts: `There we are, the bread and butter of Fire Orvell’s kit. High multiplier, teamwide magic damage buff and self ATK buff, and unlocks Super Equip “Gramlude” that can be spammed for even more damage buffs. This basically enables Fire Orvell to reach overall damage buffs that easily exceed 400% (provided he can deal critical damage), thereby making him one of the top DPSes in the game. Besides raw damage, spamming a damage-dealing equip at high velocity also generates a substantial amount of battle ether (which helps with team arts) and deals a non-negligible amount of break.`,
                superequip: `30% stackable critical DMG buff that can be stacked every 2s. With a 15s duration, up to 7 buffs (240% total) can be stacked and cycled, and EQ CT buffs can further increase this number by reducing the equip cooldown. The passives of the 5-star magic equip are retained during this time, meaning that it is possible to use an equip with passives that will strategically provide added utility (such as damage buffs, EQCT buffs, etc.). As stated before, spamming this equip also generates lots of battle ether, and the break resulting can also add up rather quickly.`,
                ability1: `Can help slightly mitigate the damage Fire Orvell receives, especially against bosses that have increased critical rate against a target unit (whom is often the unit who deals the most damage).`,
                ability2: `Marginal passive that can nonetheless help finish off bosses faster.`,
                ability3: `Conditional artgen passive to help make him more self-sustainable, and a conditional critical rate that is central to his damage output, as his Super Equip gives him critical DMG buffs.`,
                trueweapon: {
                    skill: `A magic DMG buff and fire RES debuff that can be used right before TA to increase damage output. However, it must be noted that the duration of these buffs is 15s, which means that when Fire Orvell reaches max Super Equip stacks, these buffs will likely have timed out.`,
                    ability1: `Damage and stats buffs that remain when the Ultra Equip is active. However, these are marginal given the high damage buffs that Fire Orvell can typically achieve.`,
                    ability2: `A small physical RES buff that can help Fire Orvell stay above 80% hp.`
                },
                overall: `Fire Orvell has obscene DPS potential as long as the boss does not have high critical resistance, stacking critical DMG buffs. Beyond this though, his Ultra Equip also helps with team artgen through battle ether generation, and also benefits from relatively good break which can be useful in some quests. His 5* defense slot helps with team survivability in difficult content. Obviously, DPS units always tend to get superseded at some point, but right now, Fire Orvell is among the top DPS in the game, and his added utility helps him edge out his competitors.`,
                lastupdated: `March 14, 2023`,
                author: `MOBofMetal`
            }
    },
    { // Cestina (Light) - Complete (+ JP version), Review done
        id: 103466412,
        name: "Cestina (Light)",
        rank: "S",
        attribute: "Light",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103465412.png",
                detailawk: "/db/Units/Detail/unit_detail_103466412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103465412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103466412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6847,
                hpplus: 999,
                atk: 2989,
                atkplus: 458,
                def: 2489,
                defplus: 383
            },
        skillset: 
            {
                skill: "9s CT - 1400% Light DMG (PHY). For 5s, increase own ATK by 15%.",
                skillbreak: 600,
                arts: "15000% Light DMG (PHY). For 15s, increase Allies' PHY DMG by 50% and God Allies' Accuracy by 30%.",
                artsbreak: 800,
                truearts: `45000% Light DMG (PHY). For 15s, replace the equipment in her first slot with Ultra Equip "Eisenstern". Additionally, for 15s, reduce Enemies' Light RES and Physical RES by 35%.`,
                trueartsbreak: 1500,
                superequip: "2s CT - 3000% Light DMG (PHY). Heal Allies' HP by 1000. For 12s, reduce Enemies' PHY RES by 5% (stackable). ",
                superequipbreak: 300,
                superequiptype: "/db/Mines/thumbnail/physLB.png",
                superequipname: "Eisenstern"
            },
        skillsetJP: 
            {
                skill: "9s CT - 1400% Light DMG (PHY). For 5s, increase own ATK by 15%.",
                skillbreak: 600,
                arts: "15000% Light DMG (PHY). For 15s, increase Allies' PHY DMG by 50% and God Allies' Accuracy by 30%.",
                artsbreak: 800,
                truearts: `45000% Light DMG (PHY). For 15s, replace the equipment in her first slot with Ultra Equip "Eisenstern". Additionally, for 15s, reduce Enemies' Light RES and Physical RES by 35%.`,
                trueartsbreak: 1500,
                superequip: "2s CT - 3000% Light DMG (PHY). Heal Allies' HP by 1000. For 15s, reduce Enemies' PHY RES by 5% (stackable). ",
                superequipbreak: 300,
                superequiptype: "/db/Mines/thumbnail/physLB.png",
                superequipname: "Eisenstern"
            },
        passive: 
            {
                ability1: "Increase DMG to Demon Enemies by 50%.",
                ability2: "Increase Critical RES by 30%.",
                ability3: "When unafflicted by status ailments, increase own accuracy by 20% and Arts by 2/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 2,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 2,
                    hover4: "Radachne"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Eisenstern",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_110121.png",
                skill: "60s CT - 11000% Light DMG (PHY). For 20s, increase own DMG and Equipment DMG by 40%.",
                skillbreak: 900,
                passive: 
                    {
                        ability1: "When equipped by Cestina (Light), increase own ATK by 50% and Arts by 1/s. Does not stack with Abilities of the same name.",
                        ability2: "Increase own Healing Output by 20% (except Lifesteal)."
                    }
            },
        lore: 
            {   
                evo5: "Cestina, returning to Raktehelm after the Great Seal's removal, became the leader of the bygone nation of Gran Verge. With Liza's death, she believes the remaining three siblings should bond together first and foremost for the purpose of restoring their homeland. This means her present focus is chasing Orvell to awaken him from his obsession for vengeance, holding the Lance of Anointing Stardust tight.",
                evoawk: "When Gran Verge was destroyed by the Usurper, Cestina was off fighting in another nation. The news she had lost both her homeland and dear sister came as a massive shock. She returned to the ruined capital and walked through the ruins alone. Reflecting on her days with Liza renewed her determination. Taking up the holy lance Eisenstern, she set forth to build anew the kingdom they loved."
            },
        review:
            {
                skill: `A minor ATK buff, but it is better than nothing.`,
                arts: `A huge damage buff, increasing allies Physical DMG by 50% with a good uptime, which pairs well with Stellar Wing Cestina’s TA . Furthermore it has the accuracy niche, which is tied to God Allies only, so it shouldn’t be relied on generally.`,
                truearts: `By using Stellar Wing Cestina’s TA, the equipment in the first slot will be replaced with her Super Equip (detailed below), for 15s, which pairs well with her TA. The cooldown of previous equipment will be kept on hold during the duration of the activation of the Super equipment. The passive of the previous equipment will not be replaced and is still active. In addition, she is able to provide a good Light and Physical Res down of a total of 70%, which pairs especially well for Light Nukes or any Light physical DPS.`,
                ability1: `A huge damage buff for Stellar Wing Cestina, although only if facing Demon enemies. Better than nothing`,
                ability2: `A good buff for Stellar Wing Cestina by providing 30% Critical RES, if you are not running a taunt tank or the boss can crit.`,
                ability3: `Free artgen which is always appreciated by not being afflicted by Status ailment. As a bonus, it provides a small portion of accuracy buff, which is useful for certain niche situations. It should not be hard to keep her unaffected by ailments, just run a cleanse equipment or unit.`,
                superequip: `A spammable Super Equipment that allows Stellar Wing Cestina to heal your units with an average of 500 HP/s and reduce Enemies PHY RES by 30%, which can be increased furthermore by buffing the Equipment CT Recovery from other sources such as Freed TW, Izanagi, and Liza’s True Arts.`,
                trueweapon: {
                    skill: `A high damage multiplier, which is great, but due the multi hits it’s not good for wave clearing. On top of that, it provides a good Damage and Equipment DMG buff for herself, assuming you’re using her TW before activating her TA. This will allow Stellar Wing Cestina to dish out some great damage.`,
                    ability1: `More damage buff for Stellar Wing Cestina by applying passively 50% ATK, and self sufficient artgen of 1aps, which pairs well with her Unit passive. Makes it a total of 3aps`,
                    ability2: `A great passive to help Stellar Wing Cestina survivability by providing 1200 heal with every use of the Super Equipment, which can be increased furthermore with Equipment CT Recovery buff.`
                },
                overall: `Stellar Wing Cestina is one of the best Healers which rivals Hart as a Top Healer and being able to match Hart healing by her own. On top of that, she is an amazing buff and debuff unit, which is able to deal fairly decent DPS by herself with all the debuffs she applies from using her True Art and Super Equipment together. In addition, with the low Equipment CT of the Super Equipment she's also able to help your team with getting Arts thanks to the generation of BE.`,
                lastupdated: `March 14, 2023`,
                author: `Alice`
            }
    },
    { // Charlotte - Complete, Review done
        id: 103476112,
        name: "Charlotte",
        attribute: "Fire",
        rank: "A+",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103475112.png",
                detailawk: "/db/Units/Detail/unit_detail_103476112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103475112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103476112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6820,
                hpplus: 999,
                atk: 2990,
                atkplus: 458,
                def: 2750,
                defplus: 422
            },
        skillset: 
            {
                skill: "12s CT - 1600% Fire DMG (PHY). For 8s, increase own accuracy by 20%.",
                skillbreak: 600,
                arts: "17000% Fire DMG (PHY). For 15s, increase Allies' max HP by 30% and Fire Allies' ATK by 50%.",
                artsbreak: 1800,
                truearts: "50000% Fire DMG (PHY). 80% chance of inflicting Faint for 3s. For 18s, increase Allies' Arts DMG by 100%. For Allies unafflicted by Status Ailments, increase Arts DMG by 150% instead.",
                trueartsbreak: 1800
            },
        passive: 
            {
                ability1: "Increase own Arts by [1 × # of Human Allies (including self)]/s.",
                ability2: "When own HP is above 90%, increase DEF by 20%.",
                ability3: "When own HP is above 90%, increase ATK by 20%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 1,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                    mat4amt: 2,
                    hover4: "Degilmagna"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Heroic Path",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_110021.png",
                skill: "50s CT - 7500% Fire DMG (PHY). For 10s, increase own DMG by 50% and reduce Enemies' Fire RES by 30%.",
                skillbreak: 1300,
                passive: 
                    {
                        ability1: "When equipped by Charlotte, increase Status RES (except Faint) by 30% and Arts by 2/s.",
                        ability2: "When own HP is above 90%, increase Skill CT REC by 10%."
                    }
            },
        lore: 
            {   
                evo5: "The young granddaughter of Zeorg, Hero of Famelle. She boasts of his incredible deeds to anyone who will listen. Her days are spent in knight training along with her best friend Valerie. She surpassed the order's standards for swordplay when she was only eleven years old. The aspiration to become a great warrior like her grandfather carries her through the toughest drills with an upbeat attitude.",
                evoawk: "Although sturdy enough in mind and body to undergo knight training, she threw a tantrum when Zeorg said he was joining the Demon Suppression Corps. So great was her dissent that she clung to his legs on the day he departed. This expression of both the fear he would not return and the anger she was not strong enough to replace the elderly soldier was, if nothing else, age-appropriate."
            },
        review:
            {
                skill: `Pretty underwhelming and will be largely irrelevant in most scenarios.`,
                arts: `A pseudo heal on a fire unit is always welcome all while providing a decent attack buff to fire units.`,
                truearts: `Well then! A fairly substantial buff for nuking comps and on a long duration for sustain comps. There's even a high chance of faint for when that's relevant. An overall great TA whose extra buff conditions can be easily met.`,
                ability1: `It'll range from minimal to great self-sustainability depending on the comp, but at minimum having just Charlotte means you'll get something out of it. Mostly irrelevant with nuking comps.`,
                ability2: `Attack buffs with conditions that aren't incredibly tough to meet are great, though it is pretty low and the defense buff is largely irrelevant.`,
                trueweapon: {
                    skill: `An actually decent active on a moderate CT for Fire sustain strategies and as high as you can get for unconditional Fire RES debuffs for nuking strategies. All with an added bonus of a good personal damage buff.`,
                    ability1: `The Status RES is whatever but the 2/s arts generation is great for sustain strategies, making it one of her best arts gen equips for that type of content. Not so great for nuking strategies for the passive but the on use buffs make up for that.`,
                    ability2: `This is such small conditional buff, it might as well say nothing.`
                },
                overall: `Wow! Charlotte is impressive and versatile. Being a Fire unit with a pseudo heal on her NA means that she'll shine in Fire Ruins, not having to sacrifice a team slot for Vermilia and she accesses her more potent pseudo heal faster than Sword Berwick.

                For those that missed out on Draken, here's another shot at a good damage support unit! She packs quite a punch and provides buffs without needing to be at full HP. Even if you have Draken, she'll slot into mono-Fire nuking strategies well. Though she has some random parts to her overall kit, that doesn't detract from her role as a toptier damage support unit or a healer.`,
                lastupdated: `September 15, 2022`,
                author: `JC`
            }
    },
    { // Mizuki the Twelfth - Complete, Review done
        id: 103496212,
        name: "Mizuki the Twelfth",
        attribute: "Water",
        rank: "S",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103495212.png",
                detailawk: "/db/Units/Detail/unit_detail_103496212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103495212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103496212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7200,
                hpplus: 999,
                atk: 2950,
                atkplus: 429,
                def: 2542,
                defplus: 393
            },
        skillset: 
            {
                skill: "12s CT - 2000% Water DMG (PHY). Increase own Arts by 20.",
                skillbreak: 300,
                arts: "18000% Water DMG (PHY). Cure Allies' Status Ailments (except Faint) + For 15s, increase Allies' Status RES (except Faint) by 100%. During True Third Eye, further increase Water Allies' DMG by 100%.",
                artsbreak: 1200,
                truearts: "For 120s, enter Third Eye. During Third Eye: 55000% Water DMG (PHY). For 15s, reduce Enemies' PHY RES by 60% and increase >50% HP Allies' Arts by 4/s.",
                trueartsbreak: 2000
            },
        passive: 
            {
                ability1: "Increase Hellfire RES by 60%.",
                ability2: "When entering Third Eye, if enemy is Demon or Dragon Boss, activate True Third Eye instead. During True Third Eye, increase DMG to Demon and Dragon Enemies by 200%.",
                ability3: "When entering Third Eye/True Third Eye, increase Allies' Arts by 100. During Third Eye/True Third Eye, increase own ATK and Skill CT REC by 100%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat3amt: 2,
                    hover3: "Freydal",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 1,
                    hover4: "Code-XTF",
                    mat5: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat5amt: 1,
                    hover5: "Palamicia"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Mukudori",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_110151.png",
                skill: "45s CT - 9000% Water DMG (PHY). For 10s, increase own DMG by 50% and reduce Enemies' Water RES by 30%.",
                skillbreak: 800,
                passive: 
                    {
                        ability1: "When equipped by Mizuki the Twelfth, at the start of a Quest, increase stats by 20% and Arts by 100. Does not stack with Abilities of the same name.",
                        ability2: "Increase PHY RES by 10%."
                        
                    }
            },
        lore: 
            {   
                evo5: "The twelfth master of Imari Village, embodying the first Mizuki's ninja arts in their latest revision. The shinobi have retained their traditions throughout the changing years, but he questions that attachment to the past. Though he reveals his misgivings by his deeds rather than his words, others are starting to take notice.",
                evoawk: `Each generation of Mizuki refined the ancestral techniques, making him the current pinnacle of all shinobi in history. He also developed an original fighting style that combines seven different schools of martial arts: "Seventh Heaven".`
            },
        review:
            {
                skill: `Artgen on skill, tremendously useful for self-sustainability.`,
                arts: `Teamwide cleanse and status resistance that can come in handy in several stages.`,
                truearts: `The main part of Mizuki the 12th’s kit, decent damage along with a PHY RES debuff and artgen that stacks with most units, enabling it to easily carry three artgens that can all accomplish other functions. It must be noted that the first True Arts will only enable Third Eye, though even this has strong teamwide benefits (see Passive #3).`,
                ability1: `Not significant for a vast majority of content, but allows Mizuki the 12th to trounce Hellfire stages.`,
                ability2: `Buffs damage and also enables a teamwide buff for water units on NA against two enemy races, one of which is rather common.`,
                ability3: `A common problem of units with Magia Drive, Third Eye, etc., is that they have to spend an initial 200 arts to unlock their full potential, which makes them slower as a result. However, Mizuki the 12th repays the whole team with this 200 arts investment by providing each team member with an instant 100 arts, which helps kickstart the team.`,
                trueweapon: {
                    skill: `Reminiscent of Emperor Isliid’s True "Sky Light", it combines a self damage buff with a Water RES debuff with a decently short CT.`,
                    ability1: `The starting 100 arts enables him to easily reach 200 arts for Third Eye, with some help from arts up crests and other artgen equips, and thereby is tremendously useful in nuking scenarios. The stats up is a nice added touch.`,
                    ability2: `A small Physical RES buff that can help Mizuki the 12th stay above 50% HP for his True Arts.`
                },
                overall: `There is not a single weak point in Mizuki the 12th’s kit. He brings a huge burst of teamwide arts on Third Eye, self-sufficiency with arts on skill, status res and cleanse on NA, and physical debuff with artgen of an uncommon type on TA with respectable damage. On top of all this, he can also stomp hellfire stages, as well as get buffs against Demon and Dragon bosses. A versatile unit that can be used in both nuking and sustain situations.`,
                lastupdated: `March 24, 2023`,
                author: `MOB of Metal`
            }
    },
    { // Ginzo: Battle Form - Missing Awk Lore
        id: 103506511,
        name: "Ginzo: Battle Form",
        attribute: "Dark",
        type: "Human",
        rank: "C",
        luckrank: `C`,
        twrequire: "favorable",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103505511.png",
                detailawk: "/db/Units/Detail/unit_detail_103506511.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103505511.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103506511.png"
            },
        slots:
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6900,
                hpplus: 999,
                atk: 2920,
                atkplus: 448,
                def: 2528,
                defplus: 389
            },
        skillset: 
            {
                skill: "13s CT - 2000% Dark DMG (PHY). Cure own Blind.",
                skillbreak: 900,
                arts: "15000% Dark DMG (PHY). 60% chance to inflict Paralysis for 4s. During True Third Eye: Further increase own Arts by 30. ",
                artsbreak: 1200,
                truearts: "For 120s, enter Third Eye. During Third Eye: 40000% Dark DMG (PHY). For 20s, increase Allies' Dark DMG by 100%.",
                trueartsbreak: 1900
            },
        passive: 
            {
                ability1: "When entering Third Eye, if Enemy is Human or Giant, enter True Third Eye instead. During True Third Eye, increase DMG against Human and Giant Enemies by 200%.",
                ability2: "When entering True Third Eye, for 40s, reduce Enemies' PHY RES by 50%. During True Third Eye, increase own Arts by 2/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_130150.png",
                    mat3amt: 20,
                    hover3: "Ginzo: Battle Form"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Hell's Throat",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_110161.png",
                skill: "50s CT - 6500% Dark DMG (PHY). If user is Dark, for 8s, increase own Critical Rate by 100%. If user is Human, for 8s, increase own Critical DMG by 50%.",
                skillbreak: 700,
                passive: 
                    {
                        ability1: "When equipped by Ginzo: Battle Form, increase own stats by 30% and Arts gauge by 1/s. Does not stack with Abilities of the same name.",
                        ability2: "Increase ATK by 5%."
                    }
            },
        lore: 
            {   
                evo5: "In Imari Village to the far east, he silently dedicated his life for the sake of the shinobi. Once seeking to become the perfect retainer, his role shifted into evaluator -- no heir would be accepted as leader until they bested him. Since hte distant past, he acts with great regard for Imari's future.",
                evoawk: "His name is legendary among the shinobi's long history. Instead of free will, loyalty and duty led him to attain their ideal form. None know his true origins, but the tales describe how the struggle between the shinobi around the time of Ugetsu's defeat caused a marked change in him. What he witnessed then may have granted his existence new meaning."
            }
    },
    { //Riviera (Dark) - Complete, Review done + JP ver
        id: 103576512,
        name: "Riviera (Dark)",
        attribute: "Dark",
        type: "Demon",
        rank: "A",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103575512.png",
                detailawk: "/db/Units/Detail/unit_detail_103576512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103575512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103576512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7101,
                hpplus: 999,
                atk: 2803,
                atkplus: 430,
                def: 2548,
                defplus: 392
            },
        skillset: 
            {
                skill: "13s CT - 1800% Dark DMG (MAG). For 6s, reduce Enemies' DMG RES by 20%.",
                skillbreak: 400,
                arts: "16000% Dark DMG (PHY). For 30s, reduce Enemies' Dark RES by 20% (stackable). During Magia Drive: 16000% Dark DMG (PHY). Activate Magia Drive: Unlimited for the duration of the current Magia Drive. For 30s, reduce Enemies' Dark RES by 20% (stackable). During Magia Drive: Unlimited: 16000% Dark DMG (PHY). For 30s, reduce Enemies' Dark RES by 40% (stackable).",
                artsbreak: 800,
                truearts: "For 180s, enter Magia Drive. During Magia Drive: 60000% Dark DMG (MAG). For 20s, increase Allies' Arts by 4/s and reduce Enemies' MAG RES by 60%.",
                trueartsbreak: 1200
            },
        skillsetJP: 
            {
                skill: "13s CT - 1800% Dark DMG (MAG). For 6s, reduce Enemies' DMG RES by 20%.",
                skillbreak: 400,
                arts: "25000% Dark DMG (PHY). For 30s, reduce Enemies' Dark RES by 20% (stackable). During Magia Drive: 25000% Dark DMG (MAG). Enter Magia Drive: Unlimited for remaining duration of Magia Arts. For 40s, reduce Enemies' Dark RES by 20% (stackable). During Magia Drive: Unlimited: 25000% Dark DMG (MAG). For 40s, reduce Enemies' Dark RES by 50% (stackable).",
                artsbreak: 800,
                truearts: "For 180s, enter Magia Drive. During Magia Drive: 70000% Dark DMG (MAG). For 20s, increase Allies' Arts by 4/s and reduce Enemies' MAG RES by 100%.",
                trueartsbreak: 1200
            },
        passive: 
            {
                ability1: "During Magia Drive, increase own DMG to Demons by 50%.",
                ability2: "During Magia Drive: Unlimited, increase Allies' Arts by 3/s. Does not stack with Abilities of the same name.",
                ability3: "Increase Paralysis RES by 90%."
            }, 
        passiveJP: 
            {
                ability1: "During Magia Drive, increase own DMG to Demons by 120%.",
                ability2: "During Magia Drive: Unlimited, increase Allies' Arts by 3/s. Does not stack with Abilities of the same name.",
                ability3: "Increase Paralysis RES by 90%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                    mat3amt: 2,
                    hover3: "Ragsherum Phantom",
                    mat4: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat4amt: 2,
                    hover4: "Elpneumas"
                }
            },
            dreamJP: {
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                        mat3amt: 2,
                        hover3: "Fosradius",
                    },
                    passive: {
                        ability1: `Increase own Arts by 3/s.`,
                        ability2: `Increase own DMG against Light enemies by 100%.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragMavroskia.png",
                        mat3amt: 2,
                        hover3: "Mavroskia",
                    },
                    passive: {
                        ability1: `Increase Dark allies' Arts by 1/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own DMG against Demon enemies by 100%.`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Noble Blood",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_110231.png",
                skill: "50s CT - 8000% Dark DMG (MAG). For 20s, increase own Dark DMG by 70%.",
                skillbreak: 700,
                passive: 
                    {
                        ability1: "When equipped by Dark Riviera, increase own Skill CT REC by 30%, and increase Arts by 100 at the Start of a Quest. Does not stack with Abilities of the same name.",
                        ability2: "Increase MAG DMG by 10%."
                    }
            },
        lore: 
            {   
                evo5: `A Demon bounty hunter once feared as the "Brutal War Axe". Her untamed life was changed when she formed a pact with Juno and bonded with the other Templars. However, the years in Granadia brought considerable regret and suffering. Unable to withstand any more, she reluctantly decided to part.`,
                evoawk: `A Demon soldier who fought for Granadia in Juno's Templars. After the Great Seal was destroyed, she began to feel Leone's presence, and the trail led her to a dusky forest. However, as her search continued, she lost her mind and turned into a raging berserker. Only the voice of her friend Thetis restored her senses and stilled her scythe.`
            },
        review:
            {
                skill: `Overall, not that bad of a skill. While the uptime is rather bad, the actual effect is decent.`,
                arts: `Yeah...you'll pretty much never use this other than to activate Magia Drive: Unlimited. While the stackable dark res down looks tempting, you'd be better off just spamming her True art if you intend to use her as an artgen. At most you might cycle between her arts if you have enough artgen to afford it.`,
                truearts: `A nice artgen effect with a rather high magic res down. Only real downside is the need to Magia Drive before she can start doing anything else. However, given the lackluster artgen options dark has, she'll almost always have a nice place in dark ruins.`,
                ability1: `Given the rather large amount of demon enemies, this passive will see some use, however it's nothing game changing.`,
                ability2: `This is the only reason you'd enter Magia Drive Unlimited, a nice teamwide artgen effect which stacks with everything`,
                ability3: `I mean...this is nice, but why not 100%?`,
                trueweapon: {
                    skill: `Decently long uptime on a rather high dark dmg up, however the main reason you'd use this equip is for the passive not it's active.`,
                    ability1: `While the skill CT helps with that horrendis uptime on her skill, it's not enough to help the low uptime. The instant 100 art help with her consistency given the lack of artgen which doesn't require 300-400 arts investment.`,
                    ability2: `Minor dmg up helps.`
                },
                overall: `While she can't compete with the stronger artgen units like Emperor, Juno, or hart, she's much closer to someone like Dark Celia. Her lack of self sufficiency and need for 300+ artgen before she can do anything heavily slows her down and makes her reliant on her team for startup. However, once you get past that startup she is a solid artgen option for those who lack the aforementioned top tier units or when running content which doesn't let you use them like dark ruins. While her artgen my not be able to compete with the faster startup of other untis, her large and stackable dmg res down would have some utility even when paired with someone else like Juno.`,
                lastupdated: `April 20, 2023`,
                author: `Zanster`
            }
    },
    { //Thetis (Light) - Complete, Review done + JP ver
        id: 103586412,
        name: "Thetis (Light)",
        attribute: "Light",
        type: "Demon",
        rank: "C",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103585412.png",
                detailawk: "/db/Units/Detail/unit_detail_103586412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103585412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103586412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6961,
                hpplus: 999,
                atk: 3210,
                atkplus: 491,
                def: 2505,
                defplus: 385
            },
        skillset: 
            {
                skill: "10s CT - 2000% Light DMG (PHY). Increase own Arts by 10.",
                skillbreak: 300,
                arts: "17000% Light DMG (PHY). During Magia Drive: 17000% Light DMG (PHY). Activate Magia Drive: Unlimited for the duration of the current Magia Drive. During Magia Drive: Unlimited: 17000% Light DMG (PHY). For 25s, increase Allies' PHY DMG by 80%.",
                artsbreak: 700,
                truearts: "For 120s, enter Magia Drive. During Magia Drive: 100000% Light DMG (PHY).",
                trueartsbreak: 1000
            },
        skillsetJP: 
            {
                skill: "10s CT - 2000% Light DMG (PHY). Increase own Arts by 10.",
                skillbreak: 300,
                arts: "During Magia Drive: 17000% Light DMG (PHY). Enter Magia Drive: Unlimited for the duration of the current Magia Drive. During Magia Drive: Unlimited: 17000% Light DMG (PHY). For 25s, increase Allies' PHY DMG by 120%.",
                artsbreak: 700,
                truearts: "For 120s, enter Magia Drive. During Magia Drive: 130000% Light DMG (PHY).",
                trueartsbreak: 1000
            },
        passive: 
            {
                ability1: "During Magia Drive, increase own ATK and DEF by 50%.",
                ability2: "During Magia Drive: Unlimited, increase own Critical Rate by 150%.",
                ability3: "Increase Accuracy by 20%."
            }, 
        passiveJP: 
            {
                ability1: "During Magia Drive, increase own ATK and DEF by 50%.",
                ability2: "During Magia Drive: Unlimited, increase own Critical Rate by 220%.",
                ability3: "Increase Accuracy by 20%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 2,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragAerugraxes.png",
                    mat4amt: 2,
                    hover4: "Aerugraxes"
                }
            },
            dreamJP: {
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                        mat3amt: 2,
                        hover3: "Wilkes",
                    },
                    passive: {
                        ability1: `Increase own Arts by 5/s.`,
                        ability2: `Increase own DMG by 30% against enemies below 30% HP.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragOldeus.png",
                        mat3amt: 2,
                        hover3: "Oldeus",
                    },
                    passive: {
                        ability1: `Increase all allies' Dark RES by 20%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own Skill CT Speed by 35%.`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section 
            {
                name: "Tenchi Raimei",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_110221.png",
                skill: "50s CT - 8000% Light DMG (PHY). For 20s, increase own Light DMG by 70%.",
                skillbreak: 700,
                passive: 
                    {
                        ability1: "When equipped by Light Thetis, increase Arts by 2/s, Paralysis and Freeze RES by 100%. Does not stack with Abilities of the same name.",
                        ability2: "ncrease Skill DMG by 20%."
                    }
            },
        lore: 
            {   
                evo5: "Formerly defended Granadia as a member of Juno's Templars. Despite his mild manner, he is a veteran of many fierce battles. The determination to reclaim what he valued most convinced him to set aside his trademark shield. After the Great Seal was destroyed, his search for Riviera took him to Gramenia, where Kane appointed him as a provisional commander for his outstanding talent.",
                evoawk: "A Demon martial artist personally hired by President Kane. His astounding physical strength and battle sense has been further refined. Whiel searching for the missing Gramenian soldiesrs, he encountered Riviera (in) a state of insanity from the Crimson Blood. He saved her from that madness by revealing his long-hidden feelings for her."
            },
        review:
            {
                skill: `1 APS passive, nothing major but still nice to have.`,
                arts: `A very convoluted system. Needing like 300 arts to activate the damage buff is needlessly expensive for what you get.`,
                truearts: `A very high multiplier paired with his built in crit and ability to buff PHY damage makes Light Thetis a very potent dps unit after everything is set up.`,
                ability1: `The buffs are too insignificant to even be worth locking behind Magia Drive. In most cases you won’t even notice the difference.`,
                ability2: `Built in crit is always good, and there's even some extra on top for crit resistant bosses. Paired with his high multiplier in MGU, it will let Thetis hit pretty hard.`,
                ability3: `Not enough to be significant on any boss where you would need accuracy, but appreciated nonetheless`,
                trueweapon: {
                    skill: `Very nice damage buff, but you would either need to be going manual or using AI in order to get him to make the most of it before it runs out, because its uptime is too low to be consistent.`,
                    ability1: `Extra arts will definitely help with getting him to MGU faster, and flinch resistance is appreciated. Nothing major, but not bad either.`,
                    ability2: `This just serves little no purpose, nor is it helpful in 99% of situations.`
                },
                overall: `Light Thetis has the potential to be an amazing DPS with teamwide buffing, self crit, and a crazy high multiplier. Unfortunately, he is hindered by requiring Batman levels of prep time in the form of 400 Arts to get the relevant buffs, and then a further 200 Arts to actually do damage. While he can’t keep up with the more mainstream DPS units, he can still be used for a decent bit of content if you have some heavy-duty artsgen capabilities. Otherwise, Thetis is still going to fall short since he’s just too slow to see use in harder missions that press you for time, he can’t be used to nuke with, and is completely overshadowed by Erza and even NYMilim as a DPS.`,
                lastupdated: `April 20, 2023`,
                author: `Pula`
            }
    },
    { // Sakuya - Review done
        id: 103516312,
        name: "Sakuya",
        attribute: "Earth",
        type: "Human",
        rank: "C",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103515312.png",
                detailawk: "/db/Units/Detail/unit_detail_103516312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103515312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103516312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6750,
                hpplus: 999,
                atk: 2998,
                atkplus: 459,
                def: 2801,
                defplus: 430
            },
        skillset: 
            {
                skill: "12s CT- 2200% Earth DMG (PHY). Plant 3 bombs (up to 6).",
                skillbreak: 500,
                arts: "16000% Earth DMG (PHY). For 10s, increase Allies' evasion by [5% × # of bombs planted].",
                artsbreak: 900,
                truearts: "For 120s, enter Third Eye. During Third Eye: [40000% + 10000% × # of bombs planted] Earth DMG (PHY). Remove all planted bombs. For 15s, increase Allies' PHY DMG by 100%.",
                trueartsbreak: 1500
            },
        passive: 
            {
                ability1: "During Third Eye or True Third Eye, ignore 100% of Enemies' DEF and increase Arts by 5/s.",
                ability2: "When entering Third Eye or True Third Eye, if enemy boss is God/Spirit, activate True Third Eye instead. During True Third Eye, increase DMG to God and Spirit enemies by 200%.",
                ability3: "Increase Accuracy by 10%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragGoldola.png",
                    mat3amt: 2,
                    hover3: "Goldola",
                    mat4: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat4amt: 1,
                    hover4: "Radachne"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Hyakka",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_110281.png",
                skill: "50s CT - 7500% Earth DMG (PHY). For 10s, increase own DMG by 50% and reduce Enemies' Earth RES by 30%.",
                skillbreak: 700,
                passive: 
                    {
                        ability1: "When equipped by Sakuya, increase Skill CT REC by 30% and increase max # of possible planted bombs by 1. Does not stack with Abilities of the same name.",
                        ability2: "Increase Evasion by 10%."
                    }
            },
        lore: 
            {   
                evo5: "A shinobi from Imari Village. Although considered a child prodigy for how quickly she learned techniques, she stayed humble throughout her training to become an expert. Her role is Mizuki the Twelfth's retainer, but she finds both his radical mindset concerning and his charismatic confidence assuring.",
                evoawk: `Holds the rare title "Kasumi", bestowed to those of elusive as mist. She is the descendant of one who sealed a powerful Yoma, and that talent runs thick in her blood. The strong ties with Kirisame's clan ensure their hierarchies are closely entwined even today. However, new feelings awakened by the changing world put her devotion to Imari Village and the shinobi as a whole to the test.`
            },
        review:
            {
                skill: `A rather interesting gimmick to rely on Sakuya’s buffs and damage output.`,
                arts: `An evasion buff that’s appreciated, if the boss accuracy isn’t increased. This will help your team’s survivability. With that said however, her Arts will generally not see much use as Evasion is a very niche debuff.`,
                truearts: `Offering a decent multiplier that can be increased even further by using Sakuya’s Skill to plant bombs and thus push the damage up to 100k% and also being able to provide a teamwide physical damage buff. Sakuya is able to dish out some great damage.`,
                ability1: `Self sufficient artgen while in (True) Third Eye, and being able to ignore the Enemies’ DEF will help Sakuya out to be a good dps unit.`,
                ability2: `Increases Sakuya’s Damage output furthermore by activating True Third Eye when God/Spirit Enemy is present.`,
                ability3: `A nice 10% accuracy buff, which isn’t too noticeable. It can help a little bit, if the enemy has evasion up buff.`,
                trueweapon: {
                    skill: `A decent damage buff with a decent uptime, which is also able to decrease Enemies’ Earth Res.`,
                    ability1: `A nice Skill CT Recover buff that allows Sakuya to plant Kunais faster, and increase her Damage output furthermore.`,
                    ability2: `A nice 10% Evasion buff, if the boss doesn’t have accuracy up.`
                },
                overall: `Sakuya is a good Def Ignore DPS unit, if the niche is required or you don’t have better options. As she falls behind in the current meta with faster alternatives DPS units. Regardless, she can fit in the current meta with her DEF ignore niche, if needed.`,
                lastupdated: `April 6, 2023`,
                author: `Alice`
            }
    },
    { // Summer Cestina - Complete + JP ver
        id: 103596112,
        name: "Summer Cestina",
        rank: "B+",
        attribute: "Fire",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103595112.png",
                detailawk: "/db/Units/Detail/unit_detail_103596112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103595112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103596112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7000,
                hpplus: 999,
                atk: 3003,
                atkplus: 460,
                def: 2598,
                defplus: 399
            },
        skillset: 
            {
                skill: "10s CT - 1200% Fire DMG (PHY). For 6s, increase own accuracy by 30%.",
                skillbreak: 300,
                arts: "20000% Fire DMG (PHY). For 10s, reduce Enemies' Fire RES by 30% and increase Allies with >50% HP's Arts by 2/s.",
                artsbreak: 1000,
                truearts: "[Current HP x 25]% Fire DMG (PHY).",
                trueartsbreak: 1200
            },
        skillsetJP: 
            {
                skill: "10s CT - 2000% Fire DMG (PHY). For 6s, increase own accuracy by 30%.",
                skillbreak: 300,
                arts: "25000% Fire DMG (PHY). For 15s, reduce Enemies' Fire RES by 30% and increase Allies with >50% HP's Arts by 2/s.",
                artsbreak: 1000,
                truearts: "[Current HP x 25]% Fire DMG (PHY).",
                trueartsbreak: 1200
            },
        passive: 
            {
                ability1: "Increase own ATK by 15% when HP is above 90%.",
                ability2: "Increase own Arts by 2/s when HP is above 90%.",
                ability3: "When near-death, increase own Arts by 200 and restore own HP by 100%. Only once and Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 2,
                    hover3: "Flogassa",
                    mat4: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                    mat4amt: 2,
                    hover4: "Degilmagna"
                }
            },
        dreamJP: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                        mat3amt: 2,
                        hover3: "Ifrit",
                    },
                    passive: {
                        ability1: `Increase own Accuracy by 50%.`,
                        ability2: `Increase own Heal Amount by 20% (excluding Lifesteal).`
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                        mat3amt: 2,
                        hover3: "Radachne",
                    },
                    passive: {
                        ability1: `Increase own BE Output by 35%.`,
                        ability2: `Increase own Skill CT Speed by 20%.`
                    }
                }
            },
        trueweaponJP: //If no TW, may delete this section
            {
                name: "Ces' Sandy Blossom",
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_111761.png",
                skill: "35s CT - For 12s, increase all allies' Arts gauge by 2/s and Fire allies' Max HP by 20%.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Summer Cestina, increase own Arts gauge by 2/s and all stats by 30%. Does not stack with Abilities of the same name.",
                        ability2: "Increase own DEF by 5%."
                    }
            },
        lore: 
            {   
                evo5: "Cestina was looking forward to the summer vacation. What made her happiest was how all of her siblings were going -- the first time in years. Her younger brother Luahn, long unable to join due to his busy schedule, would finally see their island. She wanted to show him the pristine beach and introduce him to her favorite ice cream as soon as possible. Her excitement made each minute feel like an eternity as the billowing sails carried their boat southwards.",
                evoawk: "The second princess of Gran Verge keenly anticipated her vacation on the tropical Twilight Isle. It would be rare chance the royal family could forget their worries and enjoy some quality time together. As the ship neared those sunny shores, visions of past events reemerged in her dreams. She would never tell a soul, but the memories of her late father amidst the tumult and the strife of their voyage were those she treasured most."
            },
        review:
            {
                skill: `With a low magnitude and a short duration, the buff is basically unnoticeable.`,
                arts: `While it is in a new, different arts generation grouping (Type F), the duration and rate at which it generates are fairly small in today's standards. The magnitude of the fire resistance debuff is okay, but it's usually much better to just use true arts and opt for other units in both arts generating and debuffing.`,
                truearts: `As long as Beach Spiker (Summer) Cestina is healthy, she boasts the highest possible damage multiplier in the game. Assuming there are no max HP debuffs, she will have a multiplier of at least 160000% at full health (level 90, maxed tasmon stats, equipped), and Summer Cestina's damage can be further increased through items that increase max HP such as Feena Devil Wings, Iris's Change of Pace, etc. Some stages may increase max HP and raise her damage multiplier to immensely high levels, and other stages may have mechanics that will greatly lower Summer Cestina's HP and thus make her deal incredibly low damage.`,
                ability1: `Regardless of the requirement, 15% more ATK isn't really noticeable.`,
                ability2: `It's a decent passive to provide Cestina a little bit of self-sufficiency.`,
                ability3: `This is what you can call "payback" if you almost get wrecked by a boss, allowing you to make a last stand and basically unleash a supernova. You can also nuke certain bosses that start you at near-death or just hit really hard at the beginning.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `With the right conditions, Beach Spiker Cestina can deal the highest damage in the game. She is already a solid DPS option if the team can keep her healthy, and with ample max HP and damage support, she can be an absolute powerhouse. There are a few DPS units that are generally more accessible and practical for both sustaining and nuking, whether it’s providing more utility, dealing great damage through easier means, or just being faster, but Beach Spiker Cestina is nonetheless a very impressive and interesting unit.`,
                lastupdated: `November 9, 2022`,
                author: `PrayingPatrick`
            }
            
    },
    { // Summer Liza - Complete + JP ver
        id: 103606212,
        name: "Summer Liza",
        rank: "A-",
        attribute: "Water",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103605212.png",
                detailawk: "/db/Units/Detail/unit_detail_103606212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103605212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103606212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        slotsJP: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6391,
                hpplus: 968,
                atk: 2547,
                atkplus: 392,
                def: 2843,
                defplus: 436
            },
        skillset: 
            {
                skill: "13s CT - 1200% Water DMG (MAG). Heal Water Allies' HP by 1000.",
                skillbreak: 200,
                arts: "16000% Water DMG (MAG). Increase own Arts by 20. For 80s, reduce Enemies' DMG RES by 20% (Stackable).",
                artsbreak: 2200,
                truearts: "47000% Water DMG (MAG). For 30s, increase Allies' MAG and Critical DMG by 100%.",
                trueartsbreak: 1300
            },
        skillsetJP: 
            {
                skill: "13s CT - 1200% Water DMG (MAG). Heal Water Allies' HP by 1500.",
                skillbreak: 200,
                arts: "22000% Water DMG (MAG). Increase own Arts by 20. For 80s, reduce Enemies' DMG RES by 30% (stackable).",
                artsbreak: 2200,
                truearts: "52000% Water DMG (MAG). For 30s, increase Allies' MAG and CRI DMG by 150%.",
                trueartsbreak: 3000
            },
        passive: 
            {
                ability1: "At the start of a Quest, increase own Arts by 100. When HP is above 80%, increase own Critical Rate by 100%.",
                ability2: "Increase own Paralysis RES by 80%.",
                ability3: "Increase own Arts by 3/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat3amt: 3,
                    hover3: "Code-XTF",
                    mat4: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat4amt: 2,
                    hover4: "Palamicia"
                }
            },
        dreamJP: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragPalamicia.png",
                        mat3amt: 2,
                        hover3: "Palamicia",
                    },
                    passive: {
                        ability1: `Increase Water allies' Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own DMG by 30% against enemies under 30% HP.`
                    }
                },
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                        mat3amt: 2,
                        hover3: "Tonetaurus",
                    },
                    passive: {
                        ability1: `Increase allies' Break Power by 40%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own HP Recovery Amount by 50% (excluding Lifesteal).`
                    }
                }
            },
        trueweaponJP: //If no TW, may delete this section
            {
                name: "Liza's Mature Look",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_111771.png",
                skill: "45s CT - 4500% Water DMG (PHY). For 15s, increase own Water DMG by 40%. If user is God, increase own Water DMG by 60% instead.",
                skillbreak: 400,
                passive: 
                    {
                        ability1: "When equipped by Summer Liza, increase own Arts gauge by 2/s and Heal Amount by 50% (excluding Lifesteal). Does not stack with Abilities of the same name.",
                        ability2: "Increase own Fire RES by 5%."
                    }
            },
        lore: 
            {   
                evo5: "Liza declared that this summer, everyone in the family would go on a vacation. This meant they needed to depart later in the season to accommodate for Luahn's busy schedule and miss the bulk of the festivities, but she was not disappointed in the least. There was still plenty of enjoyment to be had swimming, barbequing, and more with her beloved siblings, including a special surprise of her own...",
                evoawk: "Liza surprised the others with an extra-loud, extra-dazzling fireworks display. The only downside was it needed a stable source of magical energy, which was rare on the isolated island. Although there was a low-powered variety, she wanted to remain true to the technique her late father taught her. She listened to everyone's laughter and cheers, hoping he was enjoying them just as much."
            },
        review:
            {
                skill: `The healing does have some use if you're using a mono water team, outside of that it's rather mediocre. Only upside is that the healing happens the second her skill is used.`,
                arts: `While the stacking dmg res is nice, the 7 odd second animation on her Art heavily limits the number of stacks you can get, making it better to just spam her TA instead.`,
                truearts: `Despite having a multiplier from 3 years ago, the teamwide dmg up she offers more than makes up for it. Having the potential to give everyone 200% dmg up allows other hard hitting allies to do their job better, even if you're only giving them the 100% crit dmg up and not the whole buff.`,
                ability1: `The instant 100 Arts she gets is a useful addition, allowing her to either get started sooner or limit the downside of dignified silence in nukes. Her crit rate being locked behind her being over 80% HP does hurt a bit, however it's rarely an issue if you're nuking or running a solid team.`,
                ability2: `Well, it's something. Never know when it might be helpful.`,
                ability3: `Who doesn't love passive artgen?`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Despite being overshadowed by her younger sister, Liza is still a competent unit being able to compete with other magic buffers such as Santa Rimuru and Fubuki. Additionally, unlike them she can also work for non magic teams if you lack stronger dmg buffers. However, on her own she's rather mediocre. She needs another strong unit who can make the most of her buffs to shine, good thing Hao is here to fill that role for now.`,
                lastupdated: `November 9, 2022`,
                author: `Zanster`
            }
    },
    { // War Hero Fen - Complete, Review done
        id: 103617312,
        name: "War Hero Fen",
        rank: "SSS",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103616312.png",
                detailsuper: "/db/Units/Detail/unit_detail_103617312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103616312.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103617312.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/suppLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7843,
                hpplus: 1414,
                atk: 3289,
                atkplus: 648,
                def: 3162,
                defplus: 600
            },
        skillset: 
            {
                skill: `12s CT - 2300% Earth DMG (PHY). Increase own Arts by 50.`,
                skillbreak: 300,
                arts: `26000% Earth DMG (PHY). For 20s, increase Allies' accuracy and Equipment CT REC by 40%.`,
                artsbreak: 1300,
                truearts: `60000% Earth DMG (PHY). Increase own Super Critical Rate by 20% during True Arts. For 20s, increase Allies' Arts by 4/s and Critical Rate by 100%.`,
                trueartsbreak: 2600,
                superarts: `160000% Earth DMG (PHY). Increase own Super Critical Rate by 40% and Critical Rate by 100% during Super Arts. For 40s, increase own ATK by 100%.`,
                superartsbreak: 1800
            },
        passive: 
            {
                ability1: `When self is Leader, at the start of a Quest, increase Allies' DMG RES by 30% for 45s. Does not stack with Abilities of the same name.`,
                ability2: `At the start of a Quest, increase Allies' Arts by 50. Does not stack with Abilities of the same name.`,
                ability3: `Increase Allies' DMG to Enemies with 50% or less HP by 30%. Does not stack with Abilities of the same name.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat1amt: 1,
                    hover1: "Wilkes",
                    mat2: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat2amt: 2,
                    hover2: "Flogassa",
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_130653.png",
                    mat3amt: 220,
                    hover3: "Smogdragon Soulgem"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: `Euthanasia`,
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_110601.png",
                skill: `30s CT - Increase Allies' Arts by 25. For 15s, increase Human Allies' DMG by 40%`,
                skillbreak: 0,
                passive: 
                    {
                        ability1: `When equipped by War Hero Fen, increase own Skill CT REC and Arts DMG by 20%`,
                        ability2: `Increase DMG to Human enemies by 10%`
                    }
            },
        lore: 
            {   
                evoawk: "Secretary-general and strategist of the Gramenian army. Born in the slums. Later-president Kane saw his potential and taught the young street urchin swordplay, literature, tactics, and statecraft until he acquired the dignity of a military officer. When he took his current post, all trace of his crude origins had fallen away, leaving only a leader of bravery, intelligence, and esteem.",
                evosuper: "What drove him was the power to overcome his own weaknesses. Although it lifted him from the slums, it gradually waned as he climbed the ranks. Only after hte turmoil brought about by the Great Seal's destruction did he feel that hunger again. This incomprehensible new world was yet another challenge to be conquered. However, it would not be for himself, but for those he wanted to protect... who revered him as a hero of war."
            },
        review:
            {
                skill: `Artgen on skill, tremendously useful for self-sustainability. 50 unconditional arts is huge, and helps War Hero Fen cycle his various arts easily when paired with a Primary Artsgen unit such as Hart, Emperor, or Juno.`,
                arts: `It cannot be stated how useful EQCT buffs are, enabling full uptime on certain defense equips and helping artgen equips reset faster. Furthermore, it is also accompanied by a 40% accuracy buff, which is somewhat useful as several bosses have evasion.`,
                truearts: `Teamwide crit along with unconditional artgen that stacks with most other units, something that is fairly rare so far, allowing players to slot in DPS units that previously lacked Crit Rate. And a decent TA multiplier as well. 20% super critical rate is a nice added touch, it cannot be too relied upon because of its aleatory nature but it helps buff War Hero Fen’s TA damage output.`,
                superarts: `Ridiculously high DPS coupled with a 40% supercritical rate and a long-lasting ATK buff. Outdamages virtually anything else in the game unless the boss has critical resistance, in which case it still deals appreciable damage simply due to how high the multiplier is.`,
                ability1: `30% free DMG RES that is available from the start of the quest with no other effort needed that having War Hero Fen as leader. Most quests will not last the 45s duration with him on board, and for those that do, it gives ample time to set up proper defenses. Note that if cresting other units with him on the team, he will not be able to be leader.`,
                ability2: `Helps kickstart team arts, whether in a sustain or nuke scenario. Note that this passive does not stack with Dark Fen’s, but does stack with Forte/Draken/NY Milim’s.`,
                ability3: `Helps everyone finish off enemies faster, especially at low hp when they tend to be most dangerous.`,
                trueweapon: {
                    skill: `Decent instant artgen with a good arts/s rate coupled with a DMG buff for humans. It is a nice option as a permanently available teamwide artgen equip, but ultimately it is replaceable by any other teamwide artgen equip.`,
                    ability1: `Mild damage buff passive, along with a skill CT buff which will help a bit with artgen from skill.`,
                    ability2: `A small DMG buff against Human enemies.`
                },
                overall: `Arguably the best unit in the game alongside Emperor Isliid, War Hero Fen does virtually everything except healing. While his arts/s output is not as high as some other units, he has a rare artsgen type (type A) that enables him to stack arts with most units, and he will outright have every unit start with 50 arts. He offers accuracy, EQCT and crit buffs, some teamwide DMG RES when leader, and he can also deal ridiculously high damage on top. War Hero Fen is thus an extremely versatile and self-sufficient unit who can fit well on virtually any team. Being able to use three different arts also makes him a much less linear unit than some of the older awakened units who too often spam the same arts over and over again. War Hero Fen breaks this trend, and proper arts cycling will prove invaluable to extract his full potential. Note that while the defense slot is a nice addition, the scarcity of Expert Limit Break Stones makes it so that other future units may be preferable to limit break beyond the standard LB5.`,
                lastupdated: `May 16, 2023`,
                author: `MOB of Metal`
            }
    },
    { // Valerie - Complete, Missing Review
        id: 103486212,
        name: "Valerie",
        attribute: "Water",
        type: "Human",
        rank: "A",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103485212.png",
                detailawk: "/db/Units/Detail/unit_detail_103486212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103485212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103486212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6990,
                hpplus: 999,
                atk: 2990,
                atkplus: 458,
                def: 2614,
                defplus: 402
            },
        skillset: 
            {
                skill: "10s CT - 1800% Water DMG (PHY). Increase own Arts by 20.",
                skillbreak: 600,
                arts: "20000% Water DMG (PHY). Permanently increase Allies' stats by 10% (up to 30%). For 15s, reduce Enemies' PHY RES by 20%.",
                artsbreak: 1200,
                truearts: "70000% Water DMG (PHY). For 15s, increases Water Allies' DMG by 120%. If Water Ally's HP is above 70% upon activation of this True Arts, increase their DMG by 240% instead.",
                trueartsbreak: 1800
            },
        passive: 
            {
                ability1: "When near-death, increase own Arts by 200 and recover 100% of own HP. Can only be activated once per quest.",
                ability2: "Increase DMG to Demons by 50%.",
                ability3: "Increase own Accuracy by 30%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 1,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat4amt: 2,
                    hover4: "Freydal"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Shooting Star",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_110451.png",
                skill: "50s CT - 9000% Water DMG (PHY). For 12s, reduce Enemies' PHY RES by 40%. On first use of this Equip, for 12s, increase Allies' Stats by 30%.",
                skillbreak: 500,
                passive: 
                    {
                        ability1: "When equipped by Valerie, increase own DMG by 30%, and increase own Arts by 2/s. Does not stack with Abilities of the same name.",
                        ability2: "Increase PHY RES by 5%."
                    }
            },
        lore: 
            {   
                evo5: "A knight-in-training of Famelle. The mental wounds from losing her parents at a tender age have not yet healed. She began studying the blade with the belief that it would grant her the strength to survive alone. To everyone's surprise, she swiftly outpaced those much older than her. Although she has opened up to fellow student Charlotte, dark thoughts still glimmer deep within her eyes.",
                evoawk: "One of the next generation of soldiers upholding Famelle's legacy. Zeorg took her under his wing after her parents' death, and she became like a sister to his granddaughter Charlotte. Her gratitude for their familial love runs deep, but the insecurity if she can ever properly show it bothers her."
            }
    },
    { // Cosmo - Complete, Review done
        id: 103626412,
        name: "Cosmo",
        rank: "B+",
        twrequire: "favorable", 
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103625412.png",
                detailawk: "/db/Units/Detail/unit_detail_103626412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103625412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103626412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6800,
                hpplus: 999,
                atk: 2900,
                atkplus: 445,
                def: 2820,
                defplus: 433
            },
        skillset: 
            {
                skill: "13s CT - 1800% Light DMG (MAG). 40% chance to inflict Paralysis for 2s.",
                skillbreak: 300,
                arts: "18000% Light DMG (MAG). For 15s, reduce Enemies' Paralysis and MAG RES by 30%.",
                artsbreak: 800,
                truearts: "80000% Light DMG (MAG). 100% chance to inflict Paralysis for 4s. For 20s, increase own Critical Rate by 100%. ",
                trueartsbreak: 1200
            },
        passive: 
            {
                ability1: "Whenever Cosmo's Skill or True Arts inflicts Paralysis on an enemy, permanently increase all Light allies' DMG by 20% (up to 200%).",
                ability2: "Increase DMG to Dark enemies by 30%.",
                ability3: "Increase Critical DMG by 30%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                    mat3amt: 1,
                    hover3: "Wilkes",
                    mat4: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat4amt: 2,
                    hover4: "Flogassa"
                }
            },
        trueweapon: 
            {
                name: "Soaring Z",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_110551.png",
                skill: "40s CT - 8000% Light DMG (MAG). For 10s, increase own Light DMG and Skill CT REC by 50%.",
                skillbreak: 500,
                passive: 
                    {
                        ability1: "When equipped by Cosmo, increase own DMG by 30% and Arts by 3/s. Does not stack with Abilities of the same name.",
                        ability2: "Increase PHY RES by 5%."
                    }
            },
        lore: 
            {   
                evo5: "A soldier of the Bamint Principality. Formerly affiliated with the Rose Garden Knights, Mira reassigned her to the general affairs bureau where she now oversees everything from budgeting to large-scale construction projects. As competent as she is, her solicitous nature causes her to shoulder too many tasks. She claims playing the guitar is a mere hobby, but it ends up taking most of her free time since she relieves stress that way. Just the thought of laying down sweet licks with her trusty Soaring Z sustains her through the daily grind.",
                evoawk: "A Bamint military officer and aspiring guitarist. Personal lessons from underground rock legend Kane has seen her talent improve by leaps and bounds. Her performances are more emotional than technical, but they have an undeniable groove that even leaves her tutor gobsmacked at times. She respect their student-teacher relationship, but that has not cooled her desire to challenge him to a head-to-head guitar duel one day."
            },
        review:
            {
                skill: `Nothing too special about this, helps her get stacks up faster but otherwise is too inconsistent to be used reliably.`,
                arts: `Decent uptime and res down effects for a Normal Art, helping boost her damage and on True Art and paralysis chance if the boss has any res to it.`,
                truearts: `With a high multiplier, guaranteed paralyze chance if the boss doesn't have any res, and crit rate she's a solid DPS and the best paralyze option without question.`,
                ability1: `A very powerful passive capable boosting light allies dmg by up to 200%. It's especially strong given the current state of light units being top tier. Although being locked behind landing up to 10 paralyzes, for content where cosmo will be used such as mines, this conditional effect is negligible.`,
                ability2: `Well, it's something. Never know when it might be helpful.`,
                ability3: `Minor Crit DMG boost just helps out her True Arts damage a bit.`,
                trueweapon: {
                    skill: `While it's active effect isn't the main reason you'd want to run her TW, the 50% light dmg and skill CT up are a nice boost although it's uptime is rather low does hurt quite a bit.`,
                    ability1: `This is the main reason you'd run her TW, the passive 3aps greatly helps her self sufficiency since she doesn't have any other self artgen in her kit. The 30% dmg up is just another solid boost.`,
                    ability2: `A little bit of bonus physical res never hurt.`
                },
                overall: `Cosmo's Viability can be described in a flow chart, do you need to paralyze? Yes, she's cracked. No, She's still a solid DPS. With Cosmo's only real competition for best paralyze option being Norn and Elfalla, she blows them both out the water in both consistency and damage. Although her lack of self sufficiency does hurt a bit, the 3 aps she gets from her TW helps. However, what helps her the most is the surplus of amazing light units to pair up with to make the most of her passive dmg up per paralyze.`,
                lastupdated: `December 31, 2022`,
                author: `Zanster`
            }
    },
    { // Kirisame - Complete, Review done
        id: 103527112,
        name: "Kirisame",
        rank: "S",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103526112.png",
                detailsuper: "/db/Units/Detail/unit_detail_103527112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103526112.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103527112.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/defLB.png",
                slot32type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 7480,
                hpplus: 1410,
                atk: 3045,
                atkplus: 630,
                def: 3500,
                defplus: 610
            },
        skillset: 
            {
                skill: `12s CT - 2200% Fire DMG (PHY). Increase own Arts by 30. For 8s, reduce Enemies' Burn RES by 30%.`,
                skillbreak: 700,
                arts: `18000% Fire DMG (PHY). 70% chance to inflict Burn for 10s.`,
                artsbreak: 1200,
                truearts: `For 180s, enter Third Eye. During Third Eye/True Third Eye: 52000% Fire DMG (PHY). For 25s, redirect Allies' DMG taken to self and gain a DMG barrier that negates 35000 DMG to self.`,
                trueartsbreak: 1800,
                superarts: `70000% Fire DMG (PHY). For 40s, redirect Allies' DMG taken to self and gain a DMG barrier that negates 50000 DMG to self. During Third Eye/True Third Eye: 70000% Fire DMG (PHY). For 40s, redirect Allies' DMG taken to self and gain a DMG barrier that negates 50000 DMG to self + Cleanse 2 DMG RES debuffs from self.`,
                superartsbreak: 2600
            },
        passive: 
            {
                ability1: `When in Third Eye or True Third Eye, increase DMG RES by 30%. Additionally, increase DMG RES against Burning Enemies by 20%. Does not stack with Abilities of the same name.`,
                ability2: `When entering Third Eye, if there is a human ally other than himself, he will enter True Third Eye. During True Third Eye, increase PHY RES by 20%. Does not stack with Abilities of the same name.`,
                ability3: `At the start of a Quest, increase own Arts by 50. Does not stack with Abilities of the same name.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_130853.png",
                    mat2amt: 220,
                    hover2: "Flametail Soulgem"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: `Garuda`,
                slot: "/db/Mines/thumbnail/defLB.png",
                detail: "/db/Equipment/Detail/item_detail_110621.png",
                skill: `40s CT - 3000% Fire DMG (PHY). For 10s, increase own Fire DMG by 30% and DMG RES by 20%.`,
                skillbreak: 700,
                passive: 
                    {
                        ability1: `When equipped by Kirisame, increase own Paralysis and Freeze RES by 100%. Additionally, increase Third Eye or True Third Eye duration by 60s.`,
                        ability2: `Increase ATK by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "A shinobi from Imari Village. He was always causing mischief in his younger age, but he also formed a close friendship with Mizuki, giving the leader a valuable emotional outlet. There is nothing he hates more than running away, so he avoids practicing escape arts to focus on hand-to-hand combat whenever possible.",
                evosuper: `Granted the title "Guren" for possessing the strength of flame itself. Being the descendant of a Yoma-sealing shinobi helped, but that achievement was earned with his ferocious spirit and righteous will. His style contradicts the clam judgement the shinobi are known for, but Sakuya's support lets him burn bright in battle.`
            },
        review:
            {
                skill: `A skill that provides Artgen, which is appreciated to get Kirisame started up faster. The Burn Resistance down debuff of 30% is a nice touch for its niche as it helps burning a boss more frequently.`,
                arts: `While the Art is quite niche since burning an enemy isn't really needed. This Art pairs very well with his Skill and Passive, it does increase his survivability as taunt. Just awkward to use..`,
                truearts: `A core ability of Kirisame, activating Third Eye. There’s not much to be said about this otherwise, it’s a taunt effect that’s stronger than average but his Super Arts outperforms this.`,
                superarts: `Kirisame’s Bread & Butter, the largest Barrier in the game with the ability to remove DMG RES Down effects on self whenever he's in third eye. It can not be understated how strong this is for endgame quests that time you out through multiple DMG RES down stacks. It also has the added benefit of not requiring his Third Eye to be active unlike TA.`,
                ability1: `Wow. Such an amazing passive. Just by entering either Third Eye. Kirisame will have a 30% Damage Resistance up buff, which helps him furthermore with taunting. In addition by burning an enemy he'll increase it even more, which makes a damage resistance of 50% just from passives. Although hard to achieve.`,
                ability2: `Easily accessible due to how prevalent Human Units are. He will enter True Third Eye and increase his damage resistance against physical bosses even further. This makes up a total of 50% DR passively or 70% when the enemy is burnt.`,
                ability3: `Free arts isn't a bad thing. This helps Kirisame to activate his Super Art much faster to redirect the damage towards him.`,
                trueweapon: {
                    skill: `A relatively short uptime with a questionable skill. Kirisame does not want additional DMG & the DMG RES is not worth using over other 5* DEFs.`,
                    ability1: `The main reason you’d purchase this TW, Flinch RES to be able to use the unit in certain quests, the additional time on Third Eye also is helpful if you do not require additional DMG Res from equipment.`,
                    ability2: `A minor Atk buff, which isn't too noticeable. But it's still nice to have.`
                },
                overall: `Kirisame is just an amazing taunt tank. Not only does he have one of the biggest barriers (50,000) but also one of the longest durations of 40s, equal to Earth Vultee. But that's not all! He just gains massive damage resistance passively by only entering Third Eye. Letting him enter True Third Eye ain't a difficult task as well, because most meta relevant units are from the Human Race. With the ideal slotting for a taunt tank, there’s nothing you can complain about regarding his ability to tank enemies.`,
                lastupdated: `August 23, 2023`,
                author: `Alice`
            }
    },
    { //Hakuro - Complete, Review done
        id: 103566311,
        name: "Hakuro",
        rank: "C",
        luckrank: "C",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_103565311.png",
                detailawk: "/db/Units/Detail/unit_detail_103566311.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_103565311.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103566311.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6442,
                hpplus: 976,
                atk: 2812,
                atkplus: 431,
                def: 2759,
                defplus: 423
            },
        skillset: 
            {
                skill: "12s CT - 1800% Earth DMG (MAG). Cures own Poison & Burn. If this skill cures Poison/Burn, increase own Arts by 50.",
                skillbreak: 500,
                arts: "17000% Earth DMG (MAG). 50% chance of inflicting Blind for 10s.",
                artsbreak: 1000,
                truearts: "For 120s, enter Third Eye. During Third Eye/True Third Eye: 42000% Earth DMG (MAG). For 20s, increase Earth Allies' DMG by 100%.",
                trueartsbreak: 2100
            },
        passive: 
            {
                ability1: "When Third Eye is activated, for 40s, reduce Enemies' MAG RES by 50%. When in Third Eye or True Third Eye, increase own Arts by 2/s.",
                ability2: "When activating Third Eye, if enemy is Demon or Beast Boss, activate True Third Eye instead. During True Third Eye, increase DMG to Demon and Beast Enemies by 200%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragHakurou.png",
                    mat3amt: 20,
                    hover3: "Hakurou"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Tenrui-Giri",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_110651.png",
                skill: "65s CT - 8000% Earth DMG (MAG). For 10s, reduce Enemies' Blind RES and Evasion rate by 30%.",
                skillbreak: 600,
                passive: 
                    {
                        ability1: "When Equipped by Hakuro, increase own Arts DMG by 40%.",
                        ability2: "Increase DEF by 5%."
                    }
            },
        lore: 
            {   
                evo5: "The oldest surviving shinobi of Imari Village, having served the Mizuki household for three generations. His life has been dedicated to protecting the clan's secret history and their patriotic duty. That resolve also lets him perceive changes, no matter how slight, which the passing ages bring to his home.",
                evoawk: `The highest authority of Imari Village, surpassing even the current leader. He spares no mercy for any who do not share his honor for law and tradition. This is because he has seen first-hand how prideful impudence can lead the strong to ruin. In his mind, a shinobi should desire to live and die in the shadows, and those who seek light are despicable fools.`
            },
        review:
            {
                skill: `A skill that will often be useless due how rare status ailments are in quests, it is not to say that it is bad whenever applicable however.`,
                arts: `A chance to blind the enemy. It’s appreciated when needed, but it won’t see much use over his True Arts`,
                truearts: `A decent multiplier and a Good teamwide Earth Damage buff of 100%, Hakuro and your Earth units in your team will do some strong damage, although this does rely on having a competent Earth DPS on your team.`,
                ability1: `After using his True Arts to enter Third Eye, Hakuro will reduce the Enemies Magic resistance by 50% for 40s. Paired with his Earth Damage buff, he can act as a fairly well dps. The Arts he gains passively is also a great bonus.`,
                ability2: `Assuming there is either a Demon or Beast enemy upon entering Third Eye, he’ll enter True Third Eye instead, which will increase his damage furthermore against the mentioned 2 races. This helps him out with his damage output even more especially since Demon race bosses are quite common.`,
                trueweapon: {
                    skill: `A rather pathetic active effect, while the blind res down can pairs well with Hakuro Normal Art to inflict the enemy with blind more frequently, as mentioned earlier you generally wouldn’t use his Normal Art over his True Art. The evasion down is also a decent addition if the boss has some evasion since this is one of the few equips in the game that can provide evasion down. `,
                    ability1: `A nice noticeable Arts DMG buff is always appreciated.`,
                    ability2: `It's a nice bonus, but nothing too noticeable.`
                },
                overall: `If Hakuro released with the original batch of ninja’s in 2020 he would have been a decent unit, sadly it’s 2023 and he can’t compare to the newer and modern options we have. His overall kit is more oriented to do damage, and increase the damage output of your earth units. Atleast he's a luck unit...`,
                lastupdated: `August 23, 2023`,
                author: `Alice`
            }
    },
    { // Demon Edge Berwick - Missing Lore (GL)
        id: 103636112,
        name: "Demon Edge Berwick",
        translate: "Sword Demon Berwick",
        attribute: "Fire",
        rank: "A+",
        twrequire: "reliance",
        type: "Demon",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103636112.png",
                detailsuper: "/db/Units/Detail/unit_detail_103637112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103636112.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103637112.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7174,
                hpplus: 1354,
                atk: 4086,
                atkplus: 703,
                def: 2962,
                defplus: 614
            },
        skillset: 
            {
                skill: `12s CT - 2700% Fire DMG (PHY). For 8s, reduce Enemies' Fire RES by 50%.`,
                skillbreak: 700,
                arts: `17000% Fire DMG (PHY). Increase Fire Allies' Arts by 20. For 12s, increase Allies' stats by 30%. When this Arts is used during Magia Drive, activate Magia Drive: Unlimited for the remaining duration of Magia Drive.`,
                artsbreak: 1200,
                truearts: `For 180s, enter Magia Drive. When used during Magia Drive/Magia Drive: Unlimited: 70000% Fire DMG (PHY). For 30s, increase Allies' PHY DMG by 200%.`,
                trueartsbreak: 1900,
                superarts: `180000% Fire DMG (PHY). During own Super Arts, ignore 100% of Enemies' DEF. For 40s, increase own DMG by 50%.`,
                superartsbreak: 2200
            },
        passive: 
            {
                ability1: `Increase DMG against Earth Enemies by 25%.`,
                ability2: `When in Magia Drive, increase own ATK by 100%.`,
                ability3: `When in Magia Drive: Unlimited, increase own Arts DMG by 50%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_130853.png",
                    mat2amt: 220,
                    hover2: "Flametail Soulgem"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: `Enmon Zanketsu`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_110761.png",
                skill: `60s CT - 10000% Fire DMG (PHY). For 12s, reduce Enemies' PHY RES by 20%. If user is Fire, reduce Enemies' PHY RES by an additional 15%.`,
                skillbreak: 700,
                passive: 
                    {
                        ability1: `When equipped by Demon Edge Berwick, increase Arts by 5/s and Max HP by 1000. Does not stack with Abilities of the same name.`,
                        ability2: `Increase ATK by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    {
        id: 103537112,
        name: `Kokuri`,
        attribute: `Fire`,
        type: `Demon`,
        rank: `A`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103536112.png",
                detailsuper: "/db/Units/Detail/unit_detail_103537112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103536112.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103537112.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 7473,
                hpplus: 1349,
                atk: 2720,
                atkplus: 544,
                def: 3264,
                defplus: 617
            },
        skillset: 
            {
                skill: `13s CT - 1800% Fire DMG (MAG). Increase own Arts gauge by 30`,
                skillbreak: 500,
                arts: `16000% Fire DMG (MAG). For 30s, reduce enemies' DMG RES by 20% and CRI RES by 100%.`,
                artsbreak: 1000,
                truearts: `45000% Fire DMG (MAG). For 20s, reduce enemies' Fire RES by [# of Fire allies in Party x 30%].`,
                trueartsbreak: 1800,
                superarts: `100000% Fire DMG (MAG). For 30s, increase all allies' CRI DMG by 200%`,
                superartsbreak: `2200`
            },
        passive: 
            {
                ability1: `Skill CT when Near-Death 20% UP.`,
                ability2: `When own HP is 50% or above, increase all allies' MAG RES by 20%. Does not stack with Abilities of the same name.`,
                ability3: `Increase Demon allies' stats by 10%. Does not stack with Abilities of the same name.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_130853.png",
                    mat2amt: 220,
                    hover2: "Flametail Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Kubou`,
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_111301.png",
                skill: `30s CT - Increase all allies' Arts gauge by 20. For 8s, increase all Fire allies' ATK and DMG by 30%.`,
                skillbreak: 0,
                passive: 
                    {
                        ability1: `When equipped by Kokuri, increase own Arts gauge by 3/s and Equip CT by 20%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own MAG RES by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    { // Hazuki - Missing Lore (GL)
        id: 103547412,
        name: "Hazuki",
        attribute: "Light",
        type: "Human",
        rank: `SS`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103546412.png",
                detailsuper: "/db/Units/Detail/unit_detail_103547412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103546412.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103547412.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/suppLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 8050,
                hpplus: 1450,
                atk: 3058,
                atkplus: 606,
                def: 3128,
                defplus: 594
            },
        skillset: 
            {
                skill: `12s CT - 2000% Light DMG (PHY). For 10s, reduce Enemies' DMG RES by 20%.`,
                skillbreak: 500,
                arts: `20000% Light DMG (PHY). Permanently increase Allies' PHY DMG by 30% (up to 150%).`,
                artsbreak: 1500,
                truearts: `For 120s, enter Third Eye. During Third Eye: 55000% Light DMG (PHY). For 30s, increase Allies' Arts DMG by 130% and DMG RES by 30%.`,
                trueartsbreak: 1800,
                superarts: `120000% Light DMG (PHY). For 15s, Increase Allies' Accuracy by 50% and reduce Enemies' Light RES by 100%.`,
                superartsbreak: 2500
            },
        passive: 
            {
                ability1: `When in Third Eye or True Third Eye, increase own DMG by 100% and increase own Arts by 5/s.`,
                ability2: `When entering Third Eye, if all Allies' HP is 90% or higher, activate True Third Eye instead. During True Third Eye, increase own ATK and Accuracy by 100%.`,
                ability3: `Increase Paralysis RES by 100%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131354.png",
                    mat2amt: 220,
                    hover2: "Eternaglint Soulgem"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: `Jurai`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_110801.png",
                skill: `50s CT - 10000% Light DMG (PHY). For 10s, increase own DMG by 50% and increase Light Allies' DMG by 30%.`,
                skillbreak: 800,
                passive: 
                    {
                        ability1: `When equipped by Hazuki, increase stats by 20% and increase own Arts by 100 when entering Third Eye or True Third Eye.`,
                        ability2: `Increase Skill CT REC by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    { // Sublime Supernova Liza - Missing Lore
        id: 103647412,
        name: "Sublime Supernova Liza",
        translate: `Star God Liza`,
        attribute: "Light",
        type: "God",
        rank: `SS`,
        lb7require: true,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103646412.png",
                detailsuper: "/db/Units/Detail/unit_detail_103647412.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_364_1.png",                
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103646412.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103647412.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_364_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 8165,
                hpplus: 1470,
                atk: 3080,
                atkplus: 610,
                def: 2990,
                defplus: 570
            },
        skillset: 
            {
                skill: `12s CT - 1800% Light DMG (MAG). Increase own Arts by 30. For 8s, increase Allies' Arts by 3/s.`,
                skillbreak: 500,
                arts: `23000% Light DMG (MAG). For 25s, reduce Enemies' MAG RES by 50%.`,
                artsbreak: 900,
                truearts: `70000% Light DMG (MAG). For 30s, increase Allies' Equipment CT REC by 60% and increase Light allies' DMG by 150%.`,
                trueartsbreak: 1300,
                superarts: `130000% Light DMG (MAG). For 15s, increase Allies' MAG DMG based on own current HP% (MAX: 300%).`,
                superartsbreak: 2000
            },
        passive: 
            {
                ability1: `Ether Recast: Long-press on an equipment to reset its cooldown, allowing you to use it again (Only once per equipment).`,
                ability2: `Increase own HP Recovery Amount by 20%.`,
                ability3: `Increase own Arts Gauge by 50 at Start of Quest.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131354.png",
                    mat2amt: 220,
                    hover2: "Eternaglint Soulgem"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: `Mercurius`,
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_110851.png",
                skill: `50s CT - For 10s, increase Allies' Arts by 4/s and Allies' Light DMG by 40% (stackable).`,
                skillbreak: 800,
                passive: 
                    {
                        ability1: `When equipped by Sublime Supernova Liza, increase all stats and Skill CT REC by 20%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase MAG RES by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Liza holding the Scepter of Revelation. Although extremely shy in her youth, she gained confidence after banding with the other children in the wake of their parents' death. The Scepter being one of Gran Verge's symbols of rule, she declined it at first believing she fell far short of her father. However, following the Great Seal's destruction, her hand grasped it with the determination to prove she and her siblings could surpass him.",
                evosuper: "Liza after claiming the magistone scepter Mercurius. She enclosed herself in the barrier it created when the royal castle was razed. Although having yet to speak of the event, it is easy to imagine how she felt while helplessly watching diaster befall her nation, her people, and her siblings. Regardless, she lets her cheerful attitude shine through like a guiding star, as bright as Mercurius itself."
            }
    },
    { // Noble Flare Mira - Complete
        id: 103657112,
        name: "Noble Flare Mira",
        translate: "Searing Petal Mira",
        attribute: "Fire",
        rank: `S`,
        type: "Human",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103656112.png",
                detailsuper: "/db/Units/Detail/unit_detail_103657112.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_365_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103656112.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103657112.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_365_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/healLB.png",
                slot32type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7216,
                hpplus: 1362,
                atk: 3900,
                atkplus: 674,
                def: 3024,
                defplus: 626
            },
        skillset: 
            {
                skill: `12s CT - 2500% Fire DMG (PHY). Increase own Arts by 30.`,
                skillbreak: 500,
                arts: `20000% Fire DMG (PHY). For 20s, reduce Enemies' PHY RES by 60%.`,
                artsbreak: 1000,
                truearts: `60000% Fire DMG (PHY). For 17s, increase Allies' Arts by 6/s. Increase Fire Allies' Arts by 8/s instead.`,
                trueartsbreak: 2200,
                superarts: `160000% Fire DMG (PHY). For 30s, increase own Critical Rate and ATK by 100%.`,
                superartsbreak: 2400
            },
        passive: 
            {
                ability1: `Increase own Super Critical Rate by 50% against Enemies under 50% HP.`,
                ability2: `Increase Poison RES 80%.`,
                ability3: `Increase DMG by 50% against Enemies under 30% HP.`,
                ability4: `Unit EXP UP 20% UP. (When Noble Flare Mira's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Noble Flare Mira's SP. Art is owned)`
            },
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_130853.png",
                    mat2amt: 220,
                    hover2: "Flametail Soulgem"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: `Braise de Bamint`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_111191.png",
                skill: `45s CT - 8000% Fire DMG (PHY). For 12s, reduce Enemies' Fire RES by 40%. If User is Fire, increase own accuracy rate by 30%.`,
                skillbreak: 800,
                passive: 
                    {
                        ability1: `When equipped by Noble Flare Mira, increase own Skill CT by 10% + At the Start of a Quest, increase own Arts by 50. Does not stack with Abilities of the same name.`,
                        ability2: `Increase DMG to Enemies in BREAK by 20%.`
                    }
            },
        lore: 
            {   
                evoawk: `The Principality of Bamint's third princess garbed in the traditional attire of the nation's founder. Although normally a privilege of the eldest daughter, she is being confined for high treason, and the second-eldest is a truant. Her own talent is more than sufficient, but she still feels inferior to her siblings. Regardless, the inextinguishable fire in her heart will surely rise as bright as a phoenix.`,
                evosuper: `After dueling with the oldest sister again, she identified the emotion deep inside her heart. She was daunted but not discouraged in the least. That frustration was pure, always driving her to improve, to surpass any challenge. Her hands gripped harder. Her attacks grew stronger. Her heart burned redder, hotter, fiercer. She regained her old self-confidence.`
            }
    },
    { // Long - Missing Lore
        id: 103557212,
        name: "Long",
        attribute: "Water",
        type: "Demon",
        rank: `S`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103556212.png",
                detailsuper: "/db/Units/Detail/unit_detail_103557212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103556212.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103557212.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/physLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7056,
                hpplus: 1333,
                atk: 3725,
                atkplus: 646,
                def: 3234,
                defplus: 666
            },
        skillset: 
            {
                skill: `10s CT - 2500% Water DMG (PHY). Increase own Arts by 30.`,
                skillbreak: 600,
                arts: `27000% Water DMG (PHY). 80% chance to inflict Disease for 10s if enemy is Bleeding.`,
                artsbreak: 1000,
                truearts: `80000% Water DMG (PHY). 100% chance to inflict Bleed. If the enemy is already Bleeding, increase its Bleed level by 1 (MAX: 3).`,
                trueartsbreak: 1800,
                superarts: `180000% Water DMG (PHY). For 25s, increase own ATK by 100% and reduce enemies' Water RES by 100%.`,
                superartsbreak: 2500
            },
        passive: 
            {
                ability1: `Increase own DMG by 150% against enemies with Bleed Lv.2 or higher.`,
                ability2: `Increase own Accuracy by 100% against enemies with Bleed Lv.3.`,
                ability3: `Increase own Blind RES by 80%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_132152.png",
                    mat2amt: 220,
                    hover2: "Frostsprite Soulgem"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: `Yamata`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_111041.png",
                skill: `45s CT - 8000% Water DMG (PHY). For 10s, reduce enemies' PHY RES by 30%. If user is Water, increase own Arts gauge by 20.`,
                skillbreak: 500,
                passive: 
                    {
                        ability1: `When equipped by Long, increase own DMG by 40%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase PHY RES by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    { // Miranda (Valentines) - Complete
        id: 103667412,
        name: "Miranda (Valentines)",
        translate: "Valentines Miranda",
        attribute: "Light",
        rank: `A+`,
        type: "Human",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103666412.png",
                detailsuper: "/db/Units/Detail/unit_detail_103667412.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_366_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103666412.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103667412.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_366_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6927,
                hpplus: 1309,
                atk: 3486,
                atkplus: 607,
                def: 3452,
                defplus: 707
            },
        skillset: 
            {
                skill: `12s CT - Heal all allies' HP by 1000. For 6s, increase own Arts by 7/s.`,
                skillbreak: 0,
                arts: `20000% Light DMG (PHY). For 60s, reduce Enemies' Status RES by 30%.`,
                artsbreak: 600,
                truearts: `66666% Light DMG (PHY). 100% chance to inflict Poison for 20s. For 25s, Allies ignore 100% of Enemies' DEF.`,
                trueartsbreak: 1500,
                superarts: `144444% Light DMG (PHY). For 20s, reduce Enemies' PHY RES by 80%. If enemy is Poisoned when Super Arts is used, reduce Enemies' PHY RES by 160% instead.`,
                superartsbreak: 1600
            },
        passive: 
            {
                ability1: `Increase own DMG by 120% against Poisoned Enemies.`,
                ability2: `Increase own Disease RES by 70%.`,
                ability3: `Increase Healing Taken by 10%.`,
                ability4: `Unit EXP UP 20% UP. (When Valentines Miranda's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Valentines Miranda's SP. Art is owned)`
            },
        passiveJP: 
            {
                ability1: `Increase own DMG by 120% against Poisoned Enemies.`,
                ability2: `Increase own Disease RES by 70%.`,
                ability3: `Increase Healing Taken by 10%.`
            },
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131354.png",
                    mat2amt: 220,
                    hover2: "Eternaglint Soulgem"
                }
            },
        lore: 
            {   
                evoawk: `A teacher from Simon's Hill. Gifted with brains and beauty, she is perfect at first glance. However, her cooking, as fearsome as an enchanted bullet, can make the average stomach revolt with a single bite. She decided to try making chocolates for Valentine's Day, oblivious to the threat her products would pose.`,
                evosuper: `To get into the mood of the occasion, she tried on her old school uniform. It fit surprisingly well despite not having worn it in over a decade. Reminiscing in front of the mirror, scenes and emotions from her time as a student returned as clear as day. She then resumed making chocolates. Her feelings for him had not changed, and she would prove it.`
            }
    },
    { // Priscilla (Valentines) - Complete
        id: 103677112,
        name: "Priscilla (Valentines)",
        translate: "Valentines Priscilla",
        attribute: "Fire",
        rank: "SS",
        type: "Human",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103676112.png",
                detailsuper: "/db/Units/Detail/unit_detail_103677112.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_367_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103676112.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103677112.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_367_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/healLB.png",
                slot32type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 8242,
                hpplus: 1423,
                atk: 2857,
                atkplus: 569,
                def: 2986,
                defplus: 589
            },
        skillset: 
            {
                skill: `13s CT - 1300% Fire DMG (PHY). For 6s, increase Allies' accuracy by 20%.`,
                skillbreak: 400,
                arts: `Heal Allies by 50% of their Max HP + Cure Allies' status ailments (except Faint and Disease).`,
                artsbreak: 0,
                truearts: `40000% Fire DMG (PHY). For 25s, reduce Enemies' Fire RES by 50% and increase >50% HP Allies' Arts by 4/s.`,
                trueartsbreak: 1200,
                superarts: `80000% Fire DMG (PHY). For 30s, increase Allies' PHY DMG by 150% and DMG RES by 30%.`,
                superartsbreak: 1500
            },
        passive: 
            {
                ability1: `Increase own Arts by 4/s. If own HP is 80% or above, increase own Freeze & Paralysis RES by 100%.`,
                ability2: `Increase own Accuracy by 30%.`,
                ability3: `Increase Allies' Fire DMG by 30%. Does not stack with Abilities of the same name.`,
                ability4: `Unit EXP UP 20% UP. (When Valentines Priscilla's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Valentines Priscilla's SP. Art is owned)`
            }, 
        passiveJP: 
            {
                ability1: `Increase own Arts by 4/s. If own HP is 80% or above, increase own Freeze & Paralysis RES by 100%.`,
                ability2: `Increase own Accuracy by 30%.`,
                ability3: `Increase Allies' Fire DMG by 30%. Does not stack with Abilities of the same name.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_130853.png",
                    mat2amt: 220,
                    hover2: "Flametail Soulgem"
                }
            },
        lore: 
            {   
                evoawk: `With Valentine's Day around the corner, Priscilla was more concerned about her appearance than usual. Like every other adolescent girl, she stood before the mirror fighting her bangs for an hour in hopes of drawing the attention of her childhood friend. That effort would sadly go unrewarded; boys pay little heed to a girl's changes -- doubly so for one as oblivious as Hart. She can only pray for better luck next year.`,
                evosuper: `"If I'm afraid of getting hurt, I'll never succeed!" Miranda's talk with Priscilla steeled her resolve. Even if the road was fraught with thorns, even if her enemy was as foreboding as a game's final boss, she would forge ahead with full force. She would proudly share her precious feelings with her precious friend. Miranda was a role model, but she was also her H(e)art's rival.`
            }
    },
    { // Aristela Orbis - Missing Lore
        id: 103687212,
        name: "Aristela Orbis",
        attribute: "Water",
        type: "Spirit",
        rank: "A+",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103686212.png",
                detailsuper: "/db/Units/Detail/unit_detail_103687212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103686212.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103687212.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 8510,
                hpplus: 1530,
                atk: 2970,
                atkplus: 590,
                def: 2760,
                defplus: 530
            },
        skillset: 
            {
                skill: `13s CT - 2000% Water DMG (MAG). For 7s, reduce Enemies' evasion by 20%.`,
                skillbreak: 800,
                arts: `17000% Water DMG (MAG). For 15s, increase Allies' Arts by 4/s.`,
                artsbreak: 2000,
                truearts: `40000% Water DMG (MAG). For 15s, reduce Enemies' MAG RES and Break RES by 80%.`,
                trueartsbreak: 8000,
                superarts: `80000% Water DMG (MAG). Each time Super Arts is used, permanently increase Water Allies' DMG by 60% (up to 300%). For 15s, increase Allies' stats by 30%.`,
                superartsbreak: 3000
            },
        passive: 
            {
                ability1: `Increase DMG and Break Power against Enemies over 50% HP by 30%.`,
                ability2: `Increase DMG against Enemies in BREAK by 70%.`,
                ability3: `Increase Poison RES by 80%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_132152.png",
                    mat2amt: 220,
                    hover2: "Frostsprite Soulgem"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: `Happiness Trigger`,
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_111231.png",
                skill: `45s CT - For 12s, increase Allies' Arts by 3/s and increase Water Allies' Break Power by 40%.`,
                skillbreak: 500,
                passive: 
                    {
                        ability1: `When equipped by Aristela Orbis, increase own Arts by 3/s and DMG by 50%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own MAG DMG by 10%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    { // Demon Lord Vox - Complete
        id: 103697512,
        name: "Demon Lord Vox",
        translate: "Demon King Vox",
        attribute: "Dark",
        type: "Demon",
        rank: "S",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103696512.png",
                detailsuper: "/db/Units/Detail/unit_detail_103697512.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_369_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103696512.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103697512.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_369_1.png",
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 5,
                slot31type: "/db/Mines/thumbnail/healLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7370,
                hpplus: 1390,
                atk: 4000,
                atkplus: 690,
                def: 2856,
                defplus: 594
            },
        skillset: 
            {
                skill: `15s CT - 4000% Dark DMG (PHY). Increase own Arts gauge by 50.`,
                skillbreak: 600,
                arts: `20000% Dark DMG (MAG). Increase Allies' Arts by 20. For 10s, reduce Enemies' Dark RES by 60%.`,
                artsbreak: 1100,
                truearts: `70000% Dark DMG (PHY). For 30s, increase Allies' Critical Rate by 100%. Increase own Super Critical Rate by 50% for duration of True Arts.`,
                trueartsbreak: 1800,
                superarts: `150000% Dark DMG (PHY). For 50s, increase Demon Allies' stats and accuracy by 60%.`,
                superartsbreak: 2200
            },
        passive: 
            {
                ability1: `Increase own Arts by 1/s when Near-Death.`,
                ability2: `Heal own HP by 1% of DMG dealt to Enemies and increase Demon Allies' Arts gauge by 3/s. Does not stack with Abilities of the same name.`,
                ability3: `Increase own Status RES by 30%.`,
                ability4: `Unit EXP UP 20% UP. (When Demon Lord Vox's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Demon Lord Vox's SP. Art is owned)`
            },
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131455.png",
                    mat2amt: 220,
                    hover2: "Sinflow Soulgem"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: `Maken Zanketsu`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_111361.png",
                skill: `35s CT - 13000% Dark DMG (PHY). For 8s, reduce enemies' PHY RES and Critical RES by 30%.`,
                skillbreak: 2000,
                passive: 
                    {
                        ability1: `When equipped by Demon Lord Vox, increase own Skill CT Speed and Equip CT Speed by 15%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase ATK by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "To protect both Juno and her country, Vox tapped into the forbidden power of Auge. This amplified strength came with amplified arrogance and aggression as well. Although he is clearly no longer the same captain of the Templars, his core remains untouched along with his oath to the late queen.",
                evosuper: "Vox of Granadia after obtaining the might of Auge. Juno's blood allowed him to harness it, but his own fortitude prevented that evil influence from taking over after her blood ran out. He is sustained by his oath to the queen, driven to fight in defense of what she left behind."
            }
    },
    { // Jay - Missing Lore
        id: 103707212,
        name: "Jay",
        attribute: "Water",
        type: "Human",
        rank: "SS",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103706212.png",
                detailsuper: "/db/Units/Detail/unit_detail_103707212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103706212.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103707212.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/physLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7155,
                hpplus: 1351,
                atk: 3975,
                atkplus: 686,
                def: 3032,
                defplus: 627
            },
        skillset: 
            {
                skill: `10s CT - 2300% Water DMG (PHY). For 7s, reduce Enemies' evasion by 10%.`,
                skillbreak: 400,
                arts: `20000% Water DMG (PHY). For 20s, increase Water Allies' DMG by 50%. If used within first 120s from the Start of the Quest, increase Allies' Arts by 20.`,
                artsbreak: 1000,
                truearts: `70000% Water DMG (PHY). For 30s, reduce Enemies' DMG RES by 40%. If used when own HP is 90% or more, reduce Enemies' DMG RES by 80% instead.`,
                trueartsbreak: 2000,
                superarts: `150000% Water DMG (PHY). For 60s, increase own DMG by 50% and own Arts by 2/s (stackable). If used within first 120s from the Start of the Quest, increase own DMG by 100% and own Arts by 4/s (stackable) instead.`,
                superartsbreak: 4000
            },
        passive: 
            {
                ability1: `At the start of a Battle, increase own Arts by 200.`,
                ability2: `Increase own Paralysis RES by 80%.`,
                ability3: `When set as the Leader of the party, reduces Allies' DMG taken by 10%. When not set as the Leader of the party, increase own accuracy by 60%. Does not stack with Abilities of the same name.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_132152.png",
                    mat2amt: 220,
                    hover2: "Frostsprite Soulgem"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: `Neon Bayonet`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_111391.png",
                skill: `45s CT - 7500% Water DMG (PHY). For 10s, increase own Water DMG by 50% and reduce Enemies' PHY RES by 30%.`,
                skillbreak: 500,
                passive: 
                    {
                        ability1: `When equipped by Jay, increase own DMG and Break Power by 30%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase PHY RES by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    { //Canary - Missing Lore (JP)
        id: 103717212,
        name: `Canary`,
        attribute: `Water`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103716212.png",
                detailsuper: "/db/Units/Detail/unit_detail_103717212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103716212.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103717212.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/physLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7269,
                hpplus: 1342,
                atk: 3430,
                atkplus: 659,
                def: 3221,
                defplus: 610
            },
        skillset: 
            {
                skill: `12s CT - 1800% Current Element DMG (PHY). Increase own Arts gauge by [(Maximum Arts gauge - Current Arts gauge)/2] (up to 150).`,
                skillbreak: 500,
                arts: `15000% Current Element DMG (PHY). Changes own Element in the order of (Water -> Earth -> Fire -> Water...) [Starts Quest as Water Element].
                For 30s, increase own DMG by 50% & Arts gauge by 3/s.`,
                artsbreak: 1000,
                truearts: `60000% Current Element DMG (PHY).\n
                If own element is Water: 100% Chance to inflict Disease for 25s.\n
                If own element is Earth: 100% Chance to inflict Poison for 25s.\n
                If own element is Fire: 100% Chance to inflict Burn for 25s.`,
                trueartsbreak: 2000,
                superarts: `130000% Current Element DMG (PHY). For 30s, reduce enemies' PHY RES by 100% (Stackable).`,
                superartsbreak: 2600
            },
        passive: 
            {
                ability1: `Impact Quake: Increase own DMG by 100% against enemies with Status Ailment. When own HP is 80% or above, increase own Equipment CT Speed by 20%.`,
                ability2: `Increase own Status RES by 100% (Except Faint).`,
                ability3: `Ignore Enemies' DEF by 15%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_132152.png",
                    mat2amt: 220,
                    hover2: "Frostsprite Soulgem"
                }
            },
        trueweapon: 
            {
                name: `KAGEGIRI`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_111801.png",
                skill: `20s CT - 4000% Water DMG (PHY). For 10s, reduce enemies' PHY RES by 30%. If user is Human, increase own Arts gauge by 10. `,
                skillbreak: 500,
                passive: 
                    {
                        ability1: `When equipped by Canary, increase own ATK by 100% & Skill CT by 20%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own PHY Evasion by 5%`
                    }
            },
        lore: 
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Luana - Missing Lore
        id: 103337412,
        name: `Luana`,
        attribute: `Light`,
        type: `Human`,
        rank: `A`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103336412.png",
                detailsuper: "/db/Units/Detail/unit_detail_103337412.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_333_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103336412.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103337412.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_333_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/healLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7492,
                hpplus: 1412,
                atk: 3815,
                atkplus: 660,
                def: 2856,
                defplus: 594
            },
        skillset: 
            {
                skill: `11s CT - 3500% Light DMG (MAG). For 10s, Increase own Accuracy Rate by 30%.`,
                skillbreak: 400,
                arts: `23000% Light DMG (MAG). For 30s, Increase own ATK by 100% & Increase all allies' PHY & MAG Equipment CT REC by 30%.`,
                artsbreak: 800,
                truearts: `62000% Light DMG (MAG). Increase the damage of allies' next Arts, True Arts, Super Arts, Cross Arts, Phantom Bullet, or Mega Arts by 180%. (Only Once)`,
                trueartsbreak: 1200,
                superarts: `170000% Light DMG (MAG). For 15s, Reduce enemies' Light Res by 100%. If enemy is below 30% HP, reduce Light Res by 200% instead.`,
                superartsbreak: 1800
            },
        passive: 
            {
                ability1: `Increase own DMG by [40% x Human Allies] (Max 160%) `,
                ability2: `When self is Leader, increase all Allies' Max HP by 30%.`,
                ability3: `Increase own Curse RES by 100%.`,
                ability4: `Unit EXP UP 20% UP. (When Luana's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Luana's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131354.png",
                    mat2amt: 220,
                    hover2: "Eternaglint Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Millenium Arc`,
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_111491.png",
                skill: `45s CT - 7000% Light DMG (MAG). For 10, Increase own Magic DMG & DMG to Giant Bosses by 35%.`,
                skillbreak: 500,
                passive: 
                    {
                        ability1: `When equipped by Luana, Increase own Arts by 4/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own Skill DMG by 20%`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    { //Summer Iris - Missing Lore + JP ver
        id: 103727112,
        name: `Summer Iris`,
        translate: `Iris, Bursting Jubilation`,
        attribute: `Fire`,
        type: `God`,
        rank: `S`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103726112.png",
                detailsuper: "/db/Units/Detail/unit_detail_103727112.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_372_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103726112.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103727112.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_372_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/healLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7312,
                hpplus: 1350,
                atk: 3234,
                atkplus: 625,
                def: 3306,
                defplus: 625
            },
        skillset: 
            {
                skill: `12s CT - Increase own Arts gauge by 50% of the difference between her current Arts gauge and Maximum Arts gauge. (MAX: 150)`,
                skillbreak: 0,
                arts: `17000% Fire DMG (MAG). For 20s, increase all allies' Skill CT Speed by 70% and all Fire allies' Skill CT Speed by an additional 50%.`,
                artsbreak: 1000,
                truearts: `60000% Fire DMG (MAG). For 25s, reduce enemies' MAG RES by 80%.`,
                trueartsbreak: 1700,
                superarts: `150000% Fire DMG (MAG). For 7s, increase all Fire allies' Arts gauge by 10. For 15s, increase all allies' Arts DMG based on how high this unit's current remaining HP is (MAX: 250%).`,
                superartsbreak: 2500
            },
        skillsetJP: 
            {
                skill: `12s CT - Increase own Arts gauge by 50% of the difference between her current Arts gauge and Maximum Arts gauge. (MAX: 150)`,
                skillbreak: 0,
                arts: `17000% Fire DMG (MAG). For 20s, increase all allies' Skill CT Speed by 70% and all Fire allies' Skill CT Speed by an additional 50%.`,
                artsbreak: 1000,
                truearts: `60000% Fire DMG (MAG). For 25s, reduce enemies' MAG RES by 80%.`,
                trueartsbreak: 1700,
                superarts: `165000% Fire DMG (MAG). For 10s, increase all Fire allies' Arts gauge by 10. For 30s, increase all allies' Arts DMG based on how high this unit's current remaining HP is (MAX: 250%).`,
                superartsbreak: 2500
            },
        passive: 
            {
                ability1: `Increase all allies' DMG RES by 15% and all Fire allies' DMG RES by an additional 15% (No Duplication).`,
                ability2: `Increase DMG during Break by 20%.`,
                ability3: `Increase DMG against Giant Bosses by 30%.`,
                ability4: `Unit EXP UP 20% UP. (When Summer Iris' SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Summer Iris' SP. Art is owned)`
            },
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131354.png",
                    mat2amt: 220,
                    hover2: "Flametail Soulgem"
                }
            },
        trueweaponJP: 
            {
                name: `Iris' Dream Rocket`,
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_112551.png",
                skill: `50s CT - 8000% Fire DMG (MAG). For 8s, reduce enemies' Fire RES by 40%. Additionally if user is God, for 8s, reduce enemies' Evasion rate by 30%.`,
                skillbreak: 400,
                passive: 
                    {
                        ability1: `When equipped by Summer Iris, increase own Equip CT Speed by 20%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own ATK by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    { //Summer Fen - Missing Lore + JP ver
        id: 103736212,
        name: `Summer Fen`,
        translate: `Wave Master Fen`,
        attribute: `Water`,
        type: `Human`,
        rank: `A+`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103736212.png",
                detailsuper: "/db/Units/Detail/unit_detail_103737212.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_373_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103736212.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103737212.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_373_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/suppLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7762,
                hpplus: 1430,
                atk: 3285,
                atkplus: 634,
                def: 3137,
                defplus: 595
            },
        skillset: 
            {
                skill: `10s CT - 2400% Water DMG (PHY). Heal allies' HP by 15%.`,
                skillbreak: 400,
                arts: `15000% Water DMG (PHY). For 20s, increase own Heal Equipment CT Speed by 40% & Heal Amount by 60%.`,
                artsbreak: 900,
                truearts: `75000% Water DMG (PHY). For 25s, reduce enemies' CRI RES by 100% & Evasion rate by 40%.`,
                trueartsbreak: 1700,
                superarts: `120000% Water DMG (PHY). For 30s, reduce enemies' Water RES by 100%. For 60s, increase own Arts gauge by 8.`,
                superartsbreak: 2100
            },
        skillsetJP: 
            {
                skill: `10s CT - 2400% Water DMG (PHY). Heal allies' HP by 20%.`,
                skillbreak: 400,
                arts: `15000% Water DMG (PHY). For 20s, increase own Heal Equipment CT Speed by 40% & Heal Amount by 60%.`,
                artsbreak: 900,
                truearts: `75000% Water DMG (PHY). For 25s, reduce enemies' CRI RES by 100% & Evasion rate by 50%.`,
                trueartsbreak: 1700,
                superarts: `130000% Water DMG (PHY). For 30s, reduce enemies' Water RES by 150%. For 60s, increase own Arts gauge by 8.`,
                superartsbreak: 2100
            },
        passive: 
            {
                ability1: `At the Start of Quest, increase own Arts gauge by 100 and Increase own Arts gauge by 1 every time this unit is Hit.`,
                ability2: `Increase own Burn RES by 100%.`,
                ability3: `Increase own CRI DMG by 150%.`,
                ability4: `Unit EXP UP 20% UP. (When Summer Fen's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Summer Fen's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_132152.png",
                    mat2amt: 220,
                    hover2: "Frostsprite Soulgem"
                }
            },
        trueweaponJP: 
            {
                name: `Fen's Wavecutter`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_112561.png",
                skill: `50s CT - 8000% Water DMG (PHY). For 10s, reduce enemies' Water RES by 30%. If user is Human, reduce enemies' Water RES by 50% instead.`,
                skillbreak: 500,
                passive: 
                    {
                        ability1: `When equipped by Summer Fen, increase own stats by 20% and Healing Amount by 15% (excluding Lifesteal). Does not stack with Abilities of the same name.`,
                        ability2: `Increase own DEF by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    { //Summer Riviera - Missing Lore (JP)
        id: 103747112,
        name: `Summer Riviera`,
        attribute: `Fire`,
        type: `Demon`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103746112.png",
                detailsuper: "/db/Units/Detail/unit_detail_103747112.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_374_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103746112.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103747112.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_374_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/defLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7590,
                hpplus: 1430,
                atk: 4087,
                atkplus: 704,
                def: 2643,
                defplus: 553
            },
        skillset: 
            {
                skill: `12s CT - 3200% Fire DMG (PHY). For 3s, increase all Allies' Arts by 8/s.`,
                skillbreak: 700,
                arts: `25000% Fire DMG (PHY). For 20s, increase Equipment CT REC of own PHY Equipment and own ATK by 50%.`,
                artsbreak: 1500,
                truearts: `68000% Fire DMG (PHY). For 40s, Allies ignore 100% of Enemies' DEF + reduce Enemies' Fire RES by 100%.`,
                trueartsbreak: 2000,
                superarts: `180000% Fire DMG (PHY). During Super Arts, increase own DMG by 100% for each Ally above 90% HP (up to 400%).`,
                superartsbreak: 2700
            },
        passive: 
            {
                ability1: `When Summer Riviera is near death, recover Allies' HP by 100% HP + For 20s, Allies gain a DMG barrier that negates 5000 DMG. Can only occur once per Quest.`,
                ability2: `Increase DMG to Water Enemies by 30%.`,
                ability3: `Increase Freeze RES by 80%.`,
                ability4: `Unit EXP UP 20% UP. (When Summer Riviera's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Summer Riviera's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_130853.png",
                    mat2amt: 220,
                    hover2: "Flametail Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Riviera's Dynamite Attack`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_112581.png",
                skill: `50s CT - 6000% Fire DMG (PHY). For 15s, increase own Fire DMG by 50%. If user is Demon, increase own Fire DMG by 80% instead.`,
                skillbreak: 600,
                passive: 
                    {
                        ability1: `When equipped by Summer Riviera, increase own Arts by 3/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own MAG RES by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Summer Rosetta - Missing Lore (JP)
        id: 103757512,
        name: `Summer Rosetta`,
        attribute: `Dark`,
        type: `Demon`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103756512.png",
                detailsuper: "/db/Units/Detail/unit_detail_103757512.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_375_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103756512.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103757512.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_375_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/healLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7987,
                hpplus: 1470,
                atk: 3487,
                atkplus: 670,
                def: 2599,
                defplus: 502
            },
        skillset: 
            {
                skill: `11s CT - 2300% Dark DMG (MAG). Increase own Arts by 20. If own HP is above 80%, increase own Arts by 40 instead.`,
                skillbreak: 700,
                arts: `18000% Dark DMG (MAG). For 20s, increase Allies' stats by 30%.`,
                artsbreak: 1200,
                truearts: `50000% Dark DMG (MAG). For 25s, reduce Enemies' MAG RES by 90% and increase >50% HP Allies' Arts gauge by 4/s.`,
                trueartsbreak: 1800,
                superarts: `140000% Dark DMG (MAG). For 20s, increase Dark Allies' DMG by 150% and Demon Allies' DMG by 150%.`,
                superartsbreak: 2500
            },
        passive: 
            {
                ability1: `When unafflicted with any Status ailment, increase Allies' Heal Equip's CT Speed by 30% and Heal Equip's Heal Amount by 60% (Excluding Lifesteal). Does not stack with Abilities of the same name.`,
                ability2: `Increase own DMG by [2% × own ATK/100].`,
                ability3: `Increase Paralysis RES by 80%.`,
                ability4: `Unit EXP UP 20% UP. (When Summer Rosetta's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Summer Rosetta's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131455.png",
                    mat2amt: 220,
                    hover2: "Sinflow Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Rosetta's Innerwear (Slim)`,
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_112571.png",
                skill: `35s CT - For 10s, increase all allies' Arts by 3/s and Demon allies' stats by 15%.`,
                skillbreak: 0,
                passive: 
                    {
                        ability1: `When equipped by Summer Rosetta, increase own Arts by 100 at Start of Quest. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own DEF by 10%.`
                    }
            },
        lore: 
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Goddess Celia - Missing Lore
        id: 103767412,
        name: `Goddess Celia`,
        translate: `Celia the Exalted`,
        attribute: `Light`,
        type: `Human`,
        rank: `A+`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103766412.png",
                detailsuper: "/db/Units/Detail/unit_detail_103767412.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_376_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103766412.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103767412.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_376_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/defLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7370,
                hpplus: 1390,
                atk: 3937,
                atkplus: 680,
                def: 2887,
                defplus: 600
            },
        skillset: 
            {
                skill: `10s CT - 3000% Light DMG (PHY). Summon a kin with 1000 HP (MAX: 4). Summoned kins will redirect all DMG taken by Goddess Celia to themselves.`,
                skillbreak: 400,
                arts: `20000% Light DMG (PHY). Increase all Human allies' Arts gauge by 30. For 20s, reduce enemies' Light RES by 30%.`,
                artsbreak: 800,
                truearts: `60000% Light DMG (PHY). Summon 4 kins with 1000 HP (MAX: 4). Summoned kins will redirect all DMG taken by Goddess Celia to themselves.`,
                trueartsbreak: 1600,
                superarts: `160000% DMG (PHY). Sacrifice all kins. Increase own CRI rate by 100% for the duration of Super Arts and own Super CRI rate by [20 × # of kins sacrificed from current Super Arts]% (MAX: 80%) for the duration of Super Arts.`,
                superartsbreak: 2000
            },
        passive: 
            {
                ability1: `For each kin killed or sacrificed throughout battle, increase own DMG by 30% (MAX: 600%). Does not stack with Abilities of the same name.`,
                ability2: `When HP is above 90%, increase own Arts by 5/s.`,
                ability3: `Increase own Heal Amount by 10%.`,
                ability4: `Unit EXP UP 20% UP. (When Goddess Celia's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Goddess Celia's SP. Art is owned)`
            }, 
        passiveJP: 
            {
                ability1: `For each kin killed or sacrificed throughout battle, increase own DMG by 30% (MAX: 600%). Does not stack with Abilities of the same name.`,
                ability2: `When HP is above 90%, increase own Arts by 5/s.`,
                ability3: `Increase own Heal Amount by 10%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131354.png",
                    mat2amt: 220,
                    hover2: "Eternaglint Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Shangri-La`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_111871.png",
                skill: `50s CT - 12000% Light DMG (PHY). If user is Light, for 10s, reduce enemies' DMG RES by 20%. If user is Light and above 80% HP, reduce enemies' DMG RES by 35% instead.`,
                skillbreak: 800,
                passive: 
                    {
                        ability1: `When equipped by Goddess Celia, increase own DMG and Skill CT Speed by 30%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own CRI DMG by 20%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    { //Halloween Charlotte - Missing Lore + JP ver
        id: 103777112,
        name: `Halloween Charlotte`,
        translate: `Wild Side Charlotte`,
        rank: `S`,
        attribute: `Fire`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103776112.png",
                detailsuper: "/db/Units/Detail/unit_detail_103777112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103776112.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103777112.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/defLB.png",
                slot32type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 7810,
                hpplus: 1470,
                atk: 4012,
                atkplus: 692,
                def: 2513,
                defplus: 528
            },
        skillset: 
            {
                skill: `10s CT - 2200% Fire DMG (PHY). Increase own Arts by 30. If this skill crits, increase own Arts by 60 instead.`,
                skillbreak: 300,
                arts: `23000% Fire DMG (PHY). For 35s, increase own CRI DMG by 150%.`,
                artsbreak: 800,
                truearts: `70000% Fire DMG (PHY). 100% chance to inflict Bleed. If the Enemy is already Bleeding, increase its stack of Bleed by 1 (up to 3).`,
                trueartsbreak: 1800,
                superarts: `185000% Fire DMG (PHY). For 20s, reduce enemies' PHY RES by 100%. For each stack of Bleed enemy has, for 20s, reduce enemies' PHY RES by an additional 50%.`,
                superartsbreak: 2200
            },
        skillsetJP: 
            {
                skill: `10s CT - 2200% Fire DMG (PHY). Increase own Arts by 30. If this skill crits, increase own Arts by 60 instead.`,
                skillbreak: 300,
                arts: `23000% Fire DMG (PHY). For 60s, increase own CRI DMG by 150%.`,
                artsbreak: 800,
                truearts: `80000% Fire DMG (PHY). 100% chance to inflict Bleed. If the Enemy is already Bleeding, increase its stack of Bleed by 1 (up to 3).`,
                trueartsbreak: 1800,
                superarts: `185000% Fire DMG (PHY). For 20s, reduce enemies' PHY RES by 100%. For each stack of Bleed enemy has, for 20s, reduce enemies' PHY RES by an additional 70%.`,
                superartsbreak: 2200
            },
        passive: 
            {
                ability1: `Increase own CRI rate by 100% against enemies with 1 or more stacks of Bleed.`,
                ability2: `Increase own Accuracy by 100% against enemies with 3 stacks of Bleed.`,
                ability3: `Increase own Freeze RES by 80%.`
            }, 
        passiveJP: 
            {
                ability1: `Increase own CRI rate by 100% against enemies with 1 or more stacks of Bleed.`,
                ability2: `Increase own Accuracy by 150% against enemies with 3 stacks of Bleed.`,
                ability3: `Increase own Freeze RES by 80%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_130853.png",
                    mat2amt: 220,
                    hover2: "Flametail Soulgem"
                }
            },
        trueweaponJP: 
            {
                name: `Lotte's Fluffy Mitts`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_112621.png",
                skill: `50s CT - 5000% Fire DMG (PHY). If enemy is Bleeding, increase Bleed level by 1 (MAX: 3). Additionally if user is Human, for 8s, reduce enemies' Fire RES by 30%.`,
                skillbreak: 200,
                passive: 
                    {
                        ability1: `When equipped by Halloween Charlotte, increase own DMG by 30% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own ATK by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    { //Halloween Valerie - Missing Lore + JP ver
        id: 103787212,
        name: `Halloween Valerie`,
        translate: `Mighty Magical Valerie`,
        rank: `A+`,
        attribute: `Water`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103786212.png",
                detailsuper: "/db/Units/Detail/unit_detail_103787212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103786212.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103787212.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7590,
                hpplus: 1430,
                atk: 4000,
                atkplus: 690,
                def: 2688,
                defplus: 562
            },
        skillset: 
            {
                skill: `11s CT - 1700% Water DMG (MAG). Increase own Arts by 30. If self is unafflicted by Status Ailments, increase Arts by 50 instead.`,
                skillbreak: 500,
                arts: `22000% Water DMG (MAG). For 25s, increase own ATK by 120%.`,
                artsbreak: 700,
                truearts: `60000% Water DMG (MAG). For 45s, reduce enemies' MAG RES by 70% (stackable).`,
                trueartsbreak: 1500,
                superarts: `165000% Water DMG (MAG). For 20s, increase own DMG by 200% and ignore 100% of Enemies' DEF. Additionally when used for the first time, resets the cooldown of own Magic Equips.`,
                superartsbreak: 2000
            },
        skillsetJP: 
            {
                skill: `11s CT - 1700% Water DMG (MAG). Increase own Arts by 30. If self is unafflicted by Status Ailments, increase Arts by 50 instead.`,
                skillbreak: 500,
                arts: `22000% Water DMG (MAG). For 25s, increase own ATK by 120%.`,
                artsbreak: 700,
                truearts: `60000% Water DMG (MAG). For 45s, reduce enemies' MAG RES by 70% (stackable).`,
                trueartsbreak: 1500,
                superarts: `165000% Water DMG (MAG). For 20s, increase own DMG by 250% and ignore 100% of Enemies' DEF. Additionally when used for the first 2 times, resets the cooldown of own Magic Equips.`,
                superartsbreak: 2000
            },
        passive: 
            {
                ability1: `Increase all Allies' DMG RES with respect to how high own % of HP is (MAX: 30%). Does not stack with Abilities of the same name.`,
                ability2: `Increase own DMG by [1% × own ATK/100].`,
                ability3: `Increase own Paralysis RES by 80%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                    mat2amt: 220,
                    hover2: `Frostsprite Soulgem`
                }
            },
        trueweaponJP: 
            {
                name: `Magical Muscles Valerie`,
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_112631.png",
                skill: `70s CT - 7000% Water DMG (MAG). For 15s, increase own Water DMG by 30%. Additionally if user is Human, for 15s, increase all allies' MAG DMG by 40% (stackable).`,
                skillbreak: 700,
                passive: 
                    {
                        ability1: `When equipped by Halloween Valerie, increase own DMG by 30% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own ATK by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    { //Halloween Forte - Missing Lore + JP ver
        id: 103797512,
        name: `Halloween Forte`,
        translate: `Pianissimo Figure Forte`,
        rank: `S`,
        attribute: `Dark`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103796512.png",
                detailsuper: "/db/Units/Detail/unit_detail_103797512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103796512.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103797512.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/healLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7820,
                hpplus: 1410,
                atk: 3168,
                atkplus: 626,
                def: 3243,
                defplus: 614
            },
        skillset: 
            {
                skill: `13s CT - 1300% Dark DMG (MAG). For 7s, increase own Arts by 6/s.`,
                skillbreak: 700,
                arts: `16000% Dark DMG (MAG). For 20s, increase all Allies' ATK and Equip CT Speed by 40%.`,
                artsbreak: 1300,
                truearts: `43000% Dark DMG (MAG). For 18s, increase all Allies' Arts by 4/s and reduce enemies' Dark RES by 100%.`,
                trueartsbreak: 2000,
                superarts: `120000% Dark DMG (MAG). For 15s, increase all Dark allies' DMG and Break Power by 200%.`,
                superartsbreak: 3500
            },
        skillsetJP: 
            {
                skill: `13s CT - 1300% Dark DMG (MAG). For 7s, increase own Arts by 6/s.`,
                skillbreak: 700,
                arts: `16000% Dark DMG (MAG). For 25s, increase all Allies' ATK and Equip CT Speed by 40%.`,
                artsbreak: 1300,
                truearts: `43000% Dark DMG (MAG). For 25s, increase all Allies' Arts by 5/s and reduce enemies' Dark RES by 100%.`,
                trueartsbreak: 2000,
                superarts: `120000% Dark DMG (MAG). For 20s, increase all Dark allies' DMG and Break Power by 250%.`,
                superartsbreak: 3500
            },
        passive: 
            {
                ability1: `Increase all Allies' DMG by [30 x # of Unique Races in the Party]% (MAX: 120%).`,
                ability2: `Increase own Curse RES by 60%.`,
                ability3: `Increase own Seal RES by 60%.`
            }, 
        passiveJP: 
            {
                ability1: `Increase all Allies' DMG by [50 x # of Unique Races in the Party]% (MAX: 200%).`,
                ability2: `Increase own Curse RES by 60%.`,
                ability3: `Increase own Seal RES by 60%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131455.png",
                    mat2amt: 220,
                    hover2: "Sinflow Soulgem"
                }
            },
        trueweaponJP: 
            {
                name: `Forte's Dolly Dress`,
                slot: "/db/Mines/thumbnail/defLB.png",
                detail: "/db/Equipment/Detail/item_detail_112641.png",
                skill: `35s CT - For 15s, increase all allies' DMG RES by 20%. If user is Dark, increase all allies' DMG RES by 30% instead.`,
                skillbreak: 0,
                passive: 
                    {
                        ability1: `When equipped by Halloween Forte, increase HP Recovery amount by 10% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own DEF by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    { //Isliid, Human Pinnacle - Missing Lore + JP ver
        id: 103807412,
        name: "Isliid, Human Pinnacle",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103806412.png",
                detailsuper: "/db/Units/Detail/unit_detail_103807412.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_380_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103806412.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103807412.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_380_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 5,
                slot31type: "/db/Mines/thumbnail/physLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7478,
                hpplus: 1409,
                atk: 4082,
                atkplus: 703,
                def: 2731,
                defplus: 570
            },
        skillset: 
            {
                skill: `11s CT - 2500% Light DMG (PHY). Increase own Arts by 20. For 8s, increase own Paralysis RES and Freeze RES by 100%.`,
                skillbreak: 800,
                arts: `27000% Light DMG (PHY). An additional effect occurs depending on Isliid's unit position (refer to Unit Passives below).`,
                artsbreak: 1200,
                truearts: `60000% Light DMG (PHY). For 8s, increase all allies' Arts by 10/s. For 20s, reduce enemies' DMG RES by 60%. If enemy is Human, God or Demon, reduce enemies' DMG RES by 120% instead.`,
                trueartsbreak: 1800,
                superarts: `150000% Light DMG (MAG). When Super Arts is activated, reduce own remaining Equip CT by half. For 20s, increase Allies unaffected by Status Ailments' Equipment CT Speed by 30%.`,
                superartsbreak: 2000
            },
        passive: 
            {
                ability1: `If unaffected by Status Ailments, increase own Arts by 7/s.`,
                ability2: `When Arts is used while Isliid is in the 1st unit position, increase Allies' Magic Equip CT Speed by 30% and own DMG against Gods by 180% for 25s.`,
                ability3: `When Arts is used while Isliid is in the 2nd unit position, increase Allies' Support Equip CT Speed by 30% and own DMG against Humans by 180% for 25s.`,
                ability4: `When Arts is used while Isliid is in the 3rd unit position, increase Allies' Physical Equip CT Speed by 30% and own DMG against Demons by 180% for 25s.`,
                ability5: `When Arts is used while Isliid is in the 4th unit position, effect rotates between the 1st, 2nd and 3rd unit positions.`,
                ability6: `Unit EXP UP 20% UP. (When Isliid, Human Pinnacle's SP. Art is owned)`,
                ability7: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Isliid, Human Pinnacle's SP. Art is owned)`
            }, 
        passiveJP: 
            {
                ability1: `If unaffected by Status Ailments, increase own Arts by 7/s.`,
                ability2: `When Arts is used while Isliid is in the 1st unit position, increase Allies' Magic Equip CT Speed by 30% and own DMG against Gods by 180% for 25s.`,
                ability3: `When Arts is used while Isliid is in the 2nd unit position, increase Allies' Support Equip CT Speed by 30% and own DMG against Humans by 180% for 25s.`,
                ability4: `When Arts is used while Isliid is in the 3rd unit position, increase Allies' Physical Equip CT Speed by 30% and own DMG against Demons by 180% for 25s.`,
                ability5: `When Arts is used while Isliid is in the 4th unit position, effect rotates between the 1st, 2nd and 3rd unit positions.`
            }, 
        evolution:
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                    mat2amt: 220,
                    hover2: `Eternaglint Soulgem`,
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                true1: 
                {
                    name: "Rune Sovereign",
                    slot: "/db/Mines/thumbnail/magLB6.png",
                    thumb: "/db/Equipment/Thumbnail/item_thumbnail_111971.png",
                    detail: "/db/Equipment/Detail/item_detail_111971.png",
                    skill: "50s CT - 10000% Light DMG (MAG). Increase own MAG DMG By 50% and reduce enemies' MAG RES by 30% (stackable) for 20s.",
                    skillbreak: 500,
                    passive: 
                        {
                            ability1: "When equipped by Isliid, Human Pinnacle, increase own DMG by 80%. Does not stack with Abilities of the same name.",
                            ability2: "Increase own MAG RES by 5%."
                        }
                },
                true2: 
                {
                    name: "Komplettes Trio",
                    slot: "/db/Mines/thumbnail/physLB.png",
                    thumb: "/db/Equipment/Thumbnail/item_thumbnail_111981.png",
                    detail: "/db/Equipment/Detail/item_detail_111981.png",
                    skill: "50s CT - 8000% Light DMG (PHY). Increase own Light DMG by 40% (stackable) for 30s.",
                    skillbreak: 500,
                    passive: 
                        {
                            ability1: "When equipped by Isliid, Human Pinnacle, increase own ATK by 50%. Does not stack with Abilities of the same name.",
                            ability2: "Increase own PHY RES by 5%."
                        }
                }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    { //Anti-Heroine Pola - Missing Lore
        id: 103817312,
        name: `Anti-Heroine Pola`,
        translate: `Evil Pola`,
        rank: "A+",
        attribute: `Earth`,
        type: `Demon`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103816312.png",
                detailsuper: "/db/Units/Detail/unit_detail_103817312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103816312.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103817312.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/defLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 8050,
                hpplus: 1450,
                atk: 3080,
                atkplus: 610,
                def: 3105,
                defplus: 590
            },
        skillset: 
            {
                skill: `12s CT - 2500% Earth DMG (PHY). Cure all allies' Poison. Increase allies cured by this Skill's Arts by 60.`,
                skillbreak: 600,
                arts: `16000% Earth DMG (PHY). For 15s, increase all allies' stats by 30% and reduce all allies' DMG Taken from Poisoned enemies by 30%.`,
                artsbreak: 1000,
                truearts: `50000% Earth DMG (PHY). For 30s, increase >50% HP Allies' Arts by 4/s and reduce enemies' Poison RES by 50%.`,
                trueartsbreak: 2000,
                superarts: `150000% Earth DMG (PHY). 100% Chance to inflict Poison for 30s. For 30s, increase allies' PHY DMG with respect to how high own % of HP is (MAX: 150%).`,
                superartsbreak: 3200
            },
        passive: 
            {
                ability1: `Increase own Paralysis RES by 100%.`,
                ability2: `Increase own Disease RES by 100%.`,
                ability3: `Increase own DMG and Break Power against Poisoned enemies by 100%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                    mat2amt: 220,
                    hover2: `Smogdragon Soulgem`
                }
            },
        trueweapon: 
            {
                name: `Radical Edge`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_112011.png",
                skill: `40s CT - 6000% Earth DMG (PHY). 70% Chance to inflict Poison for 12s. If user is Earth, for 12s, increase own DMG by 35%.`,
                skillbreak: 600,
                passive: 
                    {
                        ability1: `When equipped by Anti-Heroine Pola, increase own Arts by 3/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own ATK by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: `Missing Lore.`,
                evosuper: `Missing Lore.`
            }
    },
    { //Holy Knight Roy - Missing Lore (JP)
        id: 103827212,
        name: `Holy Knight Roy`,
        translate: `HKRoy`,
        attribute: `Water`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103826212.png",
                detailsuper: "/db/Units/Detail/unit_detail_103827212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103826212.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103827212.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/healLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7480,
                hpplus: 1410,
                atk: 3875,
                atkplus: 670,
                def: 2835,
                defplus: 590
            },
        skillset: 
            {
                skill: `12s CT - 3000% Water DMG (PHY). Heals own Status ailments (except Faint, Paralysis, Seal, Freeze).`,
                skillbreak: 800,
                arts: `23000% Water DMG (PHY). 80% chance to inflict Ultimate Freeze for 7s.`,
                artsbreak: 1500,
                truearts: `60000% Water DMG (PHY). For 30s, increase Water allies' Break Power by 100%.`,
                trueartsbreak: 3300,
                superarts: `180000% Water DMG (PHY). For 20s, increase all allies' Arts by 7/s and PHY DMG by 150%.`,
                superartsbreak: 4000,
                phantombullet: "Can be activated instead of Super Arts - 250000% Water DMG (PHY). For 100s, reduce enemies' PHY RES by 120%. When used for the first time, increase all allies' Arts by 200. When used for the second time, heal all allies' HP by 100% (Can only be used 2 times per Quest).",
                phantombulletbreak: 8000
            },
        passive: 
            {
                ability1: `When self is Leader, at the start of a Quest, increase Allies' PHY RES by 50% for 30s. Does not stack with Abilities of the same name.`,
                ability2: `When above 80% HP, increase own Arts by 4/s.`,
                ability3: `Increase own Paralysis RES by 80%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                    mat2amt: 220,
                    hover2: `Frostsprite Soulgem`,
                }
            },
        trueweapon: 
            {
                name: `Gladius`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_112051.png",
                skill: `50s CT - 10000% Water DMG (PHY). For 10s, increase own PHY DMG by 50%. Additionally if user is Water, for 10s, increase own ATK by 50%.`,
                skillbreak: 1000,
                passive: 
                    {
                        ability1: `When equipped by Holy Knight Roy, increase own Arts by 2/s. At the start of a Quest, increase own Arts by 100. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own Accuracy by 10%.`
                    }
            },
        lore: 
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Marlet - Missing Lore (JP)
        id: 103837412,
        name: `Marlet`,
        translate: `Sound Princess Marlet`,
        attribute: `Light`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103836412.png",
                detailsuper: "/db/Units/Detail/unit_detail_103837412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103836412.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103837412.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/defLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7150,
                hpplus: 1350,
                atk: 4012,
                atkplus: 692,
                def: 3017,
                defplus: 624
            },
        skillset: 
            {
                skill: `13s CT - 2600% Light DMG (PHY). Increase own Arts by 20. If used when own HP is above 80%, increase own Arts by 40 instead.`,
                skillbreak: 800,
                arts: `22000% Light DMG (PHY). For 25s, increase all allies' Equipment DMG by 100% and PHY Equipment CT Speed by 30%.`,
                artsbreak: 1200,
                truearts: `55000% Light DMG (PHY). For 25s, reduce enemies' Light RES by 80% and Evasion rate by 40%.`,
                trueartsbreak: 2000,
                superarts: `150000% Light DMG (PHY). Increase all allies' Arts by 30. For 20s, increase all allies' PHY DMG based on how high own % of HP is when Super Arts is used (MAX: 200%).`,
                superartsbreak: 2500
            },
        passive: 
            {
                ability1: `Each time own Physical equipment is used, permanently increase own DMG by 30% (MAX: 300%).`,
                ability2: `Increase own Blind RES by 80%.`,
                ability3: `When above 80% HP, increase own Accuracy by 40%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                    mat2amt: 220,
                    hover2: `Eternaglint Soulgem`,
                }
            },
        trueweapon: 
            {
                name: `Vespada`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_112171.png",
                skill: `25s CT - 5000% Light DMG (PHY). For 15s, reduce enemies' PHY RES by 15% (stackable). If user is Light, increase own Arts by 10.`,
                skillbreak: 500,
                passive: 
                    {
                        ability1: `When equipped by Marlet, increase own ATK and Skill CT Speed by 50%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own DMG by 10% against enemies in BREAK.`
                    }
            },
        lore: 
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Fictional Mage Hart - Missing Lore (JP)
        id: 103847512,
        name: `Fictional Mage Hart`,
        attribute: `Dark`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103846512.png",
                detailsuper: "/db/Units/Detail/unit_detail_103847512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103846512.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103847512.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 7541,
                hpplus: 1421,
                atk: 4208,
                atkplus: 723,
                def: 2619,
                defplus: 549
            },
        skillset: 
            {
                skill: `13s CT - 4000% Dark DMG (MAG). For 10s, reduce enemies' MAG RES by 20%.`,
                skillbreak: 700,
                arts: `22000% Dark DMG (MAG). Consumes 30% of own HP. For 20s, reduce enemies' Dark RES by 60%.`,
                artsbreak: 1300,
                truearts: `50000% Dark DMG (MAG). For 100s, increase own Arts by 5/s and ATK by 120%.`,
                trueartsbreak: 1800,
                superarts: `180000% Dark DMG (MAG). Increase all allies' Arts by 50. Increase own Arts based on how low own HP is (MAX: 150). For 25s, consume 3%/s of own Max HP.`,
                superartsbreak: 2500
            },
        passive: 
            {
                ability1: `Increase own Blind RES by 100%.`,
                ability2: `If unaffected by Status Ailments, ignore 100% of enemies' DEF. Increase own DMG based on how low own HP is. (MAX: 350%)`,
                ability3: `When this unit takes lethal DMG, revive all allies with 30% HP and increase all allies' Arts by 200. Can only be activated once per quest.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131455.png",
                    mat2amt: 220,
                    hover2: "Sinflow Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Coffin of Asgard`,
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_112201.png",
                skill: `50s CT - 8000% Dark DMG (MAG). For 15s, reduce enemies' Dark RES by 30%. If used when own HP is below 70%, reduce enemies' Dark RES by 50% instead.`,
                skillbreak: 500,
                passive: 
                    {
                        ability1: `When equipped by Fictional Mage Hart, increase own Arts by 100 at start of quest. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own Accuracy by 10%.`
                    }
            },
        lore:   
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Liza (Valentines) - Missing Lore (JP)
        id: 103857312,
        name: `Liza (Valentines)`,
        translate: `Valentines Liza`,
        attribute: `Earth`,
        type: `God`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103856312.png",
                detailsuper: "/db/Units/Detail/unit_detail_103857312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103856312.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103857312.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/healLB.png",
                slot32type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 8400,
                hpplus: 1450,
                atk: 2970,
                atkplus: 590,
                def: 2596,
                defplus: 522
            },
        skillset: 
            {
                skill: `12s CT - 2300% Earth DMG (MAG). For 10s, increase own Arts by 6/s.`,
                skillbreak: 400,
                arts: `20000% Earth DMG (MAG). Cure all allies' Status Ailments (Except Faint). For 15s, reduce enemies' MAG RES by 30%.`,
                artsbreak: 800,
                truearts: `45000% Earth DMG (MAG). Increase all allies' Arts by 50. For 10s, heal all allies' HP by 10%/s.`,
                trueartsbreak: 1200,
                superarts: `180000% Earth DMG (MAG). For 30s, increase all allies' DMG by 100% and increase God allies' DMG by an additional 100%.`,
                superartsbreak: 2000
            },
        passive: 
            {
                ability1: `Increase own Burn RES by 80%.`,
                ability2: `When Near-death, increase own DMG RES by 20%.`,
                ability3: `When self is Leader, increase all allies' HP Recovery by 20% for 100s. Does not stack with Abilities of the same name.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                    mat2amt: 220,
                    hover2: `Smogdragon Soulgem`
                }
            },
        lore:   
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Cestina (Valentines) - Missing Lore (JP)
        id: 103867212,
        name: `Cestina (Valentines)`,
        translate: `Valentines Cestina`,
        attribute: `Water`,
        type: `God`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103866212.png",
                detailsuper: "/db/Units/Detail/unit_detail_103867212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103866212.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103867212.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/defLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7810,
                hpplus: 1470,
                atk: 2730,
                atkplus: 570,
                def: 3500,
                defplus: 610
            },
        skillset: 
            {
                skill: `12s CT - 2000% Water DMG (PHY). Heal own HP by 20%. For 10s, increase own Arts by 4/s.`,
                skillbreak: 400,
                arts: `17000% Water DMG (PHY). Increase all allies' Arts by 25. For 20s, increase all allies' ATK and DEF by 50%.`,
                artsbreak: 1300,
                truearts: `40000% Water DMG (PHY). For 35s, redirect Allies' DMG taken to self and gain a DMG barrier that negates 36000 DMG to self.`,
                trueartsbreak: 2100,
                superarts: `80000% Water DMG (PHY). For 60s, increase all allies' DMG RES and MAG RES by 20% and Allies gain a DMG barrier that negates 6000 DMG.`,
                superartsbreak: 2800
            },
        passive: 
            {
                ability1: `Increase Paralysis RES by 100%.`,
                ability2: `When Near-death, increase own Arts by 6/s.`,
                ability3: `When Near-death, heal own HP by 100% and for 10s, increase own DMG RES by 20%. Can only be activated once per quest.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                    mat2amt: 220,
                    hover2: `Frostsprite Soulgem`
                }
            },
        lore:   
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Lark - Missing Lore (JP)
        id: 103877112,
        name: `Lark`,
        translate: `Crimson Enforcer Lark`,
        attribute: `Fire`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103876112.png",
                detailsuper: "/db/Units/Detail/unit_detail_103877112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103876112.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103877112.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/physLB.png",
                slot32type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 7452,
                hpplus: 1405,
                atk: 3812,
                atkplus: 660,
                def: 2887,
                defplus: 600
            },
        skillset: 
            {
                skill: `12s CT - 2500% Fire DMG (PHY). Increase own Arts by 50.`,
                skillbreak: 700,
                arts: `20000% Fire DMG (PHY). For 30s, increase own ATK by 120% and increase own Freeze RES and Paralysis RES by 100%.`,
                artsbreak: 1000,
                truearts: `64000% Fire DMG (PHY). For 30s, increase all allies' Accuracy by 50% and own DMG by 200%.`,
                trueartsbreak: 1400,
                superarts: `180000% Fire DMG (PHY). If used when own HP is 80% or above, for 20s, reduce enemies' PHY RES by 130%. Additionally if used when unafflicted by Status Ailments, for 20s, reduce enemies' Fire RES by 130%.`,
                superartsbreak: 2500
            },
        passive: 
            {
                ability1: `When self is Leader, increase Fire allies' Arts by 5/s for first 30s of Quest. Does not stack with Abilities of the same name.`,
                ability2: `Increase own DMG against Human enemies by 80%.`,
                ability3: `Increase own Evasion rate by 10%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                    mat2amt: 220,
                    hover2: `Flametail Soulgem`,
                }
            },
        trueweapon: 
                {
                    name: `Fiery Decapitation`,
                    slot: "/db/Mines/thumbnail/physLB.png",
                    detail: "/db/Equipment/Detail/item_detail_112321.png",
                    skill: `50s CT - 10000% Fire DMG (PHY). For 10s, increase own PHY DMG and Fire DMG by 50%.`,
                    skillbreak: 600,
                    passive: 
                        {
                            ability1: `When equipped by Lark, increase own Arts by 100 at Start of Quest. Does not stack with Abilities of the same name.`,
                            ability2: `Increase own PHY RES by 5%.`
                        }
                },
        lore:   
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Elenore - Missing Lore (JP)
        id: 103887312,
        name: `Elenore`,
        attribute: `Earth`, 
        type: `Demon`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103886312.png",
                detailsuper: "/db/Units/Detail/unit_detail_103887312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103886312.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103887312.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/defLB.png",
                slot32type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 7792,
                hpplus: 1405,
                atk: 2983,
                atkplus: 592,
                def: 3463,
                defplus: 652
            },
        skillset: 
            {
                skill: `12s CT - 2500% Earth DMG (MAG). For 10s, increase own DMG by 20%.`,
                skillbreak: 800,
                arts: `20000% Earth DMG (MAG). For 20s, reduce enemies' Earth RES by 50%.`,
                artsbreak: 1400,
                truearts: `70000% Earth DMG (MAG). For 30s, increase all allies' MAG DMG by 150%.`,
                trueartsbreak: 2000,
                superarts: `For 30s, increase all allies' Skill CT Speed by [15 x # of times Super Arts has been used]% (MAX: 180%) and Equip CT Speed by [5 x # of times Super Arts has been used]% (MAX: 60%). Additionally, for 15s, increase all allies' DMG RES by 30%.`,
                superartsbreak: 0
            },
        passive: 
            {
                ability1: `Increase own Arts by [2 x # of Earth allies alive in Party]/s (MAX: 8/s). Does not stack with Abilities of the same name.`,
                ability2: `Increase all allies' MAG RES by 10%. Does not stack with Abilities of the same name.`,
                ability3: `Increase own Seal RES by 30%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                    mat2amt: 220,
                    hover2: `Smogdragon Soulgem`,
                }
            },
        trueweapon: 
                {
                    name: `Festalion`,
                    slot: "/db/Mines/thumbnail/magLB.png",
                    detail: "/db/Equipment/Detail/item_detail_112351.png",
                    skill: `50s CT - 7000% Earth DMG (MAG). For 10s, increase own MAG DMG by 40% and reduce enemies' MAG RES by 30%.`,
                    skillbreak: 900,
                    passive: 
                        {
                            ability1: `When equipped by Elenore, the Skill CT Speed buff and Equip CT Speed buff from Super Arts will start at 60% and 20% respectively (MAX effect will not be affected). Does not stack with Abilities of the same name.`,
                            ability2: `Increase own BE Output by 10%.`
                        }
                },
        lore:   
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Star Swordswoman Valerie - Missing Lore (JP)
        id: 103897212,
        name: `Star Swordswoman Valerie`,
        attribute: `Water`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103896212.png",
                detailsuper: "/db/Units/Detail/unit_detail_103897212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103896212.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103897212.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/suppLB.png",
                slot32type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 7428,
                hpplus: 1400,
                atk: 3836,
                atkplus: 663,
                def: 2893,
                defplus: 601
            },
        skillset: 
            {
                skill: `13s CT - 1500% Water DMG (PHY). For 10s, reduce enemies' DMG RES by 20% (stackable).`,
                skillbreak: 500,
                arts: `18000% Water DMG (PHY). For 30s, increase own ATK and Skill CT Speed by 150%.`,
                artsbreak: 1000,
                truearts: `70000% Water DMG (PHY). For 30s, allies ignore 100% of Enemies' DEF and increase Water allies' DMG by 200%.`,
                trueartsbreak: 1500,
                superarts: `180000% Water DMG (PHY). Increase current Super Arts DMG Multiplier by an additional [10 x own ATK]%. Additionally, heal lowest ally's HP by 60%.`,
                superartsbreak: 2000
            },
        passive: 
            {
                ability1: `Increase own DMG by 60% for each ally above 70% HP (MAX: 240%). Does not stack with Abilities of the same name.`,
                ability2: `Increase own Arts by 5/s when above 90% HP.`,
                ability3: `Increase own PHY Equip CT Speed by 20%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                    mat2amt: 220,
                    hover2: `Frostsprite Soulgem`,
                }
            },
        trueweapon: 
                {
                    name: `Rising Nova`,
                    slot: "/db/Mines/thumbnail/physLB.png",
                    detail: "/db/Equipment/Detail/item_detail_112381.png",
                    skill: `50s CT - 9000% Water DMG (PHY). For 10s, reduce enemies' Water RES by 40%. Additionally if user is Water, for 10s, increase own Accuracy by 30%.`,
                    skillbreak: 500,
                    passive: 
                        {
                            ability1: `When equipped by Star Swordswoman Valerie, increase own ATK by 50% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                            ability2: `Increase own HP Recovery by 5%.`
                        }
                },
        lore:   
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Velzard - Missing Lore (JP)
        id: 103907112,
        name: `Velzard`,
        attribute: `Fire`,
        type: `Demon`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103906112.png",
                detailsuper: "/db/Units/Detail/unit_detail_103907112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103906112.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103907112.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/suppLB.png",
                slot32type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 8030,
                hpplus: 1510,
                atk: 2750,
                atkplus: 490,
                def: 3150,
                defplus: 650
            },
        skillset: 
            {
                skill: `11s CT - 2000% Fire DMG (PHY). Increase own Arts by 25. Additionally if this Skill crits, increase all allies' Arts by 25 (1 HIT).`,
                skillbreak: 700,
                arts: `30000% Fire DMG (PHY). 100% chance to inflict Bleed. If the Enemy is already Bleeding, increase its stack of Bleed by 1 (up to 3).`,
                artsbreak: 1200,
                truearts: `70000% Fire DMG (PHY). For 50s, increase own ATK by 150% and CRI rate by 100%.`,
                trueartsbreak: 2700,
                superarts: `170000% Fire DMG (PHY). For 30s, reduce enemies' Fire RES by [60 x # of Bleed stacks]% (MAX: 180%).`,
                superartsbreak: 3200
            },
        passive: 
            {
                ability1: `Reduce all allies' DMG taken from enemies with 3 stacks of Bleed by 30%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own DMG by 60% for every enemy killed by own attack (MAX: 300%). Does not stack with Abilities of the same name.`,
                ability3: `Increase own Healing received by 20% (except Lifesteal).`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                    mat2amt: 220,
                    hover2: `Flametail Soulgem`,
                }
            },
        trueweapon: 
                {
                    name: `Deep Blood`,
                    slot: "/db/Mines/thumbnail/physLB.png",
                    detail: "/db/Equipment/Detail/item_detail_112401.png",
                    skill: `60s CT - 7500% Fire DMG (PHY). If enemy is already Bleeding, increase its stack of Bleed by 1 (up to 3). For 8s, reduce enemies' PHY RES by 35%.`,
                    skillbreak: 300,
                    passive: 
                        {
                            ability1: `When equipped by Velzard, increase own DMG by 30% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                            ability2: `Increase own Accuracy by 10%.`
                        }
                },
        lore:   
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Azuel - Missing Lore (JP)
        id: 103917112,
        name: `Azuel`,
        attribute: `Fire`,
        type: `Demon`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103916112.png",
                detailsuper: "/db/Units/Detail/unit_detail_103917112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103916112.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103917112.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/healLB.png",
                slot32type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7146,
                hpplus: 1349,
                atk: 3940,
                atkplus: 680,
                def: 3056,
                defplus: 632
            },
        skillset: 
            {
                skill: `10s CT - 2500% Fire DMG (PHY). Increase own Arts by 40 and Regalia Gauge by 40 (MAX: 300).`,
                skillbreak: 1000,
                arts: `20000% Fire DMG (PHY). For 30s, reduce enemies' Break RES by 40%.`,
                artsbreak: 2000,
                truearts: `65000% Fire DMG (PHY). For 30s, increase own DMG by 150%. Each time True Arts is used, permanently increase all allies' stats by 10% (MAX: 40%).`,
                trueartsbreak: 3000,
                superarts: `160000% Fire DMG (PHY). Consume current Regalia Gauge. For 30s, increase Fire allies' DMG by [1 x Regalia consumed]% (MAX: 300%). Additionally if 100 or more Regalia is consumed, for 30s, increase all Fire allies' Break Power by 150%. Additionally if 300 Regalia is consumed, for 30s, increase all Fire allies' Accuracy by 150%.`,
                superartsbreak: 7000
            },
        passive: 
            {
                ability1: `Increase own Regalia Gauge by 4/s. When own HP is 100%, increase own Regalia Gauge by 8/s instead. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Freeze RES by 100%.`,
                ability3: `Increase own DMG against enemies in BREAK by 50%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                    mat2amt: 220,
                    hover2: `Flametail Soulgem`,
                }
            },
        trueweapon: 
                {
                    name: `Twin Falcon`,
                    slot: "/db/Mines/thumbnail/physLB.png",
                    detail: "/db/Equipment/Detail/item_detail_112421.png",
                    skill: `45s CT - 8000% Fire DMG (PHY). For 8s, increase own PHY DMG by 40% (stackable).`,
                    skillbreak: 4000,
                    passive: 
                        {
                            ability1: `When equipped by Azuel, increase own Break Power by 30% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                            ability2: `Increase own ATK by 5%.`
                        }
                },
        lore:   
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Vena - Missing Lore (JP)
        id: 103927311,
        name: `Vena`,
        attribute: `Earth`,
        type: `Beast`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103926311.png",
                detailsuper: "/db/Units/Detail/unit_detail_103927311.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103926311.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103927311.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/suppLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7245,
                hpplus: 1310,
                atk: 3187,
                atkplus: 629,
                def: 3119,
                defplus: 592
            },
        skillset: 
            {
                skill: `13s CT - 2500% Earth DMG (PHY). Cure Poison and Disease for ownself.`,
                skillbreak: 400,
                arts: `20000% Earth DMG (PHY). For 20s, Allies gain a DMG barrier that negates 2000 DMG. If used while Regalia Gauge is 100 or higher, allies gain a DMG barrier that negates 4000 DMG instead.`,
                artsbreak: 1000,
                truearts: `58000% Earth DMG (PHY). For 20s, increase Earth allies' ATK by 150%. If used while Regalia Gauge is 200 or higher, increase Earth allies' ATK by 300% instead.`,
                trueartsbreak: 1700,
                superarts: `110000% Earth DMG (PHY). For 20s, reduce enemies' PHY RES by 100%. If used while Regalia Gauge is at 300, reduce enemies' PHY RES by 200% instead.`,
                superartsbreak: 2000
            },
        passive: 
            {
                ability1: `Increase own Physical Evasion rate by 10%.`,
                ability2: `Increase own Arts by 4/s.`,
                ability3: `Increase own Regalia Gauge by 1/s. During Grand Battle, increase own Regalia Gauge by 2/s instead (MAX: 300). Does not stack with Abilities of the same name.`,
                ability4: `Increase Earth allies' DMG RES by [0.1 x current Regalia Gauge]% (MAX: 30%). Does not stack with Abilities of the same name.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_134953.png`,
                    mat2amt: 20,
                    hover2: `Vena Soulgem`,
                }
            },
        lore:   
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Sword God Luahn - Missing Lore (JP)
        id: 103997512,
        name: `Sword God Luahn`,
        attribute: `Dark`,
        type: `God`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_103996512.png",
                detailsuper: "/db/Units/Detail/unit_detail_103997512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_103996512.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_103997512.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7143,
                hpplus: 1320,
                atk: 3656,
                atkplus: 700,
                def: 3185,
                defplus: 604
            },
        skillset: 
            {
                skill: `11s CT - 2500% Dark DMG (PHY). For 5s, increase Dark allies' Arts by 3/s.`,
                skillbreak: 700,
                arts: `[Current HP x 7]% Dark DMG (PHY). For 10s, reduce enemies' PHY RES by 30%.`,
                artsbreak: 1000,
                truearts: `60000% Dark DMG (PHY). For 20s, increase all allies' stats by 30% and PHY DMG by 100%.`,
                trueartsbreak: 2000,
                superarts: `150000% Dark DMG (PHY). For [15 x # of Allies alive of Unique races]s, increase own Super CRI rate by 50% and all allies' CRI rate by 100% (MAX: 60s).`,
                superartsbreak: 3000
            },
        passive: 
            {
                ability1: `When set as Leader, increase all allies' Equip CT Speed by 50% for first 100s of Quest. Does not stack with Abilities of the same name.`,
                ability2: `Increase all allies' CRI DMG by 50%. Does not stack with Abilities of the same name.`,
                ability3: `At the Start of Quest, increase own Arts by 100.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131455.png",
                    mat2amt: 220,
                    hover2: "Sinflow Soulgem"
                }
            },
        trueweapon: 
                {
                    name: `Hakugen Ryogi`,
                    slot: "/db/Mines/thumbnail/physLB.png",
                    detail: "/db/Equipment/Detail/item_detail_112441.png",
                    skill: `20s CT - 4000% Dark DMG (PHY). For 10s, reduce enemies' PHY RES by 30%. Additionally if user is God, increase own Arts by 10.`,
                    skillbreak: 500,
                    passive: 
                        {
                            ability1: `When equipped by Sword God Luahn, increase own stats by 20% and Arts by 3/s. Does not stack with Abilities of the same name.`,
                            ability2: `Increase own Skill DMG by 10%.`
                        }
                },
        lore:   
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Summer Mira - Missing Lore (JP)
        id: 104007212,
        name: `Summer Mira`,
        attribute: `Water`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_104006212.png",
                detailsuper: "/db/Units/Detail/unit_detail_104007212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_104006212.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_104007212.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/physLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7256,
                hpplus: 1340,
                atk: 3487,
                atkplus: 670,
                def: 3197,
                defplus: 606
            },
        skillset: 
            {
                skill: `12s CT - 3200% Water DMG (PHY). For 10s, increase own ATK by 20%.`,
                skillbreak: 600,
                arts: `20000% Water DMG (PHY). Heal all allies' Status ailements (except Faint). For 20s, increase all allies' Freeze RES and Paralysis RES by 100%.`,
                artsbreak: 800,
                truearts: `65000% Water DMG (PHY). For 25s, increase all allies' Accuracy by 80% and own Accuracy by an additional 80%.`,
                trueartsbreak: 1400,
                superarts: `160000% Water DMG (PHY). For 30s, increase Water allies' DMG by 150% and DMG RES by 40%.`,
                superartsbreak: 2000
            },
        passive: 
            {
                ability1: `Increase own DMG by [2 x Accuracy buffs]% and Arts by [Accuracy buffs ÷ 20]/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Paralysis RES by 80%.`,
                ability3: `Increase Hellfire RES by 40%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                    mat2amt: 220,
                    hover2: `Frostsprite Soulgem`,
                }
            },
        trueweapon: 
                {
                    name: `Mira's Swelling Mermaid`,
                    slot: "/db/Mines/thumbnail/physLB.png",
                    detail: "/db/Equipment/Detail/item_detail_112471.png",
                    skill: `70s CT - 12000% Water DMG (PHY). For 15s, increase own DMG by 50% and Water DMG by 70%.`,
                    skillbreak: 500,
                    passive: 
                        {
                            ability1: `When equipped by Summer Mira, increase own ATK and Accuracy by 30%. Does not stack with Abilities of the same name.`,
                            ability2: `Increase own PHY DMG by 20%.`
                        }
                },
        lore:   
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Summer Celia - Missing Lore (JP)
        id: 104017512,
        name: `Summer Celia`,
        attribute: `Dark`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_104016512.png",
                detailsuper: "/db/Units/Detail/unit_detail_104017512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_104016512.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_104017512.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/suppLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 8338,
                hpplus: 1439,
                atk: 2977,
                atkplus: 591,
                def: 2645,
                defplus: 531
            },
        skillset: 
            {
                skill: `10s CT - 3000% Dark DMG (PHY). For 8s, increase own Accuracy by 20%.`,
                skillbreak: 700,
                arts: `Increase all allies' Arts by 30. For 30s, allies gain a DMG barrier that negates 5000 DMG.`,
                artsbreak: 0,
                truearts: `50000% Dark DMG (PHY). Increase all allies' Arts by 50. For 20s, reduce enemies' DMG RES by 60%.`,
                trueartsbreak: 1600,
                superarts: `140000% Dark DMG (MAG). 100% chance to inflict Burn and Blind for 30s. For 30s, heal all allies' HP by 1000/s and Dark allies by an additional 1000/s.`,
                superartsbreak: 2300
            },
        passive: 
            {
                ability1: `Increase own CRI rate by 100% and Super CRI rate by 20% against enemies affected by Status ailment(s). Does not stack with Abilities of the same name.`,
                ability2: `Increase own Burn RES by 70%.`,
                ability3: `Increase own Blind RES by 70%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131455.png",
                    mat2amt: 220,
                    hover2: "Sinflow Soulgem"
                }
            },
        trueweapon: 
                {
                    name: `Celia's Rosy Parasol`,
                    slot: "/db/Mines/thumbnail/physLB.png",
                    detail: "/db/Equipment/Detail/item_detail_112481.png",
                    skill: `45s CT - 8000% Dark DMG (PHY). For 12s, reduce enemies' Dark RES by 40%. Additionally if user is Dark, for 12s, increase own Accuracy by 30%.`,
                    skillbreak: 800,
                    passive: 
                        {
                            ability1: `When equipped by Summer Celia, increase own Arts by 3/s and DMG by 30%. Does not stack with Abilities of the same name.`,
                            ability2: `Increase own DMG against enemies in BREAK by 20%.`
                        }
                },
        lore:   
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Fiery Platoon Leader Charlotte - Missing Lore (JP)
        id: 104027112,
        name: `Fiery Platoon Leader Charlotte`,
        attribute: `Fire`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_104026112.png",
                detailsuper: "/db/Units/Detail/unit_detail_104027112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_104026112.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_104027112.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/suppLB.png",
                slot32type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7425,
                hpplus: 1400,
                atk: 3875,
                atkplus: 670,
                def: 2877,
                defplus: 598
            },
        skillset: 
            {
                skill: `12s CT - 2200% Fire DMG (MAG). Increase own Arts by 25. If this skill crits, increase own Arts by 50 instead (1 HIT only).`,
                skillbreak: 700,
                arts: `27000% Fire DMG (MAG). Increase all allies' next True Arts or Super Arts DMG by 170%.`,
                artsbreak: 1200,
                truearts: `63000% Fire DMG (MAG). 80% chance of inflicting Faint for 5s. For 30s, increase all allies' stats by 30% and Fire allies' stats by an additional 30%.`,
                trueartsbreak: 2000,
                superarts: `150000% Fire DMG (MAG). For 30s, increase all allies' CRI rate by 100%. Additionally, increase own Super CRI rate during Super Arts duration depending on current HP remaining (MAX: 100%).`,
                superartsbreak: 2500
            },
        passive: 
            {
                ability1: `When set as Leader of the party, increase all allies' Arts by 10/s for the first 20s of Quest. Does not stack with Abilities of the same name.`,
                ability2: `Increase own DMG by [1% × own ATK/100].`,
                ability3: `Increase own Paralysis RES by 100%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_130853.png",
                    mat2amt: 220,
                    hover2: "Flametail Soulgem"
                }
            },
        trueweapon: 
                {
                    name: `Deed, Ix, Flam`,
                    slot: "/db/Mines/thumbnail/magLB.png",
                    detail: "/db/Equipment/Detail/item_detail_112601.png",
                    skill: `55s CT - 10000% Fire DMG (MAG). For 8s, reduce enemies' Fire RES by 30%. Additionally if user is Human, for 8s, reduce enemies' DMG RES by 20%.`,
                    skillbreak: 500,
                    passive: 
                        {
                            ability1: `When equipped by Fiery Platoon Leader Charlotte, increase own DMG by 30% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                            ability2: `Increase own MAG RES by 5%.`
                        }
                },
        lore:   
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Phantom Saintess Platina - Missing Lore (JP)
        id: 104047412,
        name: `Phantom Saintess Platina`,
        attribute: `Light`,
        type: `God`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_104046412.png",
                detailsuper: "/db/Units/Detail/unit_detail_104047412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_104046412.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_104047412.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/defLB.png",
                slot32type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7392,
                hpplus: 1394,
                atk: 3551,
                atkplus: 667,
                def: 3071,
                defplus: 584
            },
        skillset: 
            {
                skill: `12s CT - 2500% Light DMG (PHY). Increase own Arts by 40. If used while enemy is in BREAK, increase own Arts by 200 instead.`,
                skillbreak: 2000,
                arts: `21000% Light DMG (PHY). For 20s, increase all allies' ATK by 100% and Physical Equip CT Speed by 30%.`,
                artsbreak: 3500,
                truearts: `60000% Light DMG (PHY). For 30s, reduce enemies' Break RES and Physical RES by 40% (stackable).`,
                trueartsbreak: 5500,
                superarts: `140000% Light DMG (PHY). For 20s, increase all allies' CRI rate by 100% and DMG against Enemies in Break by 200%.`,
                superartsbreak: 15000
            },
        passive: 
            {
                ability1: `When own HP is above 80%, increase own Freeze RES and Paralysis RES by 100%.`,
                ability2: `Giant Boss' Break Bar is halved at the beginning of battle. Raid Bosses' Break Bars take 20000 Break DMG instead. Ability can only be activated once per Quest.`,
                ability3: `Increase own Break Power by [2% × own ATK/100].`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131354.png",
                    mat2amt: 220,
                    hover2: "Eternaglint Soulgem"
                }
            },
        trueweapon: 
                {
                    name: `Traumerei`,
                    slot: "/db/Mines/thumbnail/physLB.png",
                    detail: "/db/Equipment/Detail/item_detail_112661.png",
                    skill: `65s CT - 6000% Light DMG (PHY). For 8s, reduce enemies' Light RES by 30%.`,
                    skillbreak: 6000,
                    passive: 
                        {
                            ability1: `When equipped by Phantom Saintess Platina, increase own ATK by 40% and Arts by 1/s. Does not stack with Abilities of the same name.`,
                            ability2: `Increase own MAG RES by 5%.`
                        }
                },
        lore:   
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Zinogre Armor Rayas - Missing Lore (JP)
        id: 107006412,
        name: "Zinogre Armor Rayas",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107005412.png",
                detailawk: "/db/Units/Detail/unit_detail_107006412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107005412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107006412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6540,
                hpplus: 991,
                atk: 2851,
                atkplus: 437,
                def: 2240,
                defplus: 346
            },
        skillset: 
            {
                skill: "8s CT - 2800% Light DMG (PHY).",
                skillbreak: 1450,
                arts: "11000% Light DMG (PHY). For 20s, increase Allies' ATK by 30%.",
                artsbreak: 1800,
                truearts: "25000% Light DMG (PHY). For 20s, increase Allies' ATK and DEF by 30%.",
                trueartsbreak: 3000
            },
        passive: 
            {
                ability1: "Increase DMG to God Enemies by 50%.",
                ability2: "Increase DMG to Demon Enemies by 50%.",
                ability3: "Increase Dark RES by 25%.",
                ability4: "Increase DMG to Monster Hunter Raid Bosses by 75%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 20,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 2,
                    hover3: "Fosradius",
                    mat4: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat4amt: 1,
                    hover4: "Ifrit",
                }
            },
        lore: 
            {   
                evo5: "Currently unreleased in Global.",
                evoawk: "Currently unreleased in Global."
            }
    },
    { //Mizutsune Armor Roy - Missing Lore (JP)
        id: 107016212,
        name: "Mizutsune Armor Roy",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107015212.png",
                detailawk: "/db/Units/Detail/unit_detail_107016212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107015212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107016212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7296,
                hpplus: 999,
                atk: 2722,
                atkplus: 418,
                def: 2266,
                defplus: 349
            },
        skillset: 
            {
                skill: "8s CT - 2150% Water DMG (PHY). During Enemy Arts, increase Critical Rate of this Skill by 50% and Break PWR of this Skill by 70%.",
                skillbreak: 1550,
                arts: "13550% Water DMG (PHY). During Enemy Arts, increase Critical Rate of this Arts by 50% and Break PWR of this Arts by 70%.",
                artsbreak: 3200,
                truearts: "27550% Water DMG (PHY). 100% chance to inflict Ultimate Freeze for 6s.",
                trueartsbreak: 5600
            },
        passive: 
            {
                ability1: "Increase Rayas'/Monster Hunter Rayas' DMG by 50% (Does not stack with Abilities of the same name).",
                ability2: "Increase Allies' DMG RES by 20%.",
                ability3: "Increase DMG to Monster Hunter Raid Bosses by 50%.",
                ability4: "When near-death, heal 60 HP/s."
            },
        passiveJP:
            {
                ability1: "Increase Rayas'/Monster Hunter Rayas' DMG by 50% (Does not stack with Abilities of the same name).",
                ability2: "Increase Allies' DMG RES by 20%.",
                ability3: "Increase DMG to Monster Hunter Raid Bosses by 50%.",
                ability4: "When near-death, heal 60 HP/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 20,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat3amt: 3,
                    hover3: "Freydal",
                    mat4: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat4amt: 2,
                    hover4: "Palamicia",
                }
            },
        lore: 
            {   
                evo5: "Currently unreleased in Global.",
                evoawk: "Currently unreleased in Global."
            }
    },
    { //Rathian Armor Iris - Missing Lore (JP)
        id: 107026412,
        name: "Rathian Armor Iris",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107025412.png",
                detailawk: "/db/Units/Detail/unit_detail_107026412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107025412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107026412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6000,
                hpplus: 910,
                atk: 2400,
                atkplus: 370,
                def: 2400,
                defplus: 370
            },
        skillset: 
            {
                skill: "8s CT - 1890% Light DMG (MAG).",
                skillbreak: 1250,
                arts: "9500% Light DMG (MAG). For 10s, increase Allies' stats by 20%.",
                artsbreak: 2100,
                truearts: "21000% all-element DMG (MAG). Heal Allies' HP by 5000. For 20s, Allies gain a DMG barrier that negates 3000 DMG.",
                trueartsbreak: 3000
            },
        passive: 
            {
                ability1: "When an Ally dies (except self), revive that Ally with 33% HP. Ability can only be activated once per Quest.",
                ability2: "Increase DMG to Monster Hunter Raid Bosses by 50%.",
                ablity3: "Increase Status RES (except Faint) for all Allies by 30%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 20,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat3amt: 2,
                    hover3: "Radachne",
                    mat4: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat4amt: 2,
                    hover4: "Code-XTF",
                }
            },
        lore: 
            {   
                evo5: "Currently unreleased in Global.",
                evoawk: "Currently unreleased in Global."
            }
    },
    { //Teostra Armor Ganan - Missing Lore (JP)
        id: 107036112,
        name: "Teostra Armor Ganan",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107035112.png",
                detailawk: "/db/Units/Detail/unit_detail_107036112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107035112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107036112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6226,
                hpplus: 943,
                atk: 2244,
                atkplus: 346,
                def: 1877,
                defplus: 291
            },
        skillset: 
            {
                skill: "9s CT - 2200% Fire DMG (PHY). Increase own Arts by 3.",
                skillbreak: 2200,
                arts: "15000% Fire DMG (PHY). For 10s, increase own ATK by 200%, increase Freeze, Flinch and Paralysis RES by 100%.",
                artsbreak: 3000,
                truearts: "28000% Fire DMG (PHY). For 20s, increase Allies' Break PWR by 30%.",
                trueartsbreak: 4600
            },
        passive: 
            {
                ability1: "Increase DMG to Monster Hunter Raid Bosses by 50%.",
                ability2: "Increase own Break PWR by 10%.",
                ability3: "Increase all Allies' Break by PWR 10%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 20,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 3,
                    hover3: "Flogassa",
                    mat4: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat4amt: 2,
                    hover4: "Ifrit",
                }
            },
        lore: 
            {   
                evo5: "Currently unreleased in Global.",
                evoawk: "Currently unreleased in Global."
            }
    },
    { //Kirin Armor Liese - Missing Lore (JP)
        id: 107046412,
        name: "Kirin Armor Liese",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107045412.png",
                detailawk: "/db/Units/Detail/unit_detail_107046412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107045412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107046412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/healLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6310,
                hpplus: 956,
                atk: 2495,
                atkplus: 384,
                def: 2243,
                defplus: 346
            },
        skillset: 
            {
                skill: "7s CT - Attack mode: 1800% Light DMG (MAG). Recovery mode: Heal Allies' HP by 250.",
                skillbreak: 1700,
                arts: "Attack mode: 10500% Light DMG (MAG). 80% chance to inflict Paralysis for 2s. Recovery mode: Heal Allies' HP by 2000.",
                artsbreak: 3450,
                truearts: "15500% Light DMG (MAG). 89% chance to inflict Paralysis for 6s. Heal Allies' HP by 5000, Revive fallen Allies with 30% HP (Only able to revive fallen Allies once every 120s).",
                trueartsbreak: 4450
            },
        passive: 
            {
                ability1: "Switch between Attack Mode and Recover Mode when using Skill.",
                ability2: "Increase Equipment CT REC by 15%.",
                ability3: "Increase DMG to Monster Hunter Raid Bosses by 50%.",
                ability4: "Increase Status RES (except Faint) by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 20,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 2,
                    hover3: "Fosradius",
                    mat4: "/db/Icons/dungeon_drops/fragPalamicia.png",
                    mat4amt: 1,
                    hover4: "Palamicia",
                }
            },
        lore: 
            {   
                evo5: "Currently unreleased in Global.",
                evoawk: "Currently unreleased in Global."
            }
    },
    { //Felyne Armor Sophie - Missing Lore (JP)
        id: 107056112,
        name: "Felyne Armor Sophie",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107055112.png",
                detailawk: "/db/Units/Detail/unit_detail_107056112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107055112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107056112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6169,
                hpplus: 935,
                atk: 2118,
                atkplus: 327,
                def: 2052,
                defplus: 317
            },
        skillset: 
            {
                skill: "8s CT - 1500% Fire DMG (PHY).",
                skillbreak: 2500,
                arts: "9000% Fire DMG (PHY). For 10s, increase Allies' Skill CT REC by 30%, for Fire Allies, increase Skill CT REC by 60% instead.",
                artsbreak: 2900,
                truearts: "20000% Fire DMG (PHY). For 10s, increase Allies' Skill CT REC by 80%, for Fire Allies, increase Skill CT REC by 130% instead.",
                trueartsbreak: 4400
            },
        passive: 
            {
                ability1: "Fire Allies' ATK increased by 30%.",
                ability2: "Increase DMG to Monster Hunter Raid Bosses by 50%.",
                ability3: "Increase Burn RES by 100%.",
                ability4: "Increase own Arts by 1/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 20,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat3amt: 2,
                    hover3: "Oldeus",
                    mat4: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat4amt: 1,
                    hover4: "Ifrit",
                }
            },
        lore: 
            {   
                evo5: "Currently unreleased in Global.",
                evoawk: "Currently unreleased in Global."
            }
    },
    { //Palico (Using English name) - Missing Lore (JP)
        id: 107066112,
        name: "Rathalos Armor Palico",
        attribute: "Fire",
        type: "Beast",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107065112.png",
                detailawk: "/db/Units/Detail/unit_detail_107066112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107065112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107066112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 5,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 5900,
                hpplus: 845,
                atk: 2200,
                atkplus: 340,
                def: 2000,
                defplus: 310
            },
        skillset: 
            {
                skill: "7s CT - 1800% Fire DMG (PHY).",
                skillbreak: 1000,
                arts: "10000% Fire DMG (PHY).",
                artsbreak: 2000,
                truearts: "23500% Fire DMG (PHY). For 8s, increase own Evasion by 30%.",
                trueartsbreak: 3333
            },
        passive: 
            {
                ability1: "Increase EXP gained from quests by 30%.",
                ability2: "Increase DMG to Monster Hunter Raid Bosses by 50%.",
                ability3: "Increase own Critical Rate by 20%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRathalos.png",
                    mat3amt: 5,
                    hover3: "Rathalos",
                }
            },
        lore: 
            {   
                evo5: "Currently unreleased in Global.",
                evoawk: "Currently unreleased in Global."
            }
    },
    { //Saitama - Complete + JP ver
        id: 107076412,
        name: "Saitama",
        luckrank: "A",
        rank: "A-",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107075412.png",
                detailawk: "/db/Units/Detail/unit_detail_107076412.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_707_1.png",                            
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107075412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107076412.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_707_1.png",
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 5,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        slotsJP: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 5,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7200,
                hpplus: 999,
                atk: 3000,
                atkplus: 460,
                def: 2500,
                defplus: 385
            },
        skillset: 
            {
                skill: "8s CT - 3200% Light DMG (PHY). Increase own Arts by 20.",
                skillbreak: 2000,
                arts: "30000% Light DMG (PHY). For 40s, increase own Arts by 5/s.",
                artsbreak: 2000,
                truearts: "200000% Light DMG (PHY).",
                trueartsbreak: 6000
            },
        skillsetJP: 
            {
                skill: "8s CT - 3200% Light DMG (PHY).",
                skillbreak: 2000,
                arts: "20000% Light DMG (PHY). For 30s, increase own Arts by 5/s.",
                artsbreak: 2000,
                truearts: "120000% Light DMG (PHY).",
                trueartsbreak: 6000
            },
        passive: 
            {
                ability1: "Increase DMG to Giant Bosses by 50%.",
                ability2: "Increase DEF by 50% when under a DMG barrier.",
                ability3: "Increase Status RES (except Faint) by 100%. When damaged while near-death, for 20s, gain a DMG barrier that negates 2800 DMG. Ability can only be activated once every 60s.",
                ability4: `Unit EXP UP 20% UP. (When Saitama's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Saitama's SP. Art is owned)`
            }, 
        passiveJP: 
            {
                ability1: "Increase DMG to Giant Bosses by 50%.",
                ability2: "Increase DEF by 50% when under a DMG barrier.",
                ability3: "Increase Status RES (except Faint) by 100%. When damaged while near-death, for 20s, gain a DMG barrier that negates 2800 DMG. Ability can only be activated once every 60s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 1,
                    hover3: "Fosradius",
                }
            },
        dreamGL: 
            {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 30,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                        mat3amt: 1,
                        hover3: "Ifrit",
                    },
                    passive: {
                        ability1: `Increase own DMG against Demons by 100%.`,
                        ability2: `Increase own DMG against Dragons by 100%.`
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 30,
                        hover2: `Eternaglint Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                        mat3amt: 1,
                        hover3: "Wilkes",
                    },
                    passive: {
                        ability1: `Increase own DMG against Gods by 100%.`,
                        ability2: `Increase own DMG against Beasts by 100%.`
                    }
                }
            },
        lore: 
            {   
                evo5: "Originally only a Hero by hobby, Saitama eventually registered with the Hero Association. When younger, he was extremely weak. But after undergoing training so intense as to lose his hair, he achieved superhuman powers. Saitama is so strong some even say a god resides within his body.",
                evoawk: "A professional Hero with superhuman powers. His powers are accurately described by his name, since Saitama is the most powerful man to exist, defeating his enemies in ONE PUNCH. However, few acknowledge his true strength."
            }
    },
    { //Genos - Complete + JP ver
        id: 107086512,
        name: "Genos",
        rank: "F",
        luckrank: "C",
        attribute: "Dark",
        type: "Machine",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107085512.png",
                detailawk: "/db/Units/Detail/unit_detail_107086512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107085512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107086512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 5700,
                hpplus: 865,
                atk: 2110,
                atkplus: 326,
                def: 1990,
                defplus: 308
            },
        skillset: 
            {
                skill: "9s CT - 1600% Dark DMG (PHY). Increase own Arts by 10.",
                skillbreak: 1400,
                arts: "22000% Dark DMG (MAG). 80% chance to inflict Burn for 8s + For 10s, increase own Arts by 3/s.",
                artsbreak: 2800,
                truearts: "48000% Dark DMG (MAG). For 15s, reduce own Action Speed by 15% and increase Allies' (except self) Skill CT REC by 120%.",
                trueartsbreak: 3500
            },
        skillsetJP: 
            {
                skill: "9s CT - 1600% Dark DMG (PHY). Increase own Arts by 5.",
                skillbreak: 1400,
                arts: "15000% Dark DMG (MAG). 80% chance to inflict Burn for 8s + For 10s, increase own Arts by 3/s.",
                artsbreak: 2800,
                truearts: "30000% Dark DMG (MAG). For 10s, reduce own Action Speed by 15%, increase Allies' (except self) Skill CT REC by 100%.",
                trueartsbreak: 3500
            },
        passive: 
            {
                ability1: "Ignore 50% of Enemy DEF. Increase Critical Rate by 100% against Burned Enemies.",
                ability2: "Increase Player EXP gained on Quests by 30%. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat3amt: 1,
                    hover3: "Code-XTF",
                }
            },
        lore: 
            {   
                evo5: "Not only did Genos pass his exam, he was certified a S-Class Hero on his first try. He is a cyborg with the majority of his body being mechanized, registered with the Hero Association. He's a lone wolf by nature, but after being saved by Saitama against an impossible foe, he vowed allegiance to Saitama as his pupil.",
                evoawk: "A cyborg S-Class Hero with mechanized weapons of mass destruction embedded all over his body. Any enemy would fear of his destructive attacks that even risk Genos' own life."
            }
    },
    { //Terrible Tornado - Complete + JP ver
        id: 107096312,
        name: "Terrible Tornado",
        luckrank: "C",
        rank: "A-",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107095312.png",
                detailawk: "/db/Units/Detail/unit_detail_107096312.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_709_1.png", 
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107095312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107096312.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_709_1.png",
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        slotsJP: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6444,
                hpplus: 932,
                atk: 2618,
                atkplus: 355,
                def: 2220,
                defplus: 334
            },
        skillset: 
            {
                skill: "7s CT - 2000% Earth DMG (MAG). For 8s, reduce Enemies' Break RES by 30%.",
                skillbreak: 2000,
                arts: "22000% Earth DMG (MAG). For 15s, reduce all Enemies' MAG RES by 25%.",
                artsbreak: 4200,
                truearts: "70000% Earth DMG (MAG). 100% chance to inflict Paralysis for 6s. For 10s, increase all Allies' Break PWR by 100%.",
                trueartsbreak: 8000
            },
        skillsetJP: 
            {
                skill: "7s CT - 2000% Earth DMG (MAG). For 8s, reduce Enemies' Break RES by 30%.",
                skillbreak: 2000,
                arts: "17000% Earth DMG (MAG). For 15s, reduce all Enemies' MAG RES by 25%.",
                artsbreak: 4200,
                truearts: "32000% Earth DMG (MAG). 100% chance to inflict Paralysis for 6s. For 8s, increase all Allies' Break PWR by 50%.",
                trueartsbreak: 8000
            },
        passive: 
            {
                ability1: "Increase Arts by 2/s.",
                ability2: "Increase BE generation by 100% against Enemies in Break.",
                ability3: "Increase MAG RES by 30%. Increase DMG by 200% to Enemies in Break.",
                ability4: `Unit EXP UP 20% UP. (When Terrible Tornado's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Terrible Tornado's SP. Art is owned)`
            }, 
        passiveJP: 
            {
                ability1: "Increase Arts by 2/s.",
                ability2: "Increase BE generation by 100% against Enemies in Break.",
                ability3: "Increase MAG RES by 30%. Increase DMG by 100% to Enemies in Break."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat3amt: 1,
                    hover3: "Tonetaurus",
                }
            },
            dreamGL: {
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 30,
                        hover2: `Smogdragon Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                        mat3amt: 1,
                        hover3: "Tonetaurus",
                    },
                    passive: {
                        ability1: `Increase own Break Power against Water enemies by 100%.`
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 30,
                        hover2: `Eternaglint Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                        mat3amt: 1,
                        hover3: "Wilkes",
                    },
                    passive: {
                        ability1: `Increase allies' MAX HP by 10%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase Earth allies' Arts by 2/s. Does not stack with Abilities of the same name.`
                    }
                }
            },
        lore: 
            {   
                evo5: "She is registered with the Hero Association. She was given the nickname 'Terrible Tornado' due to her outstanding but terrifying abilities in battle. She is specialized in psychic skills, especially in wide-range telekinesis attacks.",
                evoawk: "The S-Class Rank-2 Hero in the Hero Association. It's not an overstatement to say she's the Hero Association's last card against threats against humanity. Facing her infinite strength, her enemies cannot even raise a finger after the hurt they find themselves in."
            }
    },
    { //Boros - Complete + JP ver
        id: 107106511,
        name: "Boros",
        luckrank: "F",
        rank: "F",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107105511.png",
                detailawk: "/db/Units/Detail/unit_detail_107106511.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107105511.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107106511.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6666,
                hpplus: 999,
                atk: 2244,
                atkplus: 346,
                def: 2244,
                defplus: 346
            },
        skillset: 
            {
                skill: "9s CT - 1666% Dark DMG (PHY). For 7s, increase own Skill CT REC by 20%.",
                skillbreak: 1444,
                arts: "24444% Dark DMG (PHY). Consume 10% of own HP. For 10s, increase own DMG to Enemies in BREAK by 100% and Break Power by 100% against Enemies not in BREAK.",
                artsbreak: 2222,
                truearts: "66666% Dark DMG (MAG). Consume 30% of own HP. For 15s, increase own True Arts' Critical Rate by 100%, and reduce Enemies' Fire, Water, Earth, Light and Dark RES by 30%.",
                trueartsbreak: 4444
            },
        skillsetJP: 
            {
                skill: "9s CT - 1666% Dark DMG (PHY). For 7s, increase own Skill CT REC by 20%.",
                skillbreak: 1444,
                arts: "14444% Dark DMG (PHY). Consume 10% of own HP. For 10s, increase own DMG to Enemies in BREAK by 50% and Break Power by 50% against Enemies not in BREAK.",
                artsbreak: 2222,
                truearts: "26666% Dark DMG (MAG). Consume 30% of own HP. For 10s, increase own True Arts' Critical Rate by 100%, reduce Enemies' Fire, Water, Earth, Light and Dark RES by 20%.",
                trueartsbreak: 4444
            },
        passive: 
            {
                ability1: "Increase Dark RES by 20%.",
                ability2: "At the start of a Quest, for 60s, increase stats by 15%. Heal own HP by 200/s."
            }, 
        passiveJP: 
            {
                ability1: "Increase Dark RES by 20%.",
                ability2: "At the start of a Quest, for 60s, increase stats by 15%. Heal own HP by 100/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragBoros.png",
                    mat3amt: 20,
                    hover3: "Boros",
                }
            },
        lore: 
            {   
                evo5: "The leader of \"Dark Matter,\" a group of alien invaders that storm the universe in search of a foe of equal strength. Worthy of his own moniker, \"Dominator of the Universe,\" Boros's skills in battle know no limits, thus his needs to seal them within his armor.",
                evoawk: "The true form of the leader of \"Dark Matter\" and self-proclaimed \"Dominator of the Universe\". The speed and strength of his abilities extend far beyond any living creatures capacity, and the burden of his own powers is extremely taxing on Boros' body. Few dare to fight back after seeing the carnage he is capable of causing."
            }
    },
    { //Sugimoto - Complete
        id: 107116112,
        name: "Sugimoto",
        rank: "C",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107115112.png",
                detailawk: "/db/Units/Detail/unit_detail_107116112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107115112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107116112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 5,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6550,
                hpplus: 952,
                atk: 2650,
                atkplus: 373,
                def: 2280,
                defplus: 328
            },
        skillset: 
            {
                skill: "10s CT - 2500% Fire DMG (PHY). Increase own Arts by 15.",
                skillbreak: 1200,
                arts: "17000% Fire DMG (PHY). For 12s, reduce Enemies' ATK by 60%.",
                artsbreak: 2500,
                truearts: "40000% Fire DMG (PHY). 100% chance to inflict Faint for 5s. For 20s, increase own Critical DMG by 150% + If Enemy was Fainted by this True Arts, reduce that Enemy's Arts by 20.",
                trueartsbreak: 3200
            },
        passive: 
            {
                ability1: "Increase Poison RES by 50%.",
                ability2: "Increase Critical Rate by 30%.",
                ability3: "When this unit takes lethal DMG, revive self with 100% HP and 200 Arts. For 30s, increase Arts DMG by 50% and Critical Rate by 100%. Can only be activated twice per Quest."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 1,
                    hover3: "Flogassa",
                }
            },
        lore: 
            {   
                evo5: "A war veteran of the Russo-Japanese War. He was called Sugimoto the Immortal due to many near-death situations he survived. He joined the gold hunt after hearing of the Ainu Gold by a strange coincidence.",
                evoawk: "The war veteran who served in the 1st Division of the Imperial Japanese Army. To fulfill the promise he made to his best friend who was killed at war, Sugimoto began searching for Ainu Gold. Aptly nicknamed Sugimoto the Immortal, he is a highly skilled fighter, but Sugimoto's real power may be the strength of his spirit and refusal to be controlled by the fear of death."
            }
    },
    { //Asirpa - Complete
        id: 107126412,
        name: "Asirpa",
        rank: "A+",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107125412.png",
                detailawk: "/db/Units/Detail/unit_detail_107126412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107125412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107126412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 5,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6050,
                hpplus: 887,
                atk: 2080,
                atkplus: 313,
                def: 2410,
                defplus: 364
            },
        skillset: 
            {
                skill: "9s CT - 1700% Light DMG (PHY). 60% chance to inflict Poison for 8s.",
                skillbreak: 1500,
                arts: "Heal all Allies' HP by 25%, increase Allies' Arts by 25 + For 12s, increase Allies' DEF by 100%.",
                artsbreak: 0,
                truearts: "22000% Light DMG (PHY). For 20s, increase Allies' Skill CT REC by 120%, Equipment CT REC and Break PWR by 30%.",
                trueartsbreak: 3800
            },
        passive: 
            {
                ability1: "Heal own HP by 120/s and increase Arts by 2/s.",
                ability2: "Increase Freeze RES by 100%.",
                ability3: "Increase accuracy by 20%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 1,
                    hover3: "Fosradius",
                }
            },
        lore: 
            {   
                evo5: "An Ainu girl who is a skillful archer and expert hunter with extensive knowledge of surviving in the mountains. She met and saved Sugimoto when he was being attacked by a brown bear, and she has been by his side ever since.",
                evoawk: "Her given name means both New Year and future, and she calls herself \"an Ainu woman for a new age\". She joined the hunt for Ainu Gold as Sugimoto's partner, but her main goal is to discover the truth about he father, rather than the gold itself."
            }
    },
    { //Hijikata - Complete
        id: 107136512,
        name: "Hijikata",
        rank: "F",
        luckrank: "F",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107135512.png",
                detailawk: "/db/Units/Detail/unit_detail_107136512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107135512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107136512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6700,
                hpplus: 952,
                atk: 2200,
                atkplus: 322,
                def: 2150,
                defplus: 389
            },
        skillset: 
            {
                skill: "9s CT - 2000% Dark DMG (PHY). Increase own ATK by 8% (up to 80%).",
                skillbreak: 1000,
                arts: "15000% Dark DMG (PHY). For 12s, increase Allies' DMG to Enemies in BREAK by 50%.",
                artsbreak: 2000,
                truearts: "24000% Dark DMG (PHY). Increase own Arts by 100 + For 12s, ignore 100% of Enemies' DEF.",
                trueartsbreak: 3500
            },
        passive: 
            {
                ability1: "When near-death, increase Arts by 2/s.",
                ability2: "Increase all Allies' ATK by 15%. Increase DMG to Enemies with 30% or less HP by 20%. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragHijikata.png",
                    mat3amt: 20,
                    hover3: "Hijikata",
                }
            },
        lore: 
            {   
                evo5: "The vice-commander of the Shinsengumi who played a significant role during the last days of the Tokugawa shogunate. He was called the Merciless Vice-Commander because of his outstanding sword skills and harsh personality. He was said to have been killed in the Battle of Hakodate, but he was actually kept in a secret prison. He is after the Ainu Gold.",
                evoawk: "A swordsman of the old shogunate army searching for the Ainu Gold. Despite being over 70 years old, his sword skills remain as sharp as in his youth. With a violent and thorny personality, he was nicknamed Baragaki as a child, after the Japanese word for thorns."
            }
    },
    { //Rimuru (Slime form) - Complete, Review done
        id: 107146212,
        name: "Rimuru (Slime form)",
        luckrank: "F",
        attribute: "Water",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107145212.png",
                detailawk: "/db/Units/Detail/unit_detail_107146212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107145212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107146212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6500,
                hpplus: 932,
                atk: 2520,
                atkplus: 376,
                def: 2380,
                defplus: 307
            },
        skillset: 
            {
                skill: "7s CT - 1500% Water DMG (MAG). Increase own Arts by 20.",
                skillbreak: 800,
                arts: "15000% Water DMG (PHY). 80% chance to inflict Paralysis for 3s.",
                artsbreak: 2000,
                truearts: "35000% Dark DMG (MAG). For 15s, reduce Enemies' DMG RES by 30%.",
                trueartsbreak: 3600
            },
        passive: 
            {
                ability1: "Increase Player EXP gained on Quests by 10%. Does not stack with Abilities of the same name.",
                ability2: "Increase own evasion and accuracy by 15%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragSlime.png",
                    mat3amt: 20,
                    hover3: "Slime",
                }
            },
        lore: 
            {   
                evo5: "A slime that was reincarnated to a different world. Originally, he was a human named Satoru Mikami. He gained unique skills 'Great Sage' and 'Predator' when he was reincarnated and has powers that a normal slime could never have. He became an equal existence with Veldora, one of the strongest 'Storm Dragons' by giving each other a name.",
                evoawk: "One who was reincarnated to a different world. By using his unique skills 'Great Sage' and 'Predator,' he gains various monsters' skills. Unexpectedly, he becomes the ruler of a Goblin village. Soon, he meets Shizue Izawa who comes from the same world and carries her fate."
            },
        review:
            {
                skill: ``,
                arts: ``,
                truearts: ``,
                ability1: ``,
                ability2: ``,
                ability3: ``,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Nothing for Slimuru has changed, he's still just a mediocre unit with very niche usage. Not to mention that his "utility" as being the only luck unit in the Slime collab no longer exists, there's little to no reason to increase his luck outside of collection sake.`,
                lastupdated: `January 12, 2023`,
                author: `Zanster`
            }
    },
    { //Rimuru (Human form) - Complete, Review (Pre-buff) done
        id: 107156212,
        name: "Rimuru (Human form)",
        attribute: "Water",
        rank: "S",
        luckrank: "A",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107155212.png",
                detailawk: "/db/Units/Detail/unit_detail_107156212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107155212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107156212.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7120,
                hpplus: 999,
                atk: 2820,
                atkplus: 397,
                def: 2645,
                defplus: 384
            },
        skillset: 
            {
                skill: "10s CT - 2800% Fire DMG (MAG). Increase own Arts gauge by 20.",
                skillbreak: 1200,
                arts: "Refresh Skill CT for all Allies + Cure Allies' status ailments (except Faint) and heal Allies by 35% of their max HP + For 15s, increase Allies' Status RES (except Faint) by 100%.",
                artsbreak: 0,
                truearts: "42000% Water DMG (MAG). Heal own HP by 3% of DMG dealt by this True Arts + For 15s, increase Allies' stats by 30%, increase Allies' Arts by 4/s.",
                trueartsbreak: 3700
            },
        passive: 
            {
                ability1: "Increase Fire RES by 30%.",
                ability2: "Increase Arts by 2/s. Increase Status RES (except Faint) by 30%.",
                ability3: "Increase own Equip CT speed by 5%."
            },
        dream: {
            fire: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                    mat2amt: 50,
                    hover2: `Flametail Soulgem`,
                    mat3: `/db/Icons/dungeon_drops/fragIfrit.png`,
                    mat3amt: 2,
                    hover3: `Ifrit`
                },
                passive: {
                    ability1: `Increase all allies' Fire RES by 20%. Does not stack with abilities of the same name.`,
                    ability2: `Skill will additionally heal own Status ailment (except Faint, Paralyze, Freeze, Seal).`
                }
            },
            water: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                    mat2amt: 50,
                    hover2: `Frostsprite Soulgem`,
                    mat3: `/db/Icons/dungeon_drops/fragFreydal.png`,
                    mat3amt: 2,
                    hover3: `Freydal`
                },
                passive: {
                    ability1: `Increase own Equipment CT Speed by 15%.`,
                    ability2: `Skill will additionally increase own Arts by 50.`
                }
            },
            earth: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                    mat2amt: 50,
                    hover2: `Smogdragon Soulgem`,
                    mat3: `/db/Icons/dungeon_drops/fragElpneumas.png`,
                    mat3amt: 2,
                    hover3: `Elpneumas`
                },
                passive: {
                    ability1: `Increase own Heal Amount by 20%.`,
                    ability2: `Skill will additionally increase all allies' MAX HP by 10% for 7s.`
                }
            },
            light: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                    mat2amt: 50,
                    hover2: `Eternaglint Soulgem`,
                    mat3: `/db/Icons/dungeon_drops/fragWilkes.png`,
                    mat3amt: 2,
                    hover3: `Wilkes`
                },
                passive: {
                    ability1: `Increase own BE Output by 20%.`,
                    ability2: `Skill will additionally increase own Accuracy by 60% for 7s.`
                }
            },
            dark: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                    mat2amt: 50,
                    hover2: `Sinflow Soulgem`,
                    mat3: `/db/Icons/dungeon_drops/fragRagsherumPhantom.png`,
                    mat3amt: 2,
                    hover3: `Ragsherum Phantom`
                },
                passive: {
                    ability1: `Increase all Demon Allies' Arts by 2/s. Does not stack with abilities of the same name.`,
                    ability2: `Skill will additionally increase own ATK by 50% for 7s.`
                }
            }
        },
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragAerugraxes.png",
                    mat3amt: 1,
                    hover3: "Aerugraxes"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Rimuru's Sword",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_152791.png",
                skill: "45s CT - 6000% Water DMG (PHY). For 8s, reduce enemies' Water RES by 40%.",
                skillbreak: 700,
                passive: 
                    {
                        ability1: "When equipped by Rimuru, increase own Arts by 2/s. Does not stack with Abilities of the same name.",
                        ability2: "Increase own ATK by 5%."
                    }
            },
        lore: 
            {   
                evo5: "A slime that was reincarnated to a different world. It became possible to mimic humans ever since he preyed on Shizue Izawa, a Japanese who was reincarnated to a different world. He also preyed on a high-level spirit, Ifrit. With the unique skill 'De-generate,' which was passed on from Shizu, he separated the skills he gained from various monsters and succeeded to combine them to gain multiple extra skills.",
                evoawk: "A slime that used to be a human which was reincarnated to a different world. He won the life and death battle between Geld, an Orc Disaster that was once Orc Lord. After that, he earned the overwhelming support of the monsters living in the Forest of Jura. Not long after that, he became king of 'Jura Tempest Federation.'"
            },
        review: // needs to be updated
        {
            skill: `The buff introducing ArtsGen on skill is much appreciated, allowing for an easier startup.`,
            arts: `With a 35% teamwide Heal after his buffs, he will be able to heal slightly more for the team. However, his Arts effects do not activate immediately after use due to its animation cast time, which can be a detriment at times. Teamwide Skill refresh, while not reliably useful, has certain potential when paired with specific content or units such as those with ArtsGen on Skill.`,
            truearts: `Finally being buffed to the usual 4 arts/s of collab ArtsGen units, his overall ArtsGen has been boosted slightly. His 30% All Stats buff, which can act as a pseudo-heal allows for better team survivability, but should not be relied on as the only heals for harder content. With those 2 buffs in mind, Rimuru remains in the realm of a more than competent general-use support and ArtsGen unit. Lastly, his self lifesteal effect matters very little outside of very specific stages and is generally negligible.`,
            ability1: `A decent passive but does very little for most stages that don’t have Fire bosses`,
            ability2: `A great passive that helps Rimuru’s consistency. The Status RES passive is useful in certain situations but with cleanse built into his kit, it’s not as important `,
            ability3: `Allows for his equips to be used more frequently, albeit the difference is ever so slight`,
            trueweapon: {
                skill: `A respectable amount of Water Res down on a low uptime, the main upside for this equipment being that It's strong True Weapon passive almost guarantees that you'll bring it to any quest.`,
                ability1: `Further increases Rimuru's Self Sufficiency, as Rimuru's Equipment Options are limited to either Artsgen or DMG Support this equips' mix of both is much appreciated`,
                ability2: ``
            },
            overall: `Almost 4 years since the first release of this collab, Rimuru still holds up as the swiss army knife of supports, including in his arsenal an all stat buff, which also acts as a pseudo-heal, arts generation; healing; cleanse; and status res. Moreover, all of this is backed up by passive ArtsGen on self and great slots. There’s really not much to hate about Rimuru’s kit as a support unit. Though in certain aspects he has been powercrept due to his age, very few units come close to Rimuru’s sheer utility and flexibility as a support unit and would be a welcome addition to most boxes. His buffs simply helped to improve his overall kit closer to the addition of the units.`,
            lastupdated: `January 12, 2023`,
            author: `Jeffers`
        }
    },
    { //Milim - Complete, Review (Pre-buff) done
        id: 107166112,
        name: "Milim",
        rank: "A-",
        luckrank: "A",
        attribute: "Fire",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107165112.png",
                detailawk: "/db/Units/Detail/unit_detail_107166112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107165112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107166112.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7210,
                hpplus: 999,
                atk: 2905,
                atkplus: 417,
                def: 2750,
                defplus: 408
            },
        skillset: 
            {
                skill: "8s CT - 2500% Fire DMG (MAG). For 6s, increase own ATK by 30%.",
                skillbreak: 1000,
                arts: "22000% Fire DMG (MAG). For 10s, Enemies take [250 × DMG they inflict on this unit] DMG. For 15s, increase own Arts by 3/s.",
                artsbreak: 3500,
                truearts: "60000% Fire DMG (MAG). Increase DMG of this True Arts to Enemies in Break by 200% + For 15s, increase own Critical Rate by 100%.",
                trueartsbreak: 7000
            },
        passive: 
            {
                ability1: "Whenever this unit takes damage more than 10% of its HP, increase Arts by 5.",
                ability2: "Increase Freeze and Paralysis RES by 100%. DMG increases as HP of this unit decreases (up to a maximum of 50%).",
                ability3: "Increase own DMG by 50% during Break."
            },
        dream: {
            fire: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                    mat2amt: 50,
                    hover2: `Flametail Soulgem`,
                    mat3: `/db/Icons/dungeon_drops/fragDegilmagna.png`,
                    mat3amt: 2,
                    hover3: `Degilmagna`
                },
                passive: {
                    ability1: `Increase Critical DMG by 200%.`,
                    ability2: `Increase own Arts by 2/s.`
                }
            },
            earth: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                    mat2amt: 50,
                    hover2: `Smogdragon Soulgem`,
                    mat3: `/db/Icons/dungeon_drops/fragTonetaurus.png`,
                    mat3amt: 2,
                    hover3: `Tonetaurus`
                },
                passive: {
                    ability1: `Increase own Break Power against Earth Enemies by 100%.`,
                    ability2: `Increase own BE Output by 10%.`
                }
            }
        },
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                    mat3amt: 1,
                    hover3: "Degilmagna",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Milim's Necklace",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_152801.png",
                skill: "45s CT - 6000% Fire DMG (MAG). For 8s, reduce enemies' Fire RES by 40%.",
                skillbreak: 700,
                passive: 
                    {
                        ability1: "When equipped by Milim, increase own Break Power and ATK by 50%. Does not stack with Abilities of the same name.",
                        ability2: "Increase ATK by 5%."
                    }
            },
        lore: 
            {   
                evo5: "One of the oldest Demon Lords. She looks and acts lie a child, but her mind is based on a crafty Demon Lord. She is known as a Catastrophe class and is impossible to understand all of her profound powers. Initially, she was planning to create a new Demon Lord, but she becomes interested in Rimuru which he saw through her plans.",
                evoawk: "One of the Ten Greatest Demon Lords and yet the oldest. She has over ten times more Magicules than Rimuru, and no one can beat her. She feels bored of being a Demon Lord and envies Rimuru because he is having much more fun while not being a Demon Lord."
            },
        review: // needs to be updated
        {
            skill: `Just a minor ATK buff of 30%, but better than nothing.`,
            arts: `This is where Milim becomes interesting, because she is one of the only units being able to reflect the Units taken Damage to the boss with a uptime of 10s comparable to Duran’s uptime. With a little bonus of generating a total of 30 art.`,
            truearts: `With a decent multiplier, and being able to increase the Damage of her True Art by 200% to enemies in break paired with her increase of 100% Critical Rate. Milim will be able to do some great damage to enemies once broken.`,
            ability1: `A rather small amount of art tied to taking more than 10% HP.`,
            ability2: `A nice passive, if the boss can Freeze/Paralysis your units. On the other hand, the 50% damage buff increases as the HP decreases is appreciated, which will make Reflect Nuking more comfortable.`,
            ability3: `A huge Damage buff, pairing well with her True Arts if the boss is broken.`,
            trueweapon: {
                skill: `Considering you can get this for a cheap amount of Rainbow Gems or even for Free it's not exactly the worst thing ever, however True "Fomalhaut" (Berwick) exists and makes this questionable equip once you read it's passives.`,
                ability1: ``,
                ability2: `For those who prefer to use Milim as a sustain DPS, this could be a great pickup. However most players utilize Milim for her Reflect Nuke capabilities, and Reflect damage does not account for the ATK Stat, making this useless on Milim herself.`
            },
            overall: `Milim was, before the buffs, a competent Reflect Nuker, and a decent Break Nuker. With her new buffs, it’ll be easier to Reflect Nuke due to the extension of her NA Duration. As for Break Nuking, she’s capable of dishing out a good amount of Damage once the Enemy is broken. Although her uses are rather niche, mainly being Break Nuking and Reflect Nuking, she is able to fill those roles competently with the right setups.`,
            lastupdated: `January 12, 2023`,
            author: `Alice`
        }
    },
    { //Benimaru - Complete, Review done
        id: 107176112,
        name: "Benimaru",
        rank: "A-",
        luckrank: "A",
        attribute: "Fire",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107175112.png",
                detailawk: "/db/Units/Detail/unit_detail_107176112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107175112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107176112.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7300,
                hpplus: 999,
                atk: 2930,
                atkplus: 431,
                def: 2500,
                defplus: 343
            },
        skillset: 
            {
                skill: "8s CT - 1800% Fire DMG (PHY). Increase own Arts by 30.",                
                skillbreak: 900,
                arts: "23000% Fire DMG (MAG). For 20s, increase all Allies' Skill CT REC by 50% and DMG by 50%.",                
                artsbreak: 1800,
                truearts: "62000% Fire DMG (MAG). 100% chance to inflict Burn for 16s + If this True Arts inflicts Burn, for 20s, reduce Burnt Enemies' MAG RES by 120%.",
                trueartsbreak: 3900
            },
        passive: 
            {
                ability1: "When near-death, increase Arts by 2/s.",
                ability2: "Increase Critical Rate by 50%. Heal HP by 100/s.",
                ability3: "At Start of Quest, increase own Arts gauge by 50."
            },
        dream: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragFlogassa.png`,
                        mat3amt: 2,
                        hover3: `Flogassa`
                    },
                    passive: {
                        ability1: `Increase own CRI DMG by 150%.`,
                        ability2: `Increase own CRI rate by 50% against Burning enemies.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragRagsherumPhantom.png`,
                        mat3amt: 2,
                        hover3: `Ragsherum Phantom`
                    },
                    passive: {
                        ability1: `Increase all allies' MAG DMG by 30%. Does not stack with abilities of the same name.`,
                        ability2: `Increase all allies' Accuracy by 20%. Does not stack with abilities of the same name.`
                    }
                }
            },
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 1,
                    hover3: "Flogassa",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Benimaru's Sword",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_152811.png",
                skill: "45s CT - 6000% Fire DMG (PHY). For 8s, reduce enemies' Fire RES by 40%.",
                skillbreak: 700,
                passive: 
                    {
                        ability1: "When equipped by Benimaru, increase own Accuracy by 50% and own Arts at the start of quest by 100.",
                        ability2: "Increase ATK by 5%."
                    }
            },
        lore: 
            {   
                evo5: "A Kijin who follows Rimuru, a slime that was reincarnated to a different word. He used to be a race of Ogre. However, when Rimuru gave him a name, he evolved into a Kijin. Not only is he a master of swords, but he also uses fire magic and is excellent at large-area extermination.",
                evoawk: "A young Kijin who gave his loyalty to Rimuru. He is an Ogre survivor of an attack from the Orcs and seeks revenge. He was appointed as the Samurai General and integrates the country's army. He is like the right-arm of Rimuru and has great popularity."
            },
        review: //Updated for Dec 15 '23
        {
            skill: `30 arts is large amount of Arts for an Awoken Unit who's Maxes out at 200 Arts.`,
            arts: `Gives his allies more self sufficiency from their Artsgennerating Skills and a little damage boost, but generally not worth using over his True Arts unless bursting enemy down from a sliver of hp. At least the duration of the buffs are considerably good.`,
            truearts: `Providing the current largest amount of MAG RES on either version of the game, Benimaru is a strong choice for Magic Nukes whenever he is capable of Burning.`,
            ability1: `As always, self sufficiency is good, but you shouldn't be near death 99% of the time anyways.`,
            ability2: `An inconsistent amount of Crit Rate however it allows Benimaru to go above 100% Crit Rate whenever required.`,
            ability3: `Startup is very cool, helps to get his DPS up and going. Paired with his self sufficiency from skill, he gets his True Arts much faster now.`,
            trueweapon: {
                skill: `This one can be argued for due to the less accessible Fire RES Down equipment in Physical Slots, one being Angelas' Halloween & the other from the Marzex Event. Questionable if Benimaru would use this as he has a 5☆ Magic already.`,
                ability1: `Uh, sure? Accuracy is sparsely needed and even less so on a character who's main role is to provide DMG Support. The additional Start Up is appreciated for utilizing less Arts Equipment.`,
                ability2: ``
            },
            overall: `Not much to say here, pretty happy with the buffs he got this time, making him at the very least good again. While still not meta in any way, he at least does his DPS job well, with a side niche of Burn if you don't have other units to do so. And hey, Makoto Furukawa's voice is some ear candy for all you men lovers out there 😉 `,
            lastupdated: `January 12, 2023`,
            author: `Julian`
        }
    },
    { //Goblin Slayer - Complete (+ JP version)
        id: 107186512,
        name: "Goblin Slayer",
        rank: "A-",
        luckrank: "C",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107185512.png",
                detailawk: "/db/Units/Detail/unit_detail_107186512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107185512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107186512.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        slotsJP: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7250,
                hpplus: 999,
                atk: 2800,
                atkplus: 450,
                def: 2622,
                defplus: 403
            },
        statsJP: 
            {
                hp: 7177,
                hpplus: 999,
                atk: 2658,
                atkplus: 408,
                def: 2622,
                defplus: 403
            },
        skillset: 
            {
                skill: "9s CT - 1100% Dark DMG (PHY). Permanently increase own DMG & Break PWR every time Skill is used (Up to 100%).",
                skillbreak: 1200,
                arts: "22000% Water DMG (PHY). For 20s, reduce Enemies' Break RES and DMG RES by 30%.",
                artsbreak: 2800,
                truearts: "70000% Dark DMG (PHY). The less HP, the more DMG & Break PWR during True Arts (up to 300%) + For 60s, increases own Arts by 3/s.",
                trueartsbreak: 5000
            },
        skillsetJP: 
            {
                skill: "9s CT - 1100% Dark DMG (PHY). Permanently increase own DMG & Break PWR every time Skill is used (Up to 50%).",
                skillbreak: 1200,
                arts: "16000% Water DMG (PHY). For 10s, reduce Enemies' Break RES by 25% and DMG RES by 20%.",
                artsbreak: 2800,
                truearts: "42000% Dark DMG (PHY). The less HP, the more DMG & Break PWR during True Arts (up to 200%) + For 60s, increases own Arts by 2/s.",
                trueartsbreak: 3000
            },
        passive: 
            {
                ability1: "Increase DMG to Demon enemies by 100%. Increase Freeze and Paralysis RES by 100%.",
                ability2: "When near-death, increase Arts by 4/s."
            },
        passiveJP: 
            {
                ability1: "Increase DMG to Demon enemies by 50%. Increase Freeze and Paralysis RES by 100%.",
                ability2: "When near-death, increase Arts by 4/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat3amt: 1,
                    hover3: "Mavroskia",
                }
            },
        dreamGL: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                        mat3amt: 2,
                        hover3: "Ifrit",
                    },
                    passive: {
                        ability1: `Increase own DMG by 150% during BREAK.`,
                        ability2: `Increase own Arts by 2/s.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragValzandeth.png",
                        mat3amt: 2,
                        hover3: "Valzandeth",
                    },
                    passive: {
                        ability1: `Increase own Break Power by 100% against Demon enemies.`,
                        ability2: `Increase all allies' Skill CT Speed by 20% (Only once).`
                    }
                }
            },
        lore: 
            {   
                evo5: "An adventurer sworn to the eradication of goblins. He always wears an iron helm and a leather armor. He lost his parents when he was a a child, so he grew up with only his sister. However after he was forced to watch as goblins slaughtered her, he became obsessed with their complete annihilation.",
                evoawk: "A twisted silver-rank adventurer sworn to kill goblins. He possesses a deep understanding of the creatures, and in battle he is ready to use any weapon fitting to his goals. Other adventurers look on his actions with scorn and suspicion, but he has still saved countless lives."
            }
    },
    { //Priestess - Complete (+ JP version)
        id: 107196412,
        name: "Priestess",
        luckrank: "A",
        rank: "S",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107195412.png",
                detailawk: "/db/Units/Detail/unit_detail_107196412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107195412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107196412.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/healLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        slotsJP: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/healLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6500,
                hpplus: 999,
                atk: 2380,
                atkplus: 367,
                def: 2575,
                defplus: 400
            },
        statsJP: 
            {
                hp: 6250,
                hpplus: 947,
                atk: 2380,
                atkplus: 367,
                def: 2500,
                defplus: 385
            },
        skillset: 
            {
                skill: "10s CT - Heals all Allies' HP by 700.",
                skillbreak: 0,
                arts: "15000% Light DMG (MAG). 100% chance of inflicting Blind for 15s. Increase Allies' Arts by 30.",
                artsbreak: 1200,
                truearts: "For 45s, Allies gain a DMG barrier that negates 12000 DMG, increase own Skill CT REC and Healing Output by 100%.",
                trueartsbreak: 0
            },
        skillsetJP: 
            {
                skill: "10s CT - Heals all Allies' HP by 600.",
                skillbreak: 0,
                arts: "8800% Light DMG (MAG). High chance of inflicting Blind. Increase Allies' Arts by 25.",
                artsbreak: 1200,
                truearts: "For 45s, Allies gain a DMG barrier that negates 6500 DMG, increase own Skill CT REC and Healing Output by 100%.",
                trueartsbreak: 0
            },
        passive: 
            {
                ability1: "Increase Poison RES by 50%.",
                ability2: "Increase own Arts by 2/s. Increase Allies' Max HP by 20%. Does not stack with Abilities of the same name."
            }, 
        passiveJP: 
            {
                ability1: "Increase Poison RES by 50%.",
                ability2: "Increase own Arts by 2/s. Increase Allies' Max HP by 10%. Does not stack with Abilities of the same name."
            },
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat3amt: 1,
                    hover3: "Radachne",
                }
            },
        dreamGL: {
            water: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                    mat2amt: 50,
                    hover2: `Frostsprite Soulgem`,
                    mat3: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                    mat3amt: 2,
                    hover3: "Geo-Zagnus",
                },
                passive: {
                    ability1: `Adds "Increase all allies' Arts by 10" effect to Skill.`,
                    ability2: `Increase all allies' Water RES by 20% (Only once).`
                }
            },
            light: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                    mat2amt: 50,
                    hover2: `Eternaglint Soulgem`,
                    mat3: "/db/Icons/dungeon_drops/fragWikes.png",
                    mat3amt: 2,
                    hover3: "Wilkes",
                },
                passive: {
                    ability1: `Increase all allies' Heal Amount by 20% (except Lifesteal).`,
                    ability2: `Increase all Human allies' Arts by 2/s (Only once).`
                }
            }
        },
        lore: 
            {   
                evo5: "This green adventurer only recently left the temple of the Earth Mother. On her first outing as an adventurer, she was attacked by goblins and was saved by Goblin Slayer at the last minute. Ever since she has joined him on his quests. She is often perplexed by his erratic behavior, but in truth, she trusts him deeply.",
                evoawk: "A young priestess of the Earth Mother. she has accompanied Goblin Slayer ever since he saved her life. She hates fighting, but she uses the miracles of the Earth Mother to help support her comrades. She is pious and peaceful, and her mutual trust with Goblin Slayer helps her to withstand the hardships of battle."
            }
    },
    { //High Elf Archer - Complete (+ JP version)
        id: 107206312,
        name: "High Elf Archer",
        luckrank: "S",
        rank: "A-",
        attribute: "Earth",
        type: "Spirit",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107205312.png",
                detailawk: "/db/Units/Detail/unit_detail_107206312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107205312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107206312.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        slotsJP: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6950,
                hpplus: 999,
                atk: 2900,
                atkplus: 450,
                def: 2408,
                defplus: 371
            },
        statsJP: 
            {
                hp: 6837,
                hpplus: 999,
                atk: 2766,
                atkplus: 424,
                def: 2408,
                defplus: 371
            },
        skillset: 
            {
                skill: "8s CT - 1800% Earth DMG (PHY). For 5s, reduce Enemies' ATK by 20%.",
                skillbreak: 800,
                arts: "25000% Earth DMG (PHY). 60% chance of inflicting Faint for 3s. If Enemy was Fainted by this Arts, for 15s, reduce that Enemy's PHY RES by 60%.",
                artsbreak: 2200,
                truearts: "77000% Earth DMG (PHY). For 25s, increase Allies' Critical Rate by 100% and evasion by 20%.",
                trueartsbreak: 3500
            },
        skillsetJP: 
            {
                skill: "8s CT - 1800% Earth DMG (PHY). For 5s, reduce Enemies' ATK by 20%.",
                skillbreak: 800,
                arts: "17000% Earth DMG (PHY). Chance of inflicting Faint. If Enemy was Fainted by this Arts, for 10s, reduce that Enemy's PHY RES by 40%.",
                artsbreak: 2200,
                truearts: "37000% Earth DMG (PHY). For 12s, increase Allies' Critical Rate by 100% and evasion by 20%.",
                trueartsbreak: 3500
            },
        passive: 
            {
                ability1: "Increase Critical DMG by 80%.",
                ability2: "Increase own Evasion by 10% and increase own Accuracy by 50%."
            }, 
        passiveJP: 
            {
                ability1: "Increase Critical DMG by 60%.",
                ability2: "Increase own Evasion by 10% and increase own Accuracy by 30%."
            },
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat3amt: 1,
                    hover3: "Tonetaurus",
                }
            },
        dreamGL: {
            earth: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                    mat2amt: 50,
                    hover2: `Smogdragon Soulgem`,
                    mat3: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat3amt: 2,
                    hover3: "Tonetaurus",
                },
                passive: {
                    ability1: `Increase own Arts by 200 at the Start of Quest (Only once).`,
                    ability2: `Increase all allies' PHY DMG By 30% (Only once).`
                }
            },
            dark: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                    mat2amt: 50,
                    hover2: `Sinflow Soulgem`,
                    mat3: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat3amt: 2,
                    hover3: "Oldeus",
                },
                passive: {
                    ability1: `Increase all allies' Accuracy by 30% (Only once).`,
                    ability2: `Increase own Arts by 3/s.`
                }
            }
        },
        lore: 
            {   
                evo5: "A High Elf adventurer who joined Goblin Slayer's party. She uses the bow in battle, and she's incredibly accurate at long distances. The High Elves are long-lived people, and although she appears quite young, she is in truth 2000 years old. And yet, inside she remains somehow childlike.",
                evoawk: "A High Elf ranger. She's not skilled at close combat, but he marksmanship and physical abilities offer powerful support from the rear, so her Silver adventurer rank is well-earned. She left her home to see the world as an adventurer, and now her main goal is to show Goblin Slayer the joys of adventuring."
            }
    },
    { //Sonic - Complete + JP ver
        id: 107216512,
        name: "Sonic",
        luckrank: "A",
        rank: "A-",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107215512.png",
                detailawk: "/db/Units/Detail/unit_detail_107216512.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_721_1.png", 
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107215512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107216512.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_721_1.png",
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        slotsJP: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6893,
                hpplus: 999,
                atk: 2607,
                atkplus: 401,
                def: 2514,
                defplus: 387
            },
        skillset: 
            {
                skill: "8s CT - 2100% Fire DMG (PHY). For 5s, reduce Enemies' DEF by 20%.",
                skillbreak: 400,
                arts: "25000% Dark DMG (PHY). For 20s, increase own PHY evasion by 30% and Arts by 5/s.",
                artsbreak: 900,
                truearts: "65000% Dark DMG (PHY). For 20s, increase own evasion by 30% and Critical Rate by 200%.",
                trueartsbreak: 1200
            },
        skillsetJP: 
            {
                skill: "8s CT - 2100% Fire DMG (PHY). For 5s, reduce Enemies' DEF by 20%.",
                skillbreak: 400,
                arts: "18000% Dark DMG (PHY). For 15s, increase own PHY evasion by 30% and Arts by 3/s.",
                artsbreak: 900,
                truearts: "40000% Dark DMG (PHY). For 20s, increase own evasion by 30% and Critical Rate by 200%.",
                trueartsbreak: 1200
            },
        passive: 
            {
                ability1: "Increase PHY evasion by 10%.",
                ability2: "Increase Equipment CT REC by 20%. DMG increases by the magnitude of Critical Rate buffs.",
                ability3: `Unit EXP UP 20% UP. (When Sonic's SP. Art is owned)`,
                ability4: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Sonic's SP. Art is owned)`
            }, 
        passiveJP: 
            {
                ability1: "Increase PHY evasion by 10%.",
                ability2: "Increase Equipment CT REC by 10%. DMG increases by the magnitude of Critical Rate buffs."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat3amt: 1,
                    hover3: "Oldeus",
                }
            },
        dreamGL: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 30,
                        hover2: `Frostsprite Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragGeoZagnus.png",
                        mat3amt: 1,
                        hover3: "Geo Zagnus",
                    },
                    passive: {
                        ability1: `Increase own Arts by 200 at Start of Quest. Does not stack with Abilities of the same name.`,
                        ability2: `Increase allies' PHY DMG by 30%. Does not stack with Abilities of the same name.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 30,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragRagsherumPhantom.png",
                        mat3amt: 1,
                        hover3: "Ragsherum Phantom",
                    },
                    passive: {
                        ability1: `Increase own CRI DMG by 60%.`,
                        ability2: `Increase Dark allies' Arts by 2/s. Does not stack with Abilities of the same name.`
                    }
                }
            },    
        lore: 
            {   
                evo5: "A ninja with absolute confidence in speed. He is obsessed with Saitama because he was once defeated by him. His objective is only to beat him as well.",
                evoawk: "He is the self-proclaimed 'Strongest Ninja'. He is equal in ability as the S-Class Hero, Genos. His obsession to defeat Saitama is so deep, he is prepared to do anything."
            }
    },
    { //Hellish Blizzard - Complete + JP ver
        id: 107226212,
        name: "Hellish Blizzard",
        luckrank: "A",
        rank: "A",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107225212.png",
                detailawk: "/db/Units/Detail/unit_detail_107226212.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_722_1.png", 
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107225212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107226212.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_722_1.png",
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        slotsJP: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6674,
                hpplus: 999,
                atk: 2390,
                atkplus: 368,
                def: 2485,
                defplus: 382
            },
        skillset: 
            {
                skill: "9s CT - 2000% Water DMG (MAG). For 5s, reduce Enemies' Movement Speed by 10%.",
                skillbreak: 1000,
                arts: "20000% Water DMG (MAG). 80% chance to inflict Faint for 5s. For 20s, increase Allies' BE Output by 100%.",
                artsbreak: 2000,
                truearts: "65000% Water DMG (MAG). For 15s, increase Allies' MAG DMG by 200% and reduce enemies' MAG RES by 30%.",
                trueartsbreak: 2400
            },
        skillsetJP: 
            {
                skill: "9s CT - 2000% Water DMG (MAG). For 5s, reduce Enemies' Movement Speed by 10%.",
                skillbreak: 1000,
                arts: "14000% Water DMG (MAG). 60% chance to inflict Faint for 3s. For 10s, increase Allies' BE Output by 80% for 10s.",
                artsbreak: 2000,
                truearts: "30000% Water DMG (MAG). For 15s, increase Allies' MAG DMG by 120%.",
                trueartsbreak: 2400
            },
        passive: 
            {
                ability1: "At the start of a Quest, gain a DMG barrier that negates 2500 DMG.",
                ability2: "Increase Arts by 3/s.",
                ability3: "Increase DMG to Fire Enemies by 100%.",
                ability4: `Unit EXP UP 20% UP. (When Hellish Blizzard's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Hellish Blizzard's SP. Art is owned)`
            }, 
        passiveJP: 
            {
                ability1: "At the start of a Quest, gain a DMG barrier that negates 2500 DMG.",
                ability2: "Increase Arts by 2/s.",
                ability3: "Increase DMG to Fire Enemies by 30%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat3amt: 1,
                    hover3: "Freydal",
                }
            },
            dreamGL: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 30,
                        hover2: `Frostsprite Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragAerugraxes.png",
                        mat3amt: 1,
                        hover3: "Aerugraxes",
                    },
                    passive: {
                        ability1: `Increase allies' Fire RES by 20%. Does not stack with Abilities of the same name.    `,
                        ability2: `Increase own Break Power against Fire enemies by 100%.`
                    }
                },
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 30,
                        hover2: `Smogdragon Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragElpneumas.png",
                        mat3amt: 1,
                        hover3: "Elpneumas",
                    },
                    passive: {
                        ability1: `Increase allies' MAG DMG by 40%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase Human allies' Arts by 2/s. Does not stack with Abilities of the same name.`
                    }
                }
            },
        lore: 
            {   
                evo5: "She is registered with the Hero Association. She is a B-Class Rank-1 Hero and the leader of the Blizzard group of B-Class Heroes.",
                evoawk: "She is a B-Class Rank 1 Hero. She is a powerful psychic, and biological sister of the S-Class Rank 2 Hero, Terrible Tornado."
            }
    },
    { //Garou - Complete + JP ver
        id: 107236512,
        name: "Garou",
        rank: "A-",
        luckrank: "A",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107235512.png",
                detailawk: "/db/Units/Detail/unit_detail_107236512.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_723_1.png", 
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107235512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107236512.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_723_1.png",
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        slotsJP: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7055,
                hpplus: 999,
                atk: 2731,
                atkplus: 419,
                def: 2308,
                defplus: 356
            },
        skillset: 
            {
                skill: "7s CT - 1800% Dark DMG (PHY). For 4s, reduce Enemies' accuracy by 10%.",
                skillbreak: 500,
                arts: "33000% Dark DMG (PHY). For 20s, reduce enemies' Evasion rate by 70%. For 40s, increase own Accuracy by 70% and Skill CT REC by 70%.",
                artsbreak: 1700,
                truearts: "80000% Dark DMG (PHY). Permanently increase own Arts DMG by 100% (up to 500%).",
                trueartsbreak: 3000
            },
        skillsetJP: 
            {
                skill: "7s CT - 1800% Dark DMG (PHY). For 4s, reduce Enemies' accuracy by 10%.",
                skillbreak: 500,
                arts: "19000% Dark DMG (PHY). For 20s, reduce enemies' Evasion rate by 30%, increase own Accuracy by 50% and Skill CT REC by 70%.",
                artsbreak: 1700,
                truearts: "50000% Dark DMG (PHY). Permanently increase own Arts DMG by 60% (up to 300%).",
                trueartsbreak: 3000
            },
        passive: 
            {
                ability1: "When near-death, increase DMG RES by 20%.",
                ability2: "When near-death, increase Skill CT REC by 100%. Gradually increase ATK & DEF for first 60s of quest (Up to 100%).",
                ability3: `Unit EXP UP 20% UP. (When Garou's SP. Art is owned)`,
                ability4: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Garou's SP. Art is owned)`
            }, 
        passiveJP: 
            {
                ability1: "When near-death, increase DMG RES by 20%.",
                ability2: "When near-death, increase Skill CT REC by 100%. Gradually increase ATK & DEF for first 60s of quest (Up to 50%)."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragValzandeth.png",
                    mat3amt: 1,
                    hover3: "Valzandeth",
                }
            },
        dreamGL: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 30,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                        mat3amt: 1,
                        hover3: "Degilmagna",
                    },
                    passive: {
                        ability1: `Increase own Arts by 5/s when Near-Death.`,
                        ability2: `Increase own Status RES by 80% (except Faint).`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 30,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragOldeus.png",
                        mat3amt: 1,
                        hover3: "Oldeus",
                    },
                    passive: {
                        ability1: `Increase own DMG against enemies under 30% HP by 100%.`,
                        ability2: `Increase own Arts by 200 at Start of Quest. Does not stack with Abilities of the same name.`
                    }
                }
            },
        lore: 
            {   
                evo5: "He is a Human who calls himself a Monster. He trained under a S-Class Hero master but was expelled, so he burns in the obsession of Hero Hunting.",
                evoawk: "He is a Human who calls himself 'Hero Hunter'. He refuses the common sense of good and evil and he's committed in defeating heroes as a villain."
            }
    },
    { //Crunchyroll-HIME - Complete
        id: 107246112,
        name: "Crunchyroll-Hime",
        rank: "B",
        attribute: "Fire",
        type: "Beast",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107245112.png",
                detailawk: "/db/Units/Detail/unit_detail_107246112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107245112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107246112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7042,
                hpplus: 999,
                atk: 2220,
                atkplus: 352,
                def: 2350,
                defplus: 392
            },
        skillset: 
            {
                skill: "7s CT - 1800% Fire DMG (MAG). Chance to inflict Burn.",
                skillbreak: 600,
                arts: "16000% Fire DMG (PHY). For 10s, increase Allies' Critical Rate by 50%, increase Fire Allies' ATK by 50%.",
                artsbreak: 1700,
                truearts: "40000% Fire DMG (MAG). For 15s, increase Allies' evasion by 15%, heal Allies' HP by 450/s.",
                trueartsbreak: 2900
            },
        passive: 
            {
                ability1: "Increase Gren gained on Quests by 20%. Does not stack with Abilities of the same name.",
                ability2: "Increase Player EXP gained on Quests by 10%. Does not stack with Abilities of the same name.",
                ability3: "When this unit takes lethal DMG, revive with 50% HP. Ability can only be activated once per Quest."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 1,
                    hover3: "Flogassa"
                }
            },
        lore: 
            {   
                evo5: "Crunchyroll-Hime is the champion of all things anime, and she's also a fantastic adventurer when duty calls, donning her trusty bow and accompanied by her mortal companion, Yuzu. Born hundreds of years ago, Hime can't remember her real name, or how she looked as a Kitsune, but once you get to know her, it's undeniable that she is one. She loves nothing more than playing tricks on people, and volunteering up riddles.",
                evoawk: "Crunchyroll-Hime is the champion of all things anime, and she's also a fantastic adventurer when duty calls, donning her trusty bow and accompanied by her mortal companion, Yuzu. Born hundreds of years ago, Hime can't remember her real name, or how she looked as a Kitsune, but she does know the burning fire of her true form rages inside of her. She's able to channel this power and defeat any foe she faces, and save any friend she needs to."
            }
    },
    { //Naofumi - Complete, Review done
        id: 107256411,
        name: "Naofumi",
        rank: "A",
        luckrank: "F",
        twrequire: "favorable",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107255411.png",
                detailawk: "/db/Units/Detail/unit_detail_107256411.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107255411.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107256411.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6901,
                hpplus: 999,
                atk: 2012,
                atkplus: 311,
                def: 3009,
                defplus: 461
            },
        skillset: 
            {
                skill: "12s CT - For 12s, heal for 10% of DMG taken, increase Freeze and Paralysis RES by 100%.",
                skillbreak: 0,
                arts: "For 20s, increase Allies' DMG by 25%, redirect Allies' DMG taken to self and gain a DMG barrier that negates 11000 DMG to self. 0 BREAK",
                artsbreak: 0,
                truearts: "80% chance to inflict Faint for 7s. If at least 1 enemy is inflicted with Faint: 55000% Dark DMG (PHY). This attack ignores 100% of enemy DEF. If no enemies are inflicted with Faint: On hit, heal self by 60% + Increase own Arts by 100 (this deals no BREAK to the enemy as well).",
                trueartsbreak: 4000
            },
        passive: 
            {
                ability1: "Increase DEF by 20% when under a DMG barrier.",
                ability2: "At the start of a Quest, for 20s, grant Allies a DMG barrier that negates 3000 DMG. Normal attacks increase own DMG RES by 10% for 2s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragNaofumi.png",
                    mat3amt: 20,
                    hover3: "Naofumi"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                true1: 
                {
                    name: "Small Shield",
                    slot: "/db/Mines/thumbnail/defLB.png",
                    thumb: "/db/Equipment/Thumbnail/item_thumbnail_150691.png",
                    detail: "/db/Equipment/Detail/item_detail_150691.png",
                    skill: "45s CT - For 10s, increase target's DEF by 60%, DMG RES by 40%, and target heals for 10% of DMG taken.",
                    skillbreak: 0,
                    passive: 
                        {
                            ability1: "When equipped by Naofumi, increase HP by 25% + Increase own Arts by 1/s.",
                            ability2: "Heal HP by 4/s."
                        }
                },
                true2: 
                {
                    name: "Wrath Shield",
                    slot: "/db/Mines/thumbnail/healLB.png",
                    thumb: "/db/Equipment/Thumbnail/item_thumbnail_151191.png",
                    detail: "/db/Equipment/Detail/item_detail_151191.png",
                    skill: "35s CT - For 20s, heal Target's HP by 3%/s. If user is Light, cure Target's Seal.",
                    skillbreak: 0,
                    passive: 
                        {
                            ability1: "When equipped by Naofumi, increase PHY RES by 35%, and increase barrier gained from own Arts by 4000.",
                            ability2: "Increase DEF by 10%."
                        }
                }
                
            },
        lore: 
            {   
                evo5: "The Shield Hero, one of the the Four Holy Heroes. Once an ordinary young man, he was summoned to a medieval fantasy world to fight against hordes of monsters, which occurence was known as 'waves'. He was excited to be in a video game-like world but soon was betrayed by several heartless people. Disappointed and depressed, he became unable to believe anyone. Eventually, he found a few people who are now his allies. However, his name is still disgraced, and he remains a fugitive of the kingdom.",
                evoawk: "When Rayas summoned him, Naofumi was fleeing from the army of Melromarc, accused of kidnapping and murdering the princess. Due to this traumatic experience, he could not open up to other people and kept a distance between Rayas and his friends. However, as he connected with the people of Reigrad, little by little, his frozen heart began to melt. As Naofumi grew closer to his new friends, he made a surprising proposal. Rayas and his friends agreed, but yet little did they know what would follow...!"
            },
        review:
            {
                skill: `Skills usually don't stand out in a unit's kit, but this one fits real nicely with Naofumi's role as a Taunt tank. Freeze and Paralysis, especially if the ailment lasts very long, can be such a bother, but with this skill on loop and Naofumi taunting for the team, Freeze/Paralysis won't be an issue for the most part.`,
                arts: `The central part of his entire kit, redirecting damage taken towards himself while providing him a chonky barrier to help soak up the damage. Additionally, he provides some extra offensive support with a small damage buff for the entire team. With the buff he received to his Arts’ barrier amount, he is able to generate a bigger barrier compared to other taunt tanks such as Gerald and Celia.`,
                truearts: `Most quests don't require Faint or DEF ignore, and Naofumi simply isn't a good source of damage to begin with, even after the buffed DMG multiplier he received. Unless inflicting Faint has a notable counter to a certain boss's mechanics, like Ragsherum Phantom (Giant Boss) for example, Naofumi’s Arts would generally be used more often than his True Arts.`,
                ability1: `A small, additional DEF boost to help make Naofumi a bit tankier.`,
                ability2: `A nice barrier to help with your team's survivability for when the enemy deals a hefty amount of damage right off the bat, giving Naofumi some leeway before he is able to start taunting.`,
                trueweapon: {
                    true1: {
                        skill: `True "Small Shield" provides great defensive effects, but it has a pretty short duration while having a fairly long cooldown. If uptime isn't issue, feel free to run it. If it is, then you may want to opt for a higher uptime DEF equip instead.`,
                        ability1: `Increases his HP by a fairly reasonable amount, but it usually wouldn't matter much when under a barrier. Some art gauge generation, while small, is also always nice.`,
                        ability2: `It doesn't really have an impact whatsoever. You could treat this as if it's not even there. That's how insignificant the healing value is.`
                    },
                    true2: {
                        skill: `It provides some impressive single-target healing and seal cleansing, which may come in handy for Naofumi as sealing can obstruct him from reapplying his damage redirection or Freeze/Paralysis resistance.`,
                        ability1: `This would be the main reason for equipping this item on Naofumi. It substantially increases his barrier amount from 11000 to a whopping 15000, and the hefty 35% physical resistance gives him an edge over other tanks when it comes to handling Physical-based bosses.`,
                        ability2: `Small but still appreciable DEF boost towards his tanking capabilities.`
                    }
                },
                overall: `In short, Naofumi Iwatani offers tanking capabilities that are slightly better than other taunt tanks such as Gerald and Celia, but he can surpass them even more when he is equipped with his true weapons in the right situations. His true equipment (most notably True “Shield of Rage”) provides significant boosts to his ability to tank Physical bosses,, but he can still perform just fine even without it. What's also impressive is the fact that he is a FREE unit, giving newer players and unfortunate souls the opportunity to have a taunt tank and a luck unit. He's an easy and useful pick-up for newer players to help tackle hard-hitting quests, whilst still being very much a usable tank for those with more developed accounts. In terms of being a luck unit, Naofumi does not bring much to the table other than his high luck stat and being able to tank when farming something more difficult, but luck farms usually don't necessitate a tank.`,
                lastupdated: `July 7, 2022`,
                author: `Jeffers`
            }
    },
    { //Raphtalia - Complete, Review done
        id: 107266412,
        name: "Raphtalia",
        luckrank: "F",
        rank: "C",
        attribute: "Light",
        type: "Beast",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107265412.png",
                detailawk: "/db/Units/Detail/unit_detail_107266412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107265412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107266412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6700,
                hpplus: 999,
                atk: 3094,
                atkplus: 474,
                def: 2219,
                defplus: 342
            },
        skillset: 
            {
                skill: "10s CT - 1500% Light DMG (PHY). Increase Arts by 15 + If this Skill crits, increase Arts by an additional 15.",
                skillbreak: 400,
                arts: "15000% Light DMG (PHY). When this attack Crits, increase Arts by 70 (only once per Arts). For 20s, if an Ally is redirecting DMG, increase Max HP of that Ally by 50% and heal that Ally by 150/s.",
                artsbreak: 800,
                truearts: "65000% Light DMG (PHY). For 35s, increase Critical Rate and Skill CT REC by 50%.",
                trueartsbreak: 1500
            },
        passive: 
            {
                ability1: "Increase Critical Rate by 20%.",
                ability2: "Increase Accuracy by 20%. If an Ally is redirecting DMG, increase DMG by 100%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat3amt: 1,
                    hover3: "Radachne",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Raphtalia's Sword",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_150711.png",
                skill: "35s CT - 4500% Light DMG (PHY). For 8s, increase ATK and Skill CT REC by 100%.",
                skillbreak: 700,
                passive: 
                    {
                        ability1: "When equipped by Raphtalia, increase DMG and Critical Rate by 30%.",
                        ability2: "Increase Skill CT REC by 10%."
                    }
            },
        lore: 
            {   
                evo5: "The Shield Hero's first companion, who is Demi-human. When Naofumi purchased her from the slave trader, she was just a child. However, the training with Naofumi led to her rapid development, and currently, she looks like an adult. At first, she was afraid of Naofumi as her slave master, but she came to understand his true, kind nature and now admires him. Admittedly, she has feelings for Naofumi, but since he was betrayed by a certain woman, he does not seem to care for romance... leaving Raphtalia somewhat lovelorn by her predicament.",
                evoawk: "Raphtalia was summoned to Raktehelm along with Naofumi, Filo and Melty. She tries to act as a bridge between Naofumi and others without much success, Rayas and his friends included. However, on the battlefield, Raphtalia's sword skills are not to be outdone by Rayas and his friends as she shows off her techniques proudly."
            },
        review:
            {
                skill: `A surprisingly good amount of innate arts generation already built into the kit, allowing Raphtalia to be more self-sufficient, or at the very least, be able to activate her kit more readily. This is especially so when paired with her True Arts’ Skill CT recovery buff and overall innate Crit Rate buffs.`,
                arts: `With the large refund of arts, this will likely serve as Raphtalia’s primary source of damage output after a True Arts use due to how immensely spammable it is. Furthermore, it has notable synergies with taunt tanks, though the heal generally won’t make too much of a difference as they’re spamming barriers anyways. As a side note, the 70 Arts refund does not have a limit, but rather is limited to one activation every Arts.`,
                truearts: `A large multiplier paired with buffs that synergize well with the rest of her kit. The general strategy would be to take advantage of the 35s buffs from her True Arts to spam her Arts continuously. Coupled with the ArtGen from her Skill, she will be self-sufficient for those 35s spamming Arts.`,
                ability1: `Nothing too spectacular on its own, though it does make her Arts refund more reliable as well as bolster her self-sufficiency from her Skill.`,
                ability2: `Accuracy is a fairly niche skill that won’t have an impact on most stages. The DMG up, although conditional, is relatively powerful on its own, boosting Raphtalia’s overall DMG at the cost of having to run a Taunt tank.`,
                trueweapon: {
                    skill: `This is a great equip, not only on Raphtalia, but also on units whose Skill acts as a major part of their kit (in Raphtalia’s case, it’ll optimally generate 0.68 arts/sec). Although it won’t be explicitly better than an Arts generation equip on most units, it still holds value outside of being bound to Raphtalia.`,
                    ability1: `With this True Weapon, this means that Raphtalia can effectively reach 100% Critical Rate when combined with her True Arts and passive, allowing for much more consistent spamming of her arts. The DMG is generally just an added bonus and cements this item as one of the best for Raphtalia.`,
                    ability2: `A passive that has a specific audience. On most units, this won’t mean much, but like the equipment’s active effect, this can do extremely well on units where their Skill is an essential part of their kit.`
                },
                overall: `While Raphtalia was never a beacon of DPS units, she was certainly able to get the job done with her very arts spam-oriented kit. Despite her seemingly-fun kit, however, she offers very little outside of the damage she provides, making her a relatively weak asset to teams given her only strength is shared by a large pool of DPS units. On luck teams, she still fares well enough due to her gacha status and self-sufficiency, but finds difficulty competing with much faster options. Overall, she's a decent beginner DPS option who will likely get phased out rather quickly once players get access to better alternatives.`,
                lastupdated: `July 7, 2022`,
                author: `Jeffers`
            }
    },
    { //Filo - Complete, Review done
        id: 107276312,
        name: "Filo",
        luckrank: "C",
        rank: "B+",
        attribute: "Earth",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107275312.png",
                detailawk: "/db/Units/Detail/unit_detail_107276312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107275312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107276312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7171,
                hpplus: 999,
                atk: 2806,
                atkplus: 430,
                def: 2663,
                defplus: 409
            },
        skillset: 
            {
                skill: "9s CT - 2300% Earth DMG (PHY). Permanently increase own ATK by 20% (up to 100%).",
                skillbreak: 800,
                arts: "20000% Earth DMG (MAG). For 15s, reduce Enemies' Action Speed by 20% and DEF by 50%.",
                artsbreak: 1500,
                truearts: "68000% Earth DMG (PHY). The lower Enemies' health, the greater the increase in DMG of this True Arts (up to 350%).",
                trueartsbreak: 3000
            },
        passive: 
            {
                ability1: "Increase PHY RES by 20% and Increase own Arts by 2/s. If own HP is above 80%, increase Arts by 4/s instead.",
                ability2: "Increase DMG to Dragon Enemies by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat3amt: 1,
                    hover3: "Tonetaurus",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Filo's Hairpin",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_151801.png",
                skill: "55s CT - 7000% Earth DMG (MAG). For 10s, increase own Critical Rate by 70%. If user is Earth, increase own Critical Rate by 100% instead.",
                skillbreak: 700,
                passive: 
                    {
                        ability1: "When equipped by Filo, increase DMG by 30% and Arts by 1/s.",
                        ability2: "Increase Skill DMG by 10%."
                    }
            },
        lore: 
            {   
                evo5: "Filo is a 'Filolial', which is a bird-like monster that hatched from the egg Naofumi Iwatani purchased from the slave master. She was born in bird form, but since her caretaker was naofumi, one of the legendary heroes, she was able to change form into a small girl with wings like an angel. Although her personality is quite childish and often can't quite follow difficult conversations, Filo admires Naofumi as her master and Raphtalia as her sister. As soon as she met Melty, Filo instantly became friends with her, and have been besties since.",
                evoawk: "Filo was summoned to Raktehelm along with Naofumi. Alghough she is in a different world, she is easy-going as always and warms up to Rayas and his friends right off the bat. It seems that she can understand Rolly's language... and also occasionally shows off her abilities as a monster. Since she was always on the run with Naofumi and his party, she was unable to eat proper meals. But in Reigrad, she's been given delicious meals at every turn and has been able to fully recharge. She'll pick fights with Raphtalia, but that's because Filo knows Naofumi can't attack back."
            },
        review:
            {
                skill: `Although 100% ATK up is a sizeable increase to her damage, unless its a multi-staged mission it'll take the majority of the mission's time to reach max stacks.`,
                arts: `Nothing too special about this, reducing enemy defense is lackluster at best due to crits negating defense anyway. The action speed down is an although a great debuff doesn't warrant using her Normal Art over her True Art.`,
                truearts: `While her increased multiplier does help her stand out amoung the countless DPS option the fatal flaw in her kit still remains, doing more damage to an enemy the lower its health is means she only can really shine when the boss is on deaths door.`,
                ability1: `Artgen passives are always loved, although the extra 2 aps being locked behind high HP does make it a bit less consistent. The DMG res is a nice addition as well, just a shame it's limited to Physical only`,
                ability2: `A sizeable addition to her damage against dragon bosses`,
                trueweapon: {
                    skill: `While being an option to give filo 100% crit rate, it's low uptime prevents it from being an amazing choice.`,
                    ability1: `Offer Filo 1 more aps is a solid addition while the minor dmg boost also helps Filo get her TA ramp started`,
                    ability2: `This is indeed...something, not something good but something
                    `
                },
                overall: `With her latest buffs Filo can finally hold her own against other DPS option. The increased self sustainability from having up to 5 art per second helps increase her consistency across the board. While her massive 68k multiplier greatly helps boost the DMG up she gets from lowering the bosses HP, it still has the fatal flaw of being strongest when the boss is almost dead. As a luck unit Filo is a solid option, even more so if she's played to her strengths. By waiting for others to use their True arts first it allows filo to get more of the DMG up from the bosses HP being lower.`,
                lastupdated: `July 7, 2022`,
                author: `Zanster`
            }
    },
    { //Melty - Complete, Review done
        id: 107286212,
        name: "Melty",
        rank: "A",
        luckrank: "A",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107285212.png",
                detailawk: "/db/Units/Detail/unit_detail_107286212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107285212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107286212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6685,
                hpplus: 999,
                atk: 2691,
                atkplus: 413,
                def: 2548,
                defplus: 392
            },
        skillset: 
            {
                skill: "10s CT - Heal Allies’ HP by 250 and increase Allies' Arts by 10.",
                skillbreak: 0,
                arts: "15000% Water DMG (MAG). For 15s, increase Allies’ ATK by 40%, Arts by 3/s.",
                artsbreak: 1000,
                truearts: "35000% Water DMG (MAG). For 20s, increase Allies’ Fire RES by 20%, reduce Enemies' DMG RES by 60%.",
                trueartsbreak: 1500
            },
        passive: 
            {
                ability1: "Increase Seal RES by 50%. Increase Allies’ Arts DMG by 20%.",
                ability2: "When near-death, increase Skill CT REC by 20%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragAerugraxes.png",
                    mat3amt: 1,
                    hover3: "Aerugraxes"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Melty's Dress",
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_151791.png",
                skill: "40s CT - For 8s, increase Allies' DMG RES by 10% and Arts gauge by 3/s.",
                skillbreak: 700,
                passive: 
                    {
                        ability1: "When equipped by Melty, the DMG RES and Arts generation buff of this Equip's Skill is doubled. Does not stack with Abilities of the same name.",
                        ability2: "Increase DEF by 5%."
                    }
            },
        lore: 
            {   
                evo5: "The Second Princess of Melromarc, which is the country Naofumi must protect as a hero. She is the first in line to the throne, even higher than her older sister. She is furious at her father and sister, who tried to frame Naofumi. Yet, they are her family and she suffers silently as she cannot sever her ties. She is typically a kind girl, but due to her royal stature she is forced to be strict and more adult-like than she actually feels. She was almost murdered in a succession conflict between her older sister and she has not gotten a good night's rest since finding out the crime against her was blamed on Naofumi.",
                evoawk: "Melty was summoned to Raktehelm along with Naofumi, Raphtalia, and Filo. She is against Naofumi's opinion of remaining in Raktehelm because she wishes to return to Melromarc as soon as possible. However, knowing Naofumi feels more at home than ever in Raktehelm, she cannot deny his request outright and feels anxious."
            },
        review:
            {
                skill: `An extremely potent skill, 10 instant teamwide arts with a small heal. The CT has been shortened by 3s for a net contribution of 1 arts/s.`,
                arts: `The atk buff has been buffed from 40% to 50%, and the duration from 13s to 15s. The extra attack buff is a nice added touch, but the extra duration allows an easier cycling of Melty’s arts. More importantly though, the art gen type has been modified from type B to type E, meaning that now, Melty’s NA stacks with the likes of Rosetta, Hart and Juno, but it no longer stacks with other collab units such as Yoh and the Mikus. This arts is perfect for a sustain-oriented approach, its low cost enables to start party arts quickly, and Melty now boasts one of the highest raw artsgen rates on NA for any unit.`,
                truearts: `The multiplier itself is nothing to get too excited (and has not been buffed), but the DMG RES debuff has been increased from 50% to 60%, now making it the highest general DMG RES debuff on any unit (tied with Duke who needs the enemy to be in break). Moreover, the duration has been buffed from 15s to 20s, also making it easier to cycle Melty’s arts. On top of this, Melty’s TA also increases the party’s fire resistance by 20%, thereby giving her extra defensive utility against fire bosses.`,
                ability1: `The seal resistance is minor, but the teamwide 20% arts dmg buff is notable, especially in nuking situations.`,
                ability2: `Ideally you do not want to rely on units being near-death, but a faster skill means more arts to the party.`,
                trueweapon: {
                    skill: `Lower artgen than most support equips with a slight dmg res buff as an added bonus`,
                    ability1: `On Melty, it's able to artgen the most out of any other 5☆ Support in the game currently at 4.8 arts/s, and the dmg res buff is doubled to 20%. A great equip on herself in sustain fights.`,
                    ability2: `Nothing too flashy, a simple stat buff.`
                },
                overall: `Melty was already one of the most universally useful nuke supports, and she also has utility in sustain as well. Her buffs have basically improved her on both fronts, her debuff on TA coupled with her arts damage buff passive and slots enables her to be used in multiple nuke comps. On the other hand, her artsgen on NA which now stacks with Juno and Hart also enables her to be used for sustain. Melty’s versatility simply makes her a universally good support unit. Her True Weapon is a marginal improvement over the gacha version, mediocre on any other unit, but offering an additional 0.6 Arts/s and 10% DMG RES buff when used by Melty.`,
                lastupdated: `July 7, 2022`,
                author: `MOB of Metal`
            }
    },
    { // Fitoria - Complete, Review done
        id: 107606312,
        name: "Fitoria",
        luckrank: "B",
        rank: "S",
        attribute: "Earth",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107605312.png",
                detailawk: "/db/Units/Detail/unit_detail_107606312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107605312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107606312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7180,
                hpplus: 999,
                atk: 2710,
                atkplus: 416,
                def: 2840,
                defplus: 436
            },
        skillset: 
            {
                skill: "12s CT - For 7s, heal Allies' HP by 200/s and increase Allies' Arts by 3/s.",
                skillbreak: 0,
                arts: "For 15s, increase Allies' DMG RES by 30%, Allies' Water RES by 20%, and Allies gain a DMG barrier that negates 2000 DMG.",
                artsbreak: 0,
                truearts: "65000% Earth DMG (MAG). Increase this True Arts's Critical Rate by 100%. For 15s, increase Allies' DMG by 60% and DMG to Giant Bosses by 60%.",
                trueartsbreak: 2200
            },
        passive: 
            {
                ability1: "Increase Poison RES by 30%.",
                ability2: "Increase DEF by 20% when under a DMG barrier.",
                ability3: "Increase Demon Allies' Max HP by 20%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat3amt: 1,
                    hover3: "Oldeus"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Fitoria's Gown",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_151811.png",
                skill: "50s CT - 8300% Earth DMG (MAG). For 15s, reduce Enemies' MAG RES by 35%.",
                skillbreak: 800,
                passive: 
                    {
                        ability1: "When equipped by Fitoria, increase her DMG, accuracy and Healing Output by 20%, and increase her Arts by 2/s.",
                        ability2: "Increase DEF by 5%."
                    }
            },
        lore: 
            {   
                evo5: "The queen of all the world's Filolials. She rescued the Shield Hero's party after they freed Tyrant Dragon Rex. Though she possesses immense power, she believed only the Cardinal Heroes could fend off the Waves of Catastrophe, which is why she was so critical of Naofumi's refusal to cooperate with the others.",
                evoawk: "Adamant that Naofumi reconcile with the other Heroes, she took Melty hostage and demanded a duel with Filo. Naofumi still hesitated, but Filo gladly accepted to help her friend. As for why the Filolial Queen took such drastic measures, she had a different reason..."
            },
        review:
            {
                skill: `This is without a shadow of doubt the strongest skill in the game. Regardless of it not having a 100% uptime it offers strong artgen and decent healing to the whole team. Funny enough Fitoria might be the only unit in the game you want to run Skill CT crest on.`,
                arts: `A rather sizeable bit of DMG res and negligible barrier. Having 50% DMG RES against water enemies makes this a great option against any water boss who hits hard.`,
                truearts: `A rather strong multiplier and innate crit rate while providing up to 120% dmg up to all allies if the boss is a giant boss. Giant bosses are just any boss who's... well giant, not just those in the giant boss catagory.`,
                ability1: `Nothing too special about this, just nice to have.`,
                ability2: `A small selfish conditional buff that won't help too much, but it's something`,
                ability3: `A decent buff to demon allies HP.`,
                trueweapon: {
                    skill: `Similar to Juno's TW but with a better uptime, a solid magic res down option for any unit.`,
                    ability1: `This passive does a bit of everything for Fitoria, helping her out in pretty much every possible way.`,
                    ability2: `.....Yeah..`
                },
                overall: `Fitoria is truly a jack of all trades. Each part of her kit offers something different from the last making her a very versatile option. Her Artgen and healing being on her Skill means it's up at the start of the fight and can support the team from the beginning. Her Art provides solid dmg res, even more so against water bosses. While her True Art increases all allies DMG while also doing some nice DMG itself.`,
                lastupdated: `July 7, 2022`,
                author: `Zanster`
            }
    },
    { //Yusuke - Complete
        id: 107296212,
        name: "Yusuke",
        luckrank: "B",
        rank: "B-",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107295212.png",
                detailawk: "/db/Units/Detail/unit_detail_107296212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107295212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107296212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7400,
                hpplus: 999,
                atk: 2883,
                atkplus: 442,
                def: 2550,
                defplus: 392
            },
        skillset: 
            {
                skill: "12s CT - 2200% Water DMG (MAG). Permanently increase own ATK by 10% (up to 100%).",
                skillbreak: 600,
                arts: "15000% Water DMG (MAG). Permanently increase own DMG by 20% (up to 200%).",
                artsbreak: 1500,
                truearts: "55000% Water DMG (MAG). For 8s, increase Allies' Arts by 5/s.",
                trueartsbreak: 2800
            },
        passive: 
            {
                ability1: "Increase own Arts by 10 for every 10% of max HP lost.",
                ability2: "Less likely to be knocked back + When this unit takes lethal DMG, revive self with 100% HP. Can only be activated once per Quest.",
                ability3: "Increase PHY RES by 10%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat3amt: 1,
                    hover3: "Freydal",
                }
            },
        lore: 
            {   
                evo5: "A teenage delinquent who attends Sarayashiki Junior High School. No other student can come close to him in a fight, and his bad behavior has made him infamous--not just in the local neighborhood, but in the Spirit World, too. He has actually died once already, but he came back to life after overcoming a series of trials and is now solving cases as a spirit detective. During the Dark Tournament, he achieved incredible growth by becoming the successor to the Spirit Wave technique.",
                evoawk: "After rigorous training and life-threatening battles with many worthy opponents, Yusuke Urameshi showed significant growth as the official successor to the Spirit Wave technique. He never turns a blind eye to any wrongdoing, and when he feels anger, even towards himself, he exerts so much power that it makes his opponents quiver. However, he doesn't just fight for the fun of it. The way he puts his whole heart into fighting for the things he wants to protect draws people towards him, friends and enemies alike."
            }
    },
    { //Kazuma - Complete
        id: 107306412,
        name: "Kazuma",
        luckrank: "B",
        rank: "C",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107305412.png",
                detailawk: "/db/Units/Detail/unit_detail_107306412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107305412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107306412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7457,
                hpplus: 999,
                atk: 2799,
                atkplus: 429,
                def: 2555,
                defplus: 393
            },
        skillset: 
            {
                skill: "12s CT - 2000% Light DMG (MAG). Increase own Arts by 10.",
                skillbreak: 700,
                arts: "16000% Light DMG (MAG). For 10s, increase Human Allies' DMG by 50%.",
                artsbreak: 1600,
                truearts: "36000% Light DMG (MAG). For 30s, increase own Accuracy and Skill CT REC by 100%.",
                trueartsbreak: 2600
            },
        passive: 
            {
                ability1: "When near-death, increase Arts by 1/s.",
                ability2: "When near-death, increase own Evasion by 5%.",
                ability3: "At the start of a Battle, increase own Arts by 80. At the start of a Quest, increase own Arts by own Luck."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 1,
                    hover3: "Fosradius",
                }
            },
        lore: 
            {   
                evo5: "Kuwabara is a teenage delinquent who attends Sarayashiki Junior High School and calls himself Yusuke Urameshi's rival. He is an incredibly average human except for his natural sixth sense, but he becomes a good partner for Yusuke when he realizes his own potential. While he is simple and a bit of a clown, the stoic way he never wavers in his ideals draws quite a number of followers to him. Kuwabara always bounces back no matter how hard he's hit, and he isn't afraid of honest hard work either, which wil continue to help him to grow.",
                evoawk: "Kuwabara has an almost miraculous ability to rebound and sixth sense for a human being--it's almost as if he's immortal. At the Dark Tournament, he took the initiative and inspired his allies, achieving much more that just winning his own battles. His indomitable fighting spirit inspires confidence in many and will probably continue to do so in the future."
            }
    },
    { //Kurama - Complete
        id: 107316312,
        name: "Kurama",
        luckrank: "B",
        rank: "B",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107315312.png",
                detailawk: "/db/Units/Detail/unit_detail_107316312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107315312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107316312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6569,
                hpplus: 999,
                atk: 2652,
                atkplus: 407,
                def: 2399,
                defplus: 369
            },
        skillset: 
            {
                skill: "11s CT - 1700% Earth DMG (MAG). If this Skill crits, increase own Arts by 30.",
                skillbreak: 400,
                arts: "12000% Earth DMG (MAG). For 16s, increase Allies' Arts by 2/s and Critical DMG by 30%.",
                artsbreak: 1000,
                truearts: "40000% Earth DMG (MAG). 80% chance to inflict Poison for 25s and 80% chance to inflict Disease for 25s. For 25s, reduce Enemies' Earth RES by 35%.",
                trueartsbreak: 1300
            },
        passive: 
            {
                ability1: "Increase Earth RES by 15%.",
                ability2: "Increase Status RES by 30%.",
                ability3: "Increase DMG to Poisoned enemies by 100%. Increase Critical Rate against Diseased enemies by 100%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat3amt: 1,
                    hover3: "Elpneumas",
                }
            },
        lore: 
            {   
                evo5: "A Yoko who has made a name for himself as a thief in the Demon World. In the past, he was fatally injured by one of his pursuers, but he managed to come back to life by implanting his soul into an unborn human child. Kurama has a sharp mind and always seems cool and collected, but he also loves to fight, and when he is angry, hsi brutal Yoko side comes out. It is said that in one battle, Kurama's rage brought him to kill his opponent in such a terrible way that it frightened even his allies.",
                evoawk: "A Yoko who was so feared as a thief in the Demon World that he was called the Legendary Bandit. After being born again in the Human World, he ends up helping Yusuke and his friends from time to time. his Yoko power is slowly returning to him, and he is a tough opponent even in hand-to-hand combat. When he returns to his brutal Yoko form, his Demonic energy is so powerful that even other Yokai can't lay a finger on him--it can be said that angering Kurama is the same as taking a shortcut to a painful death."
            }
    },
    { //Hiei - Complete
        id: 107326512,
        name: "Hiei",
        luckrank: "C",
        rank: "B-",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107325512.png",
                detailawk: "/db/Units/Detail/unit_detail_107326512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107325512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107326512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6403,
                hpplus: 999,
                atk: 2874,
                atkplus: 441,
                def: 2196,
                defplus: 339
            },
        skillset: 
            {
                skill: "12s CT - 3500% Dark DMG (PHY). For 5s, increase own Evasion by 10%.",
                skillbreak: 500,
                arts: "18000% Dark DMG (PHY). For 15s, reduce Enemies' Dark RES by 30%.",
                artsbreak: 1100,
                truearts: "50000% Dark DMG (MAG). Reduce Enemies' Dark RES by 10% for 45 seconds (stackable) + For 10s, increase Allies' Critical Rate by 100%.",
                trueartsbreak: 1600
            },
        passive: 
            {
                ability1: "Increase Critical DMG by 30%.",
                ability2: "Increase DMG to Giant Bosses by 30%.",
                ability3: "When own HP is above 90%, increase own Arts by 1/s and Critical DMG by 30%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat3amt: 1,
                    hover3: "Oldeus",
                }
            },
        lore: 
            {   
                evo5: "A cold-blooded Yokai who makes his living as a bandit in the Demon World. He is a master of the Darkness Flame Fist, and his ominous Jagan Eye--the third eye on his forehead--causes many to fear and shun him. He has the tragic fate of being born as a Glacier Child, but his past does not cast even a single shadow over his incredible power. There are very few people who can fight on the same level as him when he unleashes his vicious Demonic Energy and terrifying flame attacks.",
                evoawk: "Hiei mastered the Dragon of the Darkness Flame, the greatest technique of the Darkness Flame Fist, and unleashed the true power of the Jagan Eye. He overcame his tragic past and fought many worthy opponents to bring this power to its limits--the overwhelming Demonic Energy alone is enough to make many of his enemies cower. When Hiei invokes the dragon hellfire from the the Demon World and lets its explosive power loose, everything before him is razed to ashes by the overpowering black flames."
            }
    },
    { //Toguro - Complete
        id: 107336512,
        name: "Toguro",
        rank: "C",
        luckrank: "B",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107335512.png",
                detailawk: "/db/Units/Detail/unit_detail_107336512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107335512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107336512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7300,
                hpplus: 999,
                atk: 2700,
                atkplus: 415,
                def: 2740,
                defplus: 421
            },
        skillset: 
            {
                skill: "10s CT - 4500% Dark DMG (PHY). For 5s, reduce Enemies' DEF by 10%.",
                skillbreak: 2000,
                arts: "23000% Dark DMG (PHY). For 20s, increase own stats and Break PWR by 35%.",
                artsbreak: 3000,
                truearts: "[60000% + 1% per HP, ATK & DEF of this unit] Dark DMG (PHY).",
                trueartsbreak: 7000
            },
        passive: 
            {
                ability1: "Ignore 10% of Enemies' DEF.",
                ability2: "Increase DMG to Enemies in BREAK by 20%.",
                ability3: "Increase own ATK and DEF by 10% for each enemy killed (up to 150%)."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat3amt: 1,
                    hover3: "Mavroskia",
                }
            },
        lore: 
            {   
                evo5: "A Yokai who used to be human. He can manipulate his muscles at will and overwhelms his enemies using only his well-built body. When he uses 80% of his muscle power, run-of-the-mill Yokai would die from from just a brush with his aggressive Demonic Energy. Nobody has ever survived a fight with him at his full power, but he continues to hone his body for the day when somebody might finally be able to give him a challenge.",
                evoawk: "Younger Toguro rejoices after finally meeting an opponent who can give him a challenge and lets loose his full potential. Most people would die instantly from being hit by Toguro's monstrous levels of Demonic Energy, and any who do manage to escape would then be crushed by his overwhelmingly powerful fists. For someone like Toguro, whose strength goes beyond human understanding, it was very rare to meet an opponent who could actually be a threat to him, so when he finally did, he was filled with joy at the opportunity to give it his all."
            }
    },
    { //Emilia - Complete (+ JP version), Review done
        id: 107346212,
        name: "Emilia",
        luckrank: "A",
        rank: "A",
        attribute: "Water",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107345212.png",
                detailawk: "/db/Units/Detail/unit_detail_107346212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107345212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107346212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        slotsJP:
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 6,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6828,
                hpplus: 999,
                atk: 2824,
                atkplus: 433,
                def: 2845,
                defplus: 436
            },
        skillset: 
            {
                skill: "10s CT - 1600% Water DMG (MAG). 20% chance to inflict Freeze for 2s. If this Skill inflicts Freeze, increase own Arts by 30.",
                skillbreak: 500,                
                arts: "14000% Water DMG (MAG). Increase Allies' Arts by 20. For 15s, increase Allies' DMG RES by 25%.",
                artsbreak: 1000,
                truearts: "50000% Water DMG (MAG). 80% chance to inflict Ultimate Freeze for 10s. If this True Arts inflicts Ultimate Freeze on an Enemy, for 10s, reduce that Enemy's Water RES by 80%.",
                trueartsbreak: 2100
            },
        passive: 
            {
                ability1: "Heal own HP by 100/s.",
                ability2: "Increase Allies' Critical DMG by 50%.",
                ability3: "Increase Freeze RES by 50%.",
                ability4: "Increase Seal RES by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat3amt: 1,
                    hover3: "Freydal",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Emilia's Cape",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_150871.png",
                skill: "55s CT - 6000% Water DMG (MAG). For 12s, increase Water Allies' DMG by 10%, increase own DMG by 30%.",
                skillbreak: 800,
                passive: 
                    {
                        ability1: "When equipped by Emilia, increase own Arts DMG by 15%. Whenever Emilia uses Arts (not True Arts), for 15s, reduce Enemies' Freeze RES by 30%. Does not stack with Abilities of the same name.",
                        ability2: "Increase MAG RES by 5%.",
                        ability3: "Equip is given a random ability on craft."
                    }
            },
        lore: 
            {   
                evo5: "A half-elf girl with silver hair and pupils glimmering of purple and bluish-purple. She travels alongside her Spirit Puck who she treats as family. In the Imperial Capital she met Subaru Natsuki, with whom a fate of both laughter and pain was born.",
                evoawk: "She is a skilled Spiritual Arts User and has a contract with Puck. She has a tendency to put others before herself. She is also kind and good-natured, taking care of others without reserve, while not making them feel indebted to her."
            },
        review:
            {
                skill: `Self sufficient artsgen which is appreciated. However, it’s reliant on freezing which makes it less reliable.`,
                arts: `Flat artsgen on NA, while also being able to provide some sort of survivability with 25% DMG Resistance. However, it does not stack with Sanstone, and such.`,
                truearts: `With a decent multiplier, and a very high chance to inflict Ultimate Freeze, and a heavy Water resistance down when inflicting ultimate freeze. Emilia is a very potent damage supporting unit unless the boss has high freeze resistance as Ultimate Freeze provides 100% Crit RES down to the enemy as well as 80% water resistance down debuff, if inflicted by ultimate freeze from her True Arts. This pairs well especially for mono water (nuke) teams.`,
                ability1: `Nothing too bad, but nothing too great. A nice passive that’ll help Emilia to be kept safe with the HP. Although, it’s not really noticeable when running a taunt, or another source of healing in the team.`,
                ability2: `A huge damage buff, which provides Emilia and her team with more dps which pairs well with her True Art, if inflicting Ultimate Freeze.`,
                ability3: `A nice Resistance buff, if the boss can inflict Freeze. Although 100% would be appreciated, it is better than nothing.`,
                ability4: `A nice Resistance buff, if the boss can inflict Seal. Although 100% would be appreciated, it is better than nothing.`,
                trueweapon: {
                    skill: `A nice damage buffing equipment with a rather small side buff for water allies.`,
                    ability1: `A nice little damage buff to Emilia’s Damage output. With the side effect of using her NA to reduce the Enemy’s Freeze Resistance by 30% which pairs well with her True Arts to inflict Ultimate Freeze, if you are able to use her True Art in the duration of 15s after Debuff is applied.`,
                    ability2: `It's a nice bonus, but nothing too noticeable.`,
                    ability3: `The rest are all equally useless but definitely avoid the Increase Critical Hit Chance by 15% + Decrease Equip CT REC by 10% as it has a negative effect.`
                },
                overall: `Emilia was, before her Buffs, a good support unit capable of providing some instant arts and damage resistance on her NA and a huge debuff on TA, which pairs especially well with other strong Water units dps such as Joker Selia and Summer Juno. With the buffs Emilia received, her role as support unit was improved and makes Water Nuking more consistent on bosses that are freezable.`,
                lastupdated: `March 3, 2023`,
                author: `Alice`
            }
    },
    { //Rem (RE:ZERO) - Complete (+ JP version), Review done
        id: 107356212,
        name: "Rem (RE:ZERO)",
        luckrank: "C",
        rank: "B",
        attribute: "Water",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107355212.png",
                detailawk: "/db/Units/Detail/unit_detail_107356212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107355212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107356212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        slotsJP:
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 6,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6849,
                hpplus: 999,
                atk: 3041,
                atkplus: 466,
                def: 2696,
                defplus: 414
            },
        skillset: 
            {
                skill: "12s CT - 1300% Water DMG (MAG). For 5s, reduce Enemies' DMG RES by 10%.",
                skillbreak: 600,
                arts: "20000% Water DMG (MAG). For 10s, increase own ATK by 50%.",
                artsbreak: 1100,
                truearts: "70000% Water DMG (PHY). For 50s, consume 2%/s of own max HP (stackable), increase own Critical DMG by 130% (stackable), and increase own Arts by 3/s (stackable).",
                trueartsbreak: 1800
            },
        passive: 
            {
                ability1: "Increase Critical Rate by 100% when own HP is above 50%.",
                ability2: "Increase DMG to Beasts by 50%.",
                ability3: "When near-death, increase Arts by 1/s."
            },
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat3amt: 1,
                    hover3: "Freydal",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Rem's Morningstar",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_150911.png",
                skill: "40s CT - 7000% Water DMG (PHY). For 20s, increase own ATK by 50%. The first time this is used in a Quest, increase own Arts by 50.",
                skillbreak: 1000,
                passive: 
                    {
                        ability1: "When equipped by Rem (RE:ZERO), increase Critical DMG by 30%. Whenever Rem uses Arts (not True Arts), heal Rem's HP by 30%. Does not stack with Abilities of the same name.",
                        ability2: "Increase PHY DMG by 10%.",
                        ability3: "Equip is given a random ability on craft."
                    }
            },
        lore: 
            {   
                evo5: "A girl who hides her right eye behind her short blue hair. She and her older sister Ram serve at the Roswaal Mansion. She can have quite the sharp tongue while being superficially polite. Apparently, she is the one who keeps the Roswaal Mansion running.",
                evoawk: "A quiet and skillful maid who works at the Roswaal Mansion. Recently, she has been worrying about how her chest has become bigger than her sister's, so she has taken measures to make sure people don't find out."
            },
            review:
            {
                skill: `An alright skill, the DMG res down is nice but the buff doesn’t have enough uptime to be very useful`,
                arts: `A fairly lackluster art. Middle-of-the-road uptime along with a middle-of-the-road buff.`,
                truearts: `This is the keystone of Rem’s kit, and what simultaneously makes her the best and least practical DPS in the whole game. She can accumulate a lot of crit damage and artsgen quickly, but due to the hp consumption she becomes increasingly squishy until she becomes one tap.`,
                ability1: `Great  for self sufficiency and getting rid of the need for a crit buffer to take advantage of her damage increase. But being HP locked means she will almost require a taunt to keep it up in longer missions.`,
                ability2: `Nice if you’re fighting beasts, but for the most part will not help much.`,
                ability3: `Almost negligible compared to the arts from the rest of your team as well as her TA, and after a few TAs, she won’t be able to get near death without dying.`,
                trueweapon: {
                    skill: `The arts is nice, allowing for a faster startup, but the buff is merely alright.`,
                    ability1: `Very good for Rem, it will help keep her crit rate buff active and provides a nice little buff to said crit.`,
                    ability2: `small buff, but appreciated as a passive nonetheless`
                },
                overall: `Despite being the best damage dealer in the game, Rem doesn’t see much use because of her max hp consumption that comes with her damage buffs. She’s a very finicky unit to work with, and wants units like Hart and X taunt tank to keep her alive long enough to do her job. Despite this, with the buffs, she can do a lot of damage with her numerous stacking CRI DMG and Arts gauge buffs as long as adequate support (Heals, shields, etc.) is provided.`,
                lastupdated: `March 3, 2023`,
                author: `Pula`
            }
    },
    { //Ram - Complete (+ JP version), review done
        id: 107366312,
        name: "Ram",
        rank: "A-",
        luckrank: "F",
        twrequire: "favorable",
        attribute: "Earth",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107365312.png",
                detailawk: "/db/Units/Detail/unit_detail_107366312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107365312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107366312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        slotsJP: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 6,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6893,
                hpplus: 999,
                atk: 3061,
                atkplus: 469,
                def: 2639,
                defplus: 405
            },
        skillset: 
            {
                skill: "11s CT - 1400% Earth DMG (MAG). For 6s, reduce Enemies' ATK by 10%.",
                skillbreak: 500,
                arts: "13000% Earth DMG (MAG). Curse Freeze + For 15s, increase Allies' RES to the Element they have an Elemental Advantage against by 50%, and increase Allies' Freeze RES by 100%.",
                artsbreak: 1000,
                truearts: "42000% Earth DMG (MAG). For 35s, Allies gain a DMG barrier that negates [6000 + Ram's DEF value] DMG.",
                trueartsbreak: 2300
            },
        passive: 
            {
                ability1: "At the start of a Quest, increase own Arts Gauge by own Luck. Does not stack with Abilities of the same name.",
                ability2: "Increase accuracy by 10%.",
                ability3: "Increase evasion by 5%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat3amt: 1,
                    hover3: "Tonetaurus",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Ram's Mop",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_150931.png",
                skill: "40s CT - 4000% Earth DMG (MAG). For 15s, increase own DEF and accuracy by 25%.",
                skillbreak: 700,
                passive: 
                    {
                        ability1: "When equipped by Ram, increase own DEF by 20%. Whenever Ram uses True Arts, increase Ram's Arts by 30. Does not stack with Abilities of the same name.",
                        ability2: "Increase Water RES by 5%.",
                        ability3: "Equip is given a random ability on craft."
                    }
            },
        lore: 
            {   
                evo5: "A girl who hides her right eye behind her short pink hair. She and her younger sister Rem serve at the Roswaal Mansion. She has a sharp tongue and is very rude. She lets her sister do all the work.",
                evoawk: "A carefree maid working at the Roswaal Mansion. Her specialty is steamed yams. Her little sister Rem helps her in almost every aspect of her private life, including waking her up and taking care of her hair and nails."
            },
        review:
            {
                skill: `It isn't noticeable in reducing the enemies' damage.`,
                arts: `This is most effective on earth teams when going up against a water + freezing boss, or when the taunt tank has an elemental advantage against the boss to give them further damage resistance.`,
                truearts: `When her normal art isn't of much use to the stage, you will mainly be using her true art for a huge barrier, which is good for mitigating damage if the stage limits other options (i.e taunt tanks, Sanstone). Adding in her arts gauge passive based on luck, as well as her defense slot, she can quickly and easily protect your team when farming fairly hard-hitting stages. A DEF Up crest, units such as Asirpa and Hart who can increase her DEF stat, and other defense boosts will greatly increase the amount of damage the barrier can soak up.`,
                ability1: `When maxed, she will start off with 120 arts gauge just from her passive. This is valuable for mitigating damage as soon as possible.`,
                ability2: `A mere 10% isn't really going to help her against evasive bosses.`,
                ability3: `Even more so than the accuracy passive, this really won’t have a noticeable effect.`,
                trueweapon: {
                    skill: `The accuracy boost is whatever, but the increase in DEF will be nice for her barrier.`,
                    ability1: `Good boost to her barrier size as a result of the DEF increase, and the 30 arts gauge refund allows for easier renewals for her barrier (more so than when using normal arts gauge items).`,
                    ability2: `It's a negligible amount, and Ram's whole kit revolves around the team, so not much use anyway if it only affects her.`,
                    ability3: `Get the passive that further increases her DEF stat.`
                },
                overall: `To make the most use out of her kit, Ram requires coordination of units that can synergize with her abilities, whether that's mono-earth against water, an appropriate taunt tank with elemental advantage against the boss, or units like Asirpa and Hart to increase her DEF stat. But in general, she does a quick, easy, and satisfactory job in mitigating damage by herself for most quests. For significantly strong enemies, you'll probably need more than just her to keep your team alive.`,
                lastupdated: `March 3, 2023`,
                author: `PrayingPatrick`
            }
    },
    { //Beatrice - Complete (+ JP version), Review done
        id: 107376512,
        name: "Beatrice",
        luckrank: "A",
        rank: "B",
        attribute: "Dark",
        type: "Spirit",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107375512.png",
                detailawk: "/db/Units/Detail/unit_detail_107376512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107375512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107376512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        slotsJP:
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 6,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6287,
                hpplus: 999,
                atk: 2597,
                atkplus: 399,
                def: 2888,
                defplus: 443
            },
        skillset: 
            {
                skill: "12s CT - Increase own Arts by 10 and heal own HP by 10% of own max HP.",
                skillbreak: 0,                
                arts: "15000% Dark DMG (MAG). For 20s, reduce Enemies' Status RES by 45%.",
                artsbreak: 900,                
                truearts: "55000% Dark DMG (MAG). 80% chance to inflict Faint for 7s. For 15s, reduce Enemies' Dark RES and MAG RES by 45%.",
                trueartsbreak: 1600
            },
        passive: 
            {
                ability1: "Increase own DMG against an Enemy by [33% × # of Statuses on that Enemy].",
                ability2: "Increase Equipment CT REC by 20% if unafflicted by any Status Ailment.",
                ability3: "When near-death, increase Skill CT REC by 15%.",
                ability4: "Increase Curse RES by 30%."
            },
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragValzandeth.png",
                    mat3amt: 1,
                    hover3: "Valzandeth",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Beatrice's Ribbon",
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_150891.png",
                skill: "45s CT - For 20s, increase target's Arts by 5/s. If target is Dark, increase target's Arts DMG by 20% for 20s.",
                skillbreak: 0,
                passive:
                    {
                        ability1: "When equipped by Beatrice, increase ATK by 20%. Whenever Beatrice uses True Arts, for 10s, increase Allies' Dark DMG by 20%. Does not stack with Abilities of the same name.",
                        ability2: "Increase Light RES by 5%.",
                        ability3: "Equip is given a random ability on craft."
                    }
            },
        lore: 
            {   
                evo5: "The caretaker of the Roswaal Mansion's Forbidden Library. Her milky white hair is tied up in twin drills. She seems to always be in a bad mood. She looks like a fairy when she walks, her fancy dress trailing behind her.",
                evoawk: "She calls herself Betty and is the librarian at the Forbidden Library. She lives on her own in a room as the Roswaal Mansion that was hidden with Door Crossing. She is arrogant and does not get along well with others. While she often finds herself at odds with Subaru Natsuki, who happened to break her Door Crossing, they actually get along fairly well."
            },
            review:
            {
                skill: `A nice Skill with a fixed % Heal and Arts up improving Beatrice’s survivability and slightly boost her self sufficiency, additionally this skill comes with a few Iframes in case you need to survive an ending nuke.`,
                arts: `Despite the decent uptime and sizeable debuff, status res down is a weak effect outside of specific content which requires status effects to be damaged.`,
                truearts: `With a decent multiplier and the ability to inflict strong debuffs her TA is the best part of her kit. However, there are very few units who can even make full use of these debuffs, with only Juno, Nui, and Dark Hart being decent options to pair up with her.`,
                ability1: `While this sounds good on paper, it's unrealistic that you'll have more than 2 status effects on the enemy at a time. With most teams only having 1 due to not running anyone else who inflicts status effects.`,
                ability2: `Due to how little content inflicts status effects constantly, this passive will almost always be active. In the rare case that the boss does inflict status effect Beatrice has a 4 heal slot for force keratos to deal with it.`,
                ability3: `While this should help Beatrice due to her healing and Iframes on skill, the buff is far too weak to make any real difference.`,
                ability4: `Appreciated when needed.`,
                trueweapon: {
                    skill: `While it does get slightly outclassed by other farmable equips, this is by no means a horrible equip for new players.`,
                    ability1: `I mean...not that strong of an effect but it's far from worthless. It helps slightly increase Beatrice's overall dmg.`,
                    ability2: `A small additional boost to survival against Light Enemies.`,
                    ability3: `While all the abilities are lackluster, strive for these passives:

                    [1] - Increase MAG DMG by 10% and Decrease DMG RES by 10%.
                    [2] - Increase BREAK PWR by 5% + Increase Dark DMG by 5%.
                    [3] - Increase ATK by 5%. 
                    (Attempt in that order)`
                },
                overall: `Beatrice is the perfect example of a good support unit who lacks the units to support. Since she released two years ago the only Dark Magic units who've released are Nui and Dark Hart, of which the latter doesn't work well with her since Dark Hart would override half off Beatrice's buffs with his own. While she might be able to find her place in the meta at some point in the future, for now she'll only be seen as a luck unit for new players and in a rare dark magic nuke team.`,
                lastupdated: `March 3, 2023`,
                author: `Zanster`
            }
    },
    { //Santa Rimuru - Complete, Review done
        id: 107396212,
        name: "Santa Rimuru",
        rank: "A+",
        luckrank: "A",
        attribute: "Water",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107395212.png",
                detailawk: "/db/Units/Detail/unit_detail_107396212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107395212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107396212.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6998,
                hpplus: 999,
                atk: 2835,
                atkplus: 435,
                def: 2877,
                defplus: 441
            },
        skillset: 
            {
                skill: "12s CT - On first use, increase Allies' Arts by 3/s for 7s. On second use, increase Allies' ATK by 30% for 7s. On third use, heal Allies' HP by 3%/s for 7s. Cycle repeats afterwards.",
                skillbreak: 0,
                arts: "22000% Water DMG (MAG). Increase Allies' Arts by 25 + For 15s, increase Allies' DMG RES against Giant Bosses by 30%.",
                artsbreak: 1200,
                truearts: "45000% Water DMG (MAG). For 20s, increase Allies' DMG by 80% and MAG DMG by 80%.",
                trueartsbreak: 2300
            },
        passive: 
            {
                ability1: "Increase Equipment CT REC by 10%.",
                ability2: "Increase DMG to Giant Bosses by 50%.",
                ability3: "Increase Water and Demon Allies' DMG by 30%. Does not stack with Abilities of the same name."
            }, 
        dream: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragFreydal.png`,
                        mat3amt: 2,
                        hover3: `Freydal`
                    },
                    passive: {
                        ability1: `Increase all Allies' Skill CT by 20%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase DMG to non-Boss Enemies under 30% HP by 50%.`
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragRadachne.png`,
                        mat3amt: 2,
                        hover3: `Radachne`
                    },
                    passive: {
                        ability1: `When an Ally dies (except self), revive that Ally with 50% HP. Ability can only be activated once per Quest.`,
                        ability2: ``
                    }
                }
            },
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat3amt: 1,
                    hover3: "Freydal",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Rimuru's Santa Suit",
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_152821.png",
                skill: "30s CT - Increase Allies' Arts by 15. For 15s, increase Allies' DMG by 20%.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Santa Rimuru, the Arts generation and DMG buff of this Equip's Skill is doubled. Does not stack with Abilities of the same name.",
                        ability2: "Increase Max HP by 5%."
                    }
            },
        lore: 
            {   
                evo5: "A slime reincarnated into another world. Having once visited Raktehelm with allies once before, Rimuru was able to use special skills to return. Seeing the people of Reigrad preparing to celebrate Christmas, Rimuru decides to help out.",
                evoawk: "A slime reincarnated into another world. While Rayas and the others were getting ready for Christmas, a serious crisis caused them to lose most of the food they had prepared, Rimuru, with friends, will use their abilities and wisdom to make the Christmas celebrations everyone is looking forward to."
            },
        review:
            {
                skill: `A marginally useful skill which utility is hampered by its rotating nature. The CT has been shortened by 3s, enabling somewhat faster rotation.`,
                arts: `Great utility, teamwide artgen which now has been buffed to 25 arts, along with a 30% dmg res buff against giant bosses. Because it is conditional to the enemy being a giant boss (which includes over 70% of bosses, including all raids and mines bosses), it stacks with other dmg res buffs.`,
                truearts: `Sizable teamwide dmg buffs (especially for magic units). The multiplier itself, while buffed, is nothing to get too excited by 2023 standards, but the buffs themselves help increase Santa Rimuru’s damage output.`,
                ability1: `Somewhat useful ability, especially with a 5 star support slot.`,
                ability2: `Another ability that increases Santa Rimuru’s damage output against the vast majority of bosses in the game.`,
                ability3: `More passive dmg buffing abilities, this time increasing dmg for water and demon units.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Santa Rimuru was already one of the best magic nuke supports, as well as a solid sustain unit against giant bosses. His buffs strengthen slightly his own damage output with higher multipliers, but the most relevant buff overall is his NA artgen that increases to 25 teamwide arts, which will improve his role in sustain.`,
                lastupdated: `January 24, 2023`,
                author: `Zanster`
            }
    },
    { //Santa Milim - Complete, review done
        id: 107406112,
        name: "Santa Milim",
        rank: "A-",
        luckrank: "F",
        attribute: "Fire",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107405112.png",
                detailawk: "/db/Units/Detail/unit_detail_107406112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107405112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107406112.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 6494,
                hpplus: 999,
                atk: 2820,
                atkplus: 433,
                def: 2921,
                defplus: 448
            },
        skillset: 
            {
                skill: "12s CT - Increase own Arts by 30 + For 10s, increase own stats by 10%.",
                skillbreak: 0,
                arts: "28000% Fire DMG (PHY). For 15s, reduce Enemies' Fire RES by 40%.",
                artsbreak:  1600,
                truearts: "60000% Fire DMG (PHY). For 15s, increase Allies' Critical Rate by 100% + Increase Allies' Arts by 4/s.",
                trueartsbreak: 3100
            },
        passive: 
            {
                ability1: "Increase Critical DMG by 40%.",
                ability2: "Increase DMG to Enemies under 30% HP by 20%.",
                ability3: "When HP is above 50%, increases Arts' and True Arts' buff durations by 50%."
            },
        dream: {
            fire: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                    mat2amt: 50,
                    hover2: `Flametail Soulgem`,
                    mat3: `/db/Icons/dungeon_drops/fragFlogassa.png`,
                    mat3amt: 2,
                    hover3: `Flogassa`
                },
                passive: {
                    ability1: `Increase all Fire Allies' DMG by 30%. Does not stack with abilities of the same name.`,
                    ability2: ``
                }
            },
            light: {
                evolution: {
                    mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                    mat1amt: 10,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                    mat2amt: 50,
                    hover2: `Eternaglint Soulgem`,
                    mat3: `/db/Icons/dungeon_drops/fragRadachne.png`,
                    mat3amt: 2,
                    hover3: `Radachne`
                },
                passive: {
                    ability1: `When own HP is 80% or higher, increase own ATK by 50%.`,
                    ability2: `When own HP is 80% or higher, increase own Arts by 2/s.`
                }
            }
        },
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                    mat3amt: 1,
                    hover3: "Ifrit",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Milim's Santa Coat",
                slot: "/db/Mines/thumbnail/healLB.png",
                detail: "/db/Equipment/Detail/item_detail_152831.png",
                skill: "40s CT - Heal the lowest HP Ally's HP by 15%. For 6s, heal all Allies' HP by 4%/s.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Santa Milim, double the Healing Amount of this equipment's Skill. Does not stack with Abilities of the same name.",
                        ability2: "Increase Max HP by 5%."
                    }
            },
        lore: 
            {   
                evo5: "One of the oldest Demon Lords. Having once visited Raktehelm with Rimuru before, she demanded to go back and eat Reigrad's cuisine again. Rimuru decided it was better to satisfy her cravings that have her pitch one of her dangerous tantrums.",
                evoawk: "One of the oldest Demon Lords. When trouble arose as Reigrad was preparing for Christmas, she was enraged at the thought of losing her chance to taste their seasonal dishes. It was a struggle for Rimuru and the others to keep her from destroying the entire city."
            },
        review:
            {
                skill: `Self sufficient artgen is always appreciated. While the 10% status for 10 is a nice bonus.`,
                arts: `A nice fire resistance down with a decent multiplier and uptime, which might find uses for fire nuking, or to burst a boss with a fire dps.`,
                truearts: `With a decent multiplier, and a teamwide crit rate up of 100% Santa Milim is able to do some decent damage. The 30 total art is small, but artgen is appreciated as always.`,
                ability1: `A huge damage buff, if the boss doesn’t negate Crit, which increases Santa Milim damage output.`,
                ability2: `A little bonus to increase the damage output of Santa Milim furthermore.`,
                ability3: `A good buff to Santa Milim’s Fire resistance debuff duration on NA, which is increased by 7,5s and her True Art duration to 15s which make her total arts being 45 tied to keeping her above 50% HP which shouldn’t be too hard.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Santa Milim is overall a decent unit, which can provide some nuke support with her 30% Fire Resistance down, or the increase of 100% Crit Rate up on TA. However, for nuking her slots hold hers back. As for general use, with the buffs Santa Milim becomes a more reliable unit to rely on to increase your team's artgen especially for newer players. For veterans, where the box is more developed they won't find much use out of her, as they have better options to use over.`,
                lastupdated: `January 24, 2023`,
                author: `Zanster`
            }
    },
    { //Santa Shion - Complete, review done
        id: 107416512,
        name: "Santa Shion",
        rank: "A",
        luckrank: "A",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107415512.png",
                detailawk: "/db/Units/Detail/unit_detail_107416512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107415512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107416512.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7100,
                hpplus: 999,
                atk: 3100,
                atkplus: 475,
                def: 2361,
                defplus: 364
            },
        skillset: 
            {
                skill: "14s CT - 6000% Dark DMG (PHY). Increase BE generation of this Skill by 100%.",
                skillbreak: 400,
                arts: "23000% Dark DMG (PHY). For 20s, increase own Skill CT REC by 100%.",
                artsbreak: 1600,
                truearts: "100000% Dark DMG (PHY). For 10s, increase own Accuracy by 120%.",
                trueartsbreak: 3500
            },
        passive: 
            {
                ability1: "Increase Freeze RES by 100%.",
                ability2: "While near-death, increase DMG RES by 10%.",
                ability3: "On every use of Arts or True Arts, permanently increase own ATK and DMG by 40% (up to 200%)."
            },
        dream: {
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 50,
                        hover2: `Smogdragon Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragGoldola.png`,
                        mat3amt: 2,
                        hover3: `Goldola`
                    },
                    passive: {
                        ability1: `Increase own Arts by 100 at the start of quest.`,
                        ability2: `Increase own Arts by 3/s.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragOldeus.png`,
                        mat3amt: 2,
                        hover3: `Oldeus`
                    },
                    passive: {
                        ability1: `Increase DMG to Light Enemies by 100%.`,
                        ability2: ``
                    }
                }
            },
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragValzandeth.png",
                    mat3amt: 1,
                    hover3: "Valzandeth",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Shion's Santa Dress",
                slot: "/db/Mines/thumbnail/defLB.png",
                detail: "/db/Equipment/Detail/item_detail_152841.png",
                skill: "25s CT - For 12s, increase Allies' DMG RES by 15%. Increase <50% HP Allies' DMG RES by 25% instead.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Santa Shion, doubles the DMG RES buff of this Equip's Skill. Does not stack with Abilities of the same name.",
                        ability2: "Increase Max HP by 5%."
                    }
            },
        lore: 
            {   
                evo5: "A Kijin that serves Rimuru as his secretary and bodyguard. Though she did not join him on his last visit to Reigrad, she decided to accompany him on his return trip. She took a keen interest in the Christmas preparations and joined Benimaru in preventing as much loss to their food supply as possible.",
                evoawk: "A Kijin that serves Rimuru as his secretary and bodyguard. For the sake of Reigrad who lost a great deal of their food supply to beasts, she fought alongside her allies. Just like Benimaru, she is a powerful presence in battle."
            },
        review:
        {
            skill: `Not much to say about this, it has a good multiplier and the increase to BE generation on skill is nice to have.`,
            arts: `Nothing too special about this, a sizable increase to skill CT recovery is a nice addition. You would only use this over Shion's true art to get her ramped quicker.`,
            truearts: `This is where Shion really shines. With a large DMG multiplier allowing her to deal impressive damage. As well as boasting a substantial, albeit, niche accuracy increase to herself.
            `,
            ability1: `Pretty straight forward, isn't anything special just a nice addition.`,
            ability2: `An infamous near death passive, this will rarely activate and even if it does it won't change much.`,
            ability3: `This is where Shion's kit gets good. Being able to ramp up and get a large increase to her ATK and DMG up to 200% each.`,
            trueweapon: {
                skill: ``,
                ability1: ``,
                ability2: ``
            },
            overall: `Shion is a selfish dps who needs a lot of support in order to function since she lacking any form of self sufficency. Although her multipliers make her look like the top tier DPS option, which she can be, the lack of innate crit or defense ignore hurts almost as much as her nonexistant self sufficency. In terms of damage, at max stacks Santa Shion is only beaten by Erza which is by no means something to be ashamed about.`,
            lastupdated: `January 24, 2023`,
            author: `Zanster`
        }
    },
    { //New Years Rimuru - Complete
        id: 107426512,
        name: "New Years Rimuru",
        rank: "A-",
        luckrank: "A",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107425512.png",
                detailawk: "/db/Units/Detail/unit_detail_107426512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107425512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107426512.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6930,
                hpplus: 999,
                atk: 3010,
                atkplus: 461,
                def: 2650,
                defplus: 407
            },
        skillset: 
            {
                skill: "10s CT - 1700% Dark DMG (PHY). If this skill crits, increase own Arts by 60.",
                skillbreak: 500,
                arts: "22000% Dark DMG (PHY). For 10s, increase Allies' stats by 35%.",
                artsbreak: 1600,
                truearts: "60000% Dark DMG (PHY). For 60s, increase Allies' PHY DMG by 60% (stackable).",
                trueartsbreak: 2800
            },
        passive: 
            {
                ability1: "Increase Critical DMG by 50%.",
                ability2: "Increase PHY RES by 10%.",
                ability3: "Increase Critical Rate by 100% while unaffected by status ailments."
            }, 
        dream: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 50,
                        hover2: `Frostsprite Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragPalamicia.png`,
                        mat3amt: 2,
                        hover3: `Palamicia`
                    },
                    passive: {
                        ability1: `Increase own Equipment CT Speed by 10%.`,
                        ability2: `Increase all allies' Max HP by 10%. Does not stack with Abilities of the same name.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragRagsherumPhantom.png`,
                        mat3amt: 2,
                        hover3: `Ragsherum Phantom`
                    },
                    passive: {
                        ability1: `Increase own Status RES (except Faint) by 30%.`,
                        ability2: `Increase own CRI DMG by 60%.`
                    }
                }
            },
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat3amt: 1,
                    hover3: "Oldeus",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Rimuru's Haori",
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_152851.png",
                skill: "50s CT - For 10s, increase all allies' Arts gauge by 4/s and Dark DMG by 40% (stackable).",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by New Year Rimuru, increase own DMG by 40%. Does not stack with Abilities of the same name.",
                        ability2: "Increase DEF by 5%."
                    }
            },
        lore: 
            {   
                evo5: "A slime reincarnated into another world. Learning that Reigrad was still low on food after the Christmas celebration, he decided to help replenish their supplies. He used the full extent of his intelligence to help solve the mountain of problems from increasing crop growth to finding other sources of meat.",
                evoawk: "A slime reincarnated into another world. He contributed his know-how to Rayas' group and helped gather enough food to celebrate New Year's. Everyone was in awe as he demonstrated the leadership and skillfulness that let him build a country from the ground up in his home land."
            },
        review:
        {
            skill: `Given NYRim's 100% crit rate out of status ailment, this is a free 50 arts fairly frequently what with the pretty short cooldown.
            `,
            arts: `While the buff won't be too noticible, this is still a decent pseudo heal for those cases when you need to top up on hp or cannot use healing over time.`,
            truearts: `A very strong and long lasting stackable buff paired with a decent multiplier allows NYRim to function very well as a DPS on his own while also being able to provide ample damage support for the rest of the team.`,
            ability1: `A nice DPS buff to help NYRim's personal damage and let him hit harder than he already does.`,
            ability2: `While his lore does give this ability a bit more context, it's still rather odd. But a little extra survivability is always appreciated.`,
            ability3: `This ability is part of what makes NYRim such a good DPS, and while the status ailment restriction might hinder him against bosses who like to apply a lot of status, this can usually be remidied with a taunt or cleansing equipment.
            `,
            trueweapon: {
                skill: ``,
                ability1: ``,
                ability2: ``
            },
            overall: `Unlike Vox, NYRim is comperable to some of our less cracked-out-of-their-mind but still good modern DPS options what with his artsgen and damage self sufficiencies and no need to activate Magia Drive. In addition to this, assuming everything isn't dead already, he can also maintain at least 2-3 stacks of his damage buff for the whole team as long as he has a good supply of arts and no statuses. This paired with his good slots and decent pseudo heal for when you need it, this makes NYRim a great option for pure damage support, and even a slot mule / support for nuking if one wants. And no, he by no means makes Vox useless.`,
            lastupdated: `February 2, 2023`,
            author: `Pula`
        }
    },
    { //New Years Milim - Complete, Review Complete
        id: 107436412,
        name: "New Years Milim",
        rank: "A",
        luckrank: "S",
        attribute: "Light",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107435412.png",
                detailawk: "/db/Units/Detail/unit_detail_107436412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107435412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107436412.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7300,
                hpplus: 999,
                atk: 3120,
                atkplus: 478,
                def: 2288,
                defplus: 353
            },
        skillset: 
            {
                skill: "10s CT - 2000% Light DMG (PHY). For 7s, increase own ATK by 20%.",
                skillbreak: 500,
                arts: "24000% Light DMG (PHY). For 20s, increase own Accuracy by 60% and own DMG by 60%.",
                artsbreak: 1300,
                truearts: "85000% Light DMG (PHY). For 15s, reduce Enemies' Light RES by 70%.",
                trueartsbreak: 2000
            },
        passive: 
            {
                ability1: "At the start of a Quest, for 60s, increase Allies' ATK by 50%.",
                ability2: "At the start of a Quest, increase Allies' Arts by 50.",
                ability3: "Increase MAG RES by 10%."
            },
        dream: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragIfrit.png`,
                        mat3amt: 2,
                        hover3: `Ifrit`
                    },
                    passive: {
                        ability1: `Increase all Allies' Accuracy by 20%. Does not stack with Abilities of the same name.`,
                        ability2: ``
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 50,
                        hover2: `Eternaglint Soulgem`,
                        mat3: `/db/Icons/dungeon_drops/fragRadachne.png`,
                        mat3amt: 2,
                        hover3: `Radachne`
                    },
                    passive: {
                        ability1: `Increase Light Allies' Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: ``
                    }
                }
            },
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat3amt: 1,
                    hover3: "Radachne",
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Milim's Kimono",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_152861.png",
                skill: "45s CT - 5000% Light DMG (PHY). For 12s, increase DMG to Giant Bosses by 70%.",
                skillbreak: 500,
                passive: 
                    {
                        ability1: "When equipped by New Year Milim, increase Arts by 3/s. Does not stack with Abilities of the same name.",
                        ability2: "Increase DEF by 5%."
                    }
            },
        lore: 
            {   
                evo5: "One of the oldest Demon Lords. Reigrad had enough food to satisfy her during Christmas, but when she found out they were running low for the New Year's festival, she decided to help gather the necessary ingredients. She set out in high spirits, but something unexpected happened during the hunt...",
                evoawk: "One of the oldest Demon Lords. She went to hunt beasts and help gather ingredients for the New Year's fest with Rayas. However, when faced with the horde, she was frozen in place. As for why one as strong and powerful as her would hesitate..."
            },
        review:
            {
                skill: `Minor Atk buff, but better than nothing.`,
                arts: `A nice accuracy buff of 50%, which is niche. On the other hand, the 80% DMG is a huge buff for New Year Milim damage output. However, you will not use this Art over her True Art in most cases.`,
                truearts: `A huge Light res debuff with a good multiplier and a decent uptime. Makes New Year Milim a competent Light Nuke Support and a good dps unit.`,
                ability1: `A huge ATK buff, which is tied to 60s. However, her main purpose will be a Light Nuke support where 60s is more than enough to nuke. Although a damage buff would be appreciated over it.`,
                ability2: `Free 50 Arts for the whole team, which is appreciated especially for nuking. To take note, it doesn’t stack with Forte’s Ability.`,
                ability3: `A rather small amount of RES, but better than nothing.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `New Year Milim is overall, a very competent Light Nuke Support with her 50% Atk up passive, 
                and the 50% Light Res Down on TA. The increase of 50 Arts for all allies is a nice bonus as well as 
                her slots are great for nuking. On the other hand, she can act as a good dps unit, which is able to 
                dish out a huge amount of damage, if being able to use her Art before using her True Art. Although 
                her main role is being a Nuke Support for Light nukes. For either Newer or Veterans she’s a great 
                addition to your boxes. As she fulfills the role of being a Nuke Support and a DPS unit very well.`,
                lastupdated: `February 2, 2023`,
                author: `Alice`
            }
    },
    { //Hatsune Miku - Complete
        id: 107456212,
        name: "Hatsune Miku",
        luckrank: "B",
        rank: "A-",
        attribute: "Water",
        type: "Machine",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107455212.png",
                detailawk: "/db/Units/Detail/unit_detail_107456212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107455212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107456212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7039,
                hpplus: 999,
                atk: 2739,
                atkplus: 420,
                def: 2939,
                defplus: 450
            },
        skillset: 
            {
                skill: "10s CT - 1400% Water DMG (MAG). Increase Allies' Arts by 5 and heal Allies' HP by 200.",
                skillbreak: 400,
                arts: "10000% Water DMG (MAG). For 15s, reduce Enemies' DMG and Critical Rate by 30%.",
                artsbreak: 900,
                truearts: "32000% Water DMG (MAG). For 20s, increase Allies' Arts by 4/s, and increase Water Allies' Skill CT REC by 100%.",
                trueartsbreak: 1200
            },
        passive: 
            {
                ability1: "Increase Equipment CT REC by 10%.",
                ability2: "Increase BE generation by 25%.",
                ability3: "Increase PHY evasion by 10% and increase Paralysis and Freeze RES by 100%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragCodeXTF.png",
                    mat3amt: 1,
                    hover3: "Code-XTF",
                }
            },
        lore: 
            {   
                evo5: "An electronic diva who appears before music lovers to sing beautiful songs. It was her voice that saved the silent Bamint from the Giant's winter. In her words, she performs at the request of a higher being called the Maestro. Although no one knows how she ended up in Raktehelm, one theory claims Raktehelm itself, desiring to hear music again, called her in a manner similar to The Maestro.",
                evoawk: "An electronic diva with a lovely singing voice. It is said that in her world, she is a virtual entity surrounded by music who lives with a higher being called The Maestro. After being summoned to Raktehelm, she was sad to learn the people had forgotten how to sing. Thanks to her moving performance that expressed gratitude for all music, they were reminded of its joy."
            }
    },
    { //Sakura Miku - Complete
        id: 107466312,
        name: "Sakura Miku",
        luckrank: "B",
        rank: "A-",
        attribute: "Earth",
        type: "Machine",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107465312.png",
                detailawk: "/db/Units/Detail/unit_detail_107466312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107465312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107466312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6439,
                hpplus: 999,
                atk: 2939,
                atkplus: 450,
                def: 2939,
                defplus: 450
            },
        skillset: 
            {
                skill: "11s CT - 1200% Earth DMG (MAG). For 6s, increase Allies' accuracy by 10%.",
                skillbreak: 400,
                arts: "13000% Earth DMG (MAG). Heal Allies' Disease, Freeze and Blind. Increase Allies' Arts by [50 × # of status ailments cured on that ally].",
                artsbreak: 1000,
                truearts: "36000% Earth DMG (MAG). For 20s, increase Allies' Arts by 4/s, and increase Earth Allies' ATK by 100%.",
                trueartsbreak: 1400
            },
        passive: 
            {
                ability1: "Heal own HP by 20/s.",
                ability2: "Increase accuracy by 50%.",
                ability3: "Increase MAG evasion by 10% and increase Paralysis and Freeze RES by 100%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat3amt: 1,
                    hover3: "Elpneumas",
                }
            },
        lore: 
            {   
                evo5: "An electronic diva summoned to Bamint when a relentless chill griped the entire world. She sang a warming melody to give relief to the Humans and suppress the Giants. Even as the spring thaw began in earnest, it was said the wind was a little fresher and the scenery a little greener if she was nearby.",
                evoawk: "An electronic diva who restored spring and song to Raktehelm. Clad in the blossoms that announce the chaging of season, she saved Bamint from an endless cold. Her voice not only reminded the people of the joy of singing, but it also called a wind said to have made the flowers sing as well. For this, some believed her to be a priestess of ancient legend. However, before this could be confirmed, she vanished in a flurry of petals wearing a satisfied smile."
            }
    },
    { //Yoh - Complete (+ JP version), Review done
        id: 107476112,
        name: "Yoh Asakura",
        rank: "A",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107475112.png",
                detailawk: "/db/Units/Detail/unit_detail_107476112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107475112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107476112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6780,
                hpplus: 999,
                atk: 2900,
                atkplus: 445,
                def: 2613,
                defplus: 401
            },
        skillset: 
            {
                skill: "13s CT - 2200% Fire DMG (PHY). Permanently increase own DMG by 25% (up to 125%).",
                skillbreak: 900,
                arts: "25000% Fire DMG (PHY). For 15s, increase Allies' PHY DMG by [20% × # of times this Arts has been activated] (up to 120%).",
                artsbreak: 1300,
                truearts: "48000% Fire DMG (PHY). For 20s, increase Allies' Arts by 4/s, Fire Allies' ATK by 100%.",
                trueartsbreak: 2000
            },
        skillsetJP: 
            {
                skill: "13s CT - 2200% Fire DMG (PHY). Permanently increase own DMG by 20% (up to 100%).",
                skillbreak: 900,
                arts: "22000% Fire DMG (PHY). For 15s, increase Allies' PHY DMG by [20% × # of times this Arts has been activated] (up to 120%).",
                artsbreak: 1300,
                truearts: "48000% Fire DMG (PHY). For 20s, increase Allies' Arts by 4/s, Fire Allies' ATK by 100%.",
                trueartsbreak: 2000
            },
        passive: 
            {
                ability1: "When near-death, increase DMG by 10%.",
                ability2: "When near-death, increase DMG RES by 10%.",
                ability3: "Increase Arts by 3/s if unafflicted by any status ailment."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 1,
                    hover3: "Flogassa",
                }
            },
        lore: 
            {   
                evo5: "A shaman, one who connects the real world with the spirit realm. He has a laid-back personality best demonstrated by his favorite saying: 'Everything will work out.' He fights with his guardian ghost, Amidamaru, a legendary samurai once feared as a demon, and the sword he used, Harusame.",
                evoawk: "A shaman, one who connects the real word with the spirit realm. He pushed his power to a new extreme after intense training, and even Tao Ren admits his superiority as a rival. His fiancée Anna made the battle outfit he wears."
            },
        review:
            {
                skill: `Although it has an above average CT, its still an amazing skill that permanently increases Yoh's DMG greatly. This is especially so for multi-wave quests where his Skill resets each stage for an easier time stacking DMG buffs. Definitely try to get this skill off as much as possible till max stacks.`,
                arts: `A decent multiplier with a great offensive buff for physical damage oriented teams. Do note that his physical damage buff will be permanently 120% at max stacks and it is not able to stack with physical damage buffs from other units such as Cestina and Swordsman Berwick. Since it requires a hefty 600 arts for max stacks, you would usually stack this arts when you have the luxury to do so or for longer stages. Otherwise, you are better off sticking to his True Arts for the Arts generation and buffs.`,
                truearts: `An average damage multiplier, alongside great arts generation capabilities. Yoh's True Arts coupled with his Skill and Normal Arts buffs, enables him to perform as a competent DPS as well. Overall, this allows Yoh to be easily slotted into general teams and further shine in Fire elemental teams due to the attack buff.`,
                ability1: `A small increase to his damage output when HP falls below 40%, but you don't ever want to be near death in the first place.`,
                ability2: `A small increase to his survivability when HP falls below 40%, but similar to his first passive, you don't want to be near-death in the first place.`,
                ability3: `A significant passive to Yoh's kit as it ensures him self-sufficiency as long as he is free of any status ailments, allowing him to perform his support capabilities more efficiently.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Although he got outshone by Hao in the hype-side of things, Yoh is still definitely an impressive unit. His overall kit and self sufficiency allows him to be a flexible addition to any team by providing both consistent Arts generation and buffs. He is also one of a few Fire elemental ArtsGen unit, and would excel in mono-fire elemental teams alongside his attack buff on True Arts. His Arts when ramped is also amazing as a permanent offensive support for physical damage oriented teams. Overall, Yoh is a solid unit and a great addition to any box.`,
                lastupdated: `November 4, 2022`,
                author: `Jeffers`
            }
    },
    { //Anna Kyoyama- Complete (+ JP version), Review done
        id: 107486312,
        name: "Anna Kyoyama",
        rank: "B+",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107485312.png",
                detailawk: "/db/Units/Detail/unit_detail_107486312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107485312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107486312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6948,
                hpplus: 999,
                atk: 2620,
                atkplus: 403,
                def: 2650,
                defplus: 407
            },
        skillset: 
            {
                skill: "12s CT - 1200% Earth DMG (MAG). For 8s, reduce Enemies' evasion by 15%.",
                skillbreak: 400,
                arts: "16000% Earth DMG (MAG). Increase Allies' Arts by 20. For 15s, reduce Enemies' DMG by 25%.",
                artsbreak: 1000,
                truearts: "40000% Earth DMG (MAG). For 20s, Allies ignore 100% of Enemies' DEF, increase Earth Allies' DMG by 50%.",
                trueartsbreak: 1600
            },
        skillsetJP: 
            {
                skill: "12s CT - 1200% Earth DMG (MAG). For 8s, reduce Enemies' evasion by 10%.",
                skillbreak: 400,
                arts: "13000% Earth DMG (MAG). Increase Allies' Arts by 20. For 15s, reduce Enemies' DMG by 25%.",
                artsbreak: 1000,
                truearts: "35000% Earth DMG (MAG). For 20s, Allies ignore 100% of Enemies' DEF, increase Earth Allies' DMG by 50%.",
                trueartsbreak: 1600
            },
        passive: 
            {
                ability1: "Increase Equip CT REC by 10%.",
                ability2: "Increase DMG against Enemies in BREAK by 20%.",
                ability3: "Increase Fire and Earth Allies' DMG by 40%. Does not stack with Abilities of the same name."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat3amt: 1,
                    hover3: "Tonetaurus",
                }
            },
        lore: 
            {   
                evo5: "The Itako of Osorezan and Asakura Yoh's fiancée. She can call spirits with her channeling powers. Though stern and fearless, she also has a thoughtful side as shown by the battle outfit she made for Yoh.",
                evoawk: "The Itako of Osorezan and Asakura Yoh's fiancée. After obtaining the Ultra Senji Ryakketsu, she instantly tamed the Shikigami within, Zenki and Goki, to become her guardian ghosts."
            },
        review:
            {
                skill: `Long CT for a mediocre multiplier, topped with a negligible Evasion debuff.`,
                arts: `At first it may sound nice, providing burst Arts gauge and a soft survival tool in the form of Enemy DMG Reduction, a rarer form of DMG RES UP (and stacks multiplicatively with). However her slow slotting hinders her from performing as a sub artsgen. You’re better sticking to her TA most of the time and should only use NA in case your units are <20 Arts away from a TA. As for the DMG reduction, most of the time it’s better to just bring a form of hard survival (barrier and/or heal) with additional Defense equips if still not enough, since on bosses that apply large DMG Up buffs on self, this effect won’t have much impact.`,
                truearts: `An average multiplier coupled with DEF Ignore and a hefty teamwide 60% Earth DMG buff. This is where the bulk of her kit lies, providing a great offensive tool in a mono-Earth comp, both in sustain and nuking. Add her third passive into play and you get a whopping 100% Earth DMG UP. The generously long duration of the buffs can also help leverage Allies’ damage in sustain comps, aside from niche usage on certain stages.`,
                ability1: `A small Equip CT buff to help equips cycle faster.`,
                ability2: `Any DMG buff is always appreciated, though most stages won’t require Break.`,
                ability3: `A huge, unconditional damage boost for Earth and Fire Allies further incentivizes, though not limit, her usage in mono-Earth teams.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Anna is a great addition to mono-Earth comps, providing up to 100% Earth DMG buff topped with DEF Ignore at a long duration. With a 20 Arts gauge refund on Arts, one might assume she can perform as a sub artsgen. However, her bad slotting heavily hinders her in this regard. As for her Enemy DMG Reduction debuff, it’s merely a scratch on the sheer amount of DMG UP buffs a boss can apply. This makes her NA better thought as an additional tool when you have enough artsgen to rotate her NA and TA. Packing heavy Earth-based DMG buffs and a lack of notable utilities, Anna has a hard time finding a use outside mono Earth situations.`,
                lastupdated: `November 4, 2022`,
                author: `Zanster`
            }
    },
    { //Ren - Complete (+ JP version), Review done
        id: 107496512,
        name: "Ren Tao",
        attribute: "Dark",
        rank: "B+",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107495512.png",
                detailawk: "/db/Units/Detail/unit_detail_107496512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107495512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107496512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7101,
                hpplus: 999,
                atk: 3015,
                atkplus: 469,
                def: 2510,
                defplus: 405
            },
        skillset: 
            {
                skill: "10s CT - 1700% Dark DMG (PHY). Increase own Arts by 25. If unaffected by Status Ailments, Increase own Arts by 50 instead.",
                skillbreak: 400,
                arts: "21000% Dark DMG (PHY). Permanently increase own DMG by 60% (up to 300%).",
                artsbreak: 1200,
                truearts: "60000% Dark DMG (PHY). For 15s, increase own ATK by [# of Dark Units in Party x 50%].",
                trueartsbreak: 2300
            },
        skillsetJP: 
            {
                skill: "10s CT - 1700% Dark DMG (PHY). Increase own Arts by 20. If unaffected by Status Ailments, Increase own Arts by 40 instead.",
                skillbreak: 400,
                arts: "18000% Dark DMG (PHY). Permanently increase own DMG by 50% (up to 250%).",
                artsbreak: 1200,
                truearts: "60000% Dark DMG (PHY). For 15s, increase own ATK by [# of Dark Units in Party x 50%].",
                trueartsbreak: 2300
            },
        passive: 
            {
                ability1: "At the start of a Battle, increase own Arts by 50.",
                ability2: "Increase DMG to Enemies under 30% HP by 60%.",
                ability3: "Increase evasion by 10%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragMavroskia.png",
                    mat3amt: 4,
                    hover3: "Mavroskia",
                }
            },
        lore: 
            {   
                evo5: "The son of a shaman family and Asakura Yoh's rival. Placing great pride in himself, he fights with his ancestral guardian ghost Bason and a weapon called Basontou.",
                evoawk: "The son of a shaman family and Asakura Yoh's Rival. To prepare for the Shaman Fight, he strengthened the Basontou to perform the powerful Golden Vorpal Dance."
            },
        review:
            {
                skill: `A large source of Arts Gen at 5 Arts/s if its condition is met, also aiding in stacking Ren's Arts in order to build up his DMG.`,
                arts: `With a hefty cost of 500 total Arts to max his buffs, like all ramping units, this is a fairly expensive Arts. However, due to his Passive's and Skill's ArtsGen, it only takes Ren 5 - 6 Stages to fully ramp given the right conditions, providing a large 300% DMG boost to himself. This Arts can also be used as a stage clear due to said Passive & Skill.`,
                truearts: `Possessing a large Multiplier for nuking, although its damage is heavily dependent on Ren’s NA stacks and the amount of Dark Allies on the team. The ideal use would be within Dark mono nuke comps in a quest with multiple pre-boss waves.`,
                ability1: `A nice Arts boost at the start of every wave to help Ren ramp up his damage by using his Arts.`,
                ability2: `A good DMG boost to help Ren finish low health bosses.`,
                ability3: `A very insignificant passive.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `A Dark Nuke unit with ramping damage and the ability to clear mob stages due to his Skill and Passive. Although Ren can work as a DPS for sustained content thanks to his Skill’s 5 Arts/s and permanent 300% DMG buff from his Arts, at the cost of 500 total Arts, he will usually only shine at multi-wave stages where he is able to stack DMG buffs on pre-boss waves.`,
                lastupdated: `November 4, 2022`,
                author: `Zanster`
            }
    },
    { //Hao - Complete (+ JP version), Review done
        id: 107506112,
        name: "Hao",
        rank: "A-",
        attribute: "Fire",
        type: "God",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107505112.png",
                detailawk: "/db/Units/Detail/unit_detail_107506112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107505112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107506112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 5,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7015,
                hpplus: 999,
                atk: 3030,
                atkplus: 464,
                def: 2570,
                defplus: 443
            },
        skillset: 
            {
                skill: "18s CT - 3000% Fire DMG (MAG). For 15s, increase own ATK by 20%.",
                skillbreak: 800,
                arts: "20000% Fire DMG (MAG). Increase Allies' (Except Self) Arts by 20. For 20s, reduce Enemies' Burn RES by 50%.",
                artsbreak: 1200,
                truearts: "70000% Fire DMG (MAG). 100% chance to inflict Burn for 15s. For 20s, reduce Enemies' MAG RES by 50% + If this True Arts inflicts Burn, for 20s, reduce Burnt Enemies' Fire RES by 50%.",
                trueartsbreak: 2400
            },
        skillsetJP: 
            {
                skill: "18s CT - 3000% Fire DMG (MAG). For 10s, increase own ATK by 20%.",
                skillbreak: 800,
                arts: "17000% Fire DMG (MAG). Increase Allies' (Except Self) Arts by 20. For 20s, reduce Enemies' Burn RES by 50%.",
                artsbreak: 1200,
                truearts: "70000% Fire DMG (MAG). 100% chance to inflict Burn. For 15s, reduce Enemies' MAG RES by 50% + If this True Arts inflicts Burn, for 15s, reduce Burnt Enemies' Fire RES by 50%.",
                trueartsbreak: 2400
            },
        passive: 
            {
                ability1: "When own HP is above 70%, increase own Arts by 7/s. Does not stack with Abilities of the same name.",
                ability2: "Increase Freeze RES by 100%.",
                ability3: "Increase Burn RES by 100%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 1,
                    hover3: "Flogassa",
                }
            },
        lore: 
            {   
                evo5: "The strongest shaman who calls himself the Future King. He boasts incredible strength as the master of the Hao School. He often says 'puny'.",
                evoawk: "The strongest shaman who calls himself the Future King. His guardian ghost is the Spirit of Fire, one of the five Grand Elemental Spirits, which can become more powerful by consuming souls."
            },
        review:
            {
                skill: `A rather negligible attack buff with a high uptime that will help increase his damage.`,
                arts: `This Arts gives decent arts to all allies while being self sufficient from his passive. With this, Hao can act as a good arts gen when you're low on arts. This Arts also has the capability to reduce Burn RES, which can be very helpful when it comes to dealing with bosses with Burn RES.`,
                truearts: `Well, there is certainly a lot to say about this TA. Apart form boasting a high multiplier, Hao offers some of the highest debuffs in game, capable of inflicting both 50% MAG RES down and 50% Fire RES down, which can be easily done with his Arts reducing Burn RES as well as his True Arts already having a 100% chance to Burn.`,
                ability1: `This is... easily one of the strongest passive in game. An innate 7 arts per second which can stack with other arts gen, allowing Hao to continuously cycle Arts after Arts.`,
                ability2: `Allows Hao to evade Freeze for the situations which call for it.`,
                ability3: `Allows Hao to evade Burn for the situations which call for it.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Crazy high multiplier and debuffs on TA, highest arts/s passive in game, as well as being able to carry three 5 star slots. With the capability to cycle his Arts repeatedly from his passive ArtsGen, he can constantly dish out damage. Hao is definitely a very consistent DPS, especially if the enemy does not have Burn RES.`,
                lastupdated: `November 4, 2022`,
                author: `Zanster`
            }
    },
    { //Rudeus - Complete
        id: 107516312,
        name: "Rudeus",
        rank: "B+",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107515312.png",
                detailawk: "/db/Units/Detail/unit_detail_107516312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107515312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107516312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6750,
                hpplus: 999,
                atk: 2919,
                atkplus: 447,
                def: 2626,
                defplus: 403
            },
        skillset: 
            {
                skill: "10s CT - 2000% Fire and Earth DMG (MAG). 50% chance to Inflict Burn for 8s.",
                skillbreak: 800,
                arts: "16000% Earth DMG (MAG) For 15s, reduce Enemies' MAG RES and evasion by 30%.",
                artsbreak: 1400,
                truearts: "60000% Earth DMG (MAG). For 20s, increase Allies' Skill CT REC by 100%, and increase Allies' Arts DMG by [30% × # of Earth Allies in party].",
                trueartsbreak: 2200
            },
        passive: 
            {
                ability1: "Increase DMG to Demons by 20%.",
                ability2: "When near-death, increase DMG RES by 10%.",
                ability3: "When HP is above 90%, increase own Arts by 3/s. Additionally, increase own Arts by 3/s if unafflicted by Status Ailments."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragElpneumas.png",
                    mat3amt: 1,
                    hover3: "Elpneumas",
                }
            },
        lore: 
            {   
                evo5: "A boy who reincarnated into another world after saving students from a runaway truck. Regrets from his pathetic former life convinced him to make the most of his second chance. He started learning magic in early childhood, giving him a vast mana store and the ability to cast high-level spells without chanting. Though he acts polite, his inner thoughts tend to be perverted and impudent.",
                evoawk: "He bacame Eris' tutor at the age of seven to earn the money to attend the Ronoa University of Magic. However, the day after he turned ten, the two found themselves in the Demon Continent due to the Fittoa Territory Displacement Incident. After becoming allies with a superd named Ruijerd, they formed a party titled Dead End and made their way towards the Central Continent."
            }
    },
    { //Roxy - Complete
        id: 107526212,
        name: "Roxy",
        rank: "B",
        attribute: "Water",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107525212.png",
                detailawk: "/db/Units/Detail/unit_detail_107526212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107525212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107526212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6550,
                hpplus: 999,
                atk: 2888,
                atkplus: 443,
                def: 2822,
                defplus: 433
            },
        skillset: 
            {
                skill: "12s CT - 1500% Water DMG (MAG). Increase own Arts by 30.",
                skillbreak: 700,
                arts: "15000% Water DMG (MAG). Increase Allies' next True Art's DMG by 50%. For 15s, increase Allies' DMG RES by 20%.",
                artsbreak: 1300,
                truearts: "50000% Water DMG (MAG). For 20s, reduce Enemies' Water and MAG RES by 40%.",
                trueartsbreak: 1800
            },
        passive: 
            {
                ability1: "Increase MAG RES by 10%.",
                ability2: "Increase BE generation by 10%.",
                ability3: "Increase Arts DMG by 50% if unafflicted by Status Ailments."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat3amt: 1,
                    hover3: "Freydal",
                }
            },
        lore: 
            {   
                evo5: "A magician of the demonic Migurd tribe who knows Saint-class water spells. She left her home village on the Demon Continent to enroll in the Ronoa University of Magic. After graduating, she was hired as Rudeus' live-in instructor. She is talented enough to be immortalized in troubadour song, but she also has a clumsy side and lacks confidence in her figure.",
                evoawk: "After working for the Greyrat family, she was hired as a private tutor for the prince of Shirone. Even so, that care for her former student remained strong. Not only did she send him a self-made textbook on Demon language when he mentioned wanting to learn it, she dropped everything and left for the Demon Continent after hearing he was caught in the Fittoa Territory Displacement Incident."
            }
    },
    { //Sylphiette - Complete
        id: 107536412,
        name: "Sylphiette",
        rank: "A+",
        attribute: "Light",
        type: "Spirit",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107535412.png",
                detailawk: "/db/Units/Detail/unit_detail_107536412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107535412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107536412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/healLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6955,
                hpplus: 999,
                atk: 2410,
                atkplus: 371,
                def: 2952,
                defplus: 452
            },
        skillset: 
            {
                skill: "12s CT - 1200% Light DMG (MAG). For 8s, increase own Healing Output by 10%.",
                skillbreak: 100,
                arts: "Heal Allies' HP by 30% and increase Allies' Arts by 25 + For 15s, increase Allies' Status RES (except Faint) by 100%.",
                artsbreak: 0,
                truearts: "12000% Light DMG (MAG). Cure Allies' Poison. For 20s, increase Allies' DMG RES and MAG RES by 20%.",
                trueartsbreak: 1600
            },
        passive: 
            {
                ability1: "Increase Human, Beast and Spirit Allies' ATK by 30%. Does not stack with Abilities of the same name.",
                ability2: "Increase Critical RES by 20%.",
                ability3: "Heal own HP by 16/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat3amt: 1,
                    hover3: "Radachne",
                }
            },
        lore: 
            {   
                evo5: "A kind and obedient quarter-elf girl. She used to be bullied because her hair color was the same as that of the bloodthirsty Superd tribe until Rudeus stood up for her. He mistook her for a boy at first, but they became close friends, and she even learned magic from him.",
                evoawk: "As she and Rudeus grew up, their parents thought they were becoming too reliant on each other. To put some distance between them, Rudeus father sent him to the Boreas Greyrat household as tutor for his cousin. His absence made her realize she needed to become stronger not just for her sake, but his as well."
            }
    },
    { //Eris - Complete
        id: 107546112,
        name: "Eris",
        rank: "B+",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107545112.png",
                detailawk: "/db/Units/Detail/unit_detail_107546112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107545112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107546112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7200,
                hpplus: 999,
                atk: 3058,
                atkplus: 468,
                def: 2405,
                defplus: 370
            },
        skillset: 
            {
                skill: "12s CT - 2000% Fire DMG (PHY). Increase Arts by 30.",
                skillbreak: 400,
                arts: "15000% Fire DMG (PHY). For [# of times this Arts has been activated (up to 6) × 10]s, increase own DMG by 100% (stackable).",
                artsbreak: 1200,
                truearts: "66000% Fire DMG (PHY). Increase this True Art's Critical Rate by 100%.",
                trueartsbreak: 1800
            },
        passive: 
            {
                ability1: "When HP is above 90%, increase Skill CT REC and Critical DMG by 30%.",
                ability2: "Increase accuracy by 20%.",
                ability3: "Increase PHY evasion by 5%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 1,
                    hover3: "Flogassa",
                }
            },
        lore: 
            {   
                evo5: "The noble Boras Greyrat family's ferocious daughter. Her father thought that she would become a better adventurer than an aristocrat and raised her accordingly. Though prone to violence, she obeys orders if given a proper reason. Her talent in the Sword-God style of fighting impresses both Rudeus and her combat instructor Ghislaine.",
                evoawk: "In three years, her talent and temperament improved under Rudeus' guidance. However, the day after his tenth birthday was the fateful Fittoa Territory Displacement Incident. They ended up lost in the Demon Continent but were soon taken under the protection of Superd named Ruijerd. Forming the 'Dead End' party, the trio started for the Central Continent."
            }
    },
    { //Natsu Dragneel - Complete, Review done + JP ver
        id: 107556112,
        name: "Natsu Dragneel",
        luckrank: "B",
        rank: "B+",
        attribute: "Fire",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107555112.png",
                detailawk: "/db/Units/Detail/unit_detail_107556112.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_755_1.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107555112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107556112.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_755_1.png",
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        slotsJP: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6706,
                hpplus: 999,
                atk: 3022,
                atkplus: 463,
                def: 2822,
                defplus: 433
            },
        skillset: 
            {
                skill: "12s CT - 1900% Fire DMG (PHY). For 8s, reduce Enemies' DMG RES by 10%.",
                skillbreak: 400,
                arts: "27000% Fire DMG (PHY). For 25s, increase own ATK by 100% and reduce Enemies' Burn RES by 50%.",
                artsbreak: 1100,
                truearts: "68000% Fire DMG (PHY). 100% chance of inflicting Burn. For 20s, Allies ignore 100% of Enemies' DEF.",
                trueartsbreak: 1800
            },
        skillsetJP: 
            {
                skill: "12s CT - 1900% Fire DMG (PHY). For 8s, reduce Enemies' DMG RES by 10%.",
                skillbreak: 400,
                arts: "22000% Fire DMG (PHY). For 20s, increase own ATK by 100% and reduce Enemies' Burn RES by 50%.",
                artsbreak: 1100,
                truearts: "68000% Fire DMG (PHY). 100% chance to inflict Burn for 15s. For 15s, Allies ignore 100% of Enemies' DEF.",
                trueartsbreak: 1800
            },
        passive: 
            {
                ability1: "When own HP is above 70%, increase own Arts by 3/s, Freeze and Paralysis RES by 100%.",
                ability2: "Increase BE generation by 10%.",
                ability3: "Increase own DMG by 100% against Burnt Enemies.",
                ability4: `Unit EXP UP 20% UP. (When Natsu's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Natsu's SP. Art is owned)`
            },
        passiveJP: 
            {
                ability1: "When own HP is above 70%, increase own Arts by 3/s, Freeze and Paralysis RES by 100%.",
                ability2: "Increase BE generation by 10%.",
                ability3: "Increase own DMG by 100% against Burnt Enemies."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                    mat3amt: 1,
                    hover3: "Flogassa",
                }
            },
        dreamGL: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 30,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragIfrit.png",
                        mat3amt: 1,
                        hover3: "Ifrit",
                    },
                    passive: {
                        ability1: `Increase own Accuracy by 50%.`,
                        ability2: `Increase own DMG by 80% when enemies' HP is under 30%.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 30,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragValzandeth.png",
                        mat3amt: 1,
                        hover3: "Valzandeth",
                    },
                    passive: {
                        ability1: `Increase own Arts by 2/s.`,
                        ability2: `Increase own DMG against Demon enemies by 120%.`
                    }
                }
            },
        lore: 
            {   
                evo5: "A wizard of Fairy Tail. He was raised by the dragon Igneel, taking on his nickname of Salamander. His short-tempered nature makes him the guild's biggest troublemaker.",
                evoawk: "A wizard of Faity Tail. Known by his title of Dragon Slayer. he is a master of fire-based hand-to-hand combat. Though his impulsiveness gets him into fights, his fortitude lets him overcome any opponent."
            },
        review:
            {
                skill: `Nice little DMG RES debuff, nothing noteworthy but not entirely redundant as well.`,
                arts: `A long-lasting ATK buff and Burn RES debuff. Additionally, the Burn RES debuff can be helpful when paired with his True Arts for enemies with innate Burn RES. Otherwise, his Arts will generally not see use for most situations.`,
                truearts: `What’s there not to like about a decent DMG multiplier with teamwide DEF Ignore buff. One of the few units in the game, as well as the only Fire unit able to give teamwide DEF Ignore. Furthermore, the 100% chance to inflict Burn synergises particularly well with one of his passives, which will be covered below.`,
                ability1: `Amazing passive 3 arts/s generation as well as a nice 100% RES to both Freeze and Paralysis at the cost of keeping Natsu above 70% HP. With heals or barriers in the team, Natsu should rarely fall below this threshold and should be able to take full advantage of this passive.`,
                ability2: `Nothing much to say here, a nice BE boost, although its impact is usually negligible.`,
                ability3: `Sheeesh~ That’s a significant DMG buff against Burning enemies. As stated earlier, most enemies won’t have innate Burn RES and can be burned by Natsu’s True Arts, allowing Natsu to gain a substantial 100% DMG buff against the Burned enemies.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Being one of the few units and the only Fire unit able to provide teamwide DEF Ignore in the game, Natsu is definitely by no means a useless unit. Although stages which require DEF Ignore are few and far between, he can still act as a competent DPS unit with his high True Arts DMG multiplier paired with his passive significantly increasing DMG against Burning enemies. Moreover, he is able to passively gain Arts from simply being above 70% HP, which isn’t very difficult to maintain, allowing him to perform well in sustained fights.`,
                lastupdated: `April 6, 2023`,
                author: `Jeffers`
            }
    },
    { //Lucy Heartfilia - Complete (+ JP version), Review done
        id: 107566412,
        name: "Lucy Heartfilia",
        luckrank: "A",
        rank: "A-",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107565412.png",
                detailawk: "/db/Units/Detail/unit_detail_107566412.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_756_1.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107565412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107566412.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_756_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        slotsJP: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6680,
                hpplus: 999,
                atk: 2950,
                atkplus: 492,
                def: 2663,
                defplus: 409
            },
        skillset: 
            {
                skill: "12s CT - 1500% Light DMG (PHY). For 8s, reduce Enemies' ATK by 10%.",
                skillbreak: 300,
                arts: "15000% Water DMG (MAG). Permanently increase allies' Magic DMG by 30% (stackable, up to 150%).",
                artsbreak: 800,
                truearts: "50000% Light DMG (MAG). For 20s, increase Allies' Critical Rate by 100% + Increase Allies' Arts by [1 × # of Light Allies in the party]/s.",
                trueartsbreak: 1600
            },
        skillsetJP: 
            {
                skill: "12s CT - 1500% Light DMG (PHY). For 8s, reduce Enemies' ATK by 10%.",
                skillbreak: 300,
                arts: "12000% Water DMG (MAG). Permanently increase allies' Magic DMG by 20% (stackable, up to 100%).",
                artsbreak: 800,
                truearts: "45000% Light DMG (MAG). For 15s, increase Allies' Critical Rate by 100% + Increase Allies' Arts by [1 × # of Light Allies in the party]/s.",
                trueartsbreak: 1600
            },
        passive: 
            {
                ability1: "When own HP is above 80%, increase ATK and DMG by 50%.",
                ability2: "Increase DMG to Dark Enemies by 40%.",
                ability3: "Increase own Arts by 2 for every 10% of max HP lost.",
                ability4: `Unit EXP UP 20% UP. (When Lucy's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Lucy's SP. Art is owned)`
            }, 
        passiveJP: 
            {
                ability1: "When own HP is above 80%, increase ATK and DMG by 50%.",
                ability2: "Increase DMG to Dark Enemies by 40%.",
                ability3: "Increase own Arts by 2 for every 10% of max HP lost."
            },
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragRadachne.png",
                    mat3amt: 1,
                    hover3: "Radachne",
                }
            },
            dreamGL: {
                earth: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                        mat2amt: 30,
                        hover2: `Smogdragon Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragElpneumas.png",
                        mat3amt: 1,
                        hover3: "Elpneumas",
                    },
                    passive: {
                        ability1: `Increase own Equip CT Speed by 10%.`,
                        ability2: `Increase all allies' CRI DMG by 30%. Does not stack with Abilities of the same name.`
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 30,
                        hover2: `Eternaglint Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragWilkes.png",
                        mat3amt: 1,
                        hover3: "Wilkes",
                    },
                    passive: {
                        ability1: `Increase Light allies' Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase all allies' Max HP by 15%. Does not stack with Abilities of the same name.`
                    }
                }
            },
        lore: 
            {   
                evo5: "A celestial wizard who joined Fairy Tail with Natsu's help. She is simple-minded and cheerful, but her careful budgeting and love of reading reveals a practical side. Usually teams up with Natsu and Happy.",
                evoawk: "A young celestial wizard. Though she claims to be not too helpful in battle, she can use keys to call celestial spirits to fight in her place. This grants her a wide variety of abilities, and each form is incredibly powerful."
            },
        review:
            {
                skill: `Not much here, the ATK debuff can help with survivability but not very impactful overall.`,
                arts: `Damage is typical for a NA, but also gives a teamwide permanent, stackable buff. It is only for magic damage, encouraging the use of magic-oriented units, though in the worst case scenario, she will give herself a permanent buff at the very least. Using Lucy in conjunction with other sources of artgen can allow her to use her NA 5 times to increase the whole team’s magic damage output by 150%, which is quite substantial. Note that this arts deals Water damage, despite Lucy being a Light unit. This can be helpful if bosses somehow negate or resist light damage, but it generally won't matter much.`,
                truearts: `Decent multiplier for a support unit, but the most important aspect of Lucy’s TA is that it is currently the only light unit that both gives a teamwide crit rate of 100% while simultaneously supplying arts to the team. The only caveat is that Lucy has to be used in a team with at least 3 light units, and preferably mono-light, for her ArtsGen to be relevant.`,
                ability1: `Light tends to have great healers and tanks, so it is not unrealistic to keep Lucy at high HP most of the time. The reward for keeping her HP close to max is a sizable buff to both ATK and DMG.`,
                ability2: `Another buff that, while situational, can be relevant against dark enemies which normally abound in the game.`,
                ability3: `Seems nice in theory, but in practice, she will gain too few arts from this passive to be significant, as she would need to take 5 times her max HP in damage to gain 100 arts.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Lucy does not hide the fact that she is fairly limited to mono-light teams (or teams with 3 light units at the very least). However, she is a strong support for mono-light teams, being capable of permanently ramping the team’s Magic damage with repeated NAs, or contributing to team artgen. Her role is quite obvious and a bit limited compared to the collab’s other units, but she fulfills that role in outstanding fashion.`,
                lastupdated: `April 6, 2023`,
                author: `MOB of Metal`
            }
    },
    { //Gray Fullbuster - Complete (+ JP version), Review done
        id: 107576212,
        name: "Gray Fullbuster",
        rank: "B+",
        luckrank: "S",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107575212.png",
                detailawk: "/db/Units/Detail/unit_detail_107576212.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_757_1.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107575212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107576212.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_757_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        slotsJP: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6677,
                hpplus: 999,
                atk: 2999,
                atkplus: 459,
                def: 2859,
                defplus: 438
            },
        skillset: 
            {
                skill: "12s CT - 1800% Water DMG (PHY). For 7s, increase own accuracy by 20%.",
                skillbreak: 400,
                arts: "22000% Water DMG (PHY). Permanently increase Allies' PHY DMG by 30% (up to 150%).",
                artsbreak: 800,
                truearts: "50000% Water DMG (PHY). For 20s, increase own DMG by 100%. If used for the first time of the Quest, increase Allies' Arts gauge by 70.",
                trueartsbreak: 1800
            },
        skillsetJP: 
            {
                skill: "12s CT - 1800% Water DMG (PHY). For 7s, increase own accuracy by 20%.",
                skillbreak: 400,
                arts: "20000% Water DMG (PHY). Permanently increase Allies' PHY DMG by 20% (up to 100%).",
                artsbreak: 800,
                truearts: "48000% Water DMG (PHY). For 20s, increase own DMG by 100%. If used for the first time of the Quest, increase Allies' Arts by 50.",
                trueartsbreak: 1800
            },
        passive: 
            {
                ability1: "At the start of a Battle, increase own Arts by own Luck.",
                ability2: "Increase Water RES by 10%.",
                ability3: "Increase Freeze RES by 50%.",
                ability4: `Unit EXP UP 20% UP. (When Gray's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Gray's SP. Art is owned)`
            },
        passiveJP: 
            {
                ability1: "At the start of a Battle, increase own Arts by own Luck.",
                ability2: "Increase Water RES by 10%.",
                ability3: "Increase Freeze RES by 50%."
            },
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat3amt: 1,
                    hover3: "Freydal",
                }
            },
            dreamGL: {
                water: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_132152.png`,
                        mat2amt: 30,
                        hover2: `Frostsprite Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragAerugraxes.png",
                        mat3amt: 1,
                        hover3: "Aerugraxes",
                    },
                    passive: {
                        ability1: `Increase own ATK by 50% when own HP is 80% or more.`,
                        ability2: `Increase Human allies' Arts by 1/s. Does not stack with Abilities of the same name.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 30,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragOldeus.png",
                        mat3amt: 1,
                        hover3: "Oldeus",
                    },
                    passive: {
                        ability1: `Increase own Skill CT Speed by 20%.`,
                        ability2: `Increase own DMG against Fire enemies by 60%.`
                    }
                }
            },
        lore: 
            {   
                evo5: "A skilled member of the Fairy Tail. Though he's rude towards Natsu, they recognize each other's talent. Has an odd tendency to suddenly strip down.",
                evoawk: "A skilled member of the Fairy Tail. Called the Ice-Make wizard, he creates ice formations to use as weapons or armor with speed that no one else in his field can match."
            },
        review:
            {
                skill: `Nothing major to note about, the minor acccuracy buff is appreciated when needed.`,
                arts: `Coupled with his passive, this allows Gray to increase the team's PHY DMG, including himself in quests with many prestages, making him very useful as a mob clearer.`,
                truearts: `Another great DMG buff, albiet selfish this time, to burn down the boss after clearing all those prestages. The added first time effect of a massive 70 arts boost to the team helps if anyone is falling behind on arts when they reach the boss, something that Gray likely won't have issues with given his unique passive.`,
                ability1: `The bread and butter of what makes Gray such a good farming unit. When he's maxed at 120 luck, that allows him to use Arts immediately at the start of each battle without having to rely on any BE or equips. With enough prestages, it'll give him a use of True Arts for free.`,
                ability2: `The very minor resistance to Water is appreciated, but I don't think it'll make a difference very much.`,
                ability3: `Once again appreciated to protect him from freeze, but it's basically a coinflip as to if he gets frozen.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Gray comes swinging at farm teams as an effective upgrade to Kazuma from Yu Yu Hakusho. As a Luck unit, he excels at not only clearing waves, but also providing teamwide Arts (for the first time), and even as a decent DPS/Buffer. He provides excellent damage buffs to himself and to the team, with very offensive slots to help in full luck nukes. With all that said, he serves as one of the best Luck units in the game, period.`,
                lastupdated: `April 6, 2023`,
                author: `Senshi`
            }
    },
    { //Erza Scarlet - Complete (+ JP version), Review done
        id: 107586412,
        name: "Erza Scarlet",
        luckrank: "B",
        rank: "A+",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107585412.png",
                detailawk: "/db/Units/Detail/unit_detail_107586412.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_758_1.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107585412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107586412.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_758_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 5,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        slotsJP: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 5,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6696,
                hpplus: 999,
                atk: 3105,
                atkplus: 475,
                def: 2780,
                defplus: 427
            },
        skillset: 
            {
                skill: "12s CT - 2000% Light DMG (PHY). For 8s, increase own DMG by 10%.",
                skillbreak: 900,
                arts: "20000% Light DMG (PHY). For 20s, increase own ATK by 50% and reduce Enemies' evasion by 30%.",
                artsbreak: 1200,
                truearts: "70000% Light DMG (PHY). For 15s, reduce Enemies' Light RES by 40% and increase own Critical DMG by [60% × # of times this True Arts has been activated] (up to 300%).",
                trueartsbreak: 1600
            },
        skillsetJP: 
            {
                skill: "12s CT - 2000% Light DMG (PHY). For 8s, increase own DMG by 10%.",
                skillbreak: 900,
                arts: "16000% Light DMG (PHY). For 15s, increase own ATK by 50% and reduce Enemies' evasion by 30%.",
                artsbreak: 1200,
                truearts: "65000% Light DMG (PHY). For 15s, reduce Enemies' Light RES by 40% and increase own Critical DMG by [50% × # of times this True Arts has been activated] (up to 250%).",
                trueartsbreak: 1600
            },
        passive: 
            {
                ability1: "When own HP is above 90%, increase own Arts by 4/s. Additionally, increase own Critical Rate by 100% if unafflicted by Status Ailments.",
                ability2: "Increase Light RES by 10%.",
                ability3: "Increase Paralysis RES by 50%.",
                ability4: `Unit EXP UP 20% UP. (When Erza's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Erza's SP. Art is owned)`
            },
        passiveJP: 
            {
                ability1: "When own HP is above 90%, increase own Arts by 4/s. Additionally, increase own Critical Rate by 100% if unafflicted by Status Ailments.",
                ability2: "Increase Light RES by 10%.",
                ability3: "Increase Paralysis RES by 50%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 10,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 1,
                    hover3: "Fosradius",
                }
            },
            dreamGL: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 30,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                        mat3amt: 1,
                        hover3: "Degilmagna",
                    },
                    passive: {
                        ability1: `Increase own HP Recovery amount (except Lifesteal) by 20%.`,
                        ability2: `Increase all allies' PHY DMG by 30%. Does not stack with Abilities of the same name`
                    }
                },
                light: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                        mat2amt: 30,
                        hover2: `Eternaglint Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                        mat3amt: 1,
                        hover3: "Fosradius",
                    },
                    passive: {
                        ability1: `Increase own Blind RES by 100%.`,
                        ability2: `Increase own Arts by 3/s.`
                    }
                }
            },
        lore: 
            {   
                evo5: "The strongest woman in Fairy Tail. Her status as an S-Class wizard lets her accept S-Class requests. Her stern, strong-minded personality keeps the other men of the guild at arms' reach, and even Natsu and Gray listen to her commands.",
                evoawk: "The strongest woman in Fairy Tail. Her status as an S-Class wizard lets her accept S-Class requests. Her nickname means \"queen of the fairies\", and her magic ability \"The Knight\" lets her \"reequip\" arms, instantly changing in the middle of battle."
            },
        review:
            {
                skill: `Being a small boost to begin with, 10% is a mere droplet in such a large pool of DMG Up buffs provided by Erza's True Arts.`,
                arts: `While a 50% ATK boost is pretty decent, you'll mostly just be using Erza's True Arts for the more favorable boosts in damage output and also how you'd want to use her True Arts consecutively to ramp the damage up. However, coupled with accuracy buffs, the 30% evasion debuff can come in handy against highly evasive bosses.`,
                truearts: `Huge multiplier, potent resistance debuff, and increasingly large DMG up buffs. With passive critical rate as the cherry on top, it will hit harder than a truck searching for isekai victims. Furthermore, it will get more powerful after each use (effects max out at 5 uses, of course). Even without ramping, her True Arts can already unleash a huge amount of damage and provide great damage support for light damage dealers with herself included.`,
                ability1: `A key component of her kit, this passive is something you'll want to maintain in order for Erza perform her utmost best as a DPS unit. The automatic Arts gauge generation will help greatly in stacking up her DMG buffs, and the innate critical rate boost will ensure maximal damage against enemies with high defense. Against foes with critical resistance, you can bring an additional critical rate boost to land critical hits, assuming that the enemies don't have a resistance value substantially higher than 100% (in which case, you'll bring DEF ignore).`,
                ability2: `The value is pretty negligible, and it's especially so since Erza's not redirecting damage or anything.`,
                ability3: `Being a mere 50%, Erza might occasionally dodge paralysis, but this would mostly reduce the paralysis duration by a bit. Not really special/important to Erza's kit, and you could always just bring a defense item to deal with various kinds of ailments.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Erza can already unleash a great amount of damage on the initial True Arts, and she can increase her damage output until she caps out at a total of five True Arts uses. As such, she can serve as an excellent DPS unit for most content, whether the stage is short or long. The conditions on her passive seem kind of strict, but it ultimately isn't an issue for lengthier content (where it will matter most for her kit) since you're always expected to bring a healer for those kinds of stages anyway. And unlike most ramping DPS units, she doesn't need too much to get the ball rolling, and there aren't really any disadvantages that would deter her usage in general content as she would already be able to deal more than satisfactory damage from the get-go without hassles like stacking Normal Arts or severe health drain.
                For nuking, she can provide pretty good damage support, boasting a potent Light Resistance debuff and two 5-star physical slots that could fit more debuffs or buffs to further enhance the team's damage output while also dishing out a fairly large amount of damage herself. As a side note, her innate critical rate and ability to hold fast, hard-hitting weapons like True Grand Destin may be of use for clearing some mob stages on auto (or just in general).`,
                lastupdated: `April 6, 2023`,
                author: `Praying Patrick`
            }
    },
    { // Nui Harime - Complete
        id: 107596512,
        name: "Nui Harime",
        rank: "A",
        luckrank: "S",
        attribute: "Dark",
        type: "Demon",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107595512.png",
                detailawk: "/db/Units/Detail/unit_detail_107596512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107595512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107596512.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 5,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6810,
                hpplus: 999,
                atk: 3038,
                atkplus: 465,
                def: 2729,
                defplus: 419
            },
        skillset: 
            {
                skill: "10s CT - 1800% Dark DMG (MAG). Increase own Arts by 30.",
                skillbreak: 500,
                arts: "23000% Dark DMG (MAG). Every time this Arts is activated, increase Dark Allies' DMG by 50% (up to 200%). When this unit takes damage during this Arts, Enemies take 3000% Elementless DMG (MAG).",
                artsbreak: 1000,
                truearts: "82000% Dark DMG (MAG). For 20s, Allies ignore 100% of Enemies' DEF. Increase the Arts of the Ally with the least Arts (except self) by 200.",
                trueartsbreak: 1300
            },
        passive: 
            {
                ability1: "Increase all allies' Equipment CT of Defense equipment by 10%.",
                ability2: "Increase DMG to Human enemies by 60%.",
                ability3: "Increase Curse RES and Evasion by 70%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat3amt: 1,
                    hover3: "Oldeus"
                }
            },
        dream: {
                fire: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_130853.png`,
                        mat2amt: 50,
                        hover2: `Flametail Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragFlogassa.png",
                        mat3amt: 2,
                        hover3: "Flogassa",
                    },
                    passive: {
                        ability1: `Increase own DMG against Human enemies by 130%.`,
                        ability2: `Increase own BE Output by 10%.`
                    }
                },
                dark: {
                    evolution: {
                        mat1: `/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png`,
                        mat1amt: 10,
                        mat2: `/db/Icons/dungeon_drops/material_thumbnail_131455.png`,
                        mat2amt: 50,
                        hover2: `Sinflow Soulgem`,
                        mat3: "/db/Icons/dungeon_drops/fragOldeus.png",
                        mat3amt: 2,
                        hover3: "Oldeus",
                    },
                    passive: {
                        ability1: `Increase own Arts by 200 at Start of Quest. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own Poison RES by 100%..`
                    }
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Purple Scissor Blade",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_151771.png",
                skill: "45s CT - 8000% Dark DMG (MAG). For 10s, increase own Dark DMG by 50%, and DMG to Giant Bosses by 50%.",
                skillbreak: 800,
                passive: 
                    {
                        ability1: "When equipped by Nui, increase own Skill CT REC and Stats by 20%. Does not stack with Abilities of the same name.",
                        ability2: "Increase Dark RES by 5%."
                    }
            },
        lore: 
            {   
                evo5: "A girl born from an artificial womb made out of the Primordial Life Fiber. After Satsuki Kiryuin fell into her mother Ragyo's grasp, she wove the enormous Shinra-Koketsu garment. Her commitment to Ragyo was so great as to willingly become one with the Kiryuin matron later.",
                evoawk: "Grand Couturier of the REVOCS Corporation. Beneath her cute appearance lies a haughty, sadistic personality. After killing Ryuko's father, Isshin Matoi, she took one of the two Scissor Blades. Among her superhuman techniques is the ability to remove the Banshi from Goku Uniforms, destroying them instantly."
            }
    },
    { // Takemichi - Complete, Review done
        id: 107616111,
        name: "Takemichi",
        rank: "F",
        luckrank: "C",
        attribute: "Fire",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107615111.png",
                detailawk: "/db/Units/Detail/unit_detail_107616111.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107615111.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107616111.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6415,
                hpplus: 999,
                atk: 2889,
                atkplus: 443,
                def: 2608,
                defplus: 401
            },
        skillset: 
            {
                skill: "12s CT - 1600% Fire DMG (PHY). Increase Paralysis and Freeze RES by 100% during Skill animation.",
                skillbreak: 400,
                arts: "17000% Fire DMG (PHY). When this Arts is activated, permanently increase own DMG by 40% (up to 400%).",
                artsbreak: 1000,
                truearts: "Increase Allies' Arts by 30. For 30s, increase Human Allies' ATK by 100%.",
                trueartsbreak: 0
            },
        passive: 
            {
                ability1: "Increase own Arts by own LUCK at start of quest.",
                ability2: "Increase own Arts by 10/s when near-death.",
                ability3: "Increase Rank EXP gained by 30%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragTakemichi.png",
                    mat3amt: 20,
                    hover3: "Takemichi"
                }
            },
        lore: 
            {   
                evo5: "After being shoved in front of an oncoming train, Takemichi Hanegaki suddenly found himself back in his middle school days. Determination to change his fate as a weak, witless delinquent led him to meet Mikey and Draken.",
                evoawk: "To save his former girlfriend, Hinata Tachibana, from the violent Tokyo Manji Gang in the present, he decided to guide their formative days in the past. That unbreakable tenacity altered the paths of both himself and his fellow Toman members."
            },
        review:
            {
                skill: `While the Paralyze and Freeze RES is nice to have, the fact it only applies during his skill animation instead of as a passive effect limits its use.`,
                arts: `Having 400% potential DMG up sounds nice, sadly his low multiplier heavily limits his damage not to even mention taking 10 NAs to reach max stacks.`,
                truearts: `Who doesn't love conditional ATK up and a weak art refund? In all seriousness this is honestly one of the better parts of his kit and what you'll use most of the time, when paired with his passive this can make for a decent last stand to help the team get one last TA off.`,
                ability1: `When he's at max luck increasing his arts by 120 at the start of a fight is a nice little boost, helping him get a TA off and supporting the team faster.`,
                ability2: `Ahhh yes, near death artgen. This will likely only get used as a final stand in SPEX level stages, to give the team one last little boost before getting killed.`,
                ability3: `The legendary Genos Passive, this makes him a good subsitute for genos when XP farming. Now for the genos vs takemichi debate on who's better`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Takemichi is nothing special, his Normal Art is a noob trap for those who think 400% dmg up will make up for the low multiplier. Having 10aps when near death can make for a nice final stand, more often than not you'll just get killed before you can get enough art to TA. Outside of being used for his bonus rank XP or free revives on Tokyo revenger stages he's unlikely to see use.`,
                lastupdated: `August 5, 2022`,
                author: `Zanster`
            }
    },
    { // Mikey - Complete, Review done
        id: 107626512,
        name: "Mikey",
        rank: "A+",
        attribute: "Dark",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107625512.png",
                detailawk: "/db/Units/Detail/unit_detail_107626512.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107625512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107626512.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 5,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6885,
                hpplus: 999,
                atk: 3070,
                atkplus: 470,
                def: 2650,
                defplus: 407
            },
        skillset: 
            {
                skill: "10s CT - 1800% Dark DMG (PHY). Increase own Arts by 30. 30% chance of inflicting Faint against non-boss Enemies for 5s.",
                skillbreak: 500,
                arts: "21000% Dark DMG (PHY). For 15s, increase Human Allies' DMG by 70%.",
                artsbreak: 1000,
                truearts: "70000% Dark DMG (PHY). For 15s, increase Allies' Critical Rate by 100%.",
                trueartsbreak: 1800
            },
        passive: 
            {
                ability1: "Increase own Critical DMG with respect to how high own % of HP is (up to 200%).",
                ability2: "Increase DMG to Human Enemies by 30%.",
                ability3: "Increase Arts by 3/s."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragOldeus.png",
                    mat3amt: 1,
                    hover3: "Oldeus"
                }
            },
        lore: 
            {   
                evo5: "The Leader of the Tokyo Manji Gang, real name Manjiro Sano. Known as the Invincible Mikey, he rules over other hoodlums of the city with his incredible strength and charisma. When it comes to protecting his members, no foe is too tough.",
                evoawk: "Though his strength is uncontested, he lost the ability to control his emotions with the death of his older brother, Shinichiro. Only with Takemichi and Draken's support can he keep the darkness in his heart at bay."
            },
        review:
            {
                skill: `Impressive ArtGen on skill is always a treat and can be extremely helpful for quests with many waves. The faint chance is also generally negligible but nice for those pesky waves leading up to the boss.`,
                arts: `That is a rather large DMG buff for an Arts, despite being restricted to Human allies. Not a bad Arts to have, especially on a team consisting of Humans, although Mikey’s True Arts is the real show-stealer.`,
                truearts: `A hefty DMG multiplier, alongside a teamwide CRI rate buff. You will generally be defaulting to using his True Arts for most quests due to the immense DMG it is able to output alongside his unique passive and CRI rate buff for the entire team. As a side note for nukers, his True Arts animation is also fast, taking a little over a second for its first hit to land.`,
                ability1: `What an insane passive to have. This essentially gives Mikey a whopping 200% CRI DMG buff when he is at full HP, which is not hard to maintain with a capable healer on the team. This single-handedly makes Mikey an amazing Nuker, while also being a more than competent DPS.`,
                ability2: `A noticeable DMG buff against Humans, although most end-game bosses are Giant Bosses, so this isn’t as impressive in practice.`,
                ability3: `Nothing to complain about free passive ArtsGen. Definitely nice to have, especially when using Mikey in longer fights.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Chills. Mikey was definitely built for Dark nukes. Not only is he Dark and Human, which allows him to utilize the full buffs provided by Forte (Dark Nuke Support unit), but his unique passive grants him a whole 200% CRI DMG buff, alongside his high 70000% DMG Multiplier from his True Arts... As the cherry on top, he is also one of the few units with three 5☆ slots. With that said, he can even perform as a competent DPS for sustains, granting a teamwide CRI buff while outputting serious damage. He is definitely one of the prizes of the Part 1 banner, upgrading your nukes and filling up the missing DPS hole in your team.`,
                lastupdated: `August 5, 2022`,
                author: `Jeffers`
            }
    },
    { // Draken - Complete, Review done
        id: 107636412,
        name: "Draken",
        rank: "S",
        attribute: "Light",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107635412.png",
                detailawk: "/db/Units/Detail/unit_detail_107636412.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107635412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107636412.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 5,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 6852,
                hpplus: 999,
                atk: 3029,
                atkplus: 464,
                def: 2701,
                defplus: 415
            },
        skillset: 
            {
                skill: "12s CT - 1700% Light DMG (PHY). Increase own Arts by 30. For 7s, reduce Enemies' DEF by 10%.",
                skillbreak: 500,
                arts: "22000% Light DMG (PHY). For 20s, reduce Enemies' DMG RES by 30%.",
                artsbreak: 1000,
                truearts: "50000% Light DMG (PHY). For 40s, reduce Enemies' PHY RES by 30% (stackable). For 15s, increase Allies' Arts by 2/s.",
                trueartsbreak: 1500
            },
        passive: 
            {
                ability1: "Increase Allies' PHY DMG based on how high their HP is (up to 100%). Does not stack with Abilities of the same name.",
                ability2: "At the start of a Quest, increase Allies' Arts by 30. Does not stack with Abilities of the same name.",
                ability3: "Increase Paralysis RES by 100%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFosradius.png",
                    mat3amt: 1,
                    hover3: "Fosradius"
                }
            },
        lore: 
            {   
                evo5: "The Vice Leader of Tokyo Manji Gang, real name Ken Ryuguji. Second only to Mikey in sheer strength, he is able to turn the tide of a brawl all by himself. He also has keen judgement and empathy, reflected in his personal policy to not cause trouble for outsiders.",
                evoawk: "He was supposed to be killed by Kiyomasa during the battle of 8/3, but Takemichi saved him in the nick of time. For that and his invaluable ability to give Mikey emotional support, he places great trust in the seemingly weak boy."
            },
        review:
            {
                skill: `Impressive ArtGen on skill is always a treat and can be extremely helpful for quests with many waves. Meanwhile, the DEF debuff is negligible at best`,
                arts: `That is a rather long-lasting general DMG RES debuff. Furthermore, it fits well with his role as a damage-support unit, helping increase overall damage output by a noticeable degree. Despite this, you will find yourself using his True Arts instead most of the time.`,
                truearts: `A long-lasting Physical RES debuff that is also stackable. This allows Draken to shine in Physical-oriented teams specifically, while also dealing a decent amount of damage himself. His ArtGen, however, is not as impressive, and is thus not a good idea to rely on him for ArtGen alone.`,
                ability1: `An amazing passive which allows him to perform his role as a damage-support unit even better. Slotting him into Physical-oriented teams, he is able to buff the team’s damage forever based on his HP. This is especially useful for nukes in particular as the nuke team will be able to take full advantage of his PHY buff.`,
                ability2: `A very rare passive to have, with only a very select few units in the game being able to increase allies’ Arts gauge at the start of the quest. However, do note that this passive does NOT stack with Forte’s and NYMilim’s passive.`,
                ability3: `Rather self-explanatory, allowing Draken to be immune to Paralysis. Generally, this is rather negligible unless the quest has Paralysis attacks.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `A busted damage-support unit, especially so for Physical nukes. Draken is able to leverage his Physical DMG buffs and debuffs to heavily buff any Physical nukes with hard-hitting units - ahem Mikey ahem. Additionally, he is also one of a few units in the game with 3 5☆ slots, made even rarer when you consider his 3 5☆ slots are PHY/PHY/MAG. To top it off, he is the only unit in the game with such an absurd 100% teamwide PHY DMG passive. For veterans capable of nuking, Draken is an amazing unit to pair alongside your hard-hitting PHY units, and is definitely worth at least a pull.`,
                lastupdated: `August 17, 2022`,
                author: `Jeffers`
            }
    },
    { // Chifuyu - Complete, Review done
        id: 107646312,
        name: "Chifuyu",
        rank: "C",
        attribute: "Earth",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107645312.png",
                detailawk: "/db/Units/Detail/unit_detail_107646312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107645312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107646312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7077,
                hpplus: 999,
                atk: 2800,
                atkplus: 430,
                def: 2801,
                defplus: 430
            },
        skillset: 
            {
                skill: "8s CT - 2000% Earth DMG (PHY). For 6s, increase own BE Output by 30%.",
                skillbreak: 400,
                arts: "17000% Earth DMG (PHY). Revives Fallen Allies with 100% HP. After this Arts revives an Ally, this Arts will be unable to revive any Allies for 50s. For 10s, increase Allies' stats by 30%.",
                artsbreak: 800,
                truearts: "35000% Earth DMG (PHY). For 20s, Allies gain a DMG Barrier that negates 6000 DMG. Additionally, for 20s, increase Allies' Crit RES by 30% and DMG RES by 20%.",
                trueartsbreak: 1200
            },
        passive: 
            {
                ability1: "Increase Allies' Support Equip CT REC by 10%. Does not stack with Abilities of the same name.",
                ability2: "Increase Freeze RES by 100%.",
                ability3: "Increase DEF by 20% when under a DMG Barrier."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat3amt: 1,
                    hover3: "Tonetaurus"
                }
            },
        lore: 
            {   
                evo5: "The First Division Vice Captain of the Tokyo Manji Gang. Although he was beaten up by Keisuke Baji during his initiation ceremony to join a rival gang, he believed in the former captain until his death. That show of unshakable trust inspired Takemichi.",
                evoawk: "After Bloody Halloween, Takemichi was appointed First Division Captain to replace the dead Keisuke, so he agreed to support him as partner. The decision to watch out for Takemichi only intensified when he became one of the few people to know about his time travel ability."
            },
        review:
            {
                skill: `BE Output is always a negligible buff.`,
                arts: `Useless revive effect because that would imply that Chifuyu was unable to mitigate enough damage. however, the Stats Up is nice for a Heal every so often.`,
                truearts: `Large Barrier and sizable DMG Res Buff, for most Stages you can get by with just this True Arts, however you will have to consider other competition such as Favelle or Sanstone which do not stack with Chifuyu, and have defense slots themselvers.`,
                ability1: `Great Passive for effectively increasing Arts Gen on the team, however his kit doesn’t really benefit from it.`,
                ability2: `Freeze resistance is nice, helps him keep his Defensive Buffs up during a Freeze.`,
                ability3: `Rather self-explanatory, Increases defense when under a barrier. Though selfish, it is appreciated.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Wants to be Favelle, but fails to do so due to terrible DPS slotting with no defense slot. Honestly one of the worse collaboration units so far, so I don’t know what else to say but it's disappointing.`,
                lastupdated: `August 17, 2022`,
                author: `Gian`
            }
    },
    { // Mitsuya - Complete, Review done
        id: 107656212,
        name: "Mitsuya",
        rank: "A",
        attribute: "Water",
        type: "Human",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107655212.png",
                detailawk: "/db/Units/Detail/unit_detail_107656212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107655212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107656212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7080,
                hpplus: 999,
                atk: 2943,
                atkplus: 451,
                def: 2670,
                defplus: 410
            },
        skillset: 
            {
                skill: "13s CT - 1500% Water DMG (PHY). For 6s, reduce Enemies' evasion by 15%.",
                skillbreak: 600,
                arts: "18000% Water DMG (PHY). For 20 seconds, increase Allies' Physical Equipment CT REC by 40%.",
                artsbreak: 1100,
                truearts: "55000% Water DMG (PHY). For 20s, increase Allies' Arts by 4/s and ATK by 120%.",
                trueartsbreak: 1700
            },
        passive: 
            {
                ability1: "When near-death, increase own Arts by 200 and heal own HP by 50%. Ability can only be activated once per Quest.",
                ability2: "Increase Arts by 5/s when own HP is above 90%.",
                ability3: "Increase Paralysis RES by 40%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragFreydal.png",
                    mat3amt: 1,
                    hover3: "Freydal"
                }
            },
        lore: 
            {   
                evo5: "The Second Division Captain of the Tokyo Manji Gang. He is always there to help out a fellow member in need. To show his thanks to Takemichi for resolving Mikey and Draken's dispute, he presented him with a custom-made outfit.",
                evoawk: "While acting as a Toman captain, he also leads his school's crafting club. He impressed the other members with his top notch sewing skills, and the founders' uniforms were all handmade by him."
            },
        review:
            {
                skill: `Nothing too exciting for this skill, the evasion debuff can be of use on bosses that have high evasion coupled with accuracy buffs.`,
                arts: `The EQ CT can be useful in certain circumstances, but it is limited to physical equipment, which makes it far more difficult to abuse.`,
                truearts: `The core of Mitsuya’s kit, a decent multiplier coupled with good artgen and a sizable unconditional teamwide attack buff. This will be his main use 99% of the time.`,
                ability1: `Reminiscent of Cestina’s Majesty, Mitsuya’s art gauge will instantly shoot to 200 if near-death, and he will heal half his hp. This ensures that if the boss has a strong opening nuke, Mitsuya can TA instantly to help team arts. It is also possible to trigger it instantly with certain equips.`,
                ability2: `An impressive conditional artgen passive. Unfortunately, the condition is rather strict, as 90% hp is more difficult to maintain than 80% or 70%, but with good healing or a taunt tank it should be achievable.`,
                ability3: `Unimpressive passive, as the resistance is too low to be relevant.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Mitsuya is a competent artgen with a teamwide attack buff, and acceptable DPS. His passive can enable some tricks enabling him to reach 200 arts quickly to get artgen started quickly. He will be a great starter unit for people who start with the collab. However, veterans with diversified boxes will find him somewhat less useful, as he fails to bring anything unique.`,
                lastupdated: `August 5, 2022`,
                author: `MOB of Metal`
            }
    },
    { // Empress - Complete, Review done
        id: 107666212,
        name: "Empress",
        rank: "A-",
        attribute: "Water",
        type: "Machine",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107665212.png",
                detailawk: "/db/Units/Detail/unit_detail_107666212.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107665212.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107666212.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6606,
                hpplus: 999,
                atk: 2980,
                atkplus: 457,
                def: 2705,
                defplus: 415
            },
        skillset: 
            {
                skill: "12s CT - 1600% Water DMG (PHY). For 8s, increase own ATK by 20%.",
                skillbreak: 300,
                arts: "20000% Water DMG (PHY). For 60s, reduce Enemies' PHY and Water RES by 10% (Stackable).",
                artsbreak: 1200,
                truearts: "78000% Water DMG (PHY). For 15s, reduce Enemies' Critical RES by 100%. For 7s, increase Allies' Arts by [2 × # of Water Allies in the party]/s.",
                trueartsbreak: 1900
            },
        passive: 
            {
                ability1: "Increase own Arts by 3/s when HP is above 80%. When unafflicted by status ailments, increase own Arts by 3/s.",
                ability2: "Increase Machine Allies' stats by 10%.",
                ability3: "Increase DMG to Fire Enemies by 15%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragAerugraxes.png",
                    mat3amt: 1,
                    hover3: "Aerugraxes"
                }
            },
        lore: 
            {   
                evo5: "One of the surviving Hemiteos Units, nano-enhanced soldiers tasked to protect humanity from the rogue AI Artemis. Despite her amnesia and deadpan expression, she still has a powerful instinct to help others.",
                evoawk: "Although she lost her memories, she dreamt of a place called Light House No. 8 during her long slumber. She fought her way there and reactivated the Hemiteos proteins in her body, restoring her former power."
            },
        review:
            {
                skill: `A minor ATK buff with a rather short duration. Not a bad skill, but mostly irrelevant.`,
                arts: `A stacking debuff with a very long duration, which sounds nice on paper, but it’s on her NA. This means that, unless you need to reduce the PHYs and Water RES of an enemy, you would either leave it at 2-3 stacks or just ignore it and go for TA.`,
                truearts: `A very big multiplier that also enables Crits for the team if no Crit RES is in effect, which you’ll be spamming most of the time. It additionally provides between 56 and 224 Arts total in a short amount of time, but she has the Lucy problem of being dependent on how many water units are in your team. Thus making her more practical in ruins than in general content, since you’d want at least 3 water units on your team to make it worth your time or ideally use her in Mono-water. You shouldn't count on her as your main source of Artsgen with her slots anyway. She can function/work as a general DPS or in Mono-water nuke comps thanks to that, but here’s where it gets weird. Most of those nuke comps are also specifically built around magic DMG with JSelia having the same debuff while providing a hefty Water RES debuff on top of it, basically removing her from the competition.`,
                ability1: `A great boost to her Self-sufficiency in terms of passive ArtGen with conditions that should be relatively easy to fulfill.`,
                ability2: `A minor team wide stats boost to an underrepresented race.`,
                ability3: `Another small and nice DMG buff though mostly irrelevant.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Empress is a heavy hitter with a mish-mash of different kits stitched together for more practicality, which ultimately ends up either not being very impactful, outclassed, or immensely team dependent. An example for that is her Artsgen, requiring a Mono-water team to even compete with other units like Melty or Sakura Miku. Her slots won't provide a lot in that direction as well, leaning more on the Sub or DPS style slots with only one 4* Support, though being good otherwise. She'll be able to provide a lot of Self-sufficiency for most roles with her passives though, if they are able to be kept active. She might see some usage in nukes and ruins, but she won’t change the current Mono-water comps by herself with her DMG type being incompatible with most. She's likely to end up as a DPS with some utility and to be replaced by some other unit sooner rather than later or not even seeing use at all with the last Crossover being Mikey and Draken outclassing her in terms of both DMG and providing buffs to boost the overall DMG. Nevertheless she’ll be a nice unit for people who are starting out or those not having a good DPS. For Veterans, she'll more than likely collect some dust or won't be as useful as her kit isn't outstanding.`,
                lastupdated: `September 29, 2022`,
                author: `Hakushi`
            }
    },
    { // Dead Master - Complete, Review done
        id: 107676312,
        name: "Dead Master",
        rank: "B", 
        attribute: "Earth",
        type: "Machine",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107675312.png",
                detailawk: "/db/Units/Detail/unit_detail_107676312.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107675312.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107676312.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6748,
                hpplus: 999,
                atk: 3119,
                atkplus: 477,
                def: 2730,
                defplus: 419
            },
        skillset: 
            {
                skill: "11s CT - 1900% Earth DMG (PHY). Increase own Arts by 30.",
                skillbreak: 400,
                arts: "25000% Earth DMG (PHY). For 15s, reduce Enemies' Earth RES by 35%.",
                artsbreak: 800,
                truearts: "115000% Earth DMG (PHY). Reduce Enemies' evasion by 50% and increases own accuracy by 50% during this True Arts.",
                trueartsbreak: 1800
            },
        passive: 
            {
                ability1: "Ignore 100% of Enemies' DEF when HP is above 80%.",
                ability2: "Increases Paralysis RES by 80%",
                ability3: "Increase own ATK by 30% when HP is above 90%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragTonetaurus.png",
                    mat3amt: 1,
                    hover3: "Tonetaurus"
                }
            },
        lore: 
            {   
                evo5: "One of the surviving Hemiteos Units. Prolonged fighting has left her with an instable mind. She is greatly attached to Empress and wants to keep her all to herself. Her specialty is sniping from super long range.",
                evoawk: "Her overwhelming desire for Empress led them to fight, but she later agreed to join her cause to protect humanity. Besides her talent as a sniper, she has keen scouting sense and decision-making."
            },
        review:
            {
                skill: `Arts Gen on Skill is always appreciated, with a decent cooldown as well.`,
                arts: `Nice debuff with decent uptime. Although you will generally use her True Arts more often than not, her Arts can still be chained together with her True Arts, given enough Arts gauge.`,
                truearts: `Bonkers. A whopping 115k% True Arts Multiplier alone is nothing to scoff at. Furthermore, the buffs and debuffs from this True Arts essentially buffs her True Arts with an increased 100% Accuracy rate. Dead Master also shares an uncanny similarity with Simon, both having a 100% Accuracy boost, ignoring enemies’ DEF (Dead Master’s passive below), and a huge Multiplier. However, one downside would be its long animation, taking around 5s before it starts to deal damage.`,
                ability1: `Great passive to have, especially when the team is not using Crit. However, as it is tied to a HP condition, a Healer/Shielder might be required to keep the passive active for longer fights`,
                ability2: `Nice ATK buff, although it is tied to an even higher HP condition.`,
                ability3: `Not much to say besides she gets Paralysed less often and for a shorter duration when it matters.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Is that you, Earth Simon? In all seriousness, Dead Master is a great Earth DPS unit for both nuking and sustain fights. Although she shares many similarities with Simon, she is not restricted like Simon is with only 3 Phantom Bullets per Quest. However, Earth nukes are not the best currently, so don’t expect her to dish out “Simon nukes” levels of damage. Still, with proper support, Dead Master can pack one hell of a serious punch (shot) and decimate most quests with mommy energy.`,
                lastupdated: `September 29, 2022`,
                author: `Jeffers`
            }
    },
    { // Strength - Missing Lore (GL), Review done
        id: 107686112,
        name: "Strength",
        attribute: "Fire",
        rank: "A+",
        type: "Machine",
        image: 
            {
                detail5: "/db/Units/Detail/unit_detail_107685112.png",
                detailawk: "/db/Units/Detail/unit_detail_107686112.png",
                thumb5: "/db/Units/Thumbnail/unit_thumbnail_107685112.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107686112.png"
            },
        slots: 
            {
                slot1: 5,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot3type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7100,
                hpplus: 999,
                atk: 2950,
                atkplus: 452,
                def: 2450,
                defplus: 377
            },
        skillset: 
            {
                skill: "13s CT - 1900% Fire DMG (PHY). For 10s, reduce Enemies' Break RES by 30%.",
                skillbreak: 1000,
                arts: "18000% Fire DMG (PHY). Permanently increase Allies' Break PWR by 20% (up to 100%).",
                artsbreak: 4000,
                truearts: "65000% Fire DMG (PHY). For 20s, increase Allies' Arts DMG and DMG to Enemies in BREAK by 130%.",
                trueartsbreak: 8000
            },
        passive: 
            {
                ability1: "Increase DMG to Enemies in BREAK by 200%.",
                ability2: "When own HP is over 90%, increase own Arts by 3/s.",
                ability3: "When own HP is over 90%, increase own Skill CT REC by 30%."
            }, 
        evolution: 
            {
                evo5awk:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_paradisos.png",
                    mat2amt: 1,
                    mat3: "/db/Icons/dungeon_drops/fragDegilmagna.png",
                    mat3amt: 1,
                    hover3: "Degilmagna"
                }
            },
        lore: 
            {   
                evo5: "Missing Lore.",
                evoawk: "Missing Lore."
            },
        review:
            {
                skill: `A skill that complements Strength’s role as a breaker and break nuker, this is essential for softening bosses that have high break resistance. The fact that it is on skill is perfect, as it allows using it then high-break equips.`,
                arts: `Ideal for sustain quests that require breaking the boss multiple times, as it can ramp up the whole party’s break power.`,
                truearts: `Arguably the best break nuking-oriented arts in the whole game, a sizably high multiplier with two huge 130% buffs, an unconditional 130% arts dmg buff and another 130% dmg buff if the enemy is in break, coupled with a very high break value as well.`,
                ability1: `More buffs to enemies in break, this one only impacts Strength’s damage output but it can make her damage output completely obscene under the right circumstances.`,
                ability2: `Nice passive that gives Strength some self-sustainability. The HP requirement is somewhat strict though, but she can be kept at high HP with proper healing and tanking. In nuke scenarios the passive will not be necessary in any case.`,
                ability3: `Essentially brings Strength’s skill CT to 10s (which is equal to its duration) when her hp is high, which results in her skill being always active provided that she is not in an animation when it resets.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Strength is one of the best break-oriented units to come out in a long while, both for nukes and for sustain. Break res down on skill, permanent teamwide break buff on NA, and two very large damage buffs on TA, one which does not require the enemy to be broken, coupled with high break values. Obviously, she has a very niche role, but she excels in that role, and her role will become somewhat more prominent with some upcoming content reliant on Break.`,
                lastupdated: `September 29, 2022`,
                author: `MOB of Metal`
            }
    },
    { // Albedo - Complete, Review done
        id: 107707512,
        name: "Albedo",
        attribute: "Dark",
        type: "Demon",
        rank: "A+",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107706512.png",
                detailsuper: "/db/Units/Detail/unit_detail_107707512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107706512.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107707512.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/physLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7095,
                hpplus: 1340,
                atk: 3045,
                atkplus: 630,
                def: 3625,
                defplus: 630
            },
        skillset: 
            {
                skill: `12s CT - 2000% Dark DMG (PHY). Increase own Arts by 30. If HP is above 70%, increase Arts by 60 instead.`,
                skillbreak: 400,
                arts: `23000% Dark DMG (PHY). Permanently increase Demon Allies' DMG & DMG RES by 10% (up to 30%).`,
                artsbreak: 600,
                truearts: `50000% Dark DMG (PHY). For 30s, increase Allies' PHY Res by 20% + Allies gain a DMG Barrier that negates 10000 DMG.`,
                trueartsbreak: 1000,
                superarts: `120000% Dark DMG (PHY). For 12s, increase own Critical Rate by 100% and Allies' DMG Res by 60%.`,
                superartsbreak: 2000
            },
        passive: 
            {
                ability1: `When near-death, increase own Arts by 300 & heal Allies' HP by 50%.`,
                ability2: `Increase Paralysis RES by 100%.`,
                ability3: `Increase Freeze RES by 100%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131455.png",
                    mat2amt: 100,
                    hover2: "Sinflow Soulgem"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "3F",
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_153171.png",
                skill: "55s CT - 8000% Dark DMG (PHY). For 10s, increase own CRI DMG by 50%. If user is Dark, increase own CRI DMG by 100% instead.",
                skillbreak: 800,
                passive: 
                    {
                        ability1: "When equipped by Albedo, increase own Arts by 100 at Start of Quest. Does not stack with Abilities of the same name.",
                        ability2: "Increase own ATK by 5%."
                    }
            },
        lore: 
            {   
                evoawk: "A succubus who supervises all who protect the Great Tomb of Nazarick. Her golden eyes, ebony hair, and graceful wings give her an unworldly beauty. Ainz altered her character story on a lark so she would be obsessed with him, not expecting it to carry over when the game world became real.",
                evosuper: "A demon also known as the Shield among the guardians of Nazarick. Her custom armor Hermes Trismegistus and her ability to nullify Super-tier magic three times lets her serve this role."
            },
        review:
            {
                skill: `Not much to say, Art on skill go brrrrrr.`,
                arts: `While this does look great, sadly mono demon teams currently don't exist on global.`,
                truearts: `The high uptime and sizeable barrier makes Albedo a decent option to bring for bosses who deal physical damage. Sadly only providing 20% dmg res puts her below competition such as Sanstone, however because it's physical dmg res, it can stack with units like Sanstone and Emperor.`,
                superarts: `While the high multiplier is tempting, she lacks any innate dmg up to help actually damage. Her Super Art would mainly be used for when the boss does a large amount of damage in a short period of time.`,
                ability1: `While you shouldn't be getting near death with Albedo on your team, this is a nice fallback that likely won't save you from any major nukes.`,
                ability2: `A nice increase to her viability by preventing her from getting paralyzed.`,
                ability3: `A nice increase to her viability by preven....wait a minute didn't I just say this?`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Overall Albedo is a strong defensive unit who is only screwed over due to its reliance on running mono demon, being able to provide them with 30% dmg res by ramping her Normal Art and an additional 20% physical res on TA along with a rather sizeable barrier. However, mono demon teams are not very viable in global right now, as time progress and we get more units such as Demon king Vox and the Dream Evolution for Zenon, then a mono demon team will become a viable option. But it's important to note, Albedo and mono demon comps heavily relies on their Bone Daddy, Ainz, to help carry the team.`,
                lastupdated: `May 26, 2023`,
                author: `Zanster`
            }
    },
    { // Shalltear - Review done
        id: 107717412,
        name: "Shalltear",
        attribute: "Light",
        type: "Demon",
        rank: "A+",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107716412.png",
                detailsuper: "/db/Units/Detail/unit_detail_107717412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107716412.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107717412.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7590,
                hpplus: 1430,
                atk: 3975,
                atkplus: 686,
                def: 2700,
                defplus: 564
            },
        skillset: 
            {
                skill: `11s CT - 2500% Light DMG (MAG). For 10s, increase own Lifesteal by 3%.`,
                skillbreak: 600,
                arts: `26000% Light DMG (MAG). For 20s, reduce Enemies' Dark & Light RES by 40%.`,
                artsbreak: 1300,
                truearts: `110000% Light DMG (MAG). For 20s, increase own Critical DMG by 200%.`,
                trueartsbreak: 2000,
                superarts: `For 120s, increase own Arts by 5/s & summon a Double that mimics all of own attacks (including Arts and Skill, but not including Equipment).`,
                superartsbreak: 0
            },
        passive: 
            {
                ability1: `If HP is above 70%, increase own Critical Rate by 100%.`,
                ability2: `Increase DMG to Enemies under 30% HP by 30%.`,
                ability3: `Increase own Arts by 50 at the start of a Quest.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131354.png",
                    mat2amt: 100,
                    hover2: "Eternaglint Soulgem"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Pipette Lance",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_153181.png",
                skill: "50s CT - 8000% Light DMG (MAG). For 10s, reduce enemies' Light RES by 30%. If user is Demon, reduce enemies' Light RES by 50% instead.",
                skillbreak: 800,
                passive: 
                    {
                        ability1: "When equipped by Shalltear, Increase own Arts by 2/s. Does not stack with Abilities of the same name.",
                        ability2: "When Near-death, heal 1% of own DMG dealt."
                    }
            },
        lore: 
            {   
                evoawk: "The Floor Guardian of the first three levels in the Great Tomb of Nazarick. Her silver hair and porcelain skin combined with her red and purple ballroom gown give her a youthful appearance. However, she is actually a True Vampire.",
                evosuper: "She has the highest authority among the Floor Guardians. Although her sheer strength outclasses Ainz, she relies on that power and forgets the consequences of her actions. "
            },
        review:
            {
                skill: `A funny nod to the anime, but not terribly functional. Might counter Hellfire if you use her as a dps for one of those stages, but otherwise won't do much in most situations.`,
                arts: `Decent RES down buff for a decent duration. While she will provide more than enough dps on her own, this lets her further buff herself and other light or dark dps units.`,
                truearts: `It seems like this is just a standard for good dps units nowadays. It goes without saying that this is a huge buff, and it even has a great duration.`,
                superarts: `This is the core of Shalltear's kit. It essentially gives her a huge 180000% multiplier on TA that is further agumented by the Crit DMG buff.`,
                ability1: `Fairly standard for a modern crit dps. The condition isn't too restrictive, and will easily be held, especially with the lifesteal from Shalltear's skill.`,
                ability2: `At this point, most things are pretty much dead. But the extra damage is nice nontheless.`,
                ability3: `Nice little addition for startup`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Shalltear has the largest multiplier on global, and for 200 Artsgauge no less. Along with further buffing on her TA and enemy debuffing on NA, Shalltear is one of the best dps units to date, and that position will be solidified with the release of units like Star God Liza who can adequately support her. However, while Shalltear is a great dps, that's all she does, and is therefore relegated to being luxury unit for faster clears. Most content is already nukable with currently available characters, and you can bet future content will find some way to lock her behind gimmicks. Regardless, she is a solid unit overall and excels at what she does. Just don't expect her to shatter the meta.`,
                lastupdated: `May 26, 2023`,
                author: `Pula`
            }
    },
    { // Ainz - Complete, Review done
        id: 107697512,
        name: "Ainz",
        attribute: "Dark",
        type: "Demon",
        rank: "SS",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107696512.png",
                detailsuper: "/db/Units/Detail/unit_detail_107697512.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107696512.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107697512.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/healLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7040,
                hpplus: 1330,
                atk: 4062,
                atkplus: 700,
                def: 3076,
                defplus: 636
            },
        skillset: 
            {
                skill: `12s CT - 2500% Dark DMG (MAG). For 10s, reduce enemies' MAG RES by 20%.`,
                skillbreak: 400,
                arts: `20000% Dark DMG (MAG). 100% chance to inflict Burn for 15s. If enemy is burned by this Arts, for 15s, reduce burned enemies' Fire RES and Dark RES by 40%.`,
                artsbreak: 900,
                truearts: `60000% Dark DMG (MAG). For 20s, increase Allies' Arts gauge by 4/s. For Demon Allies, increase Arts gauge by 6/s instead. For 25s, reduce Enemies' CRIT RES by 100%.`,
                trueartsbreak: 1200,
                superarts: `170000% Dark DMG (MAG). For 15s, increase all allies' CRI DMG by 100%. Increase Dark allies' CRI DMG by 200% instead.`,
                superartsbreak: 2000
            },
        passive: 
            {
                ability1: `When HP is above 80%, increase own Arts by 5/s.`,
                ability2: `Increase MAG RES by 20%.`,
                ability3: `Increase Allies' Critical Rate by 20%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131455.png",
                    mat2amt: 100,
                    hover2: "Sinflow Soulgem"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Staff of Ainz Ooal Gown",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_153161.png",
                skill: "50s CT - 10000% Dark DMG (MAG). For 10s, increase own MAG DMG and Dark DMG by 50%.",
                skillbreak: 600,
                passive: 
                    {
                        ability1: "When equipped by Ainz, increase own Arts by 100 at Start of Quest. Does not stack with Abilities of the same name.",
                        ability2: "Increase own MAG RES by 5%."
                    }
            },
        lore: 
            {   
                evoawk: "Formerly Satoru Suzuki, addict of the game Yggdrasil. He joined Ainz Ooal Gown, a guild entirely of players with grotesque avatars that became one of the top ten, and became its leader.",
                evosuper: "When the game world became reality, Satoru's body turned into that of Ainz's. With overwhelming charisma, perception, and mercy, he commands Nazarick as its highest authority. Or that is the character he tries to present, aided by his talent as the strongest sorcerer."
            },
        review:
            {
                skill: `Not anything overly significant compared to his other buffs, but it's a cool teamwide buff nontheless.`,
                arts: `The buff itself isn't terrible, however since many bosses will usually have status ailment resistance, this isn't as consistent nor useful as you'd want it to be.`,
                truearts: `Teamwide crit in the form of crit res down is handy to have, especially against crit resistant bosses. It will help our modern crit DPS units do their work when the boss has the aformentioned crit resistance. The 4 arts/s is fairly standard for collab artsgens, but it goes up to a solid 6 arts/s for demons, which currently isn't as good as it seems due to the lack of solid mono-demon teams on Global.`,
                superarts: `Disregarding the inflated SAWK unit multiplier, the buff is fine. A 100% buff while decent, isn't really remarkable for 300 Arts. However, a 200% buff is amazing, but is unfortunately restricted to Dark units and therefore limits you to using Mikey, Shuri, Batiste, NYRimuru, or Ainz himself as a DPS, since we don't have that many good dark ones. Not to detract from the fact that it is infact 100% DMG Up to everyone else.`,
                ability1: `Solid self sustainability ability. The condition won't be too hard to fulfill, as most teams work best at full health.`,
                ability2: `The magnitude of the buff isn't even terrible, but since Ainz isn't a tank, its not really useful when only he has it.`,
                ability3: `Negligible crit rate up buff. However, appreciated as it technically does allow the team to go above 100% Crit Rate just by using Ainz alone.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Ainz is a DMG Support who provides respectable amounts of Artsgen & C.DMG with the added benefit of CRIT Res down, explained on the TA commentary; Allows for teams to achieve 200% Crit Rate to counteract a 100% Crit RES Up Buff. Moving away from this use case, Ainz would be a respectable Artsgen Unit with respectable damage. Nothing too spectacular once more Ascended units release in the future.
                On that note, while Ainz is great for certain stages & team compositions (IE: Mono Demon-Dark), most players should instead prioritize more defense & utility oriented Ascended units that will allow for more variety & surviveability to an account such as Hazuki Senki (150% Phy DMG Up + 130% Arts DMG Up + 30% Damage Resistance to the team,) Valentines Priscilla (4 Arts/s on TA, 30% DMG Res + 150% PHY DMG UP to allies on SA, 50% HP instant Heal on Arts), and the like.
                [You'll notice that alot more units are providing DMG Res] `,
                lastupdated: `June 9, 2023`,
                author: `Pula`
            }
    },
    { // Narberal - Complete, Review done
        id: 107727412,
        name: "Narberal",
        attribute: "Light",
        type: "Demon",
        rank: "S",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107726412.png",
                detailsuper: "/db/Units/Detail/unit_detail_107727412.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107726412.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107727412.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 8118,
                hpplus: 1474,
                atk: 3168,
                atkplus: 626,
                def: 2875,
                defplus: 550
            },
        skillset: 
            {
                skill: `10s CT - 1800% Light DMG (MAG). For 8s, increase Demon Allies' DMG by 30%.`,
                skillbreak: 400,
                arts: `13000% Light DMG (MAG). Increase Allies' Arts by 20. Increase Demon Allies' Arts by 30 instead. For 20s, increase Allies' MAG RES by 30%.`,
                artsbreak: 600,
                truearts: `40000% Light DMG (MAG). For 25s, increase Allies' stats by 30%, and increase Leader Unit's Arts DMG by 200% & DMG RES by 30%.`,
                trueartsbreak: 1200,
                superarts: `140000% Light DMG (MAG). For 20s, reduce enemies' MAG RES by 120%.`,
                superartsbreak: 1400
            },
        passive: 
            {
                ability1: `At the start of a Quest, increase Demon Allies' Arts gauge by 50.`,
                ability2: `Increase Paralysis RES by 70%.`,
                ability3: `Increase DMG to Dragon Enemies by 30%.`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131354.png",
                    mat2amt: 100,
                    hover2: "Eternaglint Soulgem"
                }
            },
        trueweapon: //If no TW, may delete this section
            {
                name: "Keraunos Mk-III",
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_153191.png",
                skill: "40s CT - For 10s, increase allies' MAG DMG by 30% and Arts by 3/s.",
                skillbreak: 0,
                passive: 
                    {
                        ability1: "When equipped by Narberal, increase own Arts by 2/s. Does not stack with Abilities of the same name.",
                        ability2: "Increase own ATK by 5%."
                    }
            },
        lore: 
            {   
                evoawk: "A member of Pleiades, six combat maids in the Great Tomb of Nazarick. With her ability as a Doppelganger, she takes the form of a lovely human, with a long black pontytail and oval eyes.",
                evosuper: "A spellcaster skilled in air magic. Her hostile attitude towards humans is so strong that Ainz has to keep it in check. Additionally, she has a careless side, such as bringing up Albedo in front of unrelated parties and forgetting names."
            },
        review:
            {
                skill: `A decent buff with a relatively good uptime. Unfortunately, being restricted to Demon Allies dulls its utility.`,
                arts: `A classic DMG resistance and Arts generation arts with decent utility. But again, the arts gauge is lessened for most units due to its preferential treatment towards Demons.`,
                truearts: `A very finnicky TA that will only see use for nuking or with selfish DPS units. The stats up is a nice bonus for pseudo heals.`,
                superarts: `A large amount of MAG RES down, typically utilized for nukes as Narberal's Sustain Kit is primarily budgeted towards her Leader Specific DMG Up. Still nice if you are able to fit it into your rotations.`,
                ability1: `A great passive, hindered by again, preferential treatment towards Demons.`,
                ability2: `There aren't many situations where this would be very useful aside from maybe giving her time to get Magic resistance or heals on the rest of the team.`,
                ability3: `An unnecessarily specific damage buff that won't see much use. But it's cool I guess.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Narberal is a very interesting unit. She has decent utility with her NA and TA, and her TA and SA provide great damage buffs. However, she's very finnicky due to a mixture of her Demon Race restrictions and odd TA restrictions. Her TA buffs are great for selfish damage dealers or heavy hitting nukers, and if you are using a group of heavy hitting magic units, her Magic resistance down buffs will be of immense benefit to your damage output. But despite being pretty good, she pales slightly in comparison to other units due to her passives and skill falling short in general utility, and her lack of self sustainability. A decent unit to pick up, But certainly not worth a pity what with more valuble units expected in the future.`,
                lastupdated: `June 9, 2023`,
                author: `Pula`
            }
    },
    { // Illya - Complete, Review Done
        id: 107737412,
        name: "Illya",
        attribute: "Light",
        type: "Human",
        rank: "SS",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107736412.png",
                detailsuper: "/db/Units/Detail/unit_detail_107737412.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_773_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107736412.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107737412.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_773_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/defLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7648,
                hpplus: 1409,
                atk: 3528,
                atkplus: 677,
                def: 3080,
                defplus: 585
            },
        skillset: 
            {
                skill: `12s CT - 2200% Light DMG (MAG). Increase own Arts by 30.`,
                skillbreak: 500,
                arts: `18000% Light DMG (MAG). For 15s, reduce Enemies' Light RES by 50%.`,
                artsbreak: 800,
                truearts: `55000% Light DMG (MAG). For 25s, increase Allies' Critical Rate by 100% + For 25s, if Allies are above 50% HP, increase their Arts by 5/s.`,
                trueartsbreak: 1500,
                superarts: `170000% Light DMG (MAG). For 20s, increase own DMG based on how high own current HP is (up to 300%).`,
                superartsbreak: 2000
            },
        passive: 
            {
                ability1: `Whenever Illya uses her Arts, True Arts or Super Arts, increase own Arts by 50 if own HP is above 80%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own DMG against Dark Enemies by 20%.`,
                ability3: `Increase own accuracy by 30%.`,
                ability4: `Increase Unit EXP by 20% (When Illya's SP. Art is owned).`,
                ability5: `At the Start of a Quest, increase own Arts by 10. Does not stack with Abilities of the same name (When Illya's SP. Art is owned).`
            }, 
        trueweapon: //If no TW, may delete this section
            {
                name: "Magical Ruby",
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_152311_pris.png",
                skill: "45s CT - 6000% Light DMG (MAG). If own HP is 80% or above, for 10s, increase own MAG DMG by 70%. If own HP is below 80%, heal 20% of MAX HP instead.",
                skillbreak: 700,
                passive: 
                    {
                        ability1: "When equipped by Illya, increase own DMG and stats by 20%. Does not stack with Abilities of the same name.",
                        ability2: "Increase ATK by 5%."
                        
                    }
            },
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131354.png",
                    mat2amt: 100,
                    hover2: "Eternaglint Soulgem"
                }
            },
        lore: 
            {   
                evoawk: "Illyasviel von Einzbern, an average elementary school girl. That was until Magical Ruby, a Kaleidostick, tricked her into making a contract to become a magical girl. In place of her proper owner Rin Tohsaka, she sets out to recover the Class Cards.",
                evosuper: "A magical girl's greatest power is imagination. Drawing upon the limitless magical energy of the Kaleidostick, Illya can recreate anything her prolific mind envisions."
            },
        review:
            {
                skill: `A decent amount of Arts Generated each time you use skill, much appreciated on a support unit.`,
                arts: `A good Light resistance down debuff with a relatively good uptime, which provides to do more damage, if you are using either True Arts or Super Art afterwards.`,
                truearts: `She is able to generate 5 arts/s to the Party, if they have at least 50% or more HP, which is quite appreciated for general and shouldn’t be too hard to be kept up. As a bonus she’s able to do a decent amount of damage with a decent multiplier while providing a teamwide critical rate up by 100% with a long uptime. Illyasviel can do fairly well damage on her own.`,
                superarts: `A quite strong Super Arts for pure damage output. Increasing her own damage output based by HP up to 300% with a good uptime of 20s, which can be paired by using Illyasviel True Arts to provide critical rate up, and Normal Art to apply Light Resistance down before. She can dish out some massive damage by her own even without using either of the two Arts before. Illyasviel still does an unreasonable amount of damage.`,
                ability1: `An interesting passive that refunds 50 Arts Gauge each time you use any of her Arts, assuming you're keeping her healthy. This allows Illyasviel to cycle between her arts quite reliably & you should already be bringing a healer to your party anyways.`,
                ability2: `A rather small damage increase against Dark Enemies, but since enemies tend to be commonly to be dark. It is appreciated, free damage output, which get increased.`,
                ability3: `A nice 30% accuracy buff, which isn’t too noticeable. It can help a little bit, if the enemy has evasion up buff.`,
                ability4: `Just helping by leveling your Ascended units to 120. Afterwards it has no use. It’s appreciated to have.`,
                ability5: `Even if it’s just 10 Artgen at the start of the quest. It doesn’t hurt to get more free artgen.`,
                trueweapon: {
                    skill: `A huge Magic damage buff to increase Illyasviel damage output furthermore, if the health is over 80%, otherwise an appreciated heal of 20% of her MAX HP, which pairs well with her Super Art very well.`,
                    ability1: `A nice damage buff and stat boost, just by equipping her True Weapon.`,
                    ability2: `More free damage by increasing her ATK by 5%.`
                },
                overall: `Illyasviel von Einzbern is an awesome Ascended unit by offering both Artsgen & Crit Rate to the party while increasing her own DMG Output with a large multiplier on Super Arts. Due to the flexibility of being an Artsgen character she’s incredibly easy to slot into a party as there is no real demerit of doing so for most stages. Her roles consist of Artsgen + DPS, one can infer that she’s extremely good in nuking due to her large burst of damage on Super Arts.`,
                lastupdated: `July 14, 2023`,
                author: `Alice`
            }
    },
    { // Rin & Luvia - Review Done
        id: 107767112,
        name: "Rin & Luvia",
        attribute: "Fire",
        type: "Human",
        rank: "SS",
        twrequire: "favorable",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107766112.png",
                detailsuper: "/db/Units/Detail/unit_detail_107767112.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_776_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107766112.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107767112.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_776_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/healLB.png",
                slot32type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7935,
                hpplus: 1430,
                atk: 3245,
                atkplus: 640,
                def: 3047,
                defplus: 580
            },
        skillset: 
            {
                skill: `11s CT - 1300% Fire DMG (MAG). For 8s, increase Fire Allies' Arts by 4.`,
                skillbreak: 1000,
                arts: `18000% Fire DMG (MAG). 100% Chance to inflict Burn for 20s. If this Arts inflicts Burn, for 25s, reduce Burnt Enemies' Fire RES by 50%.`,
                artsbreak: 1800,
                truearts: `50000% Fire DMG (MAG). For 25s, reduce Enemies' DMG RES by 70% & Burn RES by 50%.`,
                trueartsbreak: 2500,
                superarts: `130000% Fire DMG (MAG). For 40s, increase Allies' MAG DMG by 160% & reduce Enemies' MAG RES by 100%.`,
                superartsbreak: 3500
            },
        passive: 
            {
                ability1: `Each time Arts inflicts Burn on an Enemy, permanently increase Allies' DMG by 30% (up to 300%).`,
                ability2: `Increase own MAG RES by 15%.`,
                ability3: `Increase own Equipment CT REC by 15%.`,
                ability4: `Increase Unit EXP by 20% (When Rin & Luvia's SP. Art is owned).`,
                ability5: `At the Start of a Quest, increase own Arts by 10. Does not stack with Abilities of the same name (When Rin & Luvia's SP. Art is owned).`
            }, 
        trueweapon: //If no TW, may delete this section
            {
                name: "Jeweled Sword Zelretch",
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_152321_pris.png",
                skill: "45s CT - 6000% Fire DMG (MAG). For 10s, consume 2%/s of own max HP, increase Allies' Arts by 4/s and increase Fire Allies' DMG by 40%.",
                skillbreak: 500,
                passive: 
                    {
                        ability1: "When equipped by Rin & Luvia, increase own Arts by 2/s and ATK by 30%. Does not stack with Abilities of the same name.",
                        ability2: "Increase MAG RES by 5%."
                        
                    }
            },
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_130853.png",
                    mat2amt: 100,
                    hover2: "Flametail Soulgem"
                }
            },
        lore: 
            {   
                evoawk: "Punished by the Clock Tower, top authority of the Mage's Association, for the consequences of their constant quarrels. As a result, they must collect the Class Cards, but most of their attempts at cooperation still end in failure.",
                evosuper: "Let the Ruby and Sapphire Kaleidosticks in order to recover the Class Cards. However, the sentient magical wands grew annoyed with their arguing and made new contracts with Illya and Miyu."
            },
        review:
            {
                skill: `A teamwide Artsgen Skill, although limited to Fire Units. Will allow Rin to cycle through her Arts alot faster due to a lack of existing Type G Artsgens.`,
                arts: `If you’d skip ahead to her unique passive, you’ll notice the importance of this Arts. The viability of this Arts is entirely dependent on the Stage however, since the Fire RES Down isn’t really something you’d worry about once you look at what Arts & Super Arts can offer.
                `,
                truearts: `To follow up from Arts briefly; The Burn Res down further enhances her ability to Burn stages.
                A whopping 70% DMG Res down onto the enemy enables the party to deal a lot more DPS onto the enemy, with a long duration to allow cycling between Rin’s Arts or Super Arts.`,
                superarts: `Providing a large 150% MAG DMG Buff and after a very obnoxious delay an additional 100% MAG RES down, Super Arts rounds out Rin’s kit by adding a large burst of Damage with additional DMG Support for herself & the team.`,
                ability1: `An unassuming Ailment = DMG Up passive, but this time it affects all allies.
                Assuming you get several stacks of this passive, Rin becomes an amazing DMG Buffer for even physical teams. And what most players wouldn’t know is that Burn is extremely easy to inflict onto pre-stages. Not to say that the Burn-Proof stages don’t exist, just uncommon.`,
                ability2: `A helpful passive for survivability.`,
                ability3: `Faster Equipment CT Recovery for more consistent Artsgen & Healing from her slots.`,
                ability4: `A nice bonus if you do happen to pick the Illustration but none have a real impact on gameplay to warrant obtaining them solely for this passive.`,
                ability5: `A nice bonus if you do happen to pick the Illustration but none have a real impact on gameplay to warrant obtaining them solely for this passive.`,
                trueweapon: {
                    skill: `An interesting drawback for a Support equipment, though not one that’s entirely new.
                    It provides a good amount of arts & sizeable DMG buff. The HP Drain is often negated through Heal/s or her own heal equipment.`,
                    ability1: `Additional ATK for more damage output & Arts Gauge to allow for ease of use.`,
                    ability2: `It is what it is.`
                },
                overall: `Rin & Luvia is an amazing DMG Support & DPS due to her various DMG Buffs & RES Down debuffs. Providing a combined total of 450% DMG Up (300% to Non-Magic Units) & 170% RES Down (70% for non-MAG), the team's damage output increases significantly. Noting how plenty of stages have at least 1-2 waves that can be inflicted with burn you’ll be able to gain these DMG stacks quite often.
                TLDR: Apply Burn for DMG Up, once stacked alternate True Arts & Super Arts for plenty of damage output.
                If no burn, Alternate anyways for damage.`,
                lastupdated: `July 14, 2023`,
                author: `Gian`
            }
    },
    { //Miyu - Review Done
        id: 107747512,
        name: `Miyu`,
        attribute: `Dark`,
        type: `Human`,
        rank: `S`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107746512.png",
                detailsuper: "/db/Units/Detail/unit_detail_107747512.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_774_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107746512.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107747512.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_774_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7425,
                hpplus: 1400,
                atk: 3987,
                atkplus: 688,
                def: 2820,
                defplus: 587
            },
        skillset: 
            {
                skill: `13s CT - 4200% Dark DMG (MAG). For 10s, increase own Arts gauge by 3/s`,
                skillbreak: 500,
                arts: `25000% Dark DMG (MAG). For 20s, reduce enemies' Dark RES by 50%.`,
                artsbreak: 1000,
                truearts: `90000% Dark DMG (MAG). For 30s, increase own ATK and Accuracy rate by 150%.`,
                trueartsbreak: 1500,
                superarts: `190000% Dark DMG (MAG). For 20s, all allies ignore 100% of Enemies' DEF and increase Human allies' DMG by 200%.`,
                superartsbreak: 1800
            },
        passive: 
            {
                ability1: `For first 150s of Quest, increase own Arts gauge by 10/s. When own HP is 80% or above, increase own Arts DMG by 200%.`,
                ability2: `Paralysis RES 100% UP.`,
                ability3: `DMG 20% UP against enemies below 30% HP.`,
                ability4: `Increase Unit EXP by 20% (When Miyu's SP. Art is owned).`,
                ability5: `At the Start of a Quest, increase own Arts by 10. Does not stack with Abilities of the same name (When Miyu's SP. Art is owned).`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131455.png",
                    mat2amt: 100,
                    hover2: "Sinflow Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Magical Sapphire`,
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_152431.png",
                skill: `45s CT - 8000% Dark DMG (MAG). For 10s, increase own ATK by 50% and reduce enemies' Magic RES by 30%.`,
                skillbreak: 500,
                passive: 
                    {
                        ability1: `When equipped by Miyu, increase DMG by 60% against Non-Giant Bosses. Does not stack with Abilities of the same name.`,
                        ability2: `Magic RES 5% UP.`
                    }
            },
        lore: 
            {   
                evoawk: "A perfect elementary school student who is skilled in studying, art, cooking, sports, and everything else. After making a contract with a Kaleidostick, she became Magical Sapphire. She gathers the Class Cards in the place of the Kaleidostick's owner Luvia while also working as her maid.",
                evosuper: "Originated from a parallel world where she was known as the God Child, born with the ability to make anyone's wish come true. She was sent to Illya's world by her older brother in an effort to keep her safe."
            },
        review:
            {
                skill: `A rather strong skill all things considered, while instant artgen would be preferred anything is appreciated`,
                arts: `A solid debuff with a decent timer, although it will likely not be used often due to better options in her kit.`,
                truearts: `While the ATK up sounds nice paper due to how atk scaling actually work it's not as impactful as it seems. The accuracy itself is nice if the boss has evasion, since it's currently the highest amount on Global while also being on a unit who can do good damage.`,
                superarts: `It goes without saying that 200% dmg up to all human allies is slightly strong, even more so since a large amount of meta units are human, and that amount will only increase over time. The defense ignore is nothing to joke about either, given the increase in bosses who negate critical hits, defense ignore has become a nice option to help increase your damage`,
                ability1: `Lots of artgen = very good. Big DMG up = brain happy. With how long the artgen passive lasts most bosses should be long dead before the 2 minute timer is up. Keeping your own HP above 80% shouldn't be too hard if you have a competent team with a healer.
                `,
                ability2: `The passive is nice to have if you're ever up against a boss that inflicts paralyze to your team.`,
                ability3: `I mean, more dmg up is always nice, sadly she already has 400% dmg up, so increasing that to 420% doesn't do much other than make 14 year olds giggle`,
                ability4: `Level up a little faster`,
                ability5: `Eh, it's something`,
                trueweapon: {
                    skill: `The ATK up isn't too impactful since she can already get 150% from her TA, but the extra res down is always nice.
                    `,
                    ability1: `The extra dmg up to non giant bosses isn't too impactful, mostly doing something when clearing prestage before the boss of a quest.`,
                    ability2: `Small dmg res which you'll never notice.`
                },
                overall: `It goes without saying, Miyu is by far the best defense ignore option currently in global and one of if not the best overall dps units. While other units such as shalltear might be able to outdamage her one for one, Miyu's 200% dmg up to all human allies more than makes up for the minor difference in her own damage. The 10 aps given to Miyu by her passive for the first 120 seconds allows her to cycle True Arts and Super Arts without too much trouble. As per all the other fate units, the special artwork should only be bought if you own the unit and heavily prefer the special artwork to the regular version.`,
                lastupdated: `July 26, 2023`,
                author: `Zanster`
            }
    },
    { //Shirou Emiya - Review Done
        id: 107777212,
        name: `Shirou Emiya`,
        attribute: `Water`,
        type: `Human`,
        rank: `SSS`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107776212.png",
                detailsuper: "/db/Units/Detail/unit_detail_107777212.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_777_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107776212.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107777212.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_777_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/healLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7480,
                hpplus: 1410,
                atk: 2887,
                atkplus: 600,
                def: 3687,
                defplus: 640
            },
        skillset: 
            {
                skill: `8s CT - 2000% Water DMG (PHY). Increase own Arts by 30.\n
                During Unlimited Blade Works: 8s CT - 2000% Water DMG (PHY). increase own Arts by 60.`,
                skillbreak: 600,
                arts: `For 10s, Increase allies' PHY RES by 30% & allies gain a DMG barrier that negates 15000 DMG.\n
                During Unlimited Blade Works: For 20s, Increase allies' PHY RES by 30% & allies gain a DMG barrier that negates 15000 DMG.`,
                artsbreak: 0,
                truearts: `40000% Water DMG (PHY). For 20s, Reduce Enemies' PHY Res by 100%.\n
                During Unlimited Blade Works: 80000% Water DMG (PHY). For 20s, Reduce Enemies' PHY Res by 100%.`,
                trueartsbreak: 1500,
                superarts: `For 180s, Enter Unlimited Blade Works.\n
                During Unlimited Blade Works: 160000% Water DMG (PHY). For 35s, Increase own ATK & DMG Up by 200%.`,
                superartsbreak: 2200
            },
        passive: 
            {
                ability1: `During Unlimited Blade Works; Every 8s, deal 10000% Water DMG (PHY) to enemies. Increase own Para/Freeze Res by 100% & Increase all Allies' DMG Res by 30%. Does not stack with Abilities of the same name.`,
                ability2: `DMG to Dark Enemies 35% UP.`,
                ability3: `DMG to Light Enemies 35% UP.`,
                ability4: `Increase Unit EXP by 20% (When Shirou's SP. Art is owned).`,
                ability5: `At the Start of a Quest, increase own Arts by 10. Does not stack with Abilities of the same name (When Shirou's SP. Art is owned).`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_132152.png",
                    mat2amt: 100,
                    hover2: "Frostsprite Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Rho Aias`,
                slot: "/db/Mines/thumbnail/defLB.png",
                detail: "/db/Equipment/Detail/item_detail_152441.png",
                skill: `35s CT - For 10s, increase All Allies' DMG Res by 30% for 10s. If used by Emiya Shirou, increase its duration by an additional 10s.`,
                skillbreak: 0,
                passive: 
                    {
                        ability1: `When Equipped by Shirou Emiya, At Start of Quest, Increase own Arts by 100. Own Skill CT REC UP by 20%.`,
                        ability2: `Increase own Heal Recovery by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Adoptive brother to Miyu. Also without any living relatives, he helped her survive a mysterious accident and raised her like a sister. He also shares a striking resemblance to Illya's adoptive brother.",
                evosuper: "Miyu's adoptive brother. He shares the same name with the brother of a parallel world's Illya. Possessing incredible combat abilities, he risked his life to save Miyu. However, he was left with no choice in the end but to send her to another world."
            },
        review:
            {
                skill: `ArtsGen on skill with a relatively short CT, tremendously useful for self-sustainability. 60 arts during Unlimited Blade Works is substantial.`,
                arts: `A teamwide shield with a total of 60k damage negated coupled with 30% teamwide physical resistance. The 10s duration is a bit low, but this gets extended to 20s while in UBW mode. Normally, you should not be dying with this up, barring exceptional circumstances.`,
                truearts: `Huge physical resistance debuff to help your other physical units hit much harder. In UBW mode, Shirou’s multiplier also becomes respectable, thereby helping him contribute to damage output.`,
                superarts: `First use makes Shirou enter UBW mode, which is similar to Magia Drive or Third Eye but costs 300 arts instead of 200 (though well worth it). Subsequent uses result in a high-multiplier arts with substantial ATK and DMG buffs. However, note that this does not make Shirou a main DPS, as he still gets outdamaged by most other units. This can help deliver a finishing blow to the enemy, but it should not distract Shirou from his main objectives which are to 1) keep the team alive, and 2) support the team’s damage output.`,
                ability1: `This passive is a good part of what makes Shirou so good, because the DMG RES buff not only lasts a full 3 minutes, but it also stacks with other units’ DMG RES buffs (e.g. Sanstone TA, Emperor Isliid TA, Fitoria NA, etc.). That means that you can maintain 60% teamwide DMG RES only from unit arts without the need to use a single defense equip. On top of that, Shirou gains paralysis and freeze resistance, which will enable him to keep doing his job, and as if this were not enough already, the devs decided to also give him periodic chip damage which, while not significant, is still a nice added touch.
                `,
                ability2: `Marginally useful passive against dark enemies, but not enough to really matter in the grand scheme of things.`,
                ability3: `Also small DMG buff against light enemies. See above.`,
                ability4: `Somewhat useful for reaching level 120 faster, but nothing too spectacular.`,
                ability5: `Another marginal benefit, but reaching UBW faster is always useful.`,
                trueweapon: {
                    skill: `Mediocre defense equip off-unit with a low-duration sizable DMG RES buff and a fairly low CT. On Shirou, the duration doubles to 20s (57% uptime), making his True Weapon one of the best general defense equips in the game. Note that you need at least 75% EQCT buff to make TW Rho Aias full-uptime.`,
                    ability1: `100 arts on start of the quest, which helps reach UBW faster, along with a skill CT buff which will help with artgen from skill.`,
                    ability2: `A small healing buff that is mostly inconsequential.`
                },
                overall: `The prize of the Prisma Illya collab, Shirou combines some of the best teamwide mitigation currently available to outstanding damage support. When he is on a team, the chances of dying decrease dramatically (with proper gameplay), making him a mainstay for most hard content. Although he is capable of DPS with a crit buffer, it is not his main role, and being too greedy with his SA might result in not being capable of keeping up with his defensive role.`,
                lastupdated: `July 26, 2023`,
                author: `MOB of Metal`
            }
    },
    { //Chloe Fate - Review Done
        id: 107757112,
        name: `Chloe (Fate)`,
        twrequire: "favorable",
        attribute: `Fire`,
        type: `Human`,
        rank: `SS`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107756112.png",
                detailsuper: "/db/Units/Detail/unit_detail_107757112.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_775_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107756112.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107757112.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_775_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/suppLB.png",
                slot32type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7612,
                hpplus: 1434,
                atk: 3335,
                atkplus: 630,
                def: 3017,
                defplus: 574
            },
        skillset: 
            {
                skill: `11s CT - 2200% Fire DMG (PHY). For 8s, lower enemy's Evasion by 20%.`,
                skillbreak: 1000,
                arts: `16000% Fire DMG (PHY). For 35s, lower enemies' BREAK RES by 40%.`,
                artsbreak: 3000,
                truearts: `40000% Fire DMG (PHY). For 20s, Increase allies' Arts by 5/s & BREAK PWR by 70%.`,
                trueartsbreak: 6000,
                superarts: `100000% Fire DMG (PHY). For 25s, reduce enemies' Fire RES by 100%`,
                superartsbreak: 12000
            },
        passive: 
            {
                ability1: `Class Card Archer: When a Boss is Broken, Increase allies' Arts by 200. (Does not stack with Abilities of the same name.)`,
                ability2: `Burn RES 70% UP`,
                ability3: `Additional Attack Master: Increases all allies' DMG to Broken Enemies by 50%`,
                ability4: `Unit EXP UP 20% UP. (When Chloe's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Chloe's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_130853.png",
                    mat2amt: 100,
                    hover2: "Flametail Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Kanshou & Bakuya`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_152511.png",
                skill: `55s CT - 4000% Fire DMG (PHY). When used for the first time, increase own Arts gauge by 50.`,
                skillbreak: 4000,
                passive: 
                    {
                        ability1: `When equipped by Chloe (Fate), increase own BREAK PWR by 40% & auto-fills Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: `PHY RES 5% Up`
                    }
            },
        lore: 
            {   
                evoawk: "Identical to Illya except for her tanned skin and mischievous nature. She causes a lot of trouble at school stealing kisses from the girls. The others have a hard time telling if she is friend or foe at times.",
                evosuper: `Nicknamed "Kuro". Her true identity is a power sealed within Illya given physical form. After a fierce battle, the two make up. She receives magical energy through kissing.`
            },
        review:
            {
                skill: `It doesn't affect your performance too much, but it’s still appreciated as a niche to help deal damage to enemies with evasion`,
                arts: `A very good Break Resistance debuff of 40% with a long uptime. Allows Chloe and the rest of the team to break the boss much faster, and allows her to alternate between both her Arts and True Arts while having the Break resistance debuff always active.`,
                truearts: `Another amazing True Art for the role as a breaker. This allows Chloe and your whole party to break the boss very quickly, especially if you have applied the Break resistance debuff from her Arts beforehand. On top of that, Chloe is able to generate noticeable Arts to your whole team as well as an added bonus.`,
                superarts: `With a great damage multiplier and a 100% Fire Res debuff, Chloe, as well as the team, is able to do some good damage especially if your team is fire-oriented. Otherwise in most situations, you would use Chloe’s Arts and True Art more often.`,
                ability1: `A very cool passive especially for an unit like Chloe! Coupled with the ability to break through enemies fast, you’ll find the 200 teamwide Arts extremely useful for the team to dish out some amazing damage using Arts, True Arts  or Super Arts right before the boss breaks and right after.`,
                ability2: `A high Burn Resistance passive, which is appreciated. It’s more on the niche side, so it won’t see much use generally.`,
                ability3: `Another great Passive out of her Kit, which increases the team’s overall damage output when the Boss is broken.`,
                ability4: `Just helping by leveling your Ascended units to 120. Afterwards it has no use. It’s appreciated to have.`,
                ability5: `Even if it’s just 10 Artgen at the start of the quest. It doesn’t hurt to get more free artgen.`,
                trueweapon: {
                    skill: `A very nice burst artgen right at the start with a high break value, which is helpful to get Chloe started with using her Arts.`,
                    ability1: `A good break power buff of 40%, which allows Chloe to achieve a total of 110% Break Power up alongside her True Art. As a bonus, she passively gains 2 Arts/s as well. `,
                    ability2: `Free damage resistance against physical bosses, but with how low it is, it is barely noticeable but still appreciated.`
                },
                overall: `Chloe is an amazing breaker and support unit, achieving a whopping 110% Break Power buff by simply equipping her True Weapon and using her True Art. Her Arts inflicting a significant 40% Break resistance debuff helps Chloe and her team with breaking bosses even more, solidifying her place as an amazing Breaker. Moreover, she also offers Arts Gen for the whole party, which stacks with most other units as she is Type E. And to top it all off, her unique passive increases all allies’ Arts gauge by 200 during Boss’ Break to allow them to dish out big damage. While Break is somewhat niche, with newer content leaning more into Breaking bosses, Chloe is definitely a great unit to have!`,
                lastupdated: `August 8, 2023`,
                author: `Alice`
            }
    },
    { //Saber Alter - Review done
        id: 107787512,
        name: `Saber Alter`,
        attribute: `Dark`,
        type: `Human`,
        rank: `S`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107786512.png",
                detailsuper: "/db/Units/Detail/unit_detail_107787512.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_778_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107786512.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107787512.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_778_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/physLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7254,
                hpplus: 1369,
                atk: 4006,
                atkplus: 691,
                def: 2941,
                defplus: 610
            },
        skillset: 
            {
                skill: `11s CT - 3000% Dark DMG (PHY). Increase own Arts by 50. If this Skill crits, increase Arts by an additional 30.`,
                skillbreak: 500,
                arts: `26000% Dark DMG (PHY). For 20s, increase all allies' STATS by 30%.`,
                artsbreak: 1000,
                truearts: `64000% Dark DMG (PHY). For 30s, Increase own DMG by 100% & Reduce enemies' Dark RES by 70%.`,
                trueartsbreak: 1500,
                superarts: `185000% Dark DMG (PHY). For the duration of Super Arts, Increase own Super CRIT Rate by 60%.`,
                superartsbreak: 2200
            },
        passive: 
            {
                ability1: `Seeker of the Holy Grail: When own HP is 50% or higher, Increase own CRIT Rate by 100%.`,
                ability2: `Poison RES 40% Up`,
                ability3: `Increase own ATK by 25% when HP is above 90%.`,
                ability4: `Unit EXP UP 20% UP. (When Saber Alter's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Saber Alter's SP. Art is owned)`
            },
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131455.png",
                    mat2amt: 100,
                    hover2: "Sinflow Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Excalibur Morgan`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_152521.png",
                skill: `50s CT - 8000% Dark DMG (PHY). For 10s, Increase own DMG by 50%. If user is Dark; Additionally increase own ATK by 50%.`,
                skillbreak: 700,
                passive: 
                    {
                        ability1: `When equipped by Saber Alter, Increase own CRIT DMG by 80%. Does not stack with Abilities of the same name.`,
                        ability2: `MAG RES 5% Up`
                    }
            },
        lore: 
            {   
                evoawk: "A manifestation of the Heroic Spirit contained in the Saber Class Card. Illya and her friends struggled against her overwhelming strength.",
                evosuper: "The Heroic Spirit of the Saber Class Card. Her true identity is famed historic figure Artoria Pendragon. Her Noble Phantasm is Excalibur, the sword of promised victory, said to be the strongest of all holy blades."
            },
        review:
            {
                skill: `Large amount of artsgen on skill with a fairly short cooldown time. Since Saber should always be critting, that’s 80 arts per use`,
                arts: `A standard pseudo heal. great to have, especially to keep her HP topped up to fulfill her crit rate requirement.`,
                truearts: `Huge self damage buff paired with a very sizable Dark Res down debuff, and on top of that, it has a decent multiplier (For non-Ascendable units of course).`,
                superarts: `Enormous multiplier paired with high super crit rate. This along with her True Arts, and by virtue of being a Dark Human unit is what makes Saber Alter the uncontested Best DPS and even Nuker on Global.`,
                ability1: `100% passive crit rate, pretty much a given with most good DPS units. The HP condition isn’t very intrusive either, so you shouldn’t have any issue maintaining it. However, the same can't be said for the status ailment condition. In any stages where this might be an issue, it might be a good idea to bring consistent cleanse or a replacement for the lost accuracy. Or just put Karks to negate the issue entirely.`,
                ability2: `Not very useful in the grand scheme of things. 40% extra resistance is cool, but niche and unnecessary in 99% of cases.`,
                ability3: `High HP requirement for a very small buff. It’s helped somewhat by Saber’s high base ATK, but it’s still a rather minor damage buff.`,
                ability4: `Helps you level past 90 a bit faster, but otherwise it’s nothing special.`,
                ability5: `A very small arts buff, but appreciated nonetheless.`,
                trueweapon: {
                    skill: `It’s her bis for nuking, but when it isn’t on Saber it’s completely overshadowed by other buffing equipment like Simon TW and Bushido Master.`,
                    ability1: `On Saber Alter, This equip is pretty much the same in terms of buffing in nukes as Simon’s TW is on him. It's great for said nuking, and amazing for sustain since the larger buff is on her passive, hugely buffing her crit and super crit damage. Due to how much of a damage buff it provides, it's definitely worth picking up for her.`,
                    ability2: `The buff itself is useless since Saber isn’t a tank, and it’s so small that even if she were, it would still be for the most part, inconsequential.`
                },
                overall: `Saber Alter is the worst unit in the Fate collab. This isn't because she’s bad at what she does-- She’s likely the best DPS and Nuker on Global. Rather, it’s because outside of her pseudo-heal on NA, she contributes nothing to the team besides damage, which makes her for the most part, replaceable by any other dps unit. This effectively means that any team that clears a mission with her on it can already three-man the mission anyway, albeit while taking longer. With content becoming increasingly difficult, this means that it’s going to be harder to use units that only do one thing and more important to have units that can fill multiple roles at once, which unfortunately, Saber doesn't do. She’s still amazing for nukes, and anything that doesn’t directly counter her will still be shredded. But just keep in mind that your pities should be saved for units that can fulfill multiple roles well, allowing for more flexibility in endgame content.`,
                lastupdated: `August 8, 2023`,
                author: `Pula`
            }
    },
    { //Gabimaru - Complete, Review done
        id: 107797112,
        name: `Gabimaru`,
        rank: `S`,
        attribute: `Fire`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107796112.png",
                detailsuper: "/db/Units/Detail/unit_detail_107797112.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_779_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107796112.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107797112.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_779_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/suppLB.png",
                slot32type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 6998,
                hpplus: 1322,
                atk: 3916,
                atkplus: 676,
                def: 3181,
                defplus: 656
            },
        skillset: 
            {
                skill: `11s CT - 2000% Fire DMG (PHY). Increase own Arts by 30. If ownself is not inflicted with a Status ailment, increase own Arts by 60 instead.`,
                skillbreak: 500,
                arts: `20000% Fire DMG (PHY). 100% chance to inflict Burn for 20s + Increase own Arts by 50.`,
                artsbreak: 700,
                truearts: `65000% Fire DMG (PHY). For 30s, increase Allies' Critical Rate by 100% and Fire Allies' DMG by 150%.`,
                trueartsbreak: 1200,
                superarts: `180000% Fire DMG (PHY). For the duration of this Super Arts, increase own Critical Rate by 100% and Super Critical Rate by 30%.`,
                superartsbreak: 1800
            },
        passive: 
            {
                ability1: `When own HP is 90% or higher, increase own Super Critical Rate by 30%.`,
                ability2: `Increase own Evasion by 15%.`,
                ability3: `Increase own Poison RES by 100%.`,
                ability4: `Unit EXP UP 20% UP. (When Gabimaru's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Gabimaru's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_130853.png",
                    mat2amt: 100,
                    hover2: "Flametail Soulgem"
                }
            },
        lore: 
            {   
                evoawk: `Known as Gabimaru the Hollow, an outcast ninja from Iwagakure. Imprisoned for killing twenty magistrates, he was one of the convicts sent to Shinsenkyo. His nickname gives implication he feels no emotion, but that might not be entirely true.`,
                evosuper: `Once known as Gabimaru the Hollow. He became able to feel after marrying his wife and even swore off his deadly occupation for her sake. However, a former ally's betrayal led to his arrest. To earn pardon and see his wife again, he accepts a request to retrieve the elixir of life from Shinsenkyo.`
            },
        review:
            {
                skill: `A rather standard skill for this day and age, a nice boost to your artgen with a conditional effect that is easy to ignore.`,
                arts: `I mean, if you need to burn then this is a decent option however most of the time you'd be fine just ignoring this.`,
                truearts: `A nice and long duration on this True Art allows Gabimaru to easily cycle between his True Art and Super Art while maintaning full uptime on the Crit rate and dmg up from this.`,
                superarts: `A strong multiplier and chance to super crit will help Gabimaru perform his role as a DPS. However the lack of a dmg up on this Super Art will force you to cycle True Art and Super Art for maximum damage.`,
                ability1: `More Super Crit chance is always good to have, While this condition can be a bit annoying to deal with, a decent healer is more then enough to keep the team above 90% HP for most stages.`,
                ability2: `....yeah no, most bosses have some form of accuracy up which will negate this anyway. Not to mention that 15% evasion is so small that you won't even be able to notice it anyway.`,
                ability3: `100% poison res is nice to have since it's one less thing to worry about for his skills conditional effect.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Gabimaru is a dps, that's it. While he is one of the best DPS option for global right now, other units have more defensive tools to allow flexibility. It should be noted that because Gabimaru has passive S.Crit he is capable of Nuking at an impressive degree. You may forget about him as a DPS but you'll be reminded he exists every blue moon by a Leaderboard Nuker.`,
                lastupdated: `September 21, 2023`,
                author: `Zanster`
            }
    },
    { //Sagiri - Complete, Review done
        id: 107807312,
        name: `Sagiri`,
        attribute: `Earth`,
        type: `Human`,
        rank: `S`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107806312.png",
                detailsuper: "/db/Units/Detail/unit_detail_107807312.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_780_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107806312.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107807312.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_780_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/defLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7638,
                hpplus: 1322,
                atk: 3172,
                atkplus: 614,
                def: 3077,
                defplus: 585
            },
        skillset: 
            {
                skill: `13s CT - 1700% Earth DMG (PHY). For 20s, increase own DMG by 20% (stackable).`,
                skillbreak: 500,
                arts: `16000% Earth DMG (PHY). For 20s, increase Allies' PHY DMG and Break PWR by 75%.`,
                artsbreak: 1800,
                truearts: `50000% Earth DMG (PHY). Increase Allies' Arts by 20. For 30s, increase Allies' Arts by 3/s and increase Allies' PHY RES by 30%.`,
                trueartsbreak: 2500,
                superarts: `150000% Earth DMG (PHY). For 30s, reduce Enemies' Earth RES by 120%.`,
                superartsbreak: 4200
            },
        passive: 
            {
                ability1: `When own HP is 80% or higher, increase own accuracy by 100%. When own HP is 50% or higher, increase own Arts by 6/s.`,
                ability2: `Increase own DMG against Human enemies by 50%.`,
                ability3: `Increase own Blind RES by 60%.`,
                ability4: `Unit EXP UP 20% UP. (When Sagiri's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Sagiri's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat3: "/db/Icons/dungeon_drops/material_thumbnail_130653.png",
                    mat3amt: 100,
                    hover3: "Smogdragon Soulgem"
                }
            },
        lore: 
            {   
                evoawk: `A member of the Yamada Asaemon "blade testers", official executors of the shogunate. Although twelfth in rank, her talent rivals the upper echelon. She joins Gabimaru as his escort to Shinsenkyo.`,
                evosuper: `One of the few female members of Yamada Asaemon. She was born to a family of official executors, but her internal conflicts hold her back from true mastery. It is only after she meets Gabimaru, that she begins to accept both her strengths and weaknesses.`
            },
        review:
            {
                skill: `It's relatively small for a sawk dmg buff, even if she isn't a main DPS. Artsgen would be better though.`,
                arts: `Decent Physical Damage buff along with a substantial teamwide Break Power buff. very valuble due to the mix of break with other useful abilities.`,
                truearts: `The artsgen is fine, Sagiri isn't ideal for solo artsgenning but she'll function fine as a secondary artsgen. The Physical Resistance buff however is very good, and one not offered by many units, especially ones with as much utility as Sagiri.`,
                superarts: `Big Earth Resistance buff and decently high base break, not much else to say about it. War Hero Fen likey.`,
                ability1: `Slightly high HP requirement, but a useful unit who fills the accuracy niche is always cool. The HP requirement for the arts however is fairly attainable, and her self artsgen is substantial.`,
                ability2: `Cool I guess, but anything that wants the extra damage probably isn't human.`,
                ability3: `Meh. At any point where you'd want Blind Resistance, you'd need to bring it up to 100% for the rest of the team anyway.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Sagiri is a perfect embodiment of "Jack of all trades, master of none". She has it all, arts over time artsgen, instant artsgen, damage resistance (albiet only physical), damage buffing, and even break. She has the similar merits to Fate Chloe: She enables decent break while not being a dead slot, it should be emphasized that Sagiri is leagues behind Chloe in BREAK due to a lack of BREAK RES down & Strong Break Values. Additionally, The physical damage resistance is slightly devalued due to many bosses being a mix of magic and physical damage dealers, but less damage is still less damage. High self sustainability arts wise despite not having an arts skill, and her slots are... alright. Overall a decent unit, but obviously not worth breaking the bank for.`,
                lastupdated: `September 21, 2023`,
                author: `Pula`
            }
    },
    { //Yuzuriha - Missing Lore (GL), Review Done
        id: 107817512,
        name: `Yuzuriha`,
        attribute: `Dark`,
        type: `Human`,
        rank: `S`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107816512.png",
                detailsuper: "/db/Units/Detail/unit_detail_107817512.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_781_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107816512.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107817512.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_781_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7810,
                hpplus: 1470,
                atk: 2635,
                atkplus: 552,
                def: 3300,
                defplus: 578
            },
        skillset: 
            {
                skill: `10s CT - 1800% Dark DMG (PHY). Heal own HP by 40% and increase own Arts gauge by 20.`,
                skillbreak: 600,
                arts: `15000% Dark DMG (PHY). For 25s, increase own Max HP by 60% and redirect 40% of Allies' DMG taken to self. `,
                artsbreak: 1000,
                truearts: `52000% Dark DMG (PHY). For 25s, reduce enemies' Dark RES by 80% and increase Arts gauge of all allies unaffected by status ailments by 4/s.`,
                trueartsbreak: 1600,
                superarts: `140000% Dark DMG (PHY). For 20s, reduce enemies' PHY RES based on how high this unit's HP is when Super Arts is activated (MAX: 200%).`,
                superartsbreak: 2000
            },
        passive: 
            {
                ability1: `When self is Leader, increase Human allies' DMG by 50%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Paralysis RES by 100%.`,
                ability3: `Increase own PHY RES by 30%.`,
                ability4: `Unit EXP UP 20% UP. (When Yuzuriha's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Yuzuriha's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131455.png",
                    mat2amt: 100,
                    hover2: "Sinflow Soulgem"
                }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            },
        review:
            {
                skill: `A noticeable amount of heal paired with artgen helps with survivability in her role as Artgenner or Damage Support.`,
                arts: `A very interesting Art that redirects 40% of the damage towards self while increasing the Max HP by 60%. This can help your team survivability. But due the lack of a def slot it might go the wrong way as well. It’ll see less use than Yuzuriha’s True Art and Super Art.`,
                truearts: `With a decently multiplier, but with a huge amount of artgen assuming your units aren’t affected by Status Ailment paired with a very good Dark Resistance Down of 80%. Yuzuriha’s True Art, and your allies' Dark units can deal greater damage than before.`,
                superarts: `With a huge multiplier and an amazing Physical Resistance down paired with her Normal Art this can easily achieve some massive damage while also your physical dps unit will deal an amazing amount of damage. Which will often be used by nuking certain stages.`,
                ability1: `A minimum effort Passive, just by setting Yuzuriha as Leader and increasing Human Races units damage output further is nothing wrong. That’s some good Passive, especially for nuking.
                `,
                ability2: `Free Status Ailment Resistance is never wrong. Although it’s only for Paralyse, it’ll help Yuzuriha to get her True Art / Super Art out when the stage is to paralyze your team.`,
                ability3: `Some Physical Resistance doesn’t hurt, as it will help with survivability. Also, it pairs well with her Normal Art assuming you’re using it. But as mentioned earlier it likely won’t see much use.`,
                ability4: `No comment`,
                ability5: `No comment`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `Yuzuriha is an amazing supportive unit for any box. With some sort of Survivability by redirecting the damage towards self, or by increasing allies Artsgen with her True Art or simply increasing your physical oriented teams damage output drastically. She can fit in most teams. Mainly, she’ll shine as an Artgen unit, if lacking better options or as a massive damage supporter with either her True Arts or Super Art.`,
                lastupdated: `October 5, 2023`,
                author: `Alice`
            }
    },
    { //Chobe - Missing Lore (GL), Review Done
        id: 107827412,
        name: `Chobe`,
        attribute: `Light`,
        type: `Human`,
        rank: `S`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107826412.png",
                detailsuper: "/db/Units/Detail/unit_detail_107827412.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_782_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107826412.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107827412.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_782_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/physLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7755,
                hpplus: 1460,
                atk: 3530,
                atkplus: 664,
                def: 2886,
                defplus: 552
            },
        skillset: 
            {
                skill: `13s CT - 2500% Light DMG (PHY). For 7s, increase own ATK by 30%.`,
                skillbreak: 1000,
                arts: `20000% Light DMG (PHY). For 25s, reduce enemies' Break RES by 40%.`,
                artsbreak: 2000,
                truearts: `55000% Light DMG (PHY). For 65s, increase own Arts gauge by 1/s and own Break Power by 30% (Stackable).`,
                trueartsbreak: 4200,
                superarts: `160000% Light DMG (PHY). For 65s, increase own DMG by 60% and own Arts gauge by 2/s (Stackable).`,
                superartsbreak: 4500
            },
        passive: 
            {
                ability1: `When own HP is 80% or higher, ignore 100% of enemies' DEF. Increase own Arts gauge by 100 at the Start of Quest. Does not stack with Abilities of the same name.`,
                ability2: `Increase own DMG against God enemies by 70%.`,
                ability3: `Increase own DMG against Demon enemies by 70%.`,
                ability4: `Unit EXP UP 20% UP. (When Chobe's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Chobe's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131354.png",
                    mat2amt: 100,
                    hover2: "Eternaglint Soulgem"
                }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            },
        review:
            {
                skill: `Long CT for a small ATK buff. Not very impactful overall.`,
                arts: `Reducing enemies’ break RES is highly relevant for when breaking is involved, because bosses who need to be broken several times usually gain break RES every time.`,
                truearts: `Ramping break buff along with stacking self artgen. Fairly useful for when bosses need to be broken to deal appreciable damage, though situational for when break is required.`,
                superarts: `This time, ramping DMG buff with stacking self artgen and with a rather long duration. The animation is fairly fast so it is possible to stack it a fair amount of times with help from the team`,
                ability1: `The starting arts is somewhat nice because Chobe has no other source or self-sufficiency other than his self artgen from TA/SA that require 200/300 arts to get started. Ignoring enemies’ defense is a convenient way to bypass bosses’ crit resistance.
                `,
                ability2: `Marginally useful passive against God enemies, but not enough to really matter in the grand scheme of things.`,
                ability3: `Also small DMG buff against Demon enemies, which are more common than Gods. Still, marginal passive.`,
                ability4: `Somewhat useful for reaching level 120 faster, but nothing too spectacular.`,
                ability5: `Another marginal benefit, but reaching TA or SA faster is always useful.`,
                trueweapon: {
                    skill: ``,
                    ability1: ``,
                    ability2: ``
                },
                overall: `A ramping breaker/DPS who can stack self artgen, it’s always nice to have a breaker that can accomplish other roles for when break is not needed. The defense ignore passive is great for bosses who have or gain crit resistance. His fast SA animation also make him usable for nukes (for nukable content). Anybody who has farmed summoners’ road or Nova crests can notice that his mechanics line up very well with recent content, making him a solid addition to one’s box.`,
                lastupdated: `October 5, 2023`,
                author: `MOB of Metal`
            }
    },
    { //Demon Lord Rimuru - Complete
        id: 107837512,
        name: `Demon Lord Rimuru`,
        attribute: `Dark`,
        type: `Demon`,
        rank: `SS`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107836512.png",
                detailsuper: "/db/Units/Detail/unit_detail_107837512.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_783_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107836512.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107837512.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_783_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 5,
                slot31type: "/db/Mines/thumbnail/healLB.png",
                slot32type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7260,
                hpplus: 1370,
                atk: 4050,
                atkplus: 698,
                def: 2914,
                defplus: 605
            },
        skillset: 
            {
                skill: `12s CT - 3000% Dark DMG (MAG). For 7s, increase own Stats by 20%.`,
                skillbreak: 500,
                arts: `17000% Dark DMG (MAG). For 45s, increase own CRI rate by 100%. The next time this unit's Super Arts is used, increase own Arts by 150.`,
                artsbreak: 1000,
                truearts: `60000% Dark DMG (MAG). For 40s, reduce enemies' DMG RES by 70% (stackable).`,
                trueartsbreak: 1800,
                superarts: `160000% Dark DMG (MAG). Increase own Super Critical Rate by 40% for the duration of Super Arts. For 30s, increase all allies' Dark DMG by 120% (stackable).`,
                superartsbreak: 2000
            },
        passive: 
            {
                ability1: `Lord of Wisdom: When HP is 50% or more, increase own Arts by 5/s. When own HP is 50% or less, increase all allies' DMG RES by 30%. Does not stack with abilities of the same name. (Lv.1)`,
                ability2: `Increase own Burn RES by 100%. (Lv. 110)`,
                ability3: `Increase own Paralyze RES by 100%. (Lv. 120)`,
                ability4: `Unit EXP UP 20% UP. (When Demon Lord Rimuru's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Demon Lord Rimuru's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131455.png",
                    mat2amt: 100,
                    hover2: "Sinflow Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Rimuru's Mantle`,
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_152871.png",
                skill: `40s CT - Increase Target's Arts by 40. For 5s, increase all allies' Arts by 4/s.`,
                skillbreak: 0,
                passive: 
                    {
                        ability1: `When equipped by Demon Lord Rimuru, increase own ATK by 70% & Arts by 1/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own Skill DMG by 10%.`
                    }
            },
        lore: 
            {   
                evoawk: "To revive comrades killed by humans. Rimuru Tempest decided to become a true Demon Lord. However, a great number of sacrifices were necessary to provide the nutrients, so the Harvest Festival began.",
                evosuper: `The newest member of Octagram, eight Demon Lords that rule the world. is a Slime known as Rimuru Tempest. The Unique Skill "Great Sage" also evolved into its ultimate form, "Raphael".`
            },
        review:
            {
                skill: `Though not the usual arts on skill, it can help to provide DLRimuru with some pseudo-healing through the stats up and maintain his passive’s arts generation.`,
                arts: `Provides a long-lasting crit-rate up buff that helps to increase DLRimuru’s damage output if crit wasn’t already provided in the team. The 150 arts refund after using his next SA will enable Rimuru to burst down bosses by using 2 consecutive Super Arts.`,
                truearts: `With a large 70% general DMG RES down this can easily help boost your whole team’s damage, not to mention it lasts for 40 seconds and is also stackable with consecutive casts of his TA. However, this is generally used if the boss has DMG Res or Reduction, due to the large amount of damage Super Arts can provide even without this debuff.`,
                superarts: `The bulk of DLRimuru’s damage lies here. A 160k% multiplier paired with 40% Super Crit Rate, it’s important to note how in order to super crit you must crit first, requiring you to use his normal art every now and then if you don’t have another source of crit. Additionally, with hefty 30s stackable teamwide Dark DMG up allows DLRimuru and other dark damaging units in the team to dish out incredible amounts of damage.`,
                ability1: `A very strong passive all things considered, providing him with arts most of the time and teamwide DMG Res whenever things start going bad. Additionally since this is passive DMG Res, it will stack with all other sources.`,
                ability2: `Extra status resistance against burning which is always appreciated.`,
                ability3: `Much like burn res this is nice to have, even more so since Paralyze can be annoying to deal with.`,
                trueweapon: {
                    skill: `Decent overtime artsgen equipment with 3 arts/s rate, and on DLRimuru it becomes even better thanks to its unique passive.`,
                    ability1: `Comes with a substantial 70% ATK buff, alongside a 1 arts/s buff to DLRimuru, and extra arts/s is always appreciated.`,
                    ability2: `Negligible buff that will not have any significant contribution to DLRimuru’s damage.`
                },
                overall: `Demon Lord Rimuru’s kit is incredibly damage oriented with him being one of the best DPS options available and a staple in nuke teams thanks to his extremely fast & hard hitting SA animation. While he can be used on almost any team as the main DPS, where DLRimuru truly shines is on the premium Mono Demon Dark composition, where the team uses every possible synergy to power through quests. `,
                lastupdated: `December 15, 2023`,
                author: `damino_`
            }
    },
    { //Combat Form Milim - Missing Lore (GL), Review Done
        id: 107847112,
        name: `Combat Form Milim`,
        attribute: `Fire`,
        type: `Demon`,
        rank: `A`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107846112.png",
                detailsuper: "/db/Units/Detail/unit_detail_107847112.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_784_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107846112.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107847112.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_784_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7480,
                hpplus: 1410,
                atk: 3450,
                atkplus: 650,
                def: 3059,
                defplus: 582
            },
        skillset: 
            {
                skill: `12s CT - 3000% Fire DMG (PHY). For 10s, reduce enemies' DMG RES by 10%.`,
                skillbreak: 300,
                arts: `22000% Fire DMG (PHY). Heals own Status ailments (except Faint, Paralysis, Seal, Freeze). For 20s, increase all allies' Paralysis RES & Freeze RES by 100%.`,
                artsbreak: 700,
                truearts: `30000% Fire DMG (PHY). Each time True Arts is used, increase Break Power of this True Arts by 3000 (MAX: 15000). When Super Arts is used, resets the Break Power of this True Arts back to 3000.`,
                trueartsbreak: 3000,
                superarts: `170000% Fire DMG (PHY). Each time Super Arts is used, increase the DMG Multiplier of this Super Arts by an additional 170000% (MAX: 510000%). When Arts or True Arts is used, resets the DMG Multiplier of this Super Arts back to 170000%.`,
                superartsbreak: 1500
            },
        passive: 
            {
                ability1: `Tyrant of Destruction: When not affected by Status ailments, increase own DMG and Break Power by 50%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Arts by 1/s.`,
                ability3: `Increase own Arts by 200 at start of quest.`,
                ability4: `Unit EXP UP 20% UP. (When Combat Form Milim's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Combat Form Milime's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_130853.png",
                    mat2amt: 100,
                    hover2: "Flametail Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Milim's Armor`,
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_152881.png",
                skill: `50s CT - 6000% Fire DMG (MAG). For 15s, increase own Fire DMG by 50%. If user is Demon, increase own Fire DMG by 80% instead.`,
                skillbreak: 600,
                passive: 
                    {
                        ability1: `When equipped by Combat Form Milim, increase own Status RES (except Faint) by 30% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own MAG RES by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            },
        review:
            {
                skill: `Minor DMG RES down with good uptime is always appreciated. However it conflicts with the multiple DMG Res down effects that exist in the game already.`,
                arts: `A rather weird part in her kit that serves to maintain her own unique passive, can also provide Flinch resistances against those that can stop your units and attacks in their tracks. That being said you’ll almost never use this since you wouldn’t want to lose your TA/SA stacks to something a heal equipment can handle.`,
                truearts: `Milim’s True Arts focuses on ramping up her next TAs’ Break Power, taking a total of 5 uses to hit the cap of 15000 Break on her TA. Although this looks like a big number, Milim’s breaking capabilities are heavily hurt due to a lack of break power up and no break res down.`,
                superarts: `Similar to her True Arts, but this time it’s ramping damage instead of Break. It takes 3 uses to reach the 510k% multiplier that this SA boasts. Nothing much other than a big number and unfortunately Milim lacks a significant amount of DMG Up by herself so this can result in unfulfilled expectations.`,
                ability1: `The passive offers decent buffs in both areas her kit covers, nice passive with a requirement that is not hard to fulfil.`,
                ability2: `Innate arts generation with no requirement is always nice to have, even if you won’t notice it most of the time.`,
                ability3: `This burst of arts only happens once at the start, not per wave. Nonetheless it’s good to help her start ramping earlier.`,
                trueweapon: {
                    skill: `Provides quite a huge Fire DMG up on Combat Form Milim or any other Demon. Even if not used by a Demon, 50% is still a noticeable buff.`,
                    ability1: `Status RES is to try and maintain her passive, but at only 30% there’s still quite a big chance of getting hit by one. It’s to be considered as an added bonus, as the real prize of this True Weapon is the 2 arts/s it provides to Milim to help her dish out her ramping Arts.`,
                    ability2: `Minor buff but appreciated.`
                },
                overall: `Combat Form Milim tries too hard to be both a Breaker as well as a DPS and in doing so she suffers in both. Aside from her passive, she has no innate breaking or damage buffs to aid her. Not only does she require time and loads of art to ramp her TA or SA, she has very little self artsgen, even with her TW. It goes without saying that she can do very well if supported properly, but that’s the same for any other unit. Bringing a dedicated breaker or a DPS that have the abilities to complement their own role will serve better than trying to get Combat Form Milim to do either.`,
                lastupdated: `December 15, 2023`,
                author: `damino_`
            }
    },
    { //Diablo - Complete
        id: 107857512,
        name: `Diablo`,
        attribute: `Dark`,
        type: `Demon`,
        rank: `S`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107856512.png",
                detailsuper: "/db/Units/Detail/unit_detail_107857512.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_785_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107856512.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107857512.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_785_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/suppLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7700,
                hpplus: 1450,
                atk: 2835,
                atkplus: 590,
                def: 3500,
                defplus: 610
            },
        skillset: 
            {
                skill: `11s CT - 1700% Dark DMG (PHY). Increase own Arts by 40.`,
                skillbreak: 500,
                arts: `17000% Dark DMG (PHY). For 20s, increase all Allies' DEF Equip CT Speed by 30% and reduce enemies' ATK by 30%.`,
                artsbreak: 900,
                truearts: `50000% Dark DMG (PHY). For 20s, reduce enemies' Evasion by 40% and PHY RES by 80%.`,
                trueartsbreak: 1500,
                superarts: `130000% Dark DMG (PHY). 100% Chance to inflict Blind for 30s. For 30s, reduce enemies' Dark RES by 120%.`,
                superartsbreak: 2000
            },
        passive: 
            {
                ability1: `Seducer: When own HP is 90% or more, increase the Arts gauge of the units to the Left and Right of Diablo by 6/s. Does not stack with Abilities of the same name. (Lv. 1)`,
                ability2: `Increase own Blind RES by 100%. (Lv. 110)`,
                ability3: `Increase own DMG to Blinded Enemies by 80%. (Lv. 120)`,
                ability4: `Unit EXP UP 20% UP. (When Diablo's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Diablo's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131455.png",
                    mat2amt: 100,
                    hover2: "Sinflow Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Diablo's Suit`,
                slot: "/db/Mines/thumbnail/defLB.png",
                detail: "/db/Equipment/Detail/item_detail_152891.png",
                skill: `40s CT - For 15s, increase all Allies' DMG RES by 30%.`,
                skillbreak: 0,
                passive: 
                    {
                        ability1: `When equipped by Diablo, reduce the amount of HP required for his Seducer Passive Ability to 80% HP or higher instead. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own PHY RES by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "An Arch Daemon summoned by Rimuru Tempest to serve as bodyguard. The extent of his power exceeded even his master's expectations. When Rimuru became a Demon Lord, he received his current name and swore absolute loyalty in response.",
                evosuper: "His true identity is Noir, one of seven Primordial Daemons. After being named by Rimuru, he became a Demon Lord, giving him nearly equivalent strength."
            },
        review:
            {
                skill: `Big instant arts on skill is always great to have.`,
                arts: `Since this Equipment CT is specific to defense equipment it will stack with general Equipment CT, allowing defense equipment to refresh much faster. While the ATK debuff can look useful on paper, most bosses come with innate ATK up which will counter this, but hey, any bit of survivability helps`,
                truearts: `The evasion debuff on enemies can reduce/remove the need for accuracy buffs, and the PHY RES down helps Diablo and his Physical damaging allies to deal a lot more damage.`,
                superarts: `An acceptable multiplier for a super art, but the main draw of this would be the high Dark Res down it applies, since the 120% reduction in enemies Dark RES will allow Diablo and other Dark units to deal significantly more damage. The chance to blind on this also pairs up well with his passive allowing him to get some dmg up too.`,
                ability1: `Instead of giving himself artgen, Diablo supports the team further by providing an insane 6 arts/s to units beside him. It should be noted that you’ll need Diablo in slot 2 or 3 to get the most out of this. The passive does require Diablo to be at a high HP of 90% or above, which can be hard to maintain depending on the team.`,
                ability2: `Status resistance as an extra on the side is again, always appreciated.`,
                ability3: `The damage increase from this passive is quite notable and as previously mentioned, it primarily works in conjunction with his Super Arts’ ability to inflict Blind, or any other sources of Blind from the team.`,
                trueweapon: {
                    skill: `Although 30% DMG Res might sound good at first, this equip does not have good uptime at all even with his DEF Equip CT down buffs, and the unique passive buffs for Diablo is also very minor.`,
                    ability1: `With this, Diablo has a larger HP window and is able to tank more damage before his passive deactivates, however it is not difficult to maintain the >90% condition, and the lackluster passive & active alone is not good enough to warrant using his TW over another defense equip.`,
                    ability2: `Minor PHY RES buff that is nice to have.`
                },
                overall: `Diablo is a great unit with good defensive slots who can support the team well with his RES down and 6 arts per second passive to neighbouring units. The supporting capabilities in his kit can allow him to be quite flexible in teams, but just like Demon Lord Rimuru, his potential shines within Mono Demon Dark teams where his kit can be utilised to the fullest. `,
                lastupdated: `December 15, 2023`,
                author: `damino_`
            }
    },
    { //Sunraku - Complete + JP ver
        id: 107867112,
        name: `Sunraku`,
        attribute: `Fire`,
        rank: `SS`,
        luckrank: `SS`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107866112.png",
                detailsuper: "/db/Units/Detail/unit_detail_107867112.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_786_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107866112.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107867112.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_786_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/physLB.png",
                slot32type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 6820,
                hpplus: 1290,
                atk: 4500,
                atkplus: 720,
                def: 3024,
                defplus: 626
            },
        skillset: 
            {
                skill: `11s CT - 2500% Fire DMG (PHY). Increase own Arts by 30. If this Skill crits, increase own Arts by 60 instead.`,
                skillbreak: 500,
                arts: `27000% Fire DMG (PHY). For 25s, reduce enemies' PHY RES and Evasion by 50%.`,
                artsbreak: 800,
                truearts: `70000% Fire DMG (PHY). For 25s, increases allies' Arts by 6/s and reduces enemies' CRI RES by 100%.`,
                trueartsbreak: 1300,
                superarts: `160000% Fire DMG (PHY). Increase own CRI rate by 100% and Super CRI rate by 50% for the duration of Super Arts. Permanently increases own ATK by 50% (MAX: 200%).`,
                superartsbreak: 2000
            },
        skillsetJP: 
            {
                skill: `11s CT - 2500% Fire DMG (PHY). Increase own Arts by 30. If this Skill crits, increase own Arts by 60 instead.`,
                skillbreak: 500,
                arts: `27000% Fire DMG (PHY). For 30s, reduce enemies' PHY RES and Evasion by 50%.`,
                artsbreak: 800,
                truearts: `75000% Fire DMG (PHY). For 25s, increases allies' Arts by 6/s and reduces enemies' CRI RES by 100%.`,
                trueartsbreak: 1300,
                superarts: `170000% Fire DMG (PHY). Increase own CRI rate by 100% and Super CRI rate by 50% for the duration of Super Arts. Permanently increases own ATK by 50% (MAX: 200%).`,
                superartsbreak: 2000
            },
        passive: 
            {
                ability1: `Increase Fire allies' DMG RES by 15%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Evasion by 20%.`,
                ability3: `Increase own Skill CT Speed by 50% when Near-Death.`,
                ability4: `Unit EXP UP 20% UP. (When Sunraku's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Sunraku's SP. Art is owned)`
            }, 
        passiveJP: 
            {
                ability1: `Increase Fire allies' DMG RES by 15%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Evasion by 20%.`,
                ability3: `Increase own Skill CT Speed by 60% when Near-Death.`,
                ability4: `Unit EXP UP 20% UP. (When Sunraku's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Sunraku's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_130853.png",
                    mat2amt: 100,
                    hover2: "Flametail Soulgem"
                }
            },
        lore: 
            {   
                evoawk: `In real life, he is a high schooler named Rakuro Hizutome. He loves broken, bug-riddled games so much that he earned the nickname "Kusoge (crappy game) Hunter", but he started playing a high-quality full-dive VR game "Shangri-La Frontier" at a friend's recommendation. His job is Mercenary (Twin Swords) and race is Human, and he is armed with nothing but his weapons and a funny-looking bird mask.`,
                evosuper: `He has outstanding skills and razor-sharp reactions from mastering so many unfairly balanced games. In "Shangri-La Frontier", they earned him the cursed marks of the Unique Monster Lycagon the Nightslayer, setting him on a course unseen by any other player.`
            }
    },
    { //Psyger-0 - Complete + JP ver
        id: 107877412,
        name: `Psyger-0`,
        attribute: `Light`,
        rank: `S`,
        luckrank: `A`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107876412.png",
                detailsuper: "/db/Units/Detail/unit_detail_107877412.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_787_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107876412.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107877412.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_787_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/defLB.png",
                slot32type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7040,
                hpplus: 1330,
                atk: 4125,
                atkplus: 710,
                def: 3045,
                defplus: 630
            },
        skillset: 
            {
                skill: `14s CT - 5000% Light DMG (PHY). Increase own Arts by 20. For 8s, increase own Accuracy by 20%.`,
                skillbreak: 500,
                arts: `30000% Light DMG (PHY). Increase all allies' Arts by 20. For 20s, Allies gain a DMG barrier that negates 5000 DMG.`,
                artsbreak: 900,
                truearts: `70000% Light DMG (PHY). For 25s, increases own DMG by 100% and all allies' DMG by [30% x # of Humans in the Party].`,
                trueartsbreak: 1400,
                superarts: `200000% Light DMG (PHY). Ignore 100% of Enemies' DEF for the duration of Super Arts.`,
                superartsbreak: 1800
            },
        skillsetJP: 
            {
                skill: `14s CT - 5000% Light DMG (PHY). Increase own Arts by 20. For 8s, increase own Accuracy by 20%.`,
                skillbreak: 500,
                arts: `30000% Light DMG (PHY). Increase all allies' Arts by 20. For 25s, Allies gain a DMG barrier that negates 5000 DMG.`,
                artsbreak: 900,
                truearts: `70000% Light DMG (PHY). For 30s, increases own DMG by 100% and all allies' DMG by [30% x # of Humans in the Party].`,
                trueartsbreak: 1400,
                superarts: `220000% Light DMG (PHY). Ignore 100% of Enemies' DEF for the duration of Super Arts.`,
                superartsbreak: 1800
            },
        passive: 
            {
                ability1: `When using Super Arts for the first time, double its damage multiplier to 400000% instead. When using Super Arts for the second time and onwards, for 20s, increase own Arts by 5/s.`,
                ability2: `Increase own Defence Equip CT Speed by 20%.`,
                ability3: `Increase own Paralysis RES by 100%.`,
                ability4: `Unit EXP UP 20% UP. (When Psyger-0's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Psyger-0's SP. Art is owned)`
            }, 
        passiveJP: 
            {
                ability1: `When using Super Arts for the first time, double its damage multiplier to 440000% instead. When using Super Arts for the second time and onwards, for 25s, increase own Arts by 6/s.`,
                ability2: `Increase own Defence Equip CT Speed by 20%.`,
                ability3: `Increase own Paralysis RES by 100%.`,
                ability4: `Unit EXP UP 20% UP. (When Psyger-0's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Psyger-0's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131354.png",
                    mat2amt: 100,
                    hover2: "Eternaglint Soulgem"
                }
            },
        lore: 
            {   
                evoawk: `In the full-dive VR game "Shangri-La Frontier", she is one of the strongest players with the title of "Attack Master". Instantly recognizable for her white armor and black sword, she prefers to let her actions speak for themselves.`,
                evosuper: `Despite her reputation as a powerful warrior in "Shangri-La Frontier", she is a high schooler named Rei Saiga in real life. Hoping to form a closer bond with her classmate Rakuro, she seeks out his character Sunraku.`
            }
    },
    { //Arthur Pencilgon - Complete + JP ver
        id: 107887512,
        name: `Arthur Pencilgon`,
        attribute: `Dark`,
        rank: `SS`,
        luckrank: `S`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107886512.png",
                detailsuper: "/db/Units/Detail/unit_detail_107887512.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_788_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107886512.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107887512.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_788_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7150,
                hpplus: 1350,
                atk: 3680,
                atkplus: 690,
                def: 3197,
                defplus: 606
            },
        skillset: 
            {
                skill: `12s CT - 2500% Dark DMG (PHY). For 10s, increase own Arts by 4/s.`,
                skillbreak: 1000,
                arts: `17000% Dark DMG (MAG). For 35s, reduce enemies' Break RES by 40%.`,
                artsbreak: 3000,
                truearts: `52000% Dark DMG (PHY). For 25s, reduce enemies' Dark RES by 80% and increase >50% HP Allies' Arts by 5/s.`,
                trueartsbreak: 4000,
                superarts: `130000% Dark DMG (PHY). When Super Arts is used right after Arts has been used, increase current Super Arts' Break Power by 150%. When Super Arts is used right after True Arts has been used, increase current Super Arts' DMG by 300%.`,
                superartsbreak: 12000
            },
        skillsetJP: 
            {
                skill: `12s CT - 2500% Dark DMG (PHY). For 10s, increase own Arts by 4/s.`,
                skillbreak: 1000,
                arts: `17000% Dark DMG (MAG). For 40s, reduce enemies' Break RES by 40%.`,
                artsbreak: 3000,
                truearts: `52000% Dark DMG (PHY). For 30s, reduce enemies' Dark RES by 80% and increase >50% HP Allies' Arts by 5/s.`,
                trueartsbreak: 5000,
                superarts: `130000% Dark DMG (PHY). When Super Arts is used right after Arts has been used, increase current Super Arts' Break Power by 200%. When Super Arts is used right after True Arts has been used, increase current Super Arts' DMG by 350%.`,
                superartsbreak: 12000
            },
        passive: 
            {
                ability1: `When boss is broken, resets all allies' PHY Equip CT. Does not stack with Abilities of the same name.`,
                ability2: `Increase DMG to Enemies with 30% or less HP by 50%.`,
                ability3: `Increase DMG to Humans by 20%.`,
                ability4: `Unit EXP UP 20% UP. (When Arthur Pencilgon's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Arthur Pencilgon's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131455.png",
                    mat2amt: 100,
                    hover2: "Sinflow Soulgem"
                }
            },
        lore: 
            {   
                evoawk: `In the full-dive VR game "Shangri-la Frontier", she is second-in-command of the PK clan Asura Kai. Her ability to ambush and defeat stronger players has given her the nickname "Giant Killer". Her main job is Magic Lancer, but her sub-job is unknown.`,
                evosuper: `She has a history with Sunraku and OiKatzo from previous games. Her reign as the Dystopian Empress in one particular MMORPG ended when the two of them led a revolution that unseated her from power.`
            }
    },
    { //OiKatzo - Complete + JP ver
        id: 107897112,
        name: `OiKatzo`,
        attribute: `Fire`,
        rank: `S`,
        luckrank: `A`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107896112.png",
                detailsuper: "/db/Units/Detail/unit_detail_107897112.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_789_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107896112.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107897112.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_789_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/physLB.png",
                slot32type: "/db/Mines/thumbnail/magLB.png"
            },
        stats: 
            {
                hp: 7425,
                hpplus: 1400,
                atk: 3937,
                atkplus: 680,
                def: 2845,
                defplus: 592
            },
        skillset: 
            {
                skill: `10s CT - 4300% Fire DMG (PHY). 10% chance to inflict Faint for 6s.`,
                skillbreak: 500,
                arts: `21000% Fire DMG (MAG). For 20s, reduce enemies' Fire RES by 20% (stackable) and increase all allies' stats by 30%.`,
                artsbreak: 1000,
                truearts: `60000% Fire DMG (MAG). For 25s, increase own Accuracy by 150%. When ownself is above 80% HP, for 25s, reduce enemies' DMG RES by 50%. When ownself is above 50% HP, for 25s, increase own Arts by 5/s.`,
                trueartsbreak: 1600,
                superarts: `170000% Fire DMG (MAG). For 25s, increase all allies' Freeze RES and Paralysis RES by 100%. When ownself is above 80% HP, for 25s, reduce enemies' MAG RES by 150%.`,
                superartsbreak: 2000
            },
        skillsetJP: 
            {
                skill: `10s CT - 4300% Fire DMG (PHY). 10% chance to inflict Faint for 6s.`,
                skillbreak: 500,
                arts: `25000% Fire DMG (MAG). For 20s, reduce enemies' Fire RES by 20% (stackable) and increase all allies' stats by 30%.`,
                artsbreak: 1000,
                truearts: `65000% Fire DMG (MAG). For 30s, increase own Accuracy by 150%. Additionally if used when ownself is above 80% HP, for 30s, reduce enemies' DMG RES by 70%. Additionally if used when ownself is above 50% HP, for 30s, increase own Arts by 5/s.`,
                trueartsbreak: 1600,
                superarts: `170000% Fire DMG (MAG). For 30s, increase all allies' Freeze RES and Paralysis RES by 100%. Additionally if used when ownself is above 80% HP, for 30s, reduce enemies' MAG RES by 150%.`,
                superartsbreak: 2000
            },
        passive: 
            {
                ability1: `When Near-Death, increase own Arts by 200 and heal own HP by 100%. Can only be activated once per Quest.`,
                ability2: `Increase own DMG by [2% x own ATK and DEF/100].`,
                ability3: `When above 80% HP, increase own Arts by 4/s.`,
                ability4: `Unit EXP UP 20% UP. (When OiKatzo's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When OiKatzo's SP. Art is owned)`
            }, 
        passiveJP: 
            {
                ability1: `When Near-Death, increase own Arts by 200 and heal own HP by 100%. Can only be activated once per Quest.`,
                ability2: `Increase own DMG by [3% x own ATK and DEF/100].`,
                ability3: `When above 80% HP, increase own Arts by 4/s.`,
                ability4: `Unit EXP UP 20% UP. (When OiKatzo's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When OiKatzo's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_130853.png",
                    mat2amt: 100,
                    hover2: "Flametail Soulgem"
                }
            },
        lore: 
            {   
                evoawk: `The alter-ego of professional gamer Kei Uomi in the full-dive VR game "Shangri-La Frontier". When he saw how much Sunraku was enjoying it, he decided to join the action. His job is Monk with an Archaeologist sub-job.`,
                evosuper: `Making headlines as a professional gamer, his forte is fighting games. This means he even enjoys broken, buggy ones like Berserk Online Passion in his off-time. That predilection can be seen in his character choice of Monk with a close-quarters HP/VIT build.`
            }
    },
    { //Momon - Missing Lore
        id: 107907512,
        name: `Momon`,
        attribute: `Dark`,
        type: `Demon`,
        rank: `S`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107906512.png",
                detailsuper: "/db/Units/Detail/unit_detail_107907512.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_790_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107906512.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107907512.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_790_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/defLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 6935,
                hpplus: 1311,
                atk: 4060,
                atkplus: 699,
                def: 3157,
                defplus: 651
            },
        skillset: 
            {
                skill: `11s CT - 2300% Dark DMG (PHY). Increase own Arts by 40.`,
                skillbreak: 600,
                arts: `25000% Dark DMG (PHY). For 40s, increase own CRI rate & Ally to the right of Momon's CRI rate by 100%.`,
                artsbreak: 600,
                truearts: `65000% Water DMG (PHY). For 25s, increases all allies' PHY DMG by 150% and Accuracy by 50%.`,
                trueartsbreak: 1200,
                superarts: `180000% Dark DMG (PHY). For 15s, reduce enemies' Dark RES by [50% x # of Demon Allies in the party] (MAX: 200%).`,
                superartsbreak: 1500
            },
        passive: 
            {
                ability1: `When own HP is 80% or higher, increase own DMG & Ally to the right of Momon's DMG by 250%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Curse RES by 80%.`,
                ability3: `Increase own Blind RES by 80%.`,
                ability4: `Unit EXP UP 20% UP. (When Momon's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Momon's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131455.png",
                    mat2amt: 100,
                    hover2: "Sinflow Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Momon's Greatsword`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_153201.png",
                skill: `40s CT - 8000% Dark DMG (PHY). For 10s, increase own Dark DMG & Skill CT Speed by 50%.`,
                skillbreak: 500,
                passive: 
                    {
                        ability1: `When equipped by Momon, increase own Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own ATK by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    { //Demiurge - Complete
        id: 107917112,
        name: `Demiurge`,
        attribute: `Fire`,
        type: `Demon`,
        rank: `A`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107916112.png",
                detailsuper: "/db/Units/Detail/unit_detail_107917112.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_791_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107916112.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107917112.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_791_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/healLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7200,
                hpplus: 1330,
                atk: 3335,
                atkplus: 643,
                def: 3336,
                defplus: 630
            },
        skillset: 
            {
                skill: `12s CT - 3500% Fire DMG (MAG). For 8s, increase own Arts by 5/s.`,
                skillbreak: 500,
                arts: `25000% Fire DMG (MAG). For 30s, reduce enemies' MAG RES by 50%.`,
                artsbreak: 1200,
                truearts: `For 30s, increase all allies' Status RES (except Faint) by 100% and allies gain a DMG barrier that negates 10000 DMG.`,
                trueartsbreak: 0,
                superarts: `130000% Fire DMG (MAG). For 80s, increase Fire allies' DMG by 50% (stackable). If Super Arts is used when own HP is above 80%, increase own Arts by 100.`,
                superartsbreak: 2000
            },
        passive: 
            {
                ability1: `When unaffected by Status ailments, increase own DMG by 80% and Equipment CT Speed by 20%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Heal Amount by 30%.`,
                ability3: `Increase DMG to Human enemies by 50%.`,
                ability4: `Unit EXP UP 20% UP. (When Demiurge's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Demiurge's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_130853.png",
                    mat2amt: 100,
                    hover2: "Flametail Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Demiurge's Suit`,
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_153211.png",
                skill: `50s CT - 6000% Fire DMG (MAG). For 10s, increase own Fire DMG by 60%. When used for the first time, increase own Arts by 30.`,
                skillbreak: 300,
                passive: 
                    {
                        ability1: `When equipped by Demiurge, increase own Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own DEF by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: `Guardian of the Great Tomb of Nazarick's seventh floor. He was created by Ulbert, the most committed to evil among the forty-one guild members. With the sharpest mind of Nazarick's NPCs, he devises all number of schemes and strategies.`,
                evosuper: `As the brains of Nazarick, he enacts an intricate web of plots for the sake of his sworn master Ainz Ooal Gown. He shows no mercy to anyone who interferes, even his own peers.`
            }
    },
    { //Evileye - Missing Lore
        id: 107927312,
        name: `Evileye`,
        attribute: `Earth`,
        type: `Demon`,
        rank: "S",
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107926312.png",
                detailsuper: "/db/Units/Detail/unit_detail_107927312.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_792_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107926312.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107927312.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_792_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7425,
                hpplus: 1370,
                atk: 3352,
                atkplus: 646,
                def: 3141,
                defplus: 596
            },
        skillset: 
            {
                skill: `12s CT - 2000% Earth DMG (MAG). Increase own Arts by 20. If unaffected by Status Ailments, increase own Arts by 40 instead.`,
                skillbreak: 500,
                arts: `For 25s, increase all allies' DMG RES by 20% and Earth allies' DMG RES by an additional 20%.`,
                artsbreak: 0,
                truearts: `50000% Earth DMG (MAG). For 60s, increase own ATK by 150% and Freeze RES & Paralysis RES by 100%.`,
                trueartsbreak: 1600,
                superarts: `150000% Earth DMG (MAG). For 20s, increase all allies' Arts by 6/s and MAG DMG by 200%.`,
                superartsbreak: 2000
            },
        passive: 
            {
                ability1: `When self is Leader, for 100s, increase Earth Allies' DMG by 100%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Disease RES by 80%.`,
                ability3: `Increase own DMG to Beast enemies by 50%.`,
                ability4: `Unit EXP UP 20% UP. (When Evileye's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Evileye's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                    mat2amt: 100,
                    hover2: `Smogdragon Soulgem`
                }
            },
        trueweapon: 
            {
                name: `Evileye's Mask`,
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_153221.png",
                skill: `50s CT - 8000% Earth DMG (MAG). For 10s, reduce enemies' Earth RES by 30%. If the user is Demon, reduce enemies' Earth RES by 50% instead.`,
                skillbreak: 500,
                passive: 
                    {
                        ability1: `When equipped by Evileye, increase own Arts by 100 at the start of Quest. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own PHY RES by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    { //Uzu Sanageyama - Missing Lore
        id: 107937312,
        name: `Uzu Sanageyama`,
        attribute: `Earth`,
        type: `Human`,
        rank: `S`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107936312.png",
                detailsuper: "/db/Units/Detail/unit_detail_107937312.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_793_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107936312.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107937312.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_793_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/physLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7370,
                hpplus: 1390,
                atk: 4000,
                atkplus: 690,
                def: 2856,
                defplus: 594
            },
        skillset: 
            {
                skill: `13s CT - 2000% Earth DMG (PHY). Increase own Arts by 30. If this Skill crits, increase own Arts by 60 instead.`,
                skillbreak: 600,
                arts: `30000% Earth DMG (PHY). For 35s, increase own DMG and Skill CT Speed by 130%.`,
                artsbreak: 1000,
                truearts: `80000% Earth DMG (PHY). Each time True Arts is used, permanently increase own Super CRI rate by 25% (MAX: 75%).`,
                trueartsbreak: 1500,
                superarts: `190000% Earth DMG (PHY). For 25s, increase own CRI rate by 100% and reduce enemies' PHY RES by 150%.`,
                superartsbreak: 1800
            },
        passive: 
            {
                ability1: `When unaffected by Status ailments, increase own ATK and Accuracy by 100%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Blind RES by 100%.`,
                ability3: `When near-death, increase own Arts by 2/s.`,
                ability4: `Unit EXP UP 20% UP. (When Uzu's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Uzu's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                    mat2amt: 100,
                    hover2: `Smogdragon Soulgem`
                }
            },
        trueweapon: 
            {
                name: `Blade Regalia`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_153361.png",
                skill: `50s CT - 8000% Earth DMG (PHY). For 10s, reduce enemies' Earth RES by 30%. If user is Human, reduce enemies' Earth RES by 50% instead.`,
                skillbreak: 500,
                passive: 
                    {
                        ability1: `When equipped by Uzu Sanageyama, increase own CRI DMG by 70%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own DEF by 10%.`
                    }
            },
        lore: 
            {   
                evoawk: `Missing Lore.`,
                evosuper: `Missing Lore.`
            }
    },
    { //Houka Inumuta - Missing Lore
        id: 107947212,
        name: `Houka Inumuta`,
        attribute: `Water`,
        type: `Human`,
        rank: `SSS`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107946212.png",
                detailsuper: "/db/Units/Detail/unit_detail_107947212.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_794_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107946212.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107947212.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_794_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 8395,
                hpplus: 1510,
                atk: 2420,
                atkplus: 490,
                def: 3450,
                defplus: 650
            },
        skillset: 
            {
                skill: `12s CT - Increase own Arts by 40. Heal ally with the lowest HP by 3000.`,
                skillbreak: 0,
                arts: `18000% Water DMG (MAG). For 20s, increase all allies' Accuracy by 50% and all Human allies' ATK by 100%.`,
                artsbreak: 500,
                truearts: `55000% Water DMG (MAG). For 25s, reduce enemies' CRI RES by 100% and Water RES by 80%.`,
                trueartsbreak: 1600,
                superarts: `For 25s, increase all allies' Arts by 7/s and CRI DMG by 300%. Increase Leader's Arts by an additional 3/s for 25s.`,
                superartsbreak: 0
            },
        passive: 
            {
                ability1: `When own HP is 80% or above, increase own Equipment CT Speed by 20%. Does not stack with Abilities of the same name.`,
                ability2: `Increase all allies' Max HP by 15%.`,
                ability3: `Increase own Evasion by 10%.`,
                ability4: `Unit EXP UP 20% UP. (When Houka's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Houka's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_132152.png",
                    mat2amt: 100,
                    hover2: "Frostsprite Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Probe Regalia`,
                slot: "/db/Mines/thumbnail/suppLB.png",
                detail: "/db/Equipment/Detail/item_detail_153371.png",
                skill: `35s CT - For 10s, increase all allies' Arts by 3/s and Human allies' stats by 15%.`,
                skillbreak: 0,
                passive: 
                    {
                        ability1: `When equipped by Houka Inumuta, increase own Arts by 100 at Start of Quest. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own DEF by 10%.`
                    }
            },
        lore: 
            {   
                evoawk: `Missing Lore.`,
                evosuper: `Missing Lore.`
            }
    },
    { //Ira Gamagoori - Missing Lore
        id: 107957512,
        name: `Ira Gamagoori`,
        attribute: `Dark`,
        type: `Human`,
        rank: `S`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107956512.png",
                detailsuper: "/db/Units/Detail/unit_detail_107957512.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_795_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107956512.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107957512.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_795_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/defLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/defLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7700,
                hpplus: 1450,
                atk: 2730,
                atkplus: 570,
                def: 3625,
                defplus: 630
            },
        skillset: 
            {
                skill: `12s CT - 3000% Dark DMG (PHY). Increase own Arts by 40.`,
                skillbreak: 700,
                arts: `25000% Dark DMG (PHY). For 15s, reduce enemies' Dark RES by 50%.`,
                artsbreak: 1000,
                truearts: `50000% Dark DMG (PHY). For 30s, consume 2%/s of own Max HP. For 40s, increase own PHY RES by 30% and redirect Allies' DMG taken to self and gain a DMG barrier that negates 35000 DMG to self.`,
                trueartsbreak: 1400,
                superarts: `150000% Dark DMG (PHY). Ignores 100% of enemies' DEF for the duration of Super Arts. For 40s, increase own Freeze RES and Paralysis RES by 100%.`,
                superartsbreak: 2000
            },
        passive: 
            {
                ability1: `Increase own DMG the lower own HP is (MAX: 200%). When taking lethal DMG for the first time, heal own HP by 50%. Does not stack with Abilities of the same name.`,
                ability2: `When Near-death, increase own Equipment CT Speed by 20%.`,
                ability3: `When Near-death, increase own DMG RES by 20%.`,
                ability4: `Unit EXP UP 20% UP. (When Ira's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Ira's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131455.png",
                    mat2amt: 100,
                    hover2: "Sinflow Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Shackle Regalia`,
                slot: "/db/Mines/thumbnail/defLB.png",
                detail: "/db/Equipment/Detail/item_detail_153381.png",
                skill: `30s CT - For 15s, increase own DMG RES by 20%. If used when own HP is below 50%, increase own DMG RES by 30% instead.`,
                skillbreak: 0,
                passive: 
                    {
                        ability1: `When equipped by Ira Gamagoori, increase own Arts by 100 at Start of Quest. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own DEF by 10%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    { //Nonon Jakuzure - Missing Lore
        id: 107967412,
        name: `Nonon Jakuzure`,
        attribute: `Light`,
        type: `Human`,
        rank: `A+`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107966412.png",
                detailsuper: "/db/Units/Detail/unit_detail_107967412.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_796_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107966412.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107967412.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_796_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/healLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7423,
                hpplus: 1399,
                atk: 3788,
                atkplus: 656,
                def: 2821,
                defplus: 606
            },
        skillset: 
            {
                skill: `13s CT - 2000% Light DMG (MAG). For 8s, increase own Arts by 4/s. Additionally, increase own Arts by 20 if own Skill hits the Boss (1 HIT).`,
                skillbreak: 500,
                arts: `25000% Light DMG (MAG). Each time own Arts is used, permanently increase Light allies' DMG by 40% (MAX: 200%).`,
                artsbreak: 1000,
                truearts: `70000% Light DMG (MAG). Increase own DMG against non-Boss enemies by 200% for the duration of own True Arts. For 35s, increase allies' ATK by 100%.`,
                trueartsbreak: 1800,
                superarts: `160000% Light DMG (MAG). For 25s, reduce enemies' MAG RES by 130% and Break RES by 50%.`,
                superartsbreak: 3000
            },
        passive: 
            {
                ability1: `At the start of Battle (Wave), increase Light allies' Arts by 25 and Human allies' Arts by an additional 25. Does not stack with Abilities of the same name.`,
                ability2: `Increase own DMG by [2 x own ATK/100]%.`,
                ability3: `Increase own HP Recovery by 10%.`,
                ability4: `Unit EXP UP 20% UP. (When Nonon's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Nonon's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                    mat2amt: 100,
                    hover2: `Eternaglint Soulgem`,
                }
            },
        trueweapon: 
            {
                name: `Symphony Regalia`,
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_153391.png",
                skill: `60s CT - 8000% Light DMG (MAG). For 10s, reduce enemies' DMG RES by 20%. If user is Light, reduce enemies' DMG RES by an additional 10%. If user is Human, reduce enemies' DMG RES by an additional 10%.`,
                skillbreak: 500,
                passive: 
                    {
                        ability1: `When equipped by Nonon Jakuzure, increase own ATK and Skill CT Speed by 50%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own DEF by 10%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    { //Summer Illya - Missing Lore
        id: 107977212,
        name: `Summer Illya`,
        attribute: `Water`,
        rank: `S`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107976212.png",
                detailsuper: "/db/Units/Detail/unit_detail_107977212.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_797_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107976212.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107977212.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_797_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/healLB.png"
            },
        stats: 
            {
                hp: 7150,
                hpplus: 1350,
                atk: 3968,
                atkplus: 685,
                def: 3039,
                defplus: 629
            },
        skillset: 
            {
                skill: `12s CT - 2500% Water DMG (MAG). For 7s, increase own Arts by 5/s.`,
                skillbreak: 600,
                arts: `20000% Water DMG (MAG). For 20s, increase own ATK by 100% and reduce enemies' Evasion rate by 50%.`,
                artsbreak: 1200,
                truearts: `70000% Water DMG (MAG). Heal all allies' Burn. For 45s, increase allies' Water DMG by 100% and Burn RES by 100%.`,
                trueartsbreak: 2000,
                superarts: `150000% Water DMG (MAG). Increase own Arts by [25 x # of times Super Arts has been used] (MAX: 150). For 30s, reduce enemies' MAG RES by 100% (stackable).`,
                superartsbreak: 2800
            },
        passive: 
            {
                ability1: `When own HP is above 80%, increase own DMG by 100% and increase own Freeze/Paralysis RES by 100%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own MAG RES by 10%.`,
                ability3: `Increase own BE Output by 20%.`,
                ability4: `Unit EXP UP 20% UP. (When Summer Illya's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Summer Illya's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_132152.png",
                    mat2amt: 100,
                    hover2: "Frostsprite Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Illya's Swimsuit`,
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_153401.png",
                skill: `55s CT - 8000% Water DMG (MAG). For 10s, increase own MAG DMG by 50%. If user is Water, increase own MAG DMG by 100% instead.`,
                skillbreak: 800,
                passive: 
                    {
                        ability1: `When equipped by Summer Illya, increase own DMG by 30% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own ATK by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    { //Summer Miyu - Missing Lore
        id: 107987112,
        name: `Summer Miyu`,
        attribute: `Fire`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107986112.png",
                detailsuper: "/db/Units/Detail/unit_detail_107987112.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_798_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107986112.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107987112.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_798_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/physLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7150,
                hpplus: 1350,
                atk: 3968,
                atkplus: 685,
                def: 3039,
                defplus: 629
            },
        skillset: 
            {
                skill: `12s CT - 2000% Fire DMG (PHY). For 7s. increase own Accuracy by 30%.`,
                skillbreak: 700,
                arts: `28000% Fire DMG (PHY). For 15s, reduce enemies' Fire RES by 60%.`,
                artsbreak: 1200,
                truearts: `65000% Fire DMG (PHY). Increase allies' Arts by 30. For 50s, increase allies' PHY DMG by 80% (stackable).`,
                trueartsbreak: 1500,
                superarts: `170000% Fire DMG (PHY). Increase own CRI rate by 100% and Super CRI rate by 50% for the duration of Super Arts.`,
                superartsbreak: 2000
            },
        passive: 
            {
                ability1: `At the Start of Battle, increase own Arts by 200 and ATK by 50% (MAX: 300%). Does not stack with Abilities of the same name.`,
                ability2: `Increase own Paralysis RES by 100%.`,
                ability3: `Increase own MAG RES by 10%.`,
                ability4: `Unit EXP UP 20% UP. (When Summer Miyu's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Summer Miyu's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_130853.png",
                    mat2amt: 100,
                    hover2: "Flametail Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Miyu's Swimsuit`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_153411.png",
                skill: `50s CT - 5000% Fire DMG (PHY). For 10s, reduce enemies' Fire RES by 40% and increase own ATK by 40%.`,
                skillbreak: 500,
                passive: 
                    {
                        ability1: `When equipped by Summer Miyu, increase own Arts by 3/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own PHY RES by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    { //Summer Chloe - Missing Lore
        id: 107997412,
        name: `Summer Chloe`,
        attribute: `Light`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_107996412.png",
                detailsuper: "/db/Units/Detail/unit_detail_107997412.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_799_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_107996412.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_107997412.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_799_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 8050,
                hpplus: 1450,
                atk: 2970,
                atkplus: 590,
                def: 3220,
                defplus: 610
            },
        skillset: 
            {
                skill: `12s CT - 1200% Light DMG (PHY). For 7s, reduce enemies' DMG RES by 20%.`,
                skillbreak: 800,
                arts: `22000% Light DMG (PHY). For 15s, increase all allies' Status RES (except Faint) by 100% and reduce enemies' Light RES by 50%.`,
                artsbreak: 1500,
                truearts: `50000% Light DMG (PHY). For 20s, increase all allies' HP Recovery amount by 100% and Arts by 5/s. For 20s, increase Light allies' Arts by an additional 2/s.`,
                trueartsbreak: 2200,
                superarts: `130000% Light DMG (PHY). For 25s, increase allies' DMG by 150% and Human allies' DMG by 100%.`,
                superartsbreak: 2800
            },
        passive: 
            {
                ability1: `Heal all allies' HP by 400/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Accuracy by 30%.`,
                ability3: `Increase own MAG RES by 10%.`,
                ability4: `Unit EXP UP 20% UP. (When Summer Chloe's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Summer Chloe's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                    mat2amt: 100,
                    hover2: `Eternaglint Soulgem`,
                }
            },
        trueweapon: 
            {
                name: `Chloe's Swimsuit`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_153421.png",
                skill: `25s CT - 5000% Light DMG (PHY). For 15s, reduce enemies' Light RES by 15% (stackable). If user is Human, increase own Arts by 10.`,
                skillbreak: 500,
                passive: 
                    {
                        ability1: `When equipped by Summer Chloe, increase own Arts by 100 at the start of Quest. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own ATK by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }  
    },
    { //Summer Bazett - Missing Lore
        id: 108007212,
        name: `Summer Bazett`,
        attribute: `Water`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_108006212.png",
                detailsuper: "/db/Units/Detail/unit_detail_108007212.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_800_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_108006212.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_108007212.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_800_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/healLB.png",
                slot32type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7299,
                hpplus: 1377,
                atk: 3937,
                atkplus: 680,
                def: 2941,
                defplus: 610
            },
        skillset: 
            {
                skill: `12s CT - 2700% Water DMG (PHY). Increase own Arts by 50. For 8s, increase own Paralysis RES and Freeze RES by 100%.`,
                skillbreak: 500,
                arts: `30000% Water DMG (PHY). For 20s, increase all allies' stats by 30% and PHY RES by 20%.`,
                artsbreak: 900,
                truearts: `80000% Water DMG (PHY). When True Arts is used, reduce own remaining DEF Equip CT by half. For 20s, reduce enemies' Water RES by 80%.`,
                trueartsbreak: 1800,
                superarts: `160000% Water DMG (PHY). Increase own Super Arts DMG by 250% and Accuracy by 100% for the duration of Super Arts. If Super Arts is used while enemy is using Arts, increase own Super Arts DMG by 500% and Accuracy by 200% for the duration of Super Arts instead.`,
                superartsbreak: 2100
            },
        passive: 
            {
                ability1: `Reduce own Seal RES by 100%. When own HP is 70% or higher, ignore 100% of enemies' DEF. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Arts by 50 at the Start of Quest.`,
                ability3: `Increase own Evasion rate by 20%.`,
                ability4: `Unit EXP UP 20% UP. (When Summer Bazett's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Summer Bazett's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_132152.png",
                    mat2amt: 100,
                    hover2: "Frostsprite Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Bazett's Swimsuit`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_153441.png",
                skill: `50s CT - 12000% Water DMG (PHY). If user is Water, for 10s, reduce enemies' DMG RES by 20%. If user is Water and when own HP is above 80%, reduce enemies' DMG RES by 35% instead.`,
                skillbreak: 800,
                passive: 
                    {
                        ability1: `When equipped by Summer Bazett, increase own DMG by 50% and Skill CT Speed by 30%. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own DEF by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }  
    },
    { //Summer Luvia & Rin - Missing Lore
        id: 108017312,
        name: `Summer Luvia & Rin`,
        attribute: `Earth`,
        rank: `SS`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_108016312.png",
                detailsuper: "/db/Units/Detail/unit_detail_108017312.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_801_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_108016312.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_108017312.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_801_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/suppLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/healLB.png",
                slot32type: "/db/Mines/thumbnail/physLB.png"
            },
        stats: 
            {
                hp: 7368,
                hpplus: 1360,
                atk: 3260,
                atkplus: 629,
                def: 3244,
                defplus: 614
            },
        skillset: 
            {
                skill: `12s CT - 2000% Earth DMG (PHY). Increase own Arts by 30.`,
                skillbreak: 800,
                arts: `27000% Earth DMG (PHY). For 20s, increase allies' PHY RES by 20% and reduce enemies' PHY RES by 40%.`,
                artsbreak: 1300,
                truearts: `60000% Earth DMG (PHY). Consumes 30% of own MAX HP. For 25s, increase all allies' PHY DMG by 220%.`,
                trueartsbreak: 2200,
                superarts: `155000% Earth DMG (PHY). For 20s, reduce enemies' DMG RES by 100%. Additionally if used when unaffected by Status ailments, for 20s, reduce enemies' Earth RES by 100%.`,
                superartsbreak: 2800
            },
        passive: 
            {
                ability1: `When Near-Death, increase all allies' Arts by 200. Additionally, for 15s, increase allies' DMG RES by 30%. Can only be activated once per quest.`,
                ability2: `Increase own HP Recovery Amount by 15%.`,
                ability3: `Increase own Burn RES by 30%.`,
                ability4: `Unit EXP UP 20% UP. (When Summer Luvia & Rin's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Summer Luvia & Rin's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_130653.png`,
                    mat2amt: 100,
                    hover2: `Smogdragon Soulgem`
                }
            },
        trueweapon: 
            {
                name: `Luvia's Swimsuit`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_153431.png",
                skill: `40s CT - 9000% Earth DMG (PHY). Increase own Arts by 30. Additionally if user is Earth, for 10s, increase all stats by 30%.`,
                skillbreak: 800,
                passive: 
                    {
                        ability1: `When equipped by Summer Luvia & Rin, increase own ATK by 30% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own DEF by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    { //Summer Sakura - Missing Lore
        id: 108027512,
        name: `Summer Sakura`,
        attribute: `Dark`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_108026512.png",
                detailsuper: "/db/Units/Detail/unit_detail_108027512.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_802_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_108026512.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_108027512.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_802_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/defLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7031,
                hpplus: 1300,
                atk: 3375,
                atkplus: 650,
                def: 3450,
                defplus: 650
            },
        skillset: 
            {
                skill: `12s CT - 2000% Dark DMG (MAG). Increase own Arts by 30.`,
                skillbreak: 500,
                arts: `28000% Dark DMG (MAG). Increase allies' Arts by 20. 90% chance of inflicting Blind for 15s.`,
                artsbreak: 1000,
                truearts: `65000% Dark DMG (MAG). For 30s, increase allies' MAG DMG by 150% and MAG RES by 30%.`,
                trueartsbreak: 2000,
                superarts: `160000% Dark DMG (MAG). For 30s, reduce enemies' Dark RES by 150%. If enemy was Blinded, reduce enemies' Dark RES by 200% instead.`,
                superartsbreak: 2500
            },
        passive: 
            {
                ability1: `Increase Leader's DMG by 150% and Arts by 5/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Blind RES by 100%.`,
                ability3: `Increase DMG against Humans by 50%.`,
                ability4: `Unit EXP UP 20% UP. (When Summer Sakura's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Summer Sakura's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131455.png",
                    mat2amt: 100,
                    hover2: "Sinflow Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Sakura's Swimsuit`,
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_153451.png",
                skill: `50s CT - 8000% Dark DMG (MAG). For 10s, reduce enemies' Dark RES by 30%. If user is Human, reduce enemies' Dark RES by 50% instead.`,
                skillbreak: 500,
                passive: 
                    {
                        ability1: `When equipped by Summer Sakura, increase own ATK by 40% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own ATK by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Missing Lore.",
                evosuper: "Missing Lore."
            }
    },
    { //Shadow - JP Complete
        id: 108037512,
        name: `Shadow`,
        attribute: `Dark`,
        type: `Human`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_108036512.png",
                detailsuper: "/db/Units/Detail/unit_detail_108037512.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_803_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_108036512.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_108037512.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_803_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/magLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/physLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7093,
                hpplus: 1311,
                atk: 3462,
                atkplus: 665,
                def: 3345,
                defplus: 631
            },
        skillset: 
            {
                skill: `12s CT - 3000% Dark DMG (PHY). For 8s, increase all stats by 20%.`,
                skillbreak: 800,
                arts: `20000% Dark DMG (PHY). For 20s, increase own ATK by 100% and all allies' Freeze RES and Paralysis RES by 100%.`,
                artsbreak: 1500,
                truearts: `60000% Dark DMG (PHY). Increase all allies' Arts by 30. For 40s, reduce enemies' PHY RES by 60% (stackable) and Evasion rate by 30% (stackable).`,
                trueartsbreak: 2000,
                superarts: `180000% Dark DMG (PHY). For 30s, allies ignore 100% of Enemies' DEF and increase allies not affected by Status ailments' DMG by 200%.`,
                superartsbreak: 3000
            },
        passive: 
            {
                ability1: `When above 70% HP, increase Dark allies' Arts by 5/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own DMG against Light enemies by 35%.`,
                ability3: `Increase own PHY Equipment CT Speed by 20%.`,
                ability4: `Unit EXP UP 20% UP. (When Shadow's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Shadow's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_131455.png",
                    mat2amt: 100,
                    hover2: "Sinflow Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Shadow's Sword`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_153641.png",
                skill: `60s CT - 8000% Dark DMG (PHY). For 8s, reduce enemies' Dark RES by 40%. Additionally if user is Human, for 8s, increase own DMG by 50%.`,
                skillbreak: 1700,
                passive: 
                    {
                        ability1: `When equipped by Shadow, increase own DMG by 40% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own Evasion rate by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Beta - JP Complete
        id: 108057212,
        name: `Beta`,
        attribute: `Water`,
        type: `Spirit`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_108056212.png",
                detailsuper: "/db/Units/Detail/unit_detail_108057212.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_805_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_108056212.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_108057212.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_805_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/magLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/suppLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7820,
                hpplus: 1410,
                atk: 2860,
                atkplus: 570,
                def: 3565,
                defplus: 670
            },
        skillset: 
            {
                skill: `12s CT - 1800% Water DMG (MAG). For 9s, reduce enemies' DMG RES by 20%.`,
                skillbreak: 600,
                arts: `Heal Water allies' HP by 25%. For 25s, Allies gain a DMG barrier that negates 8000 DMG.`,
                artsbreak: 0,
                truearts: `For 30s, increase all allies' DMG RES by 40% and >50% HP Allies' Arts by 5/s.`,
                trueartsbreak: 0,
                superarts: `150000% Water DMG (MAG). For 20s, increase all allies' MAG DMG by [# of Water allies x 80]% (MAX: 320%).`,
                superartsbreak: 1500
            },
        passive: 
            {
                ability1: `Increase Ally to the left of Beta's DMG by 150%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Curse RES by 100%.`,
                ability3: `Increase DMG to Human Enemies by 40%.`,
                ability4: `Unit EXP UP 20% UP. (When Beta's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Beta's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_132152.png",
                    mat2amt: 100,
                    hover2: "Frostsprite Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Beta's Bow`,
                slot: "/db/Mines/thumbnail/magLB.png",
                detail: "/db/Equipment/Detail/item_detail_153651.png",
                skill: `50s CT - 9000% Water DMG (MAG). For 10s, reduce enemies' Water RES by 30%. If user is Spirit, reduce enemies' Water RES by 50% instead.`,
                skillbreak: 800,
                passive: 
                    {
                        ability1: `When equipped by Beta, increase own ATK by 30% and Arts by 3/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own DEF by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Alpha - JP Complete
        id: 108047412,
        name: `Alpha`,
        attribute: `Light`,
        type: `Spirit`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_108046412.png",
                detailsuper: "/db/Units/Detail/unit_detail_108047412.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_804_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_108046412.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_108047412.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_804_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/healLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/physLB.png",
                slot32type: "/db/Mines/thumbnail/suppLB.png"
            },
        stats: 
            {
                hp: 7085,
                hpplus: 1309,
                atk: 3362,
                atkplus: 647,
                def: 3413,
                defplus: 643
            },
        skillset: 
            {
                skill: `11s CT - 2500% Light DMG (PHY). Increase own Arts by 20. If used when HP is above 80%, increase own Arts by 40 instead.`,
                skillbreak: 700,
                arts: `25000% Light DMG (PHY). For 15s, reduce enemies' Light RES by 60%.`,
                artsbreak: 1200,
                truearts: `65000% Light DMG (PHY). For 25s, increase all allies' Arts by 6/s and reduce enemies' CRI RES by 100%.`,
                trueartsbreak: 1800,
                superarts: `150000% Light DMG (PHY). For 30s, increase all allies' PHY DMG by 100%. If used while the effects of own Super Arts is active, refreshes effect duration and increase PHY DMG buff by an additional 100% (MAX: 400%).`,
                superartsbreak: 2500
            },
        passive: 
            {
                ability1: `When Near-Death, heal all allies' HP by 100%. Additionally, for 10s, increase all allies' DMG RES by 50% (Only once). Does not stack with Abilities of the same name.`,
                ability2: `Increase own CRI DMG by 50%.`,
                ability3: `Increase own Heal Equip CT Speed by 10%.`,
                ability4: `Unit EXP UP 20% UP. (When Alpha's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Alpha's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: `/db/Icons/dungeon_drops/material_thumbnail_131354.png`,
                    mat2amt: 100,
                    hover2: `Eternaglint Soulgem`
                }
            },
        trueweapon: 
            {
                name: `Alpha's Sword`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_153741.png",
                skill: `55s CT - 8000% Light DMG (PHY). For 10s, increase own CRI DMG by 50%. If user is Light, increase own CRI DMG by 100% instead.`,
                skillbreak: 800,
                passive: 
                    {
                        ability1: `When equipped by Alpha, increase own ATK by 20%. Increase own Arts by 100 at the Start of a Quest. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own MAG RES by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    },
    { //Delta - JP Complete
        id: 108067112,
        name: `Delta`,
        attribute: `Fire`,
        type: `Beast`,
        image: 
            {
                detailawk: "/db/Units/Detail/unit_detail_108066112.png",
                detailsuper: "/db/Units/Detail/unit_detail_108067112.png",
                detailspecial: "/db/Units/Special/Detail/unit_sp_detail_806_1.png",
                thumbawk: "/db/Units/Thumbnail/unit_thumbnail_108066112.png",
                thumbsuper: "/db/Units/Thumbnail/unit_thumbnail_108067112.png",
                thumbspecial: "/db/Units/Special/Thumbnail/unit_sp_icon_806_1.png"
            },
        slots: 
            {
                slot1: 6,
                slot1type: "/db/Mines/thumbnail/physLB.png",
                slot2: 5,
                slot2type: "/db/Mines/thumbnail/physLB.png",
                slot3: 4,
                slot31type: "/db/Mines/thumbnail/magLB.png",
                slot32type: "/db/Mines/thumbnail/defLB.png"
            },
        stats: 
            {
                hp: 7645,
                hpplus: 1440,
                atk: 3812,
                atkplus: 660,
                def: 2740,
                defplus: 572
            },
        skillset: 
            {
                skill: `12s CT - 3500% Fire DMG (PHY). Heal ally with the lowest HP by 20%.`,
                skillbreak: 600,
                arts: `20000% Fire DMG (PHY). For 20s, increase own Skill CT Speed by 100% and Physical Equip CT Speed by 30%.`,
                artsbreak: 1000,
                truearts: `60000% Fire DMG (PHY). For 40s, increase own ATK by 50% (stackable) and enemies' Fire RES by 50% (stackable).`,
                trueartsbreak: 1600,
                superarts: `170000% Fire DMG (PHY). For 20s, ignore enemies' DEF by 100%. If used when above 50% HP, for 20s, increase own DMG by 150%. Additionally if used when ownself is not affected by Status ailment(s), for 20s, increase own DMG by 150%.`,
                superartsbreak: 2000
            },
        passive: 
            {
                ability1: `Increase own Arts based on current HP (MAX: 10/s). Does not stack with Abilities of the same name.`,
                ability2: `Increase own DMG by [2% × own ATK/100].`,
                ability3: `Increase Fire allies' BE Output by 20%.`,
                ability4: `Unit EXP UP 20% UP. (When Delta's SP. Art is owned)`,
                ability5: `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name. (When Delta's SP. Art is owned)`
            }, 
        evolution: 
            {
                evoawksuper:
                {
                    mat1: "/db/Icons/dungeon_drops/material_thumbnail_heroSoul.png",
                    mat1amt: 30,
                    mat2: "/db/Icons/dungeon_drops/material_thumbnail_130853.png",
                    mat2amt: 100,
                    hover2: "Flametail Soulgem"
                }
            },
        trueweapon: 
            {
                name: `Delta's Suit`,
                slot: "/db/Mines/thumbnail/physLB.png",
                detail: "/db/Equipment/Detail/item_detail_153751.png",
                skill: `70s CT - 12000% Fire DMG (PHY). For 15s, increase own DMG by 50% and Fire DMG by 70%.`,
                skillbreak: 500,
                passive: 
                    {
                        ability1: `When equipped by Delta, increase own ATK by 40% and Arts by 1/s. Does not stack with Abilities of the same name.`,
                        ability2: `Increase own PHY RES by 5%.`
                    }
            },
        lore: 
            {   
                evoawk: "Currently unreleased in Global.",
                evosuper: "Currently unreleased in Global."
            }
    }
];

export function getUnits() {
    return UnitInformation;
}

export function getUnitByName(name) {
    return UnitInformation.find(
        units => units.name === name
    );
}

